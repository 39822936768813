import { buildFragmentParams } from 'shared/helpers/graphql'
import addLeadNoteMutation from 'graphql/mutations/campaign/addLeadNote'
import leadActivityLog from 'graphql/fragments/leadActivityLog'

export default (client, lead, note) =>
  client.mutate({
    mutation: addLeadNoteMutation,
    variables: { leadUuid: lead.uuid, ...note },
    update: (cache, { data: { addLeadNote: { note } } }) => {
      const fragmentParams = buildFragmentParams(lead, leadActivityLog)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          activityLog: [
            note,
            ...fragmentData.activityLog
          ]
        }
      })
    }
  })

import { t } from 'ttag'

export const facebookPositions = [
  { value: 'feed', label: t`Feed` },
  { value: 'right_hand_column', label: t`Right hand column` },
  { value: 'instant_article', label: t`Instant articles` },
  { value: 'video_feeds', label: t`Instream video` },
  { value: 'marketplace', label: t`Marketplace` },
  { value: 'story', label: t`Story` }
]

export const audienceNetworkPositions = [
  { value: 'classic', label: t`Classic` },
  { value: 'instream_video', label: t`Instream video` },
  { value: 'rewarded_video', label: t`Rewarded video` }
]

export const messengerPositions = [
  { value: 'messenger_home', label: t`Messenger home` },
  { value: 'story', label: t`Story` }
]

export const instagramPositions = [
  { value: 'stream', label: t`Stream` },
  { value: 'story', label: t`Story` }
]

export const portalPositions = [
  { value: 'feed', label: t`Feed` },
  { value: 'right_hand_column', label: t`Right hand column` },
  { value: 'instant_article', label: t`Instant articles` },
  { value: 'video_feeds', label: t`Instream video` },
  { value: 'marketplace', label: t`Marketplace` },
  { value: 'story', label: t`Story` }
]

import React from 'react'
import { t } from 'ttag'
import { shape, string } from 'prop-types'

import Section from './Section'
import TemplateSelect from './TemplateSelect'

const DefaultTemplate = ({ project: { uuid, defaultTemplateUuid } }) => {
  return (
    <Section
      title={ t`Default template` }
      data-test-id='default-template-select-wrapper'>
      <TemplateSelect projectUuid={ uuid } value={ defaultTemplateUuid } />
    </Section>
  )
}

DefaultTemplate.propTypes = {
  project: shape({
    uuid: string.isRequired,
    defaultTemplateUuid: string
  }).isRequired
}

export default DefaultTemplate

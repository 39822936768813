import React from 'react'
import { Dropdown } from 'react-bootstrap'

import UserSummary from './UserSummary'
import LocalesSelector from './LocalesSelector'
import UserMenuToggle from './UserMenuToggle'
import UserMenuActions from './UserMenuActions'

const UserMenu = () => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="link"
        as={ UserMenuToggle }
      />

      <Dropdown.Menu alignRight>
        <UserSummary/>
        <Dropdown.Divider/>
        <LocalesSelector/>
        <Dropdown.Divider/>
        <UserMenuActions/>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UserMenu

import React from 'react'

import AudiencesTable from './AudiencesTable'

const Audiences = (props) => {
  return (
    <AudiencesTable { ...props } />
  )
}

export default Audiences

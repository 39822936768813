import gql from 'graphql-tag'

import stageUnit from 'graphql/fragments/stageUnit'

export default gql`
  fragment StageUnits on Stage {
    units {
      ... StageUnit
    }
  }
  ${stageUnit}
`

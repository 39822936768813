import React from 'react'
import Select from 'react-select'
import { string, func, number } from 'prop-types'
import { t } from 'ttag'

const styles = {
  container: provided => ({
    ...provided,
    minWidth: '6rem'
  })
}

const options = Array.apply(0, Array(121)).map((element, index) => {
  const x = index - 20
  return {
    value: x,
    label: (x === 0 ? t`Ground` : x)
  }
})

const FloorNumberSelect = ({ value, onUpdate, uuid, className }) => {
  const handleChange = ({ value: floorNumber }) => {
    return onUpdate({ uuid, attributes: { floorNumber } })
  }

  return (
    <Select
      className={ className }
      value={ options.find((option) => (option.value === value)) }
      options={ options }
      onChange={ handleChange }
      styles={ styles }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
    />
  )
}

FloorNumberSelect.propTypes = {
  onUpdate: func.isRequired,
  uuid: string.isRequired,
  value: number
}

FloorNumberSelect.defaultProps = {
  value: undefined
}

export default FloorNumberSelect

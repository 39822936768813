import React from 'react'
import { t } from 'ttag'
import styled from 'styled-components'
import { string, shape, array, bool, object } from 'prop-types'

import { tableBorderValue } from 'shared/style/placeholders'

import ColumnHeader from '../ColumnHeader'

import SpendingSubheader     from './SpendingSubheader'
import SpendingChannels      from './SpendingChannels'
import UpdateEstimatesButton from './UpdateEstimatesButton'

const Spending = ({ className, campaign, currency, specification, ...props }) => {
  return (
    <div className={ `${ className } d-flex flex-column` }>
      <ColumnHeader>
        { t`Recommended spending` }
      </ColumnHeader>

      <div className='d-flex flex-column flex-1-1-auto'>
        <SpendingSubheader
          channelsCount={ specification.channels.length }
          channelOptimizationEnabled={ specification.channelOptimizationEnabled }
        />

        <SpendingChannels
          currency={ currency }
          channels={ specification.channels }
          { ...props }
        />

        <UpdateEstimatesButton campaign={ campaign } />
      </div>
    </div>
  )
}

Spending.propTypes = {
  campaign: object.isRequired,
  currency: string.isRequired,
  specification: shape({
    channelOptimizationEnabled: bool.isRequired,
    channels: array.isRequired
  }).isRequired,
}

export default styled(Spending)`
  border-left: ${tableBorderValue}
  border-right: ${tableBorderValue}

  .flex-1-1-auto {
    flex: 1 1 auto
  }
`

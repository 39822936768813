import React  from 'react'
import styled from 'styled-components'
import { array, func, number } from 'prop-types'

import Tab from 'shared/components/ui/TabBar/Tab'

const TabBar = ({ tabs, activeTab, onChange, className }) => {
  const renderTabs = () => {
    return tabs.map((tab) => {
      return (
        <Tab
          className="sales-card-tab"
          key={ tab.index }
          title={ tab.title }
          showArrow={ tab.index < (tabs.length - 1) }
          active={ tab.index === activeTab }
          onClick={ () => onChange(tab.index) }
        />
      )
    })
  }

  return <div className={ `d-flex ${className}` }>
    {renderTabs()}
  </div>
}

TabBar.propTypes = {
  activeTab: number.isRequired,
  onChange: func.isRequired,
  tabs: array.isRequired,
}

export default styled(TabBar)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  .sales-card-tab {
    white-space: normal;
    padding: 4px !important;
  }

  ${Tab} {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0 1.125rem;

    &:last-child {
      border-right: none
    }

    .arrow-top {
      display: none
    }

    .arrow-bottom {
      display: none
    }

    .tab-title {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: .875rem;
      text-align: center;
      line-height: 1.4;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`

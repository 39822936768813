import { t } from 'ttag'

export default function useTabs(project) {
  const tabs = [{ title: t`Total`, index: 0 }].concat(project.completeStages.map((stage, index) => {
    return {
      title: stage.name,
      index: index + 1
    }
  }))

  return {
    tabs
  }
}

import React from 'react'
import styled from 'styled-components'
import { useApolloClient } from 'react-apollo'
import { arrayOf, func, bool, shape, string } from 'prop-types'
import { get } from 'lodash'

import credentials from 'config/credentials'
import { altoGreyColor } from 'shared/style/colors'
import landingPageType from 'shared/types/landingPageType'
import creativeSetType from 'shared/types/creativeSetType'
import useCurrentCampaignUuid from 'shared/hooks/useCurrentCampaignUuid'
import redirectTo from 'shared/helpers/redirectTo'

import { withPopulatedTexts } from '../PopulateTexts'

import revalidateLandingPages from './revalidateLandingPages'
import updateLandingPage from './updateLandingPage'
import LeftColumn from './LeftColumn'
import RightColumn from './RightColumn'

const LandingPage = ({
  className,
  landingPage,
  publishable,
  creativeSets,
  onRemove,
  campaign,
  ...props
}) => {
  const campaignUuid = campaign && campaign.uuid || useCurrentCampaignUuid()
  const project = campaign.project
  const client = useApolloClient()
  const validate = props.validate
  const handleUpdate = input => {
    updateLandingPage(client, landingPage, input).then(() =>
      revalidateLandingPages({ client, campaignUuid, creativeSets, validate })
    )
  }

  const handlePreview = () => {
    if (landingPage.portal) {
      window.open(`${credentials.portalHost}/listings/${project.uuid}/preview`, '_blank')
    } else {
      const templateUrl = get(landingPage, 'template.url')

      if (!templateUrl) return

      const currentUrl = new URL(window.location)
      const dataUrl = `${currentUrl.origin}/landing_page_content/${landingPage.uuid}`

      window.open( `${templateUrl}/index.html?dataUrl=${dataUrl}`)
    }
  }

  const handlePortalEditContent = () => {
    redirectTo(`/projects/${project.uuid}/portal/edit`)
  }

  return (
    <div className={ className }>
      <LeftColumn
        landingPage={ landingPage }
        creativeSets={ creativeSets }
        onUpdate={ handleUpdate }
        onRemove={ onRemove }
        publishable={ publishable }
        { ...props }
      />

      <Separator />

      <RightColumn
        landingPage={ landingPage }
        onUpdate={ handleUpdate }
        onPreview={ handlePreview }
        onPortalEditContent={ handlePortalEditContent }
        { ...props }
      />
    </div>
  )
}

LandingPage.propTypes = {
  campaign: shape({
    uuid: string.isRequired,
  }).isRequired,
  creativeSets: arrayOf(creativeSetType).isRequired,
  landingPage: landingPageType.isRequired,
  publishable: bool.isRequired,
  onRemove: func,
  validate: func
}

LandingPage.defaultProps = {
  validate: undefined,
  onRemove: () => {},
}

const Separator = styled.div`
  margin: 0 1.875rem;
  border-left: solid 2px ${altoGreyColor};
`

export default styled(withPopulatedTexts(LandingPage))`
  border: solid 2px ${altoGreyColor};
  border-radius: 10px;
  display: flex;
  margin: 1rem 0;
  padding: 1.875rem;

  .form-control {
    box-shadow: none;
    height: 1.875rem;
  }

  .form-control:disabled {
    background-color: white;
    border-color: ${altoGreyColor};
    opacity: 0.57;
  }
`

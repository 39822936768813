import React, { memo } from 'react'
import { t } from 'ttag'
import { array, arrayOf, string, shape, func, object } from 'prop-types'
import debounceRender from 'react-debounce-render'
import styled from 'styled-components'

import { debounceTimeout } from 'shared/constants'

import Iframe from './Iframe'

const DebouncedIframe = debounceRender(Iframe, debounceTimeout)

const GmpPreview = ({
  color,
  onLoad,
  banners,
  previewAsset,
  logo,
  property,
  campaign,
  assets,
  bannerTemplateCollection,
  location,
  ...props
}) => {

  const { title, content, address, city } = props

  if (!banners || !banners[0]) {
    return (
      <p className="text-muted">
        { t`select a banner for the preview` }
      </p>
    )
  }

  const renderBanners = () => {
    return banners.map((banner) => {
      return (
        <BannerDiv key={ banner.uuid }>
          <DebouncedIframe
            type={ property.propertyType }
            price={ property.totalPrice }
            size={ property.usableAreaM2 }
            bedrooms={ property.bedrooms }
            location={ location }
            agentName={ property.primaryAgentName }
            agentTitle={ property.primaryAgentTitle }
            agentPhotoUrl={ property.primaryAgentAvatar?.media }
            title={ title }
            color={ color }
            content={ content }
            address={ address }
            city={ city }
            assetUrl={ previewAsset }
            logoUrl={ logo && logo.thumbnail }
            bannerUuid={ banner.uuid }
            bannerName={ banner.name }
            campaign={ campaign }
            onLoad={ onLoad }
            assets={ assets }
            bannerTemplateCollection={ bannerTemplateCollection }
          />
        </BannerDiv>
      )
    })
  }

  return (
    <div>
      {renderBanners()}
    </div>
  )
}

GmpPreview.propTypes = {
  address: string,
  assets: array,
  bannerTemplateCollection: object,
  banners: arrayOf(shape({
    uuid: string
  })),
  campaign: object,
  city: string,
  color: string,
  content: string,
  location: object,
  logo: shape({
    thumbnail: string
  }),
  onLoad: func,
  previewAsset: string,
  property: object,
  title: string
}

GmpPreview.defaultProps = {
  address: null,
  assets: [],
  bannerTemplateCollection: undefined,
  location: {},
  banners: [],
  city: null,
  color: null,
  content: null,
  logo: null,
  onLoad: null,
  previewAsset: null,
  property: {},
  campaign: null,
  title: null
}

const BannerDiv = styled.div`
  margin-bottom: 10px
`

export default memo(GmpPreview, (prevProps, nextProps) => {
  return JSON.stringify(nextProps) === JSON.stringify(prevProps)
})

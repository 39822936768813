import React from 'react'
import styled from 'styled-components'
import { arrayOf, bool } from 'prop-types'

import Gallery from 'shared/components/Gallery'
import assetType from 'shared/types/assetType'

const ImagesGallery = ({ assets, isClosed }) => {
  return (
    <GalleryWrapper isClosed={ isClosed }>
      <Gallery assets={ assets }/>
    </GalleryWrapper>
  )
}

const GalleryWrapper = styled.div`
  height: ${({ isClosed }) => isClosed ? '9rem' : 'auto'}
  overflow: hidden
`

ImagesGallery.propTypes = {
  assets: arrayOf(assetType).isRequired,
  isClosed: bool.isRequired,
}

export default ImagesGallery

import React, { useRef, useState }      from 'react'
import { t }                            from 'ttag'
import { withApollo }                   from 'react-apollo'
import { toast }                        from 'react-toastify'
import { arrayOf, string, shape, func } from 'prop-types'

import Button             from 'shared/components/ui/Button'
import apolloClientType   from 'shared/types/apolloClientType'
import allowedMimeTypes   from 'shared/constants/campaigns/allowedMimeTypes'
import completeFileUpload from 'shared/helpers/files/completeFileUpload'

const UploadButton = ({ assetable, client, attachFileToAssetable, mimeTypes }) => {
  const fileInput = useRef()
  const [isUploading, setIsUploading] = useState(false)

  const handleClick = () => { fileInput.current.click() }

  const handleFile = (file) => {
    const fileName = file.name
    setIsUploading(true)

    completeFileUpload({ client, file, allowedMimeTypes: mimeTypes })
      .then(({ signedBlobId }) => attachFileToAssetable(
        { client, assetable, blobId: signedBlobId }
      ))
      .then(() => toast.success(`File "${fileName}" was uploaded!`))
      .finally(() => {
        fileInput.current.value = ''
        setIsUploading(false)
      })
      .catch((error) => {
        toast.error(`Failed to upload file "${fileName}"`)
        throw error
      })
  }

  const handleFilesSelected = (event) => {
    const files = Array.from(event.target.files)
    if (!files.length) return
    files.forEach((file) => { handleFile(file) })
  }

  return (
    <Button
      onClick={ handleClick }
      disabled={ isUploading }
      className="float-right"
      data-test-id='upload-button'
    >
      <input
        type="file"
        multiple="multiple"
        className="d-none"
        ref={ fileInput }
        data-test-id="upload-button-file-input"
        onChange={ handleFilesSelected }
        accept={ mimeTypes.join(',') }
      />
      { isUploading ? t`Uploading` : t`Upload` }
    </Button>
  )
}

UploadButton.propTypes = {
  assetable: shape({
    uuid: string.isRequired
  }).isRequired,
  attachFileToAssetable: func.isRequired,
  client: apolloClientType.isRequired,
  mimeTypes: arrayOf(string)
}

UploadButton.defaultProps = {
  mimeTypes: allowedMimeTypes
}

export default withApollo(UploadButton)

import { gql } from 'apollo-boost'

export default gql`
  query CampaignAnalyticsTotal($campaignUuid: ID!) {
    campaignAnalyticsTotal(campaignUuid: $campaignUuid) {
      totalImpressions
      totalVisitors
      averageCpc
      averageCtr
      totalLeads
      goalLeads
      averageCpl
      averageArt
      averageLqs
      totalSpent
    }
  }
`

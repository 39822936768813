import React     from 'react'
import { t }     from 'ttag'

import toastError from 'shared/toastError'

const onCampaignCreationFailed = (errors) => {
  if (!errors?.length) {
    return
  }

  toastError(
    <>
      { errors.map(({ message, code, value }) => (
        (code === 'goal_value_too_high' && value) ? (
          t`Goal value too high. 
            The highest realistic value with current specifications is ${value}.`
        ) : (
          message
        )
      )) }
    </>
  )
}

export default onCampaignCreationFailed

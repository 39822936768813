import React, { useCallback, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import { func, shape } from 'prop-types'
import { useQuery } from 'react-apollo'

import Media from 'shared/components/Media'
import productType from 'shared/types/productType'
import campaignAssetAssignments from 'graphql/queries/campaignAssetAssignments'
import stageAssetAssignments    from 'graphql/queries/stageAssetAssignments'

import attachFileToCampaign from './attachFileToCampaign'
import createRemoveAssetAssignment from './createRemoveAssetAssignment'
import createCreateAssetAssignment from './createCreateAssetAssignment'

const CampaignMedia = ({ campaign, setValidatablePayload, onLoaded }) => {
  const { data: stageAssetAssignmentsData, loading: loadingStageAssetAssignments } = useQuery(
    stageAssetAssignments,
    { variables: { uuid: campaign.product.promotable.uuid } }
  )

  const { data: campaignAssetAssignmentsData, loading: loadingCampaignAssetAssignments } = useQuery(
    campaignAssetAssignments,
    { variables: { uuid: campaign.uuid } }
  )

  useEffect(() => {
    if(setValidatablePayload) {
      setValidatablePayload(campaign)
    }
  }, [campaign])

  const attachFileToAssetable = useCallback(({ client, blobId, mediaType }) => {
    return attachFileToCampaign({ client, blobId, mediaType, campaignUuid: campaign.uuid })
  }, [campaign.uuid])

  if (loadingCampaignAssetAssignments || loadingStageAssetAssignments) {
    return null
  }

  const availableAssets = stageAssetAssignmentsData.stage.assetAssignments
    .map((assignment) => {
      const asset = cloneDeep(assignment.asset)
      asset.assignmentUuid = assignment.uuid
      return asset
    })

  const campaignWithAssignments = Object.assign(campaign, {
    assetAssignments: campaignAssetAssignmentsData.campaign.assetAssignments
  })

  if (onLoaded) onLoaded()

  return (
    <Media
      availableAssets={ availableAssets }
      assetAssignable={ campaignWithAssignments }
      mediaSourceType={ campaign.product.promotableType }
      attachFileToAssetable={ attachFileToAssetable }
      createRemoveAssetAssignment={ createRemoveAssetAssignment }
      createCreateAssetAssignment={ createCreateAssetAssignment }
    />
  )
}

CampaignMedia.propTypes = {
  campaign: shape({
    product: productType
  }).isRequired,
  onLoaded: func,
  setValidatablePayload: func
}

CampaignMedia.defaultProps = {
  onLoaded: undefined,
  setValidatablePayload: undefined
}

export default CampaignMedia

import React     from 'react'
import styled    from 'styled-components'
import { shape } from 'prop-types'
import { orderBy }            from 'lodash'
import { FieldArray, Formik } from 'formik'

import stageType from 'shared/types/stageType'

import validationSchema   from './validationSchema'
import SalesArgumentsForm from './SalesArgumentsForm'

const SalesArguments = ({ product: { promotable: stage } }) => {
  const salesArguments = orderBy(stage.salesArguments, 'createdAt')

  const initialValues = {
    salesArguments
  }

  return (
    <Formik initialValues={ initialValues } validationSchema={ validationSchema }>
      <Container>
        <FieldArray name="salesArguments">
          {({ push, remove, form }) => (
            <SalesArgumentsForm
              stageUuid={ stage.uuid }
              salesArguments={ form.values.salesArguments }
              push={ push }
              remove={ remove }
            />
          )}
        </FieldArray>
      </Container>
    </Formik>
  )
}

const Container = styled.div`
  ul {
    padding: 1rem 0;
    margin: 0;
  }
`

SalesArguments.propTypes = {
  product: shape({
    promotable: stageType.isRequired
  }).isRequired,
}

export default SalesArguments

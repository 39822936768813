import React from 'react'
import { t } from 'ttag'
import styled from 'styled-components'
import { string, array } from 'prop-types'

import Select
  from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/GmpExtraFields/ColorSelect/Select'

import useSetColor from './useSetColor'

const options = [{
  value: '#9D162E',
  label: t`red`
}, {
  value: '#55565A',
  label: t`grey`
}, {
  value: '#0E4982',
  label: t`blue`
}, {
  value: '#268D5B',
  label: t`green`
}]

const ColorSelect = ({ className, selectedColor, projectUuid, companyColors }) => {
  const value = options.find(({ value }) => selectedColor === value)
  const setColor = useSetColor(projectUuid)

  return (
    <div className={ className }>
      <div>
        <Select
          onChange={ setColor }
          options={ companyColors.length > 0 ? companyColors : options }
          value={ value }
          selectedColor={ selectedColor }
        />
      </div>
    </div>
  )
}

ColorSelect.propTypes = {
  projectUuid: string.isRequired,
  companyColors: array,
  selectedColor: string
}

ColorSelect.defaultProps = {
  companyColors: [],
  selectedColor: null
}

export default styled(ColorSelect)`
  width: 100%
`

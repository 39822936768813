import React from 'react'
import { t } from 'ttag'
import { string } from 'prop-types'

import { IconCase } from 'shared/components/ui/icons'

import Body from './Body'
import Section from './Section'

const Customer = ({ customerName }) => (
  <Section title={ t`Customer` }>
    <Body
      title={ customerName }
      icon={ <IconCase /> }
    />
  </Section>
)

Customer.propTypes = {
  customerName: string.isRequired,
}

export default Customer

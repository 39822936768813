import React, { createRef, useState } from 'react'
import { t }                  from 'ttag'
import { toast }              from 'react-toastify'
import { map }                from 'lodash'
import { useApolloClient }    from 'react-apollo'
import { array, func, oneOf } from 'prop-types'

import mimeTypes          from 'shared/constants/mimeTypes'
import completeFileUpload from 'shared/helpers/files/completeFileUpload'
import getBlobPreview     from 'shared/helpers/files/getBlobPreview'

import createMimicAsset from './createMimicAsset'
import MediaListView    from './MediaListView'

const MediaList = ({
  className,
  assets,
  handleAssetsChange,
  handleAssetRemoval,
  handleAssetEdit,
  mediaType
}) => {
  const client = useApolloClient()
  const fileInput = createRef()
  const [isUploading, setIsUploading] = useState(false)
  const allowedMimeTypes = mimeTypes[mediaType]

  const openUploadWindow = () => {
    fileInput.current.click()
  }

  const uploadFile = async (files) => {
    setIsUploading(true)
    const uploadedAssets = await Promise.all(map(files, (file) => (
      completeFileUpload({ client, file, allowedMimeTypes, mediaType })
        .then((data) => getBlobPreview({ client, ...data }))
        .then(createMimicAsset(mediaType))
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          if (error === 'Image dimensions must be at least 1000x1000.') {
            toast.error(t`Image dimensions must be at least 1000x1000.`)
          } else toast.error(t`An error happened during upload.`)
        })
        .finally(() => setIsUploading(false))
    )))

    handleAssetsChange(uploadedAssets.filter(Boolean))
  }

  return (
    <MediaListView
      className={ className }
      assets={ assets }
      mediaType={ mediaType }
      fileInput={ fileInput }
      uploadFile={ uploadFile }
      isUploading={ isUploading }
      handleAssetEdit={ handleAssetEdit }
      handleAssetRemoval={ handleAssetRemoval }
      openUploadWindow={ openUploadWindow }
    />
  )
}

MediaList.propTypes = {
  assets: array.isRequired,
  handleAssetsChange: func.isRequired,
  mediaType: oneOf(Object.keys(mimeTypes)).isRequired,
  handleAssetEdit: func,
  handleAssetRemoval: func
}

MediaList.defaultProps = {
  handleAssetEdit: undefined,
  handleAssetRemoval: undefined
}

export default MediaList

import React from 'react'
import styled from 'styled-components'
import { func, object, array, oneOfType } from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'

import { shuttleGray } from 'shared/style/colors'

import AssetContainer from './AssetContainer'

const DraggableAssetContainer = ({ children, onDrop, asset, className }) => {
  const [, connectDrag] = useDrag({
    item: { type: asset.mediaType, ...asset },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [{ isHovered }, connectDrop] = useDrop({
    accept: asset.mediaType,
    drop: (dragAsset) => {
      onDrop(dragAsset, asset)
    },
    collect: monitor => {
      return {
        isHovered: monitor.isOver()
      }
    }
  })

  return connectDrag(connectDrop(
    <div className="d-inline-flex mr-3">
      {isHovered && <HoverMarker />}
      <AssetContainer className={ className }>
        {children}
      </AssetContainer>
    </div>
  ))
}

const HoverMarker = styled.div`
  width: 0.2rem;
  height: 7rem;
  background-color: ${shuttleGray};
  margin-right: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
`

DraggableAssetContainer.propTypes = {
  children: oneOfType([object, array]).isRequired,
  onDrop: func.isRequired
}

export default DraggableAssetContainer

import React from 'react'
import { get } from 'lodash'
import { string, bool } from 'prop-types'
import styled from 'styled-components'
import { ngettext, msgid } from 'ttag'

import projectType from 'shared/types/projectType'
import Card from 'shared/components/ui/CardDeck/Item'
import CampaignCount from 'shared/components/CampaignCount'
import { projectMissingDataPresentation }
  from 'shared/components/Indicators/MissingData/Presentations'

const ProjectCard = ({ project, className, campaignsLiveCount }) => {
  const { stagesCount, unitsCount, activeCampaignsCount } = project
  const missingData = {
    data: project.missingData,
    presentation: projectMissingDataPresentation
  }

  return (
    <Card
      className={ className }
      thumbnail={ get(project, 'image.thumbnail') }
      header={ project.name }
      subheader={ project.customerName }
      missingData={ missingData }
    >
      <div className='stages-info'>
        <span className='stages-count'>
          {
            ngettext(
              msgid`${stagesCount} Stage`,
              `${stagesCount} Stages`,
              stagesCount
            )
          }
        </span>
        <span className='units-count'>
          {
            ngettext(
              msgid`${unitsCount} Unit`,
              `${unitsCount} Units`,
              project.unitsCount
            )
          }
        </span>
      </div>

      {campaignsLiveCount && (
        <CampaignCount count={ activeCampaignsCount } />
      )}
    </Card>
  )
}

ProjectCard.propTypes = {
  className: string.isRequired,
  project: projectType.isRequired,
  campaignsLiveCount: bool
}

ProjectCard.defaultProps = {
  campaignsLiveCount: true
}

export default styled(ProjectCard)`
  min-height: ${props => props.campaignsLiveCount ? '15rem' : '14rem'}

  .stages-info {
    padding-top: 0.75rem
    font-size: 0.875rem
  }

  .stages-count {
    padding-right: 1.25rem
    border-right: solid 0.5px #979797
  }

  .units-count {
    padding-left: 1.25rem
  }
`

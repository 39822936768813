import React from 'react'

import productType from 'shared/types/productType'
import ProductDetailsPanel from 'shared/components/ProductDetails'
import Card from 'shared/components/ui/Card'

const ProductDetails = ({ product }) => {
  return (
    <Card collapsible header="Product details">
      <ProductDetailsPanel product={ product }  />
    </Card>
  )
}

ProductDetails.propTypes = {
  product: productType.isRequired,
}

export default ProductDetails

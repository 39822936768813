import { useEffect, useState } from 'react'

import forwardPostcode from 'shared/helpers/geocoding/forwardPostcode'

export default function useMapCenter({ countryCode, zipCode, latitude, longitude }) {
  const [mapCenter, setMapCenter] = useState(undefined)

  useEffect(() => {
    if (latitude && longitude) {
      setTimeout(() => {
        setMapCenter([longitude, latitude])
      }, 100)

    } else if (countryCode && zipCode) {
      forwardPostcode(zipCode, countryCode).then(({ body }) => {
        if (body?.features?.length) {
          setMapCenter(body.features[0].center)
        }
      })
    }
  }, [])

  return {
    mapCenter,
    setMapCenter
  }
}

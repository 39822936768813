export const getHumanUrlHost = (url) => {
  try {
    const { host } = new URL(url) // eslint-disable-line no-undef

    // Remove "www." from the beginning of URL
    return host.replace(/^www\./, '')
  } catch {
    return false
  }
}

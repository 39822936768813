import styled from 'styled-components'

import { buttonTextColor, redColor, buttonBorderColor } from 'shared/style/colors'

export const Header = styled.h3`
  margin: 0;
  font-size: 1.25rem;
`

export const Row = styled.div`
  margin-bottom: 1rem;
`

export const Label = styled.label`
  display: block;
  color: ${buttonTextColor};
  margin-bottom: 1rem;
  font-size: 0.825rem;
`

export const Input = styled.input`
  border-radius: 0.25rem;
  border: solid 1px ${props => props.isInvalid ? redColor : buttonBorderColor};
  display: block;
  font-size: 0.825rem;
  height: 2rem;
  width: 100%;
  padding: 0 0.5rem;
`

export const ErrorMessage = styled.div`
  font-size: 0.75rem;
  line-height: 2;
  color: ${redColor};
`

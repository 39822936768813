import gql from 'graphql-tag'

import asset from 'graphql/fragments/asset'

export default gql`
  mutation AddLandingPageGalleryImage(
    $uuid: ID!
    $assetUuid: ID!
  ) {
    addLandingPageGalleryImage(
      uuid: $uuid
      assetUuid: $assetUuid
    ) {
      galleryImage {
        uuid
        asset {
          ... Asset
        }
      }
    }
  }
  ${asset}
`

import { gql } from 'apollo-boost'

import facebookPlatformAudience from 'graphql/fragments/facebookPlatformAudience'
import instagramPlatformAudience from 'graphql/fragments/instagramPlatformAudience'
import portalPlatformAudience from 'graphql/fragments/portalPlatformAudience'
import campaign from 'graphql/fragments/campaign'

export default gql`
  mutation UpdateFacebookMarketableAudience(
    $uuid: ID!
    $attributes: FacebookMarketableAudienceAttributes!
  ) {
    updateFacebookMarketableAudience(
      uuid: $uuid
      attributes: $attributes
    ) {
      campaign {
        ... Campaign
      }
      audience {
        platformAudience {
          ... FacebookPlatformAudience

          ... InstagramPlatformAudience

          ... PortalPlatformAudience
        }
      }
    }
  }
  ${facebookPlatformAudience}
  ${instagramPlatformAudience}
  ${portalPlatformAudience}
  ${campaign}
`

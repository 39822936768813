import attachFileMutation from 'graphql/mutations/assetable/attachFile'

export default function attachFileToUnit({ client, blobId, unitUuid, mediaType }) {
  const assetableUuid = unitUuid
  const assetableType = 'StageUnit'

  return client.mutate({
    mutation: attachFileMutation,
    variables: { blobId, assetableUuid, assetableType, mediaType },
    update: (store, {
      data: {
        attachFileToAssetable: { assetAssignment }
      }
    }) => {
      return assetAssignment
    }
  })
}

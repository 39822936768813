import styled      from 'styled-components'
import { NavLink } from 'react-router-dom'

import { blackColor } from 'shared/style/colors'

const SidebarLink = styled(NavLink)`
  color: ${blackColor}
  display: block
  font-size: .875rem
  letter-spacing: 0.35px
  padding-left: .4rem
  margin-bottom: .6rem
  white-space: nowrap
  vertical-align: center
  opacity: ${({ to }) => to ? '1' : '0.5'};
  pointer-events: ${({ to }) => to ? 'initial' : 'none'};

  &:hover {
    color: ${blackColor}
    text-decoration: none
  }

  &:last-child {
    margin-bottom: 0
  }

  &:first-child {
    padding-top: .1rem
  }

  [class^="icon-"] {
    display: inline-block
    font-size: 1rem
    width: 1.5rem
  }

  &.active {
    color: #4F68D8
  }
`

SidebarLink.defaultProps = {
  to: ''
}

export default SidebarLink

import client from './client'

const forwardPostcode = (query, countryCode) => (
  client
    .forwardGeocode({
      query,
      countries: [countryCode],
      types: ['postcode']
    })
    .send()
)

export default forwardPostcode

import { useState } from 'react'

export default function useGallery(initialAssets) {
  const [assets, setAssets] = useState(initialAssets)
  const [openedKey, open]   = useState(undefined)
  const close = () => open(undefined)

  if (initialAssets.length !== assets.length) {
    setAssets(initialAssets)

    if (!initialAssets[openedKey]) {
      if (initialAssets[openedKey - 1]) {
        open(openedKey - 1)
      } else {
        close()
      }
    }
  }

  const controls = { open, close }

  const state = {
    assets,
    openedKey,
    isOpened: openedKey !== undefined
  }

  return [controls, state]
}

import React  from 'react'
import styled from 'styled-components'
import { bool, func } from 'prop-types'

import Add from 'shared/assets/icons/svg/add.svg'

const PlusIcon = ({ className, onClick }) => (
  <Add className={ className } onClick={ onClick  } />
)

PlusIcon.propTypes = {
  onClick: func,
  rotated: bool // eslint-disable-line react/no-unused-prop-types
}

PlusIcon.defaultProps = {
  onClick: null,
  rotated: false
}

export default styled(PlusIcon)`
  height: 12px;
  width: 12px;
  
  transform: ${({ rotated }) => rotated ? 'rotate(45deg)' : 'none'}
`

import React, { useEffect } from 'react'
import { t }                from 'ttag'
import { useQuery, useApolloClient } from 'react-apollo'
import { toast }            from 'react-toastify'
import { get } from 'lodash'
import { array, string, shape, func } from 'prop-types'

import campaignCreativeSets   from 'graphql/queries/campaignCreativeSets'
import companyBannerTemplateCollections from 'graphql/queries/companyBannerTemplateCollections'
import LoadingBlock           from 'shared/components/ui/LoadingBlock'
import { withPopulatedTexts } from 'shared/components/CampaignInfo/PopulateTexts'
import NewCreativeSet         from 'shared/components/CampaignInfo/CreativeSets/NewCreativeSet'
import cloneCreativeSet       from 'shared/components/CampaignInfo/CreativeSets/cloneCreativeSet'

import CreativeSetsList                   from './CreativeSetsList'
import buildCreativeSetsValidationPayload from './buildCreativeSetsValidationPayload'

const CreativeSets = ({
  campaign,
  setValidatablePayload,
  onLoaded,
  ...props
}) => {
  const { uuid, facebookPage, channels, product = {}, assetAssignments } = campaign

  const audiences = channels
    .filter(channel => channel.enabled)
    .flatMap(channel => channel.audiences)
    .filter(audience => !audience.deleted)

  const { company } = product
  const client = useApolloClient()
  const { data, loading } = useQuery(
    campaignCreativeSets,
    {
      variables: { campaignUuid: uuid },
      fetchPolicy: 'network-only'
    }
  )
  const creativeSets = data ? data.campaignCreativeSets : []

  useEffect(
    () => {
      if(setValidatablePayload) {
        setValidatablePayload(
          buildCreativeSetsValidationPayload({ audiences: audiences, creativeSets, campaign })
        )
      }
    },
    [JSON.stringify(creativeSets)]
  )

  useEffect(() => {
    if(!loading && onLoaded) {
      onLoaded()
    }
  }, [loading])

  const { data: templatesData } = useQuery(
    companyBannerTemplateCollections,
    {
      variables: { companyUuid: company.uuid },
      fetchPolicy: 'network-only'
    }
  )
  const bannerTemplateCollections = templatesData
    ? templatesData.companyBannerTemplateCollections : []

  if (loading) return <LoadingBlock comment={ t`Generating ads...` } />

  const handleClone = (creativeSet) => {
    cloneCreativeSet(client, campaign, creativeSet)
    toast.success(t`Creative Set has been cloned`)
  }

  return (
    <div className="px-4">
      <NewCreativeSet channels={ channels } features={ company.enabledFeatures } />

      <CreativeSetsList
        campaign={ campaign }
        company={ company }
        audiences={ audiences }
        creativeSets={ creativeSets }
        bannerTemplateCollections={ bannerTemplateCollections }
        logos={ get(product, 'promotable.logos', []) }
        facebookPageName={ facebookPage?.name }
        facebookPageLogo={ facebookPage?.logoUrl }
        assetAssignments={ assetAssignments }
        onClone={ handleClone }
        { ...props }
      />
    </div>
  )
}

CreativeSets.propTypes = {
  campaign: shape({
    channels: array.isRequired,
    uuid: string.isRequired
  }).isRequired,
  onLoaded: func,
  setValidatablePayload: func,
}

CreativeSets.defaultProps = {
  onLoaded: undefined,
  setValidatablePayload: undefined
}

export default withPopulatedTexts(CreativeSets)

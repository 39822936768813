import React from 'react'
import Select from 'react-select'
import { t } from 'ttag'
import { number } from 'prop-types'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'

import { LeadQualityScoreContainer } from './elements'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    width: '5rem'
  })
}

const options = Array(10).fill()
  .map((_val, idx) => idx + 1)
  .map(value => ({ value, label: value }))

const LeadQualityScore = ({ value, ...props }) => {
  const selectedOption = { value, label: value }

  return (
    <LeadQualityScoreContainer>
      <label>{ t`Lead Quality` }</label>
      <Select
        value={ selectedOption }
        options={ options }
        styles={ styles }
        placeholder={ t`Select...` }
        noOptionsMessage={ () => t`No options` }
        { ...props }
      />
    </LeadQualityScoreContainer>
  )
}

LeadQualityScore.propTypes = {
  value: number
}

LeadQualityScore.defaultProps = {
  value: undefined
}

export default LeadQualityScore

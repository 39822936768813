import gql from 'graphql-tag'

export default gql`
  query Properties($query: PageInput!) {
    properties(query: $query) {
      total
      items {
        uuid
        name
        customerName
        activeCampaignsCount
        campaignsCount
        missingData
        propertyType
        price
        totalPrice
        currency
        image {
          thumbnail
        }
      }
    }
  }
`

import React from 'react'

import propertyType from 'shared/types/propertyType'
import Column from 'shared/components/ui/TwoColumnList/Column'
import Lot from 'shared/components/ProductDetails/PropertyDetails/Lot'
import Floor from 'shared/components/ProductDetails/PropertyDetails/Floor'

const RightColumn = ({ promotable }) => {
  const { propertyType, roomCount, bedroomCount, lotM2, floorNumber } = promotable

  const columnItems = [
    propertyType && { label: 'Property type', value: propertyType },
    roomCount    && { label: 'Rooms', value: roomCount },
    bedroomCount && { label: 'Bedrooms', value: bedroomCount },
    lotM2        && { label: 'Lot', value: <Lot lot={ lotM2 } /> },
    floorNumber  && { label: 'Floor', value: <Floor floor={ floorNumber } /> }
  ].filter(Boolean)

  return <Column column={ columnItems }/>
}

RightColumn.propTypes = {
  promotable: propertyType.isRequired
}

export default RightColumn

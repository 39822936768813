import { useMutation } from 'react-apollo'

import updateUserMutation from 'graphql/mutations/users/updateUser'

export default function useUpdateUser() {
  const [updateUser, variables] = useMutation(updateUserMutation)

  return {
    updateUser,
    ...variables
  }
}

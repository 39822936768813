import React from 'react'
import { number, string, oneOfType } from 'prop-types'

const Lot = ({ lot }) => <span>{ lot } m2</span>

Lot.propTypes = {
  lot: oneOfType([number, string]).isRequired
}

export default Lot

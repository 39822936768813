import { omit } from 'lodash'

export const paginatedSearchTextChangeHandler = (urlParams, setUrlParams) => {
  return (search) => {
    setUrlParams(
      search.length
        ? { ...omit(urlParams, ['page']), search }
        : { ...omit(urlParams, ['page', 'search']) }
    )
  }
}

export const paginatedSearchPageChangeHandler = (urlParams, setUrlParams) => {
  return (page) => {
    setUrlParams({ ...urlParams, page: (page.selected + 1) })
  }
}

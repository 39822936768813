import React from 'react'
import { t } from 'ttag'
import { oneOf, string, shape } from 'prop-types'

import { localDateTime } from 'shared/helpers/date'
import leadProgress from 'shared/constants/leadProgress'

import { RecordTitle, RecordDetails, RecordInfo } from './elements'

const Progress = props => {
  const progressPresentation = value => {
    const element = leadProgress.find(el => el.value === value)
    return element && element.label
  }

  return (
    <>
      <RecordInfo>
        <RecordTitle>
          { t`Progress` }:
          { props.statusBefore && ` "${progressPresentation(props.statusBefore)}" ->` }
          &nbsp;
          &quot;{ progressPresentation(props.statusAfter) }&quot;
        </RecordTitle>
        <RecordDetails>
          <span>{ localDateTime(props.createdAt) }</span>
          { props.author && (
            <span> &nbsp;&mdash;&nbsp; <i>{ t`Updated by` } { props.author.name }</i></span>
          )}
        </RecordDetails>
      </RecordInfo>
    </>
  )
}

Progress.propTypes = {
  createdAt: string.isRequired,
  statusAfter: oneOf(leadProgress.map(el => el.value)).isRequired,
  author: shape({
    name: string.isRequired
  }),
  statusBefore: oneOf([null, ...leadProgress.map(el => el.value)])
}

Progress.defaultProps = {
  author: null,
  statusBefore: null
}

export default Progress

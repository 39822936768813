import React, { useRef, useCallback } from 'react'
import { func }                       from 'prop-types'
import { t }                          from 'ttag'

import Button from 'shared/components/ui/Button'
import readBlobAsDataUrl from 'shared/helpers/readBlobAsDataUrl'

const UploadButton = ({ setFileDataURI, setFileMeta }) => {
  const fileInput          = useRef()
  const openFileSelectMenu = useCallback(() => fileInput.current.click(), [])
  const setUploadingFiles  = useCallback((e) => {
    const file = e.target.files[0]

    setFileMeta({ name: file.name, type: file.type })

    if (!file) return

    readBlobAsDataUrl(file).then((fileDataURI) => {
      setFileDataURI(fileDataURI)
      fileInput.current.value = null
    })
  }, [])

  return (
    <Button onClick={ openFileSelectMenu }>
      <input
        ref={ fileInput }
        onChange={ setUploadingFiles }
        type="file"
        accept="image/x-png,image/png,image/jpeg,image/gif"
        data-test-id="upload-button"
        className="d-none" />
      { t`Upload` }
    </Button>
  )
}

UploadButton.propTypes = {
  setFileDataURI: func.isRequired,
  setFileMeta: func.isRequired
}

export default UploadButton

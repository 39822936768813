import React     from 'react'
import { merge } from 'lodash'
import { toast } from 'react-toastify'
import { t }     from 'ttag'

const defaultMessage = (
  <>
    <p className="mb-1">
      { t`Oops something went wrong,`} <br /> { t`please try again later.` }
    </p>
    <p className="mb-0">
      { t`If problem persists, please let us know.` }
    </p>
  </>
)

export default function toastError(message, options = {}) {
  const defaultOptions = {
    autoClose: false,
    style: { width: 1500 }
  }

  toast.error(message || defaultMessage, merge(defaultOptions, options))
}

import React      from 'react'
import { object } from 'prop-types'
import { t }      from 'ttag'

import Header from 'shared/components/ui/Card/StaticHeader'
import Card   from 'shared/components/ui/Card'

import SettingsTable from './SettingsTable'

const Notifications = ({ user }) => {
  return (
    <Card className="mt-3">
      <Header>{ t`Notifications` }</Header>
      <SettingsTable user={ user } />
    </Card>
  )
}

Notifications.propTypes = {
  user: object.isRequired
}

export default Notifications

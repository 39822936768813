import { gql } from 'apollo-boost'

import offer from '../../fragments/projectOffer'

export default gql`
  query projectOffers($projectUuid: ID!) {
    projectOffers(projectUuid: $projectUuid) {
      ... Offer
    }
  }
  ${offer}
`

import React from 'react'

// import Balance       from './Balance'
// import Notifications from './Notifications'
// import Tasks         from './Tasks'
import UserMenu      from './UserMenu'

const UserActions = () => {
  return (
    <div className="d-flex align-items-center">
      {/* Hidden for MVP */}
      {/*<Balance />*/}
      {/*<Tasks />*/}
      {/*<Notifications />*/}
      <UserMenu />
    </div>
  )
}

export default UserActions

import React from 'react'
import styled from 'styled-components'
import { string, node } from 'prop-types'

import Card from 'shared/components/ui/Card'
import Header from 'shared/components/ui/Card/StaticHeader'

const ChartCard = ({ className, header, dataTestId, children }) => {
  return (
    <Card className={ className } header={ header && (
      <div className="d-flex">
        <Header className="align-self-center">
          { header }
        </Header>
      </div>
    ) } dataTestId={ dataTestId }>
      { children }
    </Card>
  )
}

ChartCard.propTypes = {
  children: node.isRequired,
  dataTestId: string,
  header: string
}

ChartCard.defaultProps = {
  dataTestId: null,
  header: null
}

export default styled(ChartCard)`
  height: 16rem

  &.padded .card-body {
    padding-top: 0rem
  }

  .chart-container {
    width: 100%
    height: 100%
  }

  .loading-block {
    padding-top: 4.5rem !important;
  }
`

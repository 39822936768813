import React, { memo } from 'react'
import { t }           from 'ttag'
import { func }        from 'prop-types'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { isUndefined } from 'lodash'
import classNames from 'classnames'
import styled from 'styled-components'

import Play         from 'shared/assets/icons/svg/play.svg'
import assetType    from 'shared/types/assetType'
import getImageUrl  from 'shared/helpers/getImageUrl'
import missingImage from 'shared/assets/images/missing.png'

import AssetLabel from './AssetLabel'
import AssetContainer from './Asset/Container'

const unmarketablePopover = (
  <Popover className='bootstrap-popover'>
    <Popover.Content>
      {t`Resolution of this image is less than recommended size for channels.
      It should be 1000x1000 or higher.`}
    </Popover.Content>
  </Popover>
)

const Asset = ({
  asset,
  onClick,
  handleAssetRemoval,
  className,
  handleAssetEdit
}) => {
  const assetMarketable = asset.marketable || isUndefined(asset.marketable)

  if (!assetMarketable) {
    return null
  }

  const assetDeletable = asset.deletable
  const handleClick = (e) => {
    if (handleAssetRemoval && e.target.dataset.action === 'delete') {
      handleAssetRemoval(asset)
    } else if (handleAssetEdit && e.target.dataset.action === 'edit') {
      handleAssetEdit(asset.uuid)
    } else if (assetMarketable && onClick) {
      onClick(asset)
    }
  }

  const finalClassName = classNames(
    className,
    'asset-container',
    { 'not-marketable': !assetMarketable }
  )

  return (
    <AssetContainer
      onClick={ handleClick }
      className={ finalClassName }
      style={{ backgroundColor: (asset.mediaType === 'logo' && asset.backgroundColor) }}
    >
      { handleAssetRemoval && assetDeletable && (
        <button type="button" data-action='delete'>
          ✖
        </button>
      ) }

      { handleAssetEdit && (
        <button
          type="button"
          className='edit-button'
          data-action='edit'>
          <span className="icon-edit" data-action='edit' />
        </button>
      ) }

      { assetMarketable ? (
        <img
          src={ asset.thumbnail }
          alt={ `${ t`Media` } ${ asset.position }` }
          onError={ ({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = getImageUrl(missingImage)
          } }
        />
      ) : (
        <OverlayTrigger placement='auto' overlay={ unmarketablePopover }>
          <img
            src={ asset.thumbnail }
            alt={ `${ t`Media` } ${ asset.position }` }
            onError={ ({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = getImageUrl(missingImage)
            } }
          />
        </OverlayTrigger>
      ) }

      <AssetLabel className="asset-label" asset={ asset } />

      { asset.mediaType === 'video' && (
        <Play />
      ) }
    </AssetContainer>
  )
}

const StyledAsset = styled(Asset)`
  .edit-button {
    position: absolute;
    left: 0;
  }
`

Asset.propTypes = {
  asset: assetType.isRequired,
  handleAssetEdit: func,
  handleAssetRemoval: func,
  onClick: func,
}

Asset.defaultProps = {
  handleAssetEdit: undefined,
  handleAssetRemoval: undefined,
  onClick: undefined
}

export const NonMemoAsset = StyledAsset
export default memo(StyledAsset, () => true)

import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { t } from 'ttag'
import React from 'react'
import { bool, element } from 'prop-types'

const PublishingTooltipOverlay = ({ children, publishingMode }) => {
  if (!publishingMode) {
    return children
  }

  const publishingPopover = (
    <Tooltip id="publishing-tooltip">
      { t`The campaign is being published. Please wait until the publishing is finished.` }
    </Tooltip>
  )

  return (
    <OverlayTrigger
      placement="right"
      overlay={ publishingPopover }>
      <span>{ children }</span>
    </OverlayTrigger>
  )
}

PublishingTooltipOverlay.propTypes = {
  children: element.isRequired,
  publishingMode: bool.isRequired
}

export default PublishingTooltipOverlay

import styled from 'styled-components'

import FbBusinessManagerIcons from 'shared/assets/icons/fb-business-manager.png'

const MapMarkerIcon = styled.div`
  cursor: pointer
  width: 1.563rem
  height: 2rem
  margin-left: 0.063rem
  margin-bottom: 1.813rem
  background: url(${FbBusinessManagerIcons}) no-repeat center
  background-size: 2.188rem 28.875rem

  &.operator-include {
    background-position: 0rem -6.75rem
  }

  &.operator-include.highlighted {
    background-position: 0rem -8.87rem
  }

  &.operator-exclude {
    background-position: 0rem -2.5rem
  }

  &.operator-exclude.highlighted {
    background-position: 0rem -4.64rem
  }
`

export default MapMarkerIcon

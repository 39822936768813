import React from 'react'

import locationType from 'shared/types/locationType'
import { formatAddress } from 'shared/helpers/address'

const Address = ({ location }) => {
  return (
    <span>
      { formatAddress({
        address: location.address,
        zipCode: location.zipCode,
        city:    location.city
      }) }
    </span>
  )
}

Address.propTypes = {
  location: locationType.isRequired
}

export default Address

import { gql } from 'apollo-boost'

export default gql`
  query Project ($uuid: ID!) {
    project(uuid: $uuid) {
      uuid
      name
      customerName
      unitsCount
      unitsSold
      stagesCount
      totalCampaignsCount
      activeCampaignsCount
      salesReportEnabled
      enabledExtraUnits
      product {
        company {
          source
        }
      }
      completeStages {
        id
        uuid
        name
        units {
          uuid
          unitId
          unitType
          roomCount
          usableAreaM2
          livableAreaM2
          normalizedPriceWithCollectiveDebt
          state
          reservations
          soldAt
          vitecDocumentsCount
        }
        percentageSoldByValue
      }
      percentageSoldByValue
    }
  }
`

import { gql } from 'apollo-boost'

import stageUnitBalcony from 'graphql/fragments/stageUnitBalcony'

export default gql`
  mutation updateStageUnitBalcony(
    $uuid: ID!
    $input: StageUnitBalconyInput!
  ) {
    updateStageUnitBalcony(
      uuid: $uuid
      input: $input
    ) {
      balcony {
        ... StageUnitBalcony
      }
    }
  }
  ${stageUnitBalcony}
`

import gql from 'graphql-tag'

export default gql`
  mutation removeSpendingChannel($campaignUuid: ID!, $index: Int!) {
    removeSpendingChannel(campaignUuid: $campaignUuid, index: $index) {
      specification {
        uuid
        channels
      }
    }
  }
`

import { gql } from 'apollo-boost'

import snapshotCustomFacility from 'graphql/fragments/snapshotCustomFacility'

export default gql`
  mutation createSnapshotCustomFacility(
    $snapshotUuid: ID!
    $input: SnapshotCustomFacilityInput!
  ) {
    createSnapshotCustomFacility(
      snapshotUuid: $snapshotUuid
      input: $input
    ) {
      customFacility {
       ... SnapshotCustomFacility
      }
    }
  }
  ${snapshotCustomFacility}
`

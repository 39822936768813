import { buildFragmentParams } from 'shared/helpers/graphql'
import buildingFragment        from 'graphql/fragments/building'
import deleteUnitMutation      from 'graphql/mutations/projects/stages/units/deleteUnitMutation'

const deleteUnit = (client, building, uuid) => {
  client.mutate({
    mutation: deleteUnitMutation,
    variables: { uuid },
    update: (cache, { data: { deleteStageUnit: { unit } } }) => {
      const fragmentParams = buildFragmentParams(
        { __typename: 'Building', uuid: building.uuid },
        buildingFragment
      )
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          units: fragmentData.units.filter(({ uuid }) => uuid !== unit.uuid)
        }
      })
    }
  })
}

export default deleteUnit

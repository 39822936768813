import React    from 'react'
import styled   from 'styled-components'
import { pick } from 'lodash'

import campaignType from 'shared/types/campaignType'
import CampaignSummary from 'shared/components/CampaignSummary'
import CampaignName from 'shared/components/CampaignSummary/CampaignName'

import CampaignActions from './CampaignActions'
import PhaseBadge      from './PhaseBadge'

const Header = ({ campaign, className }) => {
  const campaignActionsProps = {
    ...pick(campaign, ['uuid', 'name', 'startDate', 'endDate', 'phase', 'canSetPhase']),
    promotableUuid: campaign.product.promotable.uuid,
    promotableType: campaign.product.promotableType
  }
  return (
    <CampaignSummary campaign={ campaign }>
      <div className={ className }>
        <CampaignActions { ...campaignActionsProps } />

        <CampaignName>
          { campaign.name }
        </CampaignName>

        <PhaseBadge campaign={ campaign } />
      </div>
    </CampaignSummary>
  )
}

Header.propTypes = {
  campaign: campaignType.isRequired
}

export default styled(Header)`
  width: 100%
  padding-bottom: 1.5rem

  ${CampaignName} {
    text-align: center
  }
`

import React from 'react'
import { t } from 'ttag'
import { arrayOf, string, shape, number } from 'prop-types'
import styled from 'styled-components'

import MultiSelect from 'shared/components/ui/MultiSelect/MultiSelect'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

import ValueContainer from './LanguageTargetingSelect/ValueContainer'
import Option from './LanguageTargetingSelect/Option'
import LanguageData from './LanguageTargetingSelect/LanguageData'

const components = { ValueContainer, Option }

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1
  })
}

const valueToOption = el => ({
  ...el,
  label: el.name,
  value: el.id
})

const LanguageTargetingSelect = ({ className, value: selectedValues, ...props }) => {
  const selectedOptions = selectedValues.map((element) => {
    const result = LanguageData.filter(language => language.id === element.id)
    return {
      name: element.name,
      id: element.id,
      value: element.id,
      label: result[0].name
    }
  })

  return (
    <div className={ className }>
      <MultiSelect
        hideSelectedOptions={ false }
        components={ components }
        value={ selectedOptions }
        options={ LanguageData.map(valueToOption) }
        styles={ styles }
        placeholder={ t`Select language targeting` }
        noOptionsMessage={ () => t`No options` }
        { ...props }
      />
    </div>
  )
}

LanguageTargetingSelect.propTypes = {
  value: arrayOf(shape({
    name: string,
    id: number
  })).isRequired
}

export default styled(LanguageTargetingSelect)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { number, string } from 'prop-types'

import { triangleUp, triangleDown } from 'shared/style/shapes'
import { lightBlueColor, textGreyColor, greenColor, darkRoseColor } from 'shared/style/colors'

const LeadsStatsRow = ({ className, value, increment, comment }) => {
  return (
    <Row className={ `${className}` }>
      <Col className='datum value text-right' sm={ 4 }>
        { value }
      </Col>

      <Col className='datum increment text-left' sm={ 2 }>
        <span className={ increment >= 0 ? 'up' : 'down' }>
          <span className='triangle' />
          <span className='increment-text'>
            { Math.abs(increment) }
          </span>
        </span>
      </Col>

      <Col className='datum comment text-left' sm={ 6 }>
        { comment }
      </Col>
    </Row>
  )
}

LeadsStatsRow.propTypes = {
  comment: string.isRequired,
  increment: number.isRequired,
  value: number.isRequired
}

export default styled(LeadsStatsRow)`
  margin: 1.7rem 0
  display: flex
  flex-direction: row
  align-items: center
  position: relative

  .datum {
    padding: 0
  }

  .increment {
    font-size: 0.65rem
    position: relative
    top: -0.4rem
  }

  .increment-text {
    position: relative
    bottom: 0.1rem
  }

  .up {
    color: ${greenColor}
  }

  .triangle {
    display: inline-block
    transform: scale(0.75)
  }

  .up .triangle {
    ${triangleUp};
    border-color: transparent transparent ${greenColor} transparent;
  }

  .down {
    color: ${darkRoseColor}
  }

  .down .triangle {
    ${triangleDown};
    border-color: ${darkRoseColor} transparent transparent transparent;
  }

  .value {
    font-weight: 600
    font-size: 2rem
    color: ${lightBlueColor}
  }

  .comment {
    align-self: center;
    font-size: 0.85rem;
    color: ${textGreyColor}
    margin-left: 0
  }
`

import { gql } from 'apollo-boost'

export default gql`
  mutation updateStage(
    $stageUuid: ID!
    $input: StageInput
  ) {
    updateStage(
      stageUuid: $stageUuid
      input: $input
    ) {
      stage {
        uuid
        name
        autoDescriptionEnabled
        sourceDescription
        customDescriptionDraft
        titleDraft
        headlineDraft
        flatFinderUrl
        linkDraft
      }
    }
  }
`

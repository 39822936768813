import { clearToken } from 'shared/helpers/auth'
import { redirectToLogin } from 'shared/helpers/redirectTo'

const fitIframeSize = (e) => {
  const iFrame = e.target
  const iframeContainer = iFrame.parentElement
  const iframeDocument = iFrame.contentDocument || iFrame.contentWindow.document

  const errorElement = iframeDocument.querySelector('body pre')
  if (errorElement !== null) {
    const errorBody = JSON.parse(errorElement.innerText)
    if (errorBody.email === null && errorBody.password === null) {
      clearToken()
      return redirectToLogin()
    }
  }

  const iframeBody = iframeDocument.querySelector('body')
  const iframeTitle = iframeDocument.querySelector('title').innerText

  // Skip if it's an error page
  if (iframeTitle.includes('404') || iframeTitle.includes('Exception')) {
    return
  }

  const containerInnerWidth = getContainerInnerWidth(iframeContainer)
  const bannerScaleRatioMultiplier = containerInnerWidth / iframeBody.offsetWidth

  iframeBody.style.transform = `scale(${bannerScaleRatioMultiplier})`
  iframeBody.style.transformOrigin = 'top left'

  const newBodySize = iframeBody.getBoundingClientRect()

  iFrame.style.height = `${newBodySize.height}px`
}

const getContainerInnerWidth = (iframeContainer) => {
  const containerStyle = window.getComputedStyle(iframeContainer, null)
  const containerPaddingLeft = parseFloat(containerStyle.getPropertyValue('padding-left'))
  const containerPaddingRight = parseFloat(containerStyle.getPropertyValue('padding-right'))
  const containerPaddingX = containerPaddingLeft + containerPaddingRight

  return iframeContainer.scrollWidth - containerPaddingX
}

export default fitIframeSize

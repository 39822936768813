import gql from 'graphql-tag'

import stage from 'graphql/fragments/stage'

export default gql`
  mutation updateStage(
    $stageUuid: ID!
    $input: StageInput
    $location: LocationInput
  ) {
    updateStage(
      stageUuid: $stageUuid
      input: $input
      location: $location
    ) {
      stage {
        ... Stage
      }
    }
  }
  ${stage}
`

import React  from 'react'
import styled from 'styled-components'
import { arrayOf } from 'prop-types'

import creativeSetType from 'shared/types/creativeSetType'
import bannerTemplateCollectionType from 'shared/types/bannerTemplateCollectionType'
import campaignType from 'shared/types/campaignType'

import CreativeSet from './CreativeSet'

const CreativeSetsList = ({
  creativeSets, className, bannerTemplateCollections, campaign, ...props
}) => {
  return (
    <div className={ className }>
      { creativeSets.map((creativeSet) => (
        <CreativeSet
          creativeSet={ creativeSet }
          key={ creativeSet.uuid }
          campaign={ campaign }
          bannerTemplateCollections={
            bannerTemplateCollections.filter(bt => (
              bt.channelType === creativeSet.channelType
              && bt.promotableType === campaign.promotableType
            ))
          }
          { ...props }
        />
      )) }
    </div>
  )
}

CreativeSetsList.propTypes = {
  bannerTemplateCollections: arrayOf(bannerTemplateCollectionType).isRequired,
  campaign: campaignType.isRequired,
  creativeSets: arrayOf(creativeSetType).isRequired
}

export default styled(CreativeSetsList)`
  padding: 1.5rem 0

  ${CreativeSet} {
    margin: 0 0 1.5rem
  }
`

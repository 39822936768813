import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { func, array } from 'prop-types'

import landingPageType from 'shared/types/landingPageType'
import Button from 'shared/components/ui/Button'
import ValidatableWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import { combineErrors } from 'shared/helpers/inputValidation'

import Preview from './Preview'
import TemplatesSelect from './TemplatesSelect'

const RightColumn = ({
  className,
  landingPage,
  onUpdate,
  onPreview,
  onPortalEditContent,
  errors
}) => {
  const handleTemplateChange = ({ value }) => onUpdate({ templateUuid: value })

  const errorsOnTemplate = combineErrors({ errors, field: 'template', uuid: landingPage.uuid })

  return (
    <div className={ className }>
      { !landingPage.custom && !landingPage.portal && (
        <ActionBar>
          <label>{ t`Select template` }</label>
          <InputWrapper error={ errorsOnTemplate }>
            <TemplatesSelect value={ landingPage.template } onChange={ handleTemplateChange }/>
          </InputWrapper>
          <Button disabled={ !landingPage.template } onClick={ onPreview }>
            { t`See preview` }
          </Button>
        </ActionBar>
      )}

      { landingPage.portal && (
        <>
          <Button onClick={ onPreview }>
            { t`See preview` }
          </Button>
          <Button
            onClick={ onPortalEditContent }
            data-test-id='edit-landing-page-content-button' >
            { t`Edit landing page content` }
          </Button>
        </>
      )}

      { !landingPage.portal && (
        <Preview landingPage={ landingPage } errors={ errors }/>
      )}
    </div>
  )
}

RightColumn.propTypes = {
  landingPage: landingPageType.isRequired,
  onPortalEditContent: func.isRequired, 
  onPreview: func.isRequired,
  onUpdate: func.isRequired,
  errors: array
}

RightColumn.defaultProps = {
  errors: []
}

const InputWrapper = styled(ValidatableWrapper)`
  flex-grow: 1;
`

const ActionBar = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 1rem;

  label {
    line-height: 2rem;
    margin: 0;
  }

  ${Button} {
    display: block;
    margin-left: auto;
    height: 2rem;
  }

  > :not(:last-child) {
    margin-right: 1rem;
  }
`

export default styled(RightColumn)`
  display: flex;
  flex-direction: column;
  width: 30rem;
`

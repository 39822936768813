import React from 'react'
import { bool } from 'prop-types'
import { t } from 'ttag'

import { canRead } from 'shared/helpers/auth'
import productType from 'shared/types/productType'
import ProductDetailsPanel from 'shared/components/ProductDetails'
import Card from 'shared/components/ui/Card'
import Header from 'shared/components/ui/Card/StaticHeader'

const productTranslations = {
  Project: t`Project`,
  Stage: t`Stage`
}

const ProductDetails = ({ collapsed, product, readOnly }) => {
  if (!canRead('stages')) return null

  const promotableType = (
    productTranslations[product.promotableType] || product.promotableType
  )

  return (
    <Card
      collapsible
      dataTestId="campaign-product-information-panel"
      className="mb-2"
      collapsed={ collapsed }
      header={ (
        <div className="d-flex justify-content-between">
          <Header className="align-self-center">
            {`${promotableType} ${t`information`}`}
          </Header>
        </div>
      ) }
    >
      <ProductDetailsPanel product={ product } readOnly={ readOnly }  />
    </Card>
  )
}

ProductDetails.propTypes = {
  product: productType.isRequired,
  collapsed: bool,
  readOnly: bool
}

ProductDetails.defaultProps = {
  collapsed: false,
  readOnly: false
}

export default ProductDetails

import React from 'react'
import { t } from 'ttag'

import Card from 'shared/components/ui/Card'

const CampaignCreationInProgress = () => (
  <Card
    padded={ false }
    header={ t`Thank you!` }>
    <p className='p-4 m-0'>
      {t`Your campaign is being created.`}
    </p>
  </Card>
)

export default CampaignCreationInProgress

import React, { useState, useEffect } from 'react'
import { t }                          from 'ttag'
import { object, func, bool }         from 'prop-types'
import { useQuery, useApolloClient }  from 'react-apollo'
import { toast }                      from 'react-toastify'
import styled                         from 'styled-components'

import { isUserAdmin }            from 'shared/helpers/auth'
import useUrlParams               from 'shared/hooks/useUrlParams'
import ValidatableTabs            from 'shared/components/ValidatableTabs'
import ContentContainer           from 'shared/components/CampaignInfo/ContentContainer'
import Card                       from 'shared/components/ui/Card'
import PrimaryButton              from 'shared/components/ui/PrimaryButton'
import finalizeCampaign           from 'graphql/mutations/campaign/finalizeCampaign'
import { lessThanOneHourFromNow } from 'shared/helpers/date'
import { buildFragmentParams }    from 'shared/helpers/graphql'
import campaignFragment           from 'graphql/fragments/campaign'
import campaignAssetAssignments   from 'graphql/queries/campaignAssetAssignments'

import detalizeErrorHandler from '../Campaign/detalizeErrorsHandler'

import buildValidator   from './validations/buildValidator'
import tabs             from './TabBar/tabs'

const allowedCompaniesNames = [
  'Eiendomsmegler Vest'
]

const PropertyCampaignInfo = ({
  className,
  campaign,
  validationMode,
  stopValidationMode,
  onChangeActiveTab
}) => {
  const client = useApolloClient()

  const campaignHasAssetAssignments = () => {
    return campaign.assetAssignments?.length !== 0
  }

  const campaignAssetAssignmentsData = useQuery(
    campaignAssetAssignments,
    {
      variables: { campaignUuid: campaign.uuid },
      fetchPolicy: 'network-only',
      skip: campaignHasAssetAssignments()
    }
  )

  useEffect(() => {
    if (campaignAssetAssignmentsData.data !== undefined) {
      updateCampaign(campaignAssetAssignmentsData.data, campaign.uuid)
    }
  }, [campaignAssetAssignmentsData.loading])

  const updateCampaign = (data, campaignUuid) => {
    const assetAssignments = data ? data.campaignAssetAssignments : []
    const fragmentParams = buildFragmentParams(
      { __typename: 'Campaign', uuid: campaignUuid },
      campaignFragment
    )
    const fragmentData = client.readFragment(fragmentParams)
    client.writeFragment({
      ...fragmentParams,
      data: {
        ...fragmentData,
        assetAssignments: assetAssignments
      }
    })
  }

  const [urlParams, setUrlParams] = useUrlParams()
  const initialTab = campaign.currentContentStep
  const [activeTab, setActiveTab] = useState(initialTab)
  const [errors, setErrors] = useState([])
  const [validatablePayload, setValidatablePayload] = useState()
  const [validationPending, setValidationPending] = useState()
  const [loadingTabContent, setLoadingTabContent] = useState(true)

  const handleSwitchActiveTab = (newTabNumber) => {
    delete urlParams.infoTab
    setLoadingTabContent(true)
    setUrlParams(urlParams)
    setActiveTab(newTabNumber)
    onChangeActiveTab(newTabNumber)
  }

  const ActiveTabContent = tabs[activeTab].component
  const validate = buildValidator({
    setValidationPending,
    setErrors,
    validatorNumber: activeTab
  })

  const refreshWholePage = () => {
    window.location.reload()
  }

  const onFinalize = async () => {
    if (lessThanOneHourFromNow(campaign.endDate)) {
      toast.error(t`End time must be at least 1 hour from now`)

      return
    }

    await client.mutate({
      mutation: finalizeCampaign,
      variables: { uuid: campaign.uuid }
    })
    refreshWholePage()
  }
  const finalActionName = tabs.length === (activeTab + 1) ? t`Finish` : t`Update`

  const allowedCompany = allowedCompaniesNames.some(
    allowedCompaniesName => campaign.product.company.name.startsWith(allowedCompaniesName)
  )

  const campaignPhaseDraft = campaign.phase === 'assembly'

  if (allowedCompany && campaignPhaseDraft && !isUserAdmin()) {
    return (
      <div className={ className }>
        <Card
          collapsible
          collapsed={ false }
          padded={ false }
          header={ t`Campaign Information` }
        >
          <p className='p-4 m-0'>
            {
              t`Your campaign is being created.
              The targeting and ads will appear here once it's ready.`
            }
          </p>
        </Card>
        <PrimaryButton
          disabled
          className='mt-2 mb-4 next-button'
          data-test-id='next-button'>
          { finalActionName }
        </PrimaryButton>
      </div>
    )
  }

  return (
    <>
      <ValidatableTabs
        collapsible
        detalizeErrors
        customDetalizeErrorsHandler={ detalizeErrorHandler }
        collapsed={ false }
        tabs={ tabs }
        title={ t`Campaign Information` }
        validate={ validate }
        validatablePayload={ validatablePayload }
        onFinalize={ onFinalize }
        publishingMode={ !!campaign.publishStartedAt }
        activeTab={ activeTab }
        setActiveTab={ handleSwitchActiveTab }
        validationPending={ validationPending }
        validationMode={ validationMode }
        stopValidationMode={ stopValidationMode }
        finalActionName={ finalActionName }
        loadingTabContent={ loadingTabContent }
      >
        <ContentContainer>
          <ActiveTabContent
            client={ client }
            campaign={ campaign }
            errors={ errors }
            validate={ validate }
            onLoading={ () => { setLoadingTabContent(true) } }
            onLoaded={ () => { setLoadingTabContent(false) } }
            setValidatablePayload={ setValidatablePayload }
            setValidationPending={ setValidationPending }
          />
        </ContentContainer>
      </ValidatableTabs>
    </>
  )
}

PropertyCampaignInfo.propTypes = {
  campaign: object.isRequired,
  onChangeActiveTab: func,
  stopValidationMode: func,
  validationMode: bool,
}

PropertyCampaignInfo.defaultProps = {
  onChangeActiveTab: () => {},
  stopValidationMode: undefined,
  validationMode: false
}

export default styled(PropertyCampaignInfo)`
  position: relative;

  .next-button {
    position: absolute
    right: 0
  }
`

import React        from 'react'
import { useQuery } from '@apollo/react-hooks'
import { string }   from 'prop-types'
import { toast }    from 'react-toastify'
import { t }        from 'ttag'

import projectQuery from './projectQuery'
import NewsList     from './NewsList'

const ProjectNewsEdit = ({ uuid }) => {
  const { data, refetch } = useQuery(projectQuery, {
    variables: { uuid }
  })

  const project = data?.project

  if (project) {
    if (project.product.company.enabledFeatures.includes('project_news')) {
      return (
        <NewsList
          newsPosts={ project.product.newsPosts }
          productUuid={ project.product.uuid }
          newsPageBaseUrl={ project.product.newsPageBaseUrl }
          onDelete={ refetch }
          onRefetch={ refetch }
        />
      )
    } else {
      toast.error(t`You are not allowed to edit project news`)
      return(<div />)
    }
  } else return(<div />)
}

ProjectNewsEdit.propTypes = {
  uuid: string.isRequired
}

export default ProjectNewsEdit

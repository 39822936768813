import { gql } from 'apollo-boost'

import productStakeholder from 'graphql/fragments/productStakeholder'

export default gql`
  mutation deleteProductStakeholder(
    $productUuid: ID!
    $uuid: ID!
  ) {
    deleteProductStakeholder(
      productUuid: $productUuid
      uuid: $uuid
    ) {
      stakeholder {
        ... ProductStakeholder
      }
    }
  }
  ${productStakeholder}
`

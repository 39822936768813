import { createGlobalStyle } from 'styled-components'

import Button from 'shared/components/ui/Button'
import fonts from 'shared/style/fonts'
import { textColor, labelColor, buttonTextColor } from 'shared/style/colors'

const Login = createGlobalStyle`
  ${fonts}

  html, body, #app {
    height: 100%
    min-height: 100%
  }
  
  * {
    transition: all .2s ease
  }
  
  h1 {
    font-size: 1.75rem;
    font-weight: 600;
    letter-spacing: 0.28px;
    color: ${textColor};
  }
  
  a {
    color: ${labelColor};
    font-size: .875rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  
  p {
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: 0.35px;
    color: ${labelColor};
  }
  
  ${Button} {
    color: ${buttonTextColor};
    font-size: .875rem;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    padding: .4rem;
    position: relative;
    text-align: center;
    
    .spinner-border {
      border-width: 2px
      height: 1rem;
      position: absolute;
      right: 1.25rem;
      top: 27%;
      width: 1rem;
    }
  }
  
  .form-label {
    color: ${buttonTextColor};
    font-size: .875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    margin-top: 1.5rem;
    margin-bottom: 1.2rem;
  }
  
  .form-control {
    background-color: #FFF
    border: solid 1px rgba(151, 151, 151, 0.56);
    font-size: .875rem;
    padding: 1.6rem 1.1rem;
    transition: all .2s ease;
    
    &:focus {
      outline: none
      box-shadow: none
      border-color: rgba(151, 151, 151, 0.85);
    }
    
    &.is-invalid {
      background-image: none

      &:focus {
        box-shadow: none
      }
    }
  }
`

export default Login


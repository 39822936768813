import { gql } from 'apollo-boost'

export default gql`
  query Campaigns($query: PageInput!) {
    campaigns(query: $query) {
      total
      items {
        uuid
        name
        phase
        logo {
          thumbnail
        }
        budget
        durationInDays
        daysSinceStart
        createdAt
        specification {
          goals
        }
        insights {
          leadsCount
          totalSpent
          totalImpressions
          totalClicks
          totalReach
          averageCpl
          averageCpc
          averageCtr
          cplTrend
          cpcTrend
          ctrTrend
        }
      }
    }
  }
`

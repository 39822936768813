import React from 'react'
import { t } from 'ttag'
import { shape, bool, string } from 'prop-types'
import { withApollo } from 'react-apollo'

import SubHeader from 'shared/components/ui/Card/SubHeader'
import Small from 'shared/components/ui/Small'
import Checkbox from 'shared/components/ui/Checkbox'
import apolloClientType from 'shared/types/apolloClientType'

import updateFacebookMarketableAudience from '../updateFacebookMarketableAudience'

const DynamicCreativeCheckbox = ({ audience, client }) => {
  const { uuid, platformAudience: { dynamicCreative } } = audience

  const handleDynamicCreativeChange = ({ target }) => {
    updateFacebookMarketableAudience(client, uuid, { dynamicCreative: target.checked })
  }

  return (
    <>
      <SubHeader>{t`Dynamic creatives`}</SubHeader>
      <Checkbox
        disabled={ audience.creativeSetAudiences.length > 0 || audience.published }
        checked={ dynamicCreative }
        onChange={ handleDynamicCreativeChange }
        label={ 'For Dynamic Creatives' } />
      <Small>
        {t`This cannot be changed if the audience was published
        or if it as attached to a creative set`}
      </Small>
      <Small>
        {t`Dynamic creatives can only be attached to a single
        Facebook Dynamic Creative creative set`}
      </Small>
    </>
  )
}

DynamicCreativeCheckbox.propTypes = {
  audience: shape({
    uuid: string,
    platformAudience: shape({
      dynamicCreative: bool
    })
  }).isRequired,
  client: apolloClientType.isRequired
}

export default withApollo(DynamicCreativeCheckbox)

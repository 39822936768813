import React from 'react'
import { t } from 'ttag'
import { string } from 'prop-types'

import StaticHeader from 'shared/components/ui/Card/StaticHeader'

import AddUserButton from './AddUserButton'

const Header = ({ companyUuid }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <StaticHeader>
        { t`Users` }
      </StaticHeader>

      <AddUserButton companyUuid={ companyUuid } />
    </div>
  )
}

Header.propTypes = {
  companyUuid: string.isRequired
}

export default Header

import React, { createRef } from 'react'
import { get } from 'lodash'
import { any, func, bool } from 'prop-types'
import { Form } from 'react-bootstrap'
import { Beforeunload } from 'react-beforeunload'
import NumberFormat from 'react-number-format'

const handleFocus = (event) => event.target.select()

const BigNumberInput = (props) => {
  const {
    value,
    defaultValue,
    onUpdate,
    setTouched,
    isInvalid,
    autoSelect,
    ...restProps
  } = props
  const inputRef = createRef()

  const handleUpdate = () => {
    setTouched && setTouched(true)
    const currentState = get(inputRef, 'current.state', {})
    const newValue = currentState.numAsString
    if (newValue === undefined || String(value) === newValue) { return }
    onUpdate(newValue)
  }

  const valueProps = defaultValue !== undefined ? (
    { defaultValue }
  ) : (
    { value }
  )

  return (
    <Beforeunload onBeforeunload={ handleUpdate }>
      <NumberFormat
        customInput={ Form.Control }
        thousandSeparator={ ' ' }
        ref={ inputRef }
        onBlur={ handleUpdate }
        onFocus={ autoSelect ? handleFocus : undefined }
        isInvalid={ isInvalid }
        { ...valueProps }
        { ...restProps }
      />
    </Beforeunload>
  )
}

BigNumberInput.propTypes = {
  autoSelect: bool,
  defaultValue: any,
  isInvalid: bool,
  onUpdate: (props, propName, componentName) => {
    if (props.disabled) {
      return
    }

    if (typeof props[propName] !== 'function') {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Function required.`
      )
    }
  },
  setTouched: func,
  value: any
}

BigNumberInput.defaultProps = {
  autoSelect: false,
  defaultValue: undefined,
  isInvalid: false,
  onUpdate: null,
  setTouched: undefined,
  value: undefined,
}

export default BigNumberInput

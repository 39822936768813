import { arrayOf, number, string, shape, array } from 'prop-types'

import assetType from 'shared/types/assetType'

export default shape({
  assetAssignments: arrayOf(shape({ asset: assetType, uuid: string })),
  name: string.isRequired,
  building_types: arrayOf(string),
  buildingsCount: number,
  completeStages: array,
  unitsCount: number,
  unitsSold: number,
  percentageSold: number,
  lowerPrice: number,
  upperPrice: number,
  lowerSizeM2: number,
  upperSizeM2: number,
  image: assetType,
  createdAt: string,
  customerName: string,
  stagesCount: number,
  activeCampaignsCount: number,
  totalCampaignsCount: number
})

import { t } from 'ttag'
import React from 'react'
import { IconWarning } from 'cabanaico'

import Tooltip from 'shared/components/ui/Tooltip'

import { localDate } from '../../../helpers/date'

import buyerTypeMap from './buyerTypeMap'

const openDocumentFormatted = (_cell, { signedDocumentUrl }) =>
  <a href={ signedDocumentUrl } target='_blank' rel='noopener noreferrer'>{t`View Offer`}</a>

const conditionsFormatter = (_cell, { conditions }) =>
  conditions && <Tooltip content={ conditions }><span><IconWarning/></span></Tooltip>

export default [{
  dataField: 'buyer.name',
  text: t`Name`,
  sort: true
},
{
  dataField: 'conditions',
  text: t`Conditions`,
  sort: true,
  formatter: conditionsFormatter
},
{
  dataField: 'unitId',
  text: t`Unit ID`,
  sort: true
},
{
  dataField: 'altUnitsCount',
  text: t`Alt. Units`,
  sort: true
},
{
  dataField: 'buyer.type',
  text: t`Buyer type`,
  sort: true,
  formatter: (cell, _row) => buyerTypeMap[cell]
},
{
  dataField: 'receivedAt',
  text: t`Date Received`,
  sort: true,
  formatter: localDate
},
{
  dataField: 'expiresAt',
  text: t`Expiration Date`,
  sort: true,
  formatter: localDate
},
{
  text: '',
  dataField: 'actions',
  formatter: openDocumentFormatted
}]

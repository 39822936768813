import { concat, filter, includes, map, reduce, uniq } from 'lodash'

export const MAXIMUM_AMOUNT_OF_ADS_PER_AUDIENCE = 45

export default ({ audiences, creativeSets, campaign }) => {
  const presentAudiences = audiences.filter((audience) => {
    return !audience.deleted
  })

  const manyAdsAudiences = []
  const manyAdsAudiencesNames = []
  creativeSets.forEach((creativeSet) => {
    if (creativeSet.channel.type === 'facebook' || creativeSet.channel.type === 'instagram') {
      creativeSet.creativeSetAudiences.forEach((creativeSetAudience) => {
        if (creativeSetAudience.audience.adsCount > MAXIMUM_AMOUNT_OF_ADS_PER_AUDIENCE) {
          manyAdsAudiences.push(creativeSetAudience.audience)
          manyAdsAudiencesNames.push(creativeSetAudience.audience.name)
        }
      })
    }

    creativeSet.channel.belongsToPropertyCampaign = !!campaign.property
  })

  const assignedAudiences = reduce(
    creativeSets,
    (acc, creativeSet) => {
      const creativeSetAudiences = creativeSet.creativeSetAudiences.filter((element) => {
        return !element.audience.deleted
      })
      return concat(
        acc,
        creativeSetAudiences.map((creativeSetAudience) => { return creativeSetAudience.audience })
      )
    },
    []
  )
  const assignedAudiencesIds = map(assignedAudiences, (audience) => audience.uuid)

  const unassignedAudiences = filter(
    presentAudiences,
    (audience) => !includes(assignedAudiencesIds, audience.uuid)
  )

  return creativeSets.map((creativeSet) => ({
    ...creativeSet,
    unassignedAudiences: unassignedAudiences,
    manyAdsAudiences: manyAdsAudiences,
    manyAdsAudiencesNames: uniq(manyAdsAudiencesNames).join(', '),
    campaign: campaign
  }))
}

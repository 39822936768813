import { gql } from 'apollo-boost'

export default gql`
  query CampaignAnalytics(
    $campaignUuid: ID!,
    $dataName: String!
  ) {
    campaignAnalytics(
      campaignUuid: $campaignUuid,
      dataName: $dataName
    ) {
      data {
        date
        value
      }
    }
  }
`

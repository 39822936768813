import React       from 'react'
import { IconAdd } from 'cabanaico'
import { t }       from 'ttag'
import styled      from 'styled-components'

import Button from 'shared/components/ui/Button'

const NewItemButton = (props) => {
  return (
    <Button { ...props } type="button" data-test-id='new-unit-button'>
      <IconAdd />
      { t`Add new unit` }
    </Button>
  )
}

export default styled(NewItemButton)`
  font-size: 0.875rem
  font-weight: 500
  margin-left: 1rem;
  margin-right: 1rem;
  padding: .3rem .5rem
`

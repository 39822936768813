import { gql } from 'apollo-boost'

import user from 'graphql/fragments/user'

export default gql`
  mutation toggleLockUser($uuid: ID!) {
    toggleLockUser(uuid: $uuid){
      user {
        ... User
      }
    }
  }
  ${user}
`

import React from 'react'
import { array, string, func } from 'prop-types'
import { useMutation } from 'react-apollo'
import { t } from 'ttag'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import { Formik } from 'formik'

import Modal from 'shared/components/ui/Modal'
import Button from 'shared/components/ui/Button'
import updateAssetMutation from 'graphql/mutations/assetable/updateAsset'

const EditAssetModal = ({
  assets,
  assetUuid,
  handleModalClose,
  className
}) => {
  const prepareInitialValues = () => {
    if (assetUuid) {
      const asset = assets.find(val => val.uuid === assetUuid)
      return{
        title: asset.title || '',
        altText: asset.altText || '',
        description: asset.description || ''
      }
    }
    return {
      title: '',
      altText: '',
      description: ''
    }
  }
  const initialValues = prepareInitialValues()
  const [runUpdateAssetMutation] = useMutation(updateAssetMutation)
  const saveValues = (values) => {
    handleModalClose()

    return runUpdateAssetMutation({
      variables: { uuid: assetUuid, input: values }
    })
  }

  return (
    <Formik initialValues={ initialValues } onSubmit={ saveValues }>
      { ({ handleSubmit, values, handleChange, handleBlur }) => (
        <Form className={ className }>
          <Modal show={ !!assetUuid } onHide={ handleModalClose } className={ className }>
            <Modal.Header closeButton>
              <Modal.Title>{ t`Update asset information` }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>{ t`Title`}</Form.Label>
                <Form.Control
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  name='title'
                  type="text"
                  value={ values.title  } />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>{ t`Alternative text` }</Form.Label>
                <Form.Control
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  name='altText'
                  type="text"
                  value={ values.altText } />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{ t`Description` }</Form.Label>
                <Form.Control
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  name='description'
                  as="textarea"
                  value={ values.description } rows={ 3 } />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={ handleModalClose }>
                { t`Close` }
              </Button>
              <Button variant='primary' type="submit" onClick={ handleSubmit }>
                { t`Save` }
              </Button>
            </Modal.Footer>
          </Modal>
        </Form> ) }
    </Formik>
  )
}

EditAssetModal.propTypes = {
  assets: array.isRequired,
  handleModalClose: func.isRequired,
  assetUuid: string
}

EditAssetModal.defaultProps = {
  assetUuid: null
}

export default styled(EditAssetModal)`
  input, textarea {
    box-shadow: none;
  }
`

import React from 'react'
import { node, object } from 'prop-types'
import { get } from 'lodash'

import campaignType from 'shared/types/campaignType'
import Card from 'shared/components/ui/Card'

import PhaseBadge from '../CampaignEdit/Header/PhaseBadge'

import CampaignName from './CampaignName'
import Image from './Image'
import Footer from './Footer'

const CampaignSummaryContext = React.createContext({
  campaignUuid: undefined, projectUuid: undefined
})

const CampaignSummary = ({ campaign, children, footerComponents }) => {
  const firstAssetSrc = get(campaign, 'assetAssignments[0].asset.media')
  const customPhases = ['archived']

  return (
    <CampaignSummaryContext.Provider value={{
      campaignUuid: campaign.uuid, projectUuid: campaign.project?.uuid
    }}>
      <Card
        className="mb-2"
        footer={ <Footer campaign={ campaign } components={ footerComponents } /> }
      >
        <Image src={ firstAssetSrc } campaign={ campaign }>
          {
            children ? (
              children
            ) : (
              <>
                <CampaignName>{ campaign.name }</CampaignName>
                { customPhases.includes(campaign.phase) && <PhaseBadge campaign={ campaign } /> }
              </>
            )
          }
        </Image>
      </Card>
    </CampaignSummaryContext.Provider>
  )
}

CampaignSummary.propTypes = {
  campaign: campaignType.isRequired,
  children: node,
  footerComponents: object
}

CampaignSummary.defaultProps = {
  children: null,
  footerComponents: {}
}

export { CampaignSummaryContext }

export default CampaignSummary

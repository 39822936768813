import { gql } from 'apollo-boost'

export default gql`
  query DashboardAnalyticsLeadsStats (
    $durationType:  String!,
    $durationValue: String!,
    $projectUuid:   String
  ) {
    dashboardAnalyticsLeadsStats(
      durationType:  $durationType, 
      durationValue: $durationValue, 
      projectUuid:   $projectUuid
    ) {
      leadsStats {
        value
        increment
      }
    }
  }
`

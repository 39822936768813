import gql from 'graphql-tag'

export default gql`
  fragment FacebookPlatformAudience on PlatformAudience {
    ... on Facebook {
      uuid
      ageFrom
      ageTo
      gender
      platform
      dynamicCreative
      updatedAt
      facebookPositions
      instagramPositions
      audienceNetworkPositions
      messengerPositions
      customAudiencesInclude {
        id
        name
        subtype
      }
      customAudiencesExclude {
        id
        name
        subtype
      }
      detailedTargetingInclude {
        id
        name
        type
      }
      detailedTargetingExclude {
        id
        name
        type
      }
      detailedTargetingAtLeastOne {
        id
        name
        type
      }
      languageTargeting {
        id
        name
      }
    }
  }
`

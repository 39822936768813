import React      from 'react'
import { string } from 'prop-types'
import styled     from 'styled-components'

import getImageUrl         from 'shared/helpers/getImageUrl'
import { getHumanUrlHost } from 'shared/helpers/url'
import { altoGreyColor, textColorLight, textGreyColor, textHeaderColor } from 'shared/style/colors'

import AdHeader from './AdHeader'

const PortalPreview = (props) => {
  const {
    title,
    content,
    facebookPageLogo,
    facebookPageName,
    description,
    domain,
    previewAsset,
    className
  } = props

  return (
    <div className={ className }>
      <AdHeader
        content={ content }
        logo={ facebookPageLogo }
        facebookPageName={ facebookPageName }
      />

      <div className="card">
        { previewAsset && (
          <img src={ getImageUrl(previewAsset) } alt="image" className="image" />
        ) }
        <div className="content" >
          <h4 className="title">
            { title }
          </h4>
          <div className="subtitle">
            { description }
          </div>
          <a className="domain" href={ domain }>
            { getHumanUrlHost(domain) }
          </a>
        </div>
      </div>
    </div>
  )
}

PortalPreview.propTypes = {
  content: string,
  description: string,
  domain: string,
  facebookPageLogo: string,
  facebookPageName: string,
  previewAsset: string,
  title: string,
}

PortalPreview.defaultProps = {
  content: '',
  description: '',
  domain: '',
  facebookPageLogo: '',
  facebookPageName: '',
  previewAsset: null,
  title: ''
}

export default styled(PortalPreview)`
  border: solid 2px ${altoGreyColor};
  border-radius: 5px;
  padding: .9rem;

  .card {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px rgba(151, 151, 151, 0.3);
    border-radius: 4px
    overflow: hidden;
  }

  .image {
    width: 100%
  }

  .content {
    padding: 10px

    .title {
      color: ${textHeaderColor}
      font-weight: bold
      font-size: 1rem
      line-height: 1
      letter-spacing: 0.25px
      margin-bottom: 0.35rem
    }

    .subtitle {
      color: ${textColorLight}
      font-size: 0.75rem
      letter-spacing: 0.25px
      margin-bottom: 0.25rem
    }

    .domain {
      color: ${textGreyColor}
      font-size: 0.7rem
      font-family: Helvetica
    }
  }
`

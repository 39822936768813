import gql from 'graphql-tag'

import stageUnitsList from 'graphql/fragments/stageUnitsList'
import stageSalesArgument from 'graphql/fragments/stageSalesArgument'
import showing from 'graphql/fragments/showing'
import snapshot from 'graphql/fragments/snapshot'

export default gql`
  fragment Stage on Stage {
    uuid
    name
    projectName
    projectUuid
    attractiveness
    buildingTypes
    unitsCount
    unitsSold
    lowerSizeM2
    upperSizeM2
    lowerPrice
    upperPrice
    priceRangeIsSubjectToChange
    sizeRangeIsSubjectToChange
    missingData
    salesStartAt
    saleState
    portalEnabled
    ... StageUnits
    location {
      address
      city
      countryCode
      zipCode
      latitude
      longitude
    }
    salesArguments {
      ... StageSalesArgument
    }
    showings {
      ... Showing
    }
    currentSnapshotRecord {
      ... Snapshot
    }
  }
  ${stageUnitsList}
  ${stageSalesArgument}
  ${showing}
  ${snapshot}
`

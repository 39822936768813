import { gql } from 'apollo-boost'

import DashboardDataset from 'graphql/fragments/dashboardDataset'

export default gql`
  query ProjectSalesLeadsAnalytics($uuid: ID!, $scopedTo: String!, $periodName: String) {
    projectSalesLeadsAnalytics(uuid: $uuid, scopedTo: $scopedTo, periodName: $periodName) {
      totalLeads {
        ... DashboardDataset
      }
    }
  }
  ${DashboardDataset}
`

import { get }      from 'lodash'
import { useQuery } from 'react-apollo'

import platformsQuery from 'graphql/queries/platform'

export default function useChannelTypes() {
  const { data } = useQuery(platformsQuery)

  return get(data, '__type.enumValues', []).map(({ name }) => name)
}

import browserHistory from 'browserHistory'
import { loginPathFrom, userRolePath } from 'routes/AppRedirect'

export default function redirectTo(path, state) {
  const redirectPath = ['/', '/admin'].includes(path) ? path : userRolePath(path)

  browserHistory.push(redirectPath, state)
}

export function redirectToLogin() {
  redirectTo(loginPathFrom(browserHistory.location.pathname))
}

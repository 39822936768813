import React      from 'react'
import styled     from 'styled-components'
import { t }      from 'ttag'
import { Button } from 'react-bootstrap'

import { logout } from 'shared/helpers/auth'
import { formInputShadow } from 'shared/style/placeholders'

const SignOutButton = ({ className }) => {
  return (
    <Button
      className={ className }
      onClick={ logout }
      data-test-id="sign-out-button"
    >
      { t`Sign out` }
    </Button>
  )
}

export default styled(SignOutButton)`
  ${formInputShadow}
  font-size: .875rem
  padding: 0.25rem 1.75rem
  white-space: no-wrap
`

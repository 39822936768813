import React, { useCallback } from 'react'
import { t }                  from 'ttag'
import { func, string }       from 'prop-types'
import Select                 from 'react-select'

import { availableLocales }  from 'locale'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const options = availableLocales.map(({ key, label }) => ({
  label,
  value: key,
}))

const LanguageSelect = ({ value, onChange, name, ...props }) => {
  const setFieldValue = useCallback(({ value }) => {
    onChange({ target: { name, value } })
  }, [])

  return (
    <Select
      isSearchable={ false }
      value={ options.find((option) => (option.value === value)) }
      options={ options }
      styles={ condensedSelectStyles }
      onChange={ setFieldValue }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

LanguageSelect.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  value: string
}

LanguageSelect.defaultProps = {
  value: undefined
}

export default LanguageSelect

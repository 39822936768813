import React from 'react'
import { string } from 'prop-types'

import ResourceQuery from 'shared/components/ResourceQuery'
import stageQuery from 'graphql/queries/stageWithSnapshots'

import Header from './Header'
import StageCard from './StageCard'
import NextButton from './NextButton'

const MissingData = ({ className, stageUuid, ...props }) => {
  return (
    <div className={ className }>
      <ResourceQuery
        query={ stageQuery }
        variables={{ uuid: stageUuid }}
        fetchPolicy='cache-first'
      >
        {stage => (
          <>
            <Header stage={ stage } />
            <StageCard stage={ stage } { ...props } />
            <NextButton stage={ stage } />
          </>
        )}
      </ResourceQuery>
    </div>
  )
}

MissingData.propTypes = {
  stageUuid: string.isRequired
}

export default MissingData

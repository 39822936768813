import { t } from 'ttag'

import Details from '../Details'
import Units   from '../Units'
import Media   from '../Media'

export default [
  {
    title: t`Building details`,
    component: Details
  },
  {
    title: t`Unit information`,
    component: Units
  },
  {
    title: t`Media`,
    component: Media
  }
]

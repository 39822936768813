import React from 'react'
import { string } from 'prop-types'

import agentType from 'shared/types/agentType'
import projectType from 'shared/types/projectType'

import Image    from './Image'
import Uploader from './Uploader'

const AgentImage = ({ agent, project, label, mediaType }) => {
  return (
    <>
      <Image agent={ agent } label={ label } mediaType={ mediaType } />
      <Uploader agent={ agent } project={ project } label={ label } mediaType={ mediaType } />
    </>
  )
}

AgentImage.propTypes = {
  agent: agentType.isRequired,
  label: string.isRequired,
  mediaType: string.isRequired,
  project: projectType.isRequired
}

export default AgentImage

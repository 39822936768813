import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { string, bool, node, oneOfType } from 'prop-types'

import { iconsGreyColor } from 'shared/style/colors'
import { formInputShadow } from 'shared/style/placeholders'

const Checkbox = forwardRef(({ label, shadow, size, ...props }, ref) => (
  <Label shadow={ shadow } className={ `checkbox-${size}` }>
    <input type="checkbox" ref={ ref } { ...props } />
    <span>{ label }</span>
  </Label>
))

const Label = styled.label`
  color: ${iconsGreyColor};
  display: block;
  font-size: 0.85rem;
  font-weight: normal;
  margin: 0;

  input {
    opacity: 0;
    pointer-events: none
    position: absolute;
  }

  [type="checkbox"] + span {
    cursor: pointer;
    display: block;
    line-height:  1.5625rem;
    padding-left: 1.8rem;
    position: relative;
    user-select: none
  }
  
  [type="checkbox"][disabled] + span {
    cursor: default
  }

  input + span:before,
  input + span:after {
    border-radius: 4px;
    border: 2px solid #dadada;
    content: '';
    height: 1.25rem;
    left: 0;
    margin-top: 0.1875rem;
    position: absolute;
    top: 0;
    transition: .2s
    width: 1.25rem;
  }

  input:checked + span:before {
    border-bottom: 2px solid #2578ce;
    border-left: 2px solid transparent;
    border-radius: 1px;
    border-right: 2px solid #2578ce;
    border-top: 2px solid transparent;
    height: .6875rem;
    left: 0.125rem;
    top: 0.1875rem;
    transform-origin: 100% 100%;
    transform: rotateZ(37deg);
    width: .5rem;
  }

  input:indeterminate + span:before {
    border-bottom: none;
    border-left: none;
    border-radius: 0;
    border-right: 2px solid #2578ce;
    border-top: none;
    height: 0.625rem;
    left: 0.3125rem;
    top: 0.0625rem;
    transform: rotate(90deg);
    width: 0.625rem;
  }

  input:checked + span:after,
  input:indeterminate + span:after {
    ${props => props.shadow ? formInputShadow : ''}
    border-color: #2578ce;
    content: '';
    height: 1.25rem;
    position: absolute;
    top: 0;
    width: 1.25rem;
  }

  input:disabled + span:after,
  input:disabled + span:before {
    opacity: 0.5
  }

  &.checkbox-sm {
    font-size: 0.625rem;

    [type="checkbox"] + span {
      line-height: 1.2rem;
      padding-left: 1.2rem;
    }

    input + span:before,
    input + span:after {
      height: 0.875rem;
      width: 0.875rem;
    }

    input:checked + span:before {
      height: .4rem;
      width: .3rem;
    }

    input:checked + span:after,
    input:indeterminate + span:after {
      height: 0.875rem;
      width: 0.875rem;
    }
  }
`

Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = {
  disabled: bool,
  label: oneOfType([string, node]),
  shadow: bool,
  size: string
}

Checkbox.defaultProps ={
  disabled: false,
  label: <>&nbsp;</>,
  shadow: true,
  size: 'md',
}

export default Checkbox

import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'
import { useApolloClient } from '@apollo/react-hooks'
import { shape, array, func } from 'prop-types'
import { orderBy } from 'lodash'

import NewItemButton from 'shared/components/ui/NewItemButton'
import stageType from 'shared/types/stageType'

import Item from './Item'
import createSalesArgument from './createSalesArgument'
import updateSalesArgument from './updateSalesArgument'
import deleteSalesArgument from './deleteSalesArgument'
import copyPreviousSalesArguments from './copyPreviousSalesArguments'

const SalesArguments = ({ product: { promotable: stage }, errors, validate }) => {
  const client = useApolloClient()
  const salesArguments = orderBy(stage.salesArguments, 'createdAt')

  const handleAddClick = () => createSalesArgument(client, stage)
  const handleCopyPreviousClick = () => copyPreviousSalesArguments(client, stage)
  const handleDelete = salesArgument => deleteSalesArgument(client, stage, salesArgument)
  const handleUpdate = (salesArgument, input) =>
    updateSalesArgument(client, salesArgument, input).
      then(({ data: { updateStageSalesArgument: { salesArgument } } }) => {
        if (!validate) { return }
        validate({ updated: salesArgument })
      })

  return (
    <Container>
      <ul>
        {salesArguments.map((argument, idx) =>
          <Item
            key={ argument.uuid }
            idx={ idx + 1 }
            stage={ stage }
            argument={ argument }
            handleDelete={ handleDelete }
            handleUpdate={ handleUpdate }
            errors={ errors }
          />
        )}
      </ul>

      <NewItemButton
        className="ml-5"
        onClick={ handleAddClick }
        data-test-id='new-sales-argument-button'
      >
        { t`Add new sales argument` }
      </NewItemButton>

      <NewItemButton className="ml-0" onClick={ handleCopyPreviousClick } displayIcon={ false }>
        { t`Import sales arguments from previous stage(s)` }
      </NewItemButton>
    </Container>
  )
}

const Container = styled.div`
  ul {
    padding: 1rem 0;
    margin: 0;
  }
`

SalesArguments.propTypes = {
  product: shape({
    promotable: stageType.isRequired
  }).isRequired,
  errors: array,
  validate: func
}

SalesArguments.defaultProps = {
  errors: [],
  validate: undefined
}

export default SalesArguments

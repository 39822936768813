import React from 'react'
import { components } from 'react-select'
import { string, any, shape } from 'prop-types'
import styled from 'styled-components'

import { LocationOperatorIcon } from 'shared/components/ui/icons'

const ValueContainer = props => {
  return (
    <components.ValueContainer
      { ... props }
    >
      <OperatorIconContainer>
        <LocationOperatorIcon operator={ props.selectProps.operator } />
      </OperatorIconContainer>
      { props.children }
    </components.ValueContainer>
  )
}

const OperatorIconContainer = styled.div`
  position: relative
  left: -0.9rem
  margin-top: 0.3rem
  margin-right: 0.25rem
`

ValueContainer.propTypes = {
  children: any.isRequired,
  selectProps: shape({
    operator: string
  }).isRequired
}

export default ValueContainer

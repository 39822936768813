import styled from 'styled-components'

import Button from 'shared/components/ui/Button'
import { textColor } from 'shared/style/colors'

const AssetWrapper = styled.div`
  border-radius: 8px;
  border: solid 2px #e1e1e1;
  border-style: ${props => props.isOver ? 'dashed' : 'solid'};
  overflow: hidden;
`

export const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
`

export const FormContainer = styled.div`
  width: 44rem;

  textarea {
    resize: none;
  }

  input, textarea {
    box-shadow: none;
  }
`

export const MediaContainer = styled.div`
  width: 24rem;
`

export const Hr = styled.hr`
  margin-left: -1.25rem;
  margin-right: -1.25rem;
`

export const SectionHeader = styled.h2`
  color: ${textColor};
  font-size: 1.125rem;
  font-weight: 600;
`

export const FinishButton = styled(Button)`
  display: block;
  margin-left: auto;
`

export const SalesArgumentsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    flex-basis: 0;
    flex-shrink: 0;
    flex-grow: 1;

    margin-right: 1.5rem;
  }

  > div:last-child {
    margin-right: 0;
  }
`

export const PricesContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    flex-basis: 0;
    flex-shrink: 0;
    flex-grow: 1;

    margin-right: 1.5rem;
  }

  > div:last-child {
    margin-right: 0;
  }
`

export const AgentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  clear: both;
  margin-top: 1.2rem;
`

export const DescriptionContainer = styled.div`
  display: flex;

  textarea {
    height: 6.5rem;
    margin-right: 1rem;
  }
`

export const DescriptionAssetWrapper = styled(AssetWrapper)`
  flex-shrink: 0;
  height: 6.5rem;
  width: 10.5rem;
  margin-left: 1.4rem;
`

export const GalleryImagesContainer = styled.div`
  display: grid;
  grid-gap: .75rem;
  grid-template-columns: repeat(4, calc(100%/4 - .59rem));
`

export const GalleryAssetWrapper = AssetWrapper

export const HeaderContainer = styled.div`
  display: flex;

  > :not(:last-child) {
    margin-right: 0.875rem;
  }
`

export const HeaderImageWrapper = styled(AssetWrapper)`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  height: 9.5rem;
  width: 29rem;
`

export const LogoContainer = styled.div`
  flex-grow: 1;
  width: 11rem;

  ${Button} {
    font-size: 0.875rem;
    width: 100%;
  }
`

export const LogoWrapper = styled(AssetWrapper)`
  margin-bottom: 0.5rem;
  height: 6.75rem;
`

import React        from 'react'
import { useQuery } from '@apollo/react-hooks'
import { t }        from 'ttag'
import { string, func, number } from 'prop-types'

import Select       from 'shared/components/ui/ThriftyInput/TouchableSelect'
import { redColor } from 'shared/style/colors'

import projectBuildingsQuery from './queries/projectBuildingsQuery'

const styles = {
  container: provided => ({
    ...provided,
    minWidth: '9rem'
  }),
  control: (provided, { selectProps }) => ({
    ...provided,
    borderColor: selectProps.isInvalid ? redColor : provided.borderColor
  })
}

const BuildingSelect = ({ value, onUpdate, uuid, projectUuid, ...props }) => {
  const handleChange = ({ value: buildingId }) => {
    return onUpdate({ uuid, attributes: { buildingId: parseInt(buildingId) } })
  }

  const { data, loading } = useQuery(
    projectBuildingsQuery, { variables: { projectUuid: projectUuid } }
  )
  if (loading) return null

  const options = data.buildings.map((building) => {
    return { value: building.id, label: building.name }
  })

  return (
    <Select
      value={ options.find((option) => (parseInt(option.value) === value)) }
      options={ options }
      onChange={ handleChange }
      styles={ styles }
      data-test-id='building-select'
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

BuildingSelect.propTypes = {
  onUpdate: func.isRequired,
  projectUuid: string.isRequired,
  uuid: string.isRequired,
  value: number
}

BuildingSelect.defaultProps = {
  value: undefined
}

export default BuildingSelect

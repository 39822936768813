import { gql } from 'apollo-boost'

export default gql`
  mutation removeSnapshotUnitSet($uuid: ID!) {
    removeSnapshotUnitSet(uuid: $uuid) {
      unitSet {
        uuid
      }
    }
  }
`

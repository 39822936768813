import { gql } from 'apollo-boost'

import facility from 'graphql/fragments/facility'

export default gql`
  mutation updateFacilityAssignments(
    $facilitiesUuid: [ID!]!
    $facilityableUuid: ID!
    $facilityableType: FacilityableEnumType!
  ) {
    updateFacilityAssignments(
      facilitiesUuid: $facilitiesUuid
      facilityableUuid: $facilityableUuid
      facilityableType: $facilityableType
    ) {
      facilities {
        ... Facility
      }
    }
  }
${facility}
`

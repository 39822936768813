import React   from 'react'
import { map } from 'lodash'
import { arrayOf, string, func } from 'prop-types'

import MultiSelect           from 'shared/components/ui/MultiSelect/MultiSelect'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import options               from 'shared/constants/buildingTypes'

import ValueContainer from './ValueContainer'
import Option         from './Option'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1,
    maxWidth: '18.4rem'
  }),
  option: provided => ({
    ...condensedSelectStyles.option(provided),
    backgroundColor: 'white',
    padding: '0.125rem 0.75rem'
  }),
  valueContainer: provided => ({
    ...condensedSelectStyles.valueContainer(provided),
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }),
  menu: provided => ({
    ...provided,
    zIndex: 3
  })
}

const BuildingTypesSelect = ({ value, setTouched, onChange, ...props }) => {
  const selectedOptions = (value || []).map(selected =>
    options.find(({ value }) => value === selected)
  )

  const handleChange = (values) => onChange(map(values, 'value'))
  const handleBlur = () => setTouched && setTouched(true)

  return (
    <MultiSelect
      options={ options }
      value={ selectedOptions }
      styles={ styles }
      components={{ ValueContainer, Option }}
      hideSelectedOptions={ false }
      onInputChange={ handleBlur }
      onChange={ handleChange }
      { ...props }
    />
  )
}

BuildingTypesSelect.propTypes = {
  onChange: func.isRequired,
  setTouched: func,
  value: arrayOf(string)
}

BuildingTypesSelect.defaultProps = {
  value: [],
  setTouched: undefined
}

export default BuildingTypesSelect

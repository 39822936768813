import { gql } from 'apollo-boost'

export default gql`
  query Company($uuid: ID!) {
    company(uuid: $uuid) {
      uuid
      name
      email
      source
      phoneNumber
      childrenCount
      availableLocalesAndCountryCodes
      users {
        uuid
        name
        email
        phoneNumber {
          fullNumber
        }
        lastSignInAt
        currentSignInAt
      }
      logo {
        thumbnail
      }
      topParent {
        uuid
        name
      }
      location {
        uuid
        address
      }
    }
  }
`

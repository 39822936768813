import { gql } from 'apollo-boost'

import notificationEvents from 'graphql/fragments/notificationEvents'

export default gql`
  query user($uuid: ID!) {
    user(uuid: $uuid) {
      uuid
      email
      name
      avatars {
        createdAt
        url
      }
      settings {
        timezone
        defaultLanguage
        notifications {
          app {
            ...NotificationEvents
          }
          email {
            ...NotificationEvents
          }
        }
      }
      phoneNumber {
        code
        number
      }
      roles {
        roleName
      }
      companies {
        uuid
        name
        location {
          city
        }
      }
    }
  }
  ${notificationEvents}
`

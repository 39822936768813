import updateStakeholderMutation
  from 'graphql/mutations/productStakeholders/updateStakeholderMutation'

const updateStakeholder = (client, productUuid, uuid, attributes) => (
  client.mutate({
    mutation: updateStakeholderMutation,
    variables: { productUuid, uuid, attributes }
  })
)

export default updateStakeholder

import React, { useState } from 'react'
import { array, func, object, shape, number } from 'prop-types'
import { t }     from 'ttag'
import styled    from 'styled-components'
import { toast } from 'react-toastify'

import useUpdateUser            from 'shared/hooks/useUpdateUser'
import useDeleteUser            from 'shared/hooks/useDeleteUser'
import useToggleLockUser        from 'shared/hooks/useToggleLockUser'
import useSendConfirmationEmail from 'shared/hooks/useSendConfirmationEmail'
import ItemsList                from 'shared/components/ItemsList'
import UserFormModal            from 'shared/components/UserForm/Modal'
import { dateTime }             from 'shared/components/ui/Table/formatters'

import SendConfirmationEmailButton from './actions/SendConfirmationEmailButton'
import ToggleLockButton            from './actions/ToggleLockButton'
import EditButton                  from './actions/EditButton'
import DeleteButton                from './actions/DeleteButton'

const List = ({ data, params, onTableChange, perPage, refetch }) => {
  const [isEditing, setIsEditing]   = useState(false)
  const [editedUser, setEditedUser] = useState()
  const { updateUser } = useUpdateUser()
  const { deleteUser } = useDeleteUser()
  const { toggleLockUser } = useToggleLockUser()
  const { sendConfirmationEmail } = useSendConfirmationEmail()

  const onEdit = (user) => {
    setIsEditing(true)
    setEditedUser(user)
  }

  const onDelete = (user) => {
    deleteUser({ variables: { uuid: user.uuid } }).then(refetch)
  }

  const onToggleLock = (user) => {
    toggleLockUser({ variables: { uuid: user.uuid } }).then(refetch)
  }

  const onSendConfirmationEmail = (user) => {
    sendConfirmationEmail({ variables: { uuid: user.uuid } }).then(() => {
      refetch()
      toast.success(t`Confirmation email has been resent to ${user.name}`)
    })
  }

  const onModalHide = () => {
    setIsEditing(false)
    setEditedUser()
  }
  const handleSubmit = (user, attributes) => {
    updateUser({ variables: { uuid: user.uuid, attributes } }).then(refetch)
  }

  const actionsFormatter = (_cell, unit, _rowIndex) => (
    <div className='buttons-container'>
      <SendConfirmationEmailButton
        onSendConfirmationEmail={ onSendConfirmationEmail }
        user={ unit }
      />
      <ToggleLockButton onToggleLock={ onToggleLock } user={ unit } />
      <EditButton onEdit={ onEdit } user={ unit } />
      <DeleteButton onDelete={ onDelete } user={ unit } />
    </div>
  )

  const columns = [{
    dataField: 'name',
    text: t`Name`,
    sort: true
  }, {
    dataField: 'email',
    text: t`Email`,
    sort: true
  }, {
    dataField: 'phoneNumber.fullNumber',
    text: t`Phone`
  }, {
    dataField: 'lastSignInAt',
    text: t`Last sign in at`,
    formatter: dateTime,
    sort: true
  }, {
    dataField: 'updatedAt',
    text: t`Last activity`,
    formatter: dateTime,
    sort: true
  }, {
    dataField: 'createdAt',
    text: t`Created at`,
    formatter: dateTime,
    sort: true
  }, {
    dataField: 'uuid',
    text: '',
    formatter: actionsFormatter
  }]

  return (
    <>
      <Container>
        <ItemsList
          hasSearchEnabled={ false }
          params={ params }
          setUrlParams={ onTableChange }
          totalCount={ data.total }
          perPage={ perPage }
          items={ data.items }
          columns={ columns }
        />
      </Container>
      <UserFormModal
        title={ t`Edit user` }
        user={ editedUser }
        show={ isEditing }
        onHide={ onModalHide }
        onSubmit={ handleSubmit }
      />
    </>
  )
}

List.propTypes = {
  data: shape({
    total: number.isRequired,
    items: array.isRequired
  }).isRequired,
  onTableChange: func.isRequired,
  params: object.isRequired,
  perPage: number.isRequired,
  refetch: func.isRequired
}

const Container = styled.div`
  margin-top: -1.1rem;
  margin-left: -1.45rem;
  margin-right: -1.45rem;
  margin-bottom: -3.2rem;

  word-break: break-word;

  .buttons-container {
    white-space: nowrap;
    text-align: right;
  }
`

export default List

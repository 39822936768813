import { gql } from 'apollo-boost'

export default gql`
  query CampaignChangeset($campaignUuid: ID!) {
    campaignChangeset(campaignUuid: $campaignUuid) {
      key
      campaignUuid
      humanizedMessage
      columnName
      oldValue
      newValue
      userName
      userEmail
      errorMessages
      createdAt
      updatedAt
    }
  }
`

import { gql } from 'apollo-boost'

export default gql`
  mutation PropertyPickerTokensGenerate(
    $projectUuid: ID!
  ) {
    propertyPickerTokensGenerate(
      projectUuid: $projectUuid
    ) {
      token {
        value
        domain
      }
    }
  }
`

import React from 'react'
import { object, func } from 'prop-types'
import { t } from 'ttag'

import ButtonTag from 'shared/components/ui/Button'

const ToggleLockButton = ({ onToggleLock, user }) => {
  const handleToggleLock = () => {
    onToggleLock(user)
  }

  const label = user.accessLocked ? t`Activate` : t`Deactivate`

  return (
    <ButtonTag
      onClick={ handleToggleLock }
      data-test-id='toggle-lock-button'
    >
      { label }
    </ButtonTag>
  )
}

ToggleLockButton.propTypes = {
  onToggleLock: func.isRequired,
  user: object.isRequired
}

export default ToggleLockButton

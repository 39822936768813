import React           from 'react'
import { t }           from 'ttag'
import { object }      from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { toast }       from 'react-toastify'

import channelFragment         from 'graphql/fragments/channel'
import { buildFragmentParams } from 'shared/helpers/graphql'
import channelType             from 'shared/types/channelType'
import Button                  from 'shared/components/ui/Button'

import cloneAudienceMutation from './cloneAudienceMutation'

const Item = ({ channel, audience }) => {
  const [cloneAudience, { loading }] = useMutation(
    cloneAudienceMutation,
    {
      update: (cache, { data: { cloneAudience: { audience } } }) => {
        if (!audience) {
          toast.error(t`Audience cloning failed`)
          return
        }

        const object         = { __typename: 'Channel', uuid: channel.uuid }
        const fragmentParams = buildFragmentParams(object, channelFragment)
        const fragmentData   = cache.readFragment(fragmentParams)

        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            audiences: [audience, ...fragmentData.audiences]
          }
        })
      }
    }
  )

  const handleReuseAudience = () => {
    cloneAudience({ variables: { channelUuid: channel.uuid, uuid: audience.uuid } })
  }

  const button = (
    <Button variant='secondary' onClick={ handleReuseAudience } >
      { t`Reuse` }
    </Button>
  )

  const campaign = audience.campaign
  const description = `${ campaign.name } | ${ campaign.project.name } | ${ campaign.stage.name }`

  return (
    <div className='audience-item'>
      <div>
        <div>{ audience.name }</div>
        <small title={ description }>{ description }</small>
      </div>

      { loading ? t`Saving...` : button }
    </div>
  )
}

Item.propTypes = {
  audience: object.isRequired,
  channel: channelType.isRequired
}

export default Item

import React from 'react'
import { Link } from 'react-router-dom'

import { localDateTime, formatDuration } from 'shared/helpers/date'

export const link = (cell, path) => (
  <Link to={ path } className="table-link">
    { cell }
  </Link>
)

export const noWrap = (cell) => (
  <span className="text-nowrap">
    { cell }
  </span>
)

export const dateTime = (cell) => (
  cell ? noWrap(localDateTime(cell)) : 'N/A'
)

export const duration = (cell) => (
  cell ? noWrap(formatDuration(cell)) : 'N/A'
)

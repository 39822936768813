import React, { forwardRef, useEffect } from 'react'
import styled from 'styled-components'
import { shape, string, number } from 'prop-types'

import { calcPosition } from './utils'

const HandleValue = forwardRef(({ className, handle: { value, percent }, suffix }, ref) => {
  useEffect(() => {
    ref.current.style.left = `${calcPosition(ref, percent)}px`
  }, [percent])

  return (
    <div className={ className } ref={ ref }>
      {[value, suffix].join(' ')}
    </div>
  )
})

HandleValue.propTypes = {
  handle: shape({
    value: number.isRequired,
    percent: number.isRequired
  }).isRequired,
  suffix: string
}

HandleValue.defaultProps = {
  suffix: undefined
}

HandleValue.displayName = 'HandleValue'

export default styled(HandleValue)`
  border: 1px solid #dadada;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.33);
  font-size: 0.75rem;
  line-height: 2;
  margin-top: 1.75rem;
  text-align: center;
  position: absolute;
  padding: 0 1rem;
  white-space: nowrap;
`

import React from 'react'
import { withApollo } from 'react-apollo'
import { t } from 'ttag'
import { array, string, shape } from 'prop-types'

import SubHeader from 'shared/components/ui/Card/SubHeader'
import apolloClientType from 'shared/types/apolloClientType'

import Label from './Label'
import Group from './Group'
import DetailedTargetingSelect from './DetailedTargetingSelect'
import updateFacebookMarketableAudience from './updateFacebookMarketableAudience'

const DetailedTargeting = ({ client, audience }) => {
  const { uuid, platformAudience } = audience

  const convertOptionsToValues = options => options && options.map(
    ({ id, name, type }) => ({ id, name, type })
  )

  const handleIncludeDetailedTargetingChange = selectedOptions => {
    updateFacebookMarketableAudience(client, uuid, {
      detailedTargetingInclude: convertOptionsToValues(selectedOptions)
    })
  }

  const handleAtLeastOneDetailedTargetingChange = selectedOptions =>
    updateFacebookMarketableAudience(client, uuid, {
      detailedTargetingAtLeastOne: convertOptionsToValues(selectedOptions)
    })

  return (
    <div>
      <SubHeader>{ t`Detailed targeting` }</SubHeader>
      <Group>
        <Label>{ t`Include` }</Label>
        <DetailedTargetingSelect
          audience={ audience }
          value={ platformAudience.detailedTargetingInclude }
          onChange={ handleIncludeDetailedTargetingChange }/>
      </Group>
      <Group>
        <Label>{ t`And match at least one of the following` }</Label>
        <DetailedTargetingSelect
          audience={ audience }
          value={ platformAudience.detailedTargetingAtLeastOne }
          onChange={ handleAtLeastOneDetailedTargetingChange }/>
      </Group>
    </div>
  )
}

DetailedTargeting.propTypes = {
  audience: shape({
    uuid: string.isRequired,
    platformAudience: shape({
      detailedTargetingInclude: array,
      detailedTargetingAtLeastOne: array,
      detailedTargetingExclude: array
    })
  }).isRequired,
  client: apolloClientType.isRequired
}

export default withApollo(DetailedTargeting)

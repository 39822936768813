import React from 'react'
import { t } from 'ttag'
import { shape, string } from 'prop-types'

import LoadingBlock from 'shared/components/ui/LoadingBlock'

import Card from '../Card'

import Row from './Row'
import useLeadsStatsPerPeriod from './useLeadsStatsPerPeriod'

const LeadsStats = ({ filter }) => {
  const { project_uuid: projectUuid } = filter
  const {
    leadsStats: leadsStats24Hours,
    loading:    loading24Hours
  } = useLeadsStatsPerPeriod('24', 'hours', projectUuid)

  const {
    leadsStats: leadsStats7Days,
    loading:    loading7Days
  } = useLeadsStatsPerPeriod('7', 'days', projectUuid)

  const {
    leadsStats: leadsStats30Days,
    loading:    loading30Days
  } = useLeadsStatsPerPeriod('30', 'days', projectUuid)

  if (loading24Hours || loading7Days || loading30Days) {
    return (
      <Card>
        <LoadingBlock />
      </Card>
    )
  }

  return (
    <Card dataTestId='leads-stats'>
      <Row
        value={ leadsStats24Hours.value }
        increment={ leadsStats24Hours.increment }
        comment={ t`Leads in last 24 hours` }
      />
      <Row
        value={ leadsStats7Days.value }
        increment={ leadsStats7Days.increment }
        comment={ t`Leads in last 7 days` }
      />
      <Row
        value={ leadsStats30Days.value }
        increment={ leadsStats30Days.increment }
        comment={ t`Leads in last 30 days` }
      />
    </Card>
  )
}

LeadsStats.propTypes = {
  filter: shape({
    project_uuid: string,
  })
}

LeadsStats.defaultProps = {
  filter: {
    project_uuid: null,
  }
}

export default LeadsStats

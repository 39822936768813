import React, { useCallback } from 'react'
import styled from 'styled-components'
import { func, string, bool } from 'prop-types'

import audienceLocationType from 'shared/types/audienceLocationType'
import iconClose from 'shared/assets/icons/close.svg'
import { LocationOperatorIcon } from 'shared/components/ui/icons'
import { textGreyColor, locationLightGrey } from 'shared/style/colors'

import { getPlaceTypeLabel } from '../helpers'

import OptionsDropdown from './OptionsDropdown'

const ListItem = ({
  location,
  onRemove,
  onLocationChange,
  onClick,
  onHighlight,
  highlighted,
  className
}) => {
  const handleOperatorChange = (value) => {
    location.operator = value
    onLocationChange(location)
  }

  const handleItemClick = useCallback(() => onClick(location), [onClick, location])
  const handleMouseEnter = () => onHighlight(location)
  const handleMouseLeave = () => onHighlight(null)
  const handleRemove = useCallback(e => {
    // This needed to prevent click event on the item itself which has its own handler
    e.stopPropagation()
    onRemove(location)
  }, [onRemove, location])

  return (
    <div
      className={ `${className} ${highlighted && 'highlighted'}` }
      onClick={ handleItemClick }
      onMouseEnter={ handleMouseEnter }
      onMouseLeave={ handleMouseLeave }
      data-test-id='location-result-item'
    >
      <div className='list-item-datum'>
        <div className='operator-icon-container'>
          <LocationOperatorIcon operator={ location.operator } />
        </div>
      </div>

      <div className='list-item-datum'>
        <b>
          { location.title }
        </b>
      </div>

      <div className='list-item-datum toggle location-type'>
        { getPlaceTypeLabel(location.locationType) }
      </div>

      <div className='list-item-datum toggle location-type-toggle'>
        <OptionsDropdown
          onChange={ handleOperatorChange }
          selectedOperator={ location.operator }
        />
      </div>

      <div className='list-item-datum toggle location-remove'>
        <div className='close-button' onClick={ handleRemove } />
      </div>
    </div>
  )
}

ListItem.defaultProps = {
  highlighted: false
}

ListItem.propTypes = {
  className: string.isRequired,
  location: audienceLocationType.isRequired,
  onClick: func.isRequired,
  onHighlight: func.isRequired,
  onLocationChange: func.isRequired,
  onRemove: func.isRequired,
  highlighted: bool
}

export default styled(ListItem)`
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  cursor: pointer
  min-height: 2.375rem
  padding-left: 1rem

  input.form-control {
    width: 5rem
  }

  &:hover, &.highlighted {
    background-color: ${locationLightGrey};
  }

  .list-item-datum.toggle {
    visiblity: hidden
  }

  .list-item-datum.location-type-toggle {
    margin-left: auto;
    margin-right: 1rem;
  }

  &:hover .list-item-datum.toggle,
  &:highlighted .list-item-datum.toggle {
    visiblity: visible
  }

  .rc-slider {
    width: 8rem
  }

  .operator-icon-container {
    margin-right: 0.5rem
    padding-top: 1.25vh
  }

  .close-button {
    background: url(${iconClose}) center no-repeat;
    background-size: 0.7rem
    width: 0.7rem
    height: 0.7rem
    cursor: pointer
    margin-right: 1.25rem
  }

  .location-type {
    color: ${textGreyColor}
    font-size: .85rem
    margin-left: 1rem
  }
`

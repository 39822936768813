import React from 'react'
import AsyncSelect from 'react-select/async'
import { useApolloClient } from '@apollo/react-hooks'
import { t } from 'ttag'
import { array } from 'prop-types'

import useCurrentCampaignUuid from 'shared/hooks/useCurrentCampaignUuid'
import query from 'graphql/queries/googleUrlBundles'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const URLBundlesSelect = ({ defaultValues, ...props }) => {
  const client = useApolloClient()
  const campaignUuid = useCurrentCampaignUuid()

  const loadOptions = async term => {
    const {
      data: { googleUrlBundles }
    } = await client.query({
      query,
      variables: { query: term, campaignUuid }
    })

    return googleUrlBundles.map(el => ({
      label: el.name,
      value: el.uuid,
      ...el
    }))
  }

  return (
    <AsyncSelect
      defaultOptions={ defaultValues }
      loadOptions={ loadOptions }
      placeholder={ t`Add new URL bundle` }
      noOptionsMessage={ () => t`No options` }
      styles={ condensedSelectStyles }
      { ...props }
    />
  )
}

URLBundlesSelect.propTypes = {
  defaultValues: array.isRequired
}

export default URLBundlesSelect

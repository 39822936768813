import React from 'react'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { object }   from 'prop-types'

import dashboardAnalyticsImpressionsQuery from 'graphql/queries/dashboardAnalytics/impressions'
import LoadingBlock                       from 'shared/components/ui/LoadingBlock'
import { formatNumberWithLocale }         from 'shared/helpers/number'

import Card                from './Card'
import Chart               from './LinearChart'
import formatCurrentValues from './formatCurrentValues'

const ImpressionsChart = ({ filter }) => {
  const {
    data: {
      dashboardAnalyticsImpressions: {
        impressions = {}
      } = {}
    } = {},
    loading
  } = useQuery(dashboardAnalyticsImpressionsQuery, {
    variables: { filter }
  })

  if (loading) {
    return (
      <Card header={ t`Impressions` }>
        <LoadingBlock />
      </Card>
    )
  }

  const { impressionsValue } = formatCurrentValues({ impressions })

  return (
    <Chart
      name={ t`Impressions` }
      comment={ t`Number of times your ads have been seen` }
      dataTestId='impressions-chart'
      dataset={ impressions.dataset }
      currentValue={ impressionsValue }
      tooltipFormatter={ (value) => formatNumberWithLocale(value) }
    />
  )
}

ImpressionsChart.propTypes = {
  filter: object
}

ImpressionsChart.defaultProps = {
  filter: {}
}

export default ImpressionsChart

import styled from 'styled-components'

import { borderGreyColor, redColor, darkRoseColor } from 'shared/style/colors'

const AssetContainer = styled.div`
  border: solid 1px ${borderGreyColor}
  border-radius: .5rem;
  padding-top: 75%;
  margin-bottom: 0.75rem;
  position: relative;
  cursor: pointer;
  transition: opacity ease .2s;

  &:hover {
    opacity: .8;

    button {
      opacity: 1
    }
  }

  button {
    color: ${darkRoseColor}
    border-radius: 0 0 0 .25rem;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    height: 1rem;
    line-height: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .5s ease;
    width: 1rem;
    z-index: 1
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.not-marketable {
    border: 3px solid ${redColor}
    cursor: not-allowed

    img {
      opacity: 0.3
    }
  }
  
  .asset-label {
    bottom: -1.65em;
    padding-top: none;
    width: 100%;
  }
`

export default AssetContainer

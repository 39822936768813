import gql from 'graphql-tag'

export default gql`
  fragment Asset on Asset {
    uuid
    media
    mediaType
    thumbnail
    position
    marketable
    deletable
    createdAt
    uploadedByUser
    backgroundColor
    sizes
    originalFilename
    description
    title
    altText
  }
`

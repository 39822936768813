import React from 'react'
import { func } from 'prop-types'

import Arrow from 'shared/assets/icons/svg/new/arrow.svg'

const NextArrow = ({ className, onClick }) => {
  return (
    <div className={ className } onClick={ onClick } >
      <Arrow/>
    </div>
  )
}

NextArrow.propTypes = {
  onClick: func
}

NextArrow.defaultProps = {
  onClick: () => {}
}

export default NextArrow

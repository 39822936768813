import { useApolloClient } from 'react-apollo'

import useCurrentCampaignUuid    from 'shared/hooks/useCurrentCampaignUuid'
import removeCreativeSetMutation from 'graphql/mutations/campaign/creativeSets/remove'
import campaignCreativeSetsQuery from 'graphql/queries/campaignCreativeSets'

const useRemoveCreativeSet = () => {
  const client = useApolloClient()
  const campaignUuid = useCurrentCampaignUuid()

  return (uuid) => (
    client.mutate({
      mutation: removeCreativeSetMutation,
      variables: { uuid },
      update: (cache, data) => {
        const audienceAdsCount = {}
        data.data.removeCreativeSet.creativeSets.forEach((creativeSet) => {
          creativeSet.creativeSetAudiences.forEach((creativeSetAudience) => {
            const audience = creativeSetAudience.audience
            if (audienceAdsCount[audience.uuid] === null) {
              audienceAdsCount[audience.uuid] = audience.adsCount
            }
          })
        })

        const query = { query: campaignCreativeSetsQuery, variables: { campaignUuid } }
        const { campaignCreativeSets } = cache.readQuery(query)
        // Replacing adsCount in each audience of creative sets
        let creativeSets = campaignCreativeSets.filter((creativeSet) => creativeSet.uuid !== uuid)
        creativeSets = creativeSets.map((creativeSet) => {
          const result = creativeSet.creativeSetAudiences.map((creativeSetAudience) => {
            const uuid = creativeSetAudience.audience.uuid
            creativeSetAudience.audience.adsCount = audienceAdsCount[uuid]
            return creativeSetAudience
          })
          creativeSet.creativeSetAudiences = result
          return creativeSet
        })

        cache.writeQuery({ ...query, data: { campaignCreativeSets: creativeSets } })
      }
    })
  )
}

export default useRemoveCreativeSet

import withLoginLayout from 'shared/components/LoginLayout/withLoginLayout'
import LoggedOut       from 'shared/components/pages/LoggedOut'
import UserLogin       from 'user/components/pages/Login'
import ForgotPassword  from 'user/components/pages/ForgotPassword'
import AdminLogin      from 'admin/components/pages/Login'

export default [
  { path: '/logged-out', component: withLoginLayout(LoggedOut) },
  { path: '/login', component: withLoginLayout(UserLogin) },
  { path: '/forgot-password', component: withLoginLayout(ForgotPassword) },
  { path: '/admin/login', component: withLoginLayout(AdminLogin) },
]

import { t }     from 'ttag'
import { toast } from 'react-toastify'
import { omit, get }  from 'lodash'

import redirectTo            from 'shared/helpers/redirectTo'
import createProjectMutation from 'graphql/mutations/projects/create'
import attachAssetMutation   from 'graphql/mutations/assetable/attachFile'
import projectsQuery         from 'graphql/queries/projects'

const buildStakeholdersInputs = (stakeholders) => (
  stakeholders.map((stakeholder) => {
    return {
      userUuid: get(stakeholder, 'user.uuid'),
      ...omit(stakeholder, ['uuid', 'user'])
    }
  })
)

const buildCompaniesInputs = (companies) => {
  if (companies === undefined) return []

  return companies.filter((company) => {
    return !company.empty
  }).map((company) => {
    return {
      uuid: company.uuid,
      category: company.category?.value
    }
  })
}

export default function createOnSubmit(client, assets, stakeholders, companies) {
  return function onSubmit ({ districtUuid, ...input }, { setSubmitting }) {
    let projectUuid = null
    const companiesInputs = buildCompaniesInputs(companies)
    if (companiesInputs.length === 0) return toast.error(t`Please add a company`)

    return client.mutate({
      mutation: createProjectMutation,
      variables: {
        input,
        districtUuid,
        stakeholders: buildStakeholdersInputs(stakeholders),
        companies: companiesInputs
      },
      refetchQueries: [{ query: projectsQuery, variables: { query: {} } }]
    }).then(({ data }) => {
      projectUuid = data.createProject.project.uuid
    }).then(() => (
      Promise.all(assets.map(({ signedBlobId, mediaType }) => (
        client.mutate({
          mutation: attachAssetMutation,
          variables: {
            mediaType,
            blobId: signedBlobId,
            assetableUuid: projectUuid,
            assetableType: 'Project'
          }
        })
      )))
    )).then(() => {
      toast.success(t`Project created`)
      redirectTo(`/projects/${projectUuid}`)
    }).finally(() => {
      setSubmitting(false)
    })
  }
}

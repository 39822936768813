import React from 'react'
import styled from 'styled-components'
import { func, string } from 'prop-types'

import LocationSelect from './LocationSelect'

const AddLocationForm = ({
  onSubmit,
  currentOperator
}) => {
  const handleSelect = value => onSubmit({ ...value, operator: currentOperator })

  return (
    <Container>
      <LocationSelect onChange={ handleSelect } />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.25rem;
`

AddLocationForm.propTypes = {
  currentOperator: string.isRequired,
  onSubmit: func.isRequired
}

export default AddLocationForm

import React, { memo } from 'react'
import styled from 'styled-components'
import { string, array, func, bool } from 'prop-types'
import { chunk, get } from 'lodash'
import { useQuery, useMutation } from 'react-apollo'

import Checkbox from 'shared/components/ui/Checkbox'
import facilitiesQuery from 'graphql/queries/facilities'
import updateFacilityAssignmentsMutation from 'graphql/mutations/facilities/updateAssignments'

const Facilities = ({ className, facilities, setFacilities, disabled, unitUuid }) => {
  const { data, loading } = useQuery(facilitiesQuery, {
    variables: {
      query: {
        page: 1,
      }
    }
  })

  const [runUpdateFacilityAssignmentsMutation] = useMutation(updateFacilityAssignmentsMutation)

  const handleChange = (facilityUuid) => {
    let newFacilitiesUuids
    const currentFacilities = facilities.map(v => v.uuid)

    if (currentFacilities.includes(facilityUuid)) {
      newFacilitiesUuids = currentFacilities.filter(v => v !== facilityUuid)
    } else {
      newFacilitiesUuids = [...currentFacilities, facilityUuid]
    }

    return runUpdateFacilityAssignmentsMutation({
      variables: {
        facilitiesUuid: newFacilitiesUuids,
        facilityableType: 'stage_unit',
        facilityableUuid: unitUuid
      },
      update: (_cache, result) => {
        const newFacilities = get(result, 'data.updateFacilityAssignments.facilities')
        setFacilities(newFacilities)
      }
    })
  }

  const renderCheckBoxes = (chunk) => {
    return chunk.map((value) => {
      return (
        <Checkbox
          disabled={ disabled }
          label={ value.title }
          shadow={ false }
          checked={ facilities.map(v => v.key).includes(value.key) }
          onChange={ () => handleChange(value.uuid) }
          data-test-id={ `${value.key}-facility-input` }
          key={ value.key }
        />
      )
    })
  }

  const facilityValues = loading ? [] : data.facilities.items

  const checkboxChunks = chunk(facilityValues, 5)

  return (
    <div className={ className }>
      { checkboxChunks.map((facilitiesChunk, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={ index }>
          { renderCheckBoxes(facilitiesChunk) }
        </div>
      )) }
    </div>
  )
}

Facilities.propTypes = {
  setFacilities: func.isRequired,
  unitUuid: string.isRequired,
  disabled: bool,
  facilities: array
}

Facilities.defaultProps = {
  disabled: false,
  facilities: []
}

export default styled(memo(Facilities))`
  display: flex;

  > div {
    margin-right: 2rem;
  }
`

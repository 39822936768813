import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

import background  from 'shared/assets/images/login-bg-2.png'
import getImageUrl from 'shared/helpers/getImageUrl'

const LeftPanel = ({ className }) => {
  return (
    <div className={ className }>
      <img src={ getImageUrl(background) } className="panel-background" alt="City view" />

      <p className="marketer-title">
        <strong>Marketer</strong> Technologies
      </p>
    </div>
  )
}

LeftPanel.propTypes = {
  className: string.isRequired
}

export default styled(LeftPanel)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #15263d;

  .panel-background {
    width: 100%;
  }

  .marketer-title {
    bottom: 3%;
    color: #FFF;
    font-size: .93rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
`

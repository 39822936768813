import { t } from 'ttag'

const stateDescriptions = {
  init: t`Collecting documents data…`,
  downloadDocs: t`Downloading project documents…`,
  makeZip: t`Creating the documents archive…`,
  done: t`The archive is ready and is being sent to your computer…`,
  generalError: t`Something went wrong. Please contact developers.`,
  sizeError: t`The total size of files is above the limit. Please contact developers.`
}

export default stateDescriptions

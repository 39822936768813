import React from 'react'
import { t } from 'ttag'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useApolloClient } from 'react-apollo'
import { Container, Row, Col, FormLabel } from 'react-bootstrap'

import { iconsGreyColor as grey } from 'shared/style/colors'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import projectAssetAssignments from 'graphql/queries/projectAssetAssignments'
import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'

import updateProject from './updateProject'
import ProjectLogo from './ProjectDetails/ProjectLogo'
import ProjectProspect from './ProjectDetails/ProjectProspect'

const ProjectDetails = ({ project, className }) => {
  const client = useApolloClient()

  const loadAssetAssignments = () => {
    const { project: { originalAssetAssignments } } = client.readQuery({
      query: projectAssetAssignments,
      variables: { uuid: project.uuid }
    })
    return originalAssetAssignments
  }

  const projectWithAssets = Object.assign(project, { assetAssignments: loadAssetAssignments() })

  return(
    <EmbeddedCardSection
      titleText={ t`Project details` }
      className={ className }
    >
      <Container>
        <Row>
          <Col>
            <div className="form-group">
              <FormLabel>{ t`Project name` }</FormLabel>
              <InputWrapper>
                <ThriftyInput
                  type="text"
                  value={ project.name }
                  data-test-id='name-field'
                  onUpdate={
                    name => updateProject(client, project, { name })
                  }
                />
              </InputWrapper>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ProjectLogo
              project={ projectWithAssets }
              mediaType={ 'project_logo' }
              label={ t`Logo` }
              className={ className }
              data-test-id='project-logo'
            />
          </Col>
          <Col>
            <ProjectProspect
              project={ projectWithAssets }
              label={ t`Document` }
              data-test-id='document-field'
              mediaType={ 'prospect' }
              className={ className }
            />
          </Col>
        </Row>
      </Container>
    </EmbeddedCardSection>
  )
}

ProjectDetails.propTypes = {
  project: PropTypes.object.isRequired
}

export default styled(ProjectDetails)`
  input {
    box-shadow: none;
  }
  
  .inline-input {
    width: 90%;
  } 
  
  label {
    color: ${grey};
    display: block;
    font-size: 0.85rem;
    font-weight: normal;
    margin: 0;
  }
  
  .wrapper {
    max-width: 8rem;
    max-height: auto;
  }
`

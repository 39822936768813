import eachLimit from 'async/eachLimit'
import JSZip from 'jszip'
import parameterize from 'parameterize-string'
import { saveAs } from 'file-saver'
import { bool, object, func } from 'prop-types'

import stateDescriptions from './stateDescriptions'

const downloadDocuments = (loading, error, documentsSourceData, project, setStateMessage) => {
  if (loading) return setStateMessage(stateDescriptions.init)

  if (error) {
    console.error(error) // eslint-disable-line
    console.debug(data) // eslint-disable-line
    return setStateMessage(stateDescriptions.generalError)
  }

  if (!documentsSourceData) return setStateMessage(stateDescriptions.generalError)

  const TOTAL_FILE_SIZE_LIMIT = 500e6 // 500Mb
  const BATCH_SIZE = 5
  const COMPRESSION = 9

  const totalSize = documentsSourceData
    .project
    .vitecDocuments
    .map(el => el.fileSize)
    .reduce((prev, next) => prev + next)

  if (totalSize > TOTAL_FILE_SIZE_LIMIT) return setStateMessage(stateDescriptions.sizeError)

  const zip = new JSZip()
  const zipGenerateOptions = { type: 'blob', compressionOptions: { level: COMPRESSION } }
  const zipFilename = `vitec-documents_${parameterize(project.name)}_${project.uuid}.zip`

  // More about Async's callback functions:
  // https://caolan.github.io/async/v3/global.html
  const downloadAndAddToArchive = async (doc, cb) => {
    try {
      const response = await fetch(doc.url, { cache: 'no-cache' })
      zip.file(doc.fileName, response.blob())
      setTimeout(() => cb(null), 0)
    } catch(error) {
      error => cb(error)
    }
  }

  const download = async () => {
    try {
      // https://caolan.github.io/async/v3/docs.html#eachLimit
      await eachLimit(
        documentsSourceData.project.vitecDocuments,
        BATCH_SIZE,
        downloadAndAddToArchive
      )

      setStateMessage(stateDescriptions.makeZip)

      const blob = await zip.generateAsync(zipGenerateOptions)

      setStateMessage(stateDescriptions.done)

      saveAs(blob, zipFilename)
    } catch(error) {
      (error) => {
        console.error(error) // eslint-disable-line
        setStateMessage(stateDescriptions.generalError)
      }
    }
  }

  setStateMessage(stateDescriptions.downloadDocs)

  download()
}

downloadDocuments.propTypes = {
  documentsSourceData: object.isRequired,
  error: bool.isRequired,
  loading: bool.isRequired,
  setStateMessage: func.isRequired
}

export default downloadDocuments

import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { IconCaretDown } from 'cabanaico'

import PrimaryButton from 'shared/components/ui/PrimaryButton'
import PlusIcon from 'shared/components/ui/icons/PlusIcon'

const DropdownToggle = forwardRef((props, ref) => (
  <PrimaryButton ref={ ref } { ...props } data-test-id='add-new-landing-page-button' >
    <PlusIcon />
    <span>{ t`Add new landing page` }</span>
    <IconCaretDown />
  </PrimaryButton>
))

DropdownToggle.displayName = 'DropdownToggle'

export default styled(DropdownToggle)`
  align-self: flex-end;
  padding: 0.25rem 1rem;

  svg[data-cabanaico] {
    margin-bottom: -0.25rem;
  }

  ::after {
    display: none;
  }
`

import React from 'react'

import RowCheckbox from 'shared/components/Stakeholders/RowCheckbox'

const settingsFormatter = (cell, row, rowIndex, extraData) => (
  <RowCheckbox
    { ...extraData }
    checked={ cell }
    stakeholderUuid={ row.uuid }
  />
)

export default settingsFormatter

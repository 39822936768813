import React from 'react'
import { array, string } from 'prop-types'

const UnassignedAudiencesErrorMessage = ({ message, unassignedAudiences }) => {
  return (
    <div className='mb-0'>
      { message }
      <ul>
        {
          unassignedAudiences.map((audience) => {
            return <li key={ audience.name }>{audience.name} ({audience.type})</li>
          })
        }
      </ul>
    </div>
  )
}

UnassignedAudiencesErrorMessage.propTypes = {
  message: string.isRequired,
  unassignedAudiences: array.isRequired,
}

export default UnassignedAudiencesErrorMessage

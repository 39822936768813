import React, { useMemo } from 'react'
import { t } from 'ttag'
import styled from 'styled-components'
import { Field } from 'formik'

import { labelColor } from 'shared/style/colors'
import { formatNumberWithLocale } from 'shared/helpers/number'
import stageType from 'shared/types/stageType'
import useCountries from 'shared/hooks/useCountries'
import useMapCenter from 'shared/hooks/useMapCenter'
import FieldErrorMessage from 'shared/components/ui/FieldErrorMessage'
import Map from 'shared/components/NewStage/StageDetails/Details/Map'
import Slider from 'shared/components/ProductDetails/StageDetails/Details/Slider'
import BuildingTypesSelect
  from 'shared/components/ProductDetails/StageDetails/Details/BuildingTypesSelect'
import useDetailsForm from 'shared/components/ProductDetails/StageDetails/Details/useDetailsForm'

import DetailsRow from './DetailsRow'

const DetailsForm = ({ className, stage }) => {
  const {
    onLocationChange,
    onBuildingTypesChange,
    onAttractivenessChange
  } = useDetailsForm(stage.uuid)

  const { countries } = useCountries()
  const country = useMemo(() => (
    countries.find(
      ({ alpha2 }) => alpha2 === stage?.location?.countryCode
    ) || {}
  ), [countries.length, stage?.location?.countryCode])

  const { mapCenter } = useMapCenter(stage.location)

  return (
    <div className={ className } data-test-id="readonly-stage-details-panel">
      <aside className="details-column">
        <DetailsRow title={ t`Stage` }>
          { stage.name }
        </DetailsRow>
        <DetailsRow title={ t`Address` }>
          { stage.location?.address }
        </DetailsRow>
        <DetailsRow title={ t`Country` }>
          { country.name }
        </DetailsRow>
        <DetailsRow title={ t`Zip code` }>
          { stage.location?.zipCode }
        </DetailsRow>
        <DetailsRow title={ t`Location` }>
          <Map
            pointMapToCoordinates={ mapCenter }
            onLocationChange={ onLocationChange }
            marker={ stage.location }
          />
        </DetailsRow>
      </aside>

      <aside className="details-column">
        <DetailsRow title={ t`Unit types` }>
          <FieldErrorMessage name="buildingTypes">
            <Field
              name="buildingTypes"
              as={ BuildingTypesSelect }
              onChange={ onBuildingTypesChange }
            />
          </FieldErrorMessage>
        </DetailsRow>
        <DetailsRow title={ t`Total units in stage` }>
          { stage.unitsCount }
        </DetailsRow>
        <DetailsRow title={ t`Units sold` }>
          { stage.unitsSold }
        </DetailsRow>
        <DetailsRow title={ t`Size range` }>
          <span>
            { t`From` } { stage.lowerSizeM2 } { t`to` } { stage.upperSizeM2 }
          </span>
          { stage.sizeRangeIsSubjectToChange && (
            <small>
              { t`Subject to change` }
            </small>
          ) }
        </DetailsRow>
        <DetailsRow title={ t`Price range` }>
          <span>
            { t`From` } { formatNumberWithLocale(stage.lowerPrice) } { t`to` }
            { ' ' } { formatNumberWithLocale(stage.upperPrice) } { stage.currency }
          </span>
          { stage.priceRangeIsSubjectToChange && (
            <small>
              { t`Subject to change` }
            </small>
          ) }
        </DetailsRow>

        <DetailsRow title={ t`Attractiveness` }>
          <Field
            name="attractiveness"
            onChange={ onAttractivenessChange }
            as={ Slider }
            domain={ [1, 10] }
          />
        </DetailsRow>
      </aside>
    </div>
  )
}

DetailsForm.propTypes = {
  stage: stageType.isRequired
}

export default styled(DetailsForm)`
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;

  .details-column {
    width: 45%;
  }

  small {
    display: block;
    color: ${labelColor}
  }
`

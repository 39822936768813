import React, { createRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useApolloClient, useMutation } from 'react-apollo'
import { toast } from 'react-toastify'
import { t } from 'ttag'
import styled from 'styled-components'
import { Container, FormLabel, Row, Col } from 'react-bootstrap'

import EmptyAsset            from 'shared/components/MediaList/EmptyAsset'
import completeFileUpload    from 'shared/helpers/files/completeFileUpload'
import updateProjectMutation from 'graphql/mutations/projects/update'
import removeAssetMutation   from 'graphql/mutations/assetable/removeAsset'
import mimeTypes             from 'shared/constants/mimeTypes'

import Asset from './ProjectProspect/Asset'

const ProjectProspect = ({ project, className, mediaType, label }) => {
  const fileInput = createRef()
  const client = useApolloClient()
  const findAssets = (assets) => {
    return assets
      .sort((x,y) => y.portalPositionDraft - x.portalPositionDraft)
      .filter(({ mediaType: type }) => type === mediaType)
  }
  const [isUploading, setIsUploading] = useState(false)
  const [assets, setAssets] = useState(findAssets(project.assets))
  const [runUpdateProject] = useMutation(updateProjectMutation)
  const [removeAsset] = useMutation(removeAssetMutation)

  const uploadProspect = (e) => {
    const allowedMimeTypes = mimeTypes.floorplan
    const { files } = e.target

    if (files.length > 0) setIsUploading(true)
    files.forEach((file) => {
      const fileMeta = { name: file.name, type: file.type }

      completeFileUpload({ client, file, allowedMimeTypes: allowedMimeTypes, fileMeta })
        .then(({ signedBlobId }) => {
          runUpdateProject({
            variables: {
              uuid: project.uuid,
              input: { prospect: signedBlobId }
            }
          })
            .then(({ data: { updateProject: { project: { assets } } } }) => {
              setAssets(findAssets(assets))
            })
            .then(() => toast.success(t`The new prospect has been uploaded`))
        })
    })
    setIsUploading(false)
  }

  const openUploadWindow = () => {
    fileInput.current.click()
  }

  const guaranteeRef = (node) => {
    if (node) fileInput.current = node
    fileInput.current.value = ''
  }

  const onDelete = async (asset) => {
    await removeAsset(
      { variables: { uuid: asset.uuid, totalRemove: true } }
    )
    const newAssets = assets.filter(({ uuid }) => uuid !== asset.uuid)
    setAssets(findAssets(newAssets))
  }

  const renderProspects = () => {
    return assets.map((asset) => {
      return (
        <ProspectAsset
          key={ asset.uuid }
          asset={ asset }
          className={ className }
          onClick={ openUploadWindow }
          handleAssetRemoval={ onDelete }
          data-test-id='prospect-asset'
        />
      )
    })
  }

  return (
    <div className={ className }>
      <input
        type="file"
        multiple="multiple"
        className="d-none"
        ref={ guaranteeRef }
        onChange={ uploadProspect }
        data-test-id='file-prospect-upload-input'
      />
      <div className="form-group">
        <FormLabel>{ label }</FormLabel>
      </div>
      <Container>
        <Row>
          <Col sm={ 4 }>
            <div className="wrapper">
              <EmptyAsset
                isUploading={ isUploading }
                mediaType={ mediaType }
                uploadable={ t`a new project prospect` }
                data-test-id='empty-asset'
                onClick={ openUploadWindow }
                onDrop={ uploadProspect }
              />
            </div>
          </Col>
          <Col sm={ 8 }>
            <div className="wrapper">
              { assets && assets.length > 0 && renderProspects() }
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const ProspectAsset = styled(Asset)`
  width: 300px;
  padding: 20%;
  text-align: center;
`

ProjectProspect.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  project:  PropTypes.object.isRequired
}

export default ProjectProspect

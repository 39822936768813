import React from 'react'
import { string, object } from 'prop-types'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import BTooltip from 'react-bootstrap/Tooltip'

const Tooltip = ({ placement, content, children, ...props }) => {
  return (
    <OverlayTrigger
      placement={ placement }
      overlay={ <BTooltip { ...props } >{ content }</BTooltip> }
    >
      { children }
    </OverlayTrigger>

  )
}

Tooltip.propTypes = {
  children: object.isRequired,
  content: string.isRequired,
  placement: string
}

Tooltip.defaultProps = {
  placement: 'top'
}
export default Tooltip

import React from 'react'
import { t } from 'ttag'
import { withApollo } from 'react-apollo'
import { array, string, shape } from 'prop-types'

import SubHeader from 'shared/components/ui/Card/SubHeader'
import apolloClientType from 'shared/types/apolloClientType'

import Label from './Label'
import Group from './Group'
import CustomAudienceSelect from './CustomAudienceSelect'
import updateFacebookMarketableAudience from './updateFacebookMarketableAudience'

const CustomAudiences = ({ client, audience }) => {
  const { uuid, platformAudience } = audience

  const convertOptionsToValues = options => options && options.map(
    ({ id, name, subtype }) => ({ id, name, subtype })
  )

  const handleIncludeCustomAudienceChange = selectedOptions =>
    updateFacebookMarketableAudience(client, uuid, {
      customAudiencesInclude: convertOptionsToValues(selectedOptions)
    })

  const handleExcludeCustomAudienceChange = selectedOptions =>
    updateFacebookMarketableAudience(client, uuid, {
      customAudiencesExclude: convertOptionsToValues(selectedOptions)
    })

  return (
    <div className="mb-5">
      <SubHeader>{ t`Custom audiences` }</SubHeader>
      <Group>
        <Label>{ t`Include` }</Label>
        <CustomAudienceSelect
          value={ platformAudience.customAudiencesInclude }
          onChange={ handleIncludeCustomAudienceChange }/>
      </Group>
      <Group>
        <Label>{ t`Exclude` }</Label>
        <CustomAudienceSelect
          value={ platformAudience.customAudiencesExclude }
          onChange={ handleExcludeCustomAudienceChange }/>
      </Group>
    </div>
  )
}

CustomAudiences.propTypes = {
  audience: shape({
    uuid: string.isRequired,
    platformAudience: shape({
      customAudiencesInclude: array,
      customAudiencesExclude: array
    })
  }).isRequired,
  client: apolloClientType.isRequired
}

export default withApollo(CustomAudiences)

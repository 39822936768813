import React, { useCallback, useState } from 'react'

import TabBar from 'shared/components/ui/TabBar'

export default function useTabBar(tabs) {
  const [activeTab, setActiveTab] = useState(0)
  const handleTabChange = useCallback((idx) => setActiveTab(idx), [])

  const CurrentComponent = tabs.map(el => el.component)[activeTab]
  const tabBar = (
    <TabBar
      tabs={ tabs.map(el => el.title) }
      activeTab={ activeTab }
      onChange={ handleTabChange }
    />
  )

  return {
    CurrentComponent,
    tabBar
  }
}

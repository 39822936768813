import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { string } from 'prop-types'
import { t } from 'ttag'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

import UserRoleNavLink from 'routes/UserRoleNavLink'
import Switch from 'shared/components/ui/Switch'
import PageHeader from 'shared/components/ui/PageHeader'
import CardList from 'shared/components/ui/CardDeck/List'
import stagesQuery from 'graphql/queries/stages'

import StageCard from './StageCard'

const NewAutoCampaignStagesSelect = ({ className, projectUuid }) => {
  const [showAllUnits, setShowAllUnits] = useState(false)

  const { loading, data } = useQuery(stagesQuery, {
    variables: { projectUuid },
    fetchPolicy: 'no-cache'
  })
  const stages = loading ? [] : (data.stages || [])

  const stagesWithUnsoldUnits = stages.filter((stage) => {
    return stage.unitsCount !== stage.unitsSold
  })

  return (
    <div className={ className }>
      <div className='header-box'>
        <PageHeader title={ t`Create campaign - Choose stage` } />
        <Switch
          onChange={ () => setShowAllUnits(!showAllUnits) }
          checked={ showAllUnits }
          className='stage-toggler'
        >
          { t`Show stages with sold units` }
        </Switch>
      </div>

      <CardList>
        {
          (showAllUnits ? stages : stagesWithUnsoldUnits).map((stage) =>
            <UserRoleNavLink
              key={ stage.uuid }
              to={
                isEmpty(stage.missingData)
                  ? `/stages/${stage.uuid}/campaigns/new`
                  : `/campaigns/new/stages/${stage.uuid}/missing_data`
              }
              className={ 'no-text-decoration' }
              data-test-id='stage-item'
            >
              <StageCard stage={ stage } />
            </UserRoleNavLink>
          )
        }
      </CardList>
    </div>
  )
}

NewAutoCampaignStagesSelect.propTypes = {
  className: string.isRequired,
  projectUuid: string.isRequired
}

export default styled(NewAutoCampaignStagesSelect)`
  position: relative;
  margin-bottom: 2rem;

  .header-box {
    position: relative;
  }

  .stage-toggler {
    position: absolute;
    top: .5rem;
    right: 0;
  }
`

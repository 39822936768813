import gql from 'graphql-tag'

export default gql`
  query Stage($uuid: ID!) {
    stage(uuid: $uuid) {
      uuid
      name
      autoDescriptionEnabled
      sourceDescription
      customDescriptionDraft
      titleDraft
      headlineDraft
      flatFinderUrl
      linkDraft
    }
  }
`

import React, { Component }     from 'react'
import onClickOutside           from 'react-onclickoutside'
import { Popover as BSPopover } from 'react-bootstrap'
import styled                   from 'styled-components'
import { object, shape, node, string, func } from 'prop-types'

class Popover extends Component {
  static propTypes = {
    arrowProps: shape({
      ref: func,
      style: object
    }).isRequired,
    children: node.isRequired,
    onClose: func.isRequired,
    placement: string.isRequired,
    style: object.isRequired
  }

  handleClickOutside = () => {
    this.props.onClose()
  }

  render() {
    const { children, arrowProps, className, style, placement } = this.props

    return (
      <BSPopover
        className={ className }
        arrowProps={ arrowProps }
        placement={ placement }
        style={ style }
      >
        <BSPopover.Content className="p-0">
          { children }
        </BSPopover.Content>
      </BSPopover>
    )
  }
}

export default styled(onClickOutside(Popover))`
  max-width: none;
`

import React from 'react'

import projectType from 'shared/types/projectType'
import Column from 'shared/components/ui/TwoColumnList/Column'
import Address from 'shared/components/ProductDetails/Address'

const LeftColumn = ({ promotable }) => {
  const {
    name,
    locations,
    buildingTypes,
    buildingsCount,
    unitsCount,
    unitsSold,
    percentageSold
  } = promotable

  const columnItems = []

  columnItems.push({ label: 'Project name', value: name })

  if (locations.length) {
    const addresses = locations
      .map((location) => <Address key={ location.uuid } location={ location }/>)
      .reduce((prev, curr, idx) => (
        [prev, <br key={ `br-${idx}` } />, curr] // eslint-disable-line react/no-array-index-key
      ))

    columnItems.push({ label: 'Address', value: addresses })
  }

  columnItems.push({ label: 'Unit types', value: buildingTypes.join(', ') })
  columnItems.push({ label: 'Buildings count', value: buildingsCount })
  columnItems.push({ label: 'Units count', value: unitsCount })
  columnItems.push({ label: 'Units sold', value: unitsSold })
  columnItems.push({ label: 'Percentage sold', value: `${percentageSold}%` })

  return <Column column={ columnItems }/>
}

LeftColumn.propTypes = {
  promotable: projectType.isRequired
}

export default LeftColumn

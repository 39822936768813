import React from 'react'
import { string, shape } from 'prop-types'

import { localDateTime } from 'shared/helpers/date'

import { RecordTitle, RecordDetails, RecordNote, RecordNoteInfo } from './elements'

const Note = props => (
  <>
    <RecordNoteInfo>
      <RecordTitle>
        { props.title }
      </RecordTitle>
      <RecordDetails>
        <span>{ localDateTime(props.createdAt) }</span>
        { props.author && (
          <span> &nbsp;&mdash;&nbsp; <i>{ props.author.name }</i></span>
        )}
      </RecordDetails>
      <RecordNote>
        { props.text }
      </RecordNote>
    </RecordNoteInfo>
  </>
)

Note.propTypes = {
  createdAt: string.isRequired,
  text: string.isRequired,
  author: shape({
    name: string.isRequired
  }),
  title: string
}

Note.defaultProps = {
  title: '',
  author: null
}

export default Note

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { t } from 'ttag'
import { get } from 'lodash'
import { useApolloClient } from 'react-apollo'

import Table from 'shared/components/ui/Table'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import updateAgentMutation from 'graphql/mutations/projects/agents/update'
import addAgentMutation from 'graphql/mutations/projects/agents/create'
import removeAgentMutation from 'graphql/mutations/projects/agents/remove'
import NewItemButton from 'shared/components/ui/NewItemButton'
import DeleteButton from 'shared/components/ui/DeleteButton'
import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'

import AgentImage from './Agents/AgentImage'

const Agents = ({ project, className }) => {
  const client = useApolloClient()
  const [agents, setAgents] = useState(() => project.agents.sort((a, b) => a.position - b.position))

  const deleteAgent = ({ uuid }) => {
    client.mutate({
      mutation: removeAgentMutation,
      variables: { agentUuid: uuid, projectUuid: project.uuid },
      update: (cache, { data: { removeAgent: { agent: removedAgent } } }) => {
        setAgents((prevAgents) => prevAgents.filter(({ uuid }) => uuid !== removedAgent.uuid))
      }
    })
  }

  const updateAgent = ({ uuid, input }) => {
    client.mutate({
      mutation: updateAgentMutation,
      variables: { agentUuid: uuid, input, projectUuid: project.uuid }
    })
  }

  const addAgent = () => {
    client.mutate({
      mutation: addAgentMutation,
      variables: { projectUuid: project.uuid },
      update: (cache, { data: { createAgent: { agent } } }) => {
        setAgents((preAgents) => [...preAgents, agent])
      }
    })
  }

  const textFieldFormatter = (cell, row, rowIndex, formatExtraData) => {
    const { field  } = formatExtraData
    const uuid = get(row, 'agent.uuid')
    return (
      <ThriftyInput
        name={ field }
        data-test-id={ `${field}-field` }
        value={ get(row, 'agent.' + field) }
        onUpdate={ input => updateAgent({ uuid, input: { [field]: input } }) }
      />
    )
  }

  const pictureFieldFormatter = (cell, row, _rowIndex, formatExtraData) => {
    const { mediaType } = formatExtraData
    const agent = get(row, 'agent')

    return <AgentImage
      agent={ agent }
      data-test-id='agent-image'
      mediaType={ mediaType }
      className={ className }
      project={ project }
    />
  }

  const deleteFieldFormatter = (cell, row, _rowIndex, _formatExtraData) => {
    const uuid = get(row, 'agent.uuid')

    const handleDelete = () => {
      if (confirm(t`Are you sure you want to remove the agent?`)) {
        deleteAgent({ uuid })
      }
    }

    return (
      <DeleteButton onClick={ handleDelete } />
    )
  }

  const columns = [
    {
      dataField: 'agent.uuid',
      hidden: true
    },
    {
      dataField: 'agent.nameDraft',
      text: t`Name`,
      formatter: textFieldFormatter,
      formatExtraData: { field: 'nameDraft' }
    },
    {
      dataField: 'agent.titleDraft',
      text: t`Agent title`,
      formatter: textFieldFormatter,
      formatExtraData: { field: 'titleDraft' }
    },
    {
      dataField: 'agent.phoneNumberDraft',
      text: t`Phone`,
      formatter: textFieldFormatter,
      formatExtraData: { field: 'phoneNumberDraft' }
    },
    {
      dataField: 'agent.emailDraft',
      text: t`Email`,
      formatter: textFieldFormatter,
      formatExtraData: { field: 'emailDraft' }
    },
    {
      dataField: 'agent.assignedAssets',
      text: t`Picture`,
      formatter: pictureFieldFormatter,
      formatExtraData: { mediaType: 'image' }
    },
    {
      dataField: 'agent.uuid',
      text: '',
      formatter: deleteFieldFormatter,
    }
  ]

  const buildTableOptions = (agents) => {
    return {
      bordered: false,
      columns: columns,
      data: agents.map((agent) => {
        return { agent }
      }),
      keyField: 'agent.uuid',
      headerClasses: (agents.length ? '' : 'd-none')
    }
  }

  const buildNewAgentButton = (agents) => {
    if (agents.length >= 5) return

    return (
      <NewItemButton onClick={ addAgent } data-test-id='add-new-agent-button'>
        { t`Add new agent` }
      </NewItemButton>
    )
  }

  return (
    <EmbeddedCardSection
      className={ className }
      titleText={ t`Agents` }
    >
      <div className={ className }>
        <AgentsTable
          data-test-id='agents-table'
          className={ className }
          tableOptions={ buildTableOptions(agents) }/>
        { buildNewAgentButton(agents) }
      </div>
    </EmbeddedCardSection>
  )
}

Agents.propTypes = {
  project: PropTypes.object.isRequired
}

const AgentsTable = styled(Table)`
  td:first-letter {
    text-transform: none
  }

  thead {
    background: none;
  }

  td {
    border-top: none;
  }
`

export default styled(Agents)`
  .buttons {
    display: flex;
    justify-content: space-between;

    button {
      margin: 0 1rem .75rem;
    }
  }
  
  input {
    box-shadow: none;
  }
`

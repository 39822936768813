import { gql } from 'apollo-boost'

import campaign from 'graphql/fragments/campaign'

export default gql`
  mutation UpdateCampaign(
    $uuid: ID!
    $input: CampaignInputType!
  ) {
    updateCampaign(
      uuid: $uuid
      input: $input
    ) {
      campaign {
        ... Campaign
      }
    }
  }
  ${campaign}
`

import { t } from 'ttag'

export default {
  campaignStarted: {
    key: 'campaign_started',
    label: t`Campaign has started`
  },
  campaignEnded: {
    key: 'campaign_ended',
    label: t`Campaign ended`
  },
  campaignPaused: {
    key: 'campaign_paused',
    label: t`Campaign paused`
  },
  newOffer: {
    key: 'new_offer',
    label: t`New offer`
  },
  newProposal: {
    key: 'new_proposal',
    label: t`New proposal`
  },
}

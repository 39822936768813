import gql from 'graphql-tag'

export default gql`
  query UnitAssetAssignments($unitUuid: ID!) {
    unitAssetAssignments(unitUuid: $unitUuid) {
      uuid
      mediaType
      asset {
        uuid
        media
        mediaType
        thumbnail
        position
        marketable
        deletable
        createdAt
        uploadedByUser
        backgroundColor
        sizes
      }
    }
  }
`

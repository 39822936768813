import React      from 'react'
import styled     from 'styled-components'
import { string } from 'prop-types'
import { NavLink } from 'react-router-dom'

import { hideEmail }   from 'shared/helpers/email'
import { IconSuccess } from 'shared/components/ui/icons'
import Button          from 'shared/components/ui/Button'

const EmailSuccessfullySent = ({ className, email }) => {
  return (
    <div className={ className }>
      <IconSuccess />

      <h1>Password reset complete</h1>

      <p>
        An email has been sent to your email { hideEmail(email) }.
        Follow the directions in the email to reset your password.
      </p>

      <NavLink to="/login">
        <Button>Go back</Button>
      </NavLink>
    </div>
  )
}

EmailSuccessfullySent.propTypes = {
  className: string.isRequired,
  email: string.isRequired
}

export default styled(EmailSuccessfullySent)`
  text-align: center
  
  h1 {
    margin-bottom: 1.6875rem
    margin-top: 1.85rem
  }
  
  p {
    margin: 0 auto 1.6875rem auto
    max-width: 27.625rem
    font-weight: 400
  }

  img {
    display: inline
    height: 4rem
    width: 4rem
  }
  
  ${Button} {
    padding: .5rem 1.75rem
  }
`

import React from 'react'
import { t } from 'ttag'
import { number } from 'prop-types'

import Section from './Section'
import Body from './Body'

const Units = ({ unitsCount, unitsSold }) => {
  return (
    <Section title={ t`Units` }>
      <Body title={ `${unitsCount} (${unitsSold} ${ t`sold` })` } />
    </Section>
  )
}

Units.propTypes = {
  unitsCount: number.isRequired,
  unitsSold: number.isRequired
}

export default Units

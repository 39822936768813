import React, { useState } from 'react'
import { t }        from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import styled       from 'styled-components'
import { get }      from 'lodash'
import { string }   from 'prop-types'

import ContextMenu              from 'shared/components/ui/ProjectContextMenu'
import ProjectSummary           from 'shared/components/ProjectSummary'
import redirectTo               from 'shared/helpers/redirectTo'
import { isUserAdmin, canRead } from 'shared/helpers/auth'
import toastError               from 'shared/toastError'

import Breadcrumbs from '../Project/Breadcrumbs'

import projectQuery            from './projectQuery'
import ExcelExportMenuItem     from './ExcelExportMenuItem'
import SalesPercentage         from './SalesPercentage'
import StagesDetails           from './StagesDetails'
import StagesExtraUnitsDetails from './StagesExtraUnitsDetails'
import Sales                   from './Sales'
import UnitsList               from './UnitsList'
import LeadsAnalytics          from './LeadsAnalytics'
import LeadsStats              from './LeadsStats'
import Leads                   from './Leads'
import Demographic             from './Demographic'
import projectUnits            from './UnitsList/Units/projectUnits'
import DocumentsExportMenuItem from './DocumentsExportMenuItem'

const disabledCompanySources = [
  'dnb'
]

const ProjectSalesReport = ({ uuid, className }) => {
  const [activeTab, setActiveTab] = useState(0)
  const { data } = useQuery(projectQuery, { variables: { uuid } })
  const project = get(data, 'project', null)

  if (project === null || project === undefined) {
    return <div />
  }

  if (!project.salesReportEnabled) {
    redirectTo(`/projects/${uuid}`)
    toastError(t`Sales report is not available for this project`, { autoClose: 3000 })
  }

  const unitsData = projectUnits({ project, stageTab: activeTab })
  const companySource = project.product.company.source
  const available = isUserAdmin() || !disabledCompanySources.includes(companySource)

  return (
    <div className={ [className, 'mb-5'].join(' ') }>
      <Breadcrumbs name={ project.name }/>

      <div className='position-relative'>
        <ProjectSummary withoutSelectors project={ project } />

        <ContextMenu>
          { (handleAndClose) => (
            <>
              {canRead('sales_reports_excel') && (
                <div>
                  <ExcelExportMenuItem
                    project={ project }
                    stageTab={ activeTab }
                    onClick={ handleAndClose() }
                  />
                </div>
              )}
              {unitsData.vitecDocumentsPresentOnProject && (
                <div>
                  <DocumentsExportMenuItem project={ project } />
                </div>
              )}
            </>
          )
          }
        </ContextMenu>
      </div>

      <SalesPercentage project={ project } />

      <StagesDetails
        project={ project }
        stageTab={ activeTab }
        handleTabChange={ (index) => setActiveTab(index) }
      />

      <StagesExtraUnitsDetails project={ project } stageTab={ activeTab } />

      <Sales project={ project } stageTab={ activeTab } />

      <Demographic project={ project } stageTab={ activeTab } />

      <UnitsList unitsData={ unitsData } />

      {false && (
        <div className='leads-block'>
          <div className='leads-graph'>
            <LeadsAnalytics project={ project } stageTab={ activeTab } />
          </div>

          <div className='leads-amount'>
            <LeadsStats project={ project } stageTab={ activeTab } />
          </div>
        </div>
      )}

      {available && canRead('sales_reports_leads_table') && (
        <Leads project={ project } stageTab={ activeTab } />
      )}
    </div>
  )
}

ProjectSalesReport.propTypes = {
  uuid: string.isRequired
}

export default styled(ProjectSalesReport)`
  .leads-block {
    display: flex;

    .leads-graph {
      width: 66%;
      margin-right: 1%;
    }

    .leads-amount {
      width: 33%;

      .card-body {
        height: 330px;
        padding: 0 1.45rem 1.1rem !important;
      }
    }
  }
`

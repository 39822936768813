import React from 'react'
import { t } from 'ttag'
import styled from 'styled-components'
import { func } from 'prop-types'

import Label from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/Label'

import BannerTemplateCollectionsSlider from './BannerTemplateCollectionsSlider'

const BannerTemplateCollectionSelect = ({ className, setBannerTemplateCollection, ...props }) => {
  return (
    <div className={ `input-wrapper ${className}` }>
      <Label>{ t`Select Template` }</Label>

      <BannerTemplateCollectionsSlider
        setBannerTemplateCollection={ setBannerTemplateCollection }
        { ...props }
      />
    </div>
  )
}

BannerTemplateCollectionSelect.propTypes = {
  setBannerTemplateCollection: func.isRequired
}

export default styled(BannerTemplateCollectionSelect)`
  margin-bottom: 1rem;
  height: 135px;

  .slick-slide {
    padding: 0 !important;
  }
`

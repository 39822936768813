import React  from 'react'
import { t }  from 'ttag'
import moment from 'moment'
import { array } from 'prop-types'

import TableView from 'shared/components/ui/Table'

const Table = ({ users }) => {
  const tableOptions = {
    data: users,
    keyField: 'uuid',
    bordered: false,
    columns: [{
      dataField: 'uuid',
      hidden: true,
    }, {
      dataField: 'name',
      text: t`Name`,
      sort: true
    }, {
      dataField: 'email',
      text: t`Email`,
      sort: true
    }, {
      dataField: 'phoneNumber.fullNumber',
      text: t`Phone`,
      sort: true
    }, {
      dataField: 'lastSignInAt',
      text: t`Last active`,
      sort: true,
      formatter: (_, { currentSignInAt, lastSignInAt }) => {
        if (!lastSignInAt) {
          return t`Never`
        }

        const signInTime = currentSignInAt || lastSignInAt

        return moment.duration(
          Math.abs(moment(signInTime).diff(moment(),'minutes')), 'minutes'
        ).format('d[d] h[h] m[m]')
      }
    }]
  }

  return (
    <TableView
      tableOptions={ tableOptions }
    />
  )
}

Table.propTypes = {
  users: array.isRequired
}

export default Table

import gql from 'graphql-tag'

import specification from 'graphql/fragments/specification'

export default gql`
  mutation requestSpending($campaignUuid: ID!) {
    requestSpending(campaignUuid: $campaignUuid) {
      errors
      specification {
        ... Specification
      }
    }
  }
  ${specification}
`

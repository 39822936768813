import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { string, func, array } from 'prop-types'

import Checkbox from 'shared/components/ui/Checkbox'
import stageType from 'shared/types/stageType'
import { buttonTextColor } from 'shared/style/colors'
import { combineErrors } from 'shared/helpers/inputValidation'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import BigNumberInput from 'shared/components/ui/ThriftyInput/BigNumber'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'

import Slider from './Slider'
import BuildingTypesSelect from './BuildingTypesSelect'

const validatableFields = [
  'unitsCount',
  'buildingTypes',
  'lowerPrice',
  'upperPrice',
  'lowerSizeM2',
  'upperSizeM2'
]

const RightColumn = ({ className, stage, onUpdate, errors }) => {
  const handleBuildingTypeChange = values => onUpdate({ buildingTypes: values.map(el => el.value) })

  const handleUnitsCountInput = value => onUpdate({ unitsCount:  Number(value) })
  const handleUnitsSoldInput  = value => onUpdate({ unitsSold:   Number(value) })
  const handleLowerSizeInput  = value => onUpdate({ lowerSizeM2: Number(value) })
  const handleUpperSizeInput  = value => onUpdate({ upperSizeM2: Number(value) })
  const handleLowerPriceInput = value => onUpdate({ lowerPrice:  Number(value) })
  const handleUpperPriceInput = value => onUpdate({ upperPrice:  Number(value) })

  const handleAttractivenessChange = ([attractiveness]) => {
    if (stage.attractiveness === attractiveness) { return }
    onUpdate({ attractiveness })
  }

  const handlePriceRangeIsSubjectToChange = e =>
    onUpdate({ priceRangeIsSubjectToChange: e.target.checked })

  const handleSizeRangeIsSubjectToChange = e =>
    onUpdate({ sizeRangeIsSubjectToChange: e.target.checked })

  const errorsOnField = validatableFields.reduce((result, field) => {
    result[field] = combineErrors({ errors, field, uuid: stage.uuid })
    return result
  }, {})

  return (
    <div className={ className } data-test-id='building-types'>
      <div className="group">
        <label>{ t`Unit types` }</label>
        <InputWrapper error={ errorsOnField['buildingTypes'] } >
          <BuildingTypesSelect
            value={ stage.buildingTypes }
            onChange={ handleBuildingTypeChange }
          />
        </InputWrapper>
      </div>

      <div className="group" data-test-id='units-count'>
        <label>{ t`Total units in stage` }</label>
        <InputWrapper error={ errorsOnField['unitsCount'] } >
          <ThriftyInput
            type="number"
            value={ stage.unitsCount }
            onUpdate={ handleUnitsCountInput }
          />
        </InputWrapper>
      </div>

      <div className="group" data-test-id='units-sold-count'>
        <label>{ t`Units sold` }</label>
        <ThriftyInput
          type="number"
          value={ stage.unitsSold }
          onUpdate={ handleUnitsSoldInput }
        />
      </div>

      <div className="group" data-test-id='size-range'>
        <label>
          <div>{ t`Size range` }</div>
          <Checkbox
            shadow={ false }
            size="sm"
            checked={ stage.sizeRangeIsSubjectToChange || false }
            onChange={ handleSizeRangeIsSubjectToChange }
            label={ t`Subject to change` } />
        </label>
        <div className='range-value'>
          <div className='range-start'>
            <label>{ t`From` }</label>
            <InputWrapper error={ errorsOnField['lowerSizeM2'] } >
              <BigNumberInput
                maxLength="11"
                value={ stage.lowerSizeM2 }
                onUpdate={ handleLowerSizeInput }
              />
            </InputWrapper>
            <label>{ t`To` }</label>
            <InputWrapper error={ errorsOnField['upperSizeM2'] } >
              <BigNumberInput
                maxLength="11"
                value={ stage.upperSizeM2 }
                onUpdate={ handleUpperSizeInput }
              />
            </InputWrapper>
          </div>
          <label className='last-label'>m<sup>2</sup></label>
        </div>
      </div>

      <div className="group" data-test-id='price-range'>
        <label>
          <div>{ t`Price range` }</div>
          <Checkbox
            shadow={ false }
            size="sm"
            checked={ stage.priceRangeIsSubjectToChange || false }
            onChange={ handlePriceRangeIsSubjectToChange }
            label={ t`Subject to change` } />
        </label>
        <div className='range-value'>
          <div className='range-start'>
            <label>{ t`From` }</label>
            <InputWrapper error={ errorsOnField['lowerPrice'] } >
              <BigNumberInput
                maxLength="15"
                value={ stage.lowerPrice }
                onUpdate={ handleLowerPriceInput }
              />
            </InputWrapper>
            <label>{ t`To` }</label>
            <InputWrapper error={ errorsOnField['upperPrice'] } >
              <BigNumberInput
                maxLength="15"
                value={ stage.upperPrice }
                onUpdate={ handleUpperPriceInput }
              />
            </InputWrapper>
          </div>
          <label className='last-label'>{ stage.currency }</label>
        </div>
      </div>

      <div className="group" data-test-id='attractiveness'>
        <label>{ t`Attractiveness` }</label>
        <Slider
          domain={ [1, 10] }
          values={ [stage.attractiveness || 1] }
          onChange={ handleAttractivenessChange }
        />
      </div>
    </div>
  )
}

RightColumn.propTypes = {
  className: string.isRequired,
  onUpdate: func.isRequired,
  stage: stageType.isRequired,
  errors: array
}

RightColumn.defaultProps = {
  errors: []
}

export default styled(RightColumn)`
  width: 50%;
  padding: 1.25rem 2.8125rem 1.625rem 1.25rem;

  .group {
    > label {
      width: 10rem !important;
    }

    > div {
      width: 100%;
    }

    .input-wrapper {
      input, > div {
        width: 100% !important;
        max-width: 100%;
      }
    }
  }

  .range-value {
    display: flex;

    label {
      color: ${buttonTextColor};
      font-size: 0.875rem;
      margin-bottom: 0;
      line-height: 2rem;
    }

    .range-start {
      flex: 1;
      display: flex;
      align-items: flex-start;

      > :not(:last-child) {
        margin-right: 0.3rem;
      }

      > :last-child {
        flex-grow: 1;
      }

      input {
        font-size: 0.75rem;
        text-align: right;
        padding: 0.375rem;
        width: 5.5rem;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .last-label {
      width: 2rem;
      margin-left: .5rem;
    }
  }

  .input-wrapper {
    width: 100%
  }
`

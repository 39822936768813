import React from 'react'
import { Radio as BaseRadio } from 'pretty-checkbox-react'
import PropTypes from 'prop-types'

const Radio = ({ className, ...props }) => {
  return (
    <BaseRadio
      className={ className }
      { ...props }
    />
  )
}

Radio.propTypes = {
  className: PropTypes.string
}

Radio.defaultProps = {
  className: null
}

export default Radio

import removeAssetAssignmentMutation from 'graphql/mutations/assetable/removeAssetAssignment'
import campaignCreativeSetsQuery     from 'graphql/queries/campaignCreativeSets'

export default function createRemoveAssetAssignment(client, campaignUuid, assetAssignments) {
  return function removeAssetAssignment(assetAssignmentUuid, confirmRemoval) {
    return client.mutate({
      mutation: removeAssetAssignmentMutation,
      variables: {
        assetAssignmentUuid,
        confirmRemoval
      },
      refetchQueries: [{
        query: campaignCreativeSetsQuery,
        variables: { campaignUuid }
      }],
      optimisticResponse: {
        removeAssetAssignment: {
          __typename: 'RemoveAssetAssignmentPayload',
          usedInCampaigns: [],
          usedInAssetables: [],
          errors: [],
          assetable: {
            __typename: 'Campaign',
            uuid: campaignUuid,
            assetAssignments: assetAssignments.filter(({ uuid }) =>
              uuid !== assetAssignmentUuid
            )
          }
        }
      }
    })
  }
}

import React from 'react'
import styled from 'styled-components'
import { withApollo } from 'react-apollo'
import { t } from 'ttag'
import { array, string, shape } from 'prop-types'

import { textGreyColor } from 'shared/style/colors'
import apolloClientType from 'shared/types/apolloClientType'

import LanguageTargetingSelect from './LanguageTargetingSelect'
import updateFacebookMarketableAudience from './updateFacebookMarketableAudience'

const LanguageTargeting = ({ client, audience }) => {
  const { uuid, platformAudience } = audience

  const convertOptionsToValues = options => options && options.map(
    ({ id, name }) => ({ id, name })
  )

  const handleLanguageTargetingChange = selectedOptions => {
    updateFacebookMarketableAudience(client, uuid, {
      languageTargeting: convertOptionsToValues(selectedOptions)
    })
  }

  return (
    <div>
      <Title>{ t`Language targeting` }</Title>
      <LanguageTargetingSelect
        value={ platformAudience.languageTargeting }
        onChange={ handleLanguageTargetingChange }
      />
    </div>
  )
}

const Title = styled.h6`
  color: ${textGreyColor}
  font-size: .8rem
  margin-bottom: .65rem
`

LanguageTargeting.propTypes = {
  audience: shape({
    uuid: string.isRequired,
    platformAudience: shape({
      languageTargeting: array
    })
  }).isRequired,
  client: apolloClientType.isRequired
}

export default withApollo(LanguageTargeting)

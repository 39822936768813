import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

const UrlParams = ({ history, children, location, match: _match, ...props }) => {
  const setUrlParams = (params) => {
    history.push(`${location.pathname}?${queryString.stringify(params)}`)
  }

  const params = queryString.parse(location.search)

  return (
    children({ setUrlParams, params, location, ...props })
  )
}

export default withRouter(UrlParams)

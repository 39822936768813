import createAssetAssignmentMutation from 'graphql/mutations/assetable/createAssetAssignment'

export default function createCreateAssetAssignment(client, stageUuid) {
  return function createAssetAssignment(asset, mediaType) {
    client.mutate({
      mutation: createAssetAssignmentMutation,
      variables: {
        assetableUuid: stageUuid,
        assetableType: 'Stage',
        assetUuid:     asset.uuid,
        mediaType:     mediaType
      }
    })
  }
}

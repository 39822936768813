import { gql } from 'apollo-boost'

export default gql`
  query BlobPreview($blobId: String!) {
    blobPreview(blobId: $blobId) {
      thumbnail
      media
    }
  }
`

import React from 'react'
import { t } from 'ttag'

import stageType from 'shared/types/stageType'
import PageHeader from 'shared/components/ui/PageHeader'

const Header = ({ stage }) => {
  return (
    <>
      <PageHeader
        title={ `${ t`We would like to know more about: Stage details` } ${ stage.name }` }
      />
      <p>
        {
          t`Your campaign is about to be created!
          For the best results, please fill out the missing information below.
          If you don't have the information, you can skip this step.
          This will effect the performance of your campaign.`
        }
      </p>
    </>
  )
}

Header.propTypes = {
  stage: stageType.isRequired
}

export default Header

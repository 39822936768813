import React, { useEffect, useState } from 'react'
import { t } from 'ttag'
import { func } from 'prop-types'
import { find, get } from 'lodash'

import useSpecification from 'admin/hooks/useSpecification'
import campaignType from 'shared/types/campaignType'
import LoadingBlock from 'shared/components/ui/LoadingBlock'
import { isUserAdmin } from 'shared/helpers/auth'

import usePopulateTargeting from './usePopulateTargeting'
import ChannelsNavbar from './ChannelsNavbar'
import Channel from './Channel'
import buildAudiencesValidationPayload from './buildAudiencesValidationPayload'

const Targeting = ({ campaign, setValidatablePayload, onLoaded, ...props }) => {
  const { populateTargeting, loading } = usePopulateTargeting(campaign.uuid)
  const { specification } = useSpecification(campaign.uuid)

  useEffect(() => {
    populateTargeting()
  }, [])

  useEffect(() => {
    if(setValidatablePayload) {
      const payload = buildAudiencesValidationPayload(campaign).map((subPayload) => ({
        ...subPayload,
        specification,
        campaign
      }))
      setValidatablePayload(payload)
    }
  }, [JSON.stringify(campaign), JSON.stringify(specification)])

  useEffect(() => {
    if(!loading && onLoaded) {
      onLoaded()
    }
  }, [loading])

  const channels = campaign
    .channels
    .filter((channel) => isUserAdmin() || (channel.type !== 'portal')) || []
  const initialChannel = get(find(channels, 'enabled'), 'type', channels?.[0]?.type)
  const [currentChannel, setCurrentChannel] = useState(initialChannel)

  const channel = channels.find(el => el.type === currentChannel)

  if (loading) return <LoadingBlock comment={ t`Generating target audiences...` } />

  return (
    <div data-test-id="channels-panel">
      <ChannelsNavbar
        channels={ channels }
        currentChannel={ currentChannel }
        setCurrentChannel={ setCurrentChannel }/>

      <Channel
        channel={ channel }
        campaign={ campaign }
        { ...props }
      />
    </div>
  )
}

Targeting.propTypes = {
  campaign: campaignType.isRequired,
  onLoaded: func,
  setValidatablePayload: func
}

Targeting.defaultProps = {
  onLoaded: undefined,
  setValidatablePayload: undefined
}

export default Targeting

import gql from 'graphql-tag'

export default gql`
  mutation setChannelOptimization(
    $campaignUuid: ID!
    $enabled: Boolean!
  ) {
    setChannelOptimization(campaignUuid: $campaignUuid, enabled: $enabled) {
      specification {
        uuid
        channelOptimizationEnabled
      }
    }
  }
`

import gql from 'graphql-tag'

export default gql`
  query Stage($uuid: ID!) {
    stage(uuid: $uuid) {
      availableCompanyLocalesAndCountryCodes
      allAvailableCompanyLocalesAndCountryCodes
    }
  }
`

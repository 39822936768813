import React from 'react'
import { bool } from 'prop-types'
import styled from 'styled-components'

import Card from 'shared/components/ui/Card'
import Header from 'shared/components/ui/Card/StaticHeader'
import MissingDataIndicator from 'shared/components/Indicators/MissingData'
import { projectMissingDataPresentation }
  from 'shared/components/Indicators/MissingData/Presentations'

import StageDetails from './StageDetails'

const Stages = ({ stages, collapsed }) => {
  return (
    stages.map((stage) => (
      <Card
        collapsible
        className="mb-2"
        collapsed={ collapsed }
        key={ stage.uuid }
        header={ (
          <div className="d-flex justify-content-between">
            <Header className="align-self-center">
              { stage.name }
              <MissingDataIndicator
                missingData={{
                  data: stage.missingData,
                  presentation: projectMissingDataPresentation
                }}
              />
            </Header>
          </div>
        ) }
      >
        <StageDetails stage={ stage } />
      </Card>
    ))
  )
}

Stages.propTypes = {
  collapsed: bool.isRequired
}

export default styled(Stages)`
  .missing-data-indicator {
    margin: 0
  }
`

import gql from 'graphql-tag'

import stage from 'graphql/fragments/stage'
import product from 'graphql/fragments/product'

export default gql`
  query Stage(
    $uuid: ID!
  ) {
    stage(uuid: $uuid) {
      ... Stage

      product {
        ... Product
      }
    }
  }
  ${stage}
  ${product}
`

import gql from 'graphql-tag'

export default gql`
  fragment Ad on Ad {
    uuid
    enabled
    deletedAt
    createdAt
    asset {
      uuid
      thumbnail
      media
    }
  }
`

import styled from 'styled-components'

import { buttonTextColor } from 'shared/style/colors'

export const Header = styled.h3`
  margin: 0;
  font-size: 1.25rem;
`

export const Row = styled.div`
  margin-bottom: 1rem;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`

export const Label = styled.label`
  display: block;
  color: ${buttonTextColor};
  margin-bottom: .5rem;
  font-size: 0.825rem;
`

import React, { useCallback } from 'react'
import { t } from 'ttag'

import ProjectsList from 'shared/components/ProjectsList'
import { canRead } from 'shared/helpers/auth'
import redirectTo from 'shared/helpers/redirectTo'
import toastError from 'shared/toastError'

const SalesReports = () => {
  if (!canRead('sales_reports')) {
    redirectTo('/')
    toastError(t`You are not authorized to perform this action`, { autoClose: 3000 })
  }

  const projectCardLinkBuilder = useCallback(
    (project) => {
      return `/sales_reports/projects/${project.uuid}`
    },
    []
  )

  return (
    <ProjectsList
      campaignsLiveCount={ false }
      pageTitle={ t`Sales Reports` }
      projectCardLinkBuilder={ projectCardLinkBuilder }
      customFilter={{ sales_report_enabled: true }}
    />
  )
}

export default SalesReports

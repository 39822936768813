import React from 'react'
import { string } from 'prop-types'

import projectType from 'shared/types/projectType'

import Image    from './Image'
import Uploader from './Uploader'

const ProjectImage = ({ project, label, mediaType }) => {
  return (
    <>
      <Image project={ project } label={ label } mediaType={ mediaType } />
      <Uploader project={ project } label={ label } mediaType={ mediaType } />
    </>
  )
}

ProjectImage.propTypes = {
  label: string.isRequired,
  mediaType: string.isRequired,
  project: projectType.isRequired
}

export default ProjectImage

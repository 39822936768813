import { t } from 'ttag'

export default [
  { value: 'registered', label: t`Registered` },
  { value: 'contact_attempted', label: t`Contact attempted` },
  { value: 'dialogue_established', label: t`Dialogue established` },
  { value: 'showing_scheduled', label: t`Showing scheduled` },
  { value: 'awaiting_decision', label: t`Awaiting decision` },
  { value: 'offer_submitted', label: t`Offer submitted` },
  { value: 'no_offer', label: t`No offer` }
]

import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'
import { string, func, shape, array, number, bool } from 'prop-types'
import { Field }        from 'formik'

import { buttonTextColor } from 'shared/style/colors'
import FormikThriftyInput from 'shared/components/ui/FormikThriftyInput'
import FormikCheckbox from 'shared/components/ui/FormikCheckbox'
import FormikBigNumber from 'shared/components/ui/FormikBigNumber'
import FieldErrorMessage from 'shared/components/ui/FieldErrorMessage'
import buildingTypes from 'shared/constants/buildingTypes'
import DetailsRow from 'shared/components/Project/Readonly/Stages/DetailsRow'
import { isUserAdmin } from 'shared/helpers/auth'

import Slider from './Slider'
import BuildingTypesSelect from './BuildingTypesSelect'

const RightColumn = (props) => {
  const {
    projectReadOnly,
    stage,
    className,
    currency,
    onBuildingTypesChange,
    onUnitsCountChange,
    onUnitsSoldChange,
    onLowerPriceChange,
    onUpperPriceChange,
    onPriceRangeIsSubjectToChangeChange,
    onSizeIsSubjectToChangeChange,
    onLowerSizeM2Change,
    onUpperSizeM2Change,
    onAttractivenessChange
  } = props

  const checkReadMode = () => {
    if (!isUserAdmin() && projectReadOnly) {
      return (
        <>
          <DetailsRow title={ t`Unit types` }>
            { stage.buildingTypes?.map((type) =>
              buildingTypes.find((buildingType) => buildingType.value === type)?.label
            ).join(', ') }
          </DetailsRow>
          <DetailsRow title={ t`Total units in stage` }>
            { stage.unitsCount }
          </DetailsRow>
          <DetailsRow title={ t`Units sold` }>
            { stage.unitsSold }
          </DetailsRow>
        </>
      )
    } else {
      return (
        <>
          <div className="group" data-test-id='unit-types'>
            <label>{ t`Unit types` }</label>
            <FieldErrorMessage name="buildingTypes">
              <Field
                name="buildingTypes"
                as={ BuildingTypesSelect }
                onChange={ onBuildingTypesChange }
              />
            </FieldErrorMessage>
          </div>
          <div className="group" data-test-id='units-count'>
            <label>{ t`Total units in stage` }</label>
            <FieldErrorMessage name="unitsCount">
              <Field
                name="unitsCount"
                type="number"
                as={ FormikThriftyInput }
                onChange={ onUnitsCountChange }
              />
            </FieldErrorMessage>
          </div>
          <div className="group" data-test-id='units-sold-count'>
            <label>{ t`Units sold` }</label>
            <Field
              name="unitsSold"
              type="number"
              as={ FormikThriftyInput }
              onChange={ onUnitsSoldChange }
            />
          </div>
        </>
      )
    }
  }

  return (
    <div className={ className } data-test-id='building-types'>
      {checkReadMode()}

      <div className="group" data-test-id='size-range'>
        <label>
          <div>{ t`Size range` }</div>
          <Field
            name="sizeRangeIsSubjectToChange"
            onChange={ onSizeIsSubjectToChangeChange }
            as={ FormikCheckbox }
            shadow={ false }
            size="sm"
            label={ t`Subject to change` }
          />
        </label>
        <div className='range-value'>
          <div className='range-start'>
            <label>{ t`From` }</label>
            <FieldErrorMessage name="lowerSizeM2">
              <Field
                name="lowerSizeM2"
                as={ FormikBigNumber }
                maxLength="11"
                onChange={ onLowerSizeM2Change }
              />
            </FieldErrorMessage>
            <label>{ t`To` }</label>
            <FieldErrorMessage name="upperSizeM2">
              <Field
                name="upperSizeM2"
                maxLength="11"
                as={ FormikBigNumber }
                onChange={ onUpperSizeM2Change }
              />
            </FieldErrorMessage>
          </div>
          <label className='last-label'>m<sup>2</sup></label>
        </div>
      </div>

      <div className="group" data-test-id='price-range'>
        <label>
          <div>{ t`Price range` }</div>
          <Field
            name="priceRangeIsSubjectToChange"
            onChange={ onPriceRangeIsSubjectToChangeChange }
            as={ FormikCheckbox }
            shadow={ false }
            size="sm"
            label={ t`Subject to change` }
          />
        </label>
        <div className='range-value'>
          <div className='range-start'>
            <label>{ t`From` }</label>
            <FieldErrorMessage name="lowerPrice">
              <Field
                name="lowerPrice"
                maxLength="15"
                as={ FormikBigNumber }
                onChange={ onLowerPriceChange }
              />
            </FieldErrorMessage>
            <label>{ t`To` }</label>
            <FieldErrorMessage name="upperPrice">
              <Field
                name="upperPrice"
                maxLength="15"
                as={ FormikBigNumber }
                onChange={ onUpperPriceChange }
              />
            </FieldErrorMessage>
          </div>
          <label className='last-label'>{ currency }</label>
        </div>
      </div>

      <div className="group" data-test-id='attractiveness'>
        <label>{ t`Attractiveness` }</label>
        <Field
          name="attractiveness"
          onChange={ onAttractivenessChange }
          as={ Slider }
          domain={ [1, 10] }
        />
      </div>
    </div>
  )
}

RightColumn.propTypes = {
  currency: string.isRequired,
  onAttractivenessChange: func.isRequired,
  onBuildingTypesChange: func.isRequired,
  onLowerPriceChange: func.isRequired,
  onLowerSizeM2Change: func.isRequired,
  onPriceRangeIsSubjectToChangeChange: func.isRequired,
  onSizeIsSubjectToChangeChange: func.isRequired,
  onUnitsCountChange: func.isRequired,
  onUnitsSoldChange: func.isRequired,
  onUpperPriceChange: func.isRequired,
  onUpperSizeM2Change: func.isRequired,
  stage: shape({
    buildingTypes: array,
    unitsCount: number,
    unitsSold: number
  }).isRequired,
  projectReadOnly: bool
}

RightColumn.defaultProps = {
  projectReadOnly: undefined
}

export default styled(RightColumn)`
  width: 50%;
  padding: 1.25rem 2.8125rem 1.625rem 1.25rem;

  .group {
    > label {
      width: 10rem !important;
    }

    > div {
      width: 100%;
    }

    .field-error-message {
      input, > div {
        width: 100% !important;
        max-width: 100%;
      }
    }
  }

  .range-value {
    display: flex;

    label {
      color: ${buttonTextColor};
      font-size: 0.875rem;
      margin-bottom: 0;
      line-height: 2rem;
    }

    .range-start {
      flex: 1;
      display: flex;
      align-items: flex-start;

      > :not(:last-child) {
        margin-right: 0.3rem;
      }

      > :last-child {
        flex-grow: 1;
      }

      input {
        font-size: 0.75rem;
        text-align: right;
        padding: 0.375rem;
        width: 5.5rem;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .last-label {
      width: 2rem;
      margin-left: .5rem;
    }
  }

  .field-error-message {
    width: 100%;
  }
`

import React, { useState } from 'react'
import { t }               from 'ttag'
import { toast }           from 'react-toastify'
import { useApolloClient } from '@apollo/react-hooks'

import redirectTo      from 'shared/helpers/redirectTo'
import productType     from 'shared/types/productType'
import ValidatableTabs from 'shared/components/ValidatableTabs'

import updateStage     from './StageDetails/Details/updateStage'
import tabs, { originalTabs } from './StageDetails/TabBar/tabs'
import buildValidator  from './validations/buildValidator'

const StageDetails = ({ product }) => {
  const isSnapshotEnabled = product.company.enabledFeatures.includes('snapshot')
  const preparedTabs = isSnapshotEnabled ? tabs : originalTabs
  const [activeTab, setActiveTab] = useState(0)
  const [errors, setErrors] = useState([])

  const stage = product.promotable
  const client = useApolloClient()
  const CurrentComponent = preparedTabs.map(el => el.component)[activeTab]

  const validate = buildValidator({ product, setErrors, validatorNumber: activeTab })

  const onFinalize = () => (
    updateStage(client, stage, { state: 'complete' }).
      then(() => {
        redirectTo(`/projects/${stage.projectUuid}`)
        toast.success(t`Stage successfully created.`)
      })
  )
  return (
    <ValidatableTabs
      tabs={ preparedTabs }
      title={ t`Stage Information` }
      collapsible={ false }
      validate={ validate }
      onFinalize={ onFinalize }
      activeTab={ activeTab }
      setActiveTab={ setActiveTab }
    >
      <CurrentComponent
        product={ product }
        errors={ errors }
        validate={ validate }
      />
    </ValidatableTabs>
  )
}

StageDetails.propTypes = {
  product: productType.isRequired
}

export default StageDetails

import { gql } from 'apollo-boost'

export default gql`
  query campaignCalculatedDates($propertyUuid: ID!) {
    campaignCalculatedDates(propertyUuid: $propertyUuid) {
      startDate
      endDate
    }
  }
`

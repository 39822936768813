import React                   from 'react'
import { Formik }              from 'formik'
import { Form }                from 'react-bootstrap'
import { t }                   from 'ttag'
import moment                  from 'moment'
import { array, object, func } from 'prop-types'

import history            from 'browserHistory'
import PrimaryButton      from 'shared/components/ui/PrimaryButton'

import validationSchema  from './validationSchema'
import Fieldset          from './Fieldset'
import PageContainer     from './PageContainer'
import FormContainer     from './FormContainer'

const CampaignForm = ({
  formValues = {},
  facebookPages,
  onSubmit
}) => {
  const handleGoBack = (e) => {
    e.preventDefault()
    history.goBack()
  }

  const initialValues = Object.assign({
    goalNumber: '',
    dsaBeneficiary: '',
    dsaPayor: '',
    goalType: 'leads',
    targetLqs: 'high',
    startDate: moment().add(1, 'day').toDate(),
    endDate: moment().add(11, 'days').toDate(),
  }, formValues)

  return (
    <PageContainer>
      <FormContainer>
        <h2 className="form-header">{ t`Set goals, start and finish dates` }</h2>

        <Formik
          initialValues={ initialValues }
          validationSchema={ validationSchema }
          onSubmit={ onSubmit }
        >
          { ({ handleSubmit, ...props }) => (
            <Form className="campaign-form" onSubmit={ handleSubmit }>
              <Fieldset
                facebookPages={ facebookPages }
                { ...props }
              />

              <div className="form-footer">
                <a href="#" onClick={ handleGoBack } className="btn-back">{ t`Go back` }</a>
                <PrimaryButton type="submit" data-test-id="campaign-submit">
                  { t`Submit` }
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </PageContainer>
  )
}

CampaignForm.propTypes = {
  facebookPages: array.isRequired,
  onSubmit: func.isRequired,
  formValues: object
}

CampaignForm.defaultProps = {
  formValues: {}
}

export default CampaignForm

import React, { useState } from 'react'
import { func } from 'prop-types'

import { Input } from './elements'

const UrlInput = ({ onChange, ...props }) => {
  const [text, setText] = useState('')

  const handleChange = e => {
    const values = e.target.value.split(',')
    const newText = values.pop()

    setText(newText)

    values.forEach(val => onChange(val.trim()))
  }

  const handleKeyDown = e => {
    if (e.key !== 'Enter') return

    e.preventDefault()

    onChange(text.trim())
    setText('')
  }

  return (
    <Input type="text" value={ text } onChange={ handleChange } onKeyDown={ handleKeyDown }
      { ...props } />
  )
}

UrlInput.propTypes = {
  onChange: func
}

UrlInput.defaultProps = {
  onChange: () => {}
}

export default UrlInput

import React from 'react'
import styled from 'styled-components'
import { number, string, shape } from 'prop-types'
import { t } from 'ttag'
import { get } from 'lodash'

import UserRoleNavLink from 'routes/UserRoleNavLink'
import { cardBoxShadow } from 'shared/style/placeholders'
import { componentBackgroundColor, textColor } from 'shared/style/colors'
import Stats from 'shared/components/StatsIndicator'
import CampaignStats from 'shared/CampaignStats'
import campaignInsightsType from 'shared/types/campaignInsightsType'
import campaignPlaceholder from 'shared/assets/images/city-contour.png'
import { editablePhases } from 'shared/constants/campaigns/phases'

import CampaignPhase from './CampaignPhase'
import CostTrend from './CostTrend'

const Item = ({ className, campaign }) => {
  const campaignStats = new CampaignStats(campaign)

  const logo = get(campaign, 'logo.thumbnail') || campaignPlaceholder

  const campaignFinished = editablePhases.includes(campaign.phase)
  const campaignUrl = `/campaigns/${campaign.uuid}` + (campaignFinished ? '/edit' : '')

  return (
    <UserRoleNavLink className={ className } to={ campaignUrl } data-test-id='campaign-item' >
      <CampaignImage style={{ backgroundImage: `url(${logo})` }} />
      <div className="campaign-name">
        <CampaignName>{campaign.name}</CampaignName>
        <CampaignPhase phase={ campaign.phase } />
      </div>

      <div className="stats-list">
        <Stats
          name={ campaignStats.goalTypePresentation }
          value={ campaignStats.actualGoalNumber }
          percentage={ campaignStats.goalPercentage }/>

        <Stats
          name={ campaignStats.costPerGoalUnitPresentation }
          value={ campaignStats.costPerGoalUnit }
          percentage={ campaignStats.costPerGoalUnitPercentage }/>

        <Stats
          name={ t`Spent` }
          value={ campaignStats.totalSpent }
          percentage={ campaignStats.spentPercentage }/>

        <Stats
          name={ t`Time spent` }
          value={ campaignStats.timeSpentPresentation }
          percentage={ campaignStats.timePercentage }/>

        <CostTrend
          name={ `${campaignStats.costPerGoalUnitPresentation} ${ t`trend` }` }
          values={ campaignStats.trend }
        />
      </div>
    </UserRoleNavLink>
  )
}

const CampaignName = styled.div`
  color: ${textColor};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 0.125rem;
`

const CampaignImage = styled.div`
  height: 4rem;
  width: 4rem;
  background-size: cover;
`

Item.propTypes = {
  campaign: shape({
    insights: campaignInsightsType.isRequired,
    name: string.isRequired,
    phase: string.isRequired,
    specification: shape({
      goals: shape({
        type: string.isRequired,
        number: number
      }).isRequired
    }).isRequired,
    uuid: string.isRequired,
    durationInDays: number.isRequired,
    daysSinceStart: number.isRequired,
    logo: shape({
      thumbnail: string
    })
  }).isRequired,
  className: string.isRequired
}

export default styled(Item)`
  ${cardBoxShadow};

  align-items: center;
  background-color: ${componentBackgroundColor};
  border-radius: 4px;
  display: flex;
  margin-bottom: 0.75rem;
  overflow: hidden;

  :hover {
    text-decoration: none;
  }

  .campaign-name {
    margin: 0 1rem;
    width: 30%;
  }

  .stats-list {
    display: flex;

    > * {
      margin-right: 1rem;
    }

    > *:last-child {
      margin-right: 0;
    }
  }
`

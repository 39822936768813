import React, { useCallback } from 'react'
import { t } from 'ttag'

import ProjectsList from 'shared/components/ProjectsList'

const Projects = () => {
  const projectCardLinkBuilder = useCallback(
    (project) => `/projects/${project.uuid}/stages/create`
    , []
  )

  return (
    <ProjectsList
      pageTitle={ t`Create stage - Choose project` }
      projectCardLinkBuilder={ projectCardLinkBuilder }
    />
  )
}

export default Projects

import gql from 'graphql-tag'

export default gql`
  query ProjectSearch($query: String) {
    projectSearch(query: $query) {
      uuid
      name
    }
  }
`

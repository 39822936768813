import React, { memo, useState } from 'react'
import { get }                   from 'lodash'
import styled                    from 'styled-components'
import { bool, func, shape, string } from 'prop-types'

import { IconSuccess, IconZoomIn }    from 'shared/components/ui/icons'
import { darkRoseColor }              from 'shared/style/colors'
import assetType                      from 'shared/types/assetType'
import ConfirmationModal              from 'shared/components/Gallery/Lightbox/ConfirmationModal'

const SelectedAsset = ({
  onAssetAssignmentRemove,
  removedAssetAssignment,
  onClick,
  assetAssignment,
  assetableType,
  displayEnabled,
  allowedSize,
  previewOnClick
}) => {
  const [usedInCampaigns, setUsedInCampaigns] = useState([])

  const removeAssetAssignment = (confirmRemoval = false) => {
    onAssetAssignmentRemove(assetAssignment.uuid, confirmRemoval).then((response) => {
      const usedInCampaigns = get(response, 'data.removeAssetAssignment.usedInCampaigns')
      const errors = get(response, 'data.removeAssetAssignment.errors') || []
      if (errors.length) {
        setUsedInCampaigns(usedInCampaigns)
      } else {
        setUsedInCampaigns([])
        removedAssetAssignment(assetAssignment.uuid)
      }
    })
  }

  const handleAssetClick = (e) => {
    const classList = e.target.classList
    const parentClassList = e.target.parentElement.classList

    if (classList.contains('asset-remove-button') || parentClassList.contains('icon-zoom-plus')) {
      return
    }

    onClick && onClick()
  }

  const assetDeletable = assetAssignment.asset.deletable
  const asset = assetAssignment.asset

  return (
    <>
      <AssetContainer
        onClick={ handleAssetClick }
        allowedSize={ allowedSize }
        style={{
          backgroundColor: (
            asset.mediaType === 'logo' && asset.backgroundColor
          )
        }}
        data-test-id="asset-container"
      >
        { (assetableType === 'Campaign' || assetableType === 'ContentImage' || assetDeletable) && (
          <>
            { displayEnabled && (
              <div onClick={ () => previewOnClick() }>
                <IconZoomIn  />
              </div>
            ) }
            <button
              type="button"
              className="asset-remove-button"
              onClick={ () => removeAssetAssignment(false) }>
              ✖
            </button>
          </>
        ) }
        {
          (displayEnabled && asset.enabled) && (
            <IconSuccess/>
          )
        }
        <img
          className={ !allowedSize || (displayEnabled && !asset.enabled) ? 'disabled' : 'enabled' }
          src={ asset.thumbnail } alt="Media" />
      </AssetContainer>
      <ConfirmationModal
        onHide={ () => setUsedInCampaigns([]) }
        onConfirm={ () => removeAssetAssignment(true) }
        usedInCampaigns={ usedInCampaigns }
      />
    </>
  )
}

SelectedAsset.propTypes = {
  allowedSize: bool.isRequired,
  assetAssignment: shape({
    uuid: string.isRequired,
    asset: assetType.isRequired,
  }).isRequired,
  onAssetAssignmentRemove: func.isRequired,
  assetableType: string,
  displayEnabled: bool,
  onClick: func,
  previewOnClick: func,
  removedAssetAssignment: func
}

SelectedAsset.defaultProps = {
  assetableType: '',
  displayEnabled: false,
  onClick: undefined,
  previewOnClick: undefined,
  removedAssetAssignment: undefined
}

const AssetContainer = styled.div`
  cursor: ${ ({ onClick, allowedSize }) => allowedSize && onClick ? 'pointer' : 'default' };
  overflow: hidden;
  padding-top: 70%;
  position: relative

  .icon-success {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
  }

  img.disabled {
    opacity: .3
  }

  .icon-zoom-plus,
  .asset-remove-button {
    border-radius: 0 0 0 .25rem;
    background: rgba(255, 255, 255, 0.5);
    border: none
    opacity: 0
    padding: 0
    position: absolute
    top: 0
    transition: all .5s ease
    z-index: 1
  }

  .icon-zoom-plus {
    right: 20px
    line-height: 1rem
  }

  .asset-remove-button {
    line-height: 0
    height: 1rem
    width: 1rem
    right: 0
    color: ${darkRoseColor}
  }

  &:hover {
    .icon-zoom-plus,
    .asset-remove-button {
      opacity: 1
    }
  }

  img {
    position: absolute
    top: 0
    left: 0
    height: 100%
    object-fit: cover
    object-position: center
    width: 100%
  }
`

export default memo(SelectedAsset, () => true)

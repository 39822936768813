import React from 'react'
import { oneOf, func } from 'prop-types'

import options from 'shared/constants/leadProgress'

import {
  Container,
  Tab,
  Arrows,
  ArrowTop,
  ArrowBottom
} from './elements'

const LeadProgress = ({ value, onChange }) => {
  const activeIndex = Math.max(0, options.findIndex(el => el.value === value))

  return (
    <Container data-test-id='lead-progress'>
      {options.map((el, idx) => {
        const active = idx <= activeIndex

        return (
          <Tab key={ el.value } active={ active } onClick={ () => onChange(el.value) }>
            { el.label }

            { (idx < options.length - 1) && (
              <Arrows>
                <ArrowTop active={ active }/>
                <ArrowBottom active={ active }/>
              </Arrows>
            )}
          </Tab>
        )
      })}
    </Container>
  )
}

LeadProgress.propTypes = {
  onChange: func.isRequired,
  value: oneOf(options.map(el => el.value)).isRequired
}

export default LeadProgress

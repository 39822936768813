import { gql } from 'apollo-boost'

import specification from 'graphql/fragments/specification'

export default gql`
  query CampaignSpecification($campaignUuid: ID!) {
    campaignSpecification(campaignUuid: $campaignUuid) {
      ... Specification
    }
  }
  ${specification}
`

import React, { useState } from 'react'
import { t }               from 'ttag'
import { bool, func, string } from 'prop-types'

import toastError     from 'shared/toastError'
import BaseEmptyAsset from 'shared/components/ui/EmptyAsset'
import mimeTypes, { typesTranslations } from 'shared/constants/mimeTypes'

const areFilesAllowed = (files, mediaType) => {
  return [...files].every((file) => {
    const allowedMimeTypes = mimeTypes[mediaType]
    if(!allowedMimeTypes) return true

    return mimeTypes[mediaType].includes(file.type)
  })
}

const EmptyAsset = ({ mediaType, isUploading, onDrop, uploadable, ...props }) => {
  const [isOver, setIsOver] = useState(false)

  const handleDragOver = (e) => {
    e.preventDefault()

    setIsOver(true)
  }

  const handleDrop  = (e) => {
    e.preventDefault()

    if (areFilesAllowed(e.dataTransfer.files, mediaType)) {
      onDrop({ target: e.dataTransfer })
    } else {
      toastError(t`Wrong file type`)
    }

    setIsOver(false)
  }

  const handleDragLeave = () => {
    setIsOver(false)
  }

  const title = (
    <span>
      { isUploading ? (
        t`Uploading...`
      ) : (
        `${ t`Click or drag` } ${ uploadable || typesTranslations[mediaType] } ${ t`here` }`
      )}
    </span>
  )

  return (
    <div
      onDragOver={ handleDragOver }
      onDragLeave={ handleDragLeave }
      onDrop={ handleDrop }
    >
      <BaseEmptyAsset
        title={ title }
        isOver={ isOver }
        { ...props }
      />
    </div>
  )
}

EmptyAsset.propTypes = {
  mediaType: string.isRequired,
  isUploading: bool,
  onDrop: func,
  uploadable: string
}

EmptyAsset.defaultProps = {
  isUploading: false,
  onDrop: () => {},
  uploadable: null
}

export default EmptyAsset

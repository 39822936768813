import React from 'react'
import { t } from 'ttag'
import { array } from 'prop-types'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'

import Select from 'shared/components/ui/Select'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import facilitiesQuery from 'graphql/queries/facilities'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1
  }),
}

const valueToOption = element => ({
  uuid: element.uuid,
  value: element.key,
  label: element.title
})

const FacilitySelect = ({ className, facilities, ...props }) => {
  const { data, loading } = useQuery(facilitiesQuery, {
    variables: {
      query: {
        page: 1,
      }
    }
  })

  const selectedOptions = facilities.map((facilityObject) => {
    return {
      uuid: facilityObject.uuid,
      value: facilityObject.key,
      label: facilityObject.title
    }
  })

  return (
    <div className={ className }>
      <Select
        isMulti
        closeMenuOnSelect={ false }
        hideSelectedOptions={ false }
        value={ selectedOptions }
        options={ loading ? [] : data.facilities.items.map(valueToOption) }
        styles={ styles }
        placeholder={ t`Select facilities` }
        noOptionsMessage={ () => t`No options` }
        { ...props }
      />
    </div>
  )
}

FacilitySelect.propTypes = {
  facilities: array.isRequired
}

export default styled(FacilitySelect)`
  display: flex;
  flex-direction: column;
  width: 100%;
`


import React       from 'react'
import { string }  from 'prop-types'
import styled      from 'styled-components'
import { NavLink } from 'react-router-dom'

import { bluishColor }     from 'shared/style/colors'

const Links = ({ className, userType }) => {
  return (
    <div className={ className }>
      <NavLink
        to={ userType === 'admin' ? '/admin/login' : '/login' }
        className="btn btn-primary"
      >
        Sign in again
      </NavLink>
    </div>
  )
}

Links.propTypes = {
  className: string.isRequired,
  userType: string.isRequired,
}

export default styled(Links)`
  align-items: center
  display: flex;
  justify-content: center;
  margin: 1.65rem auto 0;
  width: 18.5rem;

  .btn-primary {
    background-color: ${bluishColor}
    border-color: ${bluishColor}
    font-size: 0.875rem;
    padding: .4rem .85rem;
  }
`

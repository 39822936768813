import gql from 'graphql-tag'

import stageSalesArgument from 'graphql/fragments/stageSalesArgument'

export default gql`
  mutation updateStageSalesArgument(
    $uuid: ID!
    $input: StageSalesArgumentInput!
  ) {
    updateStageSalesArgument(
      uuid: $uuid
      input: $input
    ) {
      salesArgument {
        ... StageSalesArgument
      }
    }
  }
  ${stageSalesArgument}
`

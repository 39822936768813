import { useApolloClient } from 'react-apollo'

import campaignFragment        from 'graphql/fragments/campaign'
import { buildFragmentParams } from 'shared/helpers/graphql'
import useCurrentCampaignUuid  from 'shared/hooks/useCurrentCampaignUuid'

export default function useCurrentCampaign() {
  const campaignUuid = useCurrentCampaignUuid()
  const client = useApolloClient()
  const object = { __typename: 'Campaign', uuid: campaignUuid }
  const fragmentParams = buildFragmentParams(object, campaignFragment)
  const fragmentData = client.readFragment(fragmentParams)

  return fragmentData
}

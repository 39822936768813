import React             from 'react'
import { object, func, string } from 'prop-types'
import { t }             from 'ttag'
import { Field, Formik } from 'formik'

import validationSchema from 'shared/components/UserForm/validationSchema'
import Modal            from 'shared/components/ui/Modal'
import {
  ErrorMessage, Input, Label, Row
}                       from 'shared/components/UserForm/elements'
import PhoneCodeSelect  from 'shared/components/PhoneCodeSelect'
import Button           from 'shared/components/ui/Button'

import CompanySelect     from './CompanySelect'
import CompanyRoleSelect from './CompanyRoleSelect'

const UserForm = ({ user, onHide, onSubmit, companyUuid }) => {
  const initialValues = {
    name:        user?.name || '',
    email:       user?.email || '' ,
    phoneCode:   user?.phoneNumber?.code || '',
    phoneNumber: user?.phoneNumber?.number || '',
    companyUuid: (user.companies && user.companies[0]?.uuid) || companyUuid || '',
    roleId:      (user.companyRoles && user.companyRoles[0]?.id) || ''
  }

  const formKeys = Object.keys(initialValues)
  const formValues = Object.fromEntries(
    formKeys.map(key => [key, initialValues[key]])
  )

  return (
    <Formik
      validationSchema={ validationSchema }
      initialValues={ formValues }
      onSubmit={ onSubmit }
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => (
        <form onSubmit={ handleSubmit }>
          <Modal.Body>
            <Row>
              <Label>{ t`Name` }</Label>
              <Field
                name="name"
                as={ Input }
                isInvalid={ touched.name && errors.name }
              />
              {
                touched.name && errors.name
                  ? <ErrorMessage>{ errors.name }</ErrorMessage>
                  : null
              }
            </Row>

            <Row>
              <Label>{ t`Email` }</Label>
              <Field
                name="email"
                as={ Input }
                isInvalid={ touched.email && errors.email }
              />
              {
                touched.email && errors.email
                  ? <ErrorMessage>{ errors.email }</ErrorMessage>
                  : null
              }
            </Row>

            <Row>
              <Label>{ t`Phone` }</Label>
              <div className="d-flex">
                <Field
                  name="phoneCode"
                  as={ PhoneCodeSelect }
                  onChange={ value => setFieldValue('phoneCode', value) }
                  isInvalid={ touched.phoneCode && errors.phoneCode }
                />
                <Field
                  name="phoneNumber"
                  type="text"
                  as={ Input }
                  isInvalid={ touched.phoneNumber && errors.phoneNumber }
                />
              </div>
              {
                (touched.phoneCode && errors.phoneCode)
                || (touched.phoneNumber && errors.phoneNumber)
                  ? <ErrorMessage>{ errors.phoneCode || errors.phoneNumber }</ErrorMessage>
                  : null
              }
            </Row>

            {!companyUuid && (
              <Row>
                <Label>{ t`Company` }</Label>
                <Field
                  name="company"
                  as={ CompanySelect }
                  value={ values.companyUuid }
                  isDisabled={ !!user?.uuid }
                  onChange={ value => setFieldValue('companyUuid', value) }
                  isInvalid={ touched.companyUuid && errors.companyUuid }
                />
                {
                  touched.companyUuid && errors.companyUuid
                    ? <ErrorMessage>{ errors.companyUuid }</ErrorMessage>
                    : null
                }
              </Row>
            )}

            { values.companyUuid && !companyUuid && (
              <Row>
                <Label>{ t`Role` }</Label>
                <Field
                  name="role"
                  as={ CompanyRoleSelect }
                  value={ values.roleId }
                  companyUuid={ values.companyUuid }
                  onChange={ value => setFieldValue('roleId', value) }
                  isInvalid={ touched.roleId && errors.roleId }
                />
                {
                  touched.roleId && errors.roleId
                    ? <ErrorMessage>{ errors.roleId }</ErrorMessage>
                    : null
                }
              </Row>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button type="button" onClick={ onHide }>{ t`Cancel` }</Button>
            <Button type="submit">{ t`Save` }</Button>
          </Modal.Footer>
        </form>
      )}
    </Formik>
  )
}

UserForm.propTypes = {
  onSubmit: func.isRequired,
  companyUuid: string,
  onHide: func,
  user: object
}

UserForm.defaultProps = {
  companyUuid: null,
  onHide: () => {},
  user: undefined
}

export default UserForm

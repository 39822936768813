import React from 'react'
import { arrayOf, shape, string, func } from 'prop-types'
import styled from 'styled-components'

import { cardPaddingXSize } from 'shared/style/sizes'
import { greyishWhiteColor } from 'shared/style/colors'
import { cardBorderValue } from 'shared/style/placeholders'

import NavItem from './NavItem'

const Navbar = ({
  navItems,
  currentItem,
  setCurrentItem,
  getLabelForType
}) => {
  return (
    <Container>
      {navItems.map(({ title, type, enabled }) => (
        <NavItem
          enabled={ enabled }
          key={ type }
          label={ title || getLabelForType(type) }
          active={ type === currentItem }
          data-test-id={ `tab-${type}` }
          onClick={ () => setCurrentItem(type) } />
      ))}
    </Container>
  )
}

const Container = styled.div`
  border-bottom: ${cardBorderValue};

  background-color: ${greyishWhiteColor};
  display: flex;
  margin-left: -${cardPaddingXSize};
  margin-right: -${cardPaddingXSize};
  margin-top: -1rem;
  margin-bottom: 1rem;
`
Navbar.propTypes = {
  currentItem: string.isRequired,
  navItems: arrayOf(shape({
    type: string.isRequired,
    title: string
  })).isRequired,
  setCurrentItem: func.isRequired,
  getLabelForType: func
}

Navbar.defaultProps = {
  getLabelForType: (type) => type
}

export default Navbar

import gql from 'graphql-tag'

import audienceLocations from 'graphql/fragments/audienceLocations'

export default gql`
  mutation duplicateAudienceLocations(
    $sourceAudienceUuid: ID!
    $targetAudienceUuids: [ID!]!
  ) {
    duplicateAudienceLocations(
      sourceAudienceUuid: $sourceAudienceUuid
      targetAudienceUuids: $targetAudienceUuids
    ) {
      targetAudiences {
        uuid
        locations {
          ... AudienceLocations
        }
      }
    }
  }
  ${audienceLocations}
`

import React from 'react'
import { object, string } from 'prop-types'
import styled from 'styled-components'
import { t } from 'ttag'
import { useMutation } from 'react-apollo'

import ThriftyInput from 'shared/components/ui/ThriftyInput'
import createFacilityDetail from 'graphql/mutations/facilities/details/create'
import updateFacilityDetail from 'graphql/mutations/facilities/details/update'
import FormLabel from 'shared/components/ui/FormLabel'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'

const FacilityDetail = ({ className, facilityDetail, projectUuid }) => {
  const [runCreateFacilityDetail] = useMutation(createFacilityDetail)
  const [runUpdateFacilityDetail] = useMutation(updateFacilityDetail)

  const updateDetail = (value) => {
    if (facilityDetail.detailUuid) {
      return runUpdateFacilityDetail({
        variables: {
          detailUuid: facilityDetail.detailUuid,
          description: value
        }
      })
    } else {
      return runCreateFacilityDetail({
        variables: {
          facilityUuid: facilityDetail.uuid,
          description: value,
          projectUuid,
        }
      })
    }
  }

  return (
    <div className={ className }>
      <div className='group'>
        <StyledFormLabel>{ facilityDetail.title }</StyledFormLabel>
        <InputWrapper>
          <ThriftyInput
            className='w-100'
            value={ facilityDetail.description }
            placeholder={ t`Please provide description` }
            as='textarea'
            onUpdate={ updateDetail } />
        </InputWrapper>
      </div>
    </div>
  )
}

const StyledFormLabel = styled(FormLabel)`
  font-size: 1rem;
`

FacilityDetail.propTypes = {
  facilityDetail: object.isRequired,
  projectUuid: string.isRequired
}

export default styled(FacilityDetail)`
  margin-top: 1rem;
  margin-bottom: .5rem;

  input, textarea {
    box-shadow: none;
  }
  
  .facility-description {
    width: 80%;
  }
`

import { useState } from 'react'
import { get, groupBy } from 'lodash'
import { useMutation }  from 'react-apollo'

import { buildFragmentParams } from 'shared/helpers/graphql'
import attachAssetMutation     from 'graphql/mutations/assetable/attachFile'
import removeAssetAssignmentMutation from 'graphql/mutations/assetable/removeAssetAssignment'
import projectAssetAssignments from 'graphql/fragments/projectAssetAssignments'

export default function useMediaData({ projectUuid, assetAssignments, refetchQueries = {} }) {
  const assets = assetAssignments.map(({ asset }) => asset)
  const assetsByType = groupBy(assets, 'mediaType')

  const [usedInCampaigns, setUsedInCampaigns] = useState([])
  const [assetToRemove, setAssetToRemove] = useState(undefined)
  const [attachAsset] = useMutation(attachAssetMutation)
  const [runRemoveAssetAssignment] = useMutation(removeAssetAssignmentMutation)

  const resetRemovalConfirmation = () => {
    setAssetToRemove(undefined)
    setUsedInCampaigns([])
  }

  const removeAssetAssignment = (removedAsset, confirmRemoval = false) => {
    const assetAssignment = assetAssignments.find(({ asset }) => asset.uuid === removedAsset.uuid)

    if (!assetAssignment) {
      return
    }

    return runRemoveAssetAssignment({
      variables: {
        confirmRemoval,
        assetAssignmentUuid: assetAssignment.uuid,
      },
      refetchQueries: refetchQueries.removeAssetAssignment || [],
    }).then((response) => {
      if (!confirmRemoval) {
        setAssetToRemove(removedAsset)
        setUsedInCampaigns(get(response, 'data.removeAssetAssignment.usedInCampaigns'))
      } else {
        resetRemovalConfirmation()
      }
    })
  }

  const confirmAssetAssignmentRemoval = () => {

    removeAssetAssignment(assetToRemove, true)
  }

  const attachProjectAsset = (uploadedAssets) => {
    uploadedAssets.forEach(({ signedBlobId, mediaType }) => {
      attachAsset({
        variables: {
          mediaType,
          blobId: signedBlobId,
          assetableUuid: projectUuid,
          assetableType: 'Project',
        },
        update: (cache, response) => {
          const fragmentParams = buildFragmentParams(
            { __typename: 'Project', uuid: projectUuid },
            projectAssetAssignments
          )
          const fragmentData = cache.readFragment(fragmentParams)

          const assetAssignment = get(response, 'data.attachFileToAssetable.assetAssignment')

          cache.writeFragment({
            ...fragmentParams,
            data: {
              ...fragmentData,
              originalAssetAssignments: [
                ...fragmentData.originalAssetAssignments,
                assetAssignment
              ]
            }
          })
        }
      })
    })
  }

  return {
    usedInCampaigns,
    attachProjectAsset,
    assetToRemove,
    resetRemovalConfirmation,
    removeAssetAssignment,
    confirmAssetAssignmentRemoval,
    assetsByType
  }
}

import React from 'react'
import styled from 'styled-components'
import { string, func, bool } from 'prop-types'
import classNames from 'classnames'

import { bluishColor } from 'shared/style/colors'

const Nav = ({ label, onClick, className, active, enabled, ...props }) => {
  return (
    <div
      className={ classNames(className, { disabled: !enabled, active: active }) }
      onClick={ () => { enabled && onClick() } }
      data-test-id='channel-nav-item'
      { ...props }
    >
      { label }
    </div>
  )
}

Nav.propTypes = {
  className: string.isRequired,
  label: string.isRequired,
  active: bool,
  enabled: bool,
  onClick: func
}

Nav.defaultProps = {
  onClick: () => {},
  active: false,
  enabled: true
}

export default styled(Nav)`
  background-color: transparent;
  box-shadow: inset 0 -0.25rem transparent;
  border-right: 1px solid #e3e8ee;
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding: 1.25rem 1rem 1rem 1rem;
  text-align: center;

  &.active {
    background-color: #fff;
    border-bottom-color: #2578ce;
    box-shadow: inset 0 -0.25rem ${bluishColor};
  }

  &.disabled {
    cursor: default;
    background: repeating-linear-gradient(
      -45deg,
      #fafcfd,
      #fafcfd 12px,
      #eeeff0 12px,
      #eeeff0 24px
    );
  }
`

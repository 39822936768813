import React        from 'react'
import { useQuery } from '@apollo/react-hooks'

import sessionsQuery from 'graphql/queries/users/sessions'
import Table         from 'shared/components/Profile/Sessions/Table'

const SessionsTable = () => {
  const { data, loading } = useQuery(sessionsQuery)
  const sessions = loading ? [] : data.sessions

  return (
    <Table data={ sessions } />
  )
}

export default SessionsTable

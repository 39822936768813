import columns from './columns'

const SIZE_PER_PAGE = 5

const defaultSorted = []

export default function useLeadsTable(project, stageTab, leads) {
  let data = leads

  if (stageTab !== 0) {
    const stageId = project.completeStages[stageTab - 1].id
    data = leads.filter((lead) => {
      return lead.stageId === stageId
    })
  }

  const tableOptions = {
    data,
    columns,
    defaultSorted,
    bordered: false,
    keyField: 'uuid',
    remote: { pagination: false },
  }

  const paginationOptions = data.length > SIZE_PER_PAGE ? ({
    sizePerPage: SIZE_PER_PAGE,
    totalSize: data.length,
  }) : null

  return {
    tableOptions,
    paginationOptions
  }
}

import { string, number, shape } from 'prop-types'

export default shape({
  uuid: string.isRequired,
  operator: string.isRequired,
  latitude: number.isRequired,
  longitude: number.isRequired,
  locationType: string.isRequired,
  radius: number,
  title: string.isRequired,
  country: string,
  placeName: string,
  externalId: string
})

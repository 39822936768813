import React from 'react'
import { t } from 'ttag'
import { func, array, string } from 'prop-types'

import NewItemButton from 'shared/components/ui/NewItemButton'

import useSalesArgumentsForm from './useSalesArgumentsForm'
import Item from './Item'

const SalesArgumentsForm = (props) => {
  const {
    salesArguments,
    stageUuid,
    push,
    remove
  } = props

  const {
    updateSalesArgument,
    addSalesArgument,
    deleteSalesArgument,
    copyPreviousSalesArguments
  } = useSalesArgumentsForm(push, remove, stageUuid)

  return (
    <>
      <ul data-test-id="sales-arguments-form">
        {salesArguments.map((argument, idx) =>
          <Item
            key={ argument.uuid }
            idx={ idx }
            stageUuid={ stageUuid }
            argument={ argument }
            handleDelete={ deleteSalesArgument }
            handleUpdate={ updateSalesArgument }
          />
        )}
      </ul>

      <NewItemButton
        className="ml-5"
        onClick={ addSalesArgument }
        data-test-id='new-sales-argument-button'
      >
        { t`Add new sales argument` }
      </NewItemButton>

      <NewItemButton className="ml-0" onClick={ copyPreviousSalesArguments } displayIcon={ false }>
        { t`Import sales arguments from previous stage(s)` }
      </NewItemButton>
    </>
  )
}

SalesArgumentsForm.propTypes = {
  push: func.isRequired,
  remove: func.isRequired,
  salesArguments: array.isRequired,
  stageUuid: string.isRequired
}

export default SalesArgumentsForm

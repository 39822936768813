import * as Sentry from '@sentry/react'

const notifyGraphqlError = ({ operation, response }) => {
  Sentry.captureException(
    new Error(`Graphql error: ${response?.errors[0]['message']}`), {
      contexts: {
        'Operation details': operation,
        'Response errors': sentryResponseErrors(response)
      },
    }
  )
}

const notifyNetworkError = ({ operation, networkError = {} }) => {
  const response = networkError.response

  Sentry.captureException(new Error(
    `Network error: ${operation?.operationName}/${response?.statusText}`
  ), {
    contexts: {
      'Operation details': operation,
      'Response errors': sentryResponseErrors(response)
    },
  })
}

const sentryResponseErrors = (response) => ({ ...response?.errors?.map(error => error.message) })

export { notifyGraphqlError, notifyNetworkError }

export default Sentry

import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { Form } from 'react-bootstrap'
import { bool, func } from 'prop-types'
import { Formik, Field } from 'formik'

import Button from 'shared/components/ui/Button'
import Modal from 'shared/components/ui/Modal'
import { buttonTextColor } from 'shared/style/colors'
import campaignType from 'shared/types/campaignType'

const EditCampaignModal = ({ campaign, show, onHide, onSave }) => {
  const initialValues = {
    name: campaign.name || '',
    dsaBeneficiary: campaign.dsaBeneficiary || '',
    dsaPayor: campaign.dsaPayor || '',
  }

  const handleSubmit = values => onSave(values)

  return (
    <Modal centered show={ show } onHide={ onHide }>
      <Modal.Header closeButton>
        <Header>{ t`Edit campaign details` }</Header>
      </Modal.Header>

      <Formik initialValues={ initialValues } onSubmit={ handleSubmit }>
        {({ handleSubmit }) => (
          <form onSubmit={ handleSubmit }>
            <Modal.Body>
              <Group>
                <label>{ t`Campaign name` }</label>
                <Field name="name" as={ Form.Control } />
              </Group>

              <Group>
                <label>{ t`Beneficiary` }</label>
                <Field name="dsaBeneficiary" as={ Form.Control } />
              </Group>

              <Group>
                <label>{ t`Payor` }</label>
                <Field name="dsaPayor" as={ Form.Control } />
              </Group>

            </Modal.Body>

            <Modal.Footer>
              <Button type="button" onClick={ onHide }>{ t`Cancel` }</Button>
              <Button type="submit">{ t`Save` }</Button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

const Group = styled.div`
  margin-bottom: 1rem;

  input {
    box-shadow: none;
  }

  label {
    color: ${buttonTextColor};
    font-size: 0.875rem;
  }
`

const Header = styled.h3`
  margin: 0;
  font-size: 1.25rem;
`

EditCampaignModal.propTypes = {
  campaign: campaignType.isRequired,
  onHide: func,
  onSave: func,
  show: bool
}

EditCampaignModal.defaultProps = {
  show: false,
  onHide: () => {},
  onSave: () => {}
}

export default EditCampaignModal

import { useRef } from 'react'

import useIsOpened from 'shared/hooks/useIsOpened'

export default function usePopover() {
  const { isOpened, open, close, toggle } = useIsOpened(false)
  const target = useRef(null)
  const container = useRef(null)

  return { isOpened, open, close, toggle, target, container }
}

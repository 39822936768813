import { gql } from 'apollo-boost'

export default gql`
  query Stages($projectUuid: ID!) {
    stages(projectUuid: $projectUuid) {
      uuid
      name
      projectName
      projectUuid
      unitsCount
      unitsSold
      missingData
      activeCampaignsCount
      image {
        thumbnail
      }
    }
  }
`

import React, { useContext } from 'react'
import styled from 'styled-components'
import { number, string, object } from 'prop-types'
import { msgid, ngettext } from 'ttag'

import { havelockBlue } from 'shared/style/colors'

import { UnitsTableContext } from './UnitsTable'

const InlineButton = ({ quantity, unit, className }) => {
  const { setShowModal, setCurrentUnitUuid, setCurrentUnitName } = useContext(UnitsTableContext)

  const handleClick = () => {
    setShowModal(true)
    setCurrentUnitUuid(unit.uuid)
    setCurrentUnitName(unit.unitId)
  }

  return (
    <button
      onClick={ handleClick }
      className={ className }
      type='button'
      data-test-id='open-unit-documents-modal'
    >
      { ngettext(msgid`${quantity} document`, `${quantity} documents`, quantity) }
    </button>
  )
}

InlineButton.defaultProps = {
  className: null
}

InlineButton.propTypes = {
  quantity: number.isRequired,
  unit: object.isRequired,
  className: string
}

export default styled(InlineButton)`
  display: inline
  background-color: transparent
  padding: 0
  margin: 0
  border: none
  text-decoration: underline
  color: ${havelockBlue}
`

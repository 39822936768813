import gql from 'graphql-tag'

export default gql`
  fragment StageUnitBalcony on StageUnitBalcony {
    uuid
    size
    name
    facingDirection
    createdAt
  }
`

import React, { useCallback, memo }   from 'react'
import { string, func, bool, object } from 'prop-types'
import { isEqual }                    from 'lodash'

import LoadingSelect  from 'shared/components/ui/LoadingSelect'
import districtsQuery from 'graphql/queries/filters/districts'

const DistrictSelect = ({ value, name, isInvalid, onChange, styles }) => {
  const className = isInvalid ? 'is-invalid' : ''
  const saveFormValue = useCallback(({ value }) => {
    onChange({ target: { name, value } })
  }, [onChange])

  return (
    <LoadingSelect
      className={ className }
      query={ districtsQuery }
      value={ value }
      styles={ styles }
      onChange={ saveFormValue }
      valueAttribute="uuid"
      labelAttribute="title"
    />
  )
}

DistrictSelect.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  isInvalid: bool,
  styles: object,
  value: string
}

DistrictSelect.defaultProps = {
  value: undefined,
  isInvalid: undefined,
  styles: {}
}

export default memo(DistrictSelect, isEqual)

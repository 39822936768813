import React, { useCallback } from 'react'
import { t } from 'ttag'
import PropTypes from 'prop-types'

import ActiveStatus from 'shared/components/Snapshots/ActiveStatus'
import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'

const StageSaleStatus = ({ saleState, updateStage, stageUuid, className }) => {
  const saleStates = [
    {
      key: 'coming_for_sale',
      title: t`Coming for sale`
    },
    {
      key: 'for_sale',
      title: t`For sale`
    }
  ]

  const updateSaleState = useCallback((saleStateKey) => {
    updateStage({ saleState: saleStateKey })
  }, [saleState])

  return (
    <EmbeddedCardSection titleText={ t`Active sales status` } className={ className }>
      <ActiveStatus
        title={ t`Active sales stage status` }
        saleStates={ saleStates }
        saleState={ saleState }
        updateSaleState={ updateSaleState }
        radioButtonName='stageSaleState'
        recordUuid={ stageUuid }
        data-test-id='active-stage-status'
      />
    </EmbeddedCardSection>
  )
}

StageSaleStatus.propTypes = {
  saleState: PropTypes.string.isRequired,
  stageUuid: PropTypes.string.isRequired,
  updateStage: PropTypes.func.isRequired
}

export default StageSaleStatus

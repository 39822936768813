import * as Yup from 'yup'
import { t }    from 'ttag'

export default Yup.array().
  min(1, t`Please add at least 1 stakeholder.`).
  of(
    Yup.object().shape({
      responsibility: Yup.string().required(t`Please fill a role.`),
      user: Yup.object().required(t`Please select a user.`).nullable()
    })
  )

import { toUpper } from 'lodash'

import {
  sinceDate,
  sinceNowDate,
} from 'shared/helpers/date'

import forecastValidationSchema from './forecastValidationSchema'
import minChannelSpending from './minChannelSpending'

const MIN_SPENDING_PER_DAY = {
  'AUD': 2,
  'NOK': 12,
  'SEK': 12,
  'USD': 1.5,
  'GBP': 1.2,
  'EUR': 1.3
}

export default ({ payload }) => {
  const campaign = payload
  const currency = toUpper(campaign.product.promotable.currency)
  const minSpending = MIN_SPENDING_PER_DAY[currency]

  const minFacebookChannelSpending = minChannelSpending(campaign, 'facebook', minSpending)
  const minInstagramChannelSpending = minChannelSpending(campaign, 'instagram', minSpending)
  const minPortalChannelSpending = minChannelSpending(campaign, 'portal', minSpending)

  forecastValidationSchema.validateSync(campaign, {
    abortEarly: false,
    context: {
      currency: currency,
      minSpending: minSpending,
      minStartDate: sinceNowDate(5, 'minutes'),
      minEndDate: sinceDate(campaign.startDate, 1, 'day'),
      minFacebookChannelSpending: minFacebookChannelSpending,
      minInstagramChannelSpending: minInstagramChannelSpending,
      minPortalChannelSpending: minPortalChannelSpending
    }
  })
}

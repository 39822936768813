import React, { createRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useApolloClient } from 'react-apollo'
import { toast } from 'react-toastify'
import { t } from 'ttag'

import EmptyAsset from 'shared/components/MediaList/EmptyAsset'
import mimeTypes from 'shared/constants/mimeTypes'
import readBlobAsDataUrl from 'shared/helpers/readBlobAsDataUrl'
import ImageEditor from 'shared/components/ui/ImageEditor'
// eslint-disable-next-line max-len
import uploadImage from 'shared/components/ProjectPortalEdit/EditForm/Agents/AgentImage/Uploader/uploadImage'
import { borderGreyColor as grey } from 'shared/style/colors'

const AgentImage = ({ project, agent, mediaType, className }) => {
  const label = t`Agent Picture`
  const [assetAssignment, setAssetAssignment] = useState(
    agent.assetAssignments?.find(({ mediaType: type }) => mediaType === type)
  )
  const [isUploading, setIsUploading] = useState(false)
  const [fileDataURI, setFileDataURI] = useState(null)
  const [fileMeta, setFileMeta] = useState(null)
  const fileInput = createRef()
  const client = useApolloClient()

  const uploadCroppedImage = useCallback(() => {
    setIsUploading(true)
    uploadImage({ client, agent, project, fileDataURI, fileMeta, mediaType })
      .then(({ data: { attachFileToAssetable: { assetAssignment } } }) => {
        setAssetAssignment(assetAssignment)
      })
      .then(() => setFileDataURI(null))
      .then(() => setIsUploading(false))
      .then(() => toast.success(t`${ label } has been uploaded`))
  }, [fileDataURI])

  const setUploadingFiles  = useCallback((e) => {
    const file = e.target.files[0]

    setFileMeta({ name: file.name, type: file.type })

    if (!file) return

    readBlobAsDataUrl(file).then((fileDataURI) => {
      setFileDataURI(fileDataURI)
    })
  }, [])

  const openUploadWindow = () => {
    fileInput.current.click()
  }

  return (
    <div className={ className }>
      { fileDataURI && (
        <ImageEditor
          fileDataURI={ fileDataURI }
          setFileDataURI={ setFileDataURI }
          onUpload={ uploadCroppedImage }
        />
      ) }
      <input
        type="file"
        accept={ mimeTypes[mediaType].join(',') }
        className="d-none"
        ref={ fileInput }
        onChange={ setUploadingFiles }
        data-test-id='file-upload-input'
      />
      {
        assetAssignment
          ? (
            <div onClick={ openUploadWindow } className="inner image-wrapper">
              <img src={ assetAssignment.asset.thumbnail } alt={ label } />
            </div>
          )
          : (<AgentEmptyAsset
            isUploading={ isUploading }
            mediaType={ mediaType }
            onClick={ openUploadWindow }
            data-test-id='empty-asset'
            onDrop={ setUploadingFiles }
          />)
      }
    </div>
  )
}

AgentImage.propTypes = {
  agent: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  project:  PropTypes.object.isRequired
}

const AgentEmptyAsset = styled(EmptyAsset)`
  height: 6rem;
  width: 6rem;
`

export default styled(AgentImage)`
  .image-wrapper {
    height: 6rem;
    width: 6rem;
    border: 2px solid ${grey};
    color: ${grey};
    cursor: pointer;
    border-radius: .5rem;
    font-size: .65rem;
    position: relative;
    text-align: center;
    overflow: hidden;

    img {
      max-width: 100%;
      height: auto;
    }
  }
`

import React from 'react'

import { combineErrors } from 'shared/helpers/inputValidation'
import ResponsibilityInput from 'shared/components/Stakeholders/ResponsibilityInput'
import isReadOnly from 'shared/components/Stakeholders/isReadOnly'

const responsibilityFormatter = (cell, row, rowIndex, { onResponsibilityUpdate, errors }) => {
  const error = combineErrors({ errors, uuid: row.uuid, field: 'responsibility' })

  return (
    <ResponsibilityInput
      responsibility={ row.responsibility }
      onResponsibilityUpdate={ onResponsibilityUpdate }
      stakeholderUuid={ row.uuid }
      readOnly={ isReadOnly(row) }
      error={ error } />
  )
}

export default responsibilityFormatter

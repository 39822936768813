import React from 'react'
import { t } from 'ttag'
import styled from 'styled-components'
import { string } from 'prop-types'
import { IconCaretRight } from 'cabanaico'

import Card from 'shared/components/ui/Card'
import { greyishBrownColor, textGreyColor } from 'shared/style/colors'

const Breadcrumbs = ({ name, className }) => (
  <Card className={ className }>
    { t`Project` }
    <IconCaretRight className='right-arrow'/>
    { name }
  </Card>
)

Breadcrumbs.propTypes = {
  name: string.isRequired
}

export default styled(Breadcrumbs)`
  color: ${greyishBrownColor};
  margin-bottom: 0.5rem;
  font-size: 0.875rem

  .right-arrow {
    transform: scale(0.875);
    margin: 0 0.5rem -0.25rem 0.5rem;
    color: ${textGreyColor}
  }

  .project-header .card-body {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem
  }
`

import React from 'react'

import matchType    from 'shared/types/matchType'
import CampaignView from 'shared/components/CampaignEdit'

const CampaignEdit = ({ match }) => (
  <CampaignView uuid={ match.params.uuid } />
)

CampaignEdit.propTypes = {
  match: matchType.isRequired
}

export default CampaignEdit

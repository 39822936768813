import { t } from 'ttag'

const validateFileSize = (file) => (
  new Promise((resolve, reject) => {
    if (file.size > 1024) {
      resolve(file)
    } else {
      reject(t`File must be at least 1kb size`)
    }
  })
)

export default validateFileSize

import React from 'react'
import { shape, string } from 'prop-types'

import { daysAreEqual, displayDate, displayTime } from 'shared/helpers/date'

const VisitsHours = ({ visitsHours: { startAt, endAt } }) => {
  return (
    <span>
      {
        daysAreEqual(startAt, endAt)
          ? `${displayDate(startAt)} - ${displayTime(startAt)} - ${displayTime(endAt)}`
          : ([
            `${displayDate(startAt)} - ${displayTime(startAt)}`,
            ' - ',
            `${displayDate(endAt)} - ${displayTime(endAt)}`
          ])
      }
    </span>
  )
}

VisitsHours.propTypes = {
  visitsHours: shape({
    startAt: string.isRequired,
    endAt: string.isRequired
  }).isRequired
}

export default VisitsHours

import React, { forwardRef } from 'react'
import { bool, func } from 'prop-types'
import { Button }     from 'react-bootstrap'
import styled         from 'styled-components'

import Caret from 'shared/components/ui/Caret'
import { greyishBrownColor } from 'shared/style/colors'

const GreyCaret = styled(Caret)`
  color: ${greyishBrownColor}
`

const CompactButton = styled(Button)`
  padding-top: 0;
  padding-bottom: 0
`

const OptionsToggle = forwardRef((props, ref) => {
  const { onClick, ['aria-expanded']: expanded } = props

  return (
    <CompactButton variant="link" ref={ ref } onClick={ onClick }>
      <GreyCaret opened={ expanded }/>
    </CompactButton>
  )
})

OptionsToggle.displayName = 'OptionsToggle'

OptionsToggle.propTypes = {
  ['aria-expanded']: bool.isRequired,
  onClick: func.isRequired
}

export default OptionsToggle

import gql from 'graphql-tag'

import creativeSet from 'graphql/fragments/creativeSet'

export default gql`
  mutation setCreativeSetLogo($uuid: ID!, $assetUuid: ID!) {
    setCreativeSetLogo(uuid: $uuid, assetUuid: $assetUuid) {
      creativeSet {
        ... CreativeSet
      }
    }
  }
  ${creativeSet}
`

import gql from 'graphql-tag'

export default gql`
  mutation RemoveLandingPage(
    $uuid: ID!
  ) {
    removeLandingPage(
      uuid: $uuid
    ) {
      landingPage {
        uuid
      }
    }
  }
`

import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'

import { textGreyColor } from 'shared/style/colors'
import Button            from 'shared/components/ui/Button'
import StaticHeader      from 'shared/components/ui/Card/StaticHeader'
import SignOutSessionsButton from 'shared/components/Profile/Sessions/SignOutSessionsButton'

const Header = ({ className }) => {
  return (
    <StaticHeader className={ className }>
      <div className="header-title">
        { t`Login sessions` }

        <p className="header-subtitle">
          { t`Places where you logged into Marketer` }
        </p>
      </div>

      <SignOutSessionsButton />
    </StaticHeader>
  )
}

export default styled(Header)`
  display: flex
  align-items: center
  justify-content: space-between
  
  .header-title {
    margin-bottom: 0
  }

  .header-subtitle {
    color: ${textGreyColor}
    font-size: .875rem
    font-weight: 400
    margin-top: .25rem
    margin-bottom: 0
  }
  
  ${Button} {
    font-size: .875rem
    font-weight: 400
    padding: .5rem 1.25rem
  }
`

import styled from 'styled-components'

import { textGreyColor } from 'shared/style/colors'

export default styled.label`
  color: ${textGreyColor}
  font-size: 0.8rem
  margin-bottom: 0
  margin-right: 1rem
  width: 8rem
`

import gql from 'graphql-tag'

import salesArgumentFragment from 'graphql/fragments/stageSalesArgument'

export default gql`
  mutation copyPreviousSalesArguments(
    $stageUuid: ID!
  ) {
    copyPreviousSalesArguments(
      stageUuid: $stageUuid
    ) {
      salesArguments {
        ... StageSalesArgument
      }
    }
  }
  ${salesArgumentFragment}
`

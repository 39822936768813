import React              from 'react'
import styled             from 'styled-components'
import classNames         from 'classnames'
import { connect, getIn } from 'formik'
import { shape, object, node, string } from 'prop-types'

import { redColor } from 'shared/style/colors'

const FieldErrorMessage = ({ formik, name, className, children }) => {
  const error = getIn(formik.errors, name)
  const touched = getIn(formik.touched, name)

  const errorMessage = touched && error ? error : null
  const fullClassName = classNames(className, 'field-error-message', { 'is-invalid': errorMessage })

  return (
    <div className={ fullClassName }>
      { children }

      <div className="error-message">
        { errorMessage }
      </div>
    </div>
  )
}

FieldErrorMessage.propTypes = {
  children: node.isRequired,
  formik: shape({
    errors: object,
    touched: object
  }).isRequired,
  name: string.isRequired
}

export default styled(connect(FieldErrorMessage))`
 .error-message {
   color: ${redColor};
   display: none;
   font-size: .75rem;
   margin-top: .125rem;

   &::first-letter {
    text-transform: capitalize;
   }
 }

  &.is-invalid {
    [class*="-control"] {
      border-color: ${redColor}
    }

    .error-message {
      display: block;
    }
  }
`

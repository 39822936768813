import React    from 'react'
import { node, object, bool } from 'prop-types'
import { get }  from 'lodash'

import projectType from 'shared/types/projectType'
import Card        from 'shared/components/ui/Card'

import ProjectName from './ProjectName'
import Image       from './Image'
import Footer      from './Footer'

const ProjectSummaryContext = React.createContext({ projectUuid: undefined })

const ProjectSummary = ({ project, withoutSelectors, children, footerComponents, disabled }) => {
  const firstAssetSrc = get(project, 'assetAssignments[0].asset.media')

  return (
    <ProjectSummaryContext.Provider value={{ projectUuid: project.uuid }}>
      <Card
        className="mb-2"
        footer={
          <Footer
            withoutSelectors={ withoutSelectors || disabled }
            project={ project }
            components={ footerComponents }
          />
        }
        dataTestId="project-summary-panel"
      >
        <Image src={ firstAssetSrc } project={ project }>
          {
            children ? (
              children
            ) : (
              <ProjectName>{ project.name }</ProjectName>
            )
          }
        </Image>
      </Card>
    </ProjectSummaryContext.Provider>
  )
}

ProjectSummary.propTypes = {
  project: projectType.isRequired,
  children: node,
  disabled: bool,
  footerComponents: object,
  withoutSelectors: bool
}

ProjectSummary.defaultProps = {
  children: null,
  disabled: false,
  footerComponents: {},
  withoutSelectors: false
}

export { ProjectSummaryContext }

export default ProjectSummary

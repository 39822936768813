import React             from 'react'
import { string, shape } from 'prop-types'
import styled            from 'styled-components'
import { Redirect }      from 'react-router-dom'

import { IconSuccess } from 'shared/components/ui/icons'
import LoginStyle      from 'shared/style/global/login'

import MarketerHeader from './MarketerHeader'
import Messages       from './Messages'
import Links          from './Links'

const LoggedOut = ({ className, location: { state } }) => {
  return (state && state.loggedOutUserType)
    ? (
      <>
        <LoginStyle/>

        <div className={ className }>
          <div className="centered-block">
            <MarketerHeader />
            <IconSuccess />
            <Messages />
            <Links userType={ state.loggedOutUserType }/>
          </div>
        </div>
      </>
    ) : (
      <Redirect to="/" />
    )
}

LoggedOut.propTypes = {
  className: string.isRequired,
  location: shape({
    state: shape({
      loggedOutUserType: string
    })
  }).isRequired
}

export default styled(LoggedOut)`
  align-items: center
  display: flex
  justify-content: center
  height: 100%
  text-align: center
  width: 100%
    
  .centered-block {
    padding-top: .5rem;
    position: relative
    max-width: 30rem
  }
  
  h1 {
    margin-bottom: 1.55rem
    margin-top: 1.85rem
  }
  
  p {
    font-weight: 400
    margin: 1rem auto 0
    text-align: center
    line-height: 1.35
    max-width: 28rem
  }

  .icon-success {
    display: inline;
    font-size: 4rem;
  }
`

import React from 'react'
import { array } from 'prop-types'
import { t } from 'ttag'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

Highcharts.setOptions({
  lang: {
    decimalPoint: ',',
    loading: t`Loading...`,
    months: [
      t`January`, t`February`, t`March`, t`April`,
      t`May`, t`June`, t`July`, t`August`,
      t`September`, t`October`, t`November`, t`December`
    ],
    shortMonths: [
      t`Jan`, t`Feb`, t`Mar`, t`Apr`, t`May`, t`Jun`,
      t`Jul`, t`Aug`, t`Sep`, t`Oct`, t`Nov`, t`Des`
    ],
    weekdays: [
      t`Sunday`, t`Monday`, t`Tuesday`, t`Wednesday`,
      t`Thursday`, t`Friday`, t`Saturday`
    ],
    shortWeekdays: undefined,
    thousandsSep: ' ',
  }
})

const chartOptions = {
  legend: {
    enabled: false
  },
  chart: {
    type: 'column',
    reflow: true,
    height: 190,
    width: 400,
    marginRight: 20
  },
  title: {
    text: undefined
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%b'
    },
    crosshair: true,
    gridLineWidth: 0
  },
  yAxis: {
    title: {
      enabled: false
    },
    gridLineWidth: 1
  },
  tooltip: {
    shared: true,
    dateTimeLabelFormats: {
      day: '%b'
    }
  },
  credits: {
    enabled: false
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    }
  }
}

const Chart = ({ series }) => {
  return (
    <HighchartsReact
      highcharts={ Highcharts }
      options={{ ...chartOptions, series }}
    />
  )
}

Chart.propTypes = {
  series: array.isRequired
}

export default Chart

import { gql } from 'apollo-boost'

import asset  from 'graphql/fragments/asset'
import banner from 'graphql/fragments/banner'

export default gql`
  query Ads($query: PageInput!) {
    ads(query: $query) {
      total
      items {
        uuid
        enabled
        asset {
          ... Asset
        }
        audience {
          name
          uuid
        }
        creativeSet {
          name
          color
          title
          content
          description
          banners {
            ... Banner
          }
          channel {
            uuid
            type
          }
        }
        project {
          uuid
          name
        }
        campaign {
          uuid
          name
        }
        summary {
          totalImpressions
          totalClicks
          totalSpent
          averageCpl
          leadsCount
        }
      }
    }
  }
  ${asset}
  ${banner}
`

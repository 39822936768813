import React    from 'react'
import { node } from 'prop-types'

import LayoutBase  from 'shared/components/Layout/Base'
import withAuth    from 'shared/components/Auth/withAuth'

import Sidebar from './Sidebar'

const Layout = ({ children }) => {
  return (
    <LayoutBase
      page={ children }
      sidebar={ <Sidebar /> }
    />
  )
}

Layout.propTypes = {
  children: node.isRequired,
}

const LayoutWithAuth = withAuth(Layout)

export function withLayout(Page) {
  const PageWithLayout = (props) => {
    return (
      <LayoutWithAuth>
        <Page { ...props } />
      </LayoutWithAuth>
    )
  }

  PageWithLayout.displayName = `${Page.displayName || Page.name}WithLayout`

  return PageWithLayout
}

export default Layout

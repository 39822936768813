import styled from 'styled-components'

import { altoGreyColor } from 'shared/style/colors'

import Iframe from '../Iframe'

export default styled(Iframe)`
  border-radius: 5px;
  border: solid 2px ${altoGreyColor};
  padding: 0.5rem 0.9rem;
  pointer-events: none;
`

import { gql } from 'apollo-boost'

export default gql`
  query projectVitecDocuments($uuid: ID!) {
    project(uuid: $uuid) {
      uuid
      vitecDocuments {
        url
        fileName
        fileSize
      }
    }
  }
`

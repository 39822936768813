import React, { useState } from 'react'
import styled              from 'styled-components'
import { useApolloClient } from 'react-apollo'
import { t }               from 'ttag'
import { string, number, array, func, object } from 'prop-types'
import { toast } from 'react-toastify'

import assignCampaignDate         from 'admin/helpers/assignCampaignDate'
import InputWrapper               from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import { combineErrors }          from 'shared/helpers/inputValidation'
import { lessThanOneHourFromNow } from 'shared/helpers/date'
import { publishedPhases }        from 'shared/constants/campaigns/phases'

import typesPresentation  from '../../typesPresentation'
import Input        from '../Input'
import ColumnHeader from '../ColumnHeader'
import InputRow     from '../InputRow'
import LqsSelect    from '../LqsSelect'

import CampaignDatepicker   from './CampaignDatepicker'
import updateCampaignGoals  from './updateCampaignGoals'
import UpdateSpendingButton from './UpdateSpendingButton'

const validatableFields = [
  'startDate',
  'endDate'
]

const Goals = (props) => {
  const {
    startDate,
    campaign,
    campaignUuid,
    endDate,
    type,
    number,
    lqs,
    errors,
    validate
  } = props

  const client = useApolloClient()
  const [selectedStartDate, setSelectedStartDate] = useState(startDate)
  const [selectedEndDate, setSelectedEndDate] = useState(endDate)

  const campaignPublished = publishedPhases.includes(campaign.phase)
  const campaignIsPublishing = campaign.publishFailedAt === null && campaign.publishStartedAt
  const disableStartDate = campaignPublished || campaignIsPublishing

  const errorsOnField = validatableFields.reduce((result, field) => {
    result[field] = combineErrors({ errors, field, uuid: campaignUuid })
    return result
  }, {})

  const goalsChangeHandler = (name) => (
    (value) => value && updateCampaignGoals({ client, campaignUuid, value, name })
  )

  const handleGoalsSelect = (e) => {
    const { name, value } = e.target
    updateCampaignGoals({ client, campaignUuid, value, name })
  }

  const revalidateOnDatesChange = ({ data: { assignCampaignDate: { campaign } } }) => {
    if (!validate) { return }
    validate({ payload: campaign })
  }

  const handleDatesChange = ({ name, value }) => {
    if (lessThanOneHourFromNow(value)) {
      return (
        name === 'startDate'
          ? toast.error(t`Start time must be at least 1 hour from now`)
          : toast.error(t`End time must be at least 1 hour from now`)
      )
    }

    name === 'startDate'
      ? setSelectedStartDate(value)
      : setSelectedEndDate(value)

    assignCampaignDate({ client, name, value, uuid: campaignUuid }).
      then(revalidateOnDatesChange)
  }

  return (
    <div className={ `${ props.className } d-flex flex-column` }>
      <ColumnHeader>
        { t`Goals & duration` }
      </ColumnHeader>

      <div className='mt-2 d-flex flex-column flex-1-1-auto'>
        <InputRow label={ typesPresentation[type] }>
          <Input
            value={ number }
            onUpdate={ goalsChangeHandler('number') }
            data-test-id={ `campaign-info-goal-${typesPresentation[type].toLowerCase()}` }
          />
        </InputRow>

        {type === 'leads' && (
          <InputRow label={ t`LQS` }>
            <LqsSelect
              name="lqs"
              value={ lqs }
              onChange={ handleGoalsSelect }
            />
          </InputRow>
        )}

        <InputRow label={ t`Start date` } labelFor="startDate">
          <InputWrapper
            error={ errorsOnField.startDate }
            data-test-id='campaign-start-date-input-wrapper'>
            <CampaignDatepicker
              name="startDate"
              className={ errorsOnField.startDate && 'is-invalid' }
              selected={ selectedStartDate }
              minDate={ new Date() }
              maxDate={ selectedEndDate }
              onChange={ handleDatesChange }
              disabled={ disableStartDate }
              inputId="startDate"
            />
          </InputWrapper>
        </InputRow>

        <InputRow label={ t`End date` } labelFor="endDate">
          <InputWrapper
            error={ errorsOnField.endDate }
            data-test-id='campaign-end-date-input-wrapper'>
            <CampaignDatepicker
              name="endDate"
              className={ errorsOnField.endDate && 'is-invalid' }
              selected={ selectedEndDate }
              minDate={ selectedStartDate }
              onChange={ handleDatesChange }
              inputId="endDate"
            />
          </InputWrapper>
        </InputRow>

        <UpdateSpendingButton campaign={ campaign } />
      </div>
    </div>
  )
}

Goals.propTypes = {
  campaign: object.isRequired,
  campaignUuid: string.isRequired,
  lqs: string.isRequired,
  number: number.isRequired,
  type: string.isRequired,
  endDate: string,
  errors: array,
  startDate: string,
  validate: func
}

Goals.defaultProps = {
  endDate: undefined,
  errors: [],
  startDate: undefined,
  validate: undefined
}

export default styled(Goals)`
  .flex-1-1-auto {
    flex: 1 1 auto
  }
`

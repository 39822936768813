import gql from 'graphql-tag'

export default gql`
  fragment NotificationEvents on NotificationDestinationSettings {
    campaignStarted
    campaignEnded
    campaignPaused
    newOffer
    newProposal
  }
`

import { useQuery } from 'react-apollo'
import gql          from 'graphql-tag'

const query = gql`
  query currentCampaignUuid {
    currentCampaignUuid @client
  }
`

export default function useCurrentCampaignUuid() {
  const { data: { currentCampaignUuid } = {} } = useQuery(query)

  return currentCampaignUuid
}

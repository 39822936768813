import attachFileMutation from 'graphql/mutations/assetable/attachFile'
import agentsQuery        from 'graphql/queries/projects/agents'

const attachFileToAgent = ({ client, blobId, agentUuid, projectUuid, mediaType }) => {
  const assetableUuid = agentUuid
  const assetableType = 'Agent'

  return client.mutate({
    mutation: attachFileMutation,
    variables: { blobId, assetableUuid, assetableType, mediaType },
    refetchQueries: [{ query: agentsQuery, variables: { projectUuid } }]
  })
}

export default attachFileToAgent

import React     from 'react'
import { t }     from 'ttag'
import styled    from 'styled-components'
import { string, array } from 'prop-types'

import Label from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/Label'
import { greyishBrownColor } from 'shared/style/colors'

import useFormatSelect from './useFormatSelect'
import Select          from './Select'

const FormatSelect = ({
  className,
  creativeSetUuid,
  companyUuid,
  bannerTemplateCollectionUuid,
  usedBanners
}) => {
  const {
    formats,
    addCreativeSetBanner,
    removeCreativeSetBanner,
  } = useFormatSelect({ companyUuid, creativeSetUuid, bannerTemplateCollectionUuid })

  const usedFormats = usedBanners.map(
    (banner) => { return banner.format }
  ).filter(
    (value, index, self) => self.indexOf(value) === index
  )

  return (
    <div className={ className }>
      <Label>{ t`Selected Formats` }</Label>

      <div data-test-id="format-container">
        <Select
          bannerTemplateCollectionUuid={ bannerTemplateCollectionUuid }
          formats={ formats }
          usedFormats={ usedFormats }
          addCreativeSetBanner={ addCreativeSetBanner }
          removeCreativeSetBanner={ removeCreativeSetBanner }
        />
      </div>
    </div>
  )
}

FormatSelect.propTypes = {
  companyUuid: string.isRequired,
  creativeSetUuid: string.isRequired,
  usedBanners: array.isRequired,
  bannerTemplateCollectionUuid: string
}

FormatSelect.defaultProps = {
  bannerTemplateCollectionUuid: undefined
}

export default styled(FormatSelect)`
  margin-left: .75rem;

  .formats-selected {
    color: ${greyishBrownColor}
    font-size: 0.75rem;
  }
`

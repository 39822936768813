import React  from 'react'
import styled from 'styled-components'
import { string, bool, number } from 'prop-types'
import { t } from 'ttag'

import Datepicker from 'shared/components/ui/Datepickers/Datepicker'
import { greyishBrownColor } from 'shared/style/colors'
import { tableBorderValue }  from 'shared/style/placeholders'

import DeleteButton from './DeleteButton'
import useShowing   from './useShowing'

const EditableShowing = (props) => {
  const {
    className,
    uuid,
    startDate,
    endDate,
    showTimeSeparator,
    entryIndex,
    showFieldLabels
  } = props

  const {
    selectedStartDate,
    selectedEndDate,
    handleDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    minStartDate
  } = useShowing({ uuid, startDate, endDate })

  const buildFieldLabels = () => {
    return (
      <li className={ className + ' field-labels' } >
        <div className="datepicker">
          <span>{ t`Viewing ${entryIndex} date` }</span>
        </div>
        <div className="timepickers">
          <div className="shared-datepicker">
            <span>{ t`Viewing ${entryIndex} start time` }</span>
          </div>
          <div className="shared-datepicker">
            <span>{ t`Viewing ${entryIndex} end time` }</span>
          </div>
        </div>
        <a className='delete-btn-placeholder'>&nbsp;</a>
      </li>
    )
  }

  return (
    <>
      { showFieldLabels && buildFieldLabels() }
      <li className={ className } data-test-id="showing">
        <div className="datepicker" data-test-id="showing-datepicker">
          <Datepicker
            showTimeInput={ false }
            minDate={ minStartDate }
            onChange={ handleDateChange }
            selected={ selectedStartDate }
          />
        </div>

        <div className="timepickers" data-test-id="showing-timepickers">
          <Datepicker
            showTimeSelect
            showTimeSelectOnly
            showTimeInput={ false }
            minDate={ minStartDate }
            selected={ selectedStartDate }
            onChange={ handleStartTimeChange }
            timeIntervals={ 15 }
            timeCaption={ null }
            dateFormat="HH:mm"
          />
          { showTimeSeparator && <span>&nbsp;-&nbsp;</span> }
          <Datepicker
            showTimeSelect
            showTimeSelectOnly
            showTimeInput={ false }
            selected={ selectedEndDate }
            onChange={ handleEndTimeChange }
            timeIntervals={ 15 }
            timeCaption={ null }
            dateFormat="HH:mm"
          />

        </div>

        <DeleteButton uuid={ uuid } />
      </li>
    </>
  )
}

EditableShowing.propTypes = {
  uuid: string.isRequired,
  endDate: string,
  entryIndex: number,
  showFieldLabels: bool,
  showTimeSeparator: bool,
  startDate: string
}

EditableShowing.defaultProps = {
  endDate: undefined,
  entryIndex: undefined,
  showFieldLabels: false,
  showTimeSeparator: true,
  startDate: undefined
}

export default styled(EditableShowing)`
  align-items: center;
  border-top: ${tableBorderValue};
  color: ${greyishBrownColor};
  display: flex;
  list-style: none;
  padding: 0.625rem;

  &.field-labels {
    padding-bottom: 0.2rem;
    margin-top: 0.5rem;
    margin-bottom: -0.8rem;

    span {
      font-size: 0.9rem;
    }

    .delete-btn-placeholder {
      width: 1rem;
    }
  }


  &:last-child {
    border-bottom: ${tableBorderValue};
  }

  .datepicker {
    margin-right: 2rem;
    width: 6rem;
  }

  .timepickers {
    white-space: nowrap;

    .shared-datepicker {
      display: inline-block;
      width: 4rem;
    }
  }

  ${DeleteButton} {
    margin-left: .5rem;
  }
`

import React, { useState } from 'react'
import { shape, string }   from 'prop-types'
import { Formik }          from 'formik'
import { useMutation }     from 'react-apollo'

import updateUserMutation from 'graphql/mutations/users/updateUser'

import ProfileFormView    from './ProfileFormView'
import validationSchema   from './validationSchema'
import buildInputValues   from './buildInputValues'
import buildInitialValues from './buildInitialValues'

const ProfileForm = ({ user }) => {
  const [isUpdated, setIsUpdated] = useState(false)
  const [updateUser] = useMutation(updateUserMutation)
  const initialValues = buildInitialValues(user)

  return (
    <Formik
      initialValues={ initialValues }
      validationSchema={ validationSchema }
      onSubmit={ (values, { setSubmitting }) => {
        setSubmitting(true)

        updateUser(buildInputValues(values))
          .then(() => {
            setSubmitting(false)
            setIsUpdated(true)
            setTimeout(() => setIsUpdated(false), 2000)
          })
      } }
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <ProfileFormView
          user={ user }
          errors={ errors }
          values={ values }
          touched={ touched }
          handleChange={ handleChange }
          handleSubmit={ handleSubmit }
          isSubmitting={ isSubmitting }
          isUpdated={ isUpdated }
        />
      )}
    </Formik>
  )
}

ProfileForm.propTypes = {
  user: shape({
    uuid: string.isRequired
  }).isRequired
}

export default ProfileForm

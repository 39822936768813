import { pickBy } from 'lodash'

export default (params, setUrlParams) => {
  const { sortBy, sortOrder } = params

  const onQueryChange = (newParams) => {
    setUrlParams(pickBy({ ...params, ...newParams }, Boolean))
  }

  const onTableChange = (type, newState) => {
    if (type === 'pagination') {
      onQueryChange({ page: newState.page })
    } else if (type === 'sort') {
      if (sortBy === newState.sortField && sortOrder === newState.sortOrder) return

      onQueryChange({ sortOrder: newState.sortOrder, sortBy: newState.sortField })
    }
  }

  const onSearchChange = (search) => {
    onQueryChange({ search, page: null })
  }

  return [onTableChange, onSearchChange]
}

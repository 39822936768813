import gql from 'graphql-tag'

export default gql`
  mutation RefreshNewsPost(
    $uuid: ID!
  ) {
    refreshNewsPost(
      uuid: $uuid
    ) {
      post {
        uuid
        media {
          uuid
          state
          asset {
            uuid
            media
            thumbnail
            position
            mediaType
            deletable
          }
        }
      }
    }
  }
`

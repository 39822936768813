import React from 'react'

import LoginLayout from './index'

export default function withLoginLayout(Page) {
  return function PageWithLoginLayout(props) {
    return (
      <LoginLayout>
        <Page { ...props } />
      </LoginLayout>
    )
  }
}

import updateGoogleMarketableAudienceMutation
  from 'graphql/mutations/campaign/targeting/updateGmpAudience'
import audienceFragment from 'graphql/fragments/audience'
import { buildFragmentParams } from 'shared/helpers/graphql'

export default (client, uuid, input) => {
  const fragmentParams = buildFragmentParams({ uuid, __typename: 'Audience' }, audienceFragment)
  const fragmentData = client.readFragment(fragmentParams)

  return client.mutate({
    mutation: updateGoogleMarketableAudienceMutation,
    variables: { uuid, input },
    optimisticResponse: {
      updateGmpAudience: {
        __typename: 'UpdateGmpAudience',
        audience: {
          __typename: 'Audience',
          ...fragmentData,
          platformAudience: {
            ...fragmentData.platformAudience,
            ...input
          }
        }
      }
    }
  })
}

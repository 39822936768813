import gql from 'graphql-tag'

export default gql`
  mutation createCampaign(
    $attributes: CampaignInputType!
    $goals: CampaignGoalsInputType
    $blueprintTemplateUuid: ID
  ) {
    createCampaign(
      attributes: $attributes,
      goals: $goals,
      blueprintTemplateUuid: $blueprintTemplateUuid
    ) {
      errors
      campaign {
        uuid
      }
    }
  }
`

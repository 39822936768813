import React, { useState }     from 'react'
import styled                  from 'styled-components'
import { array, func, string } from 'prop-types'
import { debounce, without }   from 'lodash'
import {
  ButtonGroup, DropdownButton, Dropdown, Button
}                              from 'react-bootstrap'

import {
  buttonTextColor, white, dropDownTextGrey,
  dropDownBorderGrey, borderGreyColor, buttonBorderColor
}                          from 'shared/style/colors'
import { debounceTimeout } from 'shared/constants'

const invokeDebounced = debounce(
  (onChange, option, direction) => onChange(option, direction),
  debounceTimeout
)

const directions = [
  'asc', 'desc'
]

const Sorter = ({ className, options, selectedOption, selectedDirection, onChange }) => {
  const [option, setOption] = useState(selectedOption)
  const [direction, setDirection] = useState(selectedDirection)

  const title = options.find(
    ({ value }) => value === option
  ).label

  const handleOptionChange = (option) => {
    setOption(option)
    invokeDebounced(onChange, option, direction)
  }

  const handleDirectionChange = () => {
    const newDirection = without(directions, direction)[0]
    setDirection(newDirection)
    invokeDebounced(onChange, option, newDirection)
  }

  return (
    <div className={ className } data-test-id='sorter-wrapper'>
      <ButtonGroup>
        <DropdownButton
          as={ ButtonGroup }
          title={ title }
        >
          { options.map(({ value, label }) => (
            <Dropdown.Item
              key={ value }
              onSelect={ () => handleOptionChange(value) }
              active={ value === option }
            >
              { label }
            </Dropdown.Item>
          )) }
        </DropdownButton>

        <Button onClick={ handleDirectionChange }>
          <span dangerouslySetInnerHTML={{ __html: (direction === 'asc' ? '&darr;' : '&uarr;') }} />
        </Button>
      </ButtonGroup>
    </div>
  )
}

Sorter.propTypes = {
  onChange: func.isRequired,
  options: array.isRequired,
  selectedDirection: string.isRequired,
  selectedOption: string.isRequired
}

export default styled(Sorter)`
  .btn {
    font-size: 0.9rem
    height: calc(1.5em + 0.75rem + 3px)
  }

  .btn-primary:focus, .btn-primary.focus {
    box-shadow: none
  }

  .btn:hover, .btn-primary.dropdown-toggle:focus {
    color: ${dropDownTextGrey}
    border-color: ${dropDownBorderGrey}
    box-shadow: none
  }

  .dropdown-menu {
    font-size: 0.85rem
  }

  .dropdown-item {
    color: ${buttonTextColor}

    &.active {
      color: ${white}
    }
  }

  button {
    background-color: ${white} !important
    border-color: ${buttonBorderColor} !important
    font-size: 0.9rem
    color: ${dropDownTextGrey}

    &:hover {
      background-color: ${white}
      border-color: ${borderGreyColor}
    }
  }
`

import { gql } from 'apollo-boost'

import snapshotUnitSet from 'graphql/fragments/snapshotUnitSet'

export default gql`
  mutation updateSnapshotUnitSet($uuid: ID!, $input: SnapshotUnitSetInput!) {
    updateSnapshotUnitSet(uuid: $uuid, input: $input) {
      unitSet {
        ... SnapshotUnitSet
      }
    }
  }
  ${snapshotUnitSet}
`

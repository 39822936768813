import React     from 'react'
import styled    from 'styled-components'
import { t }     from 'ttag'
import { array, oneOf, func, object, bool } from 'prop-types'

import mimeTypes             from 'shared/constants/mimeTypes'
import { greyishBrownColor } from 'shared/style/colors'
import AssetsContainer       from 'shared/components/ui/AssetsContainer'
import Asset                 from 'shared/components/ui/Asset'
import BaseGallery           from 'shared/components/Gallery/BaseGallery'

import EmptyAsset from './EmptyAsset'

const mediaTypeHeaders = {
  image: t`Images`,
  logo: t`Logos`,
  video: t`Videos`
}

const MediaListView = (props) => {
  const {
    className,
    assets,
    mediaType,
    fileInput,
    uploadFile,
    isUploading,
    openUploadWindow,
    handleAssetRemoval,
    handleAssetEdit
  } = props
  const handleFileUpload = (e) => {
    uploadFile(e.target.files)
  }

  return (
    <div className={ className }>
      <h6>{ mediaTypeHeaders[mediaType] }</h6>

      <input
        multiple
        type="file"
        accept={ mimeTypes[mediaType].join(',') }
        className="d-none"
        ref={ fileInput }
        onChange={ handleFileUpload }
        data-test-id='file-upload-input'
      />

      <BaseGallery assets={ assets }>
        {({ controls }) => (
          <AssetsContainer>
            { assets.map((asset, key) => (
              <Asset
                handleAssetRemoval={ handleAssetRemoval }
                onClick={ () => controls.open(key) }
                key={ asset.media }
                handleAssetEdit={ handleAssetEdit }
                asset={ asset }
              />
            )) }
            <EmptyAsset
              isUploading={ isUploading }
              mediaType={ mediaType }
              onClick={ openUploadWindow }
              onDrop={ handleFileUpload }
            />
          </AssetsContainer>
        )}
      </BaseGallery>
    </div>
  )
}

MediaListView.propTypes = {
  assets: array.isRequired,
  fileInput: object.isRequired,
  isUploading: bool.isRequired,
  mediaType: oneOf(Object.keys(mimeTypes)).isRequired,
  openUploadWindow: func.isRequired,
  uploadFile: func.isRequired,
  handleAssetEdit: func,
  handleAssetRemoval: func
}

MediaListView.defaultProps = {
  handleAssetRemoval: undefined,
  handleAssetEdit: undefined
}

export default styled(MediaListView)`
  margin-bottom: 2.8125rem;

  h6 {
    color: ${greyishBrownColor}
    font-size: 1.25rem;
    margin-bottom: .75rem;
    opacity: .5;
    text-transform: capitalize;
  }
`

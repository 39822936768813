import React from 'react'
import { func, string, shape } from 'prop-types'

const SingleValue = (props) => {
  const [firstSelectedOption] = props.getValue()

  return (
    <div className="image-select--value-container">
      <img
        src={ firstSelectedOption.src }
        alt={ firstSelectedOption.value }
        style={{ background: props.selectProps.selectedColor }}
      />
    </div>
  )
}

SingleValue.propTypes = {
  getValue: func.isRequired,
  selectProps: shape({
    selectedColor: string
  }).isRequired
}

export default SingleValue

import React, { useEffect, useState } from 'react'
import { t }                          from 'ttag'
import styled                         from 'styled-components'
import classNames                     from 'classnames'
import { useApolloClient }            from 'react-apollo'
import { concat, pull }               from 'lodash'

import updateProjectExtraUnitsMutation from 'graphql/mutations/projects/updateExtraUnits'
import Card                  from 'shared/components/ui/Card'
import Table                 from 'shared/components/ui/Table'
import projectType           from 'shared/types/projectType'

import checkboxFormatter from './checkboxFormatter'

const ShowExtraUnits = ({ project, className }) => {
  const client = useApolloClient()

  const [enabledParking, setEnabledParking] = useState(false)
  const [enabledGarage, setEnabledGarage] = useState(false)
  const [enabledStorage, setEnabledStorage] = useState(false)

  useEffect(() => {
    setEnabledParking(project.enabledExtraUnits.includes('parking'))
    setEnabledGarage(project.enabledExtraUnits.includes('garage'))
    setEnabledStorage(project.enabledExtraUnits.includes('storage'))
  }, [project.enabledExtraUnits])

  const onUpdate = (extraUnit) => {
    let extraUnits = project.enabledExtraUnits
    extraUnits = extraUnits.includes(extraUnit)
      ? pull(extraUnits, extraUnit)
      : concat(extraUnits, extraUnit)

    setEnabledParking(extraUnits.includes('parking'))
    setEnabledGarage(extraUnits.includes('garage'))
    setEnabledStorage(extraUnits.includes('storage'))

    client.mutate({
      mutation: updateProjectExtraUnitsMutation,
      variables: {
        uuid: project.uuid,
        input: {
          enabledExtraUnits: extraUnits
        }
      }
    })
  }

  const columns = [
    {
      dataField: 'uuid',
      hidden: true
    }, {
      dataField: 'parking',
      text: t`Parking spaces`,
      formatter: checkboxFormatter,
      formatExtraData: { onUpdate, field: 'parking' }
    }, {
      dataField: 'garage',
      text: t`Garage spaces`,
      formatter: checkboxFormatter,
      formatExtraData: { onUpdate, field: 'garage' }
    }, {
      dataField: 'storage',
      text: t`Storage units`,
      formatter: checkboxFormatter,
      formatExtraData: { onUpdate, field: 'storage' }
    }
  ]

  const data = [{
    uuid: project.uuid,
    parking: enabledParking,
    garage: enabledGarage,
    storage: enabledStorage
  }]

  const tableOptions = {
    bordered: false,
    columns: columns,
    data,
    keyField: 'uuid',
    headerClasses: ''
  }

  return (
    <div data-test-id='show-extra-units'>
      <Card
        collapsible
        collapsed
        header={ t`Show extra units` }
        padded={ false }
        className={ classNames(className, 'mt-2') }
      >
        <Table
          className={ className }
          tableOptions={ tableOptions }
        />
      </Card>
    </div>
  )
}

ShowExtraUnits.propTypes = {
  project: projectType.isRequired
}

export default styled(ShowExtraUnits)`
  margin-bottom: 0.5rem;
`

import { gql } from 'apollo-boost'

export default gql`
  mutation createDirectUpload(
    $filename: String!
    $byteSize: Int!
    $checksum: String!
    $contentType: String!
  ) {
    createDirectUpload(
      filename: $filename
      byteSize: $byteSize
      checksum: $checksum
      contentType: $contentType
    ) {
      directUpload {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`

import gql from 'graphql-tag'

import lead from 'graphql/fragments/lead'

export default gql`
  mutation CreateLead(
    $input: CampaignLeadInput!
  ) {
    createLead(
      input: $input
    ) {
      lead {
        ... Lead
      }
    }
  }
  ${lead}
`

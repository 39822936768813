import React               from 'react'
import styled              from 'styled-components'
import { func }            from 'prop-types'
import { t }               from 'ttag'
import { useApolloClient } from 'react-apollo'

import landingPageType from 'shared/types/landingPageType'
import Button          from 'shared/components/ui/Button'

import createAgentMutation from './createMutation'

const AddNewButton = ({ className, landingPage, refetchLandingPage }) => {
  const client = useApolloClient()
  const handleCreate = () => {
    client.mutate({
      mutation: createAgentMutation,
      variables: { landingPageUuid: landingPage.uuid }
    }).then(() => {
      refetchLandingPage()
    })
  }

  return (
    <Button
      className={ `agent-add-new-button ${className}` }
      type='button'
      onClick={ handleCreate }
    >
      { t`Add agent` }
    </Button>
  )
}

AddNewButton.propTypes = {
  landingPage: landingPageType.isRequired,
  refetchLandingPage: func.isRequired
}

export default styled(AddNewButton)`
  float: right;
`

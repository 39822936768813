import { gql } from 'apollo-boost'

import showing from 'graphql/fragments/showing'

export default gql`
  mutation createShowing(
    $showableUuid: ID!,
    $showableType: String!
  ) {
    createShowing(
      showableUuid: $showableUuid,
      showableType: $showableType,
    ) {
      showing {
        ... Showing
      }
    }
  }
  ${showing}
`

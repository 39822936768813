import { gql } from 'apollo-boost'

import stageUnitBalcony from 'graphql/fragments/stageUnitBalcony'

export default gql`
  mutation createStageUnitBalcony(
    $unitUuid: ID!
    $input: StageUnitBalconyInput!
  ) {
    createStageUnitBalcony(
      unitUuid: $unitUuid
      input: $input
    ) {
      balcony {
        ... StageUnitBalcony
      }
    }
  }
  ${stageUnitBalcony}
`

import React, { useCallback } from 'react'
import styled                 from 'styled-components'
import { t }                  from 'ttag'
import { Form, Row, Col }     from 'react-bootstrap'
import { bool, func, shape, string, number, array } from 'prop-types'

import Card from 'shared/components/ui/Card'
import { buttonTextColor, redColor } from 'shared/style/colors'
import FileInput from 'shared/components/FileInput'
import DistrictSelect from 'shared/components/DistrictSelect'
import OwnershipTypeSelect from 'shared/components/OwnershipTypeSelect'

import Companies from './Companies'

const KeyInformation = ({
  className, values, handleChange, setFieldValue, touched, errors, companies, setCompanies
}) => {
  const isDistrictValid = touched.district && errors.district

  const onChange = useCallback(handleChange, [])

  return (
    <Card header={ t`Key information` } className={ className }>
      <Row>
        <Col xs="7">
          <Form.Group as={ Row }>
            <Form.Label column sm="3">{ t`Name` }</Form.Label>
            <Col sm="9">
              <Form.Control
                name="name"
                value={ values.name }
                onChange={ onChange }
                isInvalid={ touched.name && errors.name }
              />
              <Form.Control.Feedback type="invalid">
                { errors.name }
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Companies companies={ companies } setCompanies={ setCompanies } />

        </Col>
        <Col xs="5">
          <Form.Group as={ Row }>
            <Form.Label column sm="4">{ t`Prospect` }</Form.Label>
            <Col sm="8">
              <FileInput
                name="prospect"
                onChange={ prospectId => setFieldValue('prospect', prospectId) }
                isInvalid={ touched.prospect && errors.prospect }
              />
              <Form.Control.Feedback type="invalid">
                { errors.prospect }
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={ Row } data-test-id='district'>
            <Form.Label column sm="4">{ t`District` }</Form.Label>
            <Col sm="8">
              <DistrictSelect
                name="districtUuid"
                value={ values.district }
                onChange={ onChange }
                isInvalid={ isDistrictValid }
              />
              <Form.Control.Feedback type="invalid" className={ isDistrictValid && 'd-block' } >
                { errors.district }
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={ Row }>
            <Form.Label column sm="4">{ t`Ownership type` }</Form.Label>
            <Col sm="8">
              <OwnershipTypeSelect
                name="ownershipType"
                value={ values.ownershipType }
                onChange={ onChange }
              />
              <Form.Control.Feedback type="invalid">
                { errors.ownershipType }
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Card>
  )
}

KeyInformation.propTypes = {
  companies: array.isRequired,
  errors: shape({
    customer: string,
    district: number,
    name: string,
  }).isRequired,
  handleChange: func.isRequired,
  setCompanies: func.isRequired,
  setFieldValue: func.isRequired,
  touched: shape({
    customer: bool,
    district: bool,
    name: bool,
  }).isRequired,
  values: shape({
    customer: string,
    district: number,
    name: string,
  }).isRequired
}

export default styled(KeyInformation)`
  .form-control {
    box-shadow: none
  }

  label {
    color: ${buttonTextColor}
    font-size: .875rem
  }

  .is-invalid [class$=control]{
    border-color: ${redColor}
  }
`

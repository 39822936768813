import React  from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import Portal from 'shared/assets/icons/svg/facebook.svg'

const PortalIcon = ({ className, content }) => (
  <>
    <Portal className={ className } />
    { content }
  </>
)

PortalIcon.propTypes = {
  content: string
}

PortalIcon.defaultProps = {
  content: undefined
}

export default styled(PortalIcon)`
  height: 0.8rem
  margin-right: 0.4rem
`

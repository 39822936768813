import React from 'react'
import styled from 'styled-components'
import { node, string } from 'prop-types'
import { t } from 'ttag'

import MegaphoneSimple from 'shared/assets/icons/svg/megaphone-simple.svg'
import LayoutStyle from 'shared/style/global/layout'
import { greyishWhiteColor } from 'shared/style/colors'
import AppErrorBoundary from 'shared/AppErrorBoundary'

import Header from './Header'

const Layout = ({ page, sidebar, className }) => {
  const notification = t`M2 is upgrading! You will be automatically migrated to our brand new
  platform M360 later this year.`

  return (
    <div className={ className }>
      <LayoutStyle />

      <div className="page">
        <aside className="sidebar">{sidebar}</aside>
        <section className="container-main">
          <aside className="notification">
            <MegaphoneSimple className="icon" />
            <p>{notification}</p>
          </aside>

          <AppErrorBoundary>
            <div className="page-content">
              <Header />

              {page}
            </div>
          </AppErrorBoundary>
        </section>
      </div>
    </div>
  )
}

Layout.propTypes = {
  className: string.isRequired,
  page: node.isRequired,
  sidebar: node.isRequired
}

export default styled(Layout)`
  min-height: 100vh

  .page {
    align-items: stretch
    display: flex
    justify-content: space-between
    height: 100vh
  }

  .page-header {
    margin-bottom: 1.8rem
  }

  .sidebar {
    background: #FFF
    border-right: 1px solid ${greyishWhiteColor};
    padding: 1.75rem 1rem 0;
    width: 9.5rem
    flex: 0 0 9.5rem;
  }

  .notification {
    background: #FFF4E6
    display: flex
    align-items: center
    padding: 1rem
    gap: 0.625rem
  }

  .notification .icon {
    fill: #C97F1F
    width: 1.5rem
    height: 1.5rem
  }

  .notification p {
    flex: 1
    font-size: 0.875rem
    line-height: 1.375rem
    margin: 0
  }

  .sidebar.collapsed {
    // to be implemented later
  }

  .container-main {
    flex: 1 1 100%;
    width: 100%
    overflow-y: auto
  }
  
  .page-content {
    width: 70rem
    margin: 0 auto
  }

  .page-header .sidebar {
    margin-top: .2rem
    font-size: 1.25rem
  }
`

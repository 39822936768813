import gql from 'graphql-tag'

import ad from 'graphql/fragments/ad'

export default gql`
  mutation updateCreative($uuid: ID!, $input: CreativeInput!) {
    updateCreative(uuid: $uuid, input: $input) {
      creativeSetUuid
      adsCount
      creativeSetAudiences {
        uuid
        audience {
          uuid
          name
          adsCount
        }
        ads {
          ... Ad
        }
      }
    }
  }
  ${ad}
`

import React from 'react'
import { get } from 'lodash'

import { combineErrors } from 'shared/helpers/inputValidation'
import TouchableSelect from 'shared/components/ui/ThriftyInput/TouchableSelect'
import Wrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import UserSelect from 'shared/components/UserSelect'

import isReadOnly from './isReadOnly'

const userFormatter = (cell, row, rowIndex, formatExtraData) => {
  const { companyUuid, onUserUpdate, stakeholders, errors } = formatExtraData
  const error = combineErrors({ errors, uuid: row.uuid, field: 'user' })

  const userUuids = stakeholders
    .map((stakeholder) => get(stakeholder, 'user.uuid', stakeholder.userUuid))
    .filter(Boolean)

  const stakeholderOptions = stakeholders.map((stakeholder) => {
    if (stakeholder.user) return { value: stakeholder.user.uuid, label: stakeholder.user.name }
    return null
  }).filter(Boolean)

  const isOptionDisabled = ({ value }) => {
    return userUuids.includes(value)
  }

  const handleUpdate = ({ value: userUuid }) => {
    onUserUpdate({ stakeholderUuid: row.uuid, userUuid })
  }

  return isReadOnly(row) ? (
    cell
  ) : (
    <Wrapper error={ error }>
      <UserSelect
        stakeholderOptions={ stakeholderOptions }
        companyUuid={ companyUuid }
        component={ TouchableSelect }
        value={ get(row, 'user.uuid') }
        isOptionDisabled={ isOptionDisabled }
        onChange={ handleUpdate }
      />
    </Wrapper>
  )
}

export default userFormatter

import React, { useEffect } from 'react'
import { get }              from 'lodash'
import { useMutation }      from 'react-apollo'

import campaignLandingPagesFragment  from 'graphql/fragments/campaignLandingPages'
import populateCampaignTextsMutation from 'graphql/mutations/campaign/populateCampaignTexts'
import LoadingBlock                  from 'shared/components/ui/LoadingBlock'
import { buildFragmentParams }       from 'shared/helpers/graphql'
import toastError                    from 'shared/toastError'

const PopulateTexts = ({ campaign, children }) => {
  const [populateCampaignTexts, { called, loading }] = useMutation(
    populateCampaignTextsMutation,
    {
      variables: { uuid: campaign.uuid },
      update: (cache, { data: { populateCampaignTexts } }) => {
        if (get(populateCampaignTexts, 'errors')) return

        const fragmentParams = buildFragmentParams(campaign, campaignLandingPagesFragment)
        const fragmentData = cache.readFragment(fragmentParams)

        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            landingPages: populateCampaignTexts.landingPages || []
          }
        })
      }
    }
  )

  useEffect(() => {
    populateCampaignTexts().then(({ data }) => {
      if (get(data, 'populateCampaignTexts.errors.length')) {
        toastError(
          <>
            { data.populateCampaignTexts.errors.map(
              (error) => <p className="mb-1" key={ error }>{ error }</p>
            ) }
          </>
        )
      }
    })
  }, [campaign.uuid])

  if (!called || loading) {
    return (
      <LoadingBlock />
    )
  }

  return (
    children
  )
}

PopulateTexts.propTypes = {
}

export const withPopulatedTexts = (Component) => (props) => (
  <PopulateTexts { ...props }>
    <Component { ...props } />
  </PopulateTexts>
)

export default PopulateTexts

import gql from 'graphql-tag'

export default gql`
  fragment PortalPlatformAudience on PlatformAudience {
    ... on Portal {
      uuid
      ageFrom
      ageTo
      gender
      platform
      portalPositions
      audienceNetworkPositions
      messengerPositions
      updatedAt
      customAudiencesInclude {
        id
        name
        subtype
      }
      customAudiencesExclude {
        id
        name
        subtype
      }
      detailedTargetingInclude {
        id
        name
        type
      }
      detailedTargetingExclude {
        id
        name
        type
      }
      detailedTargetingAtLeastOne {
        id
        name
        type
      }
    }
  }
`

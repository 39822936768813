import React       from 'react'
import styled      from 'styled-components'
import { Popover } from 'react-bootstrap'
import { node }    from 'prop-types'

// eslint-disable-next-line react/prefer-stateless-function
class InvalidMediaPopover extends React.Component {
  static propTypes = {
    children: node.isRequired
  }

  render() {
    const { children } = this.props

    return (
      <Popover { ...this.props }>
        <Popover.Content>
          { children }
        </Popover.Content>
      </Popover>
    )
  }
}

InvalidMediaPopover.Title = Popover.Title
InvalidMediaPopover.Content = Popover.Content

export default styled(InvalidMediaPopover)`
  margin-left: 1rem

  .popover-header {
    background: #FFF
    font-weight: 600
  }

  .popover-body {
    color: #666
    font-size: 1rem
  }
`

import React from 'react'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { object }   from 'prop-types'

import dashboardAnalyticsActiveCampaignsQuery
  from 'graphql/queries/dashboardAnalytics/activeCampaigns'
import LoadingBlock from 'shared/components/ui/LoadingBlock'

import Card                from './Card'
import Chart               from './LinearChart'
import formatCurrentValues from './formatCurrentValues'

const ActiveCampaignsChart = ({ filter }) => {
  const {
    data: {
      dashboardAnalyticsActiveCampaigns: {
        activeCampaigns = {}
      } = {}
    } = {},
    loading
  } = useQuery(dashboardAnalyticsActiveCampaignsQuery, {
    variables: { filter }
  })

  if (loading) {
    return (
      <Card header={ t`Active campaigns` }>
        <LoadingBlock />
      </Card>
    )
  }

  const { activeCampaignsValue } = formatCurrentValues({ activeCampaigns })

  return (
    <Chart
      name={ t`Active campaigns` }
      comment={ t`Active campaigns` }
      dataTestId='active-campaigns-chart'
      dataset={ activeCampaigns.dataset }
      currentValue={ activeCampaignsValue }
    />
  )
}

ActiveCampaignsChart.propTypes = {
  filter: object
}

ActiveCampaignsChart.defaultProps = {
  filter: {}
}

export default ActiveCampaignsChart

export const calcPosition = (ref, percent) => {
  const element = ref.current
  const elementRect = element.getBoundingClientRect()
  const parentRect = element.parentElement.getBoundingClientRect()
  const left = parentRect.width * (percent / 100) - elementRect.width / 2

  const maxLeft = parentRect.width - elementRect.width

  return Math.min(Math.max(0, left), maxLeft)
}

export const haveIntersection = (first, second) => {
  const firstRect = first.getBoundingClientRect()
  const secondRect = second.getBoundingClientRect()

  const firstRight = firstRect.x + firstRect.width
  const secondRight = secondRect.x + secondRect.width

  return (firstRect.x <= secondRect.x && firstRight >= secondRect.x)
    || (secondRect.x <= firstRect.x && secondRight >= firstRect.x)
}

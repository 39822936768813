import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { arrayOf, shape, string } from 'prop-types'

import { textGreyColor } from 'shared/style/colors'
import campaignType from 'shared/types/campaignType'

import DuplicateAudienceLocations from '../DuplicateAudienceLocations'

import DynamicCreativeCheckbox from './DynamicCreativeCheckbox'
import AgeAndGender from './AgeAndGender'
import Placements from './Placements'
import Location from './Location'
import CustomAudiences from './CustomAudiences'
import DetailedTargeting from './DetailedTargeting'
import PotentialReach from './PotentialReach'

const Audience = ({ audience, allAudiences, platformAudienceType, ...props }) => {
  const {
    campaign: {
      areFacebookCredentialsValid,
      product: {
        company: { enabledFeatures }
      }
    }
  } = props

  const isFacebook = (platformAudienceType === 'Facebook')
  const dynamicCreativesEnabled = enabledFeatures.includes('dynamic_creatives')

  return (
    <div className="d-flex">
      <LeftColumn>

        { dynamicCreativesEnabled && isFacebook && <DynamicCreativeCheckbox
          audience={ audience }
        /> }

        <AgeAndGender
          audience={ audience }
        />
        <Location
          audience={ audience }
          { ...props }
        />

        { areFacebookCredentialsValid || (
          <p className="no-credentials-message">
            { t`There are no facebook credentials for your company.
            You will not be able to search among custom audiences and detailed targeting.` }
          </p>
        ) }
        <DuplicateAudienceLocations allAudiences={ allAudiences } currentAudience={ audience } />
        <CustomAudiences audience={ audience } />
        <DetailedTargeting
          audience={ audience }
        />
      </LeftColumn>

      <RightColumn>
        <Placements audience={ audience } { ...props } />
        <PotentialReach audience={ audience } />
      </RightColumn>
    </div>
  )
}

const LeftColumn = styled.div`
  border-right: solid 1px #e7e7e7;
  padding-right: 3.75rem;
  width: 40rem

  .no-credentials-message {
    color: ${textGreyColor}
    font-size: .75rem
  }
`

const RightColumn = styled.div`
  padding-left: 3.75rem;
`

Audience.propTypes = {
  allAudiences: arrayOf(
    shape({
      name: string,
      uuid: string
    })
  ).isRequired,
  audience: shape({
    title: string
  }).isRequired,
  campaign: campaignType.isRequired,
  platformAudienceType: string.isRequired
}

export default Audience

import React, { useRef } from 'react'

import mimeTypes from 'shared/constants/mimeTypes'

const useUploadOnClick = ({ uploadFiles, mediaType, validateVideoSize }) => {
  const fileInputRef = useRef()

  const handleClick = () => {
    const fileInput = fileInputRef.current
    fileInput.value = ''
    fileInput.click()
  }

  const handleFileUpload = (e) => {
    const files = [...e.target.files]

    if (!files.length) {
      return
    }

    uploadFiles(files, validateVideoSize)
  }

  const fileInput = (
    <input
      multiple
      type="file"
      className="d-none"
      onChange={ handleFileUpload }
      accept={ mimeTypes[mediaType].join(',') }
      ref={ fileInputRef }
    />
  )

  return {
    fileInput,
    handleClick
  }
}

export default useUploadOnClick

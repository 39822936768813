import React from 'react'
import { t } from 'ttag'
import styled from 'styled-components'

import Label from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/Label'

import BannersSlider from './BannersSlider'

const BannerPreviewSelect = ({ className, ...props }) => {
  return (
    <div className={ className }>
      <Label className="mt-0">{ t`Toggle format` }</Label>

      <BannersSlider { ...props } />
    </div>
  )
}

export default styled(BannerPreviewSelect)`
  margin-bottom: 1rem;
`

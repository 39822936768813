import React              from 'react'
import styled             from 'styled-components'
import { useMutation }    from 'react-apollo'
import { t }              from 'ttag'
import { string, object } from 'prop-types'

import { buttonTextColor } from 'shared/style/colors'
import BigNumberInput      from 'shared/components/ui/ThriftyInput/BigNumber'
import updateSnapshotUnitSetMutation from 'graphql/mutations/snapshots/unitSets/update'

const RightColumn = ({ className, unitSet, projectCurrency }) => {
  const [runMutation] = useMutation(updateSnapshotUnitSetMutation, {
    ignoreResults: true
  })

  const handleUpdate = (value, fieldName) => {
    return runMutation({
      variables: {
        uuid: unitSet.uuid,
        input: { [fieldName]: Number(value) }
      }
    })
  }

  return (
    <div className={ className } data-test-id='building-types'>
      <div className="group" data-test-id='bedrooms-range'>
        <label>
          <div>{ t`Number of bedrooms` }</div>
        </label>
        <div className='range-value'>
          <div className='range-start'>
            <label>{ t`From` }</label>
            <BigNumberInput
              maxLength="11"
              value={ unitSet.lowerBedroomCount }
              onUpdate={ (value) => handleUpdate(value, 'lowerBedroomCount') }
            />
            <label>{ t`To` }</label>
            <BigNumberInput
              maxLength="11"
              value={ unitSet.upperBedroomCount }
              onUpdate={ (value) => handleUpdate(value, 'upperBedroomCount') }
            />
          </div>
        </div>
      </div>

      <div className="group" data-test-id='size-range'>
        <label>
          <div>{ t`Size range` }</div>
        </label>
        <div className='range-value'>
          <div className='range-start'>
            <label>{ t`From` }</label>
            <BigNumberInput
              maxLength="11"
              value={ unitSet.lowerSizeM2 }
              onUpdate={ (value) => handleUpdate(value, 'lowerSizeM2') }
            />
            <label>{ t`To` }</label>
            <BigNumberInput
              maxLength="11"
              value={ unitSet.upperSizeM2 }
              onUpdate={ (value) => handleUpdate(value, 'upperSizeM2') }
            />
          </div>
          <label className='last-label'>m<sup>2</sup></label>
        </div>
      </div>

      <div className="group" data-test-id='price-range'>
        <label>
          <div>{ t`Price range` }</div>
        </label>
        <div className='range-value'>
          <div className='range-start'>
            <label>{ t`From` }</label>
            <BigNumberInput
              maxLength="15"
              value={ unitSet.lowerPrice }
              onUpdate={ (value) => handleUpdate(value, 'lowerPrice') }
            />
            <label>{ t`To` }</label>
            <BigNumberInput
              maxLength="15"
              value={ unitSet.upperPrice }
              onUpdate={ (value) => handleUpdate(value, 'upperPrice') }
            />
          </div>
          <label className='last-label'>{ projectCurrency }</label>
        </div>
      </div>
    </div>
  )
}

RightColumn.propTypes = {
  className: string.isRequired,
  projectCurrency: string.isRequired,
  unitSet: object.isRequired
}

export default styled(RightColumn)`
  width: 50%;
  padding: 0;
  padding-left: 1rem;

  .group {
    margin-bottom: 1rem;

    > label {
      width: auto !important;
      color: #697386;
      font-size: 0.875rem;
    }

    > div {
      width: 100%;
    }

    .input-wrapper {
      input, > div {
        width: 100% !important;
        max-width: 100%;
      }
    }
  }

  .range-value {
    display: flex;

    label {
      color: ${buttonTextColor};
      font-size: 0.875rem;
      margin-bottom: 0;
      line-height: 2rem;
    }

    .range-start {
      flex: 1;
      display: flex;
      align-items: flex-start;

      > :not(:last-child) {
        margin-right: 0.3rem;
      }

      > :last-child {
        flex-grow: 1;
      }

      input {
        font-size: 0.75rem;
        text-align: right;
        padding: 0.375rem;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .last-label {
      width: 2rem;
      margin-left: .5rem;
    }
  }

  .form-control {
    box-shadow: none;
  }
`

import { gql } from 'apollo-boost'

import audience from 'graphql/fragments/audience'
import campaign from 'graphql/fragments/campaign'

export default gql`
  mutation RenameAudience(
    $uuid: ID!
    $name: String!
  ) {
    renameAudience(
      uuid: $uuid
      name: $name
    ) {
      audience {
        ... Audience
      }
      campaign {
        ... Campaign
      }
    }
  }
  ${audience}
  ${campaign}
`

import React, { useState, useEffect } from 'react'
import { func, arrayOf, string } from 'prop-types'

import audienceLocationType from 'shared/types/audienceLocationType'
import audienceType from 'shared/types/audienceType'

import { fitCircleBounds, fitAllLocations, buildNewLocation } from '../helpers'

import MapView from './MapView'

const DEFAULT_DROP_CIRCLE_RADUIS = 15000

const Map = ({
  audience,
  locations,
  currentOperator,
  selectedLocation,
  highlightedLocation,
  onCreate,
  onMarkerClick,
  ...props
}) => {
  const [dropPinModeEnabled, changeDropPinMode] = useState(false)

  const [map, setMap] = useState()

  const handleMapClick = (event) => {
    if (!dropPinModeEnabled) return

    buildNewLocation({
      longitude: event.lngLat.lng,
      latitude:  event.lngLat.lat,
      radius:    DEFAULT_DROP_CIRCLE_RADUIS,
      operator:  currentOperator
    }).then((newLocation) => {
      onCreate(newLocation)
      changeDropPinMode(false)
    })
  }

  useEffect(() => {
    if (!map) return

    map.getCanvas().style.cursor = dropPinModeEnabled ? 'crosshair' : ''
    map.on('click', handleMapClick)

    return () => { map.off('click', handleMapClick) }
  }, [map, dropPinModeEnabled])

  useEffect(() => {
    if (!map || !selectedLocation) return

    fitCircleBounds(map, selectedLocation)
  }, [map, selectedLocation])

  useEffect(() => {
    if (!map) return

    fitAllLocations(map, locations)
  }, [map, locations])

  const handleSwitchDropPinMode = () => {
    changeDropPinMode(!dropPinModeEnabled)
  }

  const handleMapLoad = (map) => {
    setMap(map)
    fitAllLocations(map, locations)
  }

  return (
    <MapView
      map={ map }
      audience={ audience }
      locations={ locations }
      currentOperator={ currentOperator }
      highlightedLocation={ highlightedLocation }
      onMapLoad={ handleMapLoad }
      onSwitchDropPinMode={ handleSwitchDropPinMode }
      onFitBoundsButtonClick={ fitAllLocations }
      onMarkerClick={ onMarkerClick }
      { ...props }
    />
  )
}

Map.defaultProps = {
  audience: null,
  selectedLocation: null,
  highlightedLocation: null
}

Map.propTypes = {
  currentOperator: string.isRequired,
  locations: arrayOf(audienceLocationType).isRequired,
  onCreate: func.isRequired,
  onMarkerClick: func.isRequired,
  audience: audienceType,
  highlightedLocation: audienceLocationType,
  selectedLocation: audienceLocationType
}

export default Map

import { css } from 'styled-components'

export const triangleUp = css`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 10.4px 6px;
  border-color: transparent transparent #adadad transparent;
`

export const triangleDown = css`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10.4px 6px 0 6px;
  border-color: #adadad transparent transparent transparent;
`

export const circle = css`
  border-radius: 50%
`

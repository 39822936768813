import React from 'react'

import matchType from 'shared/types/matchType'
import CreateBuilding from 'shared/components/NewBuilding/CreateBuilding'

const CreateBuildingPage = ({ match }) => (
  <CreateBuilding projectUuid={ match.params.uuid } />
)

CreateBuildingPage.propTypes = {
  match: matchType.isRequired
}

export default CreateBuildingPage

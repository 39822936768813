import * as Yup from 'yup'
import { t } from 'ttag'
import { capitalize } from 'lodash'

const channelsToValidatePlatformPositions = ['facebook', 'instagram', 'portal']
const channelsToValidateGenderAndParentalStatus = ['gmp']
const channelsToValidateUrlsPresence = ['gmp']

export default Yup.array().min(1, t`Please add at least 1 audience.`).of(
  Yup.object().shape({
    type: Yup.string(),

    includedLocations: Yup.array().when(
      'type', (type, schema, { parent }) => {
        const minMessage = `[${capitalize(type)}] \
          ${ t`Please add at least 1 included location on` } ${parent.name}`
        return schema.min(1, minMessage)
      }
    ),

    platformPositions: Yup.array().when(
      'type', (type, schema, { parent }) => {
        if(!channelsToValidatePlatformPositions.includes(type)) return schema

        const minMessage = `[${ capitalize(type) }] \
          ${ t`Please mark at least 1 placement on` } ${parent.name}`
        return schema.min(1, minMessage)
      }
    ),

    platformAudience: Yup.object().when(
      'type', {
        is: type => channelsToValidateGenderAndParentalStatus.includes(type),
        then: Yup.object().shape({
          parentalStatus: Yup.array().test({
            test: function(parentalStatus) {
              const { from, createError, path } = this

              if (parentalStatus.length) return true

              const minMessage = `[Display] ${ t`Please choose at least 1 parental status on` } \
                ${from[1].value.name}`

              return createError({ message: minMessage, path: path })
            }
          }),
          gender: Yup.array().test({
            test: function(gender) {
              const { from, createError, path } = this

              if (gender.length) return true

              const minMessage = `[Display] ${ t`Please choose at least 1 gender on` } \
                ${from[1].value.name}`

              return createError({ message: minMessage, path: path })
            }
          }),
        })
      }
    ).when(
      'type', {
        is: type => channelsToValidateUrlsPresence.includes(type),
        then: Yup.object().shape({
          urls: Yup.array().nullable().test({
            test: function(urls) {
              const { parent, from, createError, path } = this
              const { urlBundles } = parent

              if (urls?.length || urlBundles.length) return true

              const minMessage = `[Display] ${ t`Please add at least 1 URL on`} \
                ${from[1].value.name}`

              return createError({ message: minMessage, path: path })
            },
          })
        })
      }
    ),

    specification: Yup.object().shape({
      channels: Yup.array().of(
        Yup.object().shape({
          type: Yup.string(),
          spending: Yup.number().when(
            'type', (type, schema, { parent, context, from }) => {
              if (from === undefined) return schema
              if(!channelsToValidatePlatformPositions.includes(type)) return schema

              const { spending } = parent
              let minChannelSpending
              switch (type) {
              case 'facebook':
                minChannelSpending = context.minFacebookChannelSpending
                break
              case 'instagram':
                minChannelSpending = context.minInstagramChannelSpending
                break
              case 'portal':
                minChannelSpending = context.minPortalChannelSpending
                break
              }

              return schema.required().min(
                minChannelSpending,
                `[${ capitalize(type) }] ${ spending } ${context.currency} \
                 ${ t`is below minimum budget` } (${ minChannelSpending } ${context.currency}). \
                 ${ t`Please increase the budget or reduce amount of audiences on` } \
                 ${ from[2]?.value?.name} `
              )
            }
          )
        })
      )
    })
  })
)

import attachFileMutation from 'graphql/mutations/assetable/attachFile'

import stageQuery from '../stageQuery'

import updateStageAssetsCache from './updateStageAssetsCache'

const attachFileToStage = ({ client, blobId, stageUuid, mediaType }) => {
  const assetableUuid = stageUuid
  const assetableType = 'Stage'

  return client.mutate({
    mutation: attachFileMutation,
    variables: { blobId, assetableUuid, assetableType, mediaType },
    refetchQueries: [{
      query: stageQuery,
      variables: { uuid: assetableUuid }
    }],
    update: (store, {
      data: {
        attachFileToAssetable: { assetAssignment }
      }
    }) => updateStageAssetsCache({ store, assetableUuid, assetAssignment })
  })
}

export default attachFileToStage

import { gql } from 'apollo-boost'

import format from 'graphql/fragments/format'

export default gql`
  query Formats($bannerTemplateCollectionUuid: ID) {
    formats(
      bannerTemplateCollectionUuid: $bannerTemplateCollectionUuid
    ) {
      ... Format
    }
  }
  ${format}
`

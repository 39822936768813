import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'
import { arrayOf, shape, string, func, bool } from 'prop-types'

import Switch        from 'shared/components/ui/Switch'
import { localDate } from 'shared/helpers/date'
import getImageUrl   from 'shared/helpers/getImageUrl'
import missingImage  from 'shared/assets/images/missing.png'

const List = ({ className, state, posts, onEditPost, onDeletePost, onPublish, enablePreview }) => {
  const sortedField = state === 'published' ? 'publishedAt' : 'editedAt'

  const publishTime = (post) => {
    return (
      <p className='published'>
        { state === 'published' ? localDate(post.publishedAt) : localDate(post.editedAt) }
      </p>
    )
  }

  const renderPostMedia = (post) => {
    const publishedMedia = post.media.filter((element) => element.state === 'published')
    if (publishedMedia.length === 0) return null

    return (
      <img
        src={ publishedMedia[publishedMedia.length - 1].asset.thumbnail }
        onError={ ({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = getImageUrl(missingImage)
        } }
      />
    )
  }

  const sortedPosts = () => {
    return posts.sort((a, b) => {
      if (a[sortedField] < b[sortedField]) return 1
      return -1
    })
  }

  return (
    <div>
      { sortedPosts().map(post => (
        <div className={ `post ${className}` } key={ post.uuid }>
          <div className='image-block'>
            {renderPostMedia(post)}
          </div>
          <div className='info-block'>
            <div className='main-info'>
              <div className='title-info'>
                { publishTime(post) }
                <p className='title'>{ post.title }</p>
              </div>
              <div className='buttons'>
                <div className='switch-block'>
                  <Switch
                    onChange={ () => onPublish(post, state === 'saved') }
                    checked={ state === 'published' }
                  >
                    <span className='switch-text'>
                      { state === 'published' ? t`Published` : t`Unpublished` }
                    </span>
                  </Switch>
                </div>
                <p className='button' onClick={ () => onDeletePost(post) }>{ t`Delete` }</p>
                <p className='button' onClick={ () => onEditPost(post) }>{ t`Edit` }</p>
                { enablePreview && (<p className='button'>{ t`Preview` }</p>) }
              </div>
            </div>
            <div className='data-block'>
              <div className='data-value-block'>
                <p className='data-value-title'>{ t`Reactions` }</p>
                <p className='data-value'>{ post.reactionsCount }</p>
              </div>
              <div className='data-value-block'>
                <p className='data-value-title'>{ t`Views` }</p>
                <p className='data-value'>{ post.viewsCount }</p>
              </div>
              <div className='data-value-block'>
                <p className='data-value-title'>{ t`CTA clicks` }</p>
                <p className='data-value'>{ post.ctaClicksCount }</p>
              </div>
              <div className='data-value-block'>
                <p className='data-value-title'>{ t`Shares` }</p>
                <p className='data-value'>{ post.sharesCount }</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

List.propTypes = {
  onDeletePost: func.isRequired,
  onEditPost: func.isRequired,
  onPublish: func.isRequired,
  posts: arrayOf(shape({
    uuid: string.isRequired
  })).isRequired,
  state: string.isRequired,
  enablePreview: bool
}

List.defaultProps = {
  enablePreview: false
}

export default styled(List)`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;

  .image-block {
    width: 9rem;
    height: 9rem;
    margin-right: 1rem;
    border: 1px solid rgb(151, 151, 151);
    border-radius: .5rem;
    overflow: hidden;

    img {
      width: 9rem;
      height: 9rem;
      object-fit: cover;
    }
  }

  .info-block {
    width: calc(100% - 10rem);

    .main-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      .title-info {
        width: calc(100% - 30rem);

        .published {
          margin-bottom: .25rem;
        }

        .title {
          font-size: 1.25rem;
          margin-bottom: 0;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .data-block {
    display: flex;
    flex-direction: row;

    .data-value-block {
      flex: 1;

      p {
        margin: 0;
        padding: .75rem 1.5rem;
        font-size: .875rem;
        color: #697386;
      }

      .data-value-title {
        background: #F6FAFC;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;

    .button {
      cursor: pointer;
      margin: 0 2rem;
    }
  }

  .switch-text {
    color: #697386;
  }
`

import React from 'react'
import { arrayOf, string } from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'

import { labelColor, textColor } from 'shared/style/colors'
import { cardPaddingYSize, cardPaddingXSize } from 'shared/style/sizes'
import { panelBackground } from 'shared/style/placeholders'

const Amenities = ({ className, amenities }) => {
  const columnsSize = Math.ceil(amenities.length / 4)
  const columns = [
    amenities.slice(0, columnsSize),
    amenities.slice(columnsSize, columnsSize * 2),
    amenities.slice(columnsSize * 2, columnsSize * 3),
    amenities.slice(columnsSize * 3, columnsSize * 4),
  ].filter((column) => column.length)

  return (
    <div className={ className }>
      <h5>Amenities</h5>
      <Row>
        { columns.map((column) => (
          <Col key={ column.join() }>
            <ul>
              { column.map((item) => (
                <li key={ item }>{ item }</li>
              )) }
            </ul>
          </Col>
        )) }
      </Row>
    </div>
  )
}

Amenities.propTypes = {
  amenities: arrayOf(string).isRequired,
  className: string.isRequired
}

export default styled(Amenities)`
  ${panelBackground}

  color: ${labelColor}

  margin-bottom: -${cardPaddingYSize}
  margin-left: -${cardPaddingXSize}
  margin-right: -${cardPaddingXSize}

  h5 {
    color: ${textColor}
    font-size: 1.15rem
    padding-top: .6rem
    padding-left: ${cardPaddingXSize}
  }

  .row {
    padding-left: 5.5rem
    padding-right: 3rem
    padding-bottom: .5rem
  }

  ul {
    padding-left: 0
  }

  li {
    font-size: .9rem
    line-height: 1.8
  }

  li:first-letter {
    text-transform: uppercase
  }
`

import { buildFragmentParams } from 'shared/helpers/graphql'
import productFragment from 'graphql/fragments/product'
import addStakeholderMutation from 'graphql/mutations/productStakeholders/addStakeholderMutation'

const addStakeholder = (client, product) => {
  client.mutate({
    mutation: addStakeholderMutation,
    variables: {
      productUuid: product.uuid,
      attributes: {}
    },
    update: (cache, { data: { addProductStakeholder: { stakeholder } } }) => {
      const fragmentParams = buildFragmentParams(product, productFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          stakeholders: [
            ...fragmentData.stakeholders,
            stakeholder
          ]
        }
      })
    }
  })
}

export default addStakeholder

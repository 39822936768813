import styled from 'styled-components'

const PageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 70vh;
`

export default PageContainer

import * as Yup from 'yup'
import { t }    from 'ttag'

export default Yup.object().shape({
  name: Yup.string().required(t`Please fill a name.`),

  location: Yup.object().
    shape({
      address: Yup.string().required(t`Please fill an address.`).nullable(),
      countryCode: Yup.string().required(t`Please select a country.`).nullable(),
      zipCode: Yup.string().required(t`Please select a zip code.`).nullable(),
      latitude: Yup.string().required(t`Please choose a location.`).nullable()
    }).
    required(t`Please fill in location data.`).
    nullable(),

  unitsCount: Yup.number().
    required(t`Please fill units count.`).
    positive(t`Units count must be greater than zero.`).
    integer(t`Units count must integer.`),

  buildingTypes: Yup.array().
    min(1, t`Please add at least 1 unit type.`).
    required(t`Please add at least 1 unit type.`).
    nullable(),

  lowerPrice: Yup.number().
    required(t`Please fill in price from.`).
    nullable().
    positive(t`Price from must be greater than zero.`),

  upperPrice: Yup.number().
    required(t`Please fill in price to.`).
    nullable().
    min(Yup.ref('lowerPrice'), t`Price to can not be less than Price from.`),

  lowerSizeM2: Yup.number().
    required(t`Please fill in size from.`).
    nullable().
    positive(t`Size from must be greater than zero.`),

  upperSizeM2: Yup.number().
    required(t`Please fill in size to.`).
    nullable().
    min(Yup.ref('lowerSizeM2'), t`Size to can not be less than Size from.`)
})

import React      from 'react'
import { string } from 'prop-types'
import styled     from 'styled-components'

import { greyishBrownColor } from 'shared/style/colors'
import logo                  from 'shared/assets/images/Marketer-logo-black.svg'

const MarketerHeader = ({ className }) => {
  return (
    <div className={ className }>
      <img src={ logo } alt="Marketer logo" className="marketer-logo"/>

      <p className="marketer-title">
        <strong>Marketer</strong> Technologies
      </p>
    </div>
  )
}

MarketerHeader.propTypes = {
  className: string.isRequired
}

export default styled(MarketerHeader)`
  bottom: 25.4rem
  position: absolute
  width: 100%

  .marketer-title {
    color: ${greyishBrownColor}
    font-size: 1.5rem
    letter-spacing: -.2px

    strong {
      font-weight: 500
    }
  }

  .marketer-logo {
    margin-bottom: .5rem
    width: 4rem
  }
`

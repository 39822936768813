import { t } from 'ttag'
import { merge, uniq } from 'lodash'

import { isFirefox } from 'shared/helpers/browser'

const checkOverflowFor = (previews, selector) => {
  const errorMessage = t`NB! The text may be too long for`
  const errBanners = []

  const SIZE_EPSILON = isFirefox() ? 2 : 0

  previews.forEach(ad => {
    if (!ad.target.contentWindow) { return }

    const iframeDoc = ad.target.contentWindow.document
    const textContainer = iframeDoc.querySelector(selector)

    if (!textContainer) { return }

    const heightOverflow = textContainer.scrollHeight - textContainer.clientHeight > SIZE_EPSILON
    const widthOverflow = textContainer.scrollWidth - textContainer.clientWidth > SIZE_EPSILON

    const overflows = heightOverflow || widthOverflow

    if (overflows) { errBanners.push(ad.banner.name) }
  })
  if (errBanners.length > 0) {
    return {
      error: `${errorMessage}: ${uniq(errBanners).join(', ')}`,
      field: 'content',
      force: true
    }
  }

  return {}
}

export const checkBannerPreviewFit = (creativeSet, previews, setError) => {
  const contentOverflowBanners = merge(checkOverflowFor(previews, '.text'), {
    uuid: creativeSet.uuid,
    field: 'content'
  })

  const titleOverflowBanners = merge(checkOverflowFor(previews, '.title'), {
    uuid: creativeSet.uuid,
    field: 'title'
  })

  setError([contentOverflowBanners, titleOverflowBanners])
}

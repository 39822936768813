import React from 'react'

import AdsIndex from 'shared/components/AdsIndex'

const Ads = () => {
  return (
    <AdsIndex />
  )
}

export default Ads

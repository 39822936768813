import React from 'react'
import { string, node } from 'prop-types'
import { Form } from 'react-bootstrap'

const InputWrapper = ({ error, children, ...props }) => (
  <div className={ `input-wrapper ${error ? 'with-error' : ''}` } { ...props }>
    { children }

    <Form.Control.Feedback type="invalid" className={ error ? 'd-block' : '' }>
      { error }
    </Form.Control.Feedback>
  </div>
)

InputWrapper.propTypes = {
  children: node.isRequired,
  error: string
}

InputWrapper.defaultProps = {
  error: null
}

export default InputWrapper

import React from 'react'
import { string } from 'prop-types'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import updateLandingPageMutation from 'graphql/mutations/campaign/updateLandingPage'
import campaignLandingPageQuery from 'graphql/queries/campaignLandingPage'
import campaignMediaQuery from 'graphql/queries/campaignMedia'
import redirectTo from 'shared/helpers/redirectTo'
import { buildFragmentParams } from 'shared/helpers/graphql'
import landingPagesTabName from 'shared/constants/campaigns/landingPagesTabName'
import landingPageFragment from 'graphql/fragments/landingPage'

import ContentForm from './ContentForm'
import Media from './Media'
import { PageContainer } from './elements'

const LandingPageContent = ({ uuid, campaignUuid }) => {
  const client = useApolloClient()
  const {
    data: { campaignLandingPage: landingPage } = {},
    loading: landingPageLoading,
    refetch: refetchLandingPage
  } = useQuery(campaignLandingPageQuery, {
    variables: { uuid },
  })

  const {
    data: { campaign } = {
      assetAssignments: [],
      project: {
        assetAssignments: []
      },
      stage: {
        assetAssignments: []
      }
    },
    loading: campaignLoading
  } = useQuery(campaignMediaQuery, {
    variables: { uuid: campaignUuid }
  })

  if (landingPageLoading || campaignLoading) {
    return null
  }

  const handleFinishClick = () => (
    redirectTo(`/campaigns/${campaignUuid}?infoTab=${landingPagesTabName}`)
  )

  const handleUpdate = input => (
    client.mutate({
      mutation: updateLandingPageMutation,
      variables: { uuid, input },
      update: (cache, data) => {
        const fragmentParams = buildFragmentParams(
          { __typename: 'LandingPage', uuid: uuid },
          landingPageFragment
        )
        const fragmentData = cache.readFragment(fragmentParams)
        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            headerImage: data.data.updateLandingPage.landingPage.headerImage
          }
        })
      }
    })
  )

  const { assetAssignments: campaignAssetAssignments = [] } = campaign
  const { project: { assetAssignments: projectAssetAssignments = [] } } = campaign
  const { stage: { assetAssignments: stageAssetAssignments = [] } } = campaign

  return (
    <PageContainer>
      <DndProvider backend={ HTML5Backend }>
        <ContentForm
          landingPage={ landingPage }
          campaign={ campaign }
          onUpdate={ handleUpdate }
          onFinish={ handleFinishClick }
          refetchLandingPage={ refetchLandingPage }
        />

        <Media
          assetAssignments={ campaignAssetAssignments }
          projectAssetAssignments={ projectAssetAssignments }
          stageAssetAssignments={ stageAssetAssignments }
        />
      </DndProvider>
    </PageContainer>
  )
}

LandingPageContent.propTypes = {
  campaignUuid: string.isRequired,
  uuid: string.isRequired
}

export default LandingPageContent

import updateAssetableAssetAssignmentsCache
  from 'shared/helpers/updateAssetableAssetAssignmentCache'
import attachFileMutation from 'graphql/mutations/assetable/attachFile'

import assetAssignmentFragment from './buildingAssetAssignmentFragment'

export default function attachFileToStage({ client, blobId, mediaType, buildingUuid }) {
  const assetableUuid = buildingUuid
  const assetableType = 'Building'

  return client.mutate({
    mutation: attachFileMutation,
    variables: { blobId, assetableUuid, assetableType, mediaType },
    update: (store, {
      data: {
        attachFileToAssetable: { assetAssignment }
      }
    }) => updateAssetableAssetAssignmentsCache({
      store,
      assetableUuid: buildingUuid,
      assetableType: 'Building',
      assetableFragment: assetAssignmentFragment,
      assetAssignment
    })
  })
}

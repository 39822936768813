import gql from 'graphql-tag'

import assetAssignment from 'graphql/fragments/assetAssignment'
import asset from 'graphql/fragments/asset'

export default gql`
  query Project(
    $uuid: ID!
  ) {
    project(uuid: $uuid) {
      assetAssignments {
        ... AssetAssignment
      }
      originalAssetAssignments {
        uuid
        mediaType
        portalEnabled
        portalEnabledDraft
        portalPosition
        portalPositionDraft
        asset {
          ... Asset
        }
      }
      uuid
    }
  }
  ${assetAssignment}
  ${asset}
`

import { useMutation } from 'react-apollo'
import { toast } from 'react-toastify'
import { t } from 'ttag'

import { buildFragmentParams } from 'shared/helpers/graphql'
import audienceFragment from 'graphql/fragments/audience'
import duplicateAudienceLocationsMutation
  from 'graphql/mutations/campaign/audiences/locations/duplicateLocations'

export default function useDuplicateAudienceLocations() {
  const [duplicateAudienceLocations] = useMutation(
    duplicateAudienceLocationsMutation,
    {
      update: (cache, { data: { duplicateAudienceLocations: { targetAudiences } } }) => {
        if (targetAudiences.length > 0) {
          toast.success(t`Locations successfully duplicated`)
        } else {
          toast.error(t`Location duplication failed`)
        }

        targetAudiences.forEach((audience) => {
          const fragmentParams = buildFragmentParams(
            { __typename: 'Audience', uuid: audience.uuid },
            audienceFragment
          )
          const fragmentData = cache.readFragment(fragmentParams)

          cache.writeFragment({
            ...fragmentParams,
            data: {
              ...fragmentData,
              locations: audience.locations
            }
          })
        })
      }
    }
  )

  return duplicateAudienceLocations
}

import React from 'react'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { object }   from 'prop-types'

import dashboardAnalyticsAverageCtrQuery from 'graphql/queries/dashboardAnalytics/averageCtr'
import LoadingBlock                      from 'shared/components/ui/LoadingBlock'

import Card                from './Card'
import Chart               from './LinearChart'
import formatCurrentValues from './formatCurrentValues'

const CtrChart = ({ filter }) => {
  const {
    data: {
      dashboardAnalyticsAverageCtr: {
        averageCtr = {}
      } = {}
    } = {},
    loading
  } = useQuery(dashboardAnalyticsAverageCtrQuery, {
    variables: { filter }
  })

  if (loading) {
    return (
      <Card header={ t`CTR` }>
        <LoadingBlock />
      </Card>
    )
  }

  const { averageCtrValue } = formatCurrentValues({ averageCtr })

  return (
    <Chart
      name={ t`Click through rate (CTR)` }
      comment={ t`Average CTR` }
      dataTestId='ctr-chart'
      dataset={ averageCtr.dataset }
      currentValue={ averageCtrValue }
    />
  )
}

CtrChart.propTypes = {
  filter: object
}

CtrChart.defaultProps = {
  filter: {}
}

export default CtrChart

import React from 'react'
import styled from 'styled-components'
import { arrayOf, string, shape, node } from 'prop-types'

import { bluishColor } from 'shared/style/colors'

const List = ({ className, title, items }) => {
  return (
    <div className={ className }>
      <div>{ title }:</div>
      <ul>
        { items.map(({ name, uuid }) => <li key={ uuid }>{name}</li>)}
      </ul>
    </div>
  )
}

List.propTypes = {
  items: arrayOf(shape({
    name: node,
    uuid: string.isRequired
  })).isRequired,
  title: string.isRequired
}

export default styled(List)`
  font-size: 0.875rem;
  line-height: 1rem;

  > * {
    margin-bottom: 1rem;
  }

  ul {
    color: ${bluishColor};
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-weight: 500;

    li { margin-bottom: 0.625rem }
  }
`

export default function useDataSets(data, dataTotal) {
  const baseColors = ['#1bb481', '#105559', '#a6dfd2', '#138487', '#126b70', '#68aed5', '#8184e2']
  const restColor = '#e5e5e5'
  const restBorderColor = '#ffffff'
  const restColorPercentage = 15

  const dataAsPercentage = Object.values(data).map(element => (100 * element / dataTotal))
  const backgroundColors = []
  const borderColors = []

  dataAsPercentage.forEach((element) => {
    if (Math.round(element) < restColorPercentage) {
      backgroundColors.push(restColor)
      borderColors.push(restBorderColor)
    } else {
      const color = baseColors.shift()

      backgroundColors.push(color)
      borderColors.push(color)
    }
  })

  return {
    dataAsPercentage,
    backgroundColors,
    borderColors
  }
}

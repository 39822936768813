import useReactRouter from 'use-react-router'
import queryString from 'query-string'

const useUrlParams = () => {
  const { history, location } = useReactRouter()

  const params = queryString.parse(location.search)

  const setUrlParams = (params) => {
    history.push(`${location.pathname}?${queryString.stringify(params, { arrayFormat: 'index' })}`)
  }

  return [params, setUrlParams]
}

export default useUrlParams

import React, { memo, useCallback } from 'react'
import { array, func, string } from 'prop-types'
import { isEqual } from 'lodash'

import MultiSelect from 'shared/components/ui/MultiSelect'

const Select = memo((props) => {
  const {
    bannerTemplateCollectionUuid,
    formats,
    usedFormats,
    addCreativeSetBanner,
    removeCreativeSetBanner,
    ...restProps
  } = props

  const options = formats.map(({ uuid, width, height }) => ({
    value: uuid,
    label: `${width}x${height}`
  }))

  const values = options.filter(
    ({ value }) => usedFormats.find(({ uuid }) => value === uuid)
  )

  const onChange = useCallback((selectedValues, meta) => {
    if (meta.action === 'select-option') {
      addCreativeSetBanner(meta.option.value)
    } else if (meta.action === 'deselect-option') {
      removeCreativeSetBanner(meta.option.value)
    }
  }, [bannerTemplateCollectionUuid])

  return (
    <MultiSelect
      isClearable={ false }
      value={ values }
      options={ options }
      onChange={ onChange }
      { ...restProps }
    />
  )
}, ((prevProps, nextProps) => (
  isEqual(prevProps.formats, nextProps.formats)
    && isEqual(prevProps.bannerTemplateCollectionUuid, nextProps.bannerTemplateCollectionUuid)
    && isEqual(prevProps.usedFormats, nextProps.usedFormats)
)))

Select.displayName = 'Select'

Select.propTypes = {
  addCreativeSetBanner: func.isRequired,
  formats: array.isRequired,
  removeCreativeSetBanner: func.isRequired,
  usedFormats: array.isRequired,
  bannerTemplateCollectionUuid: string
}

Select.defaultProps = {
  bannerTemplateCollectionUuid: undefined
}

Select.whyDidYouRender = true

export default Select

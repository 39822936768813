import React, { useCallback, memo } from 'react'
import { string, func } from 'prop-types'
import { isEqual } from 'lodash'
import { t } from 'ttag'
import styled from 'styled-components'

import options from 'shared/constants/buildingTypes'
import Select from 'shared/components/ui/Select'

const BuildingTypeSelect = ({ name, value, onChange, className }) => {
  const saveFormValue = useCallback(({ value }) => {
    onChange({ target: { name, value } })
  }, [onChange])

  return (
    <Select
      className={ className }
      data-test-id='building-type-selector'
      noOptionsMessage={ () => t`No options` }
      value={ options.find((option) => value === option.value) }
      onChange={ saveFormValue }
      options={ options }
    />
  )
}

BuildingTypeSelect.propTypes = {
  onChange: func.isRequired,
  name: string,
  value: string
}

BuildingTypeSelect.defaultProps = {
  name: '',
  value: ''
}

export default styled(memo(BuildingTypeSelect, isEqual))`
  div {
    font-size: .75rem;
    font-weight: normal;
  }
`

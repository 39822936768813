import { gql } from 'apollo-boost'

export default gql`
  mutation updateProductStakeholder(
    $productUuid: ID!
    $uuid: ID!
    $attributes: StakeholderAttributes!
  ) {
    updateProductStakeholder(
      productUuid: $productUuid
      uuid: $uuid
      attributes: $attributes
    ) {
      stakeholder {
        uuid
      }
    }
  }
`

import styled from 'styled-components'

import { textMainInfoHeaderColor } from 'shared/style/colors'

export default styled.h1`
  align-self: center;
  color: ${textMainInfoHeaderColor};
  justify-self: center;
  font-size: 2.625rem;
  font-weight: 600;
  margin: 0;
  width: 100%;
  text-align: center;
`

import moment from 'moment'
import { t }  from 'ttag'

import { todayDate } from 'shared/helpers/date'

const assembly = () => t`Assembly`

const review = () => t`On review`

const live = () => t`Live`

const scheduled = (campaign) => {
  const currentDate = todayDate()
  const startDate   = moment(campaign.startDate)

  const duration = moment.duration(
    Math.abs(startDate.diff(currentDate,'hours')), 'hours'
  ).format('d[d] h[h]')

  if (startDate.isAfter(currentDate)) {
    return `${ t`Starts in` } ${duration}`
  } else {
    return `${ t`Should start` } ${duration} ${ t`ago` }`
  }
}

const paused = () => t`Paused`

const canceled = () => t`Canceled`

const archived = () => t`Archived`

const titlesForPhases = {
  assembly,
  review,
  scheduled,
  live,
  paused,
  canceled,
  archived
}

export default function getTitleForPhase(campaign) {
  const getter = titlesForPhases[campaign.phase]

  return getter ? getter(campaign) : null
}

import { useEffect, useState } from 'react'
import MapboxCircle from 'mapbox-gl-circle'

import {
  mapLightBlueColor,
  mapLightRedColor,
  mapMidBlueColor,
  mapMidRedColor,
  mapDarkBlueColor,
  mapDarkRedColor
} from 'shared/style/colors'

import { mapFitBounds } from './helpers'

const circleFillColors = {
  'include': mapLightBlueColor,
  'exclude': mapLightRedColor,
}

const circleHighlightColors = {
  'include': mapMidBlueColor,
  'exclude': mapMidRedColor,
}

const circleStrokeColors = {
  'include': mapDarkBlueColor,
  'exclude': mapDarkRedColor,
}

const CircleArea = ({ map, location, highlighted }) => {
  const [prevHighlighted, setPrevHighlighted] = useState(null)

  const fetchCircleFillColor = () => (
    highlighted
      ? circleHighlightColors[location.operator]
      : circleFillColors[location.operator]
  )

  const circle = new MapboxCircle(
    {
      lat: location.latitude,
      lng: location.longitude
    },
    location.radius,
    {
      editable: false,
      minRadius: 1000,
      fillColor: fetchCircleFillColor(),
      fillOpacity: 0.3,
      strokeOpacity: 0.95,
      strokeWeight: 1,
      strokeColor: circleStrokeColors[location.operator]
    }
  )

  const fitCircleBounds = (circle) => {
    const bounds = circle.getBounds()
    mapFitBounds(map, [bounds.sw, bounds.ne])
  }

  useEffect(() => {
    circle.addTo(map)
    if(highlighted === prevHighlighted) {
      fitCircleBounds(circle)
    }
    setPrevHighlighted(highlighted)

    return () => {
      try {
        if(highlighted === prevHighlighted) {
          fitCircleBounds(circle)
        }
        circle.remove()
      } catch(error) {
        // TODO, find out why
        // "TypeError: Cannot read property 'getLayer' of undefined"
        // raised when map destroyed on navigating away from the page
      }
    }
  }, [map, location.uuid, location.radius, location.operator, highlighted])

  return null
}

export default CircleArea

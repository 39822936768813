import React      from 'react'
import { t }      from 'ttag'
import styled     from 'styled-components'
import { object } from 'prop-types'

import Unit from 'shared/components/Project/Readonly/Unit'

const Units = ({ className, building }) => {
  const units = building?.units || []
  const currency = building?.currency

  const renderUnits = () => {
    return units.map((unit) => {
      return (
        <Unit
          unit={ unit }
          unitable='Building'
          key={ unit.uuid }
        />
      )
    })
  }

  if (units.length === 0) {
    return (
      <div className={ className }>
        { t`There are no units for this building` }
      </div>
    )
  }

  return (
    <div className={ className }>
      <div className='units-table-header'>
        <div>
          <p />
          <p>{ t`ID` }</p>
          <p>{ t`Bedrooms` }</p>
          <p>{ t`Unit type` }</p>
          <p>{ t`Size (m2)` }</p>
          <p>{ t`Total price (${ currency })` }</p>
          <p>{ t`Floor` }</p>
          <p>{ t`Floor plan` }</p>
          <p>{ t`Status` }</p>
          <p>{ t`Stage` }</p>
        </div>
      </div>
      {renderUnits()}
    </div>
  )
}

Units.propTypes = {
  building: object.isRequired
}

export default styled(Units)`
  .units-table-header {
    background: #f6fafc;
    padding: .5rem 1rem;

    div {
      display: flex;

      p {
        flex: 1;
        margin: 0;
        padding: 0 .25rem;
        font-size: .875rem;
        color: #697386;

        &:nth-of-type(1) {
          flex: initial;
          width: 3rem;
        }
      }
    }
  }
`

import { currentLocale } from 'locale'

export const ensureNumber = (value, defaultValue) => {
  return Number(value) || defaultValue
}

export const formatNumberWithLocale = (number) => {
  const localeKebab = currentLocale.replace(/_/, '-')

  return new Intl // eslint-disable-line no-undef
    .NumberFormat(localeKebab, { style: 'decimal', maximumFractionDigits: 2 })
    .format(number)
}

export const formatNumberPercentage = (number) => {
  return `${formatNumberWithLocale(number)} %`
}


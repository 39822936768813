import React from 'react'
import styled from 'styled-components'
import { string, node, oneOfType } from 'prop-types'

import { lightBlueColor, textGreyColor } from 'shared/style/colors'

const Header = ({ className, boldText, comment }) => {
  return boldText ? (
    <div className={ className } >
      <div className='bold-text'>
        { boldText }
      </div>
      <div className='comment'>
        { comment }
      </div>
    </div>
  ) : (
    null
  )
}

Header.propTypes = {
  boldText: oneOfType([string, node]),
  comment: string
}

Header.defaultProps = {
  boldText: null,
  comment: ''
}

export default styled(Header)`
  display: flex;
  flex-direction: row;
  align-items: center;

  .comment {
    font-size: 0.85rem;
    color: ${textGreyColor}
  }

  .bold-text {
    font-weight: 600;
    font-size: 2rem;
    color: ${lightBlueColor};
    margin-right: 1rem;
    white-space: nowrap;
  }
`


import React , { useEffect }   from 'react'
import styled                  from 'styled-components'
import { shape, string, func } from 'prop-types'

import LoadingBlock         from 'shared/components/ui/LoadingBlock'
import useSpecification     from 'admin/hooks/useSpecification'
import { campaignCurrency } from 'shared/helpers/currency'

import Goals    from './Goals'
import Spending from './Spending'
import Results  from './Results'

const Forecast = ({ className, campaign, setValidatablePayload, onLoaded, ...props }) => {
  const { loading, specification } = useSpecification(campaign.uuid)

  useEffect(() => {
    if(setValidatablePayload) {
      setValidatablePayload({ ...campaign, specification })
    }
  }, [JSON.stringify(specification), JSON.stringify(campaign)])

  useEffect(() => {
    if(!loading && onLoaded) {
      onLoaded()
    }
  }, [loading])

  if (loading) return <LoadingBlock />

  const currency  = campaignCurrency(campaign)
  const goals     = specification.goals || {}
  const forecasts = specification.forecasts || {}

  return (
    <div className={ className }>
      <Goals
        campaign={ campaign }
        campaignUuid={ campaign.uuid }
        specificationUuid={ specification.uuid }
        startDate={ campaign.startDate }
        endDate={ campaign.endDate }
        { ...goals }
        { ...props }
      />

      <Spending
        campaign={ campaign }
        currency={ currency }
        specification={ specification }
        { ...props }
      />

      <Results { ...forecasts } />
    </div>
  )
}

Forecast.propTypes = {
  campaign: shape({
    product: shape({
      promotable: shape({
        currency: string.isRequired
      }).isRequired
    }).isRequired,
    endDate: string.isRequired,
    startDate: string.isRequired,
    uuid: string.isRequired
  }).isRequired,
  onLoaded: func,
  setValidatablePayload: func
}

Forecast.defaultProps = {
  onLoaded: undefined,
  setValidatablePayload: undefined
}

export default styled(Forecast)`
  display: flex
  justify-content: space-between

  & > * {
    padding: 0 1.875rem
    width: 30%
  }

  ${Spending} {
    width: 40%
  }
`

import React  from 'react'
import styled from 'styled-components'

import { getUser }                 from 'shared/helpers/auth'
import { textMainInfoHeaderColor } from 'shared/style/colors'

const UserSummary = ({ className }) => {
  const user = getUser()

  return (
    <div className={ className }>
      <span className="user-email">
        { user.email }
      </span>
    </div>
  )
}

UserSummary.propTypes = {
}

export default styled(UserSummary)`
  padding: 0.25rem 1.5rem;
  white-space: nowrap;

  .user-email {
    color: ${textMainInfoHeaderColor}
    font-weight: 600
  }
`

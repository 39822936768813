import React from 'react'
import nb    from 'date-fns/locale/nb'
import en    from 'date-fns/locale/en-US'
import classNames         from 'classnames'
import { registerLocale } from 'react-datepicker'
import { func, bool, object, string } from 'prop-types'
import { t } from 'ttag'

import { currentLocale } from 'locale'
import Overlay           from 'shared/components/ui/Popover/Overlay'

import useDatepicker     from './useDatepicker'
import Container         from './Container'
import StyledDatepicker  from './StyledDatepicker'
import DatepickerActions from './DatepickerActions'

registerLocale('nb', nb)
registerLocale('en', en)

const datepickerLocale = currentLocale.includes('nb') ? 'nb' : 'en'

const Datepicker = ({
  showTimeInput,
  className,
  showReset,
  showTimeSelectOnly,
  selected,
  onChange,
  disabled,
  inputId,
  placeholder,
  placement,
  ...props
}) => {
  const {
    date,
    selectedDateFormatted,
    isOpened,
    open,
    container,
    target,
    setDate,
    saveDate,
    resetDate
  } = useDatepicker(selected, onChange, showReset, showTimeInput, showTimeSelectOnly)

  return (
    <div className={ classNames('shared-datepicker', className) } ref={ container }>
      <input
        placeholder={ placeholder }
        type="text"
        className="form-control"
        ref={ target }
        id={ inputId }
        onChange={ () => {} }
        value={ selectedDateFormatted }
        disabled={ disabled }
        onFocus={ open }
      />

      <Overlay
        onClose={ saveDate }
        container={ container.current }
        show={ isOpened }
        target={ target.current }
        placement={ placement }
      >
        <StyledDatepicker
          inline
          disabledKeyboardNavigation
          shouldCloseOnSelect={ false }
          locale={ datepickerLocale }
          timeFormat='HH:mm'
          showTimeInput={ showTimeSelectOnly ? false : showTimeInput }
          timeInputLabel={ `${t`Time`} ${t`Europe/Oslo`}` }
          showTimeSelectOnly={ showTimeSelectOnly }
          calendarContainer={ Container }
          selected={ date }
          onChange={ setDate }
          { ...props }
        />
        <DatepickerActions onClose={ saveDate } onReset={ resetDate } />
      </Overlay>
    </div>
  )
}

Datepicker.propTypes = {
  onChange: func.isRequired,
  disabled: bool,
  inputId: string,
  placeholder: string,
  placement: string,
  selected: object,
  showReset: bool,
  showTimeInput: bool,
  showTimeSelectOnly: bool
}

Datepicker.defaultProps = {
  inputId: undefined,
  placeholder: null,
  placement: 'auto',
  showTimeInput: true,
  selected: undefined,
  showTimeSelectOnly: false,
  showReset: false,
  disabled: false
}

export default Datepicker

import { get } from 'lodash'
import React from 'react'

import updateSpendingChannelMutation
  from 'graphql/mutations/campaign/specifications/updateSpendingChannel'
import specificationQuery from 'graphql/queries/campaigns/specification'
import toastError from 'shared/toastError'

export default ({ client, campaignUuid, index, channel }) => {
  const query = { query: specificationQuery, variables: { campaignUuid } }
  const data = client.readQuery(query)
  const specification = data.campaignSpecification

  const updatedSpecification = {
    ...specification,
    channels: specification.channels.map((unchangedChannel, idx) => (
      idx === index ? channel : unchangedChannel
    ))
  }

  return client.mutate({
    mutation: updateSpendingChannelMutation,
    variables: { campaignUuid, index, channel },
    optimisticResponse: {
      __typename: 'Mutation',
      updateSpendingChannel: {
        __typename: 'updateSpendingChannel',
        specification: updatedSpecification,
        errors: {}
      }
    }
  }).then((response) => {
    const errors = get(response, 'data.updateSpendingChannel.errors')
    if (errors.length) {
      toastError(
        <>
          { get(response, 'data.updateSpendingChannel.errors') }
        </>
      )
    }

    return response
  })
}

import React from 'react'
import { t } from 'ttag'
import { object } from 'prop-types'

import { IconBuilding } from 'shared/components/ui/icons'

import Body from './Body'
import Section from './Section'

const Property = ({ property }) => {
  return (
    <Section title={ t`Property` }>
      <Body
        title={ property.name }
        icon={ <IconBuilding /> }
        onClick={ () => {} }
      />
    </Section>
  )
}

Property.propTypes = {
  property: object.isRequired
}

export default Property

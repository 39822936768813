import React from 'react'
import { t } from 'ttag'

import { localDateTime } from 'shared/helpers/date'

const TimeFormatter = (value, _unit) => {
  return <div>{ localDateTime(value) }</div>
}

export default [
  {
    text: '',
    dataField: 'uuid',
    hidden: true
  },
  {
    text: t`Name`,
    dataField: 'fullName'
  },
  {
    text: t`Phone`,
    dataField: 'phoneNumber'
  },
  {
    text: t`Email`,
    dataField: 'email'
  },
  {
    text: t`Created at`,
    dataField: 'createdAt',
    formatter: TimeFormatter
  }
]

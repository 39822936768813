import updateFacebookMarketableAudienceMutation
  from 'graphql/mutations/campaign/targeting/updateFacebookMarketableAudience'
import audienceFragment from 'graphql/fragments/audience'
import { buildFragmentParams } from 'shared/helpers/graphql'

export default (client, uuid, attributes) => {
  const fragmentParams = buildFragmentParams({ uuid, __typename: 'Audience' }, audienceFragment)
  const fragmentData = client.readFragment(fragmentParams)

  return client.mutate({
    mutation: updateFacebookMarketableAudienceMutation,
    variables: { uuid, attributes },
    optimisticResponse: {
      updateFacebookMarketableAudience: {
        __typename: 'UpdateFacebookMarketableAudiencePayload',
        audience: {
          __typename: 'Audience',
          ...fragmentData,
          platformAudience: {
            ...fragmentData.platformAudience,
            ...attributes
          }
        }
      }
    }
  })
}

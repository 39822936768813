import { t } from 'ttag'

import Targeting    from 'shared/components/CampaignInfo/Targeting'

import CreativeSets from '../CreativeSets'

export default [{
  title: t`Targeting`,
  name: 'targeting',
  component: Targeting
},{
  title: t`Ads`,
  name: 'ads',
  component: CreativeSets
}]

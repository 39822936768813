import React, { useCallback } from 'react'
import styled                 from 'styled-components'
import { cloneDeep }          from 'lodash'
import { useQuery }           from 'react-apollo'

import { cardPaddingX, cardPaddingY } from 'shared/style/placeholders'
import Media                          from 'shared/components/Media'
import productType                    from 'shared/types/campaignType'
import stageAssetAssignments          from 'graphql/queries/stageAssetAssignments'
import projectAssetAssignments        from 'graphql/queries/projectAssetAssignments'

import attachFileToStage           from './attachFileToStage'
import createRemoveAssetAssignment from './createRemoveAssetAssignment'
import createCreateAssetAssignment from './createCreateAssetAssignment'

const StageMedia = ({ product: { promotable: stage }, className }) => {
  const { data: stageAssetAssignmentsData, loading: loadingStageAssetAssignments } = useQuery(
    stageAssetAssignments,
    { variables: { uuid: stage.uuid } }
  )

  const { data: projectAssetAssignmentsData, loading: loadingProjectAssetAssignments } = useQuery(
    projectAssetAssignments,
    { variables: { uuid: stage.projectUuid } }
  )

  const attachFileToAssetable = useCallback(({ client, blobId, mediaType }) => {
    return attachFileToStage({ client, blobId, mediaType, stageUuid: stage.uuid })
  }, [stage.uuid])

  if (loadingProjectAssetAssignments || loadingStageAssetAssignments) {
    return null
  }

  const projectAssets = projectAssetAssignmentsData.project.assetAssignments.map((assignment) => {
    const asset = cloneDeep(assignment.asset)
    asset.assignmentUuid = assignment.uuid
    return asset
  })

  const stageWithAssetAssignments = Object.assign(stage, {
    assetAssignments: stageAssetAssignmentsData.stage.assetAssignments
  })

  return (
    <div className={ className }>
      <Media
        availableAssets={ projectAssets }
        assetAssignable={ stageWithAssetAssignments }
        mediaSourceType={ 'Project' }
        attachFileToAssetable={ attachFileToAssetable }
        createRemoveAssetAssignment={ createRemoveAssetAssignment }
        createCreateAssetAssignment={ createCreateAssetAssignment }
      />
    </div>
  )
}

StageMedia.propTypes = {
  product: productType.isRequired
}

export default styled(StageMedia)`
  ${cardPaddingX}
  ${cardPaddingY}
`

import * as Yup from 'yup'
import { t }    from 'ttag'

export default Yup.object().shape({
  unitId: Yup.string().required(t`Please fill an ID.`).nullable(),
  unitType: Yup.string().required(t`Please select a unit type.`).nullable(),
  bedroomCount: Yup.number().
    required(t`Please fill a bedroom count.`).
    positive(t`Bedroom count must be greater than zero.`).
    integer(t`Bedroom count must integer.`).
    nullable(),
  sizeM2: Yup.number().
    required(t`Please fill a size.`).
    positive(t`Size must be greater than zero.`).
    nullable(),
  totalPrice: Yup.number().
    required(t`Please fill a total price.`).
    positive(t`Total price must be greater than zero.`).
    integer(t`Total price must integer.`).
    nullable(),
})

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { t } from 'ttag'

import FormLabel from 'shared/components/ui/FormLabel'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'

import PhaseDatePicker from './PhaseDatePicker'
import { defaultFormatter } from './phaseDateFormatter'

const CustomDateSelect = ({ className, dateChangeHandler, startDate, endDate }) => {
  const startDateChange = (value) => {
    const newStartDate = defaultFormatter(value)
    const newEndDate = endDate ? defaultFormatter(endDate) : null
    dateChangeHandler(newStartDate, newEndDate)
  }

  const endDateChange = (value) => {
    dateChangeHandler(
      defaultFormatter(startDate),
      defaultFormatter(value)
    )
  }

  return(
    <Container className={ className }>
      <div>
        <FormLabel>{ t`Phase start` }</FormLabel>
        <InputWrapper>
          <PhaseDatePicker
            data-test-id='start-date-picker'
            selected={ startDate }
            onChange={ startDateChange }
            dateFormat='dd-MM-yyyy'
          />
        </InputWrapper>
      </div>
      <div>
        <FormLabel>{ t`Phase end` }</FormLabel>
        <InputWrapper>
          <PhaseDatePicker
            data-test-id='end-date-picker'
            selected={ endDate }
            onChange={ endDateChange }
            dateFormat='dd-MM-yyyy'
          />
        </InputWrapper>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  input {
    width: 95% !important;
  }
`

CustomDateSelect.propTypes = {
  dateChangeHandler: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object
}

CustomDateSelect.defaultProps = {
  endDate: null
}

export default CustomDateSelect

import React from 'react'
import { string, node } from 'prop-types'
import styled from 'styled-components'

import projectType from 'shared/types/projectType'
import { unsetCardPadding } from 'shared/style/placeholders'
import { textMainInfoHeaderColor } from 'shared/style/colors'

const Image = ({ children, className, project, src }) => {
  return (
    <div
      className={ className }
      style={{ backgroundColor: project.defaultColor }}
    >
      {src && (
        <img src={ src } />
      )}
      { children }
    </div>
  )
}

Image.propTypes = {
  project: projectType.isRequired,
  children: node,
  src: string, // eslint-disable-line react/no-unused-prop-types
}

Image.defaultProps = {
  children: null,
  src: undefined
}

export default styled(Image)`
  ${unsetCardPadding}

  min-height: 7rem;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 56px;
    left: 0;
    z-index: 0;
    transform: translateY(-50%);
  }

  h1 {
    position: absolute;
    z-index: 1;
    top: 0;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255,255,255,.75), rgba(255,255,255,.75));
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-header {
    align-self: center;
    color: ${textMainInfoHeaderColor};
    display: table-cell;
    justify-self: center;
    font-size: 2.625rem;
    font-weight: 600;
    margin: 0;
    width: 100%;
    text-align: center;
  }
`

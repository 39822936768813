import React from 'react'
import styled from 'styled-components'
import { number, string, shape, func } from 'prop-types'

const Track = ({ className, source, target, getTrackProps }) => {
  const styles = {
    left: `${source.percent}%`,
    width: `${target.percent - source.percent}%`,
  }

  return <div className={ className } style={ styles } { ...getTrackProps() } />
}

Track.propTypes = {
  className: string.isRequired,
  getTrackProps: func.isRequired,
  source: shape({
    percent: number.isRequired
  }).isRequired,
  target: shape({
    percent: number.isRequired
  }).isRequired
}

export default styled(Track)`
  background-color: #2578ce;
  border-radius: 4px;
  cursor: pointer;
  height: 0.5rem;
  margin-top: 0.8rem;
  position: absolute;
  z-index: 1
`

import React from 'react'

const Messages = () => {
  return (
    <>
      <h1>You have successfully signed out</h1>

      <p>
        You have successfully signed out of the application.
        Click the sign in button below to continue your session.
      </p>
    </>
  )
}

export default Messages

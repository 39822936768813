import React from 'react'

import adminQuery    from 'graphql/queries/users/admin'
import userQuery     from 'graphql/queries/users/user'
import ResourceQuery from 'shared/components/ResourceQuery'
import { getUser, isUserAdmin } from 'shared/helpers/auth'

import UserInfo      from './UserInfo'
import UserImages    from './UserImages'
import Notifications from './Notifications'
import Sessions      from './Sessions'

const Profile = () => {
  const user = getUser()
  const query = isUserAdmin() ? adminQuery : userQuery

  return (
    <ResourceQuery query={ query } variables={{ uuid: user.uuid }} fetchPolicy="network-only">
      {(user) => (
        <>
          <UserInfo user={ user } />
          <UserImages user={ user } />
          <Notifications user={ user }/>
          <Sessions user={ user }/>
        </>
      )}
    </ResourceQuery>
  )
}

Profile.propTypes = {
}

export default Profile

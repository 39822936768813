import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { arrayOf, shape, func, string, bool, array } from 'prop-types'

import Table from 'shared/components/ui/Table'

import settingsFormatter from './settingsFormatter'
import permissionFormatter from './permissionFormatter'
import actionsFormatter from './actionsFormatter'
import responsibilityFormatter from './responsibilityFormatter'
import userFormatter from './userFormatter'

const StakeholdersTable = (props) => {
  const {
    className,
    companyUuid,
    onUserUpdate,
    onPermissionUpdate,
    onResponsibilityUpdate,
    onSettingsUpdate,
    onStakeholderDelete,
    stakeholders,
    errors,
    disabled
  } = props

  const disabledColumns = [
    {
      dataField: 'uuid',
      hidden: true
    }, {
      dataField: 'user.name',
      text: t`Name`,
      formatter: userFormatter,
      formatExtraData: { companyUuid, onUserUpdate, errors, stakeholders }
    }, {
      dataField: 'uuid',
      text: '',
      formatter: actionsFormatter,
      formatExtraData: { onStakeholderDelete }
    }
  ]

  const columns = [
    {
      dataField: 'uuid',
      hidden: true
    }, {
      dataField: 'user.name',
      text: t`Name`,
      formatter: userFormatter,
      formatExtraData: { companyUuid, onUserUpdate, errors, stakeholders }
    }, {
      dataField: 'keys.responsibility',
      text: t`Role`,
      formatter: responsibilityFormatter,
      formatExtraData: { onResponsibilityUpdate, errors }
    }, {
      dataField: 'permission',
      text: t`Able to edit`,
      formatter: permissionFormatter,
      formatExtraData: { onPermissionUpdate }
    }, {
      dataField: 'leadNotification',
      text: t`Lead notification`,
      formatter: settingsFormatter,
      formatExtraData: { onSettingsUpdate, field: 'leadNotification' }
    }, {
      dataField: 'uuid',
      text: '',
      formatter: actionsFormatter,
      formatExtraData: { onStakeholderDelete }
    }
  ]

  const data = stakeholders
    .map((el, idx) => ({
      ...el,
      keys: { responsibility: `responsibility-${idx}` }
    }))
    .sort((a, b) =>
      `${a.role}-${a.createdAt}`.localeCompare(`${b.role}-${b.createdAt}`)
    )

  const tableOptions = {
    bordered: false,
    columns: disabled ? disabledColumns : columns,
    data,
    keyField: 'uuid',
    headerClasses: (stakeholders.length ? '' : 'd-none')
  }

  return <Table className={ className } tableOptions={ tableOptions }/>
}

StakeholdersTable.propTypes = {
  onPermissionUpdate: func.isRequired,
  onResponsibilityUpdate: func.isRequired,
  onSettingsUpdate: func.isRequired,
  onStakeholderDelete: func.isRequired,
  onUserUpdate: func.isRequired,
  stakeholders: arrayOf(shape({
    uuid: string.isRequired,
    user: shape({
      uuid: string.isRequired
    }),
    role: string,
    responsibility: string,
    leadNotification: bool.isRequired,
    weeklyReport: bool.isRequired
  })).isRequired,
  companyUuid: string,
  disabled: bool,
  errors: array
}

StakeholdersTable.defaultProps = {
  companyUuid: undefined,
  disabled: false,
  errors: []
}

export default styled(StakeholdersTable)`
  tr:last-child td {
    border-bottom: 1px solid #dee2e6;
  }

  td {
    vertical-align: text-top
  }
`

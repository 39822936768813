import * as Yup from 'yup'
import { t }    from 'ttag'

export default Yup.array().
  of(
    Yup.object().shape({
      unitId: Yup.string().required(t`Please fill an ID.`).nullable(),
      unitType: Yup.string().required(t`Please select a unit type.`).nullable(),
      bedroomCount: Yup.number().
        required(t`Please fill a bedroom count.`).
        positive(t`Bedroom count must be greater than zero.`).
        integer(t`Bedroom count must integer.`).nullable(),
      sizeM2: Yup.number().
        required(t`Please fill a size.`).
        positive(t`Size must be greater than zero.`).
        nullable().
        min(
          Yup.ref('$stage.currentSnapshotRecord.unitSets[0].lowerSizeM2'),
          t`Unit size must be in range specified in "Stage details"`
        ).max(
          Yup.ref('$stage.currentSnapshotRecord.unitSets[0].upperSizeM2'),
          t`Unit size must be in range specified in "Stage details"`
        ),
      totalPrice: Yup.number().
        required(t`Please fill a total price.`).
        positive(t`Total price must be greater than zero.`).
        integer(t`Total price must integer.`).
        nullable().
        min(
          Yup.ref('$stage.currentSnapshotRecord.unitSets[0].lowerPrice'),
          t`Unit price must be in range specified in "Stage details"`
        ).max(
          Yup.ref('$stage.currentSnapshotRecord.unitSets[0].upperPrice'),
          t`Unit price must be in range specified in "Stage details"`
        )
    })
  )

import React from 'react'
import { flatten } from 'lodash'

import FacebookIcon from 'shared/components/ui/icons/channels/FacebookIcon'
import InstagramIcon from 'shared/components/ui/icons/channels/InstagramIcon'
import GmpIcon from 'shared/components/ui/icons/channels/GmpIcon'
import PortalIcon from 'shared/components/ui/icons/channels/PortalIcon'

import columns from './columns'

const SIZE_PER_PAGE = 5

const defaultSorted = [{
  dataField: 'totalImpressions',
  order: 'desc'
}]

export default function useAudienceTable(campaign, ads) {
  const channelsIcons = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    gmp: GmpIcon,
    portal: PortalIcon
  }

  const audiences = flatten(campaign.channels.map((channel) => {
    const ChannelIcon = channelsIcons[channel.type]

    return flatten(channel.audiences.map((audience) => {
      const filteredAds = ads.filter((ad) => {
        return ad.audience.uuid === audience.uuid
      })
      if (filteredAds.length === 0) return null

      const summary = {
        totalImpressions: 0,
        totalClicks: 0,
        leadsCount: audience.leadsCount,
        averageCpl: 0,
        cpc: 0,
        ctr: 0,
        totalSpent: 0
      }

      filteredAds.forEach((ad) => {
        summary.totalImpressions += ad.summary.totalImpressions
        summary.totalClicks += ad.summary.totalClicks
        summary.totalSpent += ad.summary.totalSpent
        summary.cpc = ad.summary.cpc
        summary.ctr = ad.summary.ctr
      })

      summary.averageCpl = summary.leadsCount === 0 ? 0 : (summary.totalSpent / summary.leadsCount)

      return {
        ...summary,
        ...audience,
        ...{ name: (<ChannelIcon content={ audience.name } />) }
      }
    }))
  }))

  const existedAudiences = audiences.filter((audience) => {
    return audience !== null
  })

  const tableOptions = {
    columns,
    defaultSorted,
    bordered: false,
    data: existedAudiences,
    keyField: 'uuid',
  }

  const paginationOptions = existedAudiences.length > SIZE_PER_PAGE ? (
    {
      sizePerPage: SIZE_PER_PAGE,
      totalSize: existedAudiences.length,
    }
  ) : null

  return {
    tableOptions,
    paginationOptions
  }
}

import { buildFragmentParams } from 'shared/helpers/graphql'
import createLandingPageMutation from 'graphql/mutations/campaign/createLandingPage'
import campaignLandingPagesFragment from 'graphql/fragments/campaignLandingPages'

const createLandingPage = (client, campaign, input) => {
  client.mutate({
    mutation: createLandingPageMutation,
    variables: { campaignUuid: campaign.uuid, input },
    update: (cache, { data: { createLandingPage: { landingPage } } }) => {
      const fragmentParams = buildFragmentParams(campaign, campaignLandingPagesFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          landingPages: [
            landingPage,
            ...fragmentData.landingPages
          ]
        }
      })
    }
  })
}

export default createLandingPage

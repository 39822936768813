import React, { useCallback, useState } from 'react'
import { array, string } from 'prop-types'
import styled            from 'styled-components'
import { t }             from 'ttag'
import { chunk }         from 'lodash'

import Modal from 'shared/components/ui/Modal'

import AdPreview        from './AdPreview'
import PreviewAllButton from './PreviewAllButton'

const PreviewAll = ({ ads, className, ...props }) => {
  const [show, setShow] = useState(false)
  const handleClose     = useCallback(() => setShow(false), [])
  const handleShow      = useCallback(() => setShow(true), [])

  return (
    <div>
      <PreviewAllButton onClick={ handleShow } />

      <Modal size="lg" show={ show } onHide={ handleClose } className={ className }>
        <Modal.Header closeButton>
          <h3>{ t`All ads` }</h3>
        </Modal.Header>
        <Modal.Body>
          { chunk(ads, 2).map((adsChunk, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={ i } className="ads-row">
              { adsChunk.map((ad) => (
                <AdPreview
                  { ...props }
                  key={ ad.uuid }
                  previewAsset={ ad.asset ? ad.asset.thumbnail : null }
                  className="w-50"
                />
              )) }
            </div>
          )) }
        </Modal.Body>
      </Modal>
    </div>
  )
}

PreviewAll.propTypes = {
  ads: array.isRequired,
  companyLogo: string,
  companyName: string,
  content: string,
  domain: string,
  title: string,
}

PreviewAll.defaultProps = {
  companyLogo: '',
  companyName: '',
  content: '',
  domain: '',
  title: ''
}

export default styled(PreviewAll)`
  .ads-row {
    align-items: flex-start;
    display: flex;
    margin-bottom: .5rem
  }

  ${AdPreview}:first-child {
    margin-right: .5rem
  }
`

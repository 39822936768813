import React from 'react'
import { t } from 'ttag'

import Button from 'shared/components/ui/Button'

import useAddNewButton from './useAddNewButton'

const AddNewButton = () => {
  const { createShowing, loading } = useAddNewButton()

  return (
    <Button
      disabled={ loading }
      onClick={ createShowing }
      data-test-id="add-new-showing"
    >
      { t`Add new` }
    </Button>
  )
}

export default AddNewButton

import React, { useState, useCallback } from 'react'
import { string, func }                 from 'prop-types'
import styled                           from 'styled-components'
import ReactCrop                        from 'react-image-crop'
import { t }                            from 'ttag'

import Button from 'shared/components/ui/Button'
import Modal  from 'shared/components/ui/Modal'

import getCroppedImage from './getCroppedImage'

import 'react-image-crop/dist/ReactCrop.css'

const ImageEditor = ({ fileDataURI, className, setFileDataURI, onUpload }) => {
  const [image, setImage] = useState(null)
  const [crop, setCrop]   = useState({})

  const hasSelectedArea = crop.width && crop.height

  const resetCrop        = useCallback(() => setCrop({}), [])
  const resetFileDataURI = useCallback(() => setFileDataURI(null), [])
  const setLoadedImage   = useCallback((image) => setImage(image), [])
  const setChangedCrop   = useCallback((crop) => setCrop(crop), [])

  const cropImage = () => {
    setFileDataURI(getCroppedImage(image, crop, 'file'))
    resetCrop()
  }

  return (
    <Modal show onHide={ resetFileDataURI } className={ className }>
      <Modal.Body>
        <ReactCrop
          src={ fileDataURI }
          crop={ crop }
          onImageLoaded={ setLoadedImage }
          onChange={ setChangedCrop }
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <div>
          <Button onClick={ cropImage } disabled={ !hasSelectedArea } className="mr-1">
            { t`Crop` }
          </Button>
          <Button onClick={ resetCrop } disabled={ !hasSelectedArea }>
            { t`Reset` }
          </Button>
        </div>

        <div>
          <Button
            variant="primary"
            onClick={ onUpload }
            data-test-id="image-editor-upload-button"
          >
            { t`Upload` }
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

ImageEditor.propTypes = {
  fileDataURI: string.isRequired,
  onUpload: func.isRequired,
  setFileDataURI: func.isRequired,
}

export default styled(ImageEditor)`
  .ReactCrop {
    background-color: #FFF;
    display: block;
  }

  .ReactCrop__image {
    max-height: initial;
    margin: 0 auto;
  }
`

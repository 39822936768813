import { useState } from 'react'

export default function useIsOpened(defaultIsOpened = true) {
  const [isOpened, setIsOpened] = useState(defaultIsOpened)

  const open   = () => setIsOpened(true)
  const close  = () => setIsOpened(false)
  const toggle = () => setIsOpened(!isOpened)

  return { isOpened, open, close, toggle }
}

import { string, shape, bool } from 'prop-types'

export default shape({
  uuid: string.isRequired,
  custom: bool.isRequired,
  name: string,
  url: string,
  title: string,
  intro: string,
  projectDescription: string,
  areaDescription: string,
  salesArgument1: string,
  salesArgument2: string,
  salesArgument3: string
})

import React from 'react'
import styled from 'styled-components'
import { func, string, oneOfType, number } from 'prop-types'

import {
  bluishColor,
  greenColor,
  redColor,
  darkGreyColor,
  lightGreyColor
} from 'shared/style/colors'

const ChartTab = ({
  title,
  tab,
  selectedTab,
  setSelectedTab,
  className,
  value
}) => {
  const active = (selectedTab === tab)

  return (
    <div
      className={ `${className} ${active ? 'active' : ''}` }
      onClick={ () => setSelectedTab(tab) }
    >

      <label className='title' htmlFor={ `${tab}-header` }>
        { title }
      </label>

      <div className='d-flex align-items-baseline' id={ `${tab}-header` }>
        <strong>{ value }</strong>
      </div>
    </div>
  )
}

ChartTab.propTypes = {
  selectedTab: string.isRequired,
  setSelectedTab: func.isRequired,
  tab: string.isRequired,
  title: string.isRequired,
  value: oneOfType([string, number]).isRequired
}

export default styled(ChartTab)`
  padding: 0.5rem 1rem
  margin: 0 0.75rem
  font-size: 0.75rem
  line-height: 1.45
  cursor: pointer

  &.active {
    border-radius: 4px
    border: solid 2px ${bluishColor}
    padding: 0.375rem 0.875rem
  }

  .title {
    color: ${lightGreyColor}
    margin-bottom: 0.25rem
  }

  &.active .title {
    color: ${darkGreyColor}
  }

  .percentage.increase {
    color: ${greenColor}
  }

  .percentage.decrease {
    color: ${redColor}
  }

  .percentage .direction {
    margin-right: 0.25rem
  }
`

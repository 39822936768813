import { buildFragmentParams } from 'shared/helpers/graphql'
import stageFragment from 'graphql/fragments/stage'
import createSalesArgumentMutation
  from 'graphql/mutations/projects/stages/createSalesArgumentMutation'

const createSalesArgument = (client, stage) => {
  client.mutate({
    mutation: createSalesArgumentMutation,
    variables: {
      stageUuid: stage.uuid,
      input: {}
    },
    update: (cache, { data: { createStageSalesArgument: { salesArgument } } }) => {
      const fragmentParams = buildFragmentParams(stage, stageFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          salesArguments: [
            ...fragmentData.salesArguments,
            salesArgument
          ]
        }
      })
    }
  })

}

export default createSalesArgument

import gql from 'graphql-tag'

export default gql`
  query companyRoles($companyUuid: ID!) {
    companyRoles(companyUuid: $companyUuid) {
      id
      roleName
    }
  }
`

import { useQuery } from '@apollo/react-hooks'

import companyRolesQuery from 'graphql/queries/companies/roles'

const useCompanyRoles = (companyUuid) => {
  const { data } = useQuery(companyRolesQuery, { variables: { companyUuid } })

  const companyRoles = data?.companyRoles || []

  return {
    companyRoles
  }
}

export default useCompanyRoles

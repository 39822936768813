import gql from 'graphql-tag'

import creativeSet from 'graphql/fragments/creativeSet'

export default gql`
  query campaignCreativeSets($campaignUuid: ID!) {
    campaignCreativeSets(campaignUuid: $campaignUuid) {
      ... CreativeSet
    }
  }
  ${creativeSet}
`

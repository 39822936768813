import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'
import { string, func, array, object } from 'prop-types'

import { combineErrors }   from 'shared/helpers/inputValidation'
import InputWrapper        from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import BuildingTypesSelect
  from 'shared/components/NewStage/StageDetails/Details/BuildingTypesSelect'
import PeriodPicker
  from 'shared/components/Project/ProjectInformation/ProjectStatusInformation/Phase/PeriodPicker'

const validatableFields = [
  'buildingTypes'
]

const enabledTimeUnits = ['custom', 'quarter', 'year', 'month', 'date']

const RightColumn = ({ className, building, onUpdate, errors }) => {

  const handleBuildingTypeChange = values => onUpdate({ buildingTypes: values.map(el => el.value) })

  const errorsOnField = validatableFields.reduce((result, field) => {
    result[field] = combineErrors({ errors, field, uuid: building.uuid })
    return result
  }, {})

  const handleConstructionStartChange = (input) => {
    onUpdate(buildPayload(input, 'construction'))
  }

  const handleDeliverDateChange = (input) => {
    onUpdate(buildPayload(input, 'moving'))
  }

  const buildPayload = (input, fieldGroup) => {
    const updatePayload = {}

    if (input.phasePeriod) {
      updatePayload[fieldGroup + 'StartPhasePeriod'] = input.phasePeriod
    }
    if (input.startDate) {
      updatePayload[fieldGroup + 'StartAt'] = input.startDate
    }
    if (input.endDate) {
      updatePayload[fieldGroup + 'EndAt'] = input.endDate
    }

    return updatePayload
  }

  return (
    <div className={ className } >
      <div className="group" data-test-id='building-types'>
        <label>{ t`Unit types` }</label>
        <InputWrapper error={ errorsOnField['buildingTypes'] } >
          <BuildingTypesSelect
            value={ building.buildingTypes }
            onChange={ handleBuildingTypeChange }
          />
        </InputWrapper>
      </div>
      <div className="mt-4" data-test-id='construction-start'>
        <label>{ t`Construction start` }</label>
        <PeriodPicker
          handleChange={ handleConstructionStartChange }
          phasePeriod={ building.constructionStartPhasePeriod }
          startDate={ building.constructionStartAtIsoFormat }
          endDate={ building.constructionEndAtIsoFormat }
          enabledTimeUnits={ enabledTimeUnits }
        />
      </div>
      <div className="mt-4" data-test-id='move-in'>
        <label>{ t`Delivery date (move-in)` }</label>
        <PeriodPicker
          handleChange={ handleDeliverDateChange }
          phasePeriod={ building.movingStartPhasePeriod }
          startDate={ building.movingStartAtIsoFormat }
          endDate={ building.movingEndAtIsoFormat }
          enabledTimeUnits={ enabledTimeUnits }
        />
      </div>
    </div>
  )
}

RightColumn.propTypes = {
  building: object.isRequired,
  className: string.isRequired,
  onUpdate: func.isRequired,
  errors: array
}

RightColumn.defaultProps = {
  errors: []
}

export default styled(RightColumn)`
  width: 50%;
  padding: 1.25rem 2.8125rem 1.625rem 1.25rem;

  .input-wrapper {
    width: 100%
  }
`

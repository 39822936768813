import { gql } from 'apollo-boost'

export default gql`
  fragment Lead on Lead {
    uuid
    fullName
    progress
    responseTime
    qualityScore
    audienceName
    email
    phone
    phoneCode
    phoneNumber
    phoneFormatted
    status
    progress
    createdAt
    updatedAt
    leadMessage
    additionalInfo
    contactInfo {
      contactTime
      contactBySms
      contactByMail
      contactByPhone
      contactByWhatsApp
      contactOtherTime
      contactDuringTheDay
      contactDuringTheEvening
    }
    user {
      uuid
      name
    }
  }
`

import gql from 'graphql-tag'

import agent from 'graphql/fragments/agent'

export default gql`
  mutation createAgent(
    $projectUuid: ID!
  ) {
    createAgent(
      projectUuid: $projectUuid
    ) {
      agent {
        ... Agent
      }
    }
  }
  ${agent}
`

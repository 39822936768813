import React from 'react'
import styled from 'styled-components'
import { string, func, number } from 'prop-types'

import ThriftyInput from 'shared/components/ui/ThriftyInput'

const BedroomCountInput = ({ bedroomCount, onUpdate, uuid, className, ...props }) => {
  const handleInput = bedroomCount => onUpdate(
    { uuid, attributes: { bedroomCount: parseInt(bedroomCount) } }
  )

  return (
    <ThriftyInput
      className={ className }
      type='number'
      min={ 0 }
      value={ bedroomCount }
      onUpdate={ handleInput }
      data-test-id='bedrooms-count-input'
      { ...props }
    />
  )
}

BedroomCountInput.propTypes = {
  onUpdate: func.isRequired,
  uuid: string.isRequired,
  bedroomCount: number
}

BedroomCountInput.defaultProps = {
  bedroomCount: 0
}

export default styled(BedroomCountInput)`
  box-shadow: none !important;
  height: 2.375rem
  font-size: .75rem !important
`

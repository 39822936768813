import { gql } from 'apollo-boost'

import stageUnit from 'graphql/fragments/stageUnit'

export default gql`
  mutation createBuildingUnit(
    $buildingUuid: ID!
    $attributes: UnitAttributes!
  ) {
    createBuildingUnit(
      buildingUuid: $buildingUuid
      attributes: $attributes
    ) {
      unit {
        ... StageUnit
      }
    }
  }
  ${stageUnit}
`

import React from 'react'
import { string, func, bool } from 'prop-types'

import Checkbox from 'shared/components/ui/Checkbox'

const PermissionCheckbox = ({ readOnly, value, stakeholderUuid, onPermissionUpdate }) => {
  const checked = value === 'write'

  const handleChange = e => {
    const permission = e.target.checked ? 'write' : 'read'

    onPermissionUpdate({ stakeholderUuid, permission })
  }

  return (
    <Checkbox
      shadow={ false }
      disabled={ readOnly }
      checked={ checked }
      data-test-id='permission-checkbox'
      onChange={ handleChange } />
  )
}

PermissionCheckbox.propTypes = {
  onPermissionUpdate: func.isRequired,
  readOnly: bool.isRequired,
  stakeholderUuid: string.isRequired,
  value: string.isRequired
}

export default PermissionCheckbox


import { gql } from 'apollo-boost'

export default gql`
  mutation archiveProject(
    $uuid: ID!
  ) {
    archiveProject(
      uuid: $uuid
    ) {
      project {
        uuid
      }
      errors
    }
  }
`

export const white = '#fff'

export const shuttleGray = '#556070'

export const darkGreyColor = '#363636'

export const lightGreyColor = '#828282'

export const componentBackgroundColor = white

export const greyishBrownColor = '#4a4a4a'

export const greyishWhiteColor = '#F6FAFC'

export const altoGreyColor = '#dbdbdb'

export const galleryGreyColor = '#efefef'

export const labelColor = '#57606f'

export const textColor = greyishBrownColor

export const textColorLight = '#A0A2A3'

export const textHeaderColor = '#626262'

export const textMainInfoHeaderColor = '#444'

export const iconsGreyColor = '#697386'

export const textGreyColor = '#788192'

export const buttonTextColor = '#697386'

export const borderGreyColor = '#979797'

export const buttonBorderColor = '#ced4da'

export const bluishColor = '#2578ce'

export const dimBlue = '#6273d4'

export const lightBlueColor = '#529fd7'

export const havelockBlue = '#4a90e2'

export const darkRoseColor = '#bd3e4b'

export const mapLightBlueColor = '#ccd1ff'

export const mapLightRedColor = '#ffdfdf'

export const mapMidBlueColor = '#8890aa'

export const mapMidRedColor = '#bb8a8a'

export const mapDarkBlueColor = '#3b5898'

export const mapDarkRedColor = '#cc2222'

export const mapWhiteColor = '#fcfdfd'

export const redColor = '#dc3545'

export const blackColor = '#2e3342'

export const greenColor = '#47b881'

export const whiteButtonHoverBackground = '#f8f9fa'

export const campaignLiveMarkerColor = '#67deb5'

export const campaignClosedMarkerColor = '#a43737'

export const campaignCountdownMarkerColor = '#4794b0'

export const campaignPausedMarkerColor = '#d7a345'

export const purlpeColor = '#c6ade7'

export const inheritColor = 'inherit'

export const mediumGrey = 'rgba(172, 172, 172, 0.35)'

export const locationMediumGrey = '#777777'

export const locationLightGrey = '#f5f7f8'

export const locationBorderGrey = 'rgb(204, 204, 204)'

export const dropDownTextGrey = '#212529'

export const dropDownBorderGrey = '#A0A0A0'

import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'
import { array, func } from 'prop-types'

import FormLabel     from 'shared/components/ui/FormLabel'
import NewItemButton from 'shared/components/ui/NewItemButton'
import DeleteButton  from 'shared/components/ui/DeleteButton'

import FormInput from './FormInput'

const CustomFacilities = (props) => {
  const {
    className,
    facilities,
    addCustomFacility,
    updateCustomFacility,
    deleteCustomFacility
  } = props

  const handleAddCustomFacility = () => {
    addCustomFacility({
      data: { title: '', description: '' }
    })
  }

  const handleUpdateCustomFacility = (facility, field, input) => {
    updateCustomFacility({ uuid: facility.uuid, data: { [field]: input } })
  }

  const AddNewCustomFacilityButton = () => {
    return (
      <NewItemButton
        className="add-new-btn"
        onClick={ handleAddCustomFacility }
        data-test-id='add-new-custom-facility-button'
      >
        { t`Add new custom facility` }
      </NewItemButton>
    )
  }

  const textFieldFormatter = (facility, field) => {
    return (
      <FormInput
        data-test-id={ `${field}-field` }
        value={ facility[field] }
        onUpdate={ (value) => handleUpdateCustomFacility(facility, field, value) }
      />
    )
  }

  const deleteFieldFormatter = (facility) => {
    const handleDelete = () => {
      if (confirm(t`Are you sure you want to remove custom facility?`)) {
        deleteCustomFacility({ uuid: facility.uuid })
      }
    }

    return (
      <DeleteButton onClick={ handleDelete } />
    )
  }

  const renderFacilitiesHeader = () => {
    if (facilities.length === 0) return null

    return (
      <div className='facilities-header'>
        <p>{ t`Title` }</p>
        <p>{ t`Description` }</p>
      </div>
    )
  }

  const renderFacilities = () => {
    return facilities.map((facility) => {
      return (
        <div className='facility' key={ facility.uuid }>
          <div data-test-id='title-field'>{ textFieldFormatter(facility, 'title') }</div>
          <div data-test-id='description-field'>
            { textFieldFormatter(facility, 'description') }
          </div>
          <div>{ deleteFieldFormatter(facility) }</div>
        </div>
      )
    })
  }

  return (
    <div className={ className }>
      <FormLabel>{ t`Custom facilities` }</FormLabel>
      { renderFacilitiesHeader() }
      { renderFacilities() }
      <AddNewCustomFacilityButton />
    </div>
  )
}

CustomFacilities.propTypes = {
  addCustomFacility: func.isRequired,
  deleteCustomFacility: func.isRequired,
  facilities: array.isRequired,
  updateCustomFacility: func.isRequired,
}

export default styled(CustomFacilities)`
  margin: 30px 0px;

  .add-new-btn {
    margin-left: 0;
  }

  label {
    margin-bottom: .5rem;
  }

  .facilities-header {
    display: flex;
    flex-direction: row;

    p {
      padding: .5rem 1rem 0;
      color: #697386;
      font-size: .85rem;
      margin: 0;

      &:nth-of-type(1) {
        width: 33%;
        padding-left: 0;
      }
    }
  }

  .facility {
    display: flex;
    flex-direction: row;

    div {
      padding: .7rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &:nth-of-type(1) {
        width: 33%;
        padding-left: 0;
      }

      &:nth-of-type(2) {
        flex: 1;
      }

      &:nth-of-type(3) {
        width: 10%;
      }
    }
  }
`

import React from 'react'
import { string } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import columnType from 'shared/types/columnType'
import { labelColor } from 'shared/style/colors'

const Column = ({ column, className }) => {
  return (
    <div className={ `${className}` }>
      { column.filter(Boolean).map(({ label, value }) => {
        return (
          <Row key={ label } >
            <Col xs={ 5 }>{ label }</Col>
            <Col xs={ 7 }>{ value }</Col>
          </Row>
        )
      }) }
    </div>
  )
}

Column.propTypes = {
  column: columnType.isRequired,
  className: string
}

Column.defaultProps = {
  className: ''
}

export default styled(Column)`
  .row {
    margin-bottom: .5rem
  }
  
  .row:last-child {
    margin-bottom: 0
  }
  
  [class^=col] {
    color: #4a4a4a
    font-size: .9rem
    line-height: 1.5rem
  }
  
  [class^=col]:first-child {
    color: ${labelColor}
  }
  
  [class^=col]:first-letter {
    text-transform: uppercase
  }
`

import React                    from 'react'
import { func, object, string } from 'prop-types'
import { useQuery }             from 'react-apollo'
import { t }                    from 'ttag'

import FormField from '../FormField'

import stageQuery   from './stageQuery'
import LocaleSelect from './LocaleSelect'

const LocaleFormField = props => {
  const {
    values,
    touched,
    errors,
    onChange,
    defaultValue
  } = props

  const { data, loading } = useQuery(stageQuery, {
    variables: { uuid: values.stageUuid }
  })

  if (loading) {
    return null
  }

  const allAvailableCompanyLocalesAndCountryCodes
    = data?.stage?.allAvailableCompanyLocalesAndCountryCodes || []
  const availableCompanyLocalesAndCountryCodes
    = data?.stage?.availableCompanyLocalesAndCountryCodes || []

  const options = allAvailableCompanyLocalesAndCountryCodes.filter((locale) => {
    return availableCompanyLocalesAndCountryCodes.includes(locale.value)
  }).map((locale) => {
    return { value: locale.value, label: locale.name }
  })

  if (options.length <= 1) {
    return null
  }

  return (
    <FormField
      label={ t`Campaign locale` }
      touched={ touched.locale }
      errors={ errors.locale }
    >
      <div data-test-id='campaign-locale-select-wrapper'>
        <LocaleSelect
          defaultValue={ options.find(opt => opt.value === defaultValue) }
          isInvalid={ touched.locale && errors.locale }
          onChange={ onChange }
          options={ options }
        />
      </div>
    </FormField>
  )
}

LocaleFormField.propTypes = {
  errors: object.isRequired,
  onChange: func.isRequired,
  touched: object.isRequired,
  values: object.isRequired,
  defaultValue: string
}

LocaleFormField.defaultProps = {
  defaultValue: ''
}

export default LocaleFormField

import React, { useState, useEffect } from 'react'
import classNames              from 'classnames'
import { Form, Row, Col }      from 'react-bootstrap'
import { t }                   from 'ttag'
import { object, func, array } from 'prop-types'
import { toast }               from 'react-toastify'
import moment                  from 'moment'

import {
  todayDate, dateInThePast, lessThanOneHourFromNow, sinceDate, agoNowDate
}                      from 'shared/helpers/date'
import Datepicker      from 'shared/components/ui/Datepickers/Datepicker'
import { isUserAdmin } from 'shared/helpers/auth'

import FormField                      from './FormField'
import CampaignPackageSelect          from './CampaignPackageSelect'
import FacebookPageSelect             from './FacebookPageSelect'
import useSource                      from './useSource'
import useEmVestStartDateInDeadPeriod from './useEmVestStartDateInDeadPeriod'

const EmVest = 'em_vest'

const FieldSet = props => {
  const {
    facebookPages,
    values,
    errors,
    touched,
    handleChange,
    property
  } = props

  const source = useSource(property)
  const [maxEndDate, setMaxEndDate] = useState()

  useEffect(() => {
    const dynamicEndDate = (source === EmVest)
      ? moment(values.startDate).add(240, 'hour').toDate()
      : sinceDate(agoNowDate('1', 'day'), '1', 'year')

    setMaxEndDate(dynamicEndDate)
  }, [values.startDate])

  useEffect(() => {
    setTimeout(() => {
      handleChange({ target: { name: 'startDate', value: sinceDate(todayDate(), '25', 'hour') } })
    }, 1000)
  }, [])

  const handleTimeChange = ({ name, value }) => {
    if (!isUserAdmin() && dateInThePast(moment(value).subtract(24, 'hour'))) {
      name === 'startDate'
        ? toast.error(t`Start time must be at least 24 hours from now`)
        : toast.error(t`End date must be in the future`)
    }

    if (isUserAdmin() && lessThanOneHourFromNow(value)) {
      name === 'startDate'
        ? toast.error(t`Start time must be at least 1 hour from now`)
        : toast.error(t`End time must be at least 1 hour from now`)
    }

    if (name === 'startDate' && !isUserAdmin() && useEmVestStartDateInDeadPeriod(value, source)) {
      toast.error(t`Start date must be before Friday 15:00 or after Monday 08:00`)
    }

    handleChange({ target: { name: name, value } })
  }

  return (
    <>
      {
        facebookPages.length > 1
          && <FormField
            label={ t`Facebook Page` }
            touched={ touched.facebookPage }
            errors={ errors.facebookPage }
          >
            <div data-test-id='goal-type-select-wrapper'>
              <FacebookPageSelect
                propertyUuid={ values.propertyUuid }
                facebookPageUuid={ values.facebookPageUuid }
                facebookPages={ facebookPages }
                isInvalid={ touched.facebookPageUuid && errors.facebookPageUuid }
                onChange={ handleChange }
              />
            </div>
          </FormField>
      }

      <FormField
        label={ t`Campaign name` }
        touched={ touched.name }
        errors={ errors.name }
      >
        <Form.Control
          name="name"
          isInvalid={ touched.name && errors.name }
          defaultValue={ values.name }
          data-test-id='name-input'
          onChange={ handleChange } />
      </FormField>

      <FormField
        label={ t`Start & end date` }
        touched={ touched.startDate || touched.endDate }
        errors={ errors.startDate || errors.endDate }
      >
        <Row>
          <Col sm="6">
            <div data-test-id='date-start-input-wrapper'>
              <Datepicker
                shouldCloseOnSelect
                disabled={ values.startDateDisabled }
                disabledKeyboardNavigation={ false }
                className={ classNames({ 'is-invalid': touched.startDate && errors.startDate }) }
                minDate={ todayDate() }
                maxDate={ sinceDate(agoNowDate('1', 'day'), '1', 'year') }
                selected={ values.startDate }
                onChange={ value => handleTimeChange({ name: 'startDate', value }) } />
            </div>
          </Col>
          <Col sm="6">
            <div data-test-id='date-end-input-wrapper'>
              <Datepicker
                shouldCloseOnSelect
                disabled={ values.endDateDisabled }
                disabledKeyboardNavigation={ false }
                minDate={ values.startDate && values.startDate }
                maxDate={ maxEndDate && maxEndDate }
                className={ classNames({ 'is-invalid': touched.startDate && errors.startDate }) }
                selected={ values.endDate }
                onChange={ value => handleTimeChange({ name: 'endDate', value }) } />
            </div>
          </Col>
        </Row>
      </FormField>

      <FormField
        label={ t`Campaign package` }
        touched={ touched.blueprintTemplateUuid }
        errors={ errors.blueprintTemplateUuid }
      >
        <div data-test-id='package-select-wrapper'>
          <CampaignPackageSelect
            companyUuid={ property?.companyUuid }
            blueprintTemplateUuid={ values.blueprintTemplateUuid }
            isInvalid={ touched.blueprintTemplateUuid && errors.blueprintTemplateUuid }
            onChange={ handleChange }
          />
        </div>
      </FormField>

      <FormField
        label={ t`Beneficiary` }
        touched={ touched.dsaBeneficiary }
        errors={ errors.dsaBeneficiary }
      >
        <Form.Control
          name="dsaBeneficiary"
          isInvalid={ touched.dsaBeneficiary && errors.dsaBeneficiary }
          defaultValue={ values.dsaBeneficiary }
          data-test-id='dsaBeneficiary-input'
          onChange={ handleChange } />
      </FormField>

      <FormField
        label={ t`Payor` }
        touched={ touched.dsaPayor }
        errors={ errors.dsaPayor }
      >
        <Form.Control
          name="dsaPayor"
          isInvalid={ touched.dsaPayor && errors.dsaPayor }
          defaultValue={ values.dsaPayor }
          data-test-id='dsaPayor-input'
          onChange={ handleChange } />
      </FormField>

    </>
  )
}

FieldSet.propTypes = {
  errors: object.isRequired,
  facebookPages: array.isRequired,
  handleChange: func.isRequired,
  property: object.isRequired,
  touched: object.isRequired,
  values: object.isRequired
}

export default FieldSet

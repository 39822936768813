import React    from 'react'
import styled   from 'styled-components'
import { func } from 'prop-types'
import { t }    from 'ttag'
import { useApolloClient } from 'react-apollo'

import landingPageType from 'shared/types/landingPageType'
import BigNumberInput  from 'shared/components/ui/ThriftyInput/BigNumber'
import Switch          from 'shared/components/ui/Switch'

import { SectionHeader, PricesContainer } from '../elements'

import updateLandingPage from './updateLandingPage'

const Prices = ({ className, landingPage, onUpdate }) => {
  const client = useApolloClient()

  return <PricesContainer className={ className }>
    <div className='prices'>
      <div>
        <SectionHeader>{ t`Min` }</SectionHeader>
        <BigNumberInput
          value={ landingPage.minPrice }
          onUpdate={ minPrice => onUpdate({ minPrice }) }
          data-test-id='min-price-input'
        />
      </div>

      <div>
        <SectionHeader>{ t`Max` }</SectionHeader>
        <BigNumberInput
          value={ landingPage.maxPrice }
          onUpdate={ maxPrice => onUpdate({ maxPrice }) }
          data-test-id='max-price-input'
        />
      </div>

      <div>
        <SectionHeader>{ t`Auto` }</SectionHeader>
        <Switch
          onChange={
            () => updateLandingPage(
              client,
              landingPage,
              { autoPrices: !landingPage.autoPrices }
            )
          }
          checked={ landingPage.autoPrices }
          data-test-id='auto-prices-toggle'
        />
      </div>
    </div>
  </PricesContainer>
}

Prices.propTypes = {
  landingPage: landingPageType.isRequired,
  onUpdate: func.isRequired
}

export default styled(Prices)`
  flex-direction: column;

  .prices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

import React           from 'react'
import { t }           from 'ttag'
import { useQuery }    from 'react-apollo'
import { useMutation } from '@apollo/react-hooks'
import { omit }        from 'lodash'

import matchType               from 'shared/types/matchType'
import NewPropertyCampaignForm from 'shared/components/NewAutoCampaign/PropertyCampaignForm'
import formDataQuery           from 'graphql/queries/property_campaigns/form_data'
import createMutation          from 'graphql/mutations/property_campaigns/create'
import facebookPagesQuery      from 'graphql/queries/companies/facebookPages'

import onCampaignCreated from './onCampaignCreated'
import onSemiAutoCampaignCreated from './onSemiAutoCampaignCreated'
import onPropertyCampaignCreationFailed from './onPropertyCampaignCreationFailed'
import LoadingForm from './LoadingForm'

const PropertyCampaignForm = ({ match }) => {
  const uuid = match.params.uuid
  const [createPropertyCampaign, { loading: loadingCreatePropertyCampaign }]
    = useMutation(createMutation)

  const { data, loading: loadingProperty } = useQuery(
    formDataQuery,
    { variables: { uuid } }
  )

  const {
    data: facebookPagesData,
    loading: loadingFacebookPages
  } = useQuery(facebookPagesQuery, {
    variables: { propertyUuid: uuid }
  })

  if (loadingProperty || loadingFacebookPages || loadingCreatePropertyCampaign) {
    return <LoadingForm />
  }

  const { propertyCampaignFormData } = data
  const { property } = propertyCampaignFormData
  const { company } = propertyCampaignFormData

  const onSubmit = (values, actions) => {
    const attributes = omit(values, ['blueprintTemplateUuid'])
    const blueprintTemplateUuid = values.blueprintTemplateUuid

    createPropertyCampaign({ variables: { attributes, blueprintTemplateUuid } })
      .then(({ data: { createPropertyCampaign } }) => {
        if(company.enabledFeatures.includes('property_semi_auto_order')) {
          onSemiAutoCampaignCreated(createPropertyCampaign)
        } else {
          onCampaignCreated(createPropertyCampaign.campaign)
        }
        onPropertyCampaignCreationFailed(createPropertyCampaign.errors)
      })
      .finally(() => actions.setSubmitting(false))
  }

  const facebookPages    = facebookPagesData.companyFacebookPages?.facebookPages
  const facebookPageUuid = facebookPagesData.companyFacebookPages?.defaultFacebookPageUuid

  const defaultCampaignSpecificationUuid = property.defaultCampaignSpecificationUuid

  const formValues = {
    propertyUuid: uuid,
    name: `${t`For sale`} - ${property.location.address}`,
    dsaBeneficiary: '',
    dsaPayor: '',
    blueprintTemplateUuid: defaultCampaignSpecificationUuid,
    facebookPageUuid
  }

  return (
    <NewPropertyCampaignForm
      property={ property }
      formValues={ formValues }
      facebookPages={ facebookPages }
      onSubmit={ onSubmit }
    />
  )
}

PropertyCampaignForm.propTypes = {
  match: matchType.isRequired
}

export default PropertyCampaignForm

import React from 'react'
import { string, func } from 'prop-types'
import { t } from 'ttag'

import Select from 'shared/components/ui/Select'

const options = [
  { value: 'low', label: t`Low` },
  { value: 'high', label: t`High` }
]

const LqsSelect = ({ value, onChange }) => {
  const handleChange = ({ value }) => {
    onChange({ target: { name: 'lqs', value } })
  }

  return (
    <Select
      small
      placeholder=''
      noOptionsMessage={ () => t`No options` }
      value={ options.find((option) => value === option.value) }
      onChange={ handleChange }
      options={ options }
    />
  )
}

LqsSelect.propTypes = {
  onChange: func.isRequired,
  value: string,
}

LqsSelect.defaultProps = {
  value: undefined,
}

export default LqsSelect

import { kebabCase } from 'lodash'

import { availableLocales } from 'locale'

export default function locationSortFunc(value1, value2, order) {
  const text1 = value1.map(({ title }) => title).join('')
  const text2 = value2.map(({ title }) => title).join('')

  const locales = availableLocales.map(({ key }) => kebabCase(key))
  const comparisonResult = text1.localeCompare(text2, locales)

  return (order === 'asc' ? 1 : -1) * comparisonResult
}

import React from 'react'

import LeadsIndex from 'shared/components/LeadsIndex'

const Leads = () => {
  return (
    <LeadsIndex />
  )
}

export default Leads

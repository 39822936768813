import React from 'react'
import { t } from 'ttag'
import { string, array } from 'prop-types'
import styled from 'styled-components'

import Label from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/Label'

import Select from './Select'
import useSetColor from './useSetCoror'

const options = [{
  value: '#9D162E',
  label: t`red`
}, {
  value: '#55565A',
  label: t`grey`
}, {
  value: '#0E4982',
  label: t`blue`
}, {
  value: '#268D5B',
  label: t`green`
}]

const ColorSelect = ({ className, selectedColor, creativeSetUuid, companyColors, ...props }) => {
  const availableColors = companyColors.length > 0 ? companyColors : options
  const value = availableColors.find(({ value }) => selectedColor === value)
  const setColor = useSetColor(creativeSetUuid)

  return (
    <div className={ className }>
      <Label>{ t`Select Color` }</Label>

      <div data-test-id="color-container">
        <Select
          onChange={ setColor }
          options={ availableColors }
          value={ value }
          selectedColor={ selectedColor }
          placeholder={ t`Select...` }
          noOptionsMessage={ () => t`No options` }
          { ...props }
        />
      </div>
    </div>
  )
}

ColorSelect.propTypes = {
  creativeSetUuid: string.isRequired,
  companyColors: array,
  selectedColor: string
}

ColorSelect.defaultProps = {
  companyColors: [],
  selectedColor: null
}

export default styled(ColorSelect)`
  margin-left: .75rem;
`

import gql from 'graphql-tag'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  query Stage(
    $uuid: ID!
  ) {
    stage(uuid: $uuid) {
      assetAssignments {
        ... AssetAssignment
      }
      uuid
    }
  }
  ${assetAssignment}
`

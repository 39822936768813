import React, { useCallback, useEffect, useState } from 'react'
import { t } from 'ttag'
import { arrayOf, shape, func, string } from 'prop-types'

import MultiSelect from 'shared/components/ui/MultiSelect/MultiSelect'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

import ValueContainer from './ValueContainer'
import Option from './Option'

const components = { ValueContainer, Option }

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1,
    maxWidth: '18.4rem'
  }),
  option: provided => ({
    ...condensedSelectStyles.option(provided),
    backgroundColor: 'white',
    padding: '0.125rem 0.75rem',
    minHeight: '29px'
  }),
  valueContainer: provided => ({
    ...condensedSelectStyles.valueContainer(provided),
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }),
  menu: provided => ({
    ...provided,
    zIndex: 3
  })
}

const AudiencesSelect = ({
  audiences,
  allAudiences,
  onChange,
  setTouched,
  ...props
}) => {
  const [currentAudiences, setCurrentAudiences] = useState([])
  useEffect(() => {
    setCurrentAudiences(audiences)
  }, [audiences])

  const options = allAudiences.map(({ uuid, name }) => ({ value: uuid, label: name }))

  const handleChange = useCallback((selectedOptions) => {
    setCurrentAudiences(
      selectedOptions.map((selectedOption) => {
        return { uuid: selectedOption.value, name: null, __typename: 'Audience' }
      })
    )
    onChange(selectedOptions.map((option) => option.value))
  }, [])

  const handleBlur = () => setTouched && setTouched(true)

  return (
    <MultiSelect
      styles={ styles }
      hideSelectedOptions={ false }
      value={ currentAudiences.map(({ uuid }) => ({ value: uuid, key: uuid })) }
      placeholder={ t`Select audience` }
      noOptionsMessage={ () => t`No options` }
      onChange={ handleChange }
      onInputChange={ handleBlur }
      components={ components }
      options={ options }
      { ...props }
    />
  )
}

AudiencesSelect.propTypes = {
  allAudiences: arrayOf(shape({
    name: string,
    uuid: string
  })).isRequired,
  audiences: arrayOf(shape({
    name: string,
    uuid: string
  })).isRequired,
  onChange: func.isRequired,
  setTouched: func
}

AudiencesSelect.defaultProps = {
  setTouched: undefined
}

export default AudiencesSelect

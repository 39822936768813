import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { t } from 'ttag'

import leadsQuery from 'graphql/queries/leadsWithProject'
import useCreateLead from 'shared/hooks/useCreateLead'
import useUrlParams from 'shared/hooks/useUrlParams'
import { ensureNumber } from 'shared/helpers/number'
import { paginatedSearchTextChangeHandler } from 'shared/helpers/paginatedSearch'
import PrimaryButton from 'shared/components/ui/PrimaryButton'
import Card from 'shared/components/ui/Card'
import Header from 'shared/components/ui/Card/StaticHeader'
import PlusIcon from 'shared/components/ui/icons/PlusIcon'
import LeadsList from 'shared/components/LeadsList/List'
import Search from 'shared/components/ui/Search'
import LeadFormModal from 'shared/components/LeadForm/Modal'
import { canRead, canCreate } from 'shared/helpers/auth'

import indexColumns from '../LeadsList/indexPageColumns'

const LeadsIndex = () => {
  const [isCreating, setIsCreating] = useState(false)
  const { createLead } = useCreateLead()

  const [urlParams, setUrlParams] = useUrlParams()
  const { sortBy, sortOrder } = urlParams
  const page = ensureNumber(urlParams.page, 1)
  const search = urlParams.search
  const perPage = 15

  const { data } = useQuery(leadsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      query: { page, search, sortOrder, sortBy, perPage }
    }
  })

  const leads = data ? data.leads : { items: [], total: 0 }

  const handleSearchChange = paginatedSearchTextChangeHandler(urlParams, setUrlParams)

  const handleSubmit = (input) => {
    createLead({ variables: { input } })
  }

  return (
    <Card header={ (
      <div className="d-flex justify-content-between">
        <Header className="align-self-left">{ t`Leads` }</Header>
        <div className="align-self-right d-flex justify-content-between">
          <Search
            searchText={ search }
            onSearch={ handleSearchChange }
            className='search-input mr-4'
          />
          {canCreate('leads') && (canRead('leads_projects') || canRead('projects')) && (
            <PrimaryButton
              onClick={ () => setIsCreating(true) }
              style={{ minWidth: '11rem' }}
              data-test-id='add-new-lead-button'
            >
              <PlusIcon />
              <span>{ t`Add new lead` }</span>
            </PrimaryButton>
          )}
        </div>
      </div>
    ) }>
      <LeadsList
        data={ leads }
        params={ urlParams }
        onTableChange={ setUrlParams }
        perPage={ perPage }
        data-test-id={ 'leads-list' }
        columns={ indexColumns }
      />

      <LeadFormModal
        title={ t`Create new lead` }
        show={ isCreating }
        onHide={ () => setIsCreating(false) }
        onSubmit={ handleSubmit }
      />
    </Card>
  )
}

export default LeadsIndex

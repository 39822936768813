import React               from 'react'
import { t }               from 'ttag'
import { string, number }  from 'prop-types'

import typesPresentation  from '../../typesPresentation'
import ColumnHeader       from '../ColumnHeader'
import Input              from '../Input'
import InputRow           from '../InputRow'

const Results = ({ type, number, cpa, impressions, visits }) => {
  return (
    <div>
      <ColumnHeader className="d-flex justify-content-between align-items-baseline">
        { t`Estimated results` }
      </ColumnHeader>

      <div className="mt-2">
        <InputRow label={ typesPresentation[type] }>
          <Input
            disabled
            data-test-id="forecast-result-input"
            aria-label={ `estimated result ${type}` }
            value={ number }
          />
        </InputRow>

        <InputRow label={ t`CPL` }>
          <Input
            disabled
            data-test-id="forecast-result-input"
            aria-label={ 'estimated result CPL' }
            value={ cpa }
          />
        </InputRow>

        <InputRow label={ t`Impressions` }>
          <Input
            disabled
            data-test-id="forecast-result-input"
            aria-label={ 'estimated result impressions' }
            value={ impressions }
          />
        </InputRow>

        <InputRow label={ t`Visits` }>
          <Input
            disabled
            data-test-id="forecast-result-input"
            aria-label={ 'estimated result visits' }
            value={ visits }
          />
        </InputRow>
      </div>
    </div>
  )
}

Results.propTypes = {
  impressions: number.isRequired,
  number: number.isRequired,
  type: string.isRequired,
  visits: number.isRequired,
  cpa: number,
}

Results.defaultProps = {
  cpa: undefined
}

export default Results

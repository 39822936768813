import React    from 'react'
import { func } from 'prop-types'

import landingPageType from 'shared/types/landingPageType'

import { AgentsContainer } from '../../elements'

import Agent from './Agent'

const Agents = ({ className, landingPage, refetchLandingPage }) => {
  const agents = landingPage.agents.map(
    (agent) => (
      <Agent key={ agent.uuid } agent={ agent } refetchLandingPage={ refetchLandingPage } />
    )
  )

  return (
    <AgentsContainer className={ className }>
      { agents }
    </AgentsContainer>
  )
}

Agents.propTypes = {
  landingPage: landingPageType.isRequired,
  refetchLandingPage: func.isRequired
}

export default Agents

import { gql } from 'apollo-boost'

import snapshotFragment from 'graphql/fragments/snapshot'
import snapshotUnitSet from 'graphql/fragments/snapshotUnitSet'

export default gql`
  mutation copySnapshot(
    $fromUuid: ID!
    $toUuid: ID!
  ) {
    copySnapshot(
      fromUuid: $fromUuid
      toUuid: $toUuid
    ) {
      snapshot {
       ... Snapshot
        unitSets {
          ... SnapshotUnitSet
        }
      }
    }
  }
  ${snapshotFragment}
  ${snapshotUnitSet}
`

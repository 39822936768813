import React    from 'react'
import styled   from 'styled-components'
import { Form } from 'react-bootstrap'
import {
  arrayOf,
  string,
  oneOfType,
  shape,
  bool,
  func,
} from 'prop-types'

import Button from 'shared/components/ui/Button'

const ForgotPasswordForm = ({
  handleSubmit,
  className,
  handleChange,
  touched,
  errors,
  isSubmitting
}) => (
  <Form noValidate onSubmit={ handleSubmit } className={ className }>
    <h1>Forgot your password?</h1>

    <p>
      Enter your email below and we will send you a link to reset your password.
    </p>

    <Form.Label>Email</Form.Label>
    <Form.Control
      name="email"
      type="email"
      onChange={ handleChange }
      isInvalid={ touched.email && errors.email }
    />
    <Form.Control.Feedback type="invalid">
      Email { errors.email }
    </Form.Control.Feedback>

    <Button
      type="submit"
      disabled={ isSubmitting }
    >
      Request password reset
    </Button>
  </Form>
)

ForgotPasswordForm.propTypes = {
  className: string.isRequired,
  errors: shape({
    email: oneOfType([arrayOf(string), string]),
  }).isRequired,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired,
  touched: shape({
    email: bool,
  }).isRequired
}

export default styled(ForgotPasswordForm)`
  h1 {
    margin-bottom: 1rem
  }
  
  .form-label {
    margin-top: .75rem
  }

  ${Button} {
    width: 13.375rem
    margin-top: .875rem
  }
`

import React                    from 'react'
import { groupBy, sortBy }      from 'lodash'
import { bool, array, shape, string, func, number } from 'prop-types'
import { useApolloClient }      from 'react-apollo'
import { Alert } from  'react-bootstrap'
import styled from 'styled-components'

import Documents from 'shared/components/Documents'

import MediaTypeAssets from './MediaTypeAssets'

const AssetAssignments = ({
  assetable,
  creativeAssignments,
  mediaTypes,
  createRemoveAssetAssignment,
  removedAssetAssignment,
  attachFileToAssetable,
  createCreateAssetAssignment,
  className,
  selectAssetAssignment,
  enabledCreativesLimit,
  enabledAssets,
  channelType,
  draggable,
  onDrop,
  formats,
  ...props
}) => {
  const client           = useApolloClient()
  const assetAssignments = creativeAssignments ? creativeAssignments : assetable.assetAssignments
  const assetableType    = assetable.__typename
  const assetableUuid    = assetable.uuid
  const groupedAssets    = groupBy(assetAssignments, 'mediaType')

  const removeAssetAssignment = createRemoveAssetAssignment(client, assetableUuid, assetAssignments)
  const createAssetAssignment = createCreateAssetAssignment(client, assetableUuid)
  const enabledAssetsCount = enabledAssets.length
  const enabledCreativesAllowance = enabledCreativesLimit - enabledAssetsCount

  const handleOnAssetClick = (client, creativeUuid, enabled) => {
    if(enabled && (enabledCreativesAllowance < 1)) { return }

    selectAssetAssignment(client, creativeUuid, enabled)
  }

  // this is to force re-render whenever the number of enabled assets changes
  const composeKey = (mediaType) => (mediaType + enabledAssetsCount)
  const enabledCreativesLimitReached = enabledCreativesAllowance < 1
  const sortedAssetAssignments = (mediaType) => {
    return sortBy(groupedAssets[mediaType] || [], a => a.portalPositionDraft)
  }

  return (
    <div>
      { enabledCreativesLimitReached && <Alert variant='info'>
          The limit of { enabledCreativesLimit } enabled creatives
          for this type of Creative Set has been reached.
      </Alert>
      }
      <div className={ className }>
        <div className="medias">
          { mediaTypes.map((mediaType) => (
            <MediaTypeAssets
              draggable={ draggable }
              key={ composeKey(mediaType) }
              client={ client }
              onAssetAssignmentRemove={ removeAssetAssignment }
              removedAssetAssignment={ removedAssetAssignment }
              onAssetAssignmentCreate={ createAssetAssignment }
              onAssetCreate={ attachFileToAssetable }
              onAssetDrop={ onDrop }
              assetAssignments={ sortedAssetAssignments(mediaType) }
              assetableType={ assetableType }
              mediaType={ mediaType }
              onAssetClick={ selectAssetAssignment && handleOnAssetClick }
              channelType={ channelType }
              formats={ formats }
            />
          )) }
        </div>
        {props.showings !== undefined && (
          <Documents { ...props } />
        )}
      </div>
    </div>
  )
}

AssetAssignments.propTypes = {
  assetable: shape({
    assetAssignments: array.isRequired,
    uuid: string.isRequired
  }).isRequired,
  attachFileToAssetable: func.isRequired,
  createCreateAssetAssignment: func.isRequired,
  createRemoveAssetAssignment: func.isRequired,
  mediaTypes: array.isRequired,
  channelType: string,
  creativeAssignments: array,
  draggable: bool,
  enabledAssets: array,
  enabledCreativesLimit: number,
  formats: array,
  onDrop: func,
  removedAssetAssignment: func,
  selectAssetAssignment: func,
  showings: array
}

AssetAssignments.defaultProps = {
  channelType: undefined,
  creativeAssignments: undefined,
  draggable: false,
  enabledAssets: [],
  enabledCreativesLimit: 1000,
  formats: [],
  onDrop: () => {  },
  removedAssetAssignment: undefined,
  selectAssetAssignment: undefined,
  showings: undefined
}

export default styled(AssetAssignments)`
  display: flex;
  flex-direction: row;

  .medias {
    padding-right: 1rem;
    flex: 7;
  }
`

import React from 'react'
import { t } from 'ttag'
import AsyncSelect from 'react-select/async'
import { useApolloClient } from 'react-apollo'
import { arrayOf, string, shape } from 'prop-types'
import styled from 'styled-components'

import campaignType from 'shared/types/campaignType'
import MultiSelect from 'shared/components/ui/MultiSelect/MultiSelect'
import query from 'graphql/queries/googleTargetingItems'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

import { ErrorMessage } from './elements'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1
  })
}

const valueToOption = el => ({
  ...el,
  label: el.name,
  value: el.uuid
})

const buildOptions = data => {
  const options = data.map(valueToOption)

  const groups = Array.from(new Set(options.map(el => el.subtype)))

  return groups.map(subtype => ({
    label: subtype,
    options: options.filter(el => el.subtype === subtype)
  }))
}

const DetailedTargetingSelect = ({ className, campaign, value: selectedValues, ...props }) => {
  const client = useApolloClient()

  const value = selectedValues?.map(valueToOption)

  const loadOptions = async term => {
    const {
      data: { googleTargetingItems }
    } = await client.query({
      query: query,
      variables: { query: term, campaignUuid: campaign.uuid }
    })

    return buildOptions(googleTargetingItems)
  }

  return (
    <div className={ className }>
      <MultiSelect
        component={ AsyncSelect }
        value={ value }
        loadOptions={ loadOptions }
        styles={ styles }
        disabled={ campaign.advertiserId === null }
        { ...props }
      />
      {campaign.advertiserId === null && (
        <ErrorMessage>
          {t`Targeting search is not available. Please contact support.`}
        </ErrorMessage>
      )}
    </div>
  )
}

DetailedTargetingSelect.propTypes = {
  campaign: campaignType.isRequired,
  value: arrayOf(shape({
    name: string,
    uuid: string,
    subtype: string
  })).isRequired
}

export default styled(DetailedTargetingSelect)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

import React from 'react'
import { arrayOf, number, bool, shape, object, string } from 'prop-types'

import Table from 'shared/components/ui/Table'

import useAdsTable from './useAdsTable'

const AdsTable = ({ ads, campaign }) => {
  const { tableOptions, paginationOptions } = useAdsTable(ads, campaign)

  return (
    <Table
      tableOptions={ tableOptions }
      paginationOptions={ paginationOptions }
    />
  )
}

AdsTable.propTypes = {
  campaign: object.isRequired,
  ads: arrayOf(shape({
    enabled: bool,
    asset: shape({
      thumbnail: string
    }),
    summary: shape({
      totalImpressions: number,
      totalClicks: number,
      averageCpl: number,
      leadsCount: number
    })
  }))
}

AdsTable.defaultProps = {
  ads: []
}

export default AdsTable

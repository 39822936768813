import React from 'react'
import { t } from 'ttag'
import { node } from 'prop-types'
import styled from 'styled-components'

import Header from 'shared/components/ui/Card/StaticHeader'
import Card from 'shared/components/ui/Card'

const AnalyticsCard = ({ className, children }) => {
  return (
    <Card collapsible className={ `${className} mb-2` } dataTestId="analytics-panel"
      header={ (
        <div className="d-flex">
          <Header className="align-self-center">
            { t`Analytics` }
          </Header>
        </div>
      ) }
    >
      { children }
    </Card>
  )
}

AnalyticsCard.propTypes = {
  children: node.isRequired,
}

export default styled(AnalyticsCard)`
  &.empty {
    text-align: center
  }
`

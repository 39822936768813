import React from 'react'
import styled from 'styled-components'

import Showings from './Showings'

const Documents = ({ className, ...props }) => {
  return (
    <div className={ className }>
      <Showings { ...props } />
    </div>
  )
}

export default styled(Documents)`
  flex: 2;
  padding: 0.75rem 2rem;
  border-left: 2px solid #e7e7e7;

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #4a4a4a;
    opacity: 0.5;
    margin-bottom: 1.25rem;
  }
`

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { string, number } from 'prop-types'

import ShadowDefinition from './ShadowDefinition'
import { buildArc } from './utils'

const CircularGauge = ({ className, value, postfix }) => {
  const containerRef = React.createRef()
  const svgRef = React.createRef()

  useEffect(() => {
    const { width, height } = containerRef.current.getBoundingClientRect()
    const minSize = Math.min(width, height)

    svgRef.current.setAttribute('height', `${minSize}px`)
    svgRef.current.setAttribute('width', `${minSize}px`)
    svgRef.current.style.opacity = 1
  }, [containerRef, svgRef])

  const gaugeSize = 300
  const gaugeRadius = 100
  const gaugePercentage = Math.max(0, Math.min(360, 360 / 100 * value))
  const drawArc = gaugePercentage < 360
  const center = gaugeSize / 2
  const arc = buildArc(center, center, gaugeRadius, 0, gaugePercentage)
  const displayValue = postfix === '%' ? Math.round(value) : value

  return (
    <div className={ className } ref={ containerRef }>
      <svg
        ref={ svgRef }
        viewBox={ `0 0 ${gaugeSize} ${gaugeSize}` }
        height="6.25rem" width="6.25rem"
        style={{ opacity: 0 }}
      >
        <ShadowDefinition />

        <circle
          cx={ gaugeSize / 2 }
          cy={ gaugeSize / 2 }
          r={ gaugeRadius }
          fill="none"
          stroke="#d9dae2"
          strokeWidth="15"
        />

        { drawArc ? (
          <path
            d={ arc }
            fill="none"
            stroke="#47b881"
            strokeWidth="15"
            filter="url(#drop-shadow)"
          />
        ) : (
          <circle
            cx={ gaugeSize / 2 }
            cy={ gaugeSize / 2 }
            r={ gaugeRadius }
            fill="none"
            stroke="#47b881"
            strokeWidth="15"
            filter="url(#drop-shadow)"
          />
        )}

        { value && (
          <text
            x={ center }
            y={ center }
            fontSize="55"
            fontWeight="500"
            fill="#47b881"
            textAnchor="middle"
            dominantBaseline="central"
          >
            {displayValue}{postfix}
          </text>
        )}
      </svg>
    </div>
  )
}

CircularGauge.propTypes = {
  className: string.isRequired,
  postfix: string,
  value: number
}

CircularGauge.defaultProps = {
  value: 0,
  postfix: '%'
}

export default styled(CircularGauge)`
  height: 100%;
  width: 100%;

  svg {
    transition: opacity 0.2s ease-in-out;
  }
`

import React from 'react'
import { string, number, func } from 'prop-types'
import styled from 'styled-components'
import { t } from 'ttag'
import ReactPaginate from 'react-paginate'

import { buttonTextColor } from 'shared/style/colors'
import { buttonStyle } from 'shared/components/ui/Button'

const Pagination = ({ className, page, pageCount, onPageChange }) => {
  if (pageCount < 2) return null

  const isFirstPage = !pageCount || page === 1
  const isLastPage = !pageCount || page === pageCount

  return (
    <div className={ `pagination-row ${className}` }>
      <ReactPaginate
        forcePage={ page - 1 }
        pageCount={ pageCount }
        onPageChange={ onPageChange }
        previousLabel={ t`Previous page` }
        nextLabel={ t`Next page` }
        pageClassName={ 'button' }
        nextClassName={ `button ${isLastPage && 'd-none'}` }
        previousClassName={ `button ${isFirstPage && 'd-none'}` }
        marginPagesDisplayed={ 1 }
        pageRangeDisplayed={ 3 }
        containerClassName={ 'pagination' }
        activeClassName={ 'active' }
      />
    </div>
  )
}

Pagination.propTypes = {
  className: string.isRequired,
  onPageChange: func.isRequired,
  page: number.isRequired,
  pageCount: number.isRequired
}

export default styled(Pagination)`
  display: flex;
  justify-content: flex-end;

  ul li {
    margin-left: .5rem;
    
    &.button {
      cursor: pointer;
      
      & a {
        ${buttonStyle};
        color: ${buttonTextColor};
        text-decoration: none;
        
        &:focus {
          outline: none  
        }
      }
      
      &.active a {
        box-shadow: 0 0 0.25rem 0 #3c3c3c;
        
        &:focus, &:hover {
          box-shadow: 0 0 0.25rem 0 #3c3c3c;
        }
      }
    }
  }
`

import React from 'react'
import { bool, func, shape, string, number, oneOfType } from 'prop-types'

import Modal from 'shared/components/ui/Modal'

import { Header } from './elements'

import UserForm from './index'

const UserModal = ({ user, title, show, onHide, onSubmit, companyUuid }) => {
  const handleSubmit = async values => {
    await onSubmit(user, values)
    onHide()
  }

  return (
    <Modal centered show={ show } onHide={ onHide }>
      <Modal.Header closeButton>
        <Header>{ title }</Header>
      </Modal.Header>

      <UserForm
        companyUuid={ companyUuid }
        user={ user }
        onSubmit={ handleSubmit }
        onHide={ onHide }
      />
    </Modal>
  )
}

UserModal.propTypes = {
  onSubmit: func.isRequired,
  show: bool.isRequired,
  title: string.isRequired,
  companyUuid: string,
  onHide: func,
  user: shape({
    name: string.isRequired,
    phoneNumber: shape({
      code: oneOfType([string, number]),
      number: oneOfType([string, number])
    }),
    email: string
  })
}

UserModal.defaultProps = {
  companyUuid: null,
  user: {
    name: '',
    email: '',
    phoneNumber: {}
  },
  onHide: () => {}
}

export default UserModal

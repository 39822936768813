import gql from 'graphql-tag'

import stageUnit from 'graphql/fragments/stageUnit'

export default gql`
  fragment StageSalesArgument on StageSalesArgument {
    uuid
    createdAt
    text
    appliesTo
    units {
      ... StageUnit
    }
  }
  ${stageUnit}
`

import React  from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { arrayOf, string, func, shape, number } from 'prop-types'

import Slider from 'shared/components/ui/Slider'
import { bluishColor, buttonBorderColor } from 'shared/style/colors'

const BannersSlider = ({ usedBanners, className, previewBanner, setPreviewBanner }) => {
  const slidesToShow = 4
  const infinite = usedBanners.length > slidesToShow

  const settings = {
    infinite,
    slidesToShow,
    speed: 500,
    slidesToScroll: slidesToShow
  }

  const selectedBannerUuid = previewBanner?.uuid || usedBanners[0]?.uuid

  return (
    <Slider { ...settings } className={ className }>
      { usedBanners.map((banner) => {
        const buttonClassName = classNames(
          'banner-wrapper',
          { 'is-selected': banner.uuid === selectedBannerUuid }
        )
        const formatName = `${banner.format.width}x${banner.format.height}`

        return (
          <button
            key={ banner.uuid }
            className={ buttonClassName }
            onClick={ () => setPreviewBanner(banner) }
            type="button"
          >
            <div className="h-100 d-flex justify-content-center align-items-center">
              <div>
                {formatName}
              </div>
            </div>
          </button>
        )
      }) }
    </Slider>
  )
}

BannersSlider.propTypes = {
  setPreviewBanner: func.isRequired,
  usedBanners: arrayOf(shape({
    uuid: string,
    height: number,
    width: number,
  })).isRequired,
  previewBanner: shape({
    uuid: string
  })
}

BannersSlider.defaultProps = {
  previewBanner: null
}

export default styled(BannersSlider)`
  .banner-wrapper {
    border: 1px solid ${buttonBorderColor};
    border-radius: .25rem;
    color: ${buttonBorderColor};
    font-size: 0.625rem;
    height: 2rem;
    line-height: 1;
    width: 70px;

    &.is-selected {
      color: ${bluishColor}
      border-color: ${bluishColor}
    }
  }
`

import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'

import Button from 'shared/components/ui/Button'

const PreviewAllButton = (props) => {
  return (
    <Button { ...props }>
      { t`Preview all ads in this set` }
    </Button>
  )
}

PreviewAllButton.propTypes = {
}

export default styled(PreviewAllButton)`
  display: block
  font-size: 0.875rem
  margin-top: 1rem
  text-align: center
  width: 100%
`

import React from 'react'
import { bool, func } from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import { t } from 'ttag'

const ConfirmModal = props => {
  const {
    show,
    handleHide,
    handleCancel,
    handleProceed
  } = props

  return (
    <Modal centered show={ show } onHide={ handleHide }>
      <Modal.Body>
        {
          t`For the best campaign content and performance, please fill out the missing details.
          Proceeding without completion will create a campaign without generated content.`
        }
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={ handleCancel }>
          { t`Go back` }
        </Button>
        <Button variant='primary' onClick={ handleProceed }>
          { t`Proceed` }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  handleCancel: func.isRequired,
  handleHide: func.isRequired,
  handleProceed: func.isRequired,
  show: bool.isRequired
}

export default ConfirmModal

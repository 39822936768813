import React  from 'react'
import { array, func, object, shape, number } from 'prop-types'
import { t }  from 'ttag'
import styled from 'styled-components'

import ItemsList from 'shared/components/ItemsList'
import creativeSetAudienceFormatter
  from 'shared/components/CampaignEdit/AdsAudiences/Ads/creativeSetAudienceFormatter'
import creativeSetAudienceSortFunc
  from 'shared/components/CampaignEdit/AdsAudiences/Ads/creativeSetAudienceSortFunc'
import adPreviewFormatter
  from 'shared/components/CampaignEdit/AdsAudiences/Ads/adPreviewFormatter'
import numberFormatter
  from 'shared/components/CampaignEdit/AdsAudiences/Ads/numberFormatter'

const columns = [{
  dataField: 'uuid',
  hidden: true
}, {
  dataField: 'asset',
  text: t`Preview`,
  formatter: adPreviewFormatter
}, {
  text: t`Project`,
  dataField: 'project.name'
}, {
  text: t`Campaign`,
  dataField: 'campaign.name',
  sort: true
}, {
  text: t`Creative set/audience`,
  dataField: 'creativeSetAudience',
  formatter: creativeSetAudienceFormatter,
  sortFunc: creativeSetAudienceSortFunc,
  sort: true
}, {
  dataField: 'summary.totalImpressions',
  text: t`Impressions`,
  formatter: numberFormatter
}, {
  dataField: 'summary.totalClicks',
  text: t`Clicks`,
  formatter: numberFormatter
}, {
  dataField: 'summary.averageCpl',
  text: t`CPL`,
  formatter: numberFormatter
}, {
  dataField: 'summary.leadsCount',
  text: t`Leads`,
  formatter: numberFormatter
}]

const List = ({ data, params, onTableChange, perPage, ...props }) => {
  return (
    <Container { ...props }>
      <ItemsList
        hasSearchEnabled={ false }
        params={ params }
        setUrlParams={ onTableChange }
        totalCount={ data.total }
        perPage={ perPage }
        items={ data.items }
        columns={ columns }
      />
    </Container>
  )
}

List.propTypes = {
  data: shape({
    total: number.isRequired,
    items: array.isRequired
  }).isRequired,
  onTableChange: func.isRequired,
  params: object.isRequired,
  perPage: number.isRequired,
}

const Container = styled.div`
  margin-top: -1.1rem;
  margin-left: -1.45rem;
  margin-right: -1.45rem;
  margin-bottom: -3.2rem
`

export default List

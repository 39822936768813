import React, { useState, useEffect } from 'react'
import { bool, object, func } from 'prop-types'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { t } from 'ttag'

import CampaignPublicationStatus from 'shared/components/CampaignPublication'
import Card from 'shared/components/ui/Card'
import Button from 'shared/components/ui/Button'
import { bluishColor } from 'shared/style/colors'
import { userRolePath } from 'routes/AppRedirect'
import { isUserAdmin }  from 'shared/helpers/auth'

const CampaignPublications = ({
  campaign,
  validationMode,
  onCampaignValidate,
  className
}) => {
  const [publishingMode, setPublishingMode] = useState(false)
  const [publishingFailed, setPublishingFailed] = useState(false)

  useEffect(() => (setPublishingMode(!!campaign?.publishStartedAt)), [campaign])

  useEffect(() => (setPublishingFailed(!!campaign?.publishFailedAt)), [campaign])

  if (!isUserAdmin) {
    return null
  }

  const handlePublish = async () => {
    onCampaignValidate()
  }

  const publishingPopover = (
    <Popover>
      <Popover.Content>
        { t`The campaign is being published. Please wait until the publishing is finished.` }
      </Popover.Content>
    </Popover>
  )

  function withPublishingTooltip(children) {
    if (!publishingMode) {
      return children
    }

    return (
      <OverlayTrigger overlay={ publishingPopover }>
        <div>{ children }</div>
      </OverlayTrigger>
    )
  }

  return (
    <Card
      collapsible
      className={ `mb-2 ${className}` }
      header={ t`Campaign publications` }
      dataTestId="main-stats-panel"
    >

      <div className="publication-body">
        <CampaignPublicationStatus campaign={ campaign }/>
      </div>

      <div className="d-flex justify-content-end align-items-center pt-3 pr-3 pl-3">
        { publishingFailed && (
          <div className='d-flex flex-grow-1'>
            { t`Campaign publication failed, please contact support@marketer.tech` }
          </div> )}
        <Link
          className="btn btn-default"
          to={ userRolePath(`/campaigns/${campaign.uuid}/change_logs`) }>
          { t`Changelog` }
        </Link>
        <Button
          data-test-id='publish-changesets'
          variant='primary'
          onClick={ handlePublish }
          disabled={ publishingMode || validationMode }>
          { withPublishingTooltip(
            <>
              <span>{ t`Publish` }</span>
              { publishingMode && (
                <div className='pl-2 d-inline' data-test-id='publishing-spinner'>
                  <Spinner animation="border"/>
                </div>
              )}
            </>
          )}
        </Button>        
      </div>
    </Card>
  )
}

CampaignPublications.propTypes = {
  campaign: object.isRequired,
  onCampaignValidate: func.isRequired,
  validationMode: bool,
}

CampaignPublications.defaultProps = {
  validationMode: false
}

export default styled(CampaignPublications)`
  transition: all 0.5s ease

  tr {
    .error-message {
      display: none
    }

    &.validation-error {
      .error-message {
        display: block
        color: red
      }
    }
  }

  td {
    vertical-align: top
  }

  code {
    color: ${bluishColor}
    i {
      text-transform: capitalize
    }
  }

  .thumbnail {
    position: relative
    z-index: 150
  }

  .thumbnail:hover {
    background-color: transparent
    z-index: 200
  }

  .thumbnail > img:first-of-type {
    height: 1.1rem
    cursor: zoom-in
    border: 1px solid grey
    border-radius: 0.15rem
    margin: 0 0.2rem
  }

  .thumbnail > img:nth-of-type(2) {
    visibility: hidden
    position: fixed
    top: 0.5rem
    left: 0
    right: 0
    margin: auto
    border: 2px solid grey
    border-radius: 0.25rem
  }

  .thumbnail:hover > img:nth-of-type(2) {
    visibility: visible
  }

  .spinner-border {
    width: 1.25rem;
    height: 1.25rem;
  }

  .delete-button.disabled {
    cursor: default;
  }
`

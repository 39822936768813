import React from 'react'
import styled from 'styled-components'
import { bool } from 'prop-types'

import productType from 'shared/types/productType'
import Media from 'shared/components/ProductDetails/StageDetails/Media'

const StagesMedia = ({ className, product, readOnly }) => {
  return (
    <div className={ className }>
      <div className="medias">
        <Media product={ product } readOnly={ readOnly } />
      </div>
    </div>
  )
}

StagesMedia.propTypes = {
  product: productType.isRequired,
  readOnly: bool
}

StagesMedia.defaultProps = {
  readOnly: false
}

export default styled(StagesMedia)`
  padding: 1rem 1rem 0 3rem;
  display: flex;
  flex-direction: row;

  .medias {
    padding-right: 1rem;
    flex: 7;
  }
`

import React, { useState, useEffect }  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'
import { useApolloClient } from '@apollo/react-hooks'
import { func, bool, object } from 'prop-types'

import Modal from 'shared/components/ui/Modal'
import Media from 'shared/components/Media'

import unitAssetAssignmentsQuery from './unitAssetAssignmentsQuery'
import attachFileToUnit          from './attachFileToUnit'
import removeAssetAssignment     from './removeAssetAssignment'
import createAssetAssignment     from './createAssetAssignment'

const EditUnitFloorplansModal = ({ unit, show, onHide }) => {
  const client = useApolloClient()
  const [assetAssignments, setAssetAssignments] = useState([])
  const [deletedAssetAssignments, setDeletedAssetAssignments] = useState([])

  useEffect(() => {
    async function fetchData() {
      const response = await loadAssetAssignments()
      setAssetAssignments(response.data.unitAssetAssignments)
    }
    fetchData()
  }, [unit.uuid])

  const loadAssetAssignments = async () => {
    const data = await client.query({
      query: unitAssetAssignmentsQuery,
      variables: { unitUuid: unit.uuid },
      fetchPolicy: 'no-cache'
    })
    return data
  }

  const attachFileToAssetable = ({ client, blobId, mediaType }) => {
    async function attachFile() {
      const response = await attachFileToUnit({ client, blobId, mediaType, unitUuid: unit.uuid })

      setAssetAssignments(
        [...assetAssignments, response.data.attachFileToAssetable.assetAssignment]
      )
    }
    attachFile()
  }

  const removedAssetAssignment = (assetAssignmentId) => {
    const newArray = deletedAssetAssignments
    newArray.push(assetAssignmentId)
    setDeletedAssetAssignments(
      [...deletedAssetAssignments, assetAssignmentId]
    )
  }

  const unitWithAssignments = (assetAssignmentElements, deletedUuids) => {
    const notDeletedAssetAssignments = assetAssignmentElements.filter((element) => {
      return !deletedUuids.includes(element.uuid)
    })

    return Object.assign(unit, {
      assetAssignments: notDeletedAssetAssignments
    })
  }

  return (
    <Modal centered show={ show } onHide={ onHide }>
      <Modal.Header closeButton>
        <Header>{ t`Upload unit floorplans` }</Header>
      </Modal.Header>

      <Modal.Body>
        <Media
          availableAssets={ [] }
          assetAssignable={ unitWithAssignments(assetAssignments, deletedAssetAssignments) }
          mediaSourceType={ 'StageUnit' }
          attachFileToAssetable={ attachFileToAssetable }
          createRemoveAssetAssignment={ removeAssetAssignment }
          createCreateAssetAssignment={ createAssetAssignment }
          removedAssetAssignment={ removedAssetAssignment }
        />
      </Modal.Body>
    </Modal>
  )
}

const Header = styled.h3`
  margin: 0;
  font-size: 1.25rem;
`

EditUnitFloorplansModal.propTypes = {
  onHide: func.isRequired,
  show: bool.isRequired,
  unit: object.isRequired
}

export default EditUnitFloorplansModal

import React from 'react'
import { t } from 'ttag'
import PropTypes from 'prop-types'

import Card from 'shared/components/ui/Card'

import OtherInformation from './OtherInformation'
import ProjectStatus from './ProjectStatus'
import ProjectDetails from './ProjectDetails'
import ProjectLocation from './ProjectLocation'
import ProjectStatusInformation from './ProjectStatusInformation'
import Organizations from './ProjectOrganizations'
import ProjectStakeholders from './ProjectStakeholders'
import ProjectPortalPublication from './ProjectPortalPublication'
import Agents from './Agents'

const ProjectInformation = ({ project }) => {
  return (
    <Card
      collapsible
      collapsed
      header={ t`Project information` }
      padded={ false }
    >
      <ProjectDetails data-test-id='project-details' project={ project } />
      <ProjectLocation data-test-id='project-location' project={ project } />
      <ProjectStatus data-test-id='project-status' project={ project } />
      <ProjectStatusInformation data-test-id='project-status-information' project={ project } />
      <Organizations data-test-id='organizations' project={ project } />
      <Agents data-test-id='agents' project={ project }/>
      <ProjectStakeholders data-test-id='project-stakeholders' project={ project } />
      <OtherInformation data-test-id='other-information' project={ project } />
      <ProjectPortalPublication data-test-id='project-portal-publication' project={ project } />
    </Card>
  )
}

ProjectInformation.propTypes = {
  project: PropTypes.object.isRequired
}

export default ProjectInformation

import React  from 'react'
import styled from 'styled-components'
import { string, object, array, func } from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks'

import { buttonTextColor } from 'shared/style/colors'

import LeftColumn     from './LeftColumn'
import RightColumn    from './RightColumn'
import updateBuilding from './updateBuilding'

const Details = ({ className, building, errors, validate }) => {
  const client = useApolloClient()

  const handleUpdate = (input, location) => updateBuilding(client, building, input, location).
    then(({ data: { updateBuilding: { building } } }) => {
      if (!validate) { return }
      validate({ updated: building })
    })

  return (
    <div className={ className }>
      <LeftColumn building={ building } onUpdate={ handleUpdate } errors={ errors } />
      <RightColumn building={ building } onUpdate={ handleUpdate } errors={ errors } />
    </div>
  )
}

Details.propTypes = {
  building: object.isRequired,
  className: string.isRequired,
  errors: array,
  validate: func
}

Details.defaultProps = {
  errors: [],
  validate: undefined
}

export default styled(Details)`
  display: flex;
  width: 100%;

  .group {
    display: flex;
    margin-bottom: 0.75rem;
  }

  .group > label {
    color: ${buttonTextColor};
    display: flex;
    font-size: 0.875rem;
    line-height: 2rem;
    flex-direction: column;
    flex-shrink: 0;
    width: 12.5rem;
    margin: 0;

    i {
      font-size: 0.75rem;
      line-height: 1rem;
      width: 8.5rem;
    }
  }

  .form-control {
    box-shadow: none;
    height: 2rem;
  }
`

import React from 'react'

import LoginForm from 'shared/components/LoginForm'

const Login = () => {
  return (
    <LoginForm userType="admin" />
  )
}

export default Login

import React from 'react'
import { arrayOf, bool } from 'prop-types'
import styled from 'styled-components'

import Card from 'shared/components/ui/Card'
import Header from 'shared/components/ui/Card/StaticHeader'
import ResourceQuery from 'shared/components/ResourceQuery'
import StageDetails from 'shared/components/ProductDetails/StageDetails'
import MissingDataIndicator from 'shared/components/Indicators/MissingData'
import { projectMissingDataPresentation }
  from 'shared/components/Indicators/MissingData/Presentations'
import stageType from 'shared/types/stageType'
import stageQuery from 'graphql/queries/stageWithSnapshots'

const Stages = ({ stages, collapsed, ...props }) => {
  return (
    <>
      {
        stages.map((stage) => (
          <Card
            collapsible
            className='mb-2 stage-card'
            collapsed={ collapsed }
            key={ stage.uuid }
            dataTestId={ `stage-${stage.uuid}-panel` }
            header={ (
              <div className="d-flex justify-content-between">
                <Header className="align-self-center">
                  { stage.name }
                  <MissingDataIndicator
                    missingData={{
                      data: stage.missingData,
                      presentation: projectMissingDataPresentation
                    }}
                  />
                </Header>
              </div>
            ) }
          >
            <ResourceQuery
              query={ stageQuery }
              variables={{ uuid: stage.uuid }}
              fetchPolicy='cache-first'
            >
              {stage => (
                <StageDetails product={ stage.product } { ...props } />
              )}
            </ResourceQuery>
          </Card>
        ))
      }
    </>
  )
}

Stages.propTypes = {
  collapsed: bool,
  stages: arrayOf(stageType)
}

Stages.defaultProps = {
  collapsed: false,
  stages: []
}

export default styled(Stages)`
  .missing-data-indicator {
    margin: 0
  }
`

import React     from 'react'
import styled    from 'styled-components'
import { t }     from 'ttag'
import { Field } from 'formik'
import { array, func, number, string, shape } from 'prop-types'

import DeleteButton from 'shared/components/ui/DeleteButton'
import FormikThriftyInput from 'shared/components/ui/FormikThriftyInput'
import UnitsSelect from 'shared/components/UnitsSelect'
import FieldErrorMessage         from 'shared/components/ui/FieldErrorMessage'

import ArgumentApplicationSelect from './ArgumentApplicationSelect'

const Item = ({ className, idx, stageUuid, argument, handleUpdate, handleDelete }) => {
  const { appliesTo, units } = argument
  const unitUuids = units.map(unit => unit.uuid)

  const handleUpdateText = (text) => {
    handleUpdate(idx, { text })
  }

  const handleAppliesToChange = ({ value }) => {
    const input = { appliesTo: value }

    if (value !== 'specific') input.unitUuids = []

    handleUpdate(idx, input)
  }

  const handleUnitsChange = unitUuids => handleUpdate(idx, { unitUuids })

  const handleDeleteClick = () => {
    if (confirm(t`Are you sure you want to remove sales argument?`)) {
      handleDelete(idx)
    }
  }

  return (
    <li className={ className }>
      <div className="item-index">{ idx + 1 }</div>

      <div data-test-id='sales-argument-input-wrapper' className="input-wrapper">
        <FieldErrorMessage name={ `salesArguments.${idx}.text` }>
          <Field
            name={ `salesArguments.${idx}.text` }
            as={ FormikThriftyInput }
            onChange={ handleUpdateText }
          />
        </FieldErrorMessage>
      </div>

      <ArgumentApplicationSelect
        value={ appliesTo }
        onChange={ handleAppliesToChange }/>

      <UnitsSelect
        stageUuid={ stageUuid }
        value={ unitUuids }
        isDisabled={ appliesTo !== 'specific' }
        onChange={ handleUnitsChange } />

      <DeleteButton onClick={ handleDeleteClick } />
    </li>
  )
}

Item.propTypes = {
  argument: shape({
    uuid: string.isRequired,
    text: string,
    appliesTo: string.isRequired,
    units: array.isRequired
  }).isRequired,
  className: string.isRequired,
  handleDelete: func.isRequired,
  handleUpdate: func.isRequired,
  idx: number.isRequired,
  stageUuid: string.isRequired,
}

export default styled(Item)`
  display: flex;
  padding: 0.325rem 0;
  align-items: end;

  > * {
    margin-right: 0.5rem;
  }

  .item-index {
    font-size: 0.875rem;
    margin-left: .5rem;
    line-height: 2rem;
    text-align: center;
    width: 2rem;
  }

  .form-control {
    box-shadow: none;
    max-width: 37rem;
    font-size: 0.75rem;
    height: 2rem;
  }

  > button {
    margin-left: auto;
  }

  .input-wrapper {
    width: 100%
  }

  .input-wrapper.with-error {
    position: relative
    top: 0.73rem
  }
`

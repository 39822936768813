import { gql } from 'apollo-boost'

import project from 'graphql/fragments/project'
import assetAssignment from 'graphql/fragments/assetAssignment'
import asset from 'graphql/fragments/asset'
import agent from 'graphql/fragments/agent'
import snapshot from 'graphql/fragments/snapshot'

export default gql`
  query Project (
    $uuid: ID!
  ) {
    project(uuid: $uuid) {
      ... Project
      snapshots {
        ... Snapshot
      }
      assets {
      ... Asset
      }
      originalAssetAssignments {
        ... AssetAssignment
      }
      assetAssignments {
        ... AssetAssignment
      }
      location {
        city
      }
      currency
      product {
        uuid
        company {
          uuid
          name
          enabledFeatures
        }
        stakeholders {
          uuid
          responsibility
          permission
          user {
            uuid
            nameWithEmail
            synced
          }
          leadNotification
          weeklyReport
        }
      }
      completeStages {
        uuid
        name
        missingData
        snapshots {
          ... Snapshot
        }
      }
      companies
      completeBuildings {
        uuid
        name
      }
      agents {
        ... Agent
      }
    }
  }
  ${project}
  ${assetAssignment}
  ${asset}
  ${agent}
  ${snapshot}
`

import { useQuery } from '@apollo/react-hooks'
import { object }   from 'prop-types'
import moment       from 'moment'

import campaignCalculatedDatesQuery from './campaignCalculatedDatesQuery'

const useInitialValues = (property, formValues) => {
  const { data, loading } = useQuery(campaignCalculatedDatesQuery, {
    variables: {
      propertyUuid: property.uuid
    }
  })

  if (loading) {
    return {}
  }

  const startDate = moment(data.campaignCalculatedDates.startDate).toDate()
  const endDate = moment(data.campaignCalculatedDates.endDate).toDate()

  return Object.assign({
    startDate: startDate,
    endDate: endDate
  }, formValues)
}

useInitialValues.propTypes = {
  property: object.isRequired,
  formValues: object
}

useInitialValues.defaultProps = {
  formValues: {}
}

export default useInitialValues

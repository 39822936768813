import React from 'react'
import { bool, string } from 'prop-types'

const ColorOption = ({ isSelected, color, name }) => {
  return (
    <div className={ `color-option ${isSelected && 'is-selected'}` } data-test-id="color-option" >
      <div className="color-square" style={{ backgroundColor: color }} />
      <span className="color-name">
        { name }
      </span>
    </div>
  )
}

ColorOption.propTypes = {
  color: string.isRequired,
  name: string.isRequired,
  isSelected: bool
}

ColorOption.defaultProps = {
  isSelected: false
}

export default ColorOption

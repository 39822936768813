import React             from 'react'
import { arrayOf, func } from 'prop-types'

import useGallery from 'shared/hooks/useGallery'
import assetType  from 'shared/types/assetType'

import Lightbox from './Lightbox'

const BaseGallery = ({ assets, children }) => {
  const [controls, state] = useGallery(assets)

  return (
    <>
      { children({ controls }) }

      { state.isOpened && (
        <Lightbox
          state={ state }
          controls={ controls }
        />
      ) }
    </>
  )
}

BaseGallery.propTypes = {
  assets: arrayOf(assetType).isRequired,
  children: func.isRequired
}

export default BaseGallery

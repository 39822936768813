import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { Form } from 'react-bootstrap'
import { func, object } from 'prop-types'
import { useDrop } from 'react-dnd'
import { useApolloClient } from 'react-apollo'

import EmptyAsset from 'shared/components/Media/AssetAssignments/EmptyAsset'
import SelectedAsset from 'shared/components/Media/AssetAssignments/SelectedAsset'
import Card from 'shared/components/ui/Card'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import WysiwygEditor from 'shared/components/ui/WysiwygEditor'
import Switch from 'shared/components/ui/Switch'
import landingPageType from 'shared/types/landingPageType'
import { mediaTypeDropEvents } from 'shared/components/Media/mediaTypes'
import { combineErrors, createValidatableActionHandler } from 'shared/helpers/inputValidation'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import buildValidator from 'shared/components/CampaignInfo/validations/buildValidator'

import {
  FormContainer,
  FinishButton,
  Hr,
  SectionHeader,
  DescriptionContainer,
  DescriptionAssetWrapper
} from '../elements'

import HeaderImageAndLogo from './HeaderImageAndLogo'
import GalleryImages from './GalleryImages'
import SalesArguments from './SalesArguments'
import FlatFinder from './FlatFinder'
import Prices from './Prices'
import Agents from './Agents'
import AddNewAgentButton from './Agents/AddNewButton'
import updateLandingPage from './updateLandingPage'

const LANDING_PAGE_CONTENT_VALIDATOR_NUMBER = 5

const ContentForm = ({
  className, landingPage, campaign, onFinish, onUpdate, refetchLandingPage
}) => {
  const client = useApolloClient()
  const [errors, setErrors] = useState([])
  const [validationPending, setValidationPending] = useState()
  const [finishClicked, setFinishClicked] = useState(false)
  const validate = buildValidator({
    setValidationPending,
    setErrors,
    validatorNumber: LANDING_PAGE_CONTENT_VALIDATOR_NUMBER
  })
  const withValidation = createValidatableActionHandler({
    validate,
    validatablePayload: landingPage
  })

  const handleFinish = () => setFinishClicked(true)

  useEffect(() => {
    if(validationPending || !finishClicked) return

    withValidation(onFinish)
    setFinishClicked(false)
  }, [validationPending, finishClicked])

  const errorsOnIntro = combineErrors({ errors, uuid: landingPage.uuid, field: 'intro' })

  const handleIntroUpdate = (intro) => {
    setValidationPending(true)
    onUpdate({ intro }).then(({ data }) => validate({
      payload: data.updateLandingPage.landingPage
    }))
  }

  const handleProjectImageDrop = ({ asset: { uuid } }) =>
    onUpdate({ projectDescriptionImageUuid: uuid })

  const handleProjectImageRemove = async () => onUpdate({ projectDescriptionImageUuid: null })

  const handleAreaImageDrop = ({ asset: { uuid } }) =>
    onUpdate({ areaDescriptionImageUuid: uuid })

  const handleAreaImageRemove = async () => onUpdate({ areaDescriptionImageUuid: null })

  const [_projectProps, dropProjectImage] = useDrop({
    accept: mediaTypeDropEvents.image,
    drop: handleProjectImageDrop
  })

  const [_areaProps, dropAreaImage] = useDrop({
    accept: mediaTypeDropEvents.image,
    drop: handleAreaImageDrop
  })

  const projectDescriptionImage = landingPage.projectDescriptionImage || {}
  const areaDescriptionImage = landingPage.areaDescriptionImage || {}

  return (
    <FormContainer className={ className }>
      <Card
        className="landing-content-form"
        header={ t`Edit landing page content` }
        footer={
          <FinishButton onClick={ handleFinish } data-test-id='finish-button'>
            { t`Finish` }
          </FinishButton>
        }
      >
        <HeaderImageAndLogo
          landingPage={ landingPage }
          campaign={ campaign }
          onUpdate={ onUpdate }
        />

        <Hr/>

        <Form.Group>
          <SectionHeader>
            <Switch
              onChange={
                () => updateLandingPage(
                  client,
                  landingPage,
                  { titleEnabled: !landingPage.titleEnabled }
                )
              }
              checked={ landingPage.titleEnabled }
              className='hide-inactive-toggle title-enabled'
            />
            { t`Title` }
          </SectionHeader>
          <ThriftyInput
            autoSelect
            value={ landingPage.title }
            onUpdate={ title => onUpdate({ title }) }
            data-test-id='title-input'
          />
        </Form.Group>

        <Form.Group>
          <SectionHeader>
            <Switch
              onChange={
                () => updateLandingPage(
                  client,
                  landingPage,
                  { introEnabled: !landingPage.introEnabled }
                )
              }
              checked={ landingPage.introEnabled }
              className='hide-inactive-toggle'
            />
            { t`Intro text` }
          </SectionHeader>
          <InputWrapper error={ errorsOnIntro }>
            <WysiwygEditor
              initialValue={ landingPage.intro }
              onChange={ editor => handleIntroUpdate(editor.target.getContent()) }
              height={ 200 }
              id={ 'intro-text-input' }
            />
          </InputWrapper>
        </Form.Group>

        <Hr/>
        <SectionHeader>
          <Switch
            onChange={
              () => updateLandingPage(
                client,
                landingPage,
                { showingsEnabled: !landingPage.showingsEnabled }
              )
            }
            checked={ landingPage.showingsEnabled }
            className='hide-inactive-toggle'
          />
          { t`Showings` }
        </SectionHeader>

        <Hr/>
        <SectionHeader>
          <Switch
            onChange={
              () => updateLandingPage(
                client,
                landingPage,
                { galleryEnabled: !landingPage.galleryEnabled }
              )
            }
            checked={ landingPage.galleryEnabled }
            className='hide-inactive-toggle'
          />
          { t`Gallery` }
        </SectionHeader>
        <GalleryImages
          campaign={ campaign }
          landingPage={ landingPage }
        />

        <Hr/>
        <SectionHeader>
          <Switch
            onChange={
              () => updateLandingPage(
                client,
                landingPage,
                { projectDescriptionEnabled: !landingPage.projectDescriptionEnabled }
              )
            }
            checked={ landingPage.projectDescriptionEnabled }
            className='hide-inactive-toggle'
          />
          { t`Project description` }
        </SectionHeader>
        <DescriptionContainer>
          <WysiwygEditor
            initialValue={ landingPage.projectDescription }
            onChange={ editor => onUpdate({ projectDescription: editor.target.getContent() }) }
            height={ 105 }
            id={ 'project-description-input' }
          />

          <DescriptionAssetWrapper ref={ dropProjectImage }>
            {projectDescriptionImage.asset ? (
              <SelectedAsset
                allowedSize
                assetAssignment={ projectDescriptionImage }
                onAssetAssignmentRemove={ handleProjectImageRemove }
                assetableType={ projectDescriptionImage.__typename }
                key={ projectDescriptionImage.uuid }
                displayEnabled={ false }
              />
            ) : (
              <EmptyAsset mediaType="image" />
            )}
          </DescriptionAssetWrapper>
        </DescriptionContainer>

        <Hr/>
        <SectionHeader>
          <Switch
            onChange={
              () => updateLandingPage(
                client,
                landingPage,
                { areaDescriptionEnabled: !landingPage.areaDescriptionEnabled }
              )
            }
            checked={ landingPage.areaDescriptionEnabled }
            className='hide-inactive-toggle'
          />
          { t`Area description` }
        </SectionHeader>
        <DescriptionContainer>
          <WysiwygEditor
            initialValue={ landingPage.areaDescription }
            onChange={ editor => onUpdate({ areaDescription: editor.target.getContent() }) }
            height={ 105 }
            id={ 'area-description-input' }
          />

          <DescriptionAssetWrapper ref={ dropAreaImage }>
            {areaDescriptionImage.asset ? (
              <SelectedAsset
                allowedSize
                assetAssignment={ areaDescriptionImage }
                onAssetAssignmentRemove={ handleAreaImageRemove }
                assetableType={ areaDescriptionImage.__typename }
                key={ areaDescriptionImage.uuid }
                displayEnabled={ false }
              />
            ) : (
              <EmptyAsset mediaType="image" />
            )}

          </DescriptionAssetWrapper>
        </DescriptionContainer>

        <Hr/>
        <SectionHeader>
          <Switch
            onChange={
              () => updateLandingPage(
                client,
                landingPage,
                { mapEnabled: !landingPage.mapEnabled }
              )
            }
            checked={ landingPage.mapEnabled }
            className='hide-inactive-toggle'
          />
          { t`Map` }
        </SectionHeader>

        <Hr/>
        <SectionHeader>
          <Switch
            onChange={
              () => updateLandingPage(
                client,
                landingPage,
                { salesArgumentsEnabled: !landingPage.salesArgumentsEnabled }
              )
            }
            checked={ landingPage.salesArgumentsEnabled }
            className='hide-inactive-toggle'
          />
        </SectionHeader>
        <SalesArguments landingPage={ landingPage } onUpdate={ onUpdate } />

        <Hr/>

        <SectionHeader>
          <Switch
            onChange={
              () => updateLandingPage(
                client,
                landingPage,
                { flatFinderEnabled: !landingPage.flatFinderEnabled }
              )
            }
            checked={ landingPage.flatFinderEnabled }
            className='hide-inactive-toggle'
          />
          { t`Flat finder` }
        </SectionHeader>
        <FlatFinder landingPage={ landingPage } onUpdate={ onUpdate } />

        <Hr/>

        <SectionHeader>
          <Switch
            onChange={
              () => updateLandingPage(
                client,
                landingPage,
                { unitsListEnabled: !landingPage.unitsListEnabled }
              )
            }
            checked={ landingPage.unitsListEnabled }
            className='hide-inactive-toggle'
          />
          { t`Unit list` }
        </SectionHeader>

        <Hr/>
        <SectionHeader>
          <Switch
            onChange={
              () => updateLandingPage(
                client,
                landingPage,
                { pricesEnabled: !landingPage.pricesEnabled }
              )
            }
            checked={ landingPage.pricesEnabled }
            className='hide-inactive-toggle'
          />
          { t`Prices` }
        </SectionHeader>
        <Prices landingPage={ landingPage } onUpdate={ onUpdate } />

        { landingPage.template.agentsEnabled && (
          <>
            <Hr/>
            <SectionHeader>
              <Switch
                onChange={
                  () => updateLandingPage(
                    client,
                    landingPage,
                    { agentsEnabled: !landingPage.agentsEnabled }
                  )
                }
                checked={ landingPage.agentsEnabled }
                className='hide-inactive-toggle'
              />
              { t`Agents` }
              <AddNewAgentButton
                landingPage={ landingPage }
                refetchLandingPage={ refetchLandingPage }
              />
            </SectionHeader>
            <Agents landingPage={ landingPage } refetchLandingPage={ refetchLandingPage } />
          </>
        )}
      </Card>
    </FormContainer>
  )
}

ContentForm.propTypes = {
  campaign: object.isRequired,
  landingPage: landingPageType.isRequired,
  onFinish: func.isRequired,
  onUpdate: func.isRequired,
  refetchLandingPage: func.isRequired
}

export default styled(ContentForm)`
  .hide-inactive-toggle {
    width: 2.5rem;
    flex: auto;
    margin-right: .5rem;
  }
`

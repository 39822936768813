import React, { useState } from 'react'
import styled              from 'styled-components'
import { useMutation }     from 'react-apollo'
import { t }               from 'ttag'
import { string, object }  from 'prop-types'

import Datepicker   from 'shared/components/ui/Datepickers/Datepicker'
import { jsDate }   from 'shared/helpers/date'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import BuildingTypesSelect
  from 'shared/components/NewStage/StageDetails/Details/BuildingTypesSelect'
import updateSnapshotMutation        from 'graphql/mutations/snapshots/update'
import updateSnapshotUnitSetMutation from 'graphql/mutations/snapshots/unitSets/update'

const LeftColumn = ({ className, snapshot, unitSet }) => {
  const [currentSnapshot, setCurrentSnapshot] = useState(snapshot)

  const [runUpdateSnapshotMutation] = useMutation(updateSnapshotMutation, {
    ignoreResults: true
  })

  const [runUnitSetUpdateMutation] = useMutation(updateSnapshotUnitSetMutation, {
    ignoreResults: true
  })

  const handleSnapshotUpdate = async (value, fieldName) => {
    const result = await runUpdateSnapshotMutation({
      variables: {
        uuid: snapshot.uuid,
        input: { [fieldName]: value }
      }
    })
    setCurrentSnapshot(result.data.updateSnapshot.snapshot)
  }

  const handleUnitSetUpdate = (value, fieldName) => {
    return runUnitSetUpdateMutation({
      variables: {
        uuid: unitSet.uuid,
        input: { [fieldName]: Number(value) }
      }
    })
  }

  const handleFloorNumberChange = (value) => {
    return handleUnitSetUpdate(value, 'floorNumber')
  }

  return (
    <div className={ className }>
      <div className="group">
        <label>{ t`Unit types` }</label>
        <BuildingTypesSelect
          value={ currentSnapshot.buildingTypes }
          onChange={ (values) => handleSnapshotUpdate(values.map(el => el.value), 'buildingTypes') }
        />
      </div>

      <div className="group" data-test-id='floors-group'>
        <label>{ t`Number of floors` }</label>
        <ThriftyInput
          type="text"
          value={ unitSet.floorNumber }
          onUpdate={ handleFloorNumberChange }
        />
      </div>

      <div className="group" data-test-id='planning-group'>
        <label>{ t`Planning` }</label>
        <ThriftyInput
          type="text"
          value={ currentSnapshot.planning }
          onUpdate={ (value) => handleSnapshotUpdate(value, 'planning') }
        />
      </div>

      <div className="group" data-test-id='sales-group'>
        <label>{ t`Sales start` }</label>
        <Datepicker
          shouldCloseOnSelect
          showReset
          disabledKeyboardNavigation={ false }
          showTimeInput={ false }
          selected={ jsDate(currentSnapshot.salesStartAt) }
          onChange={ (value) => handleSnapshotUpdate(value, 'salesStartAt') }
        />
      </div>

      <div className="group" data-test-id='construction-group'>
        <label>{ t`Construction start` }</label>
        <Datepicker
          shouldCloseOnSelect
          showReset
          disabledKeyboardNavigation={ false }
          showTimeInput={ false }
          selected={ jsDate(currentSnapshot.constructionStartAt) }
          onChange={ (value) => handleSnapshotUpdate(value, 'constructionStartAt') }
        />
      </div>

      <div className="group" data-test-id='movein-group'>
        <label>{ t`Move-in date` }</label>
        <Datepicker
          shouldCloseOnSelect
          showReset
          disabledKeyboardNavigation={ false }
          showTimeInput={ false }
          selected={ jsDate(currentSnapshot.movingStartAt) }
          onChange={ (value) => handleSnapshotUpdate(value, 'movingStartAt') }
        />
      </div>
    </div>
  )
}

LeftColumn.propTypes = {
  className: string.isRequired,
  snapshot: object.isRequired,
  unitSet: object.isRequired
}

export default styled(LeftColumn)`
  width: 50%;
  padding: 0;
  padding-right: 1rem;

  .group {
    margin-bottom: 1rem;

    > label {
      width: auto !important;
      color: #697386;
      font-size: 0.875rem;
    }

    > div {
      width: 100%;
    }

    .input-wrapper {
      input, > div {
        width: 100% !important;
        max-width: 100%;
      }
    }
  }

  .input-wrapper, .group {
    width: 100%;

    .form-control {
      box-shadow: none;
    }
  }
`

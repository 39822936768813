import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { t } from 'ttag'

import { buttonTextColor } from 'shared/style/colors'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

import Option from './Option'
import SingleValue from './SingleValue'

const components = { Option, SingleValue }

const ColorSelect = ({ ...props }) => {
  return (
    <Select
      { ...props }
      styles={ condensedSelectStyles }
      components={ components }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
    />
  )
}

export default styled(ColorSelect)`
  [class*="-control"] {
    & > * {
      height: 100%;
    }
  }

  .color-option {
    align-items: center;
    display: flex;

    &.is-selected {
      .color-name {
        color: #FFF
      }
    }
  }

  .color-square {
    border-radius: .2rem;
    height: 1rem;
    width: 1rem;
    margin-right: .25rem;
  }

  .color-name {
    color: ${buttonTextColor};
    font-size: 0.75rem;
    text-transform: capitalize;
  }
`

import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { t } from 'ttag'

import { userRolePath } from 'routes/AppRedirect'
import stageType from 'shared/types/stageType'
import PrimaryButton from 'shared/components/ui/PrimaryButton'

import ConfirmModal from './ConfirmModal'

const NextButton = ({ stage }) => {
  const history = useHistory()

  const [proceed, setProceed] = useState(false)
  useEffect(() => {
    if (!proceed) return

    history.push(userRolePath(`/stages/${stage.uuid}/campaigns/new`))
    setProceed(false)
  }, [proceed])

  const [nextClicked, setNextClicked] = useState(false)
  const handleNextClick = () => setNextClicked(true)
  useEffect(() => {
    if (!nextClicked) return

    isEmpty(stage.missingData) ? setProceed(true) : setShowModal(true)
    setNextClicked(false)
  }, [nextClicked])

  const [showModal, setShowModal] = useState(false)

  const handleCancel = () => {
    setShowModal(false)
    setProceed(false)
  }

  const handleProceed = () => {
    setShowModal(false)
    setProceed(true)
  }

  return (
    <>
      <ConfirmModal
        show={ showModal }
        handleHide={ handleCancel }
        handleCancel={ handleCancel }
        handleProceed={ handleProceed }
      />

      <PrimaryButton
        className='mt-2 mb-4 float-right'
        onClick={ handleNextClick }
      >
        { t`Proceed` }
      </PrimaryButton>
    </>
  )
}

NextButton.propTypes = {
  stage: stageType.isRequired
}

export default NextButton

import React from 'react'
import AsyncSelect from 'react-select/async'
import { t } from 'ttag'
import { useApolloClient } from 'react-apollo'
import { get } from 'lodash'
import { func, string } from 'prop-types'

import projectSearch from 'graphql/queries/projectSearch'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const ProjectSelect = ({ onChange, value: _ , ...props }) => {
  const client = useApolloClient()
  const loadOptions = (query, callback) => {
    client.query({
      query: projectSearch,
      variables: { query }
    }).then(({ data }) => {
      callback(
        data.projectSearch.map(project => ({ value: project.uuid, label: project.name }))
      )
    })
  }
  const handleChange = option => onChange(get(option, 'value', null))

  return (
    <span data-test-id='project-select-wrapper'>
      <AsyncSelect
        loadOptions={ loadOptions }
        onChange={ handleChange }
        styles={ condensedSelectStyles }
        placeholder={ t`Search...` }
        noOptionsMessage={ () => t`No options` }
        { ...props }
      />
    </span>
  )
}

ProjectSelect.propTypes = {
  onChange: func.isRequired,
  value: string
}

ProjectSelect.defaultProps = {
  value: ''
}

export default ProjectSelect

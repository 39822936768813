import { buildFragmentParams } from 'shared/helpers/graphql'

const updateAssetableAssetAssignmentsCache = ({
  store,
  assetableType,
  assetableUuid,
  assetAssignment,
  assetableFragment
}) => {
  const fragmentParams = buildFragmentParams(
    { uuid: assetableUuid, __typename: assetableType },
    assetableFragment 
  )

  const fragmentData = store.readFragment(fragmentParams)

  store.writeFragment({
    ...fragmentParams,
    data: {
      ...fragmentData,
      assetAssignments: [
        ...fragmentData.assetAssignments,
        assetAssignment
      ]
    }
  })
}

export default updateAssetableAssetAssignmentsCache

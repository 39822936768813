import * as Yup from 'yup'
import { t }    from 'ttag'

import { unfinishedPhases } from 'shared/constants/campaigns/phases'

const channelsToValidatePlatformPositions = ['facebook', 'instagram', 'gmp', 'portal']
const minDisplayChannelSpending = 1

export default Yup.object().shape({
  phase: Yup.string(),
  startDate: Yup.date().
    required(t`Please fill in a start date.`).
    when('phase', {
      is: (phase) => unfinishedPhases.includes(phase),
      then: Yup.date().min(
        Yup.ref('$minStartDate'),
        t`Campaign must start not earlier than 5 minutes from now.`
      )
    }),
  endDate: Yup.date().
    required(t`Please fill in a end date.`).
    min(
      Yup.ref('$minEndDate'),
      t`Campaign must end not earlier than 1 day after it starts.`
    ),

  specification: Yup.object().shape({
    channels: Yup.array().min(1, t`Please add at least 1 channel.`).of(
      Yup.object().shape({
        type: Yup.string(),
        spending: Yup.number().when(
          'type', (type, schema, { context }) => {
            if(!channelsToValidatePlatformPositions.includes(type)) return schema

            if (type !== 'gmp') {
              let minChannelSpending
              switch (type) {
              case 'facebook':
                minChannelSpending = context.minFacebookChannelSpending
                break
              case 'instagram':
                minChannelSpending = context.minInstagramChannelSpending
                break
              case 'portal':
                minChannelSpending = context.minPortalChannelSpending
                break
              }

              return schema.required().min(
                minChannelSpending,
                `${ t`Spending for channel must be more than` }
                 ${ minChannelSpending } ${context.currency}`
              )
            } else {
              return schema.required().min(
                minDisplayChannelSpending,
                t`Spending for Display channel must be more than 0`
              )
            }
          }
        )
      })
    )
  })
})

import { gql } from 'apollo-boost'

import project from 'graphql/fragments/project'
import snapshot from 'graphql/fragments/snapshot'

export default gql`
  mutation PublishProjectToPortal(
    $uuid: ID!
  ) {
    publishProjectToPortal(
      uuid: $uuid
    ) {
      project {
        ... Project

        snapshots {
          ... Snapshot
        }

        completeStages {
          uuid

          snapshots {
            ... Snapshot

          }
        }
      }
    }
  }
  ${project}
  ${snapshot}
`

import React    from 'react'
import styled   from 'styled-components'
import { t }    from 'ttag'
import { Form } from 'react-bootstrap'
import { object, string, bool, func } from 'prop-types'

import PrimaryButton from 'shared/components/ui/PrimaryButton'
import { buttonTextColor, textColor, redColor } from 'shared/style/colors'
import AdminLocationInfo from 'admin/components/ProfileForm/AdminLocationInfo'
import UserLocationInfo  from 'user/components/ProfileForm/UserLocationInfo'

import PhoneSelect    from './PhoneSelect'
import LanguageSelect from './LanguageSelect'
import TimeZoneSelect from './TimeZoneSelect'

const ProfileFormView = (props) => {
  const {
    className,
    isUpdated,
    isSubmitting,
    handleSubmit,
    handleChange,
    touched,
    values,
    errors,
    user
  } = props

  const LocationInfo = user.__typename === 'User' ? UserLocationInfo : AdminLocationInfo

  return (
    <Form className={ className } onSubmit={ handleSubmit }>
      <div className="columns">
        <div className="column">
          <Form.Group>
            <Form.Label>{ t`Name` }</Form.Label>
            <Form.Control
              name="name"
              value={ values.name }
              onChange={ handleChange }
              data-test-id='name'
              isInvalid={ touched.name && errors.name }
            />
            <Form.Control.Feedback type="invalid">
              { errors.name }
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>{ t`Email` }</Form.Label>
            <Form.Control
              name="email"
              type="email"
              value={ values.email }
              onChange={ handleChange }
              data-test-id='email'
              isInvalid={ touched.email && errors.email }
            />
            <Form.Control.Feedback type="invalid">
              { errors.email }
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>{ t`Phone` }</Form.Label>
            <PhoneSelect
              onChange={ handleChange }
              values={ values }
              touched={ touched }
              errors={ errors }
            />
          </Form.Group>
        </div>

        <div className="column">
          <LocationInfo user={ user } />

          <Form.Group>
            <Form.Label>{ t`Default language` }</Form.Label>
            <LanguageSelect
              name="defaultLanguage"
              value={ values.defaultLanguage }
              onChange={ handleChange }
              isInvalid={ errors.defaultLanguage && touched.defaultLanguage }
            />
            <Form.Control.Feedback type="invalid">
              { errors.defaultLanguage }
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>{ t`Timezone` }</Form.Label>
            <TimeZoneSelect
              name="timezone"
              value={ values.timezone }
              onChange={ handleChange }
              isInvalid={ errors.timezone && touched.timezone }
            />
            <Form.Control.Feedback type="invalid">
              { errors.timezone }
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>

      <PrimaryButton
        type="submit"
        disabled={ isSubmitting || isUpdated } data-test-id='save-profile-button'
      >
        { t`Save` }
      </PrimaryButton>

      { isUpdated && (
        <span className="text-success align-middle ml-2">
          { t`Updated` }!
        </span>
      ) }
    </Form>
  )
}

ProfileFormView.propTypes = {
  className: string.isRequired,
  errors: object.isRequired,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired,
  isUpdated: bool.isRequired,
  touched: object.isRequired,
  user: object.isRequired,
  values: object.isRequired,
}

export default styled(ProfileFormView)`
  font-size: .875rem

  .columns {
    display: flex
    justify-content: flex-start
  }

  label {
    color: ${buttonTextColor};
  }

  .column {
    width: 40%
  }

  .column:first-child {
    margin-right: 10%;
  }

  .form-group .form-label {
    width: 40%;
    vertical-align: top;
    margin-top: .5rem;
  }

  .form-control {
    box-shadow: none
  }

  .form-group > .form-control {
    display: inline-block;
    width: 60%;
  }

  .form-control + .invalid-feedback {
    padding-left: 40%;
  }

  .is-invalid [class$=control]{
    border-color: ${redColor}
  }

  .is-invalid + .invalid-feedback {
    display: block;
    padding-left: 40%;
  }

  .info-row {
    color: ${textColor}
    display: flex
    justify-content: flex-start
    margin-bottom: .5rem
  }

  .info-row label {
    width: 40%
  }
`

import gql from 'graphql-tag'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  fragment Property on Property {
    uuid
    name
    customerName
    description
    shortDescription
    facilities
    setupComplete
    price
    totalPrice
    bedroomCount
    bedrooms
    usableAreaM2
    propertyType
    primaryAgentName
    primaryAgentTitle
    primaryAgentAvatar {
      media
    }
    visitsHours {
      endAt
      startAt
    }
    location {
      uuid
      country
      address
      city
      municipality
      district
    }
    createdAt
    activeCampaignsCount
    campaignsCount
    missingData
    companyUuid
    image {
      thumbnail
    }
    assetAssignments {
      ... AssetAssignment
    }
  }
  ${assetAssignment}
`

import React from 'react'
import AsyncSelect from 'react-select/async'
import { string, func } from 'prop-types'
import { t } from 'ttag'
import geocodingClientBase from '@mapbox/mapbox-sdk/services/geocoding'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import credentials from 'config/credentials'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1
  })
}

const ZipCodeSelect = ({ value, countryCode, setTouched, ...props })=> {
  const geocodingClient = geocodingClientBase({ accessToken: credentials.mapboxApiKey })

  const selectedOption = { value, label: value }
  const handleBlur = () => setTouched && setTouched(true)

  const loadOptions = query =>
    new Promise(resolve => {
      geocodingClient.forwardGeocode({
        query,
        countries: [countryCode],
        types: ['postcode']
      })
        .send()
        .then(({ body }) => {
          const options = body.features.map(feature => ({
            value: feature.text,
            label: feature.place_name,
            center: feature.center,
            locationDetails: feature
          }))

          resolve(options)
        })
    })

  return (
    <AsyncSelect
      value={ selectedOption }
      disabled={ !countryCode }
      placeholder={ countryCode ? '' : t`Please select a country` }
      noOptionsMessage={ () => t`No options` }
      styles={ styles }
      onBlur={ handleBlur }
      loadOptions={ loadOptions }
      { ...props }
    />
  )
}

ZipCodeSelect.propTypes = {
  countryCode: string,
  setTouched: func,
  value: string
}

ZipCodeSelect.defaultProps = {
  countryCode: undefined,
  value: undefined,
  setTouched: undefined
}

export default ZipCodeSelect

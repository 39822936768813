import { gql } from 'apollo-boost'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  mutation attachFileToAssetable(
    $blobId: String!
    $assetableUuid: String!
    $assetableType: String!
    $mediaType: AssetMediaType
  ) {
    attachFileToAssetable(
      blobId: $blobId
      assetableUuid: $assetableUuid
      assetableType: $assetableType
      mediaType: $mediaType
    ) {
      assetAssignment {
        ... AssetAssignment
      }
    }
  }
  ${assetAssignment}
`

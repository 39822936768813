import React               from 'react'
import { useApolloClient } from 'react-apollo'
import { arrayOf, number, shape, string } from 'prop-types'

import useChannelTypes        from 'shared/hooks/useChannelTypes'
import useCurrentCampaignUuid from 'shared/hooks/useCurrentCampaignUuid'
import mutation from 'graphql/mutations/campaign/specifications/addSpendingChannel'

import ChannelsList     from './ChannelsList'
import NewChannelButton from './NewChannelButton'
import Totals           from './Totals'

const SpendingChannels = ({ channels, currency, ...props }) => {
  const campaignUuid = useCurrentCampaignUuid()

  const client = useApolloClient()
  const channelTypes = useChannelTypes()
  const canAddMoreChannels = channelTypes.length > channels.length

  const addNewChannel = () => {
    client.mutate({
      mutation,
      variables: { campaignUuid },
    })
  }

  return (
    <>
      <ChannelsList
        channels={ channels }
        currency={ currency }
        { ...props }
      />

      { canAddMoreChannels && (
        <NewChannelButton onClick={ addNewChannel } />
      ) }

      <Totals channels={ channels } currency={ currency } />
    </>
  )
}

SpendingChannels.propTypes = {
  channels: arrayOf(shape({
    type: string,
    spending: number
  })).isRequired,
  currency: string.isRequired
}

export default SpendingChannels

import React     from 'react'
import { shape, bool } from 'prop-types'

import stageType from 'shared/types/stageType'
import ReadOnlyUnits from 'shared/components/Project/Readonly/Stages/Units'
import { isUserAdmin } from 'shared/helpers/auth'

import UnitsForm from './UnitsForm'

const Units = (props) => {
  const stage = props.product.promotable

  if (!isUserAdmin() && props.projectReadOnly) return <ReadOnlyUnits product={ props.product } />

  return <UnitsForm stage={ stage } />
}

Units.propTypes = {
  product: shape({
    promotable: stageType.isRequired
  }).isRequired,
  projectReadOnly: bool
}

Units.defaultProps = {
  projectReadOnly: false
}

export default Units

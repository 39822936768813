import { gql } from 'apollo-boost'

import specification        from 'graphql/fragments/specification'
import channel              from 'graphql/fragments/channel'
import productWithSnapshots from 'graphql/fragments/productWithSnapshots'
import property             from 'graphql/fragments/property'
import campaignLandingPages from 'graphql/fragments/campaignLandingPages'
import showing              from 'graphql/fragments/showing'

export default gql`
  query Campaign($uuid: ID!) {
    campaign(uuid: $uuid) {
      uuid
      name
      canceled
      budget
      orderDate
      assemblyDate
      controlDate
      clientReviewDate
      startDate
      endDate
      durationInDays
      daysSinceStart
      phase
      areFacebookCredentialsValid
      areGmpCredentialsValid
      publishStartedAt
      publishFailedAt
      backgroundColor
      propertyBased
      promotableType
      currentContentStep
      dsaBeneficiary
      dsaPayor
      specification {
        ... Specification
      }
      specificationTemplate {
        ... Specification
      }
      advertiserId
      facebookPage {
        uuid
        name
        logoUrl
      }
      canSetPhase {
        resumed
        paused
        archived
        unarchived
        assembly
      }
      channels {
        ... Channel
      }
      assignments {
        user {
          name
        }
        role
        permission
      }
      product {
        ... ProductWithSnapshots
      }
      project {
        uuid
        createdAt
        name
        customerName
        stagesCount
        unitsCount
        unitsSold
        address
        activeCampaignsCount
        missingData
        totalCampaignsCount
        readonly
        defaultColor
        defaultTemplateUuid
        sourceDescription
        customDescription
        customDescriptionDraft
        title
        titleDraft
        link
        linkDraft
        portalEnabled
        portalEnabledDraft
        salesReportEnabled
        flatFinderUrl
        multistage
        autoDescriptionEnabled
        ownershipType
        headline
        headlineDraft
        facilities
        saleState
        saleStateDraft
        enabledExtraUnits
        portalAutoSyncEnabled
        snapshotsEnabled
        companyColors {
          ... Color
        }
        assetAssignments {
          ... AssetAssignment
        }
        showings {
          ... Showing
        }
        district {
          uuid
        }
        location {
          address
          countryCode
          zipCode
          latitude
          longitude
        }
      }
      property {
        ... Property
      }
      creativeSets {
        uuid
        name
        landingPage {
          uuid
          name
        }
      }
      ... CampaignLandingPages
    }
  }
  ${specification}
  ${channel}
  ${productWithSnapshots}
  ${campaignLandingPages}
  ${showing}
  ${property}
`

import styled, { css } from 'styled-components'

import { textColor, lightGreyColor } from 'shared/style/colors'
import CircularGauge from 'shared/components/CircularGauge'

export const StatValue = styled.div`
  color: ${textColor};
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;
`

export const StatName = styled.div`
  color: ${lightGreyColor};
  font-size: 0.625rem;
  font-weight: 600;
  min-width: 2.5rem;
  white-space: nowrap;
`

export const Container = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;

  ${CircularGauge} {
    height: 4rem;
    width: 4rem;
  }

  ${props => props.size === 'medium' ? (
    css`
      height: 5rem;

      ${CircularGauge} {
        height: 5rem;
        width: 5rem;
      }

      ${StatName} {
        min-width: 5rem;
      }

      ${StatValue} {
        font-size: 1.5rem;
      }
    `
  ) : (
    undefined
  )}
`

import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { object } from 'prop-types'
import { Alert } from 'react-bootstrap'
import { t } from 'ttag'

import mostRecentPublicationsQuery from 'graphql/queries/campaigns/mostRecentPublications'

import Details from './Details'

const CampaignPublication = ({ campaign }) => {
  const {
    data: { campaignMostRecentPublications } = {},
    loading
  } = useQuery(mostRecentPublicationsQuery, {
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    variables: {
      campaignUuid: campaign.uuid
    }
  })

  if (!campaignMostRecentPublications && loading) { return null }

  if (!campaignMostRecentPublications || campaignMostRecentPublications.length === 0) {
    return (
      <Alert variant="info">
        { t`This campaign doesn't have any channel publications.` }
      </Alert>
    )
  }

  return (
    <div>
      { campaignMostRecentPublications.map((publication) => {
        return <Details publication={ publication } key={ publication.uuid }/>
      })
      }
    </div>
  )
}

CampaignPublication.propTypes = {
  campaign: object.isRequired
}

export default CampaignPublication

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { t } from 'ttag'

import Radio from 'shared/components/ui/Radio'
import { iconsGreyColor } from 'shared/style/colors'

const ActiveStatus = ({
  updateSaleState,
  saleState,
  saleStates,
  title,
  radioButtonName,
  recordUuid,
  className
}) => {
  const getStateName = (stateKey) => {
    return saleStates.find(s => s.key === stateKey)?.title
  }

  const onRadioSelect = useCallback((value) => {
    const saleStateKey = value.currentTarget.value
    const saleStateName = getStateName(saleStateKey)
    // eslint-disable-next-line max-len
    const message = t`Please confirm that you want to change the active sales status to “${saleStateName}”. No changes will be shown on portal or other external websites before you press “Publish” button at end of this section`

    if (!confirm(message)) {
      return
    }

    updateSaleState(saleStateKey)
  }, [saleState])

  const getRadioButtonName = () => {
    if (recordUuid) {
      return `${radioButtonName}-${recordUuid}`
    }

    return radioButtonName
  }

  return (
    <div className={ className }>
      <div className="controls-area">
        <span className="sale-state-title">{ title }</span>
        <div className="btn-group d-flex controls-list" role="group" >
          { (saleStates.map(saleStatus => (
            <Radio
              className="w-100 control-item"
              data-test-id={ `sale-state-${saleStatus.key}` }
              name={ getRadioButtonName() }
              key={ `${saleStatus.key}-${radioButtonName}` }
              color="primary-o"
              value={ saleStatus.key }
              onChange={ onRadioSelect }
              checked={ saleState === saleStatus.key }>
              { saleStatus.title }
            </Radio>
          ))) }
        </div>
      </div>
    </div>
  )
}

ActiveStatus.propTypes = {
  saleState: PropTypes.string.isRequired,
  saleStates: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
  title: PropTypes.string.isRequired,
  updateSaleState: PropTypes.func.isRequired,
  radioButtonName: PropTypes.string,
  recordUuid: PropTypes.string
}

ActiveStatus.defaultProps = {
  radioButtonName: 'saleState',
  recordUuid: null
}

export default styled(ActiveStatus)`
  .control-item {
    margin-left: 20px;
    
    label {
      color: ${iconsGreyColor};
    }
  }
  
  .sale-state-title {
    font-size: 1.063rem;
  }
  
  .radio-state, .sale-state-title {
    color: ${iconsGreyColor};
  }
  
  .controls-area {
    margin-bottom: 20px;
  }
  
  .controls-list {
    margin-top: 15px;
  }
`

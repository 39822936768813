import React, { useCallback, memo } from 'react'
import { isEqual }                  from 'lodash'
import { string, func, bool, object, array } from 'prop-types'

import LoadingSelect  from 'shared/components/ui/LoadingSelect'
import companiesQuery from 'graphql/queries/filters/companies'

const CompanySelect = ({ value, name, isInvalid, onChange, styles, selectedCompaniesUuids }) => {
  const className = isInvalid ? 'is-invalid' : ''
  const saveFormValue = useCallback(({ value, element }) => {
    onChange({ target: { name, value, element } })
  }, [onChange])

  return (
    <LoadingSelect
      className={ className }
      query={ companiesQuery }
      selectedCompaniesUuids={ selectedCompaniesUuids }
      value={ value }
      styles={ styles }
      onChange={ saveFormValue }
      valueAttribute="uuid"
      labelAttribute="name"
    />
  )
}

CompanySelect.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  isInvalid: bool,
  selectedCompaniesUuids: array,
  styles: object,
  value: string
}

CompanySelect.defaultProps = {
  value: undefined,
  isInvalid: undefined,
  selectedCompaniesUuids: [],
  styles: {}
}

export default memo(CompanySelect, isEqual)

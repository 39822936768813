import { useRef } from 'react'

import useIsOpened from 'shared/hooks/useIsOpened'
import { sortByDates } from 'shared/helpers/date'

export default function useShowingsListOverlay(showings) {
  const { isOpened, toggle, close } = useIsOpened(false)
  const target = useRef(null)
  const container = useRef(null)
  const sortedShowings = showings.sort((showingA, showingB) => sortByDates(showingA, showingB))
  const [firstShowing] = sortedShowings

  return { isOpened, toggle, close, target, container, sortedShowings, firstShowing }
}

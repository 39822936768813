import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const Styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1,
    maxWidth: '13.4rem',
    width: '13.4rem'
  }),
  control: provided => ({
    ...provided,
    height: 'calc(1.5em + 0.75rem + 1px)',
    minHeight: 'calc(1.5em + 0.75rem + 1px)'
  }),
  option: provided => ({
    ...condensedSelectStyles.option(provided),
    backgroundColor: 'white',
    padding: '0.125rem 0.75rem',
    minHeight: '29px'
  }),
  valueContainer: provided => ({
    ...condensedSelectStyles.valueContainer(provided),
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }),
  menu: provided => ({
    ...provided,
    zIndex: 3
  })
}

export default Styles

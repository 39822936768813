import React, { useState } from 'react'
import { t }               from 'ttag'
import { useQuery }        from '@apollo/react-hooks'
import styled              from 'styled-components'
import { get }             from 'lodash'
import { number }          from 'prop-types'

import projectType                from 'shared/types/projectType'
import { bluishColor }            from 'shared/style/colors'
import LoadingBlock               from 'shared/components/ui/LoadingBlock'
import Chart                      from 'shared/components/Chart'
import Tab                        from 'shared/components/ui/TabBar/Tab'
import projectSalesAnalyticsQuery from 'graphql/queries/projects/salesAnalytics'

import Card from '../Card'

const initialTab = 1
const tabsData = [
  {
    index: 0,
    title: t`Total`,
    name: 'total'
  },
  {
    index: 1,
    title: t`Last 7 days`,
    name: 'days'
  },
  {
    index: 2,
    title: t`Last 30 days`,
    name: 'month'
  },
  {
    index: 3,
    title: t`Last 12 weeks`,
    name: 'weeks'
  },
  {
    index: 4,
    title: t`Last year`,
    name: 'year'
  }
]

const Sales = ({ className, project, stageTab }) => {
  const [selectedTab, setSelectedTab] = useState(initialTab)

  const { data } = useQuery(
    projectSalesAnalyticsQuery,
    {
      variables: {
        scopedTo:   (stageTab === 0 ? 'Project' : 'Stage'),
        uuid:       (stageTab === 0 ? project.uuid : project.completeStages[stageTab - 1].uuid),
        periodName: tabsData[selectedTab].name
      }
    }
  )
  const salesAnalyticsData = get(data, 'projectSalesAnalytics', null)

  if (salesAnalyticsData === null) {
    return (
      <Card className={ className } >
        <LoadingBlock />
      </Card>
    )
  }

  const chartData = [
    {
      name: t`Sold`,
      seriesData: salesAnalyticsData.data,
      color: bluishColor
    }
  ]

  return (
    <Card className={ className } headerText={ t`Sales` }>
      <div data-test-id='sales-card'>
        <div className={ 'd-flex sales-header' }>
          { tabsData.map((tab) => (
            <Tab
              key={ tab.index }
              title={ tab.title }
              showArrow={ tab.index < (tabsData.length - 1) }
              active={ tab.index === selectedTab }
              onClick={ () => setSelectedTab(tab.index) }
            />
          ))}
        </div>
        {salesAnalyticsData && (
          <Chart data={ chartData } />
        )}
      </div>
    </Card>
  )
}

Sales.propTypes = {
  project: projectType.isRequired,
  stageTab: number.isRequired
}

export default styled(Sales)`
  .sales-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 2rem;
  }

  ${Tab} {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    padding-right: 0;

    &:last-child {
      border-right: none
    }

    .arrow-top {
      display: none
    }

    .arrow-bottom {
      display: none
    }
  }
`

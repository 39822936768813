import gql from 'graphql-tag'

export default gql`
  fragment GmpPlatformAudience on PlatformAudience {
    ... on Gmp {
      uuid
      ageFrom
      ageTo
      incomeFrom
      incomeTo
      gender
      parentalStatus
      urls
      urlBundles {
        uuid
        name
        urlsCount
      }
      customAudiencesInclude {
        id
        name
        subtype
      }
      customAudiencesExclude {
        id
        name
        subtype
      }
      detailedTargetingInclude {
        uuid
        name
        subtype
      }
      detailedTargetingExclude {
        uuid
        name
        subtype
      }
      languageTargeting {
        id
        name
      }
    }
  }
`

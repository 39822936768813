import React, { useState } from 'react'
import { t } from 'ttag'
import { arrayOf, shape, string } from 'prop-types'

import SubHeader from 'shared/components/ui/Card/SubHeader'
import Button from 'shared/components/ui/Button'
import AudiencesSelect from 'shared/components/CampaignInfo/AudiencesSelect'

import useDuplicateAudienceLocations from './useDuplicateAudienceLocations'

const DuplicateAudienceLocations = ({ allAudiences, currentAudience }) => {
  const [selectedAudiences, setSelectedAudiences] = useState([])
  const duplicateLocations = useDuplicateAudienceLocations()

  const handleAudiencesSelectChange = (selectedAudienceUuids) => {
    setSelectedAudiences(
      allAudiences.filter(audience => selectedAudienceUuids.includes(audience.uuid))
    )
  }

  const handleApplyClick = () => {
    if (
      confirm(t`This will replace existing target location for selected audiences. Are you sure?`)
    ) {
      duplicateLocations({
        variables: {
          sourceAudienceUuid: currentAudience.uuid,
          targetAudienceUuids: selectedAudiences.map((audience) => audience.uuid)
        }
      })
      setSelectedAudiences([])
    }
  }

  return (
    <div className="mb-5">
      <SubHeader>{ t`Duplicate target location for other audiences` }</SubHeader>
      <div className="d-flex align-items-center" data-test-id="duplicate-target-location-wrapper">
        <AudiencesSelect
          onChange={ handleAudiencesSelectChange }
          audiences={ selectedAudiences }
          allAudiences={ allAudiences.filter((audience) => audience.uuid !== currentAudience.uuid) }
        />
        <Button
          onClick={ handleApplyClick }
          disabled={ selectedAudiences.length === 0 }
          className="ml-2"
          data-test-id="duplicate-target-location-apply"
        >
          { t`Apply` }
        </Button>
      </div>
    </div>
  )
}

DuplicateAudienceLocations.propTypes = {
  allAudiences: arrayOf(
    shape({
      name: string,
      uuid: string
    })
  ).isRequired,
  currentAudience: shape({
    uuid: string
  }).isRequired
}

export default DuplicateAudienceLocations

import React               from 'react'
import styled              from 'styled-components'
import { func, object }    from 'prop-types'
import { useApolloClient } from 'react-apollo'

import { darkRoseColor } from 'shared/style/colors'

import removeAgentMutation from './removeMutation'

const RemoveButton = ({ className, agent, refetchLandingPage }) => {
  const client = useApolloClient()
  const handleRemove = () => {
    client.mutate({
      mutation: removeAgentMutation,
      variables: { uuid: agent.uuid }
    }).then(() => {
      refetchLandingPage()
    })
  }

  return (
    <button
      className={ `agent-remove-button ${className}` }
      type='button'
      onClick={ handleRemove }
    >
      ✖
    </button>
  )
}

RemoveButton.propTypes = {
  agent: object.isRequired,
  refetchLandingPage: func.isRequired
}

export default styled(RemoveButton)`
  right: 5px;
  top: 5px;
  color: ${darkRoseColor};
  user-select: none;
  border-radius: 0 0 0 .25rem;
  background-color: white;
  border: none;
  padding: 0;
  position: absolute;
  z-index: 1;

  &:focus {
    border: none;
  }
`

import React from 'react'
import Select from 'react-select'
import { t } from 'ttag'
import { string } from 'prop-types'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const styles = {
  ...condensedSelectStyles,
  control: provided => ({
    ...condensedSelectStyles.control(provided),
    width: '13rem'
  })
}

const options = [
  { value: 'all', label: t`Applies to all units` },
  { value: 'some', label: t`Applies to some units` },
  { value: 'specific', label: t`Applies to specific units` }
]

const ArgumentApplicationSelect = ({ value, ...props }) => {
  const selectedOption = options.find(el => el.value === value)

  return (
    <div data-test-id='argument-select-wrapper'>
      <Select
        options={ options }
        value={ selectedOption }
        styles={ styles }
        placeholder={ t`Select...` }
        noOptionsMessage={ () => t`No options` }
        { ...props }
      />
    </div>
  )
}

ArgumentApplicationSelect.propTypes = {
  value: string
}

ArgumentApplicationSelect.defaultProps = {
  value: undefined
}

export default ArgumentApplicationSelect

import React from 'react'
import { t }  from 'ttag'
import { string, object } from 'prop-types'
import styled from 'styled-components'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { isEmpty, isUndefined } from 'lodash'

import MissingDataIcon from 'shared/assets/icons/svg/missing-data.svg'

const MissingData = ({ className, missingData }) => {
  if (isUndefined(missingData) || isEmpty(missingData.data)) {
    return null
  }

  const missingDataLis = missingData.data.map((field) => {
    return <li key={ field }>{ missingData.presentation[field] || field }</li>
  })

  const popover = (
    <Popover className='bootstrap-popover'>
      <Popover.Content>
        <p>{ t`Missing data:` }</p>
        <ul>{ missingDataLis }</ul>
        <p>{ t`Automatic systems will be unavailable until these are added.` }</p>
      </Popover.Content>
    </Popover>
  )

  return (
    <div className={ `missing-data-indicator ${ className }` }>
      <OverlayTrigger placement='auto' overlay={ popover }>
        <MissingDataIcon />
      </OverlayTrigger>
    </div>
  )
}

MissingData.propTypes = {
  className: string.isRequired,
  missingData: object
}

MissingData.defaultProps = {
  missingData: undefined
}

export default styled(MissingData)`
  display: inline-block
  margin: 0.2rem
  float: right

  p {
    margin-bottom: 0
  }

  svg {
    cursor: default
    height: 1.5rem
    width: 1.5rem
  }
`

import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { t } from 'ttag'
import { string, func } from 'prop-types'

import { currentLocale } from 'locale'
import countriesQuery from 'graphql/queries/countries'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import Select from 'shared/components/ui/ThriftyInput/TouchableSelect'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1
  })
}

const CountriesSelect = ({ value, onChange, ...props }) => {
  const { loading, data } = useQuery(countriesQuery, { variables: { locale: currentLocale } })
  const countries = loading ? [] : data.countries

  const allOptions = countries
    .map(({ name, alpha2 }) => ({ label: name, value: alpha2 }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  const selectedOption = allOptions.find(el => el.value === value)

  const featuredCodes = ['NO', 'GB', 'AU']
  const featuredOptions = featuredCodes
    .map(code => allOptions.find(({ value }) => value === code))
    .filter(el => el)

  const groupedOptions = [
    { label: t`Featured`, options: featuredOptions },
    { label: t`All`, options: allOptions.filter(({ value }) => !featuredCodes.includes(value)) }
  ]

  const handleChange = ({ value }) => {
    onChange(value)
  }

  return (
    <Select
      styles={ styles }
      value={ selectedOption }
      options={ groupedOptions }
      onChange={ handleChange }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

CountriesSelect.propTypes = {
  onChange: func.isRequired,
  value: string
}

CountriesSelect.defaultProps = {
  value: undefined,
}

export default CountriesSelect

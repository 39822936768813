import React, { useState } from 'react'
import { useQuery }        from '@apollo/react-hooks'
import { t }               from 'ttag'

import usersQuery    from 'graphql/queries/users'
import useCreateUser from 'shared/hooks/useCreateUser'
import useUrlParams  from 'shared/hooks/useUrlParams'
import { ensureNumber }                     from 'shared/helpers/number'
import { canCreate }                        from 'shared/helpers/auth'
import { paginatedSearchTextChangeHandler } from 'shared/helpers/paginatedSearch'
import PrimaryButton from 'shared/components/ui/PrimaryButton'
import Card          from 'shared/components/ui/Card'
import Header        from 'shared/components/ui/Card/StaticHeader'
import PlusIcon      from 'shared/components/ui/icons/PlusIcon'
import Search        from 'shared/components/ui/Search'
import UsersList     from 'shared/components/UsersList/List'
import UserFormModal from 'shared/components/UserForm/Modal'

const UsersIndex = () => {
  const [isCreating, setIsCreating] = useState(false)
  const { createUser } = useCreateUser()

  const [urlParams, setUrlParams] = useUrlParams()
  const { sortBy, sortOrder } = urlParams
  const page = ensureNumber(urlParams.page, 1)
  const search = urlParams.search
  const perPage = 15

  const { data, refetch } = useQuery(usersQuery, {
    variables: {
      query: { page, search, sortOrder, sortBy, perPage }
    }
  })

  const users = data ? data.users : { items: [], total: 0 }

  const handleSearchChange = paginatedSearchTextChangeHandler(urlParams, setUrlParams)

  const handleSubmit = (user, attributes) => {
    createUser({ variables: { attributes } }).then(refetch)
  }

  return (
    <Card header={ (
      <div className="d-flex justify-content-between">
        <Header className="align-self-left">{ t`Users` }</Header>
        <div className="align-self-right d-flex justify-content-between">
          <Search
            searchText={ search }
            onSearch={ handleSearchChange }
            className='search-input mr-4'
          />

          {canCreate('users') && (
            <PrimaryButton
              onClick={ () => setIsCreating(true) }
              style={{ minWidth: '11rem' }}
              data-test-id='add-new-user-button'
            >
              <PlusIcon />
              <span>{ t`Add new user` }</span>
            </PrimaryButton>
          )}
        </div>
      </div>
    ) }>
      <UsersList
        data={ users }
        params={ urlParams }
        onTableChange={ setUrlParams }
        refetch={ refetch }
        perPage={ perPage }
      />

      <UserFormModal
        title={ t`Create new user` }
        show={ isCreating }
        onHide={ () => setIsCreating(false) }
        onSubmit={ handleSubmit }
      />
    </Card>
  )
}

export default UsersIndex

import React      from 'react'
import styled     from 'styled-components'
import { object } from 'prop-types'

import ResourceQuery from 'shared/components/ResourceQuery'
import useTabBar     from 'shared/hooks/useTabBar'
import buildingQuery from 'shared/components/NewBuilding/buildingQuery'

import tabs from './TabBar/tabs'

const BuildingDetails = ({ building }) => {
  const { tabBar, CurrentComponent } = useTabBar(tabs)

  return (
    <Container>
      { tabBar }

      <ResourceQuery
        query={ buildingQuery }
        variables={{ uuid: building.uuid }}
        fetchPolicy='cache-first'
      >
        {building => (
          <CurrentComponent readOnly building={ building } />
        )}
      </ResourceQuery>
    </Container>
  )
}

const Container = styled.div`
  margin-top: -1.1rem;
  margin-left: -1.45rem;
  margin-right: -1.45rem;

  .table {
    margin-bottom: 0;
  }
`

BuildingDetails.propTypes = {
  building: object.isRequired
}

export default BuildingDetails

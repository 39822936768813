import React from 'react'
import styled from 'styled-components'

import { altoGreyColor } from 'shared/style/colors'

const Header = ({ className }) => (
  <div className={ className }>
    <div />
    <div />
    <div />
  </div>
)

export default styled(Header)`
  align-items: center;
  border-bottom: solid 1px ${altoGreyColor};
  display: flex;
  height: 1rem;
  padding: 0.3rem;

  > div {
    border-radius: 100%;
    background-color: ${altoGreyColor};
    height: 0.35rem;
    width: 0.35rem;
    margin-right: 0.195rem;
  }
`

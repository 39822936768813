import React       from 'react'
import { arrayOf } from 'prop-types'

import assetType       from 'shared/types/assetType'
import BaseGallery     from 'shared/components/Gallery/BaseGallery'
import AssetsContainer from 'shared/components/ui/AssetsContainer'
import Asset from 'shared/components/ui/Asset'

import DraggableAsset from './DraggableAsset'

const DraggableGallery = ({ assets }) => {
  return (
    <BaseGallery assets={ assets }>
      {({ controls }) => (
        <AssetsContainer>
          { assets.map((asset, key) => {
            const KindOfAsset = asset.marketable ? DraggableAsset : Asset
            const handleClick = () => { controls.open(key) }

            return <KindOfAsset onClick={ handleClick } key={ asset.uuid } asset={ asset } />
          }) }
        </AssetsContainer>
      )}
    </BaseGallery>
  )
}

DraggableGallery.propTypes = {
  assets: arrayOf(assetType).isRequired
}

export default DraggableGallery

import React from 'react'
import { object, func } from 'prop-types'
import { t } from 'ttag'
import { Field, Formik } from 'formik'

import validationSchema from 'shared/components/LeadForm/validationSchema'
import Modal from 'shared/components/ui/Modal'
import { ErrorMessage, Input, Label, Row } from 'shared/components/LeadForm/elements'
import PhoneCodeSelect from 'shared/components/PhoneCodeSelect'
import ProjectSelect from 'shared/components/ProjectSelect'
import StageSelect from 'shared/components/StageSelect'
import UnitsSelect from 'shared/components/UnitsSelect'
import Button from 'shared/components/ui/Button'

const emptyInitialValues = {
  fullName: '',
  email: '',
  phoneCode: '',
  phoneNumber: '',
  address: '',
  projectUuid: '',
  stageUuid: '',
  unitUuids: []
}

const LeadForm = ({ initialValues = {}, onHide, onSubmit }) => {
  const formKeys = Array.from(
    new Set([...Object.keys(initialValues), ...Object.keys(emptyInitialValues)])
  )

  const formValues = Object.fromEntries(
    formKeys.map(key => [key, initialValues[key] || emptyInitialValues[key]])
  )

  return (
    <Formik
      validationSchema={ validationSchema }
      initialValues={ formValues }
      onSubmit={ onSubmit }
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }) => (
        <form onSubmit={ handleSubmit }>
          <Modal.Body>
            <Row>
              <Label>{ t`Full name` }</Label>
              <Field
                name="fullName"
                as={ Input }
                isInvalid={ touched.fullName && errors.fullName }
              />
              { touched.fullName && errors.fullName ? (
                <ErrorMessage>{ errors.fullName }</ErrorMessage>
              ) : (
                null
              )}
            </Row>
            <Row>
              <Label>{ t`Email` }</Label>
              <Field
                name="email"
                as={ Input }
                isInvalid={ touched.email && errors.email }
              />
              { touched.email && errors.email ? (
                <ErrorMessage>{ errors.email }</ErrorMessage>
              ) : (
                null
              )}
            </Row>
            <Row>
              <Label>{ t`Phone` }</Label>
              <div className="d-flex">
                <Field
                  name="phoneCode"
                  as={ PhoneCodeSelect }
                  onChange={ value => setFieldValue('phoneCode', value) }
                  isInvalid={ touched.phoneCode && errors.phoneCode }
                />
                <Field
                  name="phoneNumber"
                  as={ Input }
                  isInvalid={ touched.phoneNumber && errors.phoneNumber }
                />
              </div>
              { (touched.phoneCode && errors.phoneCode)
              || (touched.phoneNumber && errors.phoneNumber) ? (
                  <ErrorMessage>{ errors.phoneCode || errors.phoneNumber }</ErrorMessage>
                ) : (
                  null
                )}
            </Row>
            <Row>
              <Label>{ t`Address` }</Label>
              <Field
                name="address"
                as={ Input }
                isInvalid={ touched.address && errors.address }
              />
              { touched.address && errors.address ? (
                <ErrorMessage>{ errors.address }</ErrorMessage>
              ) : (
                null
              )}
            </Row>
            <Row>
              <Label>{ t`Project` }</Label>
              <Field
                isClearable
                name="projectUuid"
                as={ ProjectSelect }
                onChange={ value => setFieldValue('projectUuid', value) }
                isInvalid={ touched.projectUuid && errors.projectUuid }
              />
              { touched.projectUuid && errors.projectUuid ? (
                <ErrorMessage>{ errors.projectUuid }</ErrorMessage>
              ) : (
                null
              )}

            </Row>
            <Row>
              <Label>{ t`Stage` } ({ t`optional` })</Label>
              <Field
                isClearable
                name="stageUuid"
                as={ StageSelect }
                projectUuid={ values.projectUuid }
                onChange={ value => setFieldValue('stageUuid', value) }
                isInvalid={ touched.stageUuid && errors.stageUuid }
              />
              { touched.stageUuid && errors.stageUuid ? (
                <ErrorMessage>{ errors.stageUuid }</ErrorMessage>
              ) : (
                null
              )}
            </Row>
            <Row>
              <Label>{ t`Units` } ({ t`optional` })</Label>
              <Field
                isClearable
                name="unitUuids"
                as={ UnitsSelect }
                stageUuid={ values.stageUuid }
                onChange={ value => setFieldValue('unitUuids', value) }
                isInvalid={ touched.unitUuids && errors.unitUuids }
              />
              { touched.unitUuids && errors.unitUuids ? (
                <ErrorMessage>{ errors.unitUuids }</ErrorMessage>
              ) : (
                null
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={ onHide }>{ t`Cancel` }</Button>
            <Button type="submit">{ t`Save` }</Button>
          </Modal.Footer>
        </form>
      )}
    </Formik>
  )
}

LeadForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
  onHide: func,
}

LeadForm.defaultProps = {
  initialValues: {},
  onHide: () => {}
}

export default LeadForm

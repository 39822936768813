import React from 'react'
import Select from 'react-select'
import { t } from 'ttag'
import { func, string } from 'prop-types'

import { inputBoxShadowValue } from 'shared/style/boxShadowValues'

import ValueContainer from './ValueContainer'

const styles ={
  container: provided => ({
    ...provided,
    boxShadow: inputBoxShadowValue,
    width: '7.2rem',
    minWidth: '7.2rem'
  }),
  control: provided => ({
    ...provided,
    fontSize: '0.875rem',
    fontWeight: 'normal'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: '1.5rem'
  }),
  option: provided => ({
    ...provided,
    paddingLeft: '1.5rem',
    fontSize: '0.8rem'
  }),
}

const options = [
  { value: 'include', label: t`Include` },
  { value: 'exclude', label: t`Exclude` },
]

const OperatorSelect = ({ onChange, selectedOperator }) => {
  return (
    <Select
      onChange={ onChange }
      options={ options }
      styles={ styles }
      value={ options.filter((option) => option.value === selectedOperator) }
      autosize={ false }
      components={{ ValueContainer }}
      operator={ selectedOperator }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
    />
  )

}

OperatorSelect.propTypes = {
  onChange: func.isRequired,
  selectedOperator: string.isRequired
}

export default OperatorSelect

import React from 'react'

import leadType from 'shared/types/leadType'

import Record from './Record'
import AdditionalInfo from './AdditionalInfo'

const Activity = ({ lead }) => (
  <>
    <div data-test-id='lead-activity-records'>
      {lead.activityLog.map(record => (
        <Record key={ record.uuid } { ...record } />
      ))}
    </div>

    <AdditionalInfo additionalInfo={ lead.additionalInfo } leadMessage={ lead.leadMessage }/>
  </>
)

Activity.propTypes = {
  lead: leadType.isRequired
}

export default Activity

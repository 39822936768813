import React from 'react'
import { oneOf, string, shape } from 'prop-types'

import { localDateTime } from 'shared/helpers/date'
import placeholderMapping from 'shared/constants/leadActivityType'

import { RecordTitle, RecordDetails, RecordNote, RecordNoteInfo } from './elements'

const Activity = props => {
  const user = props.involvedUser || props.author
  const title = props.title || placeholderMapping[props.activityType]

  return (
    <>
      <RecordNoteInfo>
        <RecordTitle>
          { title }
        </RecordTitle>
        <RecordDetails>
          <span>{ localDateTime(props.recordedAt) }</span>
          { user && (
            <span> &nbsp;&mdash;&nbsp; <i>{ user.name }</i></span>
          )}
        </RecordDetails>
        <RecordNote>
          { props.text }
        </RecordNote>
      </RecordNoteInfo>
    </>
  )
}

Activity.propTypes = {
  activityType: oneOf(Object.keys(placeholderMapping)).isRequired,
  recordedAt: string.isRequired,
  text: string.isRequired,
  author: shape({
    name: string.isRequired
  }),
  involvedUser: shape({
    name: string.isRequired
  }),
  title: string
}

Activity.defaultProps = {
  author: null,
  involvedUser: null,
  title: ''
}

export default Activity

import React from 'react'
import styled from 'styled-components'
import { string, func, number } from 'prop-types'

import ThriftyInput from 'shared/components/ui/ThriftyInput'

const SizeM2Input = ({ sizeM2, onUpdate, uuid, className, ...props }) => {
  const handleInput = sizeM2 => onUpdate(
    { uuid, attributes: { sizeM2: parseFloat(sizeM2) } }
  )

  return (
    <ThriftyInput
      className={ className }
      type='number'
      min={ 0 }
      value={ sizeM2 }
      onUpdate={ handleInput }
      data-test-id='unit-size-m2-input'
      { ...props }
    />
  )
}

SizeM2Input.propTypes = {
  onUpdate: func.isRequired,
  uuid: string.isRequired,
  sizeM2: number
}

SizeM2Input.defaultProps = {
  sizeM2: 0
}

export default styled(SizeM2Input)`
  box-shadow: none !important;
  height: 2.375rem
  font-size: .75rem !important
`

import React        from 'react'
import { useQuery } from '@apollo/react-hooks'
import { t }        from 'ttag'

import adsQuery         from 'graphql/queries/ads'
import useUrlParams     from 'shared/hooks/useUrlParams'
import { ensureNumber } from 'shared/helpers/number'
import Card             from 'shared/components/ui/Card'
import AdsList          from 'shared/components/AdsList/List'

const AdsIndex = ({ className }) => {
  const [urlParams, setUrlParams] = useUrlParams()
  const { sortBy, sortOrder } = urlParams
  const page = ensureNumber(urlParams.page, 1)
  const perPage = 15

  const { data } = useQuery(adsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      query: { page, sortOrder, sortBy, perPage }
    }
  })
  const ads = data ? data.ads : { items: [], total: 0 }

  return (
    <Card
      className={ className }
      header={ t`Ads` }
      dataTestId="ads-panel"
    >
      <AdsList
        data={ ads }
        params={ urlParams }
        onTableChange={ setUrlParams }
        perPage={ perPage }
        data-test-id={ 'ads-list' }
      />
    </Card>
  )
}

export default AdsIndex

import { cloneDeep } from 'lodash'

import campaignQuery from 'graphql/queries/campaignMedia'

const updateCampaignAssetsCache = ({ store, assetableUuid, assetAssignment }) => {
  const query = { query: campaignQuery, variables: { uuid: assetableUuid } }
  const { campaign } = cloneDeep(store.readQuery(query))

  campaign.assetAssignments.push(assetAssignment)

  store.writeQuery({ ...query, data: { campaign } })
}

export default updateCampaignAssetsCache

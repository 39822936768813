import React, { useState } from 'react'
import { t }               from 'ttag'
import { toast }           from 'react-toastify'
import { useApolloClient } from '@apollo/react-hooks'
import { object }          from 'prop-types'

import redirectTo      from 'shared/helpers/redirectTo'
import ValidatableTabs from 'shared/components/ValidatableTabs'

import tabs           from './BuildingDetails/TabBar/tabs'
import updateBuilding from './BuildingDetails/Details/updateBuilding'
import buildValidator from './validations/buildValidator'

const BuildingDetails = ({ building }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [errors, setErrors] = useState([])

  const client = useApolloClient()
  const CurrentComponent = tabs[activeTab].component

  const validate = buildValidator({ building, setErrors, validatorNumber: activeTab })
  const onFinalize = () => (
    updateBuilding(client, building, { state: 'complete' }).
      then(() => {
        redirectTo(`/projects/${building.projectUuid}`)
        toast.success(t`Building successfully created.`)
      })
  )

  return (
    <ValidatableTabs
      tabs={ tabs }
      title={ t`Building Information` }
      collapsible={ false }
      validate={ validate }
      onFinalize={ onFinalize }
      activeTab={ activeTab }
      setActiveTab={ setActiveTab }
      loadingTabContent={ false }
    >
      <CurrentComponent
        building={ building }
        errors={ errors }
        validate={ validate }
      />
    </ValidatableTabs>
  )
}

BuildingDetails.propTypes = {
  building: object.isRequired
}

export default BuildingDetails

import React, { Component } from 'react'
import { array } from 'prop-types'
import { Button } from 'react-bootstrap'

import Card from 'shared/components/ui/Card'
import sortAssets from 'shared/components/Product/Images/sortAssets'
import ImagesGallery from 'shared/components/Product/Images/ImagesGallery'

const IMAGES_PER_ROW = 6

class Images extends Component {
  static propTypes = {
    assets: array.isRequired
  }

  state = {
    isClosed: true
  }

  toggle = () => {
    this.setState({ isClosed: !this.state.isClosed })
  }

  render() {
    const { assets } = this.props
    const { isClosed } = this.state
    const isCollapsible = assets.length > IMAGES_PER_ROW

    return (
      <Card
        collapsible
        header="Images"
        footer={
          isCollapsible && (
            <Button onClick={ this.toggle }>
              { isClosed ? 'Show all' : 'Hide' }
            </Button>
          )
        }
      >
        {
          assets.length
            ? (
              <ImagesGallery
                assets={ sortAssets(assets) }
                isClosed={ isCollapsible && isClosed }
              />
            ) : (
              <span>
                No images available yet due to internal processing.
                Please check back later.
              </span>
            )
        }
      </Card>
    )
  }
}

export default Images

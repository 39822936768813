import React from 'react'
import { t } from 'ttag'

import UserRoleNavLink from 'routes/UserRoleNavLink'
import { logout } from 'shared/helpers/auth'

import UserMenuAction from './UserMenuAction'

const UserMenuActions = () => {
  return (
    <>
      <UserMenuAction>
        <UserRoleNavLink
          to="/profile"
          data-test-id='profile-link'
        >
          { t`Profile` }
        </UserRoleNavLink>
      </UserMenuAction>

      <UserMenuAction onClick={ logout }>
        { t`Sign out` }
      </UserMenuAction>
    </>
  )
}

export default UserMenuActions

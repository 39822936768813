import * as Yup from 'yup'
import { t } from 'ttag'

export default Yup.object().shape({
  name: Yup.string()
    .required(t`Please fill name`),

  email: Yup.string()
    .email(t`'Seems like it\'s a wrong email'`)
    .required(t`Please fill an email`),

  phoneCode: Yup.number()
    .required(t`Please fill a phone country code`)
    .nullable(),

  phoneNumber: Yup.number()
    .required(t`Please fill a phone number`)
    .nullable(),

  settings: Yup.object().shape({
    defaultLanguage: Yup.string()
      .required(t`Please fill a language`)
      .nullable(),

    timezone: Yup.string()
      .required(t`Please fill a timezone`)
      .nullable()
  }),
})

import gql from 'graphql-tag'

export default gql`
  mutation createPropertyCampaign(
    $attributes: CampaignInputType!
    $goals: CampaignGoalsInputType
    $blueprintTemplateUuid: ID
  ) {
    createPropertyCampaign(
      attributes: $attributes,
      goals: $goals,
      blueprintTemplateUuid: $blueprintTemplateUuid
    ) {
      errors
      campaign {
        uuid
      }
    }
  }
`

export default {
  chart: {
    height: 150,
    width: 500,
    marginRight: 20,
    yAxis: {
      gridLineWidth: 0
    },
    xAxis: {
      gridLineWidth: 1
    }
  },
  plotOptions: {
    spline: {
      marker: {
        enabled: false
      }
    },
    series: {
      marker: {
        radius: 1
      }
    }
  }
}

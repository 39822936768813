import React       from 'react'
import { func, bool }    from 'prop-types'
import { isEmpty } from 'lodash'

import landingPageType from 'shared/types/landingPageType'
import ThriftyInput    from 'shared/components/ui/ThriftyInput'

import UrlsSelect from './UrlsSelect'

const UrlField = ({ landingPage, onUpdate, isInvalid, setTouched }) => {
  const placeholder = 'https://example.com'

  const hasUrls = !landingPage.subtype && !isEmpty(landingPage.urlsBasedOnCompanyDomains)

  if (hasUrls) {
    return(
      <UrlsSelect
        landingPage={ landingPage }
        onChange={ url => onUpdate(url) }
        data-test-id="urls-select"
      />
    )
  } else {
    return(
      <ThriftyInput
        disabled={ !landingPage.custom }
        value={ landingPage.url }
        onUpdate={ url => onUpdate(url) }
        placeholder={ placeholder }
        data-test-id="url-input"
        isInvalid={ isInvalid }
        setTouched={ setTouched }
      />
    )
  }
}

UrlField.propTypes = {
  landingPage: landingPageType.isRequired,
  onUpdate: func.isRequired,
  isInvalid: bool,
  setTouched: func
}

export default UrlField

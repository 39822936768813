import React, { useState, useEffect } from 'react'
import { string } from 'prop-types'
import { useMutation } from 'react-apollo'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { toast } from 'react-toastify'

import Header from 'shared/components/ui/Card/StaticHeader'
import LinkButton from 'shared/components/ui/LinkButton'
import discardAllChangesetsMutation from 'graphql/mutations/campaign/discardAllChangesets'
import changesetQuery from 'graphql/queries/campaigns/changeset'
import campaignQuery from 'graphql/queries/campaign'
import Card  from 'shared/components/ui/Card'
import Button from 'shared/components/ui/Button'
import { userRolePath } from 'routes/AppRedirect'

import LoadingBlock from '../ui/LoadingBlock'

import PublishingTooltipOverlay from './PublishingTooltipOverlay'
import CampaignChangelogsTable from './CampaignChangelogsTable'

const CampaignChangelogs = ({ uuid }) => {
  const [publishingMode, setPublishingMode] = useState(true)
  const [discardAllDisabled, setDiscardAllDisabled] = useState(false)
  const [changesets, setChangesets] = useState([])
  
  const {
    data: { campaign } = {},
    loading: { campaignLoading },
  } = useQuery(campaignQuery, { variables: { uuid } })

  const {
    data: { campaignChangeset } = [],
    loading
  } = useQuery(changesetQuery, {
    fetchPolicy: 'network-only',
    variables: {
      campaignUuid: uuid
    }
  })

  useEffect(() => {
    if (campaignChangeset) {
      setChangesets(campaignChangeset)
    }
  }, [campaignChangeset])

  useEffect(() => (setPublishingMode(!!campaign?.publishStartedAt)), [campaign])

  const [discardAllChangesets] = useMutation(discardAllChangesetsMutation)

  const handleDiscardAll = () => {
    if (!publishingMode && confirm(t`Are you sure you want to discard all changes?`)) {
      setDiscardAllDisabled(true)
      const toastLoading = toast.info(t`Discarding changes...`)
      discardAllChangesets({ variables: { uuid } })
        .then(() => {
          toast.update(toastLoading, {
            type: 'success',
            render: t`The changes have been discarded.`
          })

          setChangesets([])
        })
        .catch(() => {
          toast.update(toastLoading, {
            type: 'error',
            render: t`An error occurred while discarding the change.`
          })
          setDiscardAllDisabled(false)
        })
    }
  }

  const hasAnyChangesets = (changesets) => {
    if (!changesets) {
      return false
    }

    return !!(changesets).length
  }

  const isDiscardAllDisabled = () => {
    return campaignLoading
      || publishingMode
      || !hasAnyChangesets(changesets)
      || discardAllDisabled
  }

  return (
    <Card header={ (
      <div className="d-flex justify-content-between">
        <Header className="align-self-center">
          { t`Campaign information changes` }
        </Header>
        <LinkButton href={ userRolePath(`/campaigns/${uuid}/edit`) }>
          <span>{ t`Back to campaign` }</span>
        </LinkButton>
      </div>
    ) }>
      { (loading || !campaignChangeset) && (<LoadingBlock/>) }
      { !loading
        && (<CampaignChangelogsTable
          changesets={ changesets }
          setChangesets={ setChangesets }
          publishingMode={ publishingMode }
          setDiscardAllDisabled={ setDiscardAllDisabled }
          discardAllDisabled={ discardAllDisabled }/>)
      }
      <Button
        data-test-id='discard-changesets'
        variant='secondary'
        onClick={ handleDiscardAll }
        className="float-right"
        disabled={ isDiscardAllDisabled() }>
        <PublishingTooltipOverlay publishingMode={ publishingMode }>
          <span>{ t`Discard all` }</span>
        </PublishingTooltipOverlay>
      </Button>
    </Card>
  )
}

CampaignChangelogs.propTypes = {
  uuid: string.isRequired
}

export default CampaignChangelogs

import React    from 'react'
import styled   from 'styled-components'
import { node } from 'prop-types'

import { cardBorderValue } from 'shared/style/placeholders'

const Footer = ({ className, children }) => {
  return (
    <section className={ className }>
      { children }
    </section>
  )
}

Footer.propTypes = {
  children: node.isRequired
}

export default styled(Footer)`
  display: flex;
  padding-bottom: .2rem;
  padding-top: .2rem;

  & > * {
    flex-grow: 1;
    flex-basis: 0;
    border-left: ${cardBorderValue}
    padding-left: 1.625rem
  }

  & > *:first-child {
    border-left: none;
    padding-left: 0
  }
`


import React from 'react'
import styled from 'styled-components'
import { shape, array } from 'prop-types'

import useTabBar from 'shared/hooks/useTabBar'

import tabs, { originalTabs } from './TabBar/tabs'

const StageDetails = ({ product, ...props }) => {
  const isSnapshotEnabled = product.company.enabledFeatures.includes('snapshot')
  const { tabBar, CurrentComponent } = useTabBar(isSnapshotEnabled ? tabs : originalTabs)

  return (
    <Container>
      { tabBar }

      <CurrentComponent product={ product } { ...props } />
    </Container>
  )
}

const Container = styled.div`
  margin-top: -1.1rem;
  margin-left: -1.45rem;
  margin-right: -1.45rem;
`

StageDetails.propTypes = {
  product: shape({
    stakeholders: array.isRequired
  }).isRequired
}

export default StageDetails

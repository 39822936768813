import React from 'react'
import { t } from 'ttag'
import { string } from 'prop-types'
import { useQuery } from 'react-apollo'
import { get } from 'lodash'

import docTypes from 'shared/constants/vitec/docTypes'
import signStatuses from 'shared/constants/vitec/signStatuses'
import Table from 'shared/components/ui/Table'
import { dateTime } from 'shared/components/ui/Table/formatters'

import query from './documentsQuery.js'

const downloadLinkFormatter = (_cell, item, _rowIndex) => {
  if (!item.url) return null

  return (
    <a
      href={ item.url }
      target='_blank'
      rel='noopener noreferrer'
      data-test-id='unit-document-download-link'
    >{ t`Download` }</a>
  )
}

const DocumentsList = ({ unitUuid }) => {
  const { data } = useQuery(query, { variables: { unitUuid } })
  const documents = get(data, 'unitVitecDocuments') || []

  const columns = [
    {
      text: '',
      dataField: 'uuid',
      hidden: true
    },
    {
      dataField: 'docType',
      text: t`Type`,
      formatter: (_cell, item) => docTypes[item.docType]
    },
    {
      dataField: 'head',
      text: t`Description`
    },
    {
      dataField: 'extension',
      text: t`Extension`
    },
    {
      dataField: 'lastChanged',
      text: t`Last changed`,
      formatter: dateTime
    },
    {
      dataField: 'signStatus',
      text: t`Status`,
      formatter: (_cell, item) => signStatuses[item.signStatus]
    },
    {
      dataField: 'approvedBy',
      text: t`Approved by`
    },
    {
      dataField: 'approvalDate',
      text: t`Approved at`,
      formatter: dateTime
    },
    {
      dataField: 'url',
      text: '',
      formatter: downloadLinkFormatter
    }
  ]

  const tableOptions = {
    keyField: 'uuid',
    data: documents,
    columns: columns,
    bordered: false,
    id: 'unit-documents-table',
    noDataIndication: () => t`Loading documents...`
  }

  return (
    <Table tableOptions={ tableOptions } />
  )
}

DocumentsList.propTypes = {
  unitUuid: string.isRequired
}

export default DocumentsList

import React from 'react'
import { t } from 'ttag'
import { object } from 'prop-types'

import Body from './Body'
import Section from './Section'

const CampaignPackage = ({ campaign }) => {
  return (
    <Section title={ t`Campaign Package` }>
      <Body
        title={ campaign.specificationTemplate.name }
        onCLick={ () => {} }
      />
    </Section>
  )
}

CampaignPackage.propTypes = {
  campaign: object.isRequired
}

export default CampaignPackage

import { t } from 'ttag'

export const facilityValues = [
  ['elevator', t`Elevator`],
  ['ev_charging', t`EV Charging`],
  ['roof_terrace', t`Roof Terrace`],
  ['balcony', t`Balcony`],
  ['parking', t`Parking`],
  ['boat_parking', t`Boat parking`],
  ['gym', t`Gym`],
  ['bike_parking', t`Bike parking`],
  ['fireplace', t`Fireplace`],
  ['storage_room', t`Storage room`],
  ['ground_terrace', t`Ground Terrace`],
  ['pets_allowed', t`Pets allowed`],
  ['caretaker', t`Caretaker`],
  ['wheelchair_accessible', t`Wheelchair accessible`],
  ['pool', t`Pool`],
  ['keyless_entry', t`Keyless entry`]
]

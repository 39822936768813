import { gql } from 'apollo-boost'

import lead from 'graphql/fragments/lead'

const prepareLeadsQuery = (leadFields) =>
  gql`
    query Leads(
      $query: PageInput!,
      $projectUuid: ID,
      $campaignUuid: ID
    ) {
      leads(
        query: $query,
        projectUuid: $projectUuid,
        campaignUuid: $campaignUuid
      ) {
        total
        items {
          ${leadFields}
        }
      }
    }
    ${lead}
  `

export default prepareLeadsQuery

import styled from 'styled-components'

import Card from 'shared/components/ui/Card'

export const AudienceContainer = styled(Card)`
  border-bottom: none;
  border-left: none;
  border-right: none;

  && {
    box-shadow: none;
  }

  .card-header {
    border-bottom: none;
    padding: 0;
    position: relative;

    span {
      position: absolute;
      right: 1rem;
    }
  }

  .invalid-feedback {
    font-size: 0.6rem;
  }
`

import React from 'react'
import { t } from 'ttag'

import ProjectForm from 'shared/components/ProjectForm'

const NewProject = () => {
  return (
    <>
      <h4>{ t`Create new project` }</h4>
      <ProjectForm />
    </>
  )
}

export default NewProject

import React, { useState } from 'react'
import styled              from 'styled-components'
import { t }               from 'ttag'
import { toast }           from 'react-toastify'
import { useMutation }     from 'react-apollo'
import { arrayOf, shape, string, func } from 'prop-types'

import { currentLocale }       from 'locale'
import Card                    from 'shared/components/ui/Card'
import attachPostMediaMutation from 'graphql/mutations/products/news/attachMedia'
import createNewsPostMutation  from 'graphql/mutations/products/news/createPost'
import updateNewsPostMutation  from 'graphql/mutations/products/news/updatePost'
import deleteNewsPostMutation  from 'graphql/mutations/products/news/deletePost'
import refreshNewsPostMutation from 'graphql/mutations/products/news/refreshPost'
import removeAssetMutation     from 'graphql/mutations/assetable/removeAsset'
import publishNewsPostMutation from 'graphql/mutations/products/news/publishPost'
import setDeletedNewsPostMedia from 'graphql/mutations/products/news/mediums/setDeleted'

import PostFormModal from '../PostFormModal'

import List from './List'

const NewsList = ({ className, newsPosts, newsPageBaseUrl, productUuid, onDelete, onRefetch }) => {
  const [editPost, setEditPost] = useState(null)
  const [editMode, setEditMode] = useState(null)
  const [attachAsset] = useMutation(attachPostMediaMutation)
  const [createNewsPost] = useMutation(createNewsPostMutation)
  const [updatePost] = useMutation(updateNewsPostMutation)
  const [deletePost] = useMutation(deleteNewsPostMutation)
  const [refreshPost] = useMutation(refreshNewsPostMutation)
  const [removeAsset] = useMutation(removeAssetMutation)
  const [publishPost] = useMutation(publishNewsPostMutation)
  const [setDeletedPostMedia] = useMutation(setDeletedNewsPostMedia)

  const handleHide = async (deleteable = true) => {
    if (editMode === 'create' && deleteable) {
      await deletePost(
        { variables: { uuid: editPost.uuid } }
      )
    }
    if (editMode === 'update' && deleteable) {
      const result = await refreshPost(
        { variables: { uuid: editPost.uuid } }
      )
      editPost.media = result.data.refreshNewsPost.post.media
    }
    setEditMode(null)
    setEditPost(null)
    onRefetch()
  }

  const handleSubmit = async (input, fullSave, publish) => {
    const updatedInput = fullSave ? updateInputWithDraftValues(input) : input
    const result = await updatePost({
      variables: {
        attributes: updatedInput, uuid: editPost.uuid, publish: publish, fullSave: fullSave
      }
    })
    if (fullSave) {
      const newsPostElement = newsPosts.find((element) => element.uuid === editPost.uuid)
      const newsPostElementIndex = newsPosts.indexOf(newsPostElement)
      if (newsPostElementIndex === -1) {
        const post = { ...result.data.updateNewsPost.post, ...updatedInput }
        newsPosts.push(post)
      } else {
        onRefetch()
      }
    }
  }

  const updateInputWithDraftValues = (input) => {
    return {
      newsType: input.newsType,
      title: input.titleDraft,
      description: input.descriptionDraft,
      callToActionText: input.callToActionTextDraft,
      callToActionDestinationUrl: input.callToActionDestinationUrlDraft
    }
  }

  const onDeletePost = async (post) => {
    if (confirm(t`Are you sure?`)) {
      await deletePost(
        { variables: { uuid: post.uuid } }
      )
      onDelete(post.uuid)
    }
  }

  const createDraftPost = async () => {
    const draftInput = {
      newsType: 'general',
      titleDraft: '',
      descriptionDraft: '',
      callToActionTextDraft: '',
      callToActionDestinationUrlDraft: ''
    }
    const result = await createNewsPost(
      {
        variables: {
          attributes:  draftInput,
          productUuid: productUuid
        }
      }
    )
    setEditMode('create')
    setEditPost(result.data.createNewsPost.post)
  }

  const onEditPost = async (post) => {
    const draftValues = {
      titleDraft: post.title,
      descriptionDraft: post.description,
      callToActionTextDraft: post.callToActionText,
      callToActionDestinationUrlDraft: post.callToActionDestinationUrl,
    }
    const updatedPost = { ...post, ...draftValues }
    const result = await refreshPost(
      { variables: { uuid: post.uuid } }
    )
    updatedPost.media = result.data.refreshNewsPost.post.media
    setEditMode('update')
    setEditPost(updatedPost)
  }

  const onPublish = async (post, value) => {
    await publishPost(
      { variables: { uuid: post.uuid, publish: value } }
    )
    onRefetch()
  }

  const redirectToProjectNews = () => {
    const path = currentLocale.startsWith('nb') ? 'prosjekter' : 'listings'
    const redirectUrl = `${newsPageBaseUrl}/${path}/${productUuid}`
    window.open(redirectUrl, '_blank')
  }

  const renderPublishedPosts = () => {
    const elements = newsPosts.filter(element => ( element.uuid && element.state === 'published' ))
    if (elements.length > 0) {
      return (
        <div className='posts-list' data-test-id='published-posts'>
          <h2>
            { t`Previously published` }
            <span className="separator">|</span>
            <a onClick={ redirectToProjectNews }>{ t`Go to news page` }</a>
          </h2>
          <List
            state={ 'published' }
            posts={ elements }
            onEditPost={ onEditPost }
            onDeletePost={ onDeletePost }
            onPublish={ onPublish }
          />
        </div>
      )
    }
  }

  const renderUnpublishedPosts = () => {
    const elements = newsPosts.filter(element => ( element.uuid && element.state === 'saved' ))
    if (elements.length > 0) {
      return (
        <div className='posts-list' data-test-id='saved-posts'>
          <h2>{ t`Drafts` }</h2>
          <List
            state={ 'saved' }
            posts={ elements }
            onEditPost={ onEditPost }
            onDeletePost={ onDeletePost }
            onPublish={ onPublish }
          />
        </div>
      )
    }
  }

  const assetUpload = (uploadedAssets) => {
    const uploadedMedia = []
    uploadedAssets.forEach(({ signedBlobId, mediaType }) => {
      attachAsset({
        variables: {
          mediaType,
          blobId: signedBlobId,
          uuid:   editPost.uuid
        },
        update: (_cache, response) => {
          uploadedMedia.push(response.data.attachPostMedia.media)
          if (uploadedAssets.length === uploadedMedia.length) {
            editPost.media.push(...uploadedMedia)
          }
        }
      })
    })
  }

  const onAssetDelete = (asset) => {
    const medias = editPost.media
    const deletedMedia = medias.find((media) => {
      return media.asset.uuid === asset.uuid
    })

    if (editPost.state === 'draft') {
      removeAsset(
        { variables: { uuid: asset.uuid, totalRemove: true } }
      )
    } else {
      if (medias.length === 1) {
        toast.error(t`Last image can't be deleted`)
        return
      }

      if (deletedMedia.state === 'draft') {
        removeAsset(
          { variables: { uuid: asset.uuid, totalRemove: true } }
        )
      } else {
        setDeletedPostMedia(
          { variables: { uuid: deletedMedia.uuid } }
        )
      }
    }

    const indexOfDeletedMedia = medias.indexOf(deletedMedia)
    medias.splice(indexOfDeletedMedia, 1)
    editPost.media = medias
  }

  return (
    <Card
      className={ `project-news-edit-form ${className}` }
      header={ t`Edit project news` }
    >
      <button type='button' className='create-button' onClick={ () => createDraftPost() }>
        { t`Create news` }
      </button>
      { renderPublishedPosts() }
      { renderUnpublishedPosts() }
      <PostFormModal
        post={ editPost }
        title={ editMode === 'create' ? t`Create news` : t`Update` }
        show={ editPost !== null }
        state={ editPost?.state }
        onHide={ handleHide }
        onSubmit={ handleSubmit }
        assetUpload={ assetUpload }
        handleAssetDelete={ onAssetDelete }
      />
    </Card>
  )
}

NewsList.propTypes = {
  newsPageBaseUrl: string.isRequired,
  newsPosts: arrayOf(shape({
    uuid: string.isRequired
  })).isRequired,
  onDelete: func.isRequired,
  onRefetch: func.isRequired,
  productUuid: string.isRequired
}

export default styled(NewsList)`
  .create-button {
    background: #648FB6;
    border: 1px solid rgba(100, 143, 182, 0.55731);
    border-radius: .25rem;
    color: #fff;
    padding: .375rem;
    font-size: .875rem;
    margin-bottom: 2.75rem;
  }

  .posts-list {
    margin-bottom: 5rem;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 2.75rem;

      .separator {
        margin: 0 0.5em
      }

      a {
        font-size: 0.75em;
        font-weight: normal;
        cursor: pointer;
        &:hover {
          text-decoration: underline
        }
      }
    }
  }
`

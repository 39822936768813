import React            from 'react'
import { useQuery }     from '@apollo/react-hooks'
import { get, isEmpty } from 'lodash'
import { number }       from 'prop-types'

import projectType from 'shared/types/projectType'

import Card    from '../Card'
import useTabs from '../useTabs'

import extraUnitsDetailsQuery      from './extraUnitsDetailsQuery'
import StagesExtraUnitsDetailsInfo from './StagesExtraUnitsDetailsInfo'

const StagesExtraUnitsDetails = ({ project, stageTab }) => {
  if (isEmpty(project.enabledExtraUnits)) {
    return ''
  }

  const { data } = useQuery(
    extraUnitsDetailsQuery,
    {
      variables: {
        scopedTo: (stageTab === 0 ? 'Project' : 'Stage'),
        uuid:     (stageTab === 0 ? project.uuid : project.completeStages[stageTab - 1].uuid)
      }
    }
  )
  const extraUnitsDetails = get(data, 'projectExtraUnitsDetailsAnalytics.data', null)

  const { tabs } = useTabs(project)

  return (
    <div data-test-id='stage-extra-units-details-card'>
      {extraUnitsDetails && (
        <Card>
          <StagesExtraUnitsDetailsInfo
            tabs={ tabs }
            activeTab={ stageTab }
            extraUnitsDetails={ extraUnitsDetails }
            project={ project }
          />
        </Card>
      )}
    </div>
  )
}

StagesExtraUnitsDetails.propTypes = {
  project:  projectType.isRequired,
  stageTab: number.isRequired
}

export default StagesExtraUnitsDetails

import gql from 'graphql-tag'

import agent from 'graphql/fragments/agent'

export default gql`
  mutation removeAgent(
    $agentUuid: ID!
    $projectUuid: ID!
  ) {
    removeAgent(
      agentUuid: $agentUuid
      projectUuid: $projectUuid
    ) {
      agent {
        ... Agent
      }
    }
  }
  ${agent}
`

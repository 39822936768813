import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'
import { func, array, object } from 'prop-types'

import { combineErrors } from 'shared/helpers/inputValidation'
import Unit from 'shared/components/Unit'

const UnitsTable = ({
  className,
  onUpdate,
  onDelete,
  handleUploadFloorPlanClick,
  building,
  errors
}) => {
  const combinedErrorsOnUnit = building.units.reduce((acc, unit) => {
    acc[unit.uuid] = (field) => combineErrors({ errors, uuid: unit.uuid, field })
    return acc
  }, {})

  const renderUnits = () => {
    return building.units.map((unit) =>
      <Unit
        building={ building }
        unit={ unit }
        onUpdate={ onUpdate }
        onDelete={ onDelete }
        handleUploadFloorPlanClick={ handleUploadFloorPlanClick }
        key={ unit.uuid }
        errors={ combinedErrorsOnUnit }
      />
    )
  }

  return (
    <div className={ className }>
      <div className='units-table-header'>
        <p />
        <p>{ t`ID` }</p>
        <p>{ t`Bedrooms` }</p>
        <p style={{ flex: 'initial', width: '9.5rem' }}>{ t`Unit type` }</p>
        <p>{ t`Size (m2)` }</p>
        <p>{ t`Total price (${ building.currency })` }</p>
        <p style={{ flex: 'initial', width: '6.5rem' }}>{ t`Floor` }</p>
        <p style={{ flex: 'initial', width: '6rem' }}>{ t`Floor plan` }</p>
        <p style={{ flex: 'initial', width: '9rem' }}>{ t`Status` }</p>
        <p style={{ flex: 'initial', width: '9rem' }}>{ t`Stage` }</p>
        <p />
      </div>
      {renderUnits()}
    </div>
  )
}

UnitsTable.propTypes = {
  building: object.isRequired,
  handleUploadFloorPlanClick: func.isRequired,
  onDelete: func.isRequired,
  onUpdate: func.isRequired,
  errors: array
}

UnitsTable.defaultProps = {
  errors: []
}

export default styled(UnitsTable)`
  margin-bottom: 1rem;

  .units-table-header {
    display: flex;
    padding: .5rem 1rem;
    background: #f6fafc;

    p {
      flex: 1;
      margin: 0;
      padding: 0 .25rem;
      font-size: .875rem;
      color: #697386;

      &:nth-of-type(1), &:nth-last-of-type(1) {
        flex: initial;
        width: 3rem;
      }
    }
  }
`

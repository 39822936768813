import React from 'react'
import styled from 'styled-components'
import { func, number, string, shape } from 'prop-types'

import { formInputShadow } from 'shared/style/placeholders'

const Handle = ({ className, handle: { id, percent }, getHandleProps })=> {
  const styles = { left: `${percent}%` }

  return <div className={ className } style={ styles } { ...getHandleProps(id) } />
}

Handle.propTypes = {
  getHandleProps: func.isRequired,
  handle: shape({
    id: string.isRequired,
    percent: number.isRequired
  }).isRequired
}

export default styled(Handle)`
  ${formInputShadow}
  background-color: #2578ce;
  border-radius: 50%;
  border: 0.125rem solid white;
  cursor: pointer;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: 0.6rem;
  position: absolute;
  text-align: center;
  width: 1rem;
  z-index: 2
`

import React                         from 'react'
import { t }                         from 'ttag'
import { string, bool }              from 'prop-types'
import { set, camelCase, cloneDeep } from 'lodash'
import { Checkbox }                  from 'pretty-checkbox-react'
import { useMutation }               from 'react-apollo'

import { getUser, isUserAdmin }    from 'shared/helpers/auth'
import adminQuery                  from 'graphql/queries/users/admin'
import userQuery                   from 'graphql/queries/users/user'
import updateNotificationsMutation from 'graphql/mutations/users/updateUserNotifications'

const NotificationRow = (props) => {
  const { uuid, label, eventKey, emailEnabled } = props
  const [runUpdateNotifications] = useMutation(updateNotificationsMutation)

  const updateNotifications = (event, destination, enabled) => {
    runUpdateNotifications({
      variables: {
        event,
        enabled,
        destination,
      },
      update: (cache) => {
        const query = { query: isUserAdmin() ? adminQuery : userQuery, variables: { uuid } }
        const data = cloneDeep(cache.readQuery(query))
        const user = getUser()

        set(
          data[user.role],
          `settings.notifications.${destination}.${camelCase(eventKey)}`,
          enabled
        )

        cache.writeQuery({ ...query, data })
      }
    })
  }

  const updateEmailNotifications = (e) => {
    updateNotifications(eventKey, 'email', e.target.checked)
  }

  return (
    <tr>
      <td>
        <h6 className="setting-name">{ label }</h6>
        <p className="text-muted">{ t`Receive a notification.` }</p>
      </td>
      <td>
        <Checkbox
          checked={ emailEnabled }
          onChange={ updateEmailNotifications }
        />
      </td>
    </tr>
  )
}

NotificationRow.propTypes = {
  eventKey: string.isRequired,
  label: string.isRequired,
  uuid: string.isRequired,
  emailEnabled: bool,
}

NotificationRow.defaultProps = {
  emailEnabled: false,
}

export default NotificationRow

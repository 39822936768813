import React from 'react'
import { t } from 'ttag'
import { object } from 'prop-types'
import { toast } from 'react-toastify'
import { useMutation } from 'react-apollo'

import requestEstimateMutation
  from 'graphql/mutations/campaign/specifications/requestEstimate'

import UpdateSectionButton from '../UpdateSectionButton'

const UpdateEstimatesButton = ({ campaign }) => {
  const [requestEstimate, { loading }] = useMutation(
    requestEstimateMutation,
    { variables: { campaignUuid: campaign.uuid } }
  )

  const requestEstimateWithNotification = () => {
    requestEstimate().then(({ data: { requestEstimate } }) => {
      if (requestEstimate.errors.length) {
        toast.error(
          <>
            { requestEstimate.errors }
          </>
        )
      }
    })
  }

  return (
    <UpdateSectionButton
      campaign={ campaign }
      loading={ loading }
      caption={ t`Request estimate based on spending` }
      onClick={ requestEstimateWithNotification }
    />
  )
}

UpdateEstimatesButton.propTypes = {
  campaign: object.isRequired
}

export default UpdateEstimatesButton

import * as Yup from 'yup'
import { t } from 'ttag'

export default Yup.object().
  shape({
    address: Yup.string().required(t`Please fill an address.`).nullable(),
    countryCode: Yup.string().required(t`Please select a country.`).nullable(),
    zipCode: Yup.string().required(t`Please select a zip code.`).nullable(),
    latitude: Yup.string().required(t`Please choose a location.`).nullable()
  }).
  required(t`Please fill in location data.`).
  nullable()

import gql from 'graphql-tag'

export default gql`
  mutation addSpendingChannel($campaignUuid: ID!) {
    addSpendingChannel(campaignUuid: $campaignUuid) {
      specification {
        uuid
        channels
      }
    }
  }
`

import { get } from 'lodash'
import { t } from 'ttag'
import MapboxCircle from 'mapbox-gl-circle'
import bbox from '@turf/bbox'

const COUNTRY_RADIUS = 250000
const REGION_RADIUS = 25000

const PLACE_TYPE_MAPPING = {
  country:      t`Country`,
  region:       t`Region`,
  municipality: t`Municipality`,
  city:         t`City`,
  district:     t`District`
}

export const mapFitBounds = (map, bounds) => {
  map.fitBounds(bounds, { padding: 20, duration: 200, linear: true })
}

export const buildCircle = (location) => (
  new MapboxCircle(
    { lat: location.latitude || 0, lng: location.longitude || 0 },
    location.locationType === 'country' ? COUNTRY_RADIUS : REGION_RADIUS
  )
)

export const fitCircleBounds = (map, location) => {
  const bounds = buildCircle(location).getBounds()
  mapFitBounds(map, [bounds.sw, bounds.ne])
}

export const fitAllLocations = (map, locations) => {
  if (!locations.length) return

  const circles = locations.map(location => buildCircle(location))
  mapFitBounds(
    map,
    bbox(
      {
        type: 'FeatureCollection',
        features: circles.map((circle) => circle._circle)
      }
    )
  )
}

export const getPlaceTypeLabel = (placeType) => (
  get(PLACE_TYPE_MAPPING, placeType, placeType)
)


import updateAssetableAssetAssignmentsCache
  from 'shared/helpers/updateAssetableAssetAssignmentCache'
import createAssetAssignmentMutation from 'graphql/mutations/assetable/createAssetAssignment'

import assetAssignmentFragment from './buildingAssetAssignmentFragment'

export default function createAssetAssignment(client, buildingUuid) {
  return function createAssetAssignment(asset) {
    client.mutate({
      mutation: createAssetAssignmentMutation,
      variables: {
        assetableUuid: buildingUuid,
        assetableType: 'Building',
        assetUuid:     asset.uuid
      },
      update: (store, {
        data: {
          createAssetAssignment: { assetAssignment }
        }
      }) => updateAssetableAssetAssignmentsCache({
        store,
        assetableUuid: buildingUuid,
        assetableType: 'Building',
        assetableFragment: assetAssignmentFragment,
        assetAssignment
      })
    })
  }
}

import React from 'react'
import { FormControl } from 'react-bootstrap'

const AddressInput = (props) => {
  return (
    <FormControl as="textarea" { ...props } />
  )
}

export default AddressInput

import React from 'react'
import { t } from 'ttag'
import { Link } from 'react-router-dom'

import image       from 'shared/assets/images/m2_logo.svg'
import getImageUrl from 'shared/helpers/getImageUrl'

const Logo = () => (
  <Link to='/'>
    <img
      src={ getImageUrl(image) }
      alt={ t`Marketer Logo` }
    />
  </Link>
)

export default Logo

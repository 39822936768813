import React from 'react'
import { t, msgid, ngettext } from 'ttag'
import { shape, array, func } from 'prop-types'
import { FieldArray } from 'formik'
import { useQuery } from '@apollo/react-hooks'

import PlusIcon from 'shared/components/ui/icons/PlusIcon'
import useCurrentCampaignUuid from 'shared/hooks/useCurrentCampaignUuid'
import googleUrlBundlesQuery from 'graphql/queries/googleUrlBundles'

import URLBundlesSelect from './URLBundlesSelect'
import UrlInput from './UrlInput'
import {
  RightColumn as Container,
  PlacementsHeader,
  PlacementGroup,
  PlacementGroupHeader,
  UrlBundleListItem
} from './elements'

const RightColumn = ({ formikBag: { values, setFieldValue } }) => {
  const campaignUuid = useCurrentCampaignUuid()
  const { data } = useQuery(googleUrlBundlesQuery, { variables: { query: '', campaignUuid } })

  const handleClearPlacements = () => {
    setFieldValue('urlBundles', [])
    setFieldValue('urls', [])
  }

  const defaultOptions = () => {
    return data.googleUrlBundles.map(el => ({
      label: el.name,
      value: el.uuid,
      ...el
    }))
  }

  return (
    <Container>
      <PlacementsHeader>
        <span>{t`Display on`}</span>
        <button type="button" onClick={ handleClearPlacements }>{t`Clear all`}</button>
      </PlacementsHeader>

      <PlacementGroup>
        <PlacementGroupHeader>
          {t`URL Bundles`}: ({values.urlBundles.length})
        </PlacementGroupHeader>
        <FieldArray
          name="urlBundles"
          render={ arrayHelpers => (
            <>
              <ul>
                {values.urlBundles.map((el, idx) => (
                  <UrlBundleListItem key={ el.value }>
                    <div>
                      <strong>{el.label}</strong>
                      <div>
                        {ngettext(
                          msgid`${el.urlsCount} items`,
                          `${el.urlsCount} items`,
                          el.urlsCount
                        )}
                      </div>
                    </div>
                    <PlusIcon rotated onClick={ () => arrayHelpers.remove(idx) } />
                  </UrlBundleListItem>
                ))}
              </ul>
              {data !== undefined && (
                <URLBundlesSelect
                  defaultValues={ defaultOptions() }
                  value={ null }
                  onChange={ arrayHelpers.push }
                  placeholder={ t`Add new URL bundle` }
                  noOptionsMessage={ () => t`No options` }
                />
              )}
            </>
          ) }
        />
      </PlacementGroup>

      <PlacementGroup>
        <PlacementGroupHeader>
          {t`URLs`}: ({values.urls.length})
        </PlacementGroupHeader>
        <FieldArray
          name="urls"
          render={ arrayHelpers => (
            <>
              <ul>
                {values.urls.map((el, idx) => (
                  <li key={ el }>
                    <span>{el}</span>
                    <PlusIcon rotated onClick={ () => arrayHelpers.remove(idx) } />
                  </li>
                ))}
              </ul>
              <UrlInput
                placeholder={ t`Add new URL` }
                data-test-id="url-input"
                onChange={ url => !values.urls.includes(url) && arrayHelpers.push(url) }
              />
            </>
          ) }
        />
      </PlacementGroup>
    </Container>
  )
}

RightColumn.propTypes = {
  formikBag: shape({
    values: shape({
      urlBundles: array.isRequired,
      urls: array.isRequired
    }).isRequired,
    setFieldValue: func.isRequired
  }).isRequired
}

export default RightColumn

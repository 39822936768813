import React from 'react'
import { arrayOf, shape, string, number } from 'prop-types'

import Channel from './Channel'

const ChannelsList = ({ channels, currency, ...props }) => {
  return (
    <div>
      { channels.map((channel, index) => (
        <Channel
          key={ channel.type }
          index={ index }
          selectedChannels={ channels }
          currency={ currency }
          { ...channel }
          { ...props }
        />
      )) }
    </div>
  )
}

ChannelsList.propTypes = {
  channels: arrayOf(shape({
    type: string,
    spending: number
  })).isRequired,
  currency: string.isRequired
}

export default ChannelsList

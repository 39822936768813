import React       from 'react'
import { string }  from 'prop-types'
import { NavLink } from 'react-router-dom'

import { userRolePath } from 'routes/AppRedirect'

const UserRoleNavLink = ({ to, ...props }) => {
  return (
    <NavLink to={ userRolePath(to) } { ...props }/>
  )
}

UserRoleNavLink.propTypes = {
  to: string.isRequired
}

export default UserRoleNavLink

import { gql } from 'apollo-boost'

export default gql`
  query LanguageTargeting($query: String) {
    facebookLanguageTargeting(query: $query) {
      id
      name
    }
  }
`

import React           from 'react'
import { t }           from 'ttag'
import styled          from 'styled-components'
import { isEmpty }     from 'lodash'
import { array, bool } from 'prop-types'

import channelType from 'shared/types/channelType'

import Item from './Item'

const List = ({ className, channel, audiences, loading }) => {
  const loadingMessage = <p className='loading'>{ t`Loading...` }</p>
  const audiencesItems = isEmpty(audiences)
    ? ''
    : (
      audiences.map(
        audience => <Item key={ audience.uuid } channel={ channel } audience={ audience } />
      )
    )

  return (
    <div className={ className }>
      {
        loading ? loadingMessage : audiencesItems
      }
    </div>
  )
}

List.propTypes = {
  audiences: array.isRequired,
  channel: channelType.isRequired,
  loading: bool.isRequired
}

export default styled(List)`
  margin-top: 1rem;
  overflow-y: auto;
  max-height: 400px;

  .loading {
    text-align: center;
    margin-top: 2rem;
    font-style: italic;
  }

  .audience-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.5rem;

    div {
      overflow: hidden;
      display: grid;
      line-height: 1.07rem;
      width: 83%;

      small {
        color: grey;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
`

import React      from 'react'
import styled     from 'styled-components'
import { object } from 'prop-types'
import { t }      from 'ttag'

import { IconUser } from 'shared/components/ui/icons'

const Image = ({ className, agent }) => {
  const imageMedia = agent.assetAssignments.slice(-1)[0]

  const image = imageMedia
    ? <img src={ imageMedia.asset.thumbnail } alt={ t`Agent Picture` } />
    : <IconUser />

  return (
    <span className={ className }>
      { image }
    </span>
  )
}

Image.propTypes = {
  agent: object.isRequired
}

export default styled(Image)`
  margin-right: .5rem
  img {
    border-radius: 0.6rem
    height: 6rem
  }
`

import { gql } from 'apollo-boost'

export default gql`
  query {
    __type(name: "PlatformName") {
      name
      enumValues {
        name
      }
    }
  }
`

import gql from 'graphql-tag'

import specification from 'graphql/fragments/specification'

export default gql`
  mutation updateSpendingChannel(
    $campaignUuid: ID!,
    $index: Int!
    $channel: SpendingChannelInput!
  ) {
    updateSpendingChannel(
      campaignUuid: $campaignUuid,
      index: $index,
      channel: $channel
    ) {
      errors
      specification {
        ... Specification
      }
    }
  }
  ${specification}
`

import React from 'react'
import { string, shape, arrayOf, bool, object } from 'prop-types'
import { t } from 'ttag'
import { truncate } from 'lodash'
import styled from 'styled-components'
import debounceRender from 'react-debounce-render'
import { IconCaretRight } from 'cabanaico'

import { tableBorderValue } from 'shared/style/placeholders'
import { debounceTimeout } from 'shared/constants'
import getImageUrl from 'shared/helpers/getImageUrl'

import Iframe from '../FacebookPreview/Iframe'

import AdHeader from './AdHeader'

const DebouncedIframe = debounceRender(Iframe, debounceTimeout)

const InstagramPreview = (props) => {
  const {
    facebookPageLogo,
    facebookPageName,
    content,
    previewAsset,
    previewAssetMediaType,
    className,
    banners,
    color,
    address,
    title,
    logo,
    bannerOnly,
    property,
    campaign,
    previewWithBanners,
    location
  } = props

  const renderBanners = () => {
    return banners.map((banner) => {
      return (
        <BannerDiv key={ banner.uuid }>
          <DebouncedIframe
            type={ property.propertyType }
            price={ property.price }
            size={ property.usableAreaM2 }
            bedrooms={ property.bedrooms }
            agentName={ property.primaryAgentName }
            agentTitle={ property.primaryAgentTitle }
            agentPhotoUrl={ property.primaryAgentAvatar?.media }
            location={ location }
            title={ title }
            color={ color }
            content={ content }
            address={ address }
            assetUrl={ previewAsset }
            logoUrl={ logo && logo.thumbnail }
            bannerUuid={ banner.uuid }
            bannerName={ banner.name }
            campaign={ campaign }
          />
        </BannerDiv>
      )
    })
  }

  if (bannerOnly) {
    if (previewWithBanners) {
      return (
        <div>
          { renderBanners() }
        </div>
      )
    } else if (previewAsset){
      return <img src={ getImageUrl(previewAsset) } alt="image" className="image" />
    }
  }

  return (
    <div className={ className }>
      <AdHeader
        content={ content }
        logo={ facebookPageLogo }
        companyName={ facebookPageName }
      />

      <div className="body">
        { previewWithBanners && previewAssetMediaType !== 'video' && renderBanners() }

        { previewWithBanners && previewAssetMediaType === 'video' && (
          <img src={ getImageUrl(previewAsset) } alt="image" className="image" />
        ) }

        { !previewWithBanners && previewAsset && (
          <img src={ getImageUrl(previewAsset) } alt="image" className="image" />
        ) }
        <a href="#" onClick={ (e) => e.preventDefault() } className="learn-more">
          <span className="learn-more-text">
            <span>
              { t`Learn more` }
            </span>
            <IconCaretRight className="learn-more-arrow" />
          </span>
        </a>
        <div className="content" >
          { truncate(content, { length: 90, separator: /,? +/ }) }
        </div>
      </div>
    </div>
  )
}

InstagramPreview.propTypes = {
  address: string,
  bannerOnly: bool,
  banners: arrayOf(shape({
    uuid: string
  })),
  campaign: object,
  color: string,
  content: string,
  facebookPageLogo: string,
  facebookPageName: string,
  location: object,
  logo: string,
  previewAsset: string,
  previewAssetMediaType: string,
  previewWithBanners: bool,
  property: object,
  title: string
}

InstagramPreview.defaultProps = {
  address: null,
  bannerOnly: false,
  banners: [],
  campaign: {},
  color: null,
  facebookPageLogo: '',
  facebookPageName: '',
  content: '',
  location: {},
  logo: '',
  previewAsset: null,
  previewAssetMediaType: '',
  previewWithBanners: false,
  property: {},
  title: ''
}

const BannerDiv = styled.div`
  margin-bottom: 10px
`

export default styled(InstagramPreview)`
  border: ${tableBorderValue};
  font-size: 0.75rem;

  .body {
    overflow: hidden;
  }

  .image {
    width: 100%
  }

  .learn-more {
    display: block;
    font-weight: 500;
    padding: 0 0.625rem;
    color: #3397E9;
    transition: color ease .25s;

    &:hover {
      text-decoration: none;
    }

    .learn-more-text {
      align-items: center;
      border-bottom: 1px solid #e9eaeb;
      display: flex;
      justify-content: space-between;
      padding: 0.625rem 0;

      .learn-more-arrow {
        width: .85rem
      }
    }
  }

  .content {
    color: #282828;
    letter-spacing: 0.25px;
    line-height: 1.25;
    margin-bottom: 0.25rem;
    min-height: 5rem;
    padding: 0.55rem 0.625rem;

    &:first-letter {
      text-transform: uppercase;
    }
  }
`

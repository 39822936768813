import React, { memo }                        from 'react'
import styled                                 from 'styled-components'
import { isEqual }                            from 'lodash'
import classNames                             from 'classnames'
import { array, arrayOf, string, func, shape, bool, object } from 'prop-types'

import uniqEnabledThumbnails from '../uniqEnabledThumbnails'

import AdsSlider from './AdsSlider'
import SelectAds from './SelectAds'

const CreativeSetMedia = memo(({
  campaign,
  creativeSet,
  setPreviewAsset,
  className,
  isInvalid,
  formats,
  ...props
}) => {
  const assets = uniqEnabledThumbnails(creativeSet.creatives)

  return (
    <div className={ classNames(className, { 'is-invalid': isInvalid }) }>
      <AdsSlider assets={ assets } setPreviewAsset={ setPreviewAsset } />

      <SelectAds
        campaign={ campaign }
        setPreviewAsset={ setPreviewAsset }
        creativeSet={ creativeSet }
        formats={ formats }
        enabledAssets={ assets }
        { ...props }
      />
    </div>
  )
}, (prev, next) => {
  return (
    isEqual(prev.formats, next.formats)
      && isEqual(prev.creativeSet, next.creativeSet)
      && (prev.isInvalid === next.isInvalid)
  )
})

CreativeSetMedia.displayName = 'CreativeSetMedia'

CreativeSetMedia.propTypes = {
  campaign: object.isRequired,
  creativeSet: shape({
    creativeSetAudiences: arrayOf(shape({
      ads: arrayOf(shape({
        asset: shape({
          thumbnail: string
        }).isRequired
      })).isRequired,
    })).isRequired,
  }).isRequired,
  setPreviewAsset: func.isRequired,
  formats: array,
  isInvalid: bool
}

CreativeSetMedia.defaultProps = {
  formats: [],
  isInvalid: false
}

export default styled(CreativeSetMedia)`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin-top: 1.5rem

  ${AdsSlider} {
    width: 75%
  }

  ${SelectAds} {
    width: 15%
  };

  &.is-invalid {
    padding: calc(0.5rem - 0.063rem) calc(1rem - 0.063rem);
    border-color: red;
    border-radius: 0.25rem;
    border-style: solid;
    border-width: 0.063rem
  }
`

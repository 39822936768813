import React from 'react'
import styled from 'styled-components'
import { string, bool, func } from 'prop-types'
import classNames from 'classnames'

import { IconSuccess } from 'shared/components/ui/icons'

const Tab = ({ className, title, showArrow, onClick, enabled, complete }) => {
  return (
    <div
      className={ classNames(className, { disabled: !enabled }) }
      onClick={ enabled ? onClick : null }
    >
      { complete && (
        <IconSuccess />
      )}
      <span className='tab-title' title={ title }>{ title }</span>
      { showArrow && (
        <>
          <div className="arrow-top" />
          <div className="arrow-bottom" />
        </>
      )}
    </div>
  )
}

Tab.propTypes = {
  className: string.isRequired,
  onClick: func.isRequired,
  showArrow: bool.isRequired,
  title: string.isRequired,
  complete: bool,
  enabled: bool
}

Tab.defaultProps = {
  enabled: true,
  complete: false
}

export default styled(Tab)`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  line-height: 3.625rem;
  color: ${props => props.active ? '#2578ce' : '#697386'}
  font-weight: ${props => props.active ? 600 : 500}
  justify-content: center;
  cursor: pointer
  position:relative;
  padding-right: 1.5rem;
  overflow: hidden;
  white-space: nowrap;

  &.disabled {
    cursor: default
  }

  .icon-success {
    position: relative
    top: 1.25rem
    right: 0.5rem
  }

  .arrow-top {
    ::before {
      content: '';
      position: absolute;
      top: -3px;
      right: 7px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 32px 0 0 17px;
      border-color: transparent transparent transparent rgba(0, 0, 0, 0.125);
    }

    ::after {
      content: '';
      position: absolute;
      top: -2px;
      right: 9px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 31px 0 0 16px;
      border-color: transparent transparent transparent white;
    }
  }

  .arrow-bottom {
    ::before {
      content: '';
      position: absolute;
      right: 7px;
      bottom: -3px;
      border: 1px solid #ccc;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 32px 17px 0 0;
      border-color: rgba(0, 0, 0, 0.125) transparent transparent transparent;
    }

    ::after {
      content: '';
      position: absolute;
      right: 9px;
      bottom: -2px;
      border: 1px solid #ccc;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 31px 16px 0 0;
      border-color: white transparent transparent transparent;
    }
  }
`

import { gql } from 'apollo-boost'

export default gql`
  query Products($query: PageInput!) {
    products(query: $query) {
      total
      items {
        uuid
        name
        owner
        promotableType
        createdAt
        company {
          uuid
          name
        }
      }
    }
  }
`

import React from 'react'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { string } from 'prop-types'
import classNames from 'classnames'

import useUrlParams     from 'shared/hooks/useUrlParams'
import { ensureNumber } from 'shared/helpers/number'
import leadsQuery       from 'graphql/queries/leads'
import Header from 'shared/components/ui/Card/StaticHeader'
import Card   from 'shared/components/ui/Card'

import columns from './columns'
import List from './List'

const Leads = ({ projectUuid, campaignUuid, className }) => {
  const [urlParams, setUrlParams] = useUrlParams()
  const { sortBy, sortOrder } = urlParams
  const page = ensureNumber(urlParams.page, 1)
  const perPage = 5

  const { data } = useQuery(leadsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      projectUuid,
      campaignUuid,
      query: { page, sortOrder, sortBy, perPage }
    }
  })

  const leads = data ? data.leads : { items: [], total: 0 }

  return (
    <Card
      collapsible
      dataTestId="leads-list-panel"
      className={ classNames(className, 'mb-2') }
      header={ (
        <div className="d-flex justify-content-between">
          <Header className="align-self-center">
            { t`Leads` }
          </Header>
        </div>
      ) }
    >
      <List
        data={ leads }
        params={ urlParams }
        onTableChange={ setUrlParams }
        perPage={ perPage }
        columns={ columns }
      />
    </Card>
  )
}

Leads.propTypes = {
  campaignUuid: string,
  projectUuid: string
}

Leads.defaultProps = {
  projectUuid: null,
  campaignUuid: null
}
export default Leads

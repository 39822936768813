import { t } from 'ttag'

export default {
  various: t`Various`,
  contract: t`Contract`,
  settlement: t`Settlement`,
  supplementary_agreement: t`Supplementary agreement`,
  contract_attachment: t`Contract attachment`,
  bid_journal: t`Bid journal`
}

import styled from 'styled-components'

import { componentBackgroundColor, lightGreyColor, labelColor } from 'shared/style/colors'

export const RecordIconContainer = styled.div`
  align-items: center;
  background-color: ${componentBackgroundColor};
  border-radius: 100%;
  border: solid 3px ${lightGreyColor};
  display: flex;
  height: 2.625rem;
  justify-content: center;
  left: 0;
  margin-left: -1.4rem;
  padding-left: 0.125rem;
  position: absolute;
  width: 2.625rem;
  top: 0;
`

export const RecordTitle = styled.div`
  color: ${labelColor};
  font-size: 0.825rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
`

export const RecordDetails = styled.div`
  font-size: 0.75rem;
  color: ${labelColor};

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

export const RecordInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const RecordNoteInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const RecordNote = styled.div`
  color: ${labelColor};
  border-radius: 3px;
  border: solid 1px ${lightGreyColor};
  font-size: 0.825rem;
  padding: 0.625rem 0.75rem;
`

export const RecordContainer = styled.div`
  border-left: dashed 2px ${lightGreyColor};
  margin-bottom: 3px;
  margin-right: 2rem;
  margin-left: 4rem;
  padding: 0 4rem 3rem;
  position: relative;
  display: flex;
  flex-direction: row;

  &:last-child {
    padding-bottom: 0.125rem;
    border-left: none;
  }

  &:last-child:not(:first-child) ${RecordIconContainer} {
    left: 2px;
  }

  &:first-child:last-child {
    border-color: transparent;
  }
`

export const AdditionalInfoContainer = styled.div`
  color: ${labelColor};
  font-size: 0.75rem;
  padding-left: 8rem;

  span {
    display: inline-block;
  }
`

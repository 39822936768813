import React from 'react'
import { string } from 'prop-types'
import { t } from 'ttag'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { IconAdd } from 'cabanaico'

import productType from 'shared/types/productType'
import Button from 'shared/components/ui/Button'
import Card from 'shared/components/ui/Card'
import SetupComplete from 'shared/components/Product/MainInfo/SetupComplete'
import SubHeader from 'shared/components/Product/MainInfo/SubHeader'
import Footer from 'shared/components/Product/MainInfo/Footer'

const MainInfo = ({ product, className }) => {
  return (
    <Card
      footer={ <Footer product={ product }/> }
      className={ className }
    >
      <Row>
        <Col xs={ 9 }>
          <h5 className="font-weight-normal mb-0">
            { product.name }
          </h5>

          <SubHeader>
            { product.promotable.shortDescription }
          </SubHeader>
        </Col>
        <Col xs={ 3 }>
          <div className="text-right">
            <Button>
              <IconAdd />
              { t`Create offer` }
            </Button>
          </div>
          <div className="text-right mt-2">
            <SetupComplete complete={ product.promotable.setupComplete }/>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

MainInfo.propTypes = {
  product: productType.isRequired,
  className: string
}

MainInfo.defaultProps = {
  className: null
}

export default styled(MainInfo)`
  .col {
    border-right: solid 1px rgba(151, 151, 151, 0.6)
  }

  .col:last-child {
    border-right: none
  }
`

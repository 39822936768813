import React      from 'react'
import { string } from 'prop-types'

import ResourceQuery from 'shared/components/ResourceQuery'

import projectQuery from './projectQuery'
import EditForm     from './EditForm'

const ProjectPortalEdit = ({ uuid }) => {
  return (
    <ResourceQuery query={ projectQuery } variables={{ uuid }} fetchPolicy="network-only">
      {(project) => (
        <EditForm project={ project } />
      )}
    </ResourceQuery>
  )
}

ProjectPortalEdit.propTypes = {
  uuid: string.isRequired,
}

export default ProjectPortalEdit

import { gql } from 'apollo-boost'

import asset from 'graphql/fragments/asset'
import banner from 'graphql/fragments/banner'

export default gql`
  query CampaignAds($campaignUuid: ID!) {
    campaignAds(campaignUuid: $campaignUuid) {
      uuid
      enabled
      asset {
        ... Asset
      }
      audience {
        name
        uuid
      }
      creativeSet {
        name
        color
        title
        content
        description
        banners {
          ... Banner
        }
        channel {
          uuid
          type
        }
      }
      summary {
        totalImpressions
        totalClicks
        totalSpent
        averageCpl
        cpc
        ctr
        leadsCount
      }
    }
  }
  ${asset}
  ${banner}
`

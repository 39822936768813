import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { t } from 'ttag'
import { useMutation } from 'react-apollo'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { get } from 'lodash'
import { IconAdd, IconMinus } from 'cabanaico'
import styled from 'styled-components'
import moment from 'moment'

import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'
import Button from 'shared/components/ui/Button'
import SnapshotForm from 'shared/components/Snapshots/SnapshotForm'
import FormLabel from 'shared/components/ui/FormLabel'
import createSnapshotPhase from 'graphql/mutations/snapshots/snapshotPhases/create'
import updateSnapshotPhase from 'graphql/mutations/snapshots/snapshotPhases/update'
import deleteSnapshotPhase from 'graphql/mutations/snapshots/snapshotPhases/delete'
import copySnapshotMutation from 'graphql/mutations/snapshots/copy'
import snapshotAssetAssignmentsQuery from 'graphql/queries/snapshots/assetAssignments'

import AssignableAssets from './AssignableAssets'
import Phase from './Phase'

const ProjectStatusTab = ({ activeTab, snapshots, setSnapshots, tabs, className, project }) => {
  const [runCopySnapshotMutation] = useMutation(copySnapshotMutation)

  const getCurrentSnapshot = () => {
    return snapshots.find(value => value.saleState === activeTab)
  }

  const [isCopying, setIsCopying] = useState(false)
  const [currentSnapshot, setCurrentSnapshot] = useState(() => getCurrentSnapshot())

  useEffect(() => {
    setCurrentSnapshot(getCurrentSnapshot())
  }, [snapshots])

  const [runCreateSnapshotPhase] = useMutation(createSnapshotPhase)
  const [runUpdateSnapshotPhase] = useMutation(updateSnapshotPhase)
  const [runDeleteSnapshotPhase] = useMutation(deleteSnapshotPhase)

  const updatePhase = (phaseUuid, input) => {
    return runUpdateSnapshotPhase({
      variables: { uuid: currentSnapshot.uuid, phaseUuid, input }
    }).then(() => {
      setSnapshots(prev => {
        return prev.map((snapshot => {
          if (snapshot.uuid === currentSnapshot.uuid) {
            const newPhases = snapshot.phases.map(phase => {
              if (phase.uuid === phaseUuid) {
                return { ...phase, ...input }
              }
              return phase
            })
            return { ...snapshot, phases: newPhases }
          }
          return snapshot
        }))
      })
    })
  }

  const removePhase = () => {
    const currentSnapshot = getCurrentSnapshot()
    const newPhases = [...currentSnapshot.phases]
    const removedPhase = newPhases.pop()

    if (!removedPhase) { return }

    const updatedSnapshot = { ...currentSnapshot, phases: newPhases }
    setSnapshots(prev => {
      return prev.map((snapshot => {
        if (snapshot.saleState === activeTab) {
          return updatedSnapshot
        } else {
          return snapshot
        }
      }))
    })

    return runDeleteSnapshotPhase({
      variables: { snapshotUuid: currentSnapshot.uuid, uuid: removedPhase.uuid }
    })
  }

  const addPhase = () => {
    const newPhaseData = {
      title: 'New Phase',
      phasePeriod: 'quarter',
      startDate: moment().toISOString(),
      endDate: moment().toISOString()
    }

    return runCreateSnapshotPhase({
      variables: {
        uuid: currentSnapshot.uuid,
        input: newPhaseData
      }
    }).then((result) => {
      const newPhase = get(result, 'data.createSnapshotPhase.phase')
      if (!newPhase) { return }

      setSnapshots(prev => {
        return prev.map((snapshot => {
          if (snapshot.uuid === currentSnapshot.uuid) {
            const newPhases = [...snapshot.phases, newPhase]

            return { ...snapshot, phases: newPhases }
          }
          return snapshot
        }))
      })
    })
  }

  const [prevStateName, setPrevStateName] = useState(tabs[activeTab])

  const getPrevSaleStateIndex = () => {
    return Object.keys(tabs).indexOf(activeTab) - 1
  }

  useEffect(() => {
    setCurrentSnapshot(getCurrentSnapshot())
    setPrevStateName(Object.values(tabs)[getPrevSaleStateIndex()])
  }, [activeTab, snapshots])

  const handleCopyInformation = () => {
    const isConfirmed = confirm(t`Are you sure?`)

    if (!isConfirmed) {
      return
    }

    setIsCopying(true)

    const prevSnapshotState = Object.keys(tabs)[getPrevSaleStateIndex()]
    const prevSnapshot = snapshots.find(snapshot => snapshot.saleState === prevSnapshotState)

    return runCopySnapshotMutation({
      variables: {
        fromUuid: prevSnapshot.uuid,
        toUuid: currentSnapshot.uuid
      },
      refetchQueries:[{
        query: snapshotAssetAssignmentsQuery,
        variables: { snapshotUuid: currentSnapshot.uuid }
      }]
    }).then((result) => {
      setSnapshots(prev => {
        return prev.map(snapshot => {
          if (snapshot.uuid === currentSnapshot.uuid) {
            return result.data.copySnapshot.snapshot
          }
          return snapshot
        })
      })
      setIsCopying(false)
    })
  }

  const isSnapshotActive = currentSnapshot.saleState === project.saleState

  return (
    <div className={ className }>
      { (activeTab !== 'under_development') && prevStateName && <Button
        variant='primary'
        onClick={ handleCopyInformation }
        className='mb-2'
        data-test-id='copy-snapshot-button'
        disabled={ isCopying }>
        { t`Copy "${prevStateName}" information` }
      </Button> }
      <SnapshotForm
        data-test-id='snapshot-form'
        snapshot={ currentSnapshot }
        currency={ project.currency }
        setSnapshots={ setSnapshots }
        isSnapshotActive={ isSnapshotActive }
        snapshotableName={ t`Project` }
        projectUuid={ project.uuid }
      />
      <div className="group">
        <FormLabel>{ t`Images` }</FormLabel>
        <InputWrapper>
          <DndProvider backend={ HTML5Backend }>
            <AssignableAssets
              className='mt-3'
              projectAssetAssignments={ project.originalAssetAssignments }
              mediaType={ 'image' }
              assetableUuid={ currentSnapshot.uuid }
              assetSourceUuid={ project.uuid }
              assetSourceType='Project'
              assetableType='Snapshot'
              data-test-id='assignable-assets'
            />
          </DndProvider>
        </InputWrapper>
      </div>
      <div className='group'>
        <FormLabel>{ t`Phases` }</FormLabel>
        <InputWrapper className='phases'>
          { (getCurrentSnapshot().phases || []).map(phase => (
            <Phase
              data-test-id={ `${phase.uuid}-phase` }
              phase={ phase }
              key={ phase.uuid }
              updatePhase={ updatePhase } />
          ))}
        </InputWrapper>

        <div className='phase-buttons'>
          <Button
            variant='default'
            data-test-id='add-phase-button'
            className='phase-btn'
            onClick={ addPhase }>
            <IconAdd /> { t`Add new phase` }
          </Button>
          <Button
            variant='default'
            className='phase-btn'
            data-test-id='remove-phase-button'
            onClick={ removePhase }
            disabled={ getCurrentSnapshot().phases.length === 0 }>
            <IconMinus /> { t`Remove phase` }
          </Button>
        </div>
      </div>
    </div>
  )
}

ProjectStatusTab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  setSnapshots: PropTypes.func.isRequired,
  snapshots: PropTypes.array.isRequired,
  tabs: PropTypes.object.isRequired
}

export default styled(ProjectStatusTab)`
  .phases {
    margin-top: 20px;
  }

  .phase-buttons {
    margin-bottom: 10px;
    margin-top: 20px;

    .phase-btn {
      margin-right: 10px;
    }
  }
`

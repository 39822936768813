import React  from 'react'
import styled from 'styled-components'
import { number, bool }    from 'prop-types'
import { msgid, ngettext } from 'ttag'

import { labelColor } from 'shared/style/colors'

import OptimizationSwitch from './OptimizationSwitch'

const SpendingSubheader = ({ className, channelOptimizationEnabled, channelsCount }) => {
  return (
    <div className={ className }>
      <h6>
        { ngettext(
          msgid`${channelsCount} Channel`,
          `${channelsCount} Channels`,
          channelsCount
        ) }
      </h6>

      <OptimizationSwitch enabled={ channelOptimizationEnabled } />
    </div>
  )
}

SpendingSubheader.propTypes = {
  channelOptimizationEnabled: bool.isRequired,
  channelsCount: number.isRequired
}

export default styled(SpendingSubheader)`
  display: flex
  align-items: baseline;
  justify-content: space-between

  h6 {
    color: ${labelColor}
    margin-bottom: 0
  }
`

import React from 'react'
import styled from 'styled-components'
import { string, func } from 'prop-types'

import OwnershipTypeSelect from 'shared/components/OwnershipTypeSelect'

const OwnershipTypeInput = ({ paramName, onChange, value, uuid, className }) => {
  const handleInput = (paramValue) => {
    const attributes = {}
    attributes[paramName] = paramValue.target.value
    onChange({ uuid, attributes: attributes })
  }

  return (
    <div className={ className }>
      <OwnershipTypeSelect
        className='w-100'
        name="ownershipType"
        value={ value }
        data-test-id={ `${paramName}-input` }
        onChange={ handleInput }
      />
    </div>
  )
}

OwnershipTypeInput.propTypes = {
  onChange: func.isRequired,
  paramName: string.isRequired,
  uuid: string.isRequired,
  value: string
}

OwnershipTypeInput.defaultProps = {
  value: ''
}

export default styled(OwnershipTypeInput)`
  display: inline-block;
  box-shadow: none !important;
  height: 2.375rem;
  font-size: .75rem !important;
  width: 100%;
`

import React from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col, Badge } from 'react-bootstrap'

import { cardBorderValue } from 'shared/style/placeholders'

const Item = ({ className, labelVariant, labelText, content }) => {
  return (
    <Container fluid className={ className }>
      <Row>
        <Col xs={ 2 }>
          <Badge pill variant={ labelVariant }>
            { labelText }
          </Badge>
        </Col>
        <Col xs={ 10 }>
          { content }
        </Col>
      </Row>
    </Container>
  )
}

Item.propTypes = {
  className: string.isRequired,
  content: node.isRequired,
  labelText: string.isRequired,
  labelVariant: string,
}

Item.defaultProps = {
  labelVariant: 'primary',
}

export default styled(Item)`
  .container {
    border-bottom: ${cardBorderValue}
  }
  
  .container:last-child {
    border-bottom: none
  }
  
  .row {
    padding: 1rem 0
  }
  
  .badge {
    border-radius: 9px
    color: #4f566b
    display: inline-block
    font-weight: normal
    font-size: 13px
    min-width: 110px
    padding: .3rem 1rem
  }
  
  .badge-primary {
    background-color: #6ecadc
  }

  .badge-secondary {
    background-color: #dadada
  }

  .badge-danger {
    background-color: #f77a92
  }
`

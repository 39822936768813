import React      from 'react'
import classNames from 'classnames'
import styled     from 'styled-components'
import { string } from 'prop-types'

import PlusIcon              from 'shared/components/ui/icons/PlusIcon'
import { greyishBrownColor } from 'shared/style/colors'

import useDeleteButton from './useDeleteButton'

const DeleteButton = ({ uuid, className }) => {
  const { removeShowing, loading } = useDeleteButton(uuid)

  return (
    <a
      onClick={ removeShowing }
      className={ classNames(className, { disabled: loading }) }
      data-test-id="delete-showing-button"
    >
      <PlusIcon rotated />
    </a>
  )
}

DeleteButton.propTypes = {
  uuid: string.isRequired
}

export default styled(DeleteButton)`
  &.disabled {
    pointer-events: none;
  }

  ${PlusIcon} {
    cursor: pointer;
    height: 1rem;
    width: 1rem;

    .circle-and-plus {
      transition: all .5s ease;
    }

    &:hover {
      .circle-and-plus {
        fill: ${greyishBrownColor};
      }
    }
  }
`

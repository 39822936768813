import { formatNumberWithLocale, formatNumberPercentage } from 'shared/helpers/number'
import { formatPrice } from 'shared/helpers/currency'
import { formatDuration } from 'shared/helpers/date'

export default function formatCurrentValues(options) {
  const {
    totalLeads,
    averageCpl,
    averageArt,
    adSpend,
    impressions,
    visitors,
    currency,
    averageCtr,
    averageLcr,
    activeCampaigns
  } = options

  return {
    totalLeadsValue: totalLeads?.currentValue
      ? formatNumberWithLocale(totalLeads.currentValue)
      : formatNumberWithLocale(0),
    averageCplValue: averageCpl?.currentValue
      ? formatPrice(averageCpl.currentValue, currency)
      : formatPrice(0, currency),
    averageArtValue: averageArt?.currentValue
      ? formatDuration(averageArt.currentValue, 'minutes')
      : formatDuration(0, 'minutes'),
    adSpendValue: adSpend?.currentValue
      ? formatPrice(adSpend.currentValue, currency)
      : formatPrice(0, currency),
    impressionsValue: impressions?.currentValue
      ? formatNumberWithLocale(impressions.currentValue)
      : formatNumberWithLocale(0),
    visitorsValue: visitors?.currentValue
      ? formatNumberWithLocale(visitors.currentValue)
      : formatNumberWithLocale(0),
    averageCtrValue: averageCtr?.currentValue
      ? formatNumberPercentage(averageCtr.currentValue)
      : formatNumberPercentage(0),
    averageLcrValue: averageLcr?.currentValue
      ? formatNumberPercentage(averageLcr.currentValue)
      : formatNumberPercentage(0),
    activeCampaignsValue: activeCampaigns?.currentValue
      ? formatNumberWithLocale(activeCampaigns.currentValue)
      : formatNumberWithLocale(0)
  }
}

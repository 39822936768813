import React, { useState } from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { Form } from 'react-bootstrap'
import { bool, func } from 'prop-types'
import { Formik, Field } from 'formik'
import { useApolloClient } from 'react-apollo'

import Button from 'shared/components/ui/Button'
import Modal from 'shared/components/ui/Modal'
import FileInput from 'shared/components/FileInput'
import { buttonTextColor } from 'shared/style/colors'
import projectType from 'shared/types/projectType'
import DistrictSelect from 'shared/components/DistrictSelect'
import OwnershipTypeSelect from 'shared/components/OwnershipTypeSelect'
import CountriesSelect from 'shared/components/NewStage/StageDetails/Details/CountriesSelect'
import ZipCodeSelect from 'shared/components/NewStage/StageDetails/Details/ZipCodeSelect'
import Map from 'shared/components/NewStage/StageDetails/Details/Map'

import FacilitiesSelect from './FacilitiesSelect'
import Companies from './Companies'
import deleteProductCompanyMutation from './deleteProductCompanyMutation'

const EditProjectModal = ({ project, show, onHide, onSave }) => {
  const [pointMapToCoordinates, setPointMapToCoordinates] = useState(undefined)
  const client = useApolloClient()

  const initialValues = {
    name: project.name || '',
    districtUuid: project.district?.uuid,
    unitsCount: project.unitsCount || 0,
    unitsSold: project.unitsSold || 0,
    prospect: undefined,
    location: project.location || {},
    ownershipType: project.ownershipType || '',
    facilities: project.facilities || [],
    companies: project.companies || []
  }

  const handleSubmit = values => onSave(values)

  const handleZipChange = (value, center, setFieldValue) => {
    setFieldValue('location.zipCode', value)
    setPointMapToCoordinates(center)
  }

  const handleCoordinatesChange = ({ latitude, longitude }, setFieldValue) => {
    setFieldValue('location.latitude', latitude)
    setFieldValue('location.longitude', longitude)
  }

  const convertFacilities = (elements, setFieldValue) => {
    const convertedElements = elements.map((element) => {
      return element.label
    })
    setFieldValue('facilities', convertedElements)
  }

  const onUpdateCompanyUuid = (companies, company, value, setFieldValue) => {
    setFieldValue(
      'companies',
      companies.map((element) => {
        if (element.uuid !== company.uuid) return element

        element.uuid = value.target.value
        element.empty = false
        return element
      })
    )
  }

  const onUpdateCompanyCategory = (companies, company, value, setFieldValue) => {
    setFieldValue(
      'companies',
      companies.map((element) => {
        if (element.uuid !== company.uuid) return element

        element.category = value.value
        return element
      })
    )
  }

  const onDeleteCompany = (companies, company, setFieldValue) => {
    setFieldValue(
      'companies',
      companies.filter((element) => {
        if (element.uuid !== company.uuid) return true
        if ((company.id === null) || (company.id === undefined)) return false

        client.mutate({
          mutation: deleteProductCompanyMutation,
          variables: { id: company.id }
        })

        return false
      })
    )
  }

  const emptyCompany = () => {
    const timestamp = (new Date()).getTime().toString()
    return {
      uuid:     timestamp,
      category: null,
      empty:    true
    }
  }

  return (
    <Modal centered show={ show } onHide={ onHide }>
      <Modal.Header closeButton>
        <Header>{ t`Edit project details` }</Header>
      </Modal.Header>

      <Formik initialValues={ initialValues } onSubmit={ handleSubmit }>
        {({ setFieldValue, handleSubmit, values }) => (
          <form onSubmit={ handleSubmit }>
            <Modal.Body>
              <Group>
                <label>{ t`Project name` }</label>
                <Field name="name" as={ Form.Control } />
              </Group>

              <Group>
                <label>{ t`Total number of units` }</label>
                <Field name="unitsCount" type="number" as={ Form.Control } />
              </Group>

              <Group>
                <label>{ t`Units sold` }</label>
                <Field name="unitsSold" type="number" as={ Form.Control } />
              </Group>

              <Group>
                <label>{ t`Upload new prospect` }</label>
                <FileInput onChange={ value => setFieldValue('prospect', value)  }/>
              </Group>

              <Group>
                <label>{ t`Ownership type` }</label>
                <OwnershipTypeSelect
                  value={ values.ownershipType }
                  onChange={ value => setFieldValue('ownershipType', value.target.value) }
                />
              </Group>

              <Companies
                companies={ values.companies }
                updateCompanyUuid={
                  (company, value) => (
                    onUpdateCompanyUuid(values.companies, company, value, setFieldValue)
                  )
                }
                updateCompanyCategory={
                  (company, value) => (
                    onUpdateCompanyCategory(values.companies, company, value, setFieldValue)
                  )
                }
                addCompany={
                  () => setFieldValue('companies', [...values.companies, emptyCompany()])
                }
                deleteCompany={
                  (company) => (
                    onDeleteCompany(values.companies, company, setFieldValue)
                  )
                }
              />

              <Group>
                <label>{ t`Address` }</label>
                <Field name="location.address" as={ Form.Control } />
              </Group>

              <Group>
                <label>{ t`Country` }</label>
                <CountriesSelect
                  setTouched={ () => {} }
                  value={ values.location?.countryCode }
                  onChange={ value => setFieldValue('location.countryCode', value.value) } />
              </Group>

              <Group>
                <label>{ t`Zip code` }</label>
                <ZipCodeSelect
                  value={ values.location?.zipCode }
                  countryCode={ values.location?.countryCode }
                  onChange={ ({ value, center }) => handleZipChange(value, center, setFieldValue) }
                />
              </Group>

              <Group className='map-container'>
                <label>{ t`Location` }</label>
                <Map
                  pointMapToCoordinates={ pointMapToCoordinates }
                  onLocationChange={ (value) => handleCoordinatesChange(value, setFieldValue) }
                  marker={{
                    latitude: values.location?.latitude, longitude: values.location?.longitude
                  }}
                />
              </Group>

              <Group>
                <label>{ t`District` }</label>
                <DistrictSelect
                  name="districtUuid"
                  value={ values.districtUuid }
                  onChange={ value => setFieldValue('districtUuid', value.target.value) }
                />
              </Group>

              <Group>
                <label>{ t`Facilities` }</label>
                <FacilitiesSelect
                  values={ values.facilities }
                  onChange={ elements => convertFacilities(elements, setFieldValue) }
                />
              </Group>
            </Modal.Body>

            <Modal.Footer>
              <Button type="button" onClick={ onHide }>{ t`Cancel` }</Button>
              <Button type="submit">{ t`Save` }</Button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

const Group = styled.div`
  margin-bottom: 1rem;

  input {
    box-shadow: none;
  }

  label {
    color: ${buttonTextColor};
    font-size: 0.875rem;
  }

  &.map-container {
    width: 400px;
  }
`

const Header = styled.h3`
  margin: 0;
  font-size: 1.25rem;
`

EditProjectModal.propTypes = {
  project: projectType.isRequired,
  onHide: func,
  onSave: func,
  show: bool
}

EditProjectModal.defaultProps = {
  show: false,
  onHide: () => {},
  onSave: () => {}
}

export default EditProjectModal

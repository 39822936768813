import { get } from 'lodash'

const composeForCollecton = (objects, validataionErrors, force) => (
  validataionErrors.map((validataionError) => {
    const pathParts = validataionError.path.split('.')
    const idx = pathParts[0]
    const field = pathParts[pathParts.length - 1]
    return {
      uuid: get(objects, `${idx}.uuid`),
      field: field,
      error: validataionError.message,
      force: !!force
    }
  })
)

const composeForObject = (object, validataionErrors, force) => (
  validataionErrors.map((validataionError) => {
    return {
      uuid: object.uuid,
      field: validataionError.path,
      error: validataionError.message,
      force: !!force
    }
  })
)

export default (payload, validataionErrors, force) => (
  Array.isArray(payload)
    ? composeForCollecton(payload, validataionErrors, force)
    : composeForObject(payload, validataionErrors, force)
)

import gql from 'graphql-tag'

import building from 'graphql/fragments/building'

export default gql`
  query Building($uuid: ID!) {
    building(uuid: $uuid) {
      ... Building
    }
  }
  ${building}
`

import { gql } from 'apollo-boost'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  mutation createAssetAssignment(
    $assetableUuid: ID!,
    $assetableType: String!,
    $assetUuid:     ID!,
    $mediaType:     String
  ) {
    createAssetAssignment(
      assetableUuid: $assetableUuid,
      assetableType: $assetableType,
      assetUuid: $assetUuid,
      mediaType: $mediaType
    ) {
      assetAssignment {
        ... AssetAssignment
      }
    }
  }
  ${assetAssignment}
`

import updateStageMutation from 'graphql/mutations/projects/stages/updateStage'

const updateStage = (client, stage, input, location) => (
  client.mutate({
    mutation: updateStageMutation,
    variables: {
      stageUuid: stage.uuid,
      input: input,
      location: location
    }
  })
)

export default updateStage

import React from 'react'
import { array, func, shape, string } from 'prop-types'

import { combineErrors } from 'shared/helpers/inputValidation'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'

import ColorSelect         from './ColorSelect'
import FormatSelect        from './FormatSelect'
import LogoSelect          from './LogoSelect'

const GmpExtraFields = (props) => {
  const {
    uuid,
    logos,
    selectedLogo,
    selectedColor,
    companyUuid,
    usedBanners,
    bannerTemplateCollection,
    companyColors,
    creativeSetUuid,
    setDefaultPreviewBanner,
    errors
  } = props

  const errorsOnColor = combineErrors({ errors, uuid, field: 'color' })
  const errorsOnLogo = combineErrors({ errors, uuid, field: 'logo' })

  return (
    <div>
      <div className="d-flex justify-content-between">
        <InputWrapper
          error={ errorsOnLogo }
          className='w-25'
        >
          <LogoSelect
            logoUuid={ selectedLogo && selectedLogo.uuid }
            creativeSetUuid={ creativeSetUuid }
            logos={ logos }
            selectedColor={ selectedColor }
          />
        </InputWrapper>

        <InputWrapper
          error={ errorsOnColor }
          className='w-25'
        >
          <ColorSelect
            selectedColor={ selectedColor }
            creativeSetUuid={ creativeSetUuid }
            companyColors={ companyColors }
          />
        </InputWrapper>

        <InputWrapper
          className='w-50'
        >
          <FormatSelect
            bannerTemplateCollectionUuid={ bannerTemplateCollection?.uuid }
            companyUuid={ companyUuid }
            creativeSetUuid={ creativeSetUuid }
            setDefaultPreviewBanner={ setDefaultPreviewBanner }
            usedBanners={ usedBanners }
          />
        </InputWrapper>
      </div>
    </div>
  )
}

GmpExtraFields.propTypes = {
  companyUuid: string.isRequired,
  creativeSetUuid: string.isRequired,
  logos: array.isRequired,
  setDefaultPreviewBanner: func.isRequired,
  usedBanners: array.isRequired,
  uuid: string.isRequired,
  bannerTemplateCollection: shape({
    uuid: string.isRequired
  }),
  companyColors: array,
  errors: array,
  selectedColor: string,
  selectedLogo: shape({
    uuid: string.isRequired
  })
}

GmpExtraFields.defaultProps = {
  bannerTemplateCollection: null,
  companyColors: [],
  errors: [],
  selectedColor: null,
  selectedLogo: null,
}

export default GmpExtraFields

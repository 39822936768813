import React from 'react'
import { bool, node } from 'prop-types'
import styled from 'styled-components'

const HighlightableDiv = ({ className, isInvalid, children }) => {
  return (
    <div className={ `${className} ${isInvalid ? 'is-invalid' : ''}` }>
      { children }
    </div>
  )
}

HighlightableDiv.propTypes = {
  children: node.isRequired,
  isInvalid: bool
}

HighlightableDiv.defaultProps = {
  isInvalid: false
}

export default styled(HighlightableDiv)`
  &.is-invalid {
    border-color: red;
    border-radius: 0.25rem
    border-style: solid
    border-width: 0.063rem
  }
`

import React      from 'react'
import styled     from 'styled-components'
import { t }      from 'ttag'

import Card   from '../Card'

import projectUnitsType from './Units/projectUnitsType'
import Units  from './Units'

const UnitsList = ({ className, unitsData }) => {
  return (
    <Card headerText={ t`Unit list` }>
      <div className={ className } data-test-id='units-list-card'>
        <Units unitsData={ unitsData } />
      </div>
    </Card>
  )
}

UnitsList.propTypes = {
  unitsData: projectUnitsType.isRequired
}

export default styled(UnitsList)`
  .pagination {
    padding-top: 1rem
    padding-right: 1rem
  }

  .pagination button {
    margin-left: .5rem
  }
`

import { gql } from 'apollo-boost'

export default gql`
  mutation moveToPosition(
    $uuid: String!
    $newPosition: String!
  ) {
    moveToPosition(
      uuid: $uuid
      newPosition: $newPosition
    ) {
      assetAssignments {
        uuid
        portalPositionDraft
      }
    }
  }
`

export default ({ landingPages, creativeSets }) => {
  const unassignedCreativeSets = creativeSets
    .filter(cset => cset.channel.type !== 'facebook_lead_ad' )
    .filter(cset => !cset.landingPage)

  return landingPages.map((landingPage) => ({
    ...landingPage,
    unassignedCreativeSets: unassignedCreativeSets
  }))
}

import React  from 'react'
import { t }  from 'ttag'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { object }   from 'prop-types'

import dashboardAnalyticsMarketerMonthlyLeadsQuery
  from 'graphql/queries/dashboardAnalytics/marketerMonthlyLeads'
import dashboardAnalyticsOtherMonthlyLeadsQuery
  from 'graphql/queries/dashboardAnalytics/otherMonthlyLeads'
import dashboardAnalyticsCategorizedLeads
  from 'graphql/queries/dashboardAnalytics/categorizedLeads'
import dashboardAnalyticsCategorizedLeadsByMonth
  from 'graphql/queries/dashboardAnalytics/categorizedLeadsByMonth'
import LoadingBlock                 from 'shared/components/ui/LoadingBlock'
import { bluishColor, purlpeColor } from 'shared/style/colors'
import { formatNumberWithLocale }   from 'shared/helpers/number'
import { startOfMonth }             from 'shared/helpers/date'

import Card from '../Card'

import SourceSummary from './SourceSummary'
import Chart         from './Chart'

const LeadSourcesChart = ({ className, filter }) => {
  const {
    data: {
      dashboardAnalyticsMarketerMonthlyLeads: {
        marketerMonthlyLeads
      } = {}
    } = {},
    loading
  } = useQuery(dashboardAnalyticsMarketerMonthlyLeadsQuery, {
    variables: { filter }
  })

  const {
    data: {
      dashboardAnalyticsOtherMonthlyLeads: {
        otherMonthlyLeads
      } = {}
    } = {},
    loading: loadingOthers
  } = useQuery(dashboardAnalyticsOtherMonthlyLeadsQuery, {
    variables: { filter }
  })

  const {
    data: {
      dashboardAnalyticsCategorizedLeads: {
        categorizedLeads
      } = {}
    } = {},
    loading: loadingCategorizedLeads
  } = useQuery(dashboardAnalyticsCategorizedLeads, {
    variables: { filter }
  })

  const {
    data: {
      dashboardAnalyticsCategorizedLeadsByMonth: {
        categorizedLeadsByMonth
      } = {}
    } = {},
    loading: loadingCategorizedLeadsByMonth
  } = useQuery(dashboardAnalyticsCategorizedLeadsByMonth, {
    variables: { filter }
  })

  if (loading || loadingOthers || loadingCategorizedLeads || loadingCategorizedLeadsByMonth) {
    return (
      <Card header={ t`Lead source` } className={ className }>
        <LoadingBlock />
      </Card>
    )
  }

  const appendToSeries = ({ series, categories, color }) => {
    Object.keys(categories).forEach((category) => {
      const months = categories[category]

      const data = Object.keys(months).map((month) => {
        return [startOfMonth(month), months[month]]
      })

      series.push({
        color: color,
        name: category,
        data: data
      })
    })
  }

  const series = []

  appendToSeries({
    series: series,
    categories: categorizedLeadsByMonth.marketer,
    color: bluishColor
  })

  appendToSeries({
    series: series,
    categories: categorizedLeadsByMonth.other,
    color: purlpeColor
  })

  const sourcesConfig = [
    {
      data: marketerMonthlyLeads,
      categories: categorizedLeads.marketer,
      name: 'Marketer',
      color: bluishColor
    },
    {
      data: otherMonthlyLeads,
      categories: categorizedLeads.other,
      name: t`Other`,
      color: purlpeColor
    }
  ]

  const totalLeads = sourcesConfig.reduce((acc, source) => source.data.currentValue + acc, 0)
  const buildPercentageString = (value) => {
    const percents = totalLeads ? (value / totalLeads) * 100 : 0

    return `${formatNumberWithLocale(percents)}%`
  }

  const summary = sourcesConfig.map((source) => ({
    ...source,
    value: source.data.currentValue.toString(),
    percentage: buildPercentageString(source.data.currentValue)
  }))

  return (
    <Card header={ t`Lead source` } className={ className } dataTestId='lead-sources-chart'>
      <div className='wrapper'>
        <div className='summary'>
          {
            summary.map((source) => <SourceSummary key={ source.name } { ...source } />)
          }
        </div>

        <div className='chart-container'>
          <Chart series={ series } />
        </div>
      </div>
    </Card>
  )
}

LeadSourcesChart.propTypes = {
  filter: object
}

LeadSourcesChart.defaultProps = {
  filter: {}
}

export default styled(LeadSourcesChart)`
  .wrapper {
    display: flex
    flex-direction: row
  }

  .summary {
    margin-right: 0.4rem
    display: flex
    flex-direction: column
  }
`

import React  from 'react'
import styled from 'styled-components'
import { node, string } from 'prop-types'

import Header from 'shared/components/ui/Card/StaticHeader'
import Card from 'shared/components/ui/Card'

const SalesCard = ({ className, children, headerText }) => {
  const renderHeader = () => {
    if (!headerText) return null
    
    return (
      <div className='d-flex'>
        <Header className='align-self-center'>{ headerText }</Header>
      </div>
    )
  }

  return (
    <Card
      className={ `${className} mb-2` }
      dataTestId='sales-card'
      header={ renderHeader() }
    >
      { children }
    </Card>
  )
}

SalesCard.propTypes = {
  children: node.isRequired,
  headerText: string
}

SalesCard.defaultProps = {
  headerText: null
}

export default styled(SalesCard)`
  margin-bottom: 2rem !important;

  .card-body {
    padding: 0 !important;
  }
`

import { gql } from 'apollo-boost'

export default gql`
  mutation createBuilding($projectUuid: ID!) {
    createBuilding(projectUuid: $projectUuid) {
      building {
        uuid
      }
    }
  }
`

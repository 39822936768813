import React from 'react'
import { shape, arrayOf, string, number, bool } from 'prop-types'

import Table from 'shared/components/ui/Table'

import useAudienceTable from './useAudienceTable'

const AudiencesTable = ({ campaign, ads }) => {
  const { tableOptions, paginationOptions } = useAudienceTable(campaign, ads)

  return (
    <Table
      keyField="uuid"
      tableOptions={ tableOptions }
      paginationOptions={ paginationOptions }
    />
  )
}

AudiencesTable.propTypes = {
  ads: arrayOf(shape({
    enabled: bool,
    asset: shape({
      thumbnail: string
    }),
    summary: shape({
      totalImpressions: number,
      totalClicks: number,
      averageCpl: number,
      cpc: number,
      ctr: number,
      leadsCount: number
    })
  })).isRequired,
  campaign: shape({
    channels: arrayOf(shape({
      audiences: arrayOf(shape({
        uuid: string,
        name: string
      })).isRequired
    })).isRequired
  }).isRequired
}

export default AudiencesTable

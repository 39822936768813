import gql from 'graphql-tag'

import facility from 'graphql/fragments/facility'
import stageUnitBalcony from 'graphql/fragments/stageUnitBalcony'
import stageUnitParking from 'graphql/fragments/stageUnitParking'

export default gql`
  fragment StageUnit on StageUnit {
    uuid
    createdAt
    unitId
    unitType
    bedroomCount
    roomCount
    sizeM2
    usableAreaM2
    lotSizeM2
    totalPrice
    state
    floorNumber
    stageId
    stageName
    buildingId
    buildingName
    purchasePrice
    sharedPrice
    totalCosts
    parkingPrice
    deposit
    facilities {
      ... Facility
    }
    parkingSlotsAmount
    jointDebt
    ownershipType
    optionalParkingSpots
    balconies {
      ... StageUnitBalcony
    }
    parkings {
      ... StageUnitParking
    }
    floorplans {
      uuid
      media
      thumbnail
    }
  }
  ${facility}
  ${stageUnitBalcony}
  ${stageUnitParking}
`

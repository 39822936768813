import { gql } from 'apollo-boost'

import DashboardDataset from 'graphql/fragments/dashboardDataset'

export default gql`
  query DashboardAnalyticsAverageCpl($filter: JSON) {
    dashboardAnalyticsAverageCpl(filter: $filter) {
      currency
      averageCpl {
        ... DashboardDataset
      }
    }
  }
  ${DashboardDataset}
`

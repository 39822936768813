import { useEffect, useState } from 'react'

export default function useMediaAlignment() {
  const [mediaTop, setMediaTop] = useState(null)

  useEffect(() => {
    const landingContentForm = document.querySelector('.landing-content-form')
    const containerMain = document.querySelector('.container-main')

    const alignMedia = () => {
      const landingContentFormTop = landingContentForm.getBoundingClientRect().top

      if (landingContentFormTop >= 15) {
        setMediaTop(landingContentFormTop)
      }
    }

    containerMain.addEventListener('scroll', alignMedia)

    return () => {
      containerMain.removeEventListener('scroll', alignMedia)
    }
  }, [])

  return {
    top: mediaTop ? mediaTop : 'initial'
  }
}

import React from 'react'

import PermissionCheckbox from 'shared/components/Stakeholders/PermissionCheckbox'

const permissionFormatter = (cell, row, rowIndex, { onPermissionUpdate }) => (
  <PermissionCheckbox
    readOnly
    value={ cell }
    stakeholderUuid={ row.uuid }
    onPermissionUpdate={ onPermissionUpdate } />
)

export default permissionFormatter

import gql from 'graphql-tag'

export default gql`
  fragment InstagramPlatformAudience on PlatformAudience {
    ... on Instagram {
      uuid
      ageFrom
      ageTo
      gender
      platform
      updatedAt
      instagramPositions
      customAudiencesInclude {
        id
        name
        subtype
      }
      customAudiencesExclude {
        id
        name
        subtype
      }
      detailedTargetingInclude {
        id
        name
        type
      }
      detailedTargetingExclude {
        id
        name
        type
      }
      detailedTargetingAtLeastOne {
        id
        name
        type
      }
      languageTargeting {
        id
        name
      }
    }
  }
`

import React   from 'react'
import Select  from 'react-select'
import { get } from 'lodash'
import { t }   from 'ttag'
import { oneOfType, string, number, func } from 'prop-types'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import useCompanyRoles       from 'shared/hooks/useCompanyRoles'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    marginRight: '0.5rem',
    minWidth: '5rem'
  })
}

const CompanyRoleSelect = ({ value, companyUuid, onChange, ...props }) => {
  const { companyRoles } = useCompanyRoles(companyUuid)

  const allOptions = companyRoles
    .map(companyRole => ({ label: companyRole.roleName, value: companyRole.id }))
  const selectedOption = allOptions.find(el => el.value === value)
  const handleChange = option => onChange(get(option, 'value', null))

  return (
    <Select
      value={ selectedOption }
      options={ allOptions }
      onChange={ handleChange }
      placeholder=''
      noOptionsMessage={ () => t`No options` }
      styles={ styles }
      { ...props }
    />
  )
}

CompanyRoleSelect.propTypes = {
  companyUuid: string.isRequired,
  onChange: func,
  value: oneOfType([string, number])
}

CompanyRoleSelect.defaultProps = {
  onChange: () => {},
  value: ''
}

export default CompanyRoleSelect

import React from 'react'
import { t } from 'ttag'
import { array, string } from 'prop-types'
import styled from 'styled-components'

import MediaList from 'shared/components/MediaList'
import mediaTypes from 'shared/components/Media/mediaTypes'
import Card from 'shared/components/ui/Card'
import Documents from 'shared/components/Documents'
import ConfirmationModal from 'shared/components/Gallery/Lightbox/ConfirmationModal'

import useMediaData from '../Regular/Media/useMediaData'

const ProjectMedia = ({ assetAssignments, className, showings, projectUuid }) => {
  const {
    assetsByType,
    attachProjectAsset,
    usedInCampaigns,
    removeAssetAssignment,
    resetRemovalConfirmation,
    confirmAssetAssignmentRemoval
  } = useMediaData({ projectUuid, assetAssignments })

  return (
    <Card collapsed collapsible className="mb-2" header={ t`Media` }>
      <div className={ className }>
        <div className="medias">
          { mediaTypes.map((mediaType) => (
            <MediaList
              key={ mediaType }
              mediaType={ mediaType }
              assets={ assetsByType[mediaType] || [] }
              handleAssetsChange={ attachProjectAsset }
              handleAssetRemoval={ removeAssetAssignment }
            />
          )) }
        </div>
        <Documents
          readOnly
          showings={ showings }
          showableUuid={ projectUuid }
          showableType='Project'
        />
      </div>

      <ConfirmationModal
        onHide={ resetRemovalConfirmation }
        onConfirm={ confirmAssetAssignmentRemoval }
        usedInCampaigns={ usedInCampaigns }
      />
    </Card>
  )
}

ProjectMedia.propTypes = {
  assetAssignments: array.isRequired,
  projectUuid: string.isRequired,
  showings: array.isRequired
}

export default styled(ProjectMedia)`
  display: flex;
  flex-direction: row;

  .medias {
    padding-right: 1rem;
    flex: 7;
  }
`

import { useApolloClient } from 'react-apollo'

import setAudiencesMutation from 'graphql/mutations/campaign/creativeSets/setAudiences'
import { buildFragmentParams } from 'shared/helpers/graphql'
import creativeSetFragment from 'graphql/fragments/creativeSet'

const useSetAudiences = (uuid) => {
  const client = useApolloClient()

  return (audienceUuids) => {

    return client.mutate({
      mutation: setAudiencesMutation,
      variables: { uuid, audienceUuids },
      update: (cache, data) => {
        const updatedCreativeSet = data.data.setCreativeSetAudiences.creativeSet

        const fragmentParams = buildFragmentParams(
          { __typename: 'CreativeSet', uuid: uuid },
          creativeSetFragment
        )
        const fragmentData = cache.readFragment(fragmentParams)

        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            adsCount: updatedCreativeSet.adsCount,
            creativeSetAudiences: updatedCreativeSet.creativeSetAudiences
          }
        })
      }
    })
  }
}

export default useSetAudiences

import React, { useState } from 'react'
import { t } from 'ttag'
import { useApolloClient } from 'react-apollo'
import { toast } from 'react-toastify'
import { arrayOf, bool, shape, string } from 'prop-types'
import styled from 'styled-components'

import Table         from 'shared/components/Stakeholders/Table'
import NewItemButton from 'shared/components/ui/NewItemButton'
import PrimaryButton from 'shared/components/ui/PrimaryButton'
import PlusIcon      from 'shared/components/ui/icons/PlusIcon'
import UserFormModal from 'shared/components/UserForm/Modal'
import stakeholderHandlers
  from 'shared/components/ProjectForm/ProjectFormView/Stakeholders/stakeholderHandlers'

import addStakeholderMutation from '/graphql/mutations/productStakeholders/addStakeholderMutation'

import deleteStakeholderMutation from './deleteStakeholderMutation'
import updateStakeholderMutation from './updateStakeholderMutation'
import inviteStakeholderMutation from './inviteStakeholderMutation'

const StakeholdersTable = ({ project, disabled, className }) => {
  const updateNames = (elements) => {
    return elements.map((element) => {
      if (element.user === null) return element

      element.user.name = element.user.nameWithEmail
      return element
    })
  }

  const client = useApolloClient()
  const [isCreating, setIsCreating] = useState(false)
  const [stakeholders, setStakeholders] = useState(updateNames(project.product.stakeholders))

  const {
    removeStakeholder,
    updatePermission,
    updateResponsibility,
    updateSettings,
    updateUser
  } = stakeholderHandlers(stakeholders, setStakeholders)

  const deleteStakeholder = (stakeholder) => {
    client.mutate({
      mutation: deleteStakeholderMutation,
      variables: { productUuid: project.product.uuid, uuid: stakeholder.stakeholderUuid }
    })

    removeStakeholder(stakeholder)
  }

  const addStakeholder = () => {
    client.mutate({
      mutation: addStakeholderMutation,
      variables: { productUuid: project.product.uuid, attributes: {} },
      update: (cache, { data: { addProductStakeholder: { stakeholder } } }) => {
        setStakeholders([...stakeholders, stakeholder])
      }
    })
  }

  const updateRequest = (stakeholderUuid, attributes) => {
    client.mutate({
      mutation: updateStakeholderMutation,
      variables: {
        productUuid: project.product.uuid,
        uuid:        stakeholderUuid,
        attributes:  attributes
      }
    })
  }

  const updateStakeholderUser = (value) => {
    updateRequest(value.stakeholderUuid, { userUuid: value.userUuid })
    updateUser(value)
  }

  const updateStakeholderSettings = (value) => {
    updateRequest(value.stakeholderUuid, { [value.name]: value.enabled })
    updateSettings(value)
  }

  const updateStakeholderResponsibility = (value) => {
    updateRequest(value.stakeholderUuid, { responsibility: value.responsibility })
    updateResponsibility(value)
  }

  const updateStakeholderPermission = (value) => {
    updateRequest(value.stakeholderUuid, { permission: value.permission })
    updatePermission(value)
  }

  const handleSubmit = (user, attributes) => {
    client.mutate({
      mutation: inviteStakeholderMutation,
      variables: { productUuid: project.product.uuid, attributes: attributes },
      update: (cache, { data: { inviteProductStakeholder: { stakeholder } } }) => {
        const existedStakeholder = stakeholders.find((element) => {
          return element.uuid === stakeholder.uuid
        })
        if (existedStakeholder === undefined) {
          stakeholder.user.name = stakeholder.user.nameWithEmail
          setStakeholders([...stakeholders, stakeholder])
        } else toast.info(t`User with this email already exists`)
      }
    })
  }

  return (
    <div className={ className }>
      <Table
        disabled={ disabled }
        companyUuid={ project.product.company.uuid }
        stakeholders={ stakeholders }
        onPermissionUpdate={ updateStakeholderPermission }
        onStakeholderDelete={ deleteStakeholder }
        onResponsibilityUpdate={ updateStakeholderResponsibility }
        onSettingsUpdate={ updateStakeholderSettings }
        onUserUpdate={ updateStakeholderUser }
      />
      <div className='buttons'>
        <NewItemButton onClick={ addStakeholder } data-test-id='add-new-stakeholder-button'>
          { t`Add new stakeholder` }
        </NewItemButton>
        <PrimaryButton
          onClick={ () => setIsCreating(true) }
          style={{ minWidth: '11rem' }}
          data-test-id='add-new-user-button'
        >
          <PlusIcon />
          <span>{ t`Add new user` }</span>
        </PrimaryButton>
      </div>
      <UserFormModal
        companyUuid={ project.product.company.uuid }
        title={ t`Create new user` }
        show={ isCreating }
        onHide={ () => setIsCreating(false) }
        onSubmit={ handleSubmit }
      />
    </div>
  )
}

StakeholdersTable.propTypes = {
  disabled: bool.isRequired,
  project: shape({
    product: shape({
      company: shape({
        uuid: string.isRequired,
      }),
      stakeholders: arrayOf(shape({
        leadNotification: bool.isRequired,
        permission: string.isRequired,
        responsibility: string.isRequired,
        user: shape({
          uuid: string.isRequired,
          nameWithEmail: string.isRequired,
          synced: bool.isRequired
        }),
        uuid: string.isRequired,
        weeklyReport: bool.isRequired
      })),
      uuid: string.isRequired
    })
  }).isRequired
}

export default styled(StakeholdersTable)`
  margin-bottom: 0.5rem;
  
  .buttons {
    display: flex;
    justify-content: space-between;

    button {
      margin: 0 1rem .75rem;
    }
  }
`

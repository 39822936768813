import React                   from 'react'

import channelFragment         from 'graphql/fragments/channel'
import { buildFragmentParams } from 'shared/helpers/graphql'
import toastError              from 'shared/toastError'

import addTargetAudienceMutation from './addTargetAudienceMutation'

export const addTargetAudience = ({ client, channelUuid }) => {
  client.mutate({
    mutation: addTargetAudienceMutation,
    variables: {
      channelUuid: channelUuid
    },
    update: (cache, data) => {
      const audience = data.data.addTargetAudience.audience
      const errors   = data.data.addTargetAudience.errors

      if (errors === null) {
        const object         = { __typename: 'Channel', uuid: channelUuid }
        const fragmentParams = buildFragmentParams(object, channelFragment)
        const fragmentData   = cache.readFragment(fragmentParams)

        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            audiences: [audience, ...fragmentData.audiences]
          }
        })
      } else {
        toastError(
          <>
            { errors.join(' ') }
          </>
        )
      }
    }
  })
}

import { gql } from 'apollo-boost'

import snapshotFragment from 'graphql/fragments/snapshot'

export default gql`
  mutation updateSnapshot(
    $uuid: ID!
    $input: SnapshotInput!
  ) {
    updateSnapshot(
      uuid: $uuid
      input: $input
    ) {
      snapshot {
       ... Snapshot
      }
    }
  }
  ${snapshotFragment}
`

import React          from 'react'
import { components } from 'react-select'
import { bool, node } from 'prop-types'

import Checkbox from 'shared/components/ui/Checkbox'

const Option = props => {
  const { isSelected, children } = props

  return (
    <components.Option { ...props }>
      <Checkbox
        readOnly
        shadow={ false }
        checked={ isSelected }
        label={ children }
      />
    </components.Option>
  )
}

Option.propTypes = {
  children: node.isRequired,
  isSelected: bool.isRequired
}

export default Option

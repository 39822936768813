import gql from 'graphql-tag'

import specification from 'graphql/fragments/specification'

export default gql`
  mutation updateCampaignGoals(
    $campaignUuid: ID!
    $goals: CampaignGoalsInputType!
  ) {
    updateCampaignGoals(campaignUuid: $campaignUuid, goals: $goals) {
      errors
      specification {
        ... Specification
      }
    }
  }
  ${specification}
`

import React, { memo }        from 'react'
import { func, string, bool } from 'prop-types'
import { t }                  from 'ttag'
import Select                 from 'react-select'

import { timeZones } from 'shared/helpers/date'

import styles from './selectStyles'

const options = timeZones().map(({ name, offset }) => ({
  label: `(${offset}) ${name}`,
  value: name
}))

const TimeZoneSelect = ({ onChange, name, value, isInvalid }) => {
  return (
    <span data-test-id="time-zone-select-wrapper">
      <Select
        className={ isInvalid ? 'is-invalid' : '' }
        styles={ styles }
        name={ name }
        onChange={ ({ value }) => {
          onChange({ target: { name, value } })
        } }
        value={ options.find((option) => option.value === value) }
        options={ options }
        placeholder={ t`Select...` }
        noOptionsMessage={ () => t`No options` }
      />
    </span>
  )
}

TimeZoneSelect.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  isInvalid: bool,
  value: string,
}

TimeZoneSelect.defaultProps = {
  isInvalid: false,
  value: undefined,
}

export default memo(TimeZoneSelect, (prevProps, nextProps) => (
  prevProps.value === nextProps.value && prevProps.isInvalid === nextProps.isInvalid
))

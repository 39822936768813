import React             from 'react'
import { shape, string } from 'prop-types'
import { t }             from 'ttag'

const AdminLocationInfo = ({ user }) => {
  return (
    <>
      <p className="info-row">
        <label>{ t`Office` }</label>
        <span>{ user.office }</span>
      </p>
      <p className="info-row">
        <label>{ t`Role` }</label>
        <span>{ t`Admin` }</span>
      </p>
    </>
  )
}

AdminLocationInfo.propTypes = {
  user: shape({
    office: string
  }).isRequired
}

export default AdminLocationInfo

import React          from 'react'
import styled         from 'styled-components'
import classNames     from 'classnames'
import { node, func } from 'prop-types'

import { labelColor, dimBlue } from 'shared/style/colors'

const Body = ({ className, children, icon, title, onCLick }) => {
  return (
    <div className={ className }>
      { children ? (
        children
      ) : (
        <>
          { icon }

          <h6 className={ classNames('section-title', { clickable: Boolean(onCLick) }) }>
            { title }
          </h6>
        </>
      )}
    </div>
  )
}

Body.propTypes = {
  children: node,
  icon: node,
  onCLick: func,
  title: node,
}

Body.defaultProps = {
  children: null,
  icon: null,
  title: null,
  onCLick: undefined
}

export default styled(Body)`
  display: flex;
  align-items: center;
  height: 1rem;
  
  [class^="icon-"] {
    font-size: 1rem;
    margin-right: .5rem
  }
  
  & > svg {
    margin-right: .5rem
  }
  
  .icon-case {
    font-size: .75rem
  }
  
  .section-title {
    color: ${labelColor}
    font-size: .75rem;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 0;
    
    &:first-letter {
      text-transform: uppercase
    }
  }
  
  h6 a {
    color: ${dimBlue}
  }
`

import gql from 'graphql-tag'

export default gql`
  fragment ActivityLog on Lead {
    activityLog {
      uuid
      createdAt
      author {
        uuid
        name
      }

      ... on NoteRecord {
        title
        text
      }

      ... on ProgressRecord {
        statusBefore
        statusAfter
      }

      ... on ActivityRecord {
        title
        text
        recordedAt
        activityType
        involvedUser {
          uuid
          name
        }
      }
    }
  }
`

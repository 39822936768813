import gql from 'graphql-tag'

import audience from './audience'

export default gql`
  fragment Channel on Channel {
    uuid
    type
    title
    enabled
    lastInsightSummary {
      totalSpent
    }
    audiences {
      ... Audience
    }
  }
  ${audience}
`

import React from 'react'
import { node } from 'prop-types'
import { IconCaretUp, IconCaretDown } from 'cabanaico'
import { Row, Col } from 'react-bootstrap'

import CollapsibleBlock from 'shared/components/ui/Collapse/CollapsibleBlock'

export default class Index extends React.Component {
  static propTypes = {
    children: node.isRequired
  }

  constructor(props) {
    super(props)

    this.collapsibleBlockRef = React.createRef()
  }

  state = {
    opened: false,
    isCollapsible: true
  }

  componentDidMount() {
    const ref = this.collapsibleBlockRef.current

    this.setState({ isCollapsible: ref.scrollHeight > ref.clientHeight })
  }

  toggle = () => this.setState({ opened: !this.state.opened })

  render() {
    const { opened, isCollapsible } = this.state

    return (
      <Row>
        <Col>
          <CollapsibleBlock ref={ this.collapsibleBlockRef } opened={ opened }>
            { this.props.children }
          </CollapsibleBlock>
        </Col>
        { isCollapsible && (
          <Col xs={ 1 }>
            <span onClick={ this.toggle }>
              { opened ? <IconCaretUp/> : <IconCaretDown /> }
            </span>
          </Col>
        ) }
      </Row>
    )
  }
}

import React from 'react'
import styled from 'styled-components'
import { oneOf } from 'prop-types'

import FacebookPreview from './FacebookPreview'
import InstagramPreview from './InstagramPreview'
import PortalPreview from './PortalPreview'
import GmpPreview from './GmpPreview'
import PredefinedPreview from './PredefinedPreview'

const typeComponent = {
  facebook: FacebookPreview,
  facebook_dynamic_creative: FacebookPreview,
  instagram: InstagramPreview,
  portal: PortalPreview,
  gmp: GmpPreview,
  predefined: PredefinedPreview
}

const AdPreview = ({ type, className, ...props }) => {
  const Preview = typeComponent[type] || FacebookPreview
  return (
    <div className={ className } >
      <Preview { ...props } />
    </div>
  )
}

AdPreview.propTypes = {
  type: oneOf(Object.keys(typeComponent)).isRequired
}

export default styled(AdPreview)``

import { gql } from 'apollo-boost'

import campaign from 'graphql/fragments/campaign'

export default gql`
  mutation updateAudienceLocation(
    $uuid: ID!
    $operator: String!
    $radius: Float
  ) {
    updateAudienceLocation(
      uuid: $uuid
      radius: $radius
      operator: $operator
    ) {
      audienceLocation {
        uuid
        latitude
        longitude
        radius
        operator
        country
        placeName
        title
        locationType
      }
      campaign {
        ... Campaign
      }
    }
  }
  ${campaign}
`

import React from 'react'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'

import BaseMultiSelect from './MultiSelect'
import ValueContainer from './ValueContainer'
import Option from './Option'

const components = { ValueContainer, Option }

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1,
    maxWidth: '18.4rem'
  }),
  option: provided => ({
    ...condensedSelectStyles.option(provided),
    backgroundColor: 'white',
    padding: '0.125rem 0.75rem'
  }),
  valueContainer: provided => ({
    ...condensedSelectStyles.valueContainer(provided),
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }),
  menu: provided => ({
    ...provided,
    zIndex: 3
  })
}

const MultiSelect = (props) => {
  return (
    <BaseMultiSelect
      styles={ styles }
      hideSelectedOptions={ false }
      components={ components }
      { ...props }
    />
  )
}

export default MultiSelect

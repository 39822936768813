import React from 'react'
import { func, elementType, bool } from 'prop-types'
import Select from 'react-select'
import onClickOutside from 'react-onclickoutside'

class MultiSelect extends React.Component {
  static propTypes = {
    onChange: func.isRequired,
    component: elementType,
    disabled: bool
  }

  static defaultProps = {
    component: Select,
    disabled: false
  }

  constructor(props) {
    super(props)

    this.state = {
      menuIsOpen: false,
      // there is a bug in react-select with isMultiple=true, it fires `onChange` twice
      lastOnChangeCall: null,
      disabled: props.disabled || false
    }
  }

  onChange = (...args) => {
    const debounceWait = 100

    if (!this.state.lastOnChangeCall || this.state.lastOnChangeCall + debounceWait < Date.now()) {
      this._onChange(...args)
    }
  }

  onMenuOpen = () => {
    if (this.state.disabled) return

    this.setState({ menuIsOpen: true })
  }

  onMenuClose = () => {
    this.setState({ menuIsOpen: false })
  }

  handleClickOutside = () => {
    this.setState({ menuIsOpen: false })
  }

  _onChange = (...args) => {
    if (this.state.disabled) return

    this.props.onChange(...args)
    this.setState({ lastOnChangeCall: Date.now() })
  }

  render() {
    const { component: SelectComponent } = this.props

    return (
      <SelectComponent
        { ...this.props }
        isMulti
        menuIsOpen={ this.state.menuIsOpen }
        onMenuOpen={ this.onMenuOpen }
        onMenuClose={ this.onMenuClose }
        closeMenuOnSelect={ false }
        onChange={ this.onChange }
      />
    )
  }
}

export default onClickOutside(MultiSelect)

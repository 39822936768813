import React, { useCallback } from 'react'
import { string } from 'prop-types'
import { t }      from 'ttag'

import useRemoveCreativeSet from './useRemoveCreativeSet'

const RemoveCreativeSet = ({ uuid }) => {
  const removeCreativeSet = useRemoveCreativeSet()
  const handleClick = useCallback((e) => {
    e.preventDefault()

    if (confirm(t`Are you sure you want to remove this creative set?`)) {
      removeCreativeSet(uuid)
    }
  }, [uuid])

  return (
    <button
      type="button"
      className="btn btn-danger"
      onClick={ handleClick }
      data-test-id="remove-creative-set"
    >
      { t`Remove creative set` }
    </button>
  )
}

RemoveCreativeSet.propTypes = {
  uuid: string.isRequired
}

export default RemoveCreativeSet

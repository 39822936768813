import React from 'react'

import Card from 'shared/components/ui/Card'
import LabeledList from 'shared/components/ui/LabeledList'
import JustifiedDiv from 'shared/components/ui/JustifiedDiv'
import Paragraph from 'shared/components/ui/Paragraph'

const Campaigns = () => {
  const listItems = [{
    labelVariant: 'primary',
    labelText: 'Ongoing',
    content: (
      <JustifiedDiv>
        <Paragraph className="mb-0">
          Dyster eldor - Lead generation campaign
        </Paragraph>
        <Paragraph className="mb-0">
          2019 Apr 27, 7:54 pm  -  2019 Apr 27, 7:54 pm
        </Paragraph>
      </JustifiedDiv>
    )
  }, {
    labelVariant: 'secondary',
    labelText: 'Finished',
    content: (
      <JustifiedDiv>
        <Paragraph className="mb-0">
          Dyster eldor - Interest generation campaign
        </Paragraph>
        <Paragraph className="mb-0">
          2019 Apr 7, 7:54 pm  -  2019 Apr 17, 7:54 pm
        </Paragraph>
      </JustifiedDiv>
    )
  }]

  return (
    <Card collapsible header="Campaigns" bodyProps={{ className: 'p-0' }} >
      <LabeledList items={ listItems }/>
    </Card>
  )
}

export default Campaigns

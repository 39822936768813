import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'ttag'

import FormLabel from 'shared/components/ui/FormLabel'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'

import PhaseDatePicker from './PhaseDatePicker'
import { defaultFormatter, formatWithEndOf } from './phaseDateFormatter'

const MonthSelect = ({ dateChangeHandler, startDate }) => {
  const updateDate = (startDateValue) => {
    dateChangeHandler(
      defaultFormatter(startDateValue),
      formatWithEndOf(startDateValue, 'month')
    )
  }

  return(
    <>
      <FormLabel>{ t`Phase start` }</FormLabel>
      <InputWrapper>
        <PhaseDatePicker
          showMonthYearPicker
          data-test-id='month-picker'
          selected={ startDate }
          onChange={ updateDate }
          dateFormat="MM/yyyy" />
      </InputWrapper>
    </>
  )
}

MonthSelect.propTypes = {
  dateChangeHandler: PropTypes.func.isRequired,
  startDate: PropTypes.object
}

MonthSelect.defaultProps = {
  startDate: null
}

export default MonthSelect

import styled from 'styled-components'

export default styled.div`
  display: grid
  grid-template-columns: repeat(6, calc(100%/6 - .65rem));
  grid-gap: .75rem
  margin-top: .5rem
  margin-bottom: .5rem
  position: relative
`

import { arrayOf, number, string, shape } from 'prop-types'

import assetType from 'shared/types/assetType'

export default shape({
  uuid: string.isRequired,
  assetAssignments: arrayOf(shape({ asset: assetType, uuid: string })),
  name: string,
  nameDraft: string,
  phoneNumber: string,
  phoneNumberDraft: string,
  email: string,
  emailDraft: string,
  position: number
})

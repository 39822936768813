import React, { useCallback } from 'react'
import { array, func }        from 'prop-types'

import FormSelect from '../FormSelect'

const LocaleSelect = ({ onChange, options, ...props }) => {
  const handleChange = useCallback(({ value }) => {
    const [locale, countryCode] = value.split(/_|-/)

    onChange({ target: { name: 'locale', value: locale } })
    onChange({ target: { name: 'countryCode', value: countryCode } })
  }, [])

  return (
    <FormSelect
      { ...props }
      onChange={ handleChange }
      options={ options }
    />
  )
}

LocaleSelect.propTypes = {
  onChange: func.isRequired,
  options: array.isRequired
}

export default LocaleSelect

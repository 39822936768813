import React from 'react'
import styled from 'styled-components'

import assetType from 'shared/types/assetType'

const AssetLabel = ({ className, asset }) => {
  return (
    <div className={ className } >
      { asset.sizes && asset.sizes.join(' x ') }
    </div>
  )
}

AssetLabel.propTypes = {
  asset: assetType.isRequired
}
export default styled(AssetLabel)`
  position: absolute;
  width: 10rem;
  padding-top: 0.25em;
  font-size: 14px;
  text-align: center;
  color: #4a4a4a;
`

import React from 'react'
import { bool, object, func } from 'prop-types'
import { Marker } from 'react-mapbox-gl'

import audienceLocationType from 'shared/types/audienceLocationType'
import { MapMarkerIcon } from 'shared/components/ui/icons'

import { fitCircleBounds } from './helpers'

const LocationMarker = ({ location, map, highlighted, onMarkerClick }) => {
  const handleOnClick = () => {
    onMarkerClick(location)
    fitCircleBounds(map, location)
  }

  return (
    <Marker
      coordinates={ [location.longitude, location.latitude] }
      anchor='center'
      onClick={ handleOnClick }
    >
      <MapMarkerIcon className={
        `operator-${location.operator} ${highlighted && 'highlighted'}`
      }/>
    </Marker>
  )
}

LocationMarker.defaultProps = {
  map: null,
  highlighted: false
}

LocationMarker.propTypes = {
  location: audienceLocationType.isRequired,
  onMarkerClick: func.isRequired,
  highlighted: bool,
  map: object
}

export default LocationMarker

import { t } from 'ttag'

import Details from 'shared/components/NewBuilding/BuildingDetails/Details'
import Units from 'shared/components/NewBuilding/BuildingDetails/Units'
import Media from 'shared/components/NewBuilding/BuildingDetails/Media'

export default [
  {
    title: t`Building details`,
    component: Details
  },
  {
    title: t`Unit information`,
    component: Units
  },
  {
    title: t`Media`,
    component: Media
  }
]

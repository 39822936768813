import React, { useState, useCallback } from 'react'
import { t } from 'ttag'
import { object, func } from 'prop-types'
import styled from 'styled-components'

import ThriftyInput from 'shared/components/ui/ThriftyInput'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import Map from 'shared/components/NewStage/StageDetails/Details/Map'
import ZipCodeSelect from 'shared/components/NewStage/StageDetails/Details/ZipCodeSelect'
import CountriesSelect from 'shared/components/NewStage/StageDetails/Details/CountriesSelect'
import { iconsGreyColor } from 'shared/style/colors'

const Location = ({ className, location, updateLocation, errors }) => {
  const [pointMapToCoordinates, setPointMapToCoordinates] = useState()
  const [address, setAddress] = useState(location.address)
  const [zipCode, setZipCode] = useState(location.zipCode)
  const [countryCode, setCountryCode] = useState(location.countryCode)
  const [city, setCity] = useState(location.city)
  const [coordinates, setCoordinates] = useState({
    longitude: location.longitude,
    latitude: location.latitude
  })

  const handleAddressChange = useCallback((address) => {
    setAddress(address)
    updateLocation({ address })
  }, [address])

  const handleZipChange = useCallback(
    ({ value: zipCode, center, locationDetails: { context } }) => {
      const citySuggestion = context.find(({ id }) => id.startsWith('place.'))?.text
      const countryCodeSuggestion = context.find(
        ({ id }) => id.startsWith('country.')
      )?.short_code?.toUpperCase()

      const multipleStateUpdates = Promise.all([
        promisifyStateUpdate(setZipCode, 'zipCode', zipCode, true),
        promisifyStateUpdate(setCity, 'city', citySuggestion),
        promisifyStateUpdate(setCountryCode, 'countryCode', countryCodeSuggestion),
      ])

      multipleStateUpdates.then((updates) => {
        updateLocation(Object.assign({}, ...updates))
      })

      setPointMapToCoordinates(center)
    },
    [zipCode]
  )

  const promisifyStateUpdate = (setStateFunc, fieldName, newValue, forceUpdate = false) => {
    return new Promise((resolve) => {
      setStateFunc((currentValue) => {
        const currentValuePresent = currentValue && (currentValue.trim() !== '')

        if (forceUpdate || (newValue && !currentValuePresent)) {
          resolve({ [fieldName]: newValue })
          return newValue
        }

        resolve({})
        return currentValue
      })
    })
  }

  const handleCountryChange = useCallback((countryData) => {
    setCountryCode(countryData.value)
    updateLocation({ countryCode: countryData.value })
  }, [countryCode])

  const handleCityChange = useCallback((city) => {
    setCity(city)
    updateLocation({ city })
  }, [city])

  const handleLocationChange = useCallback((coordinates) => {
    setCoordinates({
      longitude: coordinates.longitude,
      latitude: coordinates.latitude
    })

    updateLocation({
      longitude: coordinates.longitude, latitude: coordinates.latitude
    })
  }, [coordinates])

  return (
    <div className={ className }>
      <div className="group" data-test-id='address-group'>
        <label>{ t`Address` }</label>
        <InputWrapper error={ errors.address }>
          <ThriftyInput
            type="text"
            value={ address }
            onUpdate={ handleAddressChange }
          />
        </InputWrapper>
      </div>

      <div className="form-inline">
        <div className="group w-50 pr-4">
          <label>{ t`Zip code` }</label>
          <InputWrapper error={ errors.zipCode }>
            <ZipCodeSelect
              value={ zipCode }
              countryCode={ countryCode }
              onChange={ handleZipChange }
            />
          </InputWrapper>
        </div>
        <div className="group w-50 pl-4">
          <label>{ t`City` }</label>
          <InputWrapper>
            <ThriftyInput
              className="w-100"
              type="text"
              value={ city }
              onUpdate={ handleCityChange }
            />
          </InputWrapper>
        </div>
      </div>

      <div className="group">
        <label>{ t`Country` }</label>
        <InputWrapper error={ errors.country || errors.countryCode }>
          <CountriesSelect
            setTouched={ () => {} }
            value={ countryCode }
            onChange={ handleCountryChange } />
        </InputWrapper>
      </div>

      <div className="group project-map">
        <label>
          <div>{ t`Location` }</div>
        </label>
        <InputWrapper error={ errors.latitude }>
          <Map
            className="project-map"
            mapHeight="300px"
            pointMapToCoordinates={ pointMapToCoordinates }
            onLocationChange={ handleLocationChange }
            marker={{
              latitude: coordinates.latitude, longitude: coordinates.longitude
            }}
          />
        </InputWrapper>
      </div>
    </div>
  )
}

Location.propTypes = {
  location: object.isRequired,
  updateLocation: func.isRequired,
  errors: object,
}

Location.defaultProps = {
  errors: {}
}

export default styled(Location)`
  input {
    box-shadow: none;
  }

  label {
    color: ${iconsGreyColor};
    display: block;
    font-size: 0.85rem;
    font-weight: normal;
    margin: 0;
  }

  .group {
    margin-bottom: 10px;
  }
`

import React             from 'react'
import { arrayOf, func, bool } from 'prop-types'

import UserRoleNavLink from 'routes/UserRoleNavLink'
import projectType     from 'shared/types/projectType'
import CardList        from 'shared/components/ui/CardDeck/List'

import ProjectCard from './ProjectCard'

const ProjectListView = ({ projects, buildLink, campaignsLiveCount }) => {
  return (
    <CardList>
      {
        projects.map((project) =>
          <UserRoleNavLink
            key={ project.uuid }
            to={ buildLink(project) }
            className={ 'no-text-decoration' }
            data-test-id='project-item'
          >
            <ProjectCard campaignsLiveCount={ campaignsLiveCount } project={ project } />
          </UserRoleNavLink>
        )
      }
    </CardList>
  )
}

ProjectListView.propTypes = {
  buildLink: func.isRequired,
  projects: arrayOf(projectType).isRequired,
  campaignsLiveCount: bool
}

ProjectListView.defaultProps = {
  campaignsLiveCount: true
}

export default ProjectListView

import { useCallback, useEffect, useState } from 'react'
import { get } from 'lodash'
import { useApolloClient } from '@apollo/react-hooks'

import addCreativeSetBannerMutation
  from 'graphql/mutations/campaign/creativeSets/addCreativeSetBanner'
import removeCreativeSetBannerMutation
  from 'graphql/mutations/campaign/creativeSets/removeCreativeSetBanner'
import formatsQuery from 'graphql/queries/formats'

const defaultFormats = []

const useFormatSelect = ({ companyUuid, creativeSetUuid, bannerTemplateCollectionUuid, force }) => {
  const client = useApolloClient()
  const [formats, setFormats] = useState(defaultFormats)

  useEffect(() => {
    if (bannerTemplateCollectionUuid !== undefined || force) {
      client
        .query({ query: formatsQuery, variables: { bannerTemplateCollectionUuid } })
        .then((response) => setFormats(get(response, 'data.formats', defaultFormats)))
    }
  }, [companyUuid, bannerTemplateCollectionUuid])

  const addCreativeSetBanner = useCallback((formatUuid) => {
    return (
      client.mutate({
        mutation: addCreativeSetBannerMutation,
        variables: { creativeSetUuid, formatUuid },
      })
    )
  }, [bannerTemplateCollectionUuid])

  const removeCreativeSetBanner = useCallback((formatUuid) => (
    client.mutate({
      mutation: removeCreativeSetBannerMutation,
      variables: { creativeSetUuid, formatUuid }
    })
  ), [bannerTemplateCollectionUuid])

  return {
    formats,
    addCreativeSetBanner,
    removeCreativeSetBanner,
  }
}

export default useFormatSelect

import Moment from 'moment'
import momentTimezone from 'moment-timezone'
import { extendMoment } from 'moment-range'
import momentDurationFormatSetup from 'moment-duration-format'
import { memoize } from 'lodash'
import { t } from 'ttag'

const moment = extendMoment(Moment)

momentDurationFormatSetup(moment)

export const startOfMonth = (value) => {
  const date = new Date(value)
  return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1)
}

export const datesArray = (start, end) => (
  Array.from(moment.range(start, end).by('day')).map(displayDate)
)

export const jsDate = (date) => {
  if (date) return moment(date).toDate()
  return null
}

export const todayDate = () => (
  moment().toDate()
)

export const todayOrFeatureDate = (date) => (
  moment.max(moment(), moment(date))
)

export const isBeforeNow = date => moment(date).isBefore(todayDate())

export const agoNowDate = (...args) => (
  moment().subtract(...args).toDate()
)

export const sinceDate = (date, ...args) => (
  moment(date).add(...args).toDate()
)

export const sinceNowDate = (...args) => (
  moment().add(...args).toDate()
)

export const daysAreEqual = (date1, date2) => (
  moment(date1).isSame(date2, 'day')
)

export const dateInThePast = (date) => (
  moment(date).diff(todayDate()) < 0
)

export const lessThanOneHourFromNow = (time) => (
  dateInThePast(moment(time).subtract(1, 'hour'))
)

export const sortByDates = (objectA, objectB) => {
  const startDateA = objectA.startDate
  const startDateB = objectB.startDate

  if (startDateA && !startDateB) return -1
  if (!startDateA && startDateB) return 1
  if (!startDateA && !startDateB) return 0

  const mStartDateA = moment(startDateA)
  const mStartDateB = moment(startDateB)

  if (mStartDateA > mStartDateB) return 1
  if (mStartDateA < mStartDateB) return -1
  if (moment(objectA.endDate) >= moment(objectB.endDate)) return 1
  return -1
}

export const localDate = (date) => (
  moment(date).format('DD/MM/YY')
)

export const localDateTime = (date) => {
  // Trick to correctly parse in firefox and safari
  if (date) return moment.utc(date, 'YYYY-MM-DD HH:mm:ss [UTC]').local().format('DD/MM/YY - HH:mm')
  return null
}

export const timelineDateTime = (date) => (
  moment(date).format('MMM DD, LT')
)

export const displayDate = (date) => (
  moment(date).format('MM/DD/YYYY')
)

export const displayTime = (date) => (
  moment(date).format('HH:mm')
)

export const countDifferenceInDays = (date1, date2) => (
  moment(date1).diff(moment(date2), 'days')
)

export const normalizeDate = (date) => (
  date ? moment(date).toDate() : null
)

export const timeZones = memoize(() => {
  return (
    momentTimezone.tz.names().map((timeZone) => ({
      name: timeZone,
      offset: moment.tz(timeZone).format('Z')
    }))
  )
})

export const formatDuration = (duration, units='seconds') => (
  moment.duration(duration, units).format('d[d] h[h] m[min]', { usePlural: false })
)

export const copyTime = (destinationDateTime, sourceDateTime) => {
  const newDateTime = moment(destinationDateTime.toDate())

  newDateTime.hours(sourceDateTime.hours())
  newDateTime.minutes(sourceDateTime.minutes())
  newDateTime.seconds(sourceDateTime.minutes())

  return newDateTime
}

export const formatDateForReport = (date) => {
  if (!date) return t`Not sold`

  return moment(date).tz('Europe/Stockholm').format('YYYY-MM-DD')
}

import gql from 'graphql-tag'

import vitecDocument from 'graphql/fragments/vitecDocument'

export default gql`
  query StageUnitVitecDocuments($unitUuid: ID!) {
    unitVitecDocuments(unitUuid: $unitUuid) {
      ... VitecDocument
    }
  }
  ${vitecDocument}
`

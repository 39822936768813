import React from 'react'
import { t } from 'ttag'
import { array, shape } from 'prop-types'

import Table from 'shared/components/ui/Table'

const booleanFormatter = (ableToEdit) => ableToEdit ? t`yes` : t`no`

const Stakeholders = ({ product: { stakeholders = [] } }) => {
  const tableOptions = {
    data: stakeholders,
    keyField: 'uuid',
    bordered: false,
    columns: [{
      dataField: 'uuid',
      hidden: true,
    }, {
      dataField: 'user.name',
      text: t`Name`,
      sort: true
    }, {
      dataField: 'responsibility',
      text: t`Role`,
      sort: true
    }, {
      dataField: 'permission',
      text: t`Able to edit`,
      sort: true,
      formatter: booleanFormatter
    }, {
      dataField: 'leadNotification',
      text: t`Lead notification`,
      sort: true,
      formatter: booleanFormatter
    }]
  }

  return (
    <Table tableOptions={ tableOptions } />
  )
}

Stakeholders.propTypes = {
  product: shape({
    stakeholders: array.isRequired
  }).isRequired
}

export default Stakeholders

import gql from 'graphql-tag'

import Asset           from 'graphql/fragments/asset'
import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  fragment LandingPage on LandingPage {
    uuid
    subtype
    custom
    portal
    name
    url
    urlsBasedOnCompanyDomains
    title
    intro
    projectDescription
    areaDescription
    salesArgument1
    salesArgument2
    salesArgument3
    flatFinderUrl
    flatFinderHeight
    minPrice
    maxPrice
    titleEnabled
    introEnabled
    projectDescriptionEnabled
    areaDescriptionEnabled
    salesArgumentsEnabled
    galleryEnabled
    showingsEnabled
    mapEnabled
    flatFinderEnabled
    unitsListEnabled
    autoPrices
    pricesEnabled
    agentsEnabled
    projectDescriptionImage {
      uuid
      asset {
        ... Asset
      }
    }
    areaDescriptionImage {
      uuid
      asset {
        ... Asset
      }
    }
    headerImage {
      uuid
      asset {
        ... Asset
      }
    }
    logo {
      uuid
      asset {
        ... Asset
      }
    }
    galleryImages {
      uuid
      asset {
        ... Asset
      }
    }
    template {
      uuid
      name
      url
      agentsEnabled
    }
    agents {
      uuid
      name
      phoneNumber
      email
      title
      assetAssignments {
        ... AssetAssignment
      }
    }
  }
  ${Asset}
  ${assetAssignment}
`

import { css } from 'styled-components'

import sfUiTextBold from 'shared/assets/fonts/SF-UI-Text-Bold.otf'
import sfUiTextBoldItalic from 'shared/assets/fonts/SF-UI-Text-BoldItalic.otf'
import sfUiTextHeavy from 'shared/assets/fonts/SF-UI-Text-Heavy.otf'
import sfUiTextHeavyItalic from 'shared/assets/fonts/SF-UI-Text-HeavyItalic.otf'
import sfUiTextItalic from 'shared/assets/fonts/SF-UI-Text-Italic.otf'
import sfUiTextLight from 'shared/assets/fonts/SF-UI-Text-Light.otf'
import sfUiTextLightItalic from 'shared/assets/fonts/SF-UI-Text-LightItalic.otf'
import sfUiTextMedium from 'shared/assets/fonts/SF-UI-Text-Medium.otf'
import sfUiTextMediumItalic from 'shared/assets/fonts/SF-UI-Text-MediumItalic.otf'
import sfUiTextRegular from 'shared/assets/fonts/SF-UI-Text-Regular.otf'
import sfUiTextSemibold from 'shared/assets/fonts/SF-UI-Text-Semibold.otf'
import sfUiTextSemiboldItalic from 'shared/assets/fonts/SF-UI-Text-SemiboldItalic.otf'

export default css`
  @font-face {
    font-family: 'SF UI';
    font-style: normal;
    font-weight: 700;
    src: url(${sfUiTextBold})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: italic;
    font-weight: 700;
    src: url(${sfUiTextBoldItalic})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: normal;
    font-weight: 900;
    src: url(${sfUiTextHeavy})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: italic;
    font-weight: 900;
    src: url(${sfUiTextHeavyItalic})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: italic;
    font-weight: 400;
    src: url(${sfUiTextItalic})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: normal;
    font-weight: 300;
    src: url(${sfUiTextLight})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: italic;
    font-weight: 300;
    src: url(${sfUiTextLightItalic})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: normal;
    font-weight: 500;
    src: url(${sfUiTextMedium})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: italic;
    font-weight: 500;
    src: url(${sfUiTextMediumItalic})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: normal;
    font-weight: 400;
    src: url(${sfUiTextRegular})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: normal;
    font-weight: 600;
    src: url(${sfUiTextSemibold})
  }
  @font-face {
    font-family: 'SF UI';
    font-style: italic;
    font-weight: 600;
    src: url(${sfUiTextSemiboldItalic})
  }
  
  body {
    font-family: 'SF UI', 'Arial', sans-serif;
  }
`

import React from 'react'
import Select from 'react-select'
import { t } from 'ttag'
import { number, oneOf } from 'prop-types'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    width: '5.5rem'
  })
}

const possibleOptions = [
  { from: 18, to: 24 },
  { from: 25, to: 34 },
  { from: 35, to: 44 },
  { from: 45, to: 54 },
  { from: 55, to: 64 },
  { from: 65, to: 65 }
]

const AgeSelect = ({ defaultValue, value, mode, ...props }) => {
  const options = possibleOptions.map(el => ({ value: el[mode], label: el[mode] }))
  const defaultOption = defaultValue ? options.find(el => el.value === defaultValue) : options[0]

  const selectedOption = value ? options.find(el => el.value === value) : defaultOption

  return(
    <Select
      value={ selectedOption }
      options={ options }
      styles={ styles }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

AgeSelect.propTypes = {
  mode: oneOf(['from', 'to']).isRequired,
  defaultValue: number,
  value: number
}

AgeSelect.defaultProps = {
  defaultValue: null,
  value: null
}

export default AgeSelect

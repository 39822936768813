import { buildFragmentParams } from 'shared/helpers/graphql'
import stageUnitsList from 'graphql/fragments/stageUnitsList'
import createUnitMutation from 'graphql/mutations/projects/stages/units/createUnitMutation'

const createUnit = (client, stage) => {
  client.mutate({
    mutation: createUnitMutation,
    variables: {
      stageUuid: stage.uuid,
      attributes: {}
    },
    update: (cache, { data: { createStageUnit: { unit } } }) => {
      const fragmentParams = buildFragmentParams(stage, stageUnitsList)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          units: [
            ...fragmentData.units,
            unit
          ]
        }
      })
    }
  })
}

export default createUnit

import gql from 'graphql-tag'

import landingPage from 'graphql/fragments/landingPage'

export default gql`
  fragment CampaignLandingPages on Campaign {
    landingPages {
      ... LandingPage
    }
  }
  ${landingPage}
`

import React from 'react'
import { bool, string, object, func, node } from 'prop-types'
import { Overlay as BSOverlay } from 'react-bootstrap'

import Popover from './Popover'

const Overlay = ({ children, onClose, ...props }) => {
  return (
    <BSOverlay { ...props }>
      <Popover onClose={ onClose }>
        { children }
      </Popover>
    </BSOverlay>
  )
}

Overlay.propTypes = {
  children: node.isRequired,
  onClose: func.isRequired,
  container: object,
  placement: string,
  show: bool,
  target: object
}

Overlay.defaultProps = {
  container: undefined,
  placement: 'auto',
  show: false,
  target: undefined
}

export default Overlay

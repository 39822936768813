import React from 'react'
import { shape, string } from 'prop-types'
import { t } from 'ttag'

import SubHeader from 'shared/components/ui/Card/SubHeader'

import LanguageTargeting from '../LanguageTargeting'

const AgeAndGender = ({ audience }) => {
  return (
    <div className="mb-5">
      <SubHeader>{ t`Audience Targeting` }</SubHeader>
      <LanguageTargeting audience={ audience } />
    </div>
  )
}

AgeAndGender.propTypes = {
  audience: shape({
    platformAudience: shape({
      gender: string.isRequired
    })
  }).isRequired,
}

export default AgeAndGender

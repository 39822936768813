import gql from 'graphql-tag'

import creativeSet from 'graphql/fragments/creativeSet'

export default gql`
  mutation removeCreativeSetBanner(
    $creativeSetUuid: ID!,
    $formatUuid: ID!
  ) {
    removeCreativeSetBanner(
      creativeSetUuid: $creativeSetUuid,
      formatUuid: $formatUuid
    ) {
      creativeSet {
        ... CreativeSet
      }
    }
  }
  ${creativeSet}
`

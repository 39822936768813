import { gql } from 'apollo-boost'

import productStakeholder from 'graphql/fragments/productStakeholder'

export default gql`
  mutation addProductStakeholder(
    $productUuid: ID!
    $attributes: StakeholderAttributes!
  ) {
    addProductStakeholder(
      productUuid: $productUuid
      attributes: $attributes
    ) {
      stakeholder {
        ... ProductStakeholder
      }
    }
  }
  ${productStakeholder}
`

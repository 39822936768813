import styled from 'styled-components'

import PlusIcon from 'shared/components/ui/icons/PlusIcon'
import SubHeader from 'shared/components/ui/Card/SubHeader'
import {
  textGreyColor,
  textHeaderColor,
  galleryGreyColor,
  buttonBorderColor,
  redColor
} from 'shared/style/colors'

export const AudienceContainer = styled.div`
  display: flex;
  border-top: solid 1px ${galleryGreyColor};
  margin: -1.1rem -1.45rem;
`

export const LeftColumn = styled.div`
  border-right: solid 1px ${galleryGreyColor};
  padding-left: 1.45rem;
  padding-right: 3.75rem
  padding-bottom: 1rem;
  flex-grow: 1;

  ${SubHeader}:not(:first-child) {
    margin-top: 1rem;
  }
`

export const RightColumn = styled.div`
  width: 26rem;
`

export const Title = styled.h6`
  color: ${textGreyColor}
  font-size: .8rem
  margin-bottom: .65rem
`

export const Separator = styled.span`
  line-height: 2.375rem
  padding: 0 1rem
`

export const ErrorMessage = styled.div`
  font-size: 0.75rem;
  color: ${redColor};
`

export const CheckboxGroup = styled.div`
  display: flex;

  > * {
    width: 30%;
  }
`

export const Group = styled.div`
  display: flex;
  margin-bottom: 0.65rem;
  align-items: center;
`

export const Label = styled.label`
  color: ${textGreyColor};
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-right: 1rem;
  width: 8rem;
  flex-shrink: 0;
`

export const PlacementsHeader = styled.div`
  align-items: baseline;
  border-bottom: solid 1px ${galleryGreyColor};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  width: 100%;

  span {
    color: ${textHeaderColor};
    font-weight: 500;
  }

  button {
    background-color: transparent;
    border: none;
    color: ${textHeaderColor};
    font-weight: 600;
    opacity: 0.5;
    text-transform: uppercase;
  }
`

export const PlacementGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  margin-bottom: 1rem;

  > ul {
    list-style-type: none;
    padding-left: 1rem;
    margin: 0;

    li {
      align-items: center;
      color: ${textHeaderColor};
      display: flex;
      justify-content: space-between;
      min-height: 2rem;
      margin-bottom: 0.5rem;

      ${PlusIcon} {
        height: 1rem;
        width: 1rem;
        cursor: pointer;
      }
    }
  }
`

export const PlacementGroupHeader = styled.div`
  color: ${textHeaderColor};
  font-weight: 500;
  margin-bottom: 0.5rem;
`

export const Input = styled.input`
  border-radius: 0.25rem;
  border: solid 1px ${buttonBorderColor};
  color: ${textHeaderColor};
  font-size: 0.875rem;
  padding: 0.3125rem 0.5rem;
`

export const UrlBundleListItem = styled.li`
  strong {
    display: block;
    font-size: 0.75rem;
    line-height: 0.825rem;
    font-weight: 500;
  }

  div {
    font-size: 0.625rem;
    line-height: 0.75rem;
    font-weight: 500;
  }
`

import gql from 'graphql-tag'

export default gql`
  query audiences($channelUuid: ID!, $search: String!) {
    audiences(channelUuid: $channelUuid, search: $search) {
      uuid
      name
      campaign {
        uuid
        name
        project {
          uuid
          name
        }
        stage {
          uuid
          name
        }
      }
    }
  }
`

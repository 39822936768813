import { gql } from 'apollo-boost'

import snapshotUnitSet from 'graphql/fragments/snapshotUnitSet'

export default gql`
  mutation createSnapshotUnitSet($snapshotUuid: ID!) {
    createSnapshotUnitSet(snapshotUuid: $snapshotUuid) {
      unitSet {
        ... SnapshotUnitSet
      }
    }
  }
  ${snapshotUnitSet}
`

import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Spinner } from 'react-bootstrap'

import { IconSuccess } from 'shared/components/ui/icons'
import { darkRoseColor } from 'shared/style/colors'

const SelectableProjectImage = ({ asset, isSelected, onClickHandler, busy }) => {
  return (
    <>
      <AssetContainer>
        { isSelected && (<IconSuccess/>) }
        { busy && (<Spinner animation='border' variant='secondary' className='busy-spin' />) }
        <img
          onClick={ ()=> onClickHandler(asset.uuid) }
          className={ isSelected ? 'enabled' : 'disabled' }
          data-test-id={ `${asset.uuid}-asset` }
          src={ asset.thumbnail } alt="Media" />
      </AssetContainer>
    </>
  )
}

SelectableProjectImage.propTypes = {
  asset: PropTypes.object.isRequired,
  busy: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired
}

const AssetContainer = styled.div`
  cursor: pointer;
  overflow: hidden;
  padding-top: 70%;
  position: relative;

  .icon-success {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
  }

  .busy-spin {
    position: absolute;
    top: 37%;
    left: 39%;
    z-index: 1;
  }

  img.disabled {
    opacity: .3;
  }

  .icon-zoom-plus,
  .asset-remove-button {
    border-radius: 0 0 0 .25rem;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    transition: all .5s ease;
    z-index: 1;
  }

  .icon-zoom-plus {
    right: 20px;
    line-height: 1rem;
  }

  .asset-remove-button {
    line-height: 0;
    height: 1rem;
    width: 1rem;
    right: 0;
    color: ${darkRoseColor};
  }

  &:hover {
    .icon-zoom-plus,
    .asset-remove-button {
      opacity: 1;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`

export default memo(SelectableProjectImage)

import { gql } from 'apollo-boost'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  mutation updateAssetAssignment(
    $uuid: ID!,
    $input: AssetAssignmentInput!
  ) {
    updateAssetAssignment(
      uuid: $uuid
      input: $input
    ) {
      assetAssignment {
        ... AssetAssignment
      }
    }
  }
  ${assetAssignment}
`

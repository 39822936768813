import { get, pick, isEqual } from 'lodash'

export const fragmentName = (fragment) => (
  get(fragment, 'definitions[0].name.value')
)

export const dataIdFromObject = (object) => (
  object.uuid ? `${object.__typename}/${object.uuid}` : null
)

export const buildFragmentParams = (object, fragment) => ({
  id:           dataIdFromObject(object),
  fragment:     fragment,
  fragmentName: fragmentName(fragment)
})

export const isQueryWatchedInCache = (cache, query, variables) => {
  return [...cache.watches].some((watch) => {
    const queriesAreEqual = isEqual(
      pick(watch.query, ['kind', 'definition', 'loc']),
      pick(query, ['kind', 'definition', 'loc']),
    )

    const variablesAreEqual = isEqual(watch.variables, variables)

    return queriesAreEqual && variablesAreEqual
  })
}

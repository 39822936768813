import React            from 'react'
import { useQuery }     from '@apollo/react-hooks'
import { get }          from 'lodash'
import { number, func } from 'prop-types'

import projectType from 'shared/types/projectType'

import Card    from '../Card'
import useTabs from '../useTabs'

import salesDetailsQuery from './salesDetailsQuery'
import TabBar            from './TabBar'
import StagesDetailsInfo from './StagesDetailsInfo'

const StagesDetails = ({ project, stageTab, handleTabChange }) => {
  const { data } = useQuery(
    salesDetailsQuery,
    {
      variables: {
        scopedTo: (stageTab === 0 ? 'Project' : 'Stage'),
        uuid:     (stageTab === 0 ? project.uuid : project.completeStages[stageTab - 1].uuid)
      }
    }
  )
  const salesDetails = get(data, 'projectSalesDetailsAnalytics.data', null)

  const { tabs } = useTabs(project)

  return (
    <div data-test-id='stage-details-card'>
      <Card>
        <TabBar
          tabs={ tabs }
          activeTab={ stageTab }
          onChange={ handleTabChange }
        />
      </Card>

      {salesDetails && (
        <Card>
          <StagesDetailsInfo tabs={ tabs } activeTab={ stageTab } salesDetails={ salesDetails } />
        </Card>
      )}
    </div>
  )
}

StagesDetails.propTypes = {
  handleTabChange: func.isRequired,
  project: projectType.isRequired,
  stageTab: number.isRequired
}

export default StagesDetails

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { iconsGreyColor } from '../../style/colors'

const FormLabel = ({ children, className }) => {
  return (
    <label className={ className }>{ children }</label>
  )
}

FormLabel.propTypes = {
  children: PropTypes.node.isRequired
}

export default styled(FormLabel)`
  color: ${iconsGreyColor};
  display: flex;
  justify-content: flex-start;
  font-size: 0.85rem;
  font-weight: normal;
  margin: 0;
  flex-direction: column;
`

import React     from 'react'
import { t }     from 'ttag'
import styled    from 'styled-components'
import { array } from 'prop-types'

import BaseTable from 'shared/components/ui/Table'
import { cardPaddingXSize, cardPaddingYSize } from 'shared/style/sizes'
import deviceFormatter        from 'shared/components/Profile/Sessions/Table/deviceFormatter'
import createdAtFormatter     from 'shared/components/Profile/Sessions/Table/createdAtFormatter'
import sessionStatusFormatter from 'shared/components/Profile/Sessions/Table/sessionStatusFormatter'

const Table = ({ className, data }) => {
  const tableOptions = {
    data,
    keyField: 'createdAt',
    remote: { filter: false, pagination: false },
    columns: [{
      dataField: 'aud',
      sort: true,
      text: t`Device`,
      formatter: deviceFormatter,
    }, {
      dataField: 'createdAt',
      sort: true,
      text: t`Time`,
      formatter: createdAtFormatter,
    }, {
      dataField: '',
      text: '',
      formatter: sessionStatusFormatter
    }],
  }

  return (
    <BaseTable className={ className } tableOptions={ tableOptions } />
  )
}

Table.propTypes = {
  data: array.isRequired
}

export default styled(Table)`
  margin-left: -${cardPaddingXSize}
  margin-right: -${cardPaddingXSize}
  width: calc(100% + ${cardPaddingXSize} + ${cardPaddingXSize});
  
  margin-top: 2rem

  table, td {
    border-left: none
    border-right: none
  }
  
  table {
    border-top: none
  }
  
  .table {  
    margin-bottom: -${cardPaddingYSize}
  }
`


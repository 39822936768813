import { useQuery } from '@apollo/react-hooks'
import { object }   from 'prop-types'

import companyQuery from 'graphql/queries/company'

const useSource = (property) => {
  const { data, loading } = useQuery(companyQuery, {
    variables: {
      uuid: property.companyUuid
    }
  })

  if (loading) {
    return ''
  }

  return data.company.source
}

useSource.propTypes = {
  property: object.isRequired
}

export default useSource

import React from 'react'
import { t } from 'ttag'
import { toast } from 'react-toastify'
import { array, bool, func } from 'prop-types'
import { useMutation } from 'react-apollo'
import styled from 'styled-components'
import { Alert } from 'react-bootstrap'

import Table from 'shared/components/ui/Table'
import discardChangesetMutation from 'graphql/mutations/campaign/discardChangeset'
import { localDateTime } from 'shared/helpers/date'
import { bluishColor } from 'shared/style/colors'
import PlusIcon from 'shared/components/ui/icons/PlusIcon'

import { userName } from './formatters/userName'
import { humanizedMessage } from './formatters/humanizedMessage'
import discardButtonColumnFormatter from './formatters/discardButtonColumnFormatter'

const CampaignChangelogsTable = ({
  changesets,
  setChangesets,
  publishingMode,
  discardAllDisabled,
  setDiscardAllDisabled
}) => {
  const [discardChangeset] = useMutation(discardChangesetMutation)

  const enableDiscardButton = (changesets) => {
    if (changesets.length > 1) {
      setDiscardAllDisabled(false)
    }
  }

  const handleSingleDiscard = (key) => {
    const canDiscard = !publishingMode && !discardAllDisabled
    if (canDiscard && confirm(t`Are you sure you want to discard this change?`)) {
      const originalChangesets = changesets
      const newChangesets = changesets.filter(changeset => changeset.key !== key)
      const toastLoading = toast.info(t`Discarding changes...`)

      setChangesets(newChangesets)
      setDiscardAllDisabled(true)
      discardChangeset({ variables: { changesetKey: key } })
        .then(() => {
          toast.update(toastLoading, {
            type: 'success',
            render: t`The change has been discarded.`
          })
          enableDiscardButton(changesets)
        })
        .catch(() => {
          setChangesets(originalChangesets)
          toast.update(toastLoading, {
            type: 'error',
            render: t`An error occurred while discarding the change.`
          })
          enableDiscardButton(changesets)
        })
    }
  }

  const columns = [{
    dataField: 'updatedAt',
    text: t`Timestamp`,
    formatter: (cell, _row) => localDateTime(cell),
  }, {
    dataField: 'userName',
    text: t`User`,
    formatter: (cell, row) => userName(cell, row),
  }, {
    dataField: 'humanizedMessage',
    text: t`Description`,
    formatter: (cell, row) => humanizedMessage(cell, row),
  },
  {
    dataField: 'key',
    className: 'discard-single-changeset',
    text: '',
    formatter: discardButtonColumnFormatter,
    formatExtraData: { publishingMode, discardAllDisabled, handleSingleDiscard }
  }]

  const rowClasses = (row, _rowIndex) => {
    return row.errorMessages.length > 0 ? 'validation-error' : ''
  }

  const tableOptions = {
    columns,
    rowClasses,
    data: changesets,
    keyField: 'key',
    bordered: false
  }

  if (changesets.length === 0) {
    return (<Alert variant="info">{ t`No records found` }</Alert>)
  }

  return (!!columns.length && <StyledTable tableOptions={ tableOptions } />)
}

const StyledTable = styled(Table)`
  tr {
    .error-message {
      display: none
    }

    &.validation-error {
      .error-message {
        display: block
        color: red
      }
    }
  }

  td {
    vertical-align: top
  }

  code {
    color: ${bluishColor}
    i {
      text-transform: capitalize
    }
  }

  .thumbnail {
    position: relative
    z-index: 150
  }

  .thumbnail:hover {
    background-color: transparent
    z-index: 200
  }

  .thumbnail > img:first-of-type {
    height: 1.1rem
    cursor: zoom-in
    border: 1px solid grey
    border-radius: 0.15rem
    margin: 0 0.2rem
  }

  .thumbnail > img:nth-of-type(2) {
    visibility: hidden
    position: fixed
    top: 0.5rem
    left: 0
    right: 0
    margin: auto
    border: 2px solid grey
    border-radius: 0.25rem
  }

  .thumbnail:hover > img:nth-of-type(2) {
    visibility: visible
  }
  
   ${PlusIcon} {
    cursor: pointer
    margin: -0.25rem 0 0 1rem
  }
  
  .delete-button.disabled {
    cursor: default;
  }
`

CampaignChangelogsTable.propTypes = {
  changesets: array.isRequired,
  discardAllDisabled: bool.isRequired,
  publishingMode: bool.isRequired,
  setChangesets: func.isRequired,
  setDiscardAllDisabled: func.isRequired
}

export default CampaignChangelogsTable

import { gql } from 'apollo-boost'

export default gql`
  mutation inviteProductStakeholder(
    $productUuid: ID!
    $attributes: CreateUserAttributesInput!
  ) {
    inviteProductStakeholder(
      productUuid: $productUuid
      attributes: $attributes
    ) {
      stakeholder {
        uuid
        responsibility
        permission
        user {
          uuid
          nameWithEmail
          synced
        }
        leadNotification
        weeklyReport
      }
    }
  }
`

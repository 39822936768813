import { gql } from 'apollo-boost'

import DashboardDataset from 'graphql/fragments/dashboardDataset'

export default gql`
  query DashboardAnalyticsAverageCtr($filter: JSON) {
    dashboardAnalyticsAverageCtr(filter: $filter) {
      averageCtr {
        ... DashboardDataset
      }
    }
  }
  ${DashboardDataset}
`

import React    from 'react'
import { func } from 'prop-types'
import { t }    from 'ttag'
import styled   from 'styled-components'

import DeleteButtonTag from 'shared/components/ui/DeleteButton'

const DeleteButton = ({ onDelete, className }) => {
  const handleDelete = () => {
    if (confirm(t`Are you sure you want to remove company?`)) {
      onDelete()
    }
  }

  return <DeleteButtonTag onClick={ handleDelete } className={ className } />
}

DeleteButton.propTypes = {
  onDelete: func.isRequired
}

export default styled(DeleteButton)`
  margin: 0 !important;

  &:hover {
    background: none !important;
  }
`

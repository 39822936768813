import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { t } from 'ttag'
import styled from 'styled-components'
import { useMutation } from 'react-apollo'
import { isEqual } from 'lodash'

import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'
import BigNumberInput from 'shared/components/ui/ThriftyInput/BigNumber'
import FormLabel from 'shared/components/ui/FormLabel'
import Checkbox from 'shared/components/ui/Checkbox'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import updateSnapshotUnitSetMutation from 'graphql/mutations/snapshots/unitSets/update'

import OwnershipTypeSelect from '../OwnershipTypeSelect'

import BuildingTypeSelect from './BuildingTypeSelect'

const SnapshotUnitSet = ({ className, unitSet, currency, updateUnitSetState }) => {
  const [runUpdateSnapshotUnitSetMutation] = useMutation(updateSnapshotUnitSetMutation, {
    ignoreResults: true
  })

  const handleNumberFormChange = useCallback((value, fieldName, float = false) => {
    let providedValue = null

    if (float) {
      providedValue = parseFloat(value.replace(/,/g, '.'))
    } else {
      providedValue = parseInt(value)
    }

    if (!providedValue || isNaN(providedValue)) {
      updateUnitSetState(unitSet.uuid, { [fieldName]: unitSet[fieldName] })
      return
    }

    const data = { [fieldName]: providedValue }
    updateUnitSetState(unitSet.uuid, data)
    return runUpdateSnapshotUnitSetMutation({
      variables: {
        uuid: unitSet.uuid,
        input: data
      }
    })
  }, [unitSet])

  const handleStringFormChange = useCallback((value, fieldName) => {
    const data = { [fieldName]: value }
    updateUnitSetState(unitSet.uuid, data)

    return runUpdateSnapshotUnitSetMutation({
      variables: {
        uuid: unitSet.uuid,
        input: data
      }
    })
  }, [unitSet.uuid])

  const handleSelectInputChange = useCallback((fieldName, value) => {
    const inputValue = value.target.value
    const data = { [fieldName]: inputValue }
    updateUnitSetState(unitSet.uuid, data)

    return runUpdateSnapshotUnitSetMutation({
      variables: {
        uuid: unitSet.uuid,
        input: data
      }
    })
  }, [unitSet.uuid])

  return (
    <div className={ className }>
      <div className="form-inline">
        <div className="group w-50 pr-4">
          <FormLabel>{t`Unit type`}</FormLabel>
          <InputWrapper>
            <BuildingTypeSelect
              className="w-100 mw-100"
              name="buildingType"
              data-test-id='building-type-selector'
              value={ unitSet.buildingType }
              onChange={ (value) => handleSelectInputChange('buildingType', value) }
            />
          </InputWrapper>
        </div>
        <div className="group w-50 pl-4">
          <FormLabel>{t`Ownership type`}</FormLabel>
          <InputWrapper>
            <StyledOwnershipTypeSelect
              className="w-100 ownership-type-select"
              name="ownershipType"
              data-test-id='ownership-type-selector'
              value={ unitSet.ownershipType }
              onChange={ (value) => handleSelectInputChange('ownershipType', value) }
            />
          </InputWrapper>
        </div>
      </div>

      <div className="form-inline">
        <div className="group w-50 pr-4">
          <FormLabel>{t`Total number of units`}</FormLabel>
          <InputWrapper>
            <BigNumberInput
              className="w-100"
              data-test-id='units-count-field'
              value={ unitSet.unitsCount }
              onUpdate={ value => handleNumberFormChange(value, 'unitsCount') }
            />
          </InputWrapper>
        </div>
        <div className="group w-50 pl-4">
          <FormLabel>{t`Units sold`}</FormLabel>
          <InputWrapper>
            <BigNumberInput
              className="w-100"
              data-test-id='units-sold-field'
              value={ unitSet.unitsSold }
              onUpdate={ value => handleNumberFormChange(value, 'unitsSold') }
            />
          </InputWrapper>
        </div>
      </div>
      <div className="form-inline">
        <div className="double-input">
          <div className="group">
            <FormLabel>{t`Size from (m2)`}</FormLabel>
            <InputWrapper className="w-100 pr-5">
              <ThriftyInput
                className="w-100"
                data-test-id='lower-size-m2-field'
                value={ unitSet.lowerSizeM2 }
                onUpdate={ value => handleNumberFormChange(value, 'lowerSizeM2', true) }
              />
            </InputWrapper>
          </div>
          <div className="group">
            <FormLabel>{t`Size to (m2)`}</FormLabel>
            <InputWrapper className="w-100 pr-4">
              <ThriftyInput
                className="w-100"
                data-test-id='upper-size-m2-field'
                value={ unitSet.upperSizeM2 }
                onUpdate={ value => handleNumberFormChange(value, 'upperSizeM2', true) }
              />
            </InputWrapper>
          </div>
        </div>
        <div className="group checkbox-wrap">
          <FormLabel>{t`Subj. to change`}</FormLabel>
          <InputWrapper className="checkbox-input">
            <Checkbox
              checked={ unitSet.sizeSubjectToChange }
              type="checkbox"
              data-test-id='size-subject-to-change-checkbox'
              onChange={ () => {
                return handleStringFormChange(
                  !unitSet.sizeSubjectToChange,
                  'sizeSubjectToChange'
                )
              } }
            />
          </InputWrapper>
        </div>
      </div>
      <div className="form-inline">
        <div className="double-input">
          <div className="group">
            <FormLabel>{t`Bedrooms from`}</FormLabel>
            <InputWrapper className="w-100 pr-5">
              <BigNumberInput
                className="w-100"
                data-test-id='lower-bedroom-count-field'
                value={ unitSet.lowerBedroomCount }
                onUpdate={ value => handleNumberFormChange(value, 'lowerBedroomCount') }
              />
            </InputWrapper>
          </div>
          <div className="group">
            <FormLabel>{t`Bedroom to`}</FormLabel>
            <InputWrapper className="w-100 pr-4">
              <BigNumberInput
                className="w-100"
                data-test-id='upper-bedroom-count-field'
                value={ unitSet.upperBedroomCount }
                onUpdate={ value => handleNumberFormChange(value, 'upperBedroomCount') }
              />
            </InputWrapper>
          </div>
        </div>
        <div className="group checkbox-wrap">
          <FormLabel>{t`Subj. to change`}</FormLabel>
          <InputWrapper className="checkbox-input">
            <Checkbox
              checked={ unitSet.bedroomsSubjectToChange }
              type="checkbox"
              data-test-id='bedrooms-subject-to-change-checkbox'
              onChange={ () => {
                return handleStringFormChange(
                  !unitSet.bedroomsSubjectToChange,
                  'bedroomsSubjectToChange'
                )
              } }
            />
          </InputWrapper>
        </div>
      </div>
      <div className="form-inline">
        <div className="double-input">
          <div className="group">
            <FormLabel>{t`Price from (${currency})`}</FormLabel>
            <InputWrapper className="w-100 pr-5">
              <BigNumberInput
                className="w-100"
                data-test-id='lower-price-field'
                value={ unitSet.lowerPrice }
                onUpdate={ value => handleNumberFormChange(value, 'lowerPrice') }
              />
            </InputWrapper>
          </div>
          <div className="group">
            <FormLabel>{t`Price to (${currency})`}</FormLabel>
            <InputWrapper className="w-100 pr-4">
              <BigNumberInput
                className="w-100"
                data-test-id='upper-price-field'
                value={ unitSet.upperPrice }
                onUpdate={ value => handleNumberFormChange(value, 'upperPrice') }
              />
            </InputWrapper>
          </div>
        </div>
        <div className="group checkbox-wrap">
          <FormLabel>{t`Subj. to change`}</FormLabel>
          <InputWrapper className="checkbox-input">
            <Checkbox
              checked={ unitSet.priceSubjectToChange }
              type="checkbox"
              data-test-id='price-subject-to-change-checkbox'
              onChange={ () => {
                return handleStringFormChange(
                  !unitSet.priceSubjectToChange,
                  'priceSubjectToChange'
                )
              } }
            />
          </InputWrapper>
        </div>
      </div>
    </div>
  )
}

SnapshotUnitSet.propTypes = {
  currency: PropTypes.string.isRequired,
  unitSet: PropTypes.object.isRequired,
  updateUnitSetState: PropTypes.func.isRequired
}

const StyledOwnershipTypeSelect = styled(OwnershipTypeSelect)`
  div {
    font-size: .75rem;
    font-weight: normal;
  }
`

const avoidRerender = (prev, next) => isEqual(prev.unitSet, next.unitSet)

export default styled(memo(SnapshotUnitSet, avoidRerender))`
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;
  margin-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
`

import React from 'react'
import { node, func } from 'prop-types'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'

const AppErrorBoundary = ({ children, ErrorView, ...props }) => {
  const FallbackWrapper = () => {
    toast.error('Oops! Something went wrong :(')

    return (ErrorView && <ErrorView/>) || null
  }

  return (
    <Sentry.ErrorBoundary fallback={ FallbackWrapper } { ...props }>
      { children }
    </Sentry.ErrorBoundary>
  )
}

AppErrorBoundary.propTypes = {
  children: node.isRequired,
  ErrorView: func
}

AppErrorBoundary.defaultProps = {
  ErrorView: null
}

export default AppErrorBoundary

import React from 'react'
import { arrayOf, bool, func, shape, string, number, oneOfType } from 'prop-types'
import { get, pick } from 'lodash'

import Modal from 'shared/components/ui/Modal'

import { Header } from './elements'

import LeadForm   from './index'

const LeadModal = ({ lead, title, show, onHide, onSubmit }) => {
  const initialValues = {
    ...pick(lead, 'fullName', 'email', 'phoneCode', 'phoneNumber', 'address'),
    projectUuid: get(lead, 'project.uuid', ''),
    stageUuid: get(lead, 'stage.uuid', ''),
    unitUuids: lead.units.map(el => el.uuid)
  }

  const handleSubmit = async values => {
    await onSubmit(values)
    onHide()
  }

  return (
    <Modal centered show={ show } onHide={ onHide }>
      <Modal.Header closeButton>
        <Header>{ title }</Header>
      </Modal.Header>

      <LeadForm
        initialValues={ initialValues }
        onSubmit={ handleSubmit }
        onHide={ onHide }
      />
    </Modal>
  )
}

LeadModal.propTypes = {
  onSubmit: func.isRequired,
  show: bool.isRequired,
  title: string.isRequired,
  lead: shape({
    fullName: string.isRequired,
    phoneCode: oneOfType([string, number]),
    phoneNumber: string,
    email: string,
    address: string,
    project: shape({
      uuid: string.isRequired
    }),
    stage: shape({
      uuid: string.isRequired
    }),
    units: arrayOf(shape({
      uuid: string.isRequired
    })).isRequired
  }),
  onHide: func
}

LeadModal.defaultProps = {
  lead: {
    fullName: '',
    email: '',
    phoneCode: '',
    phoneNumber: '',
    address: '',
    units: []
  },
  onHide: () => {}
}

export default LeadModal

import React    from 'react'
import styled   from 'styled-components'

import { cardPaddingXSize, cardPaddingYSize } from 'shared/style/sizes'
import apolloClientType                       from 'shared/types/apolloClientType'
import campaignType                           from 'shared/types/campaignType'
import channelType                            from 'shared/types/channelType'

import AddAudienceButtons from './AddAudienceButtons'
import Audience           from './Audience'

const Channel = ({ client, channel, campaign, ...props }) => {
  const presentAudiences = channel.audiences.filter((audience) => {
    return !audience.deleted
  })

  return (
    <div>
      <AddAudienceButtons
        client={ client }
        channel={ channel }
        campaign={ campaign }
      />

      <AudiencesContainer>
        { presentAudiences.map(audience => (
          <Audience
            key={ audience.uuid }
            audience={ audience }
            campaign={ campaign }
            allAudiences={ presentAudiences }
            { ...props }
          />
        ))}
      </AudiencesContainer>
    </div>
  )
}

const AudiencesContainer = styled.div`
  margin-left: -${cardPaddingXSize}
  margin-right: -${cardPaddingXSize}
  margin-bottom: -${cardPaddingYSize}
`

Channel.propTypes = {
  campaign: campaignType.isRequired,
  channel: channelType.isRequired,
  client: apolloClientType.isRequired
}

export default Channel

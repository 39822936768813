import React from 'react'

import UsersIndex from 'shared/components/UsersIndex'

const Users = () => {
  return (
    <UsersIndex />
  )
}

export default Users

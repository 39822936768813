import React     from 'react'
import { t }     from 'ttag'
import styled    from 'styled-components'
import { array } from 'prop-types'
import classNames from 'classnames'

import Card from 'shared/components/ui/Card'
import Item from 'shared/components/CampaignsList/Item'
import { cardBorderValue } from 'shared/style/placeholders'

const CampaignsCard = ({ className, campaigns }) => {
  const liveCampaigns = campaigns.filter(({ phase }) => phase === 'live').length
  const cardHeader = `${ t`Campaigns` } ` + t`(${ liveCampaigns } live)`

  return (
    <Card
      collapsible
      collapsed
      header={ cardHeader }
      padded={ false }
      className={ classNames(className, 'campaigns-card') }
      dataTestId="project-campaign-list-panel"
    >
      { campaigns.map((campaign) => (
        <Item key={ campaign.uuid } campaign={ campaign } />
      )) }
    </Card>
  )
}

CampaignsCard.propTypes = {
  campaigns: array.isRequired
}

export default styled(CampaignsCard)`
  margin-bottom: 0.5rem;

  ${Item} {
    box-shadow: none;
    border-bottom: ${cardBorderValue};
    border-radius: 0;
    margin-bottom: 0;
  }
`

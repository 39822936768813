import { gql } from 'apollo-boost'

import snapshotPhase from 'graphql/fragments/snapshotPhase'

export default gql`
  mutation deleteSnapshotPhase(
    $uuid: ID!
    $snapshotUuid: ID!
  ) {
    deleteSnapshotPhase(
      uuid: $uuid
      snapshotUuid: $snapshotUuid
    ) {
      phase {
       ... SnapshotPhase
      }
    }
  }
  ${snapshotPhase}
`

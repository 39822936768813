import React from 'react'
import { t } from 'ttag'
import { shape, func, string, object } from 'prop-types'

import { localDate } from 'shared/helpers/date'

const ShowingsToggle = ({ showing, target, onClick }) => {
  const title = showing && showing.startDate ? localDate(showing.startDate) : t`Not specified`

  return (
    <a
      href="#"
      className="section-title"
      data-test-id="showings-toggle"
      onClick={ onClick }
      ref={ target }
    >
      { title }
    </a>
  )
}

ShowingsToggle.propTypes = {
  onClick: func.isRequired,
  target: object.isRequired,
  showing: shape({
    startDate: string
  }),
}

ShowingsToggle.defaultProps = {
  showing: null
}

export default ShowingsToggle

import { gql } from 'apollo-boost'

import specification from 'graphql/fragments/specification'

export default gql`
  query CompanySpecification($companyUuid: ID!) {
    companySpecification(companyUuid: $companyUuid) {
      ... Specification
    }
  }
  ${specification}
`

import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

import FbBusinessManagerIcons2 from 'shared/assets/icons/fb-business-manager-2.png'

const OperatorIcon = ({ operator, className }) => (
  <div className={ `${className} operator-${operator}` }/>
)

OperatorIcon.propTypes = {
  operator: string
}

OperatorIcon.defaultProps = {
  operator: 'include'
}

export default styled(OperatorIcon)`
  width: 0.625rem
  height: 0.875rem
  display: inline-block
  cursor: pointer
  background-image: url(${FbBusinessManagerIcons2});
  background-repeat: no-repeat;
  background-size: 37.563rem 33.438rem;

  &.operator-include {
    background-position: -36.813rem -18rem
  }

  &.operator-exclude {
    background-position: -36.75rem -11.125rem
  }
`

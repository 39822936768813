import React from 'react'

import toastError from '../../../toastError'

export default function onPropertyCampaignCreationFailed(errors) {
  if (errors.length) {
    toastError(
      <>
        { errors.map((error) => <p className="mb-0" key={ error }>{ error }</p>) }
      </>
    )
  }
}

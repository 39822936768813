import React  from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import EmptyLogo from './EmptyLogo'

const Logo = ({ className, src, label }) => {
  const image = src
    ? <img src={ src } alt={ label } />
    : <EmptyLogo/>

  return (
    <div className={ className }>
      { image }
    </div>
  )
}

Logo.propTypes = {
  label: string,
  src: string
}

Logo.defaultProps = {
  label: '',
  src: undefined
}

export default styled(Logo)`
  border-radius: 0.3rem;
  height: 4rem;
  width: 6rem;
  overflow: hidden;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    border-radius: inherit;
  }
`

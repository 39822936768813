import updateSnapshotMutation from 'graphql/mutations/snapshots/update'

const updateSnapshot = (client, uuid, input) => {
  return client.mutate({
    mutation: updateSnapshotMutation,
    variables: { uuid, input }
  })
}

export default updateSnapshot

import React, { useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'

import stageType     from 'shared/types/stageType'
import buildingTypes from 'shared/constants/buildingTypes'
import createUnit    from 'shared/components/NewStage/StageDetails/Units/createUnit'
import updateUnit    from 'shared/components/NewStage/StageDetails/Units/updateUnit'
import deleteUnit    from 'shared/components/NewStage/StageDetails/Units/deleteUnit'

import NewItemButton from './NewItemButton'
import Table         from './Table'
import EditUnitFloorplansModal from './EditUnitFloorplansModal'
import validationSchema from './validationSchema'

const UnitsForm = ({ stage }) => {
  const client = useApolloClient()
  const [isUnitFloorplansEditing, setIsUnitFloorplansEditing] = useState(false)
  const [currentUnit, setCurrentUnit] = useState(null)

  const handleAddClick = () => createUnit(client, stage)
  const handleUpdate = ({ unit, attributes }) => {
    validationSchema.validate({ ...unit, ...attributes })
      .then(() => { updateUnit(client, unit.uuid, attributes) })
      .catch(() => {})
  }
  const handleDelete = ({ uuid }) => deleteUnit(client, stage, uuid)

  const uploadFloorPlanClick = (unit) => {
    setCurrentUnit(unit)
    setIsUnitFloorplansEditing(true)
  }

  return (
    <>
      <Table
        stage={ stage }
        units={ stage.units }
        buildingTypes={ buildingTypes.map(v => v.value) }
        onUpdate={ handleUpdate }
        onDelete={ handleDelete }
        handleUploadFloorPlanClick={ uploadFloorPlanClick }
      />

      <NewItemButton onClick={ handleAddClick } />

      {currentUnit && (
        <EditUnitFloorplansModal
          unit={ currentUnit }
          show={ isUnitFloorplansEditing }
          onHide={ () => setIsUnitFloorplansEditing(false) }
        />
      )}
    </>
  )
}

UnitsForm.propTypes = {
  stage: stageType.isRequired
}

export default UnitsForm

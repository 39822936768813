import { gql } from 'apollo-boost'

export default gql`
  mutation setCampaignPhase($uuid: ID!, $phase: Phase!) {
    setCampaignPhase(uuid: $uuid, phase: $phase) {
      errors
      campaign {
        phase
        canSetPhase {
          resumed
          paused
          archived
          unarchived
          assembly
        }
      }
    }
  }
`

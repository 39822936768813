import * as Yup from 'yup'
import { t }    from 'ttag'

export default Yup.object().shape({
  salesArguments: Yup.array().
    of(
      Yup.object().shape({
        text: Yup.string().required(t`Please fill an argument text.`).nullable()
      })
    )
})

import { flatten } from 'lodash'

const fetchPlatformPositions = (platformAudience) => {
  if(platformAudience.__typename === 'Facebook') {
    return platformAudience.audienceNetworkPositions
      .concat(
        platformAudience.messengerPositions,
        platformAudience.facebookPositions
      )
  } else if(platformAudience.__typename === 'Instagram') {
    return platformAudience.instagramPositions
  } else if(platformAudience.__typename === 'Portal') {
    return platformAudience.audienceNetworkPositions
      .concat(
        platformAudience.messengerPositions,
        platformAudience.portalPositions
      )
  } else {
    return []
  }
}

export default (campaign) => (
  flatten(
    campaign
      .channels
      .filter((channel) => channel.enabled)
      .map((channel) => channel.audiences.filter((audience) => {
        return !audience.deleted
      }))
  )
    .map((audience) => {
      const platformPositions = fetchPlatformPositions(audience.platformAudience)

      const includedLocations = audience.locations.filter((locaton) => (
        locaton.operator === 'include'
      ))
      return {
        ...audience,
        platformPositions,
        includedLocations
      }
    })
)

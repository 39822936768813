import React from 'react'
import { t } from 'ttag'
import classNames from 'classnames'
import { node, bool, object, string } from 'prop-types'
import styled from 'styled-components'
import { Card as BCard } from 'react-bootstrap'

import AppErrorBoundary from 'shared/AppErrorBoundary'
import { cardPaddingX, cardPaddingY } from 'shared/style/placeholders'
import { cardPaddingXSize } from 'shared/style/sizes'
import useIsOpened from 'shared/hooks/useIsOpened'
import Header from 'shared/components/ui/Card/Header'

const ErrorView = () => {
  return (
    <p className="p-3 m-0">{ t`Failed to render element` }</p>
  )
}

const Card = (props) => {
  const {
    header,
    footer,
    padded,
    children,
    className,
    dataTestId,
    bodyProps,
    collapsible,
    collapsed,
    style
  } = props
  const { isOpened, toggle } = useIsOpened(collapsible ? !collapsed : true)

  return (
    <BCard
      className={ classNames(className, { padded }) }
      data-test-id={ dataTestId }
      style={ style }
    >
      <AppErrorBoundary ErrorView={ ErrorView } >
        { header && (
          <Header
            collapsible={ collapsible }
            onToggleCollapse={ toggle }
            isOpened={ isOpened }
            content={ header } />
        )}

        { isOpened && (
          <BCard.Body { ...bodyProps }>
            { children }
          </BCard.Body>
        ) }

        { isOpened && footer && (
          <BCard.Footer>
            { footer }
          </BCard.Footer>
        ) }
      </AppErrorBoundary>
    </BCard>
  )
}

Card.propTypes = {
  children: node.isRequired,
  className: string.isRequired,
  bodyProps: object,
  collapsed: bool,
  collapsible: bool,
  dataTestId: string,
  footer: node,
  header: node,
  padded: bool,
  style: object
}

Card.defaultProps = {
  dataTestId: null,
  header: null,
  footer: null,
  collapsed: false,
  collapsible: false,
  bodyProps: {},
  padded: true,
  style: null
}

export default styled(Card)`
  .card-body {
    padding: 0
  }

  &.padded .card-body {
    ${cardPaddingX}
    ${cardPaddingY}
  }

  .card-header {
    background: #FFF
    display: flex
    letter-spacing: .04rem

    padding-left: ${cardPaddingXSize}
    padding-right: 1rem
    padding-top: 1rem
    padding-bottom: 1rem

    &.collapsible {
      cursor: pointer
    }
  }

  .card-header > *:first-child {
    flex-grow: 2
  }

  .card-header > .caret {
    --ci-size: 1.4rem

    align-self: center
    color: #8898aa
    margin-left: .7rem
  }
`

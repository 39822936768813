import React from 'react'

import Dashboard from 'shared/components/Dashboard'

const DashboardPage = () => {
  return (
    <Dashboard />
  )
}

export default DashboardPage

import React  from 'react'
import { t }  from 'ttag'
import styled from 'styled-components'
import { number, array, object } from 'prop-types'

import projectType from 'shared/types/projectType'

import StagesExtraUnitsDetailsInfoTotalBlock from './StagesExtraUnitsDetailsInfoTotalBlock'
import StagesExtraUnitsDetailsInfoBlock      from './StagesExtraUnitsDetailsInfoBlock'

const StagesExtraUnitsDetailsInfo = ({
  className, tabs, activeTab, extraUnitsDetails, project
}) => {
  return (
    <div className={ className }>
      <StagesExtraUnitsDetailsInfoTotalBlock
        title={ tabs[activeTab].title }
        data={ extraUnitsDetails.total }
      />

      {project.enabledExtraUnits.includes('parking') && (
        <StagesExtraUnitsDetailsInfoBlock
          index={ 1 }
          title={ t`Parking spaces` }
          data={ extraUnitsDetails.parking }
        />
      )}

      {project.enabledExtraUnits.includes('garage') && (
        <StagesExtraUnitsDetailsInfoBlock
          index={ 2 }
          title={ t`Garage spaces` }
          data={ extraUnitsDetails.garage }
        />
      )}

      {project.enabledExtraUnits.includes('storage') && (
        <StagesExtraUnitsDetailsInfoBlock
          index={ 3 }
          title={ t`Storage units` }
          data={ extraUnitsDetails.storage }
        />
      )}
    </div>
  )
}

StagesExtraUnitsDetailsInfo.propTypes = {
  activeTab:         number.isRequired,
  extraUnitsDetails: object.isRequired,
  project:           projectType.isRequired,
  tabs:              array.isRequired
}

export default styled(StagesExtraUnitsDetailsInfo)`
  padding: 1.125rem 0;
  display: flex;
  flex-direction: row;
`

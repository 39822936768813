import React, { forwardRef, useEffect } from 'react'
import styled from 'styled-components'
import { mean } from 'lodash'
import { array, string } from 'prop-types'

import { calcPosition } from './utils'

const MergedValues = forwardRef(({ className, handles, suffix }, ref) => {
  const isSingleHandleSlider = handles.length < 2

  if (isSingleHandleSlider) return null

  const values = handles.map(el => el.value)
  const percent = mean(handles.map(el => el.percent))

  useEffect(() => {
    ref.current.style.left = `${calcPosition(ref, percent)}px`
  }, [percent])

  const formatValue = value => [value, suffix].join(' ')

  return (
    <div className={ className } ref={ ref }>
      {`${formatValue(Math.min(...values))} – ${formatValue(Math.max(...values))}`}
    </div>
  )
})

MergedValues.propTypes = {
  handles: array.isRequired,
  suffix: string
}

MergedValues.defaultProps = {
  suffix: undefined
}

MergedValues.displayName = 'MergedValues'

export default styled(MergedValues)`
  border: 1px solid #dadada;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.33);
  font-size: 0.75rem;
  line-height: 2;
  margin-top: 1.75rem;
  min-width: 9.5rem;
  text-align: center;
  padding: 0 1rem;
  position: absolute;
  white-space: nowrap;
`

import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { bool, string, func, object } from 'prop-types'
import { isEmpty } from 'lodash'

import Button from 'shared/components/ui/Button'
import { buttonTextColor } from 'shared/style/colors'
import Arrow from 'shared/assets/icons/svg/new/arrow.svg'
import MissingDataIndicator from 'shared/components/Indicators/MissingData'
import { projectMissingDataPresentation }
  from 'shared/components/Indicators/MissingData/Presentations'

const UpdateSectionButton = ({ className, campaign, loading, onClick, caption }) => {
  const missingData = {
    data: campaign.project.missingData,
    presentation: projectMissingDataPresentation
  }
  const hasMissingData = !isEmpty(campaign.project.missingData)
  const disabled = loading || hasMissingData

  return (
    <div className={ className }>
      <Button
        variant='default'
        disabled={ disabled }
        onClick={ onClick }
      >
        { caption }

        <div className='pl-2 d-inline'>
          {
            loading
              ? <Spinner animation="border"/>
              : (hasMissingData
                ? <MissingDataIndicator missingData={ missingData } />
                : <Arrow className="item-arrow" />)
          }
        </div>
      </Button>
    </div>
  )
}

UpdateSectionButton.propTypes = {
  campaign: object.isRequired,
  caption: string.isRequired,
  loading: bool.isRequired,
  onClick: func.isRequired
}

export default styled(UpdateSectionButton)`
  display: flex
  justify-content: center
  color: ${buttonTextColor}
  font-weight: 300
  font-size: 0.875rem
  margin-top: auto

  .missing-data-indicator {
    float: none !important
    margin: 0
  }

  .spinner-border {
    opacity: .15;
    border-width: .1rem
    height: .85rem
    width: .85rem
    margin-right: .15rem
    vertical-align: middle
  }

  .item-arrow {
    opacity: .15;
    transition: opacity .2s ease;
    margin-right: .15rem
    width: .85rem
  }
`

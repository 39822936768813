import React, { useCallback } from 'react'
import { func, string, oneOfType, object }       from 'prop-types'

import Datepicker        from 'shared/components/ui/Datepickers/Datepicker'
import { normalizeDate } from 'shared/helpers/date'

const CampaignDatepicker = ({
  name,
  minDate,
  maxDate,
  selected,
  onChange,
  className,
  ...props
}) => {
  const handleChange = useCallback((value) => {
    onChange({ name, value })
  }, [])

  return (
    <Datepicker
      className={ className }
      selected={ normalizeDate(selected) }
      minDate={ normalizeDate(minDate) }
      maxDate={ normalizeDate(maxDate) }
      onChange={ handleChange }
      { ...props }
    />
  )
}

CampaignDatepicker.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  className: string,
  maxDate: oneOfType([object, string]),
  minDate: oneOfType([object, string]),
  selected: oneOfType([object, string])
}

CampaignDatepicker.defaultProps = {
  maxDate: undefined,
  minDate: undefined,
  selected: undefined,
  className: undefined
}

export default CampaignDatepicker

import React      from 'react'
import { string, shape, arrayOf, bool, object } from 'prop-types'
import styled     from 'styled-components'
import debounceRender from 'react-debounce-render'

import getImageUrl         from 'shared/helpers/getImageUrl'
import { getHumanUrlHost } from 'shared/helpers/url'
import { altoGreyColor, textColorLight, textGreyColor, textHeaderColor } from 'shared/style/colors'
import { debounceTimeout } from 'shared/constants'

import AdHeader from './AdHeader'
import Iframe from './Iframe'

const DebouncedIframe = debounceRender(Iframe, debounceTimeout)

const FacebookPreview = (props) => {
  const {
    title,
    content,
    logo,
    facebookPageLogo,
    facebookPageName,
    description,
    domain,
    previewAsset,
    previewAssetMediaType,
    className,
    banners,
    color,
    address,
    bannerOnly,
    property,
    campaign,
    previewWithBanners,
    location
  } = props

  const renderBanners = () => {
    const filteredBanners = banners.filter((banner) => {
      return banner
    })

    return filteredBanners.map((banner) => {
      return (
        <BannerDiv key={ banner.uuid }>
          <DebouncedIframe
            type={ property.propertyType }
            price={ property.price }
            size={ property.usableAreaM2 }
            bedrooms={ property.bedrooms }
            agentName={ property.primaryAgentName }
            agentTitle={ property.primaryAgentTitle }
            agentPhotoUrl={ property.primaryAgentAvatar?.media }
            location={ location }
            title={ title }
            color={ color }
            content={ content }
            address={ address }
            assetUrl={ previewAsset }
            logoUrl={ logo && logo.thumbnail }
            bannerUuid={ banner.uuid }
            bannerName={ banner.name }
            campaign={ campaign }
          />
        </BannerDiv>
      )
    })
  }

  if (bannerOnly) {
    if (previewWithBanners) {
      return (
        <div>
          { renderBanners() }
        </div>
      )
    } else if (previewAsset) {
      return <img src={ getImageUrl(previewAsset) } alt="image" className="image" />
    }
  }

  return (
    <div className={ className }>
      <AdHeader
        content={ content }
        logo={ facebookPageLogo }
        facebookPageName={ facebookPageName }
      />

      <div className="card">
        { previewWithBanners && previewAssetMediaType !== 'video' && renderBanners() }

        { previewWithBanners && previewAssetMediaType === 'video' && (
          <img src={ getImageUrl(previewAsset) } alt="image" className="image" />
        ) }

        { !previewWithBanners && previewAsset && (
          <img src={ getImageUrl(previewAsset) } alt="image" className="image" />
        ) }

        <div className="content" >
          <h4 className="title">
            { title }
          </h4>
          <div className="subtitle">
            { description }
          </div>
          <a className="domain" href={ domain }>
            { getHumanUrlHost(domain) }
          </a>
        </div>
      </div>
    </div>
  )
}

FacebookPreview.propTypes = {
  address: string,
  bannerOnly: bool,
  banners: arrayOf(shape({
    uuid: string
  })),
  campaign: object,
  color: string,
  content: string,
  description: string,
  domain: string,
  facebookPageLogo: string,
  facebookPageName: string,
  location: object,
  logo: shape({
    thumbnail: string
  }),
  previewAsset: string,
  previewAssetMediaType: string,
  previewWithBanners: bool,
  property: object,
  title: string
}

FacebookPreview.defaultProps = {
  address: null,
  bannerOnly: false,
  banners: [],
  campaign: {},
  color: null,
  content: '',
  description: '',
  domain: '',
  facebookPageLogo: '',
  facebookPageName: '',
  location: {},
  logo: null,
  previewAsset: null,
  previewAssetMediaType: '',
  previewWithBanners: false,
  property: {},
  title: ''
}

const BannerDiv = styled.div`
  margin-bottom: 10px
`

export default styled(FacebookPreview)`
  border: solid 2px ${altoGreyColor};
  border-radius: 5px;
  padding: .9rem;

  .card {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px rgba(151, 151, 151, 0.3);
    border-radius: 4px
    overflow: hidden;
  }

  .image {
    width: 100%
  }

  .content {
    padding: 10px

    .title {
      color: ${textHeaderColor}
      font-weight: bold
      font-size: 1rem
      line-height: 1
      letter-spacing: 0.25px
      margin-bottom: 0.35rem
    }

    .subtitle {
      color: ${textColorLight}
      font-size: 0.75rem
      letter-spacing: 0.25px
      margin-bottom: 0.25rem
    }

    .domain {
      color: ${textGreyColor}
      font-size: 0.7rem
      font-family: Helvetica
    }
  }
`

import React    from 'react'
import { t }    from 'ttag'
import styled   from 'styled-components'
import { bool } from 'prop-types'
import { useApolloClient } from 'react-apollo'

import { buttonTextColor }    from 'shared/style/colors'
import Switch                 from 'shared/components/ui/Switch'
import useCurrentCampaignUuid from 'shared/hooks/useCurrentCampaignUuid'
import mutation from 'graphql/mutations/campaign/specifications/setChannelOptimization'

import OptimizationMessage from './OptimizationMessage'

const OptimizationSwitch = ({ className, enabled }) => {
  const campaignUuid = useCurrentCampaignUuid()
  const client = useApolloClient()

  const handleChange = (e) => {
    client.mutate({
      mutation,
      variables: {
        campaignUuid,
        enabled: e.target.checked
      }
    })
  }

  return (
    <div className={ className }>
      <Switch onChange={ handleChange } checked={ enabled }>
        { t`Channel Optimization` }
      </Switch>

      <OptimizationMessage />
    </div>
  )
}

OptimizationSwitch.propTypes = {
  enabled: bool.isRequired
}

export default styled(OptimizationSwitch)`
  ${Switch} {
    color: ${buttonTextColor}
    font-weight: 300
    font-size: 0.875rem
    margin-right: 0.5rem
    
    &.pretty.p-switch .state:before {
      background: #dadada
      border-color: #dadada
    }
    
    &.pretty.p-switch .state label:before,
    &.pretty.p-switch .state label:after {
      border-color: #dadada
      transform: scale(0.8)
    }
    
    &.pretty.p-switch.p-fill input:checked ~ .state label:after {
      border-color: rgba(0, 0, 0, 0)
    }
    
    &.pretty.p-switch.p-fill input:checked ~ .state:before {
      background: #47b881 !important
      border-color: #47b881
    }
  }
`

import { t } from 'ttag'
import { Link } from 'react-router-dom'
import React from 'react'

import columns from './columns'

const projectFormatter = (_cell, { project }) =>
  project && <Link to={ `/admin/projects/${project.uuid}` }>{ project.name }</Link>

export default [
  ...columns,
  {
    dataField: 'project',
    text: t`Project`,
    sort: false,
    formatter: projectFormatter
  }
]

import React from 'react'

export default function locationFormatter(cell) {
  return (
    <div>
      { cell.map((location) => (
        <p key={ location.title } className="mb-0">
          { location.title }
        </p>
      )) }
    </div>
  )
}

import { t }  from 'ttag'

export const projectMissingDataPresentation = {
  lower_price_cents: t`Price range from`,
  upper_price_cents: t`Price range to`,
  lower_size_m2: t`Size range from`,
  upper_size_m2: t`Size range to`,
  building_types: t`Unit types`,
  units_count: t`Total units`,
  units_sold: t`Units sold`,
  country: t`Country`,
  zip_code: t`Zip code`,
  latitude: t`Latitude`,
  longitude: t`Longitude`,
  no_stages: t`No stages`
}

export const propertyMissingDataPresentation = { }

import gql from 'graphql-tag'

export default gql`
  mutation PublishNewsPost(
    $uuid: ID!
    $publish: Boolean!
  ) {
    publishNewsPost(
      uuid: $uuid
      publish: $publish
    ) {
      post {
        uuid
        publishedAt
        updatedAt
        state
      }
    }
  }
`

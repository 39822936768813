import React from 'react'
import Select from 'react-select'
import { get } from 'lodash'
import { t } from 'ttag'
import { oneOfType, string, number, func } from 'prop-types'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import useCountries from 'shared/hooks/useCountries'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    marginRight: '0.5rem',
    minWidth: '5rem'
  })
}

const PhoneCodeSelect = ({ value, onChange, ...props }) => {
  const { uniqueDialCodes } = useCountries()

  const allOptions = uniqueDialCodes
    .map(dialCode => ({ label: `+${dialCode}`, value: dialCode.toString() }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })

  const selectedOption = allOptions.find(el => el.value === value)

  const featuredCodes = ['47', '44']
  const featuredOptions = featuredCodes
    .map(code => allOptions.find(({ value }) => value === code))
    .filter(el => el)

  const groupedOptions = [
    { label: '', options: featuredOptions },
    { label: '', options: allOptions.filter(({ value }) => !featuredCodes.includes(value)) }
  ]

  const handleChange = option => onChange(get(option, 'value', null))

  return (
    <span data-test-id='phone-code-select-wrapper'>
      <Select
        value={ selectedOption }
        options={ groupedOptions }
        onChange={ handleChange }
        placeholder=''
        noOptionsMessage={ () => t`No options` }
        styles={ styles }
        { ...props }
      />
    </span>
  )
}

PhoneCodeSelect.propTypes = {
  onChange: func,
  value: oneOfType([string, number])
}

PhoneCodeSelect.defaultProps = {
  onChange: () => {},
  value: ''
}

export default PhoneCodeSelect

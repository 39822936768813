import React from 'react'
import styled from 'styled-components'
import { object, bool } from 'prop-types'

import projectType from 'shared/types/projectType'
import { cardBorderValue } from 'shared/style/placeholders'

import * as components from './Sections'

const Footer = ({ className, project, withoutSelectors, components: externalComponents }) => {
  const {
    Customer,
    Project,
    Stages,
    Campaigns,
    Units,
    DefaultColor,
    DefaultTemplate
  } = { ...components, ...externalComponents }

  return (
    <section className={ className }>
      <Customer customerName={ project.customerName } />
      <Project project={ project } />
      <Units unitsCount={ project.unitsCount } unitsSold={ project.unitsSold } />
      <Stages stages={ project.stagesCount } />
      <Campaigns count={ project.totalCampaignsCount } live={ project.activeCampaignsCount }/>
      { withoutSelectors ? null : <DefaultColor project={ project } /> }
      { withoutSelectors ? null : <DefaultTemplate project={ project } /> }
    </section>
  )
}

Footer.propTypes = {
  project: projectType.isRequired,
  components: object,
  withoutSelectors: bool
}

Footer.defaultProps = {
  components: {},
  withoutSelectors: false
}

export default styled(Footer)`
  display: flex;
  padding-bottom: .2rem;
  padding-top: .2rem;

  & > * {
    flex-grow: 1;
    flex-basis: 0;
    border-left: ${cardBorderValue}
    padding: 0 0.8rem
  }

  & > *:first-child {
    border-left: none;
    padding-left: 0
  }
`

import React           from 'react'
import { useQuery }    from 'react-apollo'
import { t }           from 'ttag'
import moment          from 'moment'
import { useMutation } from '@apollo/react-hooks'
import { omit }        from 'lodash'

import matchType          from 'shared/types/matchType'
import NewCampaignForm    from 'shared/components/NewAutoCampaign/CampaignForm'
import facebookPagesQuery from 'graphql/queries/companies/facebookPages'
import duplicateMutation  from 'graphql/mutations/campaign/duplicate'

import onCampaignCreated        from './onCampaignCreated'
import onCampaignCreationFailed from './onCampaignCreationFailed'
import LoadingForm              from './LoadingForm'
import campaignQuery            from './campaignQuery'

const DuplicateCampaignForm = ({ match }) => {
  const [duplicateCampaign, { loading: loadingDuplicateCampaign }] = useMutation(duplicateMutation)
  const { uuid, campaignUuid } = match.params

  const { data: campaignData, loading: loadingCampaign } = useQuery(
    campaignQuery,
    { variables: { uuid: campaignUuid } }
  )

  const {
    data: facebookPagesData,
    loading: loadingFacebookPages
  } = useQuery(facebookPagesQuery, {
    variables: { stageUuid: uuid }
  })

  if (loadingCampaign || loadingFacebookPages || loadingDuplicateCampaign) {
    return <LoadingForm />
  }
  const facebookPages    = facebookPagesData.companyFacebookPages?.facebookPages
  const facebookPageUuid = facebookPagesData.companyFacebookPages?.defaultFacebookPageUuid
  const { campaign } = campaignData

  const onSubmit = (values, actions) => {
    const attributes = {
      ...omit(values, ['goalNumber', 'goalType', 'targetLqs', 'stageUuid']),
      productUuid: campaign.product.uuid
    }
    const goals      = {
      number: values.goalNumber,
      type:   values.goalType,
      lqs:    values.targetLqs
    }

    duplicateCampaign({ variables: { attributes, goals, sourceCampaignUuid: campaignUuid } })
      .then(({ data: { duplicateCampaign } }) => {
        onCampaignCreationFailed(duplicateCampaign.errors)
        onCampaignCreated(duplicateCampaign.campaign)
      })
      .finally(() => actions.setSubmitting(false))
  }

  const formValues = {
    stageUuid: uuid,
    name: `${campaign.name} - ${t`Copy`}`,
    goalNumber: campaign.goalNumber,
    goalType: campaign.goalType,
    locale: campaign.locale,
    countryCode: campaign.countryCode,
    targetLqs: 'high',
    startDate: moment().add(1, 'day').toDate(),
    endDate: moment().add(campaign.durationInDays + 1, 'days').toDate(),
    facebookPageUuid
  }

  return (
    <NewCampaignForm
      formValues={ formValues }
      facebookPages={ facebookPages }
      onSubmit={ onSubmit }
    />
  )
}

DuplicateCampaignForm.propTypes = {
  match: matchType.isRequired
}

export default DuplicateCampaignForm

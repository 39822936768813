import React         from 'react'
import { useQuery }  from '@apollo/react-hooks'
import styled        from 'styled-components'
import { t }         from 'ttag'
import { get, some } from 'lodash'

import Project  from 'shared/assets/icons/svg/new/project.svg'
import Stage    from 'shared/assets/icons/svg/new/stage.svg'
import Devices  from 'shared/assets/icons/svg/new/devices.svg'
import Lead     from 'shared/assets/icons/svg/new/lead.svg'
import { canRead, canCreate }            from 'shared/helpers/auth'
import { greyishBrownColor, mediumGrey } from 'shared/style/colors'
import redirectTo from 'shared/helpers/redirectTo'

import companiesQuery from './companiesQuery'
import ItemCard       from './ItemCard'

const NewItem = ({ className }) => {
  const response = useQuery(companiesQuery, {
    fetchPolicy: 'no-cache'
  })
  const companies = get(response, 'data.companies', [])

  const canCreatePropertyCampaigns = some(companies, c => c.propertiesEnabled)
  const showNew = canCreate('projects') || canCreate('stages') || canCreate('buildings')
    || (canCreate('leads') && (canRead('leads_projects') || canRead('projects')))

  if (!showNew && !canCreate('campaigns')) {
    if (canRead('projects')) {
      redirectTo('/projects')
    } else if (canRead('sales_reports')) {
      redirectTo('/sales_reports')
    } else if (canRead('campaigns')) {
      redirectTo('/campaigns')
    } else if (canRead('users')) {
      redirectTo('/users')
    }
  }

  return (
    <div className={ className }>
      <h2 className='h2-title'>{ t`Create new` }</h2>
      {canCreate('campaigns') && (
        <>
          {canRead('projects') && (
            <ItemCard
              to={ '/campaigns/new/projects' }
              title={ t`Project campaign` }
              icon={ <Devices /> }
              subtitle={ t`Create a new project marketing campaign` }
              data-test-id="new-project-campaign"
            />
          )}
          {canCreatePropertyCampaigns && (
            <ItemCard
              to={ '/campaigns/new/properties' }
              title={ t`Property campaign` }
              icon={ <Devices /> }
              subtitle={ t`Create a new property marketing campaign` }
              data-test-id="new-property-campaign"
            />
          )}
        </>
      )}

      {/* Hidden for MVP */}
      {/*<ItemCard*/}
      {/*  to={ '/campaigns/new' }*/}
      {/*  title={ t`Premium campaign` }*/}
      {/*  icon={ <ThreeD /> }*/}
      {/*  subtitle={ t`Create a new marketing campaign` }*/}
      {/*/>*/}
      { showNew && (
        <>
          <hr className="my-4 with-line" />
          <h3 className='h3-title'>{ t`You can also manually create` }</h3>
        </>
      )}
      {canCreate('projects') && (
        <ItemCard
          to={ '/projects/new' }
          title={ t`New project` }
          icon={ <Project /> }
          subtitle={ t`Create a new project` }
          data-test-id="new-project"
        />
      )}
      {canCreate('buildings') && (
        <ItemCard
          to={ '/buildings/new/projects' }
          title={ t`New building` }
          icon={ <Stage /> }
          subtitle={ t`Add a building to an existing project` }
          data-test-id="new-building"
        />
      )}
      {canCreate('stages') && (
        <ItemCard
          to={ '/stages/new/projects' }
          title={ t`New stage` }
          icon={ <Stage /> }
          subtitle={ t`Add a stage to an existing project` }
          data-test-id="new-stage"
        />
      )}
      {canCreate('leads') && (canRead('leads_projects') || canRead('projects')) && (
        <ItemCard
          to={ '/leads/new' }
          title={ t`Lead` }
          icon={ <Lead /> }
          subtitle={ t`Add a lead to an existing project or campaign` }
          data-test-id="new-lead"
        />
      )}
    </div>
  )
}

export default styled(NewItem)`
  .h2-title {
    font-size: 1.5rem;
    color: ${greyishBrownColor};
    margin-bottom: 1rem;
  }

  .h3-title {
    font-size: 1.1rem;
    color: ${greyishBrownColor};
    margin-bottom: 1.5rem;
  }

  .with-line {
    width: 50%;
    margin: 3.3rem auto 2.8rem 0 !important;
    border-top: 1px solid ${mediumGrey};
  }
`

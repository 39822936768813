import React from 'react'
import styled from 'styled-components'
import { uniqBy, get, flatten } from 'lodash'
import { t } from 'ttag'
import { array, shape, string } from 'prop-types'

import { altoGreyColor } from 'shared/style/colors'
import FacebookIcon from 'shared/components/ui/icons/channels/FacebookIcon'
import InstagramIcon from 'shared/components/ui/icons/channels/InstagramIcon'
import PortalIcon from 'shared/components/ui/icons/channels/PortalIcon'
import GmpIcon from 'shared/components/ui/icons/channels/GmpIcon'

import List from './List'

const Connections = ({ className, creativeSets, landingPage }) => {
  const connectedSets = creativeSets.filter(
    set => get(set, 'landingPage.uuid') === landingPage.uuid
  )

  const channelsIcons = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    portal: PortalIcon,
    gmp: GmpIcon,
    predefined: GmpIcon
  }

  const connectedAudiences = uniqBy(
    flatten(connectedSets.map((connectedSet) => {
      const ChannelIcon = channelsIcons[connectedSet.channel.type]

      const audiences = connectedSet.creativeSetAudiences.map((element) => {
        return element.audience
      })

      return flatten(audiences.map((audience) => ({
        ...audience,
        ...{ name: (<ChannelIcon content={ audience.name } />) }
      })))
    })),
    ({ uuid }) => uuid
  )

  return (
    <div className={ className }>
      <List items={ connectedSets } title={ t`Creative sets connected` } />
      <Separator />
      <List items={ connectedAudiences } title={ t`Audiences connected` } />
    </div>
  )
}

const Separator = styled.div`
  border-left: solid 1px ${altoGreyColor};
  margin: 0.25rem 1.875rem 0 1.875rem;
`

Connections.propTypes = {
  creativeSets: array.isRequired,
  landingPage: shape({
    uuid: string.isRequired
  }).isRequired
}

export default styled(Connections)`
  display: flex;
  margin-top: 1.5rem;
`

import React from 'react'
import { string, arrayOf } from 'prop-types'
import styled from 'styled-components'

import Column from 'shared/components/ui/TwoColumnList/Column'
import SubHeader from 'shared/components/ui/Card/SubHeader'

const Websites = ({ className, main, secondaries  }) => {
  const sites = []

  if (main) {
    sites.push({ label: 'Main Website', value: main })
  }

  secondaries.forEach((site, i) => {
    sites.push({ label: `Website ${i + 2}`, value: site })
  })

  return (
    Boolean(sites.length) && (
      <div className={ className }>
        <SubHeader>websites</SubHeader>
        <Column column={ sites } />
      </div>
    )
  )
}

Websites.propTypes = {
  className: string.isRequired,
  main: string,
  secondaries: arrayOf(string)
}

Websites.defaultProps = {
  main: undefined,
  secondaries: []
}

export default styled(Websites)`
  [class^=col]:last-child:first-letter {
    text-transform: none
  }
`

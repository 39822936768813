import React from 'react'
import { shape, string } from 'prop-types'

import projectType from 'shared/types/projectType'

import LeftColumn from './LeftColumn'
import RightColumn from './RightColumn'

const ProjectDetails = ({ className, product: { promotable } }) => {
  return (
    <div className={ className }>
      <div className="details-columns">
        <aside>
          <LeftColumn promotable={ promotable } />
        </aside>
        <aside>
          <RightColumn promotable={ promotable } />
        </aside>
      </div>
    </div>
  )
}

ProjectDetails.propTypes = {
  className: string.isRequired,
  product: shape({
    promotable: projectType.isRequired,
  }).isRequired
}

export default ProjectDetails

import gql from 'graphql-tag'

export default gql`
  fragment ProjectsListItem on Project {
    uuid
    createdAt
    name
    customerName
    stagesCount
    unitsCount
    activeCampaignsCount
    missingData
    image {
      thumbnail
    }
  }
`

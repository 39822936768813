import { gql } from 'apollo-boost'

export default gql`
  fragment User on User {
    uuid
    email
    name
    phoneNumber {
      code
      number
      fullNumber
    }
    companies {
      uuid
      name
    }
    companyRoles {
      id
      roleName
    }
    accessLocked
    currentSignInAt
    lastSignInAt
    createdAt
    updatedAt
    confirmedAt
  }
`

import gql from 'graphql-tag'

export default gql`
  query Project($uuid: ID!) {
    project(uuid: $uuid) {
      uuid
      name
      sourceDescription
      customDescriptionDraft
      titleDraft
      linkDraft
      portalEnabledDraft
      autoDescriptionEnabled
      salesReportEnabled
      headlineDraft
      flatFinderUrl
      multistage
      saleStateDraft
      currency
      snapshots {
        uuid
        saleState
        buildingTypes
        planning
        salesStartAt
        constructionStartAt
        movingStartAt
        unitSets {
          uuid
          lowerSizeM2
          upperSizeM2
          lowerBedroomCount
          upperBedroomCount
          lowerPrice
          upperPrice
          floorNumber
        }
      }
      completeStages {
        uuid
        name
        autoDescriptionEnabled
        sourceDescription
        customDescriptionDraft
        titleDraft
        headlineDraft
        flatFinderUrl
        linkDraft
      }
    }
  }
`

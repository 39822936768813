import React, { useCallback } from 'react'
import { array, arrayOf, string } from 'prop-types'

import { isUserAdmin } from 'shared/helpers/auth'

import NewCreativeSetView   from './NewCreativeSetView'
import useCreateCreativeSet from './useCreateCreativeSet'

const NewCreativeSet = ({ channels, features }) => {
  const createCreativeSet = useCreateCreativeSet()
  const filteredChannels = channels
    .filter((channel) => isUserAdmin() || (channel.type !== 'portal'))

  return (
    <NewCreativeSetView
      channels={ filteredChannels }
      dynamicCreativesEnabled={ features.includes('dynamic_creatives') }
      onChannelSelect={ useCallback(createCreativeSet, []) }
    />
  )
}

NewCreativeSet.propTypes = {
  channels: array.isRequired,
  features: arrayOf(string).isRequired
}

export default NewCreativeSet

import paginationFactory from 'react-bootstrap-table2-paginator'
import { t } from 'ttag'

import PageButton from 'shared/components/ui/Table/PageButton'

const sizePerPageRenderer = () => null

export default function createPagination(paginationOptions) {
  const defaultOptions = {
    firstPageTitle: t`First page`,
    prePageTitle: t`Previous page`,
    nextPageTitle: t`Next page`,
    lastPageTitle: t`Last page`,
    pageButtonRenderer: PageButton,
    sizePerPageRenderer,
  }

  return paginationFactory({ ...defaultOptions, ...paginationOptions })
}

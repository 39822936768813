import { gql } from 'apollo-boost'

export default gql`
  query ProjectSalesLeadsStats (
    $durationType:  String!,
    $durationValue: String!,
    $uuid:          ID!,
    $scopedTo:      String!
  ) {
    projectSalesLeadsStats(
      durationType:  $durationType,
      durationValue: $durationValue,
      uuid:          $uuid,
      scopedTo:      $scopedTo
    ) {
      leadsStats {
        value
        increment
      }
    }
  }
`

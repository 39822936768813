import React from 'react'
import { string, func } from 'prop-types'
import { t } from 'ttag'

import DeleteButtonTag from 'shared/components/ui/DeleteButton'

const DeleteButton = ({ onDelete, uuid }) => {
  const handleDelete = () => {
    if (confirm(t`Are you sure you want to remove stage unit?`)) {
      onDelete({ uuid })
    }
  }

  return <DeleteButtonTag onClick={ handleDelete } />
}

DeleteButton.propTypes = {
  onDelete: func.isRequired,
  uuid: string.isRequired
}

export default DeleteButton

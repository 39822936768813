import React from 'react'
import styled from 'styled-components'
import { node, string } from 'prop-types'

import { iconsGreyColor } from 'shared/style/colors'

const DetailsRow = ({ className, title, children }) => (
  <div className={ className }>
    <label className="details-label">
      { title }
    </label>
    <span className="details-value">
      { children }
    </span>
  </div>
)

DetailsRow.propTypes = {
  title: string.isRequired,
  children: node
}

DetailsRow.defaultProps = {
  children: null
}

export default styled(DetailsRow)`
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;

  .details-label {
    color: ${iconsGreyColor};
    width: 40%;
    font-size: 14px;
  }
  .details-value {
    width: 60%;
    font-size: 14px;
  }
`

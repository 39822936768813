import React from 'react'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { object }   from 'prop-types'

import dashboardAnalyticsVisitorsQuery from 'graphql/queries/dashboardAnalytics/visitors'
import LoadingBlock                    from 'shared/components/ui/LoadingBlock'
import { formatNumberWithLocale }      from 'shared/helpers/number'

import Card                from './Card'
import Chart               from './LinearChart'
import formatCurrentValues from './formatCurrentValues'

const VisitorsChart = ({ filter }) => {
  const {
    data: {
      dashboardAnalyticsVisitors: {
        visitors = {}
      } = {}
    } = {},
    loading
  } = useQuery(dashboardAnalyticsVisitorsQuery, {
    variables: { filter }
  })

  if (loading) {
    return (
      <Card header={ t`Visitors` }>
        <LoadingBlock />
      </Card>
    )
  }

  const { visitorsValue } = formatCurrentValues({ visitors })

  return (
    <Chart
      name={ t`Visitors` }
      comment={ t`Number of landing page visits generated` }
      dataTestId='visitors-chart'
      dataset={ visitors.dataset }
      currentValue={ visitorsValue }
      tooltipFormatter={ (value) => formatNumberWithLocale(value) }
    />
  )
}

VisitorsChart.propTypes = {
  filter: object
}

VisitorsChart.defaultProps = {
  filter: {}
}

export default VisitorsChart

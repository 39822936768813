import React, { useCallback, useState } from 'react'
import { object, string, node, array } from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'

import AdPreview from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/Preview/AdPreview'
import Modal from 'shared/components/ui/Modal'

const PreviewLink = ({ creativeSet, asset, className, campaign, banners, ...props }) => {
  const [show, setShow] = useState(false)
  const handleClose     = useCallback(() => setShow(false), [])
  const handleShow      = useCallback(() => setShow(true), [])

  return (
    <div>
      <a href="#" onClick={ handleShow }>
        {props.children}
      </a>

      <Modal show={ show } onHide={ handleClose } className={ className }>
        <Modal.Body>
          <AdPreview
            previewAsset={ asset ? asset.thumbnail : null }
            banners={ banners }
            color={ creativeSet.color }
            logo={ creativeSet.logo }
            type={ get(creativeSet, 'channel.type') }
            title={ creativeSet.title }
            content={ creativeSet.content }
            description={ creativeSet.description }
            domain={ creativeSet.domain }
            facebookPageName={ campaign?.facebookPage?.name }
            facebookPageLogo={ campaign?.facebookPage?.logoUrl }
            campaign={ campaign }
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

PreviewLink.propTypes = {
  asset: object.isRequired,
  children: node.isRequired,
  creativeSet: object.isRequired,
  banners: array,
  campaign: object,
  companyLogo: string,
  companyName: string,
  content: string,
  domain: string,
  title: string,
}

PreviewLink.defaultProps = {
  banners: [],
  campaign: {},
  companyLogo: '',
  companyName: '',
  content: '',
  domain: '',
  title: ''
}

export default styled(PreviewLink)`
  .ads-row {
    align-items: flex-start;
    display: flex;
    margin-bottom: .5rem
  }
`

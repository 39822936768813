import React from 'react'
import { t } from 'ttag'
import { bool, string, func } from 'prop-types'
import { useApolloClient } from 'react-apollo'

import { userRolePath } from 'routes/AppRedirect'
import ContextMenu from 'shared/components/ui/ProjectContextMenu'

import { propertyPickerRedirect } from './PropertyPicker'
import ExportLeadsButton from './ExportLeadsButton'

const Actions = ({
  available,
  isSalesReportEnabledFlag,
  isSnapshotEnabledFlag,
  isProjectNewsEnabledFlag,
  isPropertyPickerEnabledFlag,
  projectName,
  projectUuid,
  setIsEditing
}) => {
  const client = useApolloClient()

  return (
    <ContextMenu>
      { handleAndClose => (
        <>
          {available && (
            <div>
              {!isSnapshotEnabledFlag && (
                <>
                  <div>
                    <button onClick={ handleAndClose(() => setIsEditing(true)) } type='button'>
                      { t`Edit project details` }
                    </button>
                  </div>
                  <div>
                    <a href={ userRolePath(`/projects/${projectUuid}/portal/edit`) }>
                      { t`Edit Portal details` }
                    </a>
                  </div>
                </>
              )}
              {isProjectNewsEnabledFlag && (
                <div data-test-id='project-news-link'>
                  <a href={ userRolePath(`/projects/${projectUuid}/news`) }>
                    { t`Edit project news` }
                  </a>
                </div>
              )}
              <div>
                <ExportLeadsButton projectUuid={ projectUuid } projectName={ projectName }/>
              </div>
              {isSalesReportEnabledFlag && (
                <div data-test-id='sales-report-link'>
                  <a href={ userRolePath(`/sales_reports/projects/${projectUuid}`) }>
                    { t`Sales report` }
                  </a>
                </div>
              )}
              {isPropertyPickerEnabledFlag && (
                <div data-test-id='property-picker-link'>
                  <button onClick={
                    handleAndClose(() => {
                      propertyPickerRedirect({ projectUuid, client })
                    }) } type='button'>
                    { t`Go to property picker` }
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )
      }
    </ContextMenu>
  )
}

Actions.propTypes = {
  available: bool.isRequired,
  isProjectNewsEnabledFlag: bool.isRequired,
  isPropertyPickerEnabledFlag: bool.isRequired,
  isSalesReportEnabledFlag: bool.isRequired,
  isSnapshotEnabledFlag: bool.isRequired,
  projectName: string.isRequired,
  projectUuid: string.isRequired,
  setIsEditing: func.isRequired
}

export default Actions

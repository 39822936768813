import { kebabCase } from 'lodash'

import { availableLocales } from 'locale'

import creativeSetAudienceTitle from './creativeSetAudienceTitle'

export default function creativeSetAudienceSortFunc(
  _value1, _value2, order, _dataField, row1, row2
) {
  const text1 = creativeSetAudienceTitle(row1.creativeSet, row1.audience)
  const text2 = creativeSetAudienceTitle(row2.creativeSet, row2.audience)

  const locales = availableLocales.map(({ key }) => kebabCase(key))
  const comparisonResult = text1.localeCompare(text2, locales)

  return (order === 'asc' ? 1 : -1) * comparisonResult
}

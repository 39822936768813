import gql from 'graphql-tag'

export default gql`
  query companyUsers($companyUuid: ID!) {
    companyUsers(companyUuid: $companyUuid) {
      uuid
      name
      nameWithEmail
    }
  }
`

import React from 'react'

import matchType from 'shared/types/matchType'
import Company   from 'shared/components/Company'

const CompanyPage = ({ match }) => {
  return (
    <Company uuid={ match.params.uuid } />
  )
}

CompanyPage.propTypes = {
  match: matchType.isRequired
}

export default CompanyPage

import React from 'react'
import { get } from 'lodash'
import { components } from 'react-select'
import { shape, string } from 'prop-types'

import ColorOption from './ColorOption'

const Option = (props) => {
  const isSelected = get(props, 'selectProps.value.value') === props.value

  return (
    <components.Option { ...props }>
      <ColorOption
        color={ props.value }
        name={ props.label }
        isSelected={ isSelected }
      />
    </components.Option>
  )
}

Option.propTypes = {
  label: string.isRequired,
  selectProps: shape({
    value: shape({
      value: string
    })
  }).isRequired,
  value: string.isRequired,
}

export default Option

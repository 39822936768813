import { t } from 'ttag'

export default [
  { value: 'west', label: t`West` },
  { value: 'east', label: t`East` },
  { value: 'north', label: t`North` },
  { value: 'south', label: t`South` },
  { value: 'north-east', label: t`North East` },
  { value: 'north-west', label: t`North West` },
  { value: 'south-east', label: t`South East` },
  { value: 'south-west', label: t`South West` },
]

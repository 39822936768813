import { gql } from 'apollo-boost'

export default gql`
  query sessions {
    sessions {
      aud
      expired
      current
      createdAt
    }
  }
`

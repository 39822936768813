import handlePayloadValidation from 'shared/components/ValidatableTabs/handlePayloadValidation'
import validateTargeting       from 'shared/components/CampaignInfo/validations/validateTargeting'

import validateCreativeSets from './validateCreativeSets'

const fetchValidationData = ({ validatorNumber }) => {
  return [
    validateTargeting,
    validateCreativeSets,
  ][validatorNumber]
}

const buildValidator = ({ setValidationPending, setErrors, validatorNumber }) => {
  const validatePayload = fetchValidationData({ validatorNumber })

  return ({ payload, propogateError, force } = {}) => handlePayloadValidation({
    validatePayload,
    payload,
    propogateError,
    setErrors,
    setValidationPending,
    force
  })
}

export default buildValidator

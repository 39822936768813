import React from 'react'
import { t } from 'ttag'

import UserRoleNavLink        from 'routes/UserRoleNavLink'
import { dateTime, duration } from 'shared/components/ui/Table/formatters'
import leadProgress           from 'shared/constants/leadProgress'

const fullNameFormatter = (cell, row) => (
  <UserRoleNavLink to={ `/leads/${row.uuid}` }>{ cell }</UserRoleNavLink>
)

export default [{
  dataField: 'fullName',
  text: t`Name`,
  sort: true,
  formatter: fullNameFormatter
}, {
  dataField: 'progress',
  text: t`Progress`,
  sort: true,
  formatter: cell => leadProgress.find(el => el.value === cell).label
}, {
  dataField: 'responseTime',
  text: t`Response time`,
  formatter: duration,
  sort: true
}, {
  dataField: 'qualityScore',
  text: t`LQS`,
  sort: true
}, {
  dataField: 'audienceName',
  text: t`Audience`,
  sort: true
}, {
  dataField: 'updatedAt',
  text: t`Last activity`,
  formatter: dateTime,
  sort: true
}, {
  dataField: 'createdAt',
  text: t`Created at`,
  formatter: dateTime,
  sort: true
}]

import gql from 'graphql-tag'

export default gql`
  fragment AudienceLocations on AudienceLocation {
    uuid
    latitude
    longitude
    radius
    operator
    title
    country
    placeName
    locationType
    externalId
    updatedAt
  }
`

import React from 'react'
import styled from 'styled-components'
import { string, func, bool } from 'prop-types'

import ErrorType from 'shared/types/inputValidationError'
import Input from 'shared/components/ui/ThriftyInput'
import Wrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'

const ResponsibilityInput = ({ className, ...props }) => {
  const { responsibility, onResponsibilityUpdate, stakeholderUuid, readOnly, error } = props

  const handleUpdate = (value) => onResponsibilityUpdate({
    stakeholderUuid,
    responsibility: value
  })

  return readOnly ? (
    responsibility
  ) : (
    <Wrapper error={ error }>
      <Input
        className={ className }
        type="text"
        onUpdate={ handleUpdate }
        data-test-id="responsibility-input"
        value={ responsibility }
      />
    </Wrapper>
  )
}

ResponsibilityInput.propTypes = {
  onResponsibilityUpdate: func.isRequired,
  readOnly: bool.isRequired,
  stakeholderUuid: string.isRequired,
  error: ErrorType,
  responsibility: string
}

ResponsibilityInput.defaultProps = {
  responsibility: '',
  error: null
}

export default styled(ResponsibilityInput)`
  display: inline-block;

  && {
    box-shadow: none;
    font-size: .75rem;
  }
`

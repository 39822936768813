import React from 'react'
import styled from 'styled-components'
import { object, bool } from 'prop-types'

import useTabBar from 'shared/hooks/useTabBar'

import tabs from './tabs'

const Stage = ({ product, readOnly }) => {
  const { tabBar, CurrentComponent } = useTabBar(tabs)

  return (
    <Container>
      { tabBar }

      <CurrentComponent product={ product } readOnly={ readOnly } />
    </Container>
  )
}

const Container = styled.div`
  margin-top: -1.1rem;
  margin-left: -1.45rem;
  margin-right: -1.45rem;

  .table {
    margin-bottom: 0;
  }
`

Stage.propTypes = {
  product: object.isRequired,
  readOnly: bool
}

Stage.defaultProps = {
  readOnly: false
}

export default Stage

import React            from 'react'
import { get }          from 'lodash'
import { withMutation } from 'react-apollo'
import { withRouter }   from 'react-router-dom'
import { object, func } from 'prop-types'

import assetType   from 'shared/types/assetType'
import removeAsset from 'graphql/mutations/assetable/removeAssetAssignment'

import ConfirmationModal from './ConfirmationModal'
import DeleteButton      from './DeleteButton'

class DeleteAssetButton extends React.Component {
  static propTypes = {
    asset: assetType.isRequired,
    mutate: func.isRequired,
    style: object.isRequired
  }

  state = {
    usedInCampaigns: null
  }

  needsConfirmation = () => !!this.state.usedInCampaigns

  setUsedInCampaigns = (usedInCampaigns) => {
    this.setState({ usedInCampaigns })
  }

  resetusedInCampaigns = () => this.setState({ usedInCampaigns: null })

  deleteAssetWithConfirmation = () => {
    this.props.mutate(this.mutationOptions(true))
  }

  deleteAssetWithoutConfirmation = () => {
    this.props.mutate(this.mutationOptions()).then((response) => {
      const usedInCampaigns = get(response, 'data.removeAssetAssignment.usedInCampaigns')
      const errors = get(response, 'data.removeAssetAssignment.errors')
      if (errors.length) {
        this.setUsedInCampaigns(usedInCampaigns)
      }
    })
  }

  mutationOptions = (confirmRemoval = false) => {
    return {
      variables: {
        confirmRemoval,
        assetAssignmentUuid: this.props.asset.assignmentUuid
      }
    }
  }

  render() {
    if (!this.props.asset.assignmentUuid) {
      return null
    }

    return (
      <>
        <DeleteButton
          style={ this.props.style }
          onClick={ this.deleteAssetWithoutConfirmation }
        />

        <ConfirmationModal
          onHide={ this.resetusedInCampaigns }
          onConfirm={ this.deleteAssetWithConfirmation }
          usedInCampaigns={ this.state.usedInCampaigns }
        />
      </>
    )
  }
}

export default withMutation(removeAsset)(
  withRouter(DeleteAssetButton)
)

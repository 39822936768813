import React from 'react'

import productType from 'shared/types/productType'
import Card from 'shared/components/ui/Card'
import TwoColumnList from 'shared/components/ui/TwoColumnList'

const CustomerInfo = ({ product = {} }) => {
  const {
    owner,
    primaryAgent,
    secondaryAgents,
    company = {},
  } = product

  return (
    <Card collapsible header="Customer" >
      <TwoColumnList
        leftColumn={ [
          company.name && { label: 'Company', value: company.name },
        ] }
        rightColumn={ [
          owner              && { label: 'Owner', value: owner },
          primaryAgent       && { label: 'Primary agent', value: primaryAgent },
          ...secondaryAgents.map((agent, i) => (
            { label: `Secondary agent ${i+1}`, value: agent }
          ))
        ] }
      />
    </Card>
  )
}

CustomerInfo.propTypes = {
  product: productType.isRequired
}

export default CustomerInfo

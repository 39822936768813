import React from 'react'
import { t } from 'ttag'
import { array, bool, string } from 'prop-types'

import useShowingsListOverlay from './useShowingsListOverlay'
import AddNewButton from './AddNewButton'
import ReadOnlyShowing from './Showing'
import EditableShowing from './EditableShowing'

const contextParams = { showableUuid: undefined, showableType: undefined }
const ProjectSummaryContext = React.createContext(contextParams)

const Showings = (props) => {
  const {
    className,
    showings,
    readOnly,
    showableUuid,
    showableType,
    showHeading,
    showTimeSeparator,
    showFieldLabels
  } = props

  const {
    _isOpened,
    _toggle,
    _close,
    _target,
    _container,
    sortedShowings,
    _firstShowing
  } = useShowingsListOverlay(showings)

  const KindOfShowing = readOnly ? ReadOnlyShowing : EditableShowing

  return (
    <ProjectSummaryContext.Provider
      value={{ showableUuid: showableUuid, showableType: showableType }}
    >
      <div className={ className }>
        { showHeading && <h4>{ t`Upcoming showings` }</h4> }
        { sortedShowings.map((showing, index) => (
          <KindOfShowing
            key={ showing.uuid }
            entryIndex={ index + 1 }
            showFieldLabels={ showFieldLabels }
            showTimeSeparator={ showTimeSeparator }
            { ...showing }
          />
        )) }
        {!readOnly && (
          <div className="add-new-showing-button p-2 d-flex justify-content-end">
            <AddNewButton />
          </div>
        )}
      </div>
    </ProjectSummaryContext.Provider>
  )
}

Showings.propTypes = {
  showableType: string.isRequired,
  showableUuid: string.isRequired,
  showings: array.isRequired,
  readOnly: bool,
  showFieldLabels: bool,
  showHeading: bool,
  showTimeSeparator: bool
}

Showings.defaultProps = {
  readOnly: true,
  showFieldLabels: false,
  showHeading: true,
  showTimeSeparator: true
}

export { ProjectSummaryContext }

export default Showings

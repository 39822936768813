import React, { createRef, useState }  from 'react'
import styled              from 'styled-components'
import { t }               from 'ttag'
import { toast }           from 'react-toastify'
import { map }             from 'lodash'
import { useApolloClient } from 'react-apollo'
import { func, object, shape, string, arrayOf, bool } from 'prop-types'

import Modal              from 'shared/components/ui/Modal'
import Button             from 'shared/components/ui/Button'
import Asset              from 'shared/components/ui/Asset'
import createMimicAsset   from 'shared/components/MediaList/createMimicAsset'
import EmptyAssets        from 'shared/components/MediaList/EmptyAssets'
import ThriftyInput       from 'shared/components/ui/ThriftyInput'
import mimeTypes          from 'shared/constants/mimeTypes'
import completeFileUpload from 'shared/helpers/files/completeFileUpload'
import getBlobPreview     from 'shared/helpers/files/getBlobPreview'

import { Label, Row }   from './elements'
import NewsTypeSelect   from './NewsTypeSelect'
import FormInput        from './FormInput'
import validationSchema from './validationSchema'

const Form = ({
  initialValues,
  medias,
  className,
  onSubmit,
  assetUpload,
  state,
  onAssetDelete,
  enablePreview
}) => {
  const mediaTypes = ['image', 'video']
  const client = useApolloClient()
  const fileInput = createRef()
  const [isUploading, setIsUploading] = useState(false)
  const [values, setValues] = useState(initialValues)
  const allowedMimeTypes = mediaTypes.map((mediaType) => {
    return mimeTypes[mediaType]
  }).flat()

  const handleFileUpload = async (e) => {
    const files = e.target.files

    setIsUploading(true)
    const uploadedAssets = await Promise.all(map(files, (file) => {
      const fyleType = file.type === 'image/jpeg' ? 'image' : 'video'
      const allowedTypes = mimeTypes[fyleType]
      return completeFileUpload({ client, file, allowedTypes, fyleType })
        .then((data) => getBlobPreview({ client, ...data }))
        .then(createMimicAsset(fyleType))
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
          if (error === 'Image dimensions must be at least 1000x1000.') {
            toast.error(t`Image dimensions must be at least 1000x1000.`)
          } else toast.error(t`An error happened during upload.`)
        })
        .finally(() => setIsUploading(false))
    }))

    assetUpload(uploadedAssets.filter(Boolean))
  }

  const openUploadWindow = () => {
    fileInput.current.click()
  }

  const handleFormSubmit = (publish) => {
    try {
      Object.keys(values).forEach((key) => {
        if (key === 'assets') return

        values[key] = values[key]?.trim()
      })
      const validatingValues = values
      validatingValues['assets'] = medias
      validationSchema.validateSync(validatingValues)
      onSubmit(values, true, publish)
    } catch (e) {
      e.errors.forEach((error) => {
        toast.error(error)
      })
    }
  }

  const onFormFieldUpdate = (name, value) => {
    values[name] = value
    setValues(values)
    onSubmit({ [name]: value }, false, false)
  }

  const renderMedias = () => {
    return medias.map((media) => {
      return (
        <Asset
          key={ media.asset.uuid }
          asset={ media.asset }
          handleAssetRemoval={ onAssetDelete }
        />
      )
    })
  }

  return (
    <div className={ className }>
      <Modal.Body>
        <Row>
          <Label>{ t`News type` }</Label>
          <NewsTypeSelect
            value={ values.newsType }
            onChange={ (value) => onFormFieldUpdate('newsType', value) }
          />
        </Row>

        <Row>
          <Label>{ t`Title` }</Label>
          <FormInput
            value={ values.titleDraft }
            onUpdate={ (value) => onFormFieldUpdate('titleDraft', value) }
          />
        </Row>

        <input
          multiple
          type="file"
          accept={ allowedMimeTypes.join(',') }
          className="d-none"
          ref={ fileInput }
          onChange={ handleFileUpload }
          data-test-id='file-upload-input'
        />

        <div className='assets'>
          {renderMedias()}

          <EmptyAssets
            isUploading={ isUploading }
            mediaTypes={ mediaTypes }
            onClick={ openUploadWindow }
            onDrop={ handleFileUpload }
          />
        </div>

        <Row>
          <Label>{ t`Short text description (maximum 300 characters)` }</Label>
          <ThriftyInput
            value={ values.descriptionDraft }
            placeholder={ t`Please provide description` }
            as='textarea'
            onUpdate={ (value) => onFormFieldUpdate('descriptionDraft', value) }
          />
        </Row>

        <Row>
          <Label>{ t`CTA button text` }</Label>
          <FormInput
            value={ values.callToActionTextDraft }
            onUpdate={ (value) => onFormFieldUpdate('callToActionTextDraft', value) }
          />
        </Row>

        <Row>
          <Label>{ t`CTA destination` }</Label>
          <FormInput
            value={ values.callToActionDestinationUrlDraft }
            onUpdate={ (value) => onFormFieldUpdate('callToActionDestinationUrlDraft', value) }
          />
        </Row>
        <div className='buttons-block'>
          { enablePreview && (<Button type="button">{ t`Preview` }</Button>) }
          {(state === 'draft' || state === 'saved') && (
            <div>
              <Button type="button" className='colored' onClick={ () => handleFormSubmit(false) }>
                { state === 'draft' ? t`Save as draft` : t`Save` }
              </Button>
              <Button type="button" className='colored' onClick={ () => handleFormSubmit(true) }>
                { t`Save and publish` }
              </Button>
            </div>
          )}
          {state === 'published' && (
            <Button
              type="button"
              className='colored'
              onClick={ () => handleFormSubmit(true) }
            >
              { t`Save` }
            </Button>
          )}
        </div>
      </Modal.Body>
    </div>
  )
}

Form.propTypes = {
  assetUpload: func.isRequired,
  onAssetDelete: func.isRequired,
  onSubmit: func.isRequired,
  enablePreview: bool,
  initialValues: object,
  medias: arrayOf(shape({
    media: string
  })),
  state: string
}

Form.defaultProps = {
  enablePreview: false,
  initialValues: {},
  medias: [],
  state: null
}

export default styled(Form)`
  textarea {
    height: 6rem;
  }

  .assets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > div {
      width: 13rem;
      height: 13rem;
      margin-bottom: 2rem;

      &:nth-of-type(2n+1) {
        margin-right: 4%;
      }

      & > div {
        height: 13rem;
      }
    }

    .asset-container {
      padding-top: 0;

      .asset-label {
        height: auto;
      }
    }
  }

  .news-type-select {
    & > div {
      margin-right: 0;
    }
  }

  .buttons-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;

    button {
      font-size: .875rem;

      &:nth-of-type(1), &:nth-of-type(2) {
        margin-right: .5rem;
      }

      &.colored {
        background: #648FB6;
        border: 1px solid rgba(100, 143, 182, 0.55731);
        color: #fff;
      }
    }
  }
`

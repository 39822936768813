import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { func, arrayOf, string, object, bool } from 'prop-types'
import ReactMapboxGl, { Layer, Feature, MapContext, ZoomControl } from 'react-mapbox-gl'
import { invoke, isEmpty } from 'lodash'

import useMapCenter from 'shared/hooks/useMapCenter'
import audienceLocationType from 'shared/types/audienceLocationType'
import audienceType from 'shared/types/audienceType'
import { mapWhiteColor, mapDarkBlueColor, redColor } from 'shared/style/colors'
import DropPinButton from 'shared/components/ui/DropPinButton'
import credentials from 'config/credentials'

import CircleArea from '../CircleArea'
import LocationMarker from '../LocationMarker'
import FitBoundsButton from '../FitBoundsButton'

const MapBox = ReactMapboxGl({
  accessToken: credentials.mapboxApiKey
})

const MapView = (props) => {
  const {
    map,
    audience,
    locations,
    currentOperator,
    highlightedLocation,
    className,
    onSwitchDropPinMode,
    onFitBoundsButtonClick,
    onMapLoad,
    onMarkerClick,
    isInvalid
  } = props

  const handleFitBoundsButtonClick = () => {
    onFitBoundsButtonClick(map, locations)
  }

  const isHiglightedLocation = (location) => (
    !!highlightedLocation && highlightedLocation.uuid === location.uuid
  )

  const mapCanvasContainer = invoke(map, 'getCanvasContainer')

  const { mapCenter } = isEmpty(locations) && useMapCenter({
    countryCode: audience.fallbackCountryCode,
    zipCode: audience.fallbackZipCode,
    latitude: audience.fallbackLatitude,
    longitude: audience.fallbackLongitude
  })

  return (
    <div className={ className }>
      <DropPinButton
        onClick={ onSwitchDropPinMode }
        currentOperator={ currentOperator }
      />

      <FitBoundsButton onClick={ handleFitBoundsButtonClick } />

      <MapBox
        style="mapbox://styles/mapbox/streets-v11"
        onStyleLoad={ onMapLoad }
        center={ mapCenter }
        containerStyle={{
          height: '40vh',
          borderColor: isInvalid ? redColor : 'hsl(0,0%,80%)',
          borderRadius: '0.25rem',
          borderStyle: 'solid',
          borderWidth: '0.063rem'
        }}
      >
        <ZoomControl />

        {
          mapCanvasContainer && createPortal(
            locations.map((location) => {
              return <LocationMarker
                map={ map }
                location={ location }
                key={ location.uuid }
                highlighted={ isHiglightedLocation(location) }
                onMarkerClick={ onMarkerClick }
              />
            }),
            mapCanvasContainer
          )
        }

        <Layer
          type='circle'
          paint={{
            'circle-color': mapDarkBlueColor,
            'circle-radius': 4,
            'circle-stroke-width': 2,
            'circle-stroke-color': mapWhiteColor,
          }}
        >
          {
            locations.map((location) => {
              return <Feature
                coordinates={ [location.longitude, location.latitude] }
                key={ location.uuid }
              />
            })
          }
        </Layer>

        <MapContext.Consumer>
          {(map) => {
            return locations.map((location) => {
              return location.radius && <CircleArea
                location={ location }
                highlighted={ isHiglightedLocation(location) }
                map={ map }
                key={ location.uuid }
              />
            })
          }}
        </MapContext.Consumer>
      </MapBox>
    </div>
  )
}

MapView.defaultProps = {
  audience: null,
  map: null,
  highlightedLocation: null,
  isInvalid: false
}

MapView.propTypes = {
  className: string.isRequired,
  currentOperator: string.isRequired,
  locations: arrayOf(audienceLocationType).isRequired,
  onFitBoundsButtonClick: func.isRequired,
  onMapLoad: func.isRequired,
  onMarkerClick: func.isRequired,
  onSwitchDropPinMode: func.isRequired,
  audience: audienceType,
  highlightedLocation: audienceLocationType,
  isInvalid: bool,
  map: object
}

export default styled(MapView)`
  position: relative
  z-index: 0
`

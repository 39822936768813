import React            from 'react'
import { useQuery }     from '@apollo/react-hooks'
import { string, func } from 'prop-types'
import styled           from 'styled-components'
import { get }          from 'lodash'

import Search             from 'shared/components/ui/Search'
// Temporary disabled: https://github.com/marketertechnologies/marketer/issues/2041
// import Switch from 'shared/components/ui/Switch'
import PageHeader         from 'shared/components/ui/PageHeader'
import Pagination         from 'shared/components/ui/Pagination'
import { ensureNumber }   from 'shared/helpers/number'
import {
  paginatedSearchTextChangeHandler,
  paginatedSearchPageChangeHandler
} from 'shared/helpers/paginatedSearch'
import useUrlParams       from 'shared/hooks/useUrlParams'
import { iconsGreyColor } from 'shared/style/colors'

import propertiesQuery  from './propertiesQuery'
import PropertyListView from './PropertyListView'

const ITEMS_PER_PAGE = 15

const PropertiesList = ({ className, propertyCardLinkBuilder, pageTitle }) => {
  const [urlParams, setUrlParams] = useUrlParams()
  const search = urlParams.search
  const page = ensureNumber(urlParams.page, 1)

  const { data } = useQuery(propertiesQuery, {
    variables: {
      query: {
        page,
        search,
        filter: {
          sold: false,
          initial_setup_complete: true
        }
      }
    },
    fetchPolicy: 'cache'
  })

  const properties = get(data, 'properties.items', [])
  const propertiesTotal = get(data, 'properties.total', 0)
  const pageCount = Math.ceil(propertiesTotal / ITEMS_PER_PAGE)

  // Temporary disabled: https://github.com/marketertechnologies/marketer/issues/2041
  // const onActiveFilterChange = (event) => {
  //   const onlyActive = event.target.checked
  //   setUrlParams(
  //     onlyActive
  //       ? { ...omit(urlParams, ['page']), onlyActive }
  //       : { ...omit(urlParams, ['page', 'onlyActive']) }
  //   )
  // }
  const handleSearchChange = paginatedSearchTextChangeHandler(urlParams, setUrlParams)
  const handlePageChange = paginatedSearchPageChangeHandler(urlParams, setUrlParams)

  return (
    <div className={ className }>
      <PageHeader title={ pageTitle }>
        <Search
          searchText={ search }
          onSearch={ handleSearchChange }
          className='search-input'
        />

        {/*
          Temporary disabled: https://github.com/marketertechnologies/marketer/issues/2041
          <Switch
            onChange={ onActiveFilterChange }
            checked={ onlyActive }
            className='hide-inactive-toggle'
          >
            { t`Hide inactive` }
          </Switch>
        */}
      </PageHeader>

      <PropertyListView
        properties={ properties }
        buildLink={ propertyCardLinkBuilder }
      />

      <Pagination
        page={ page }
        pageCount={ pageCount }
        onPageChange={ handlePageChange }
      />
    </div>
  )
}

PropertiesList.propTypes = {
  className: string.isRequired,
  pageTitle: string.isRequired,
  propertyCardLinkBuilder: func.isRequired
}

export default styled(PropertiesList)`
  position: relative
  margin-bottom: 2rem

  .controls {
    position: absolute
    right: 0.5rem
  }

  .hide-inactive-toggle {
    display: inline-block
    font-size: 0.875rem
    font-weight: 300
    color: ${iconsGreyColor}
  }

  .search-input {
    max-width: 12rem
    display: inline-block
    margin-right: 1rem
  }
`

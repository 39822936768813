import { gql } from 'apollo-boost'

export default gql`
  query DetailedTargeting(
    $campaignUuid: ID!
    $query: String
    $audienceUuid: ID!
  ) {
    facebookDetailedTargeting(
      campaignUuid: $campaignUuid,
      query: $query,
      audienceUuid: $audienceUuid
    ) {
      id
      name
      type
      audienceSize
    }
  }
`

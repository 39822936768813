import gql from 'graphql-tag'

export default gql`
  mutation DiscardAllChangesets($uuid: ID!) {
    discardAllChangesets(uuid: $uuid) {
      campaign {
        uuid
        phase
      }
    }
  }
`

import React from 'react'
import AsyncSelect from 'react-select/async'
import { string, func } from 'prop-types'
import { t } from 'ttag'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import forwardPostcode from 'shared/helpers/geocoding/forwardPostcode'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1
  })
}

const ZipCodeSelect = ({ value, countryCode, onChange, onBlur: _, setMapCenter, ...props })=> {
  const selectedOption = { value, label: value }

  const handleChange = ({ value, center }) => {
    onChange(value)
    setMapCenter(center)
  }

  const loadOptions = (query) => forwardPostcode(query, countryCode).then(({ body }) => (
    body.features.map(feature => ({
      value: feature.text,
      label: feature.place_name,
      center: feature.center
    }))
  ))

  return (
    <AsyncSelect
      value={ selectedOption }
      disabled={ !countryCode }
      placeholder={ countryCode ? '' : t`Please select a country` }
      noOptionsMessage={ () => t`No options` }
      styles={ styles }
      onChange={ handleChange }
      loadOptions={ loadOptions }
      { ...props }
    />
  )
}

ZipCodeSelect.propTypes = {
  onChange: func.isRequired,
  countryCode: string,
  onBlur: func,
  setMapCenter: func,
  value: string
}

ZipCodeSelect.defaultProps = {
  countryCode: undefined,
  value: undefined,
  onBlur: undefined,
  setMapCenter: undefined
}

export default ZipCodeSelect

import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import styled from 'styled-components'

import Card from 'shared/components/ui/Card'
import Collapse from 'shared/components/ui/Collapse'

const DescriptionHeader = styled.h6`
  font-weight: normal
  font-size: 1.1rem
`
const Description = styled.p`
  color: #363535
  font-size: .8rem
  font-weight: 300
  letter-spacing: .5px
  margin-bottom: 0
`

const ProjectDetails = ({ descriptions }) => {
  return (
    <Card collapsible header="Project details">
      { descriptions.map(({ name, description }) => (
        <Collapse key={ description }>
          <DescriptionHeader>{ name }</DescriptionHeader>
          <Description>{ description }</Description>
        </Collapse>
      )) }
    </Card>
  )
}

ProjectDetails.propTypes = {
  descriptions: arrayOf(shape({
    name: string,
    description: string
  })).isRequired
}

export default ProjectDetails

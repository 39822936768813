import React, { useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend    from 'react-dnd-html5-backend'
import { useQuery }    from 'react-apollo'
import { object }      from 'prop-types'

import stageAssetAssignments from 'graphql/queries/stageAssetAssignments'
import AssetAssignments      from 'shared/components/Media/AssetAssignments'

import useMoveAssetToPosition   from '../SelectAssets/useMoveAssetToPosition'
import useUpdateAssetAssignment from '../SelectAssets/useUpdateAssetAssignment'
import attachFileToStage        from '../attachFileToStage'

import createRemoveAssetAssignment from './createRemoveAssetAssignment'
import createCreateAssetAssignment from './createCreateAssetAssignment'

const SelectStageAssets = ({ stage }) => {
  const mediaTypesShown = ['image', 'banner', 'video']

  const selectAssetAssignment = useUpdateAssetAssignment()
  const { moveAssetToPosition } = useMoveAssetToPosition()

  const attachFileToAssetable = useCallback(({ client, blobId, mediaType }) => {
    return attachFileToStage({ client, blobId, mediaType, stageUuid: stage.uuid })
  }, [stage.uuid])

  const { data, loading } = useQuery(
    stageAssetAssignments,
    { variables: { uuid: stage.uuid } }
  )

  if (loading) return null

  const stageWithAssetAssignments = Object.assign(stage, {
    assetAssignments: data.stage.assetAssignments
  })

  const creativeAssignments = stageWithAssetAssignments.assetAssignments.map((assetAssignment) => {
    const extras = {
      assetAssignmentUuid: assetAssignment.uuid,
      portalEnabledDraft:  assetAssignment.portalEnabledDraft,
      enabled:             assetAssignment.portalEnabledDraft
    }
    assetAssignment.asset = Object.assign({}, assetAssignment.asset, extras)
    return assetAssignment
  })

  const handleAssetDrop = (draggedAsset, targetAsset) => {
    return moveAssetToPosition({
      variables: {
        uuid: draggedAsset.uuid,
        newPosition: targetAsset.portalPositionDraft.toString()
      }
    })
  }

  return (
    <DndProvider backend={ HTML5Backend }>
      <AssetAssignments
        draggable
        onDrop={ handleAssetDrop }
        creativeAssignments={ creativeAssignments }
        mediaTypes={ mediaTypesShown }
        assetable={ stage }
        attachFileToAssetable={ attachFileToAssetable }
        createRemoveAssetAssignment={ createRemoveAssetAssignment }
        createCreateAssetAssignment={ createCreateAssetAssignment }
        selectAssetAssignment={ selectAssetAssignment }
      />
    </DndProvider>
  )
}

SelectStageAssets.propTypes = {
  stage: object.isRequired
}

export default SelectStageAssets

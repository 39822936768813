import styled from 'styled-components'

import Button from 'shared/components/ui/Button'
import Table  from 'shared/components/ui/Table'
import {
  cardBorderRadius,
  cardBoxShadow,
  defaultBorderValue
} from 'shared/style/placeholders'

export default styled(Table)`
  ${cardBoxShadow}
  ${cardBorderRadius}

  background: #FFF
  margin-bottom: 2rem

  .react-bootstrap-table {
    border-bottom: ${defaultBorderValue}
  }

  .table {
    margin-bottom: 0
  }

  .table th {
    white-space: nowrap
  }

  .pagination {
    padding-top: 1rem
    padding-right: 1rem
  }

  .pagination ${Button} {
    margin-left: .5rem
  }

  .search-bar {
    padding: 1rem
  }

  .search-input {
    display: inline-block
    height: auto
    max-width: 12.5rem
    vertical-align: bottom
  }
`

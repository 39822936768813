import React from 'react'
import { t } from 'ttag'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useApolloClient } from 'react-apollo'

import { iconsGreyColor as grey } from 'shared/style/colors'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'

import updateProject from './updateProject'

const OtherInformation = ({ project, className }) => {
  const client = useApolloClient()

  return(
    <EmbeddedCardSection
      titleText={ t`Other information` }
      className={ className }
    >
      <div className="form-group">
        <label>{ t`Project website` }</label>
        <InputWrapper>
          <ThriftyInput
            type="text"
            data-test-id='link-draft-field'
            value={ project.linkDraft }
            onUpdate={
              linkDraft => updateProject(client, project, { linkDraft })
            }
          />
        </InputWrapper>
      </div>
      <div className="form-group">
        <label>{ t`Project flat finder URL` }</label>
        <InputWrapper>
          <ThriftyInput
            type="text"
            data-test-id='flat-finder-url-field'
            value={ project.flatFinderUrl }
            onUpdate={
              flatFinderUrl => updateProject(client, project, { flatFinderUrl })
            }
          />
        </InputWrapper>
      </div>
    </EmbeddedCardSection>
  )
}

OtherInformation.propTypes = {
  project: PropTypes.object.isRequired
}

export default styled(OtherInformation)`
  input {
    box-shadow: none;
  }
  .inline-input {
    width: 90%;
  } 
  
  label {
    color: ${grey};
    display: block;
    font-size: 0.85rem;
    font-weight: normal;
    margin: 0;
  }
  
  .group {
    margin-bottom: 10px;
  }
`

import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { t } from 'ttag'

import landingPageType from 'shared/types/landingPageType'
import WysiwygEditor from 'shared/components/ui/WysiwygEditor'

import { SectionHeader, SalesArgumentsContainer } from '../elements'

const SalesArguments = ({ className, landingPage, onUpdate }) => {
  return <SalesArgumentsContainer className={ className }>
    <div className='arguments'>
      <div>
        <SectionHeader>{ t`Sales argument` } 1</SectionHeader>
        <WysiwygEditor
          initialValue={ landingPage.salesArgument1 }
          onChange={ editor => onUpdate({ salesArgument1: editor.target.getContent() }) }
          height={ 300 }
          id={ 'sales-argument-1-input' }
        />
      </div>

      <div>
        <SectionHeader>{ t`Sales argument` } 2</SectionHeader>
        <WysiwygEditor
          initialValue={ landingPage.salesArgument2 }
          onChange={ editor => onUpdate({ salesArgument2: editor.target.getContent() }) }
          height={ 300 }
          id={ 'sales-argument-2-input' }
        />
      </div>

      <div>
        <SectionHeader>{ t`Sales argument` } 3</SectionHeader>
        <WysiwygEditor
          initialValue={ landingPage.salesArgument3 }
          onChange={ editor => onUpdate({ salesArgument3: editor.target.getContent() }) }
          height={ 300 }
          id={ 'sales-argument-3-input' }
        />
      </div>
    </div>
  </SalesArgumentsContainer>
}

SalesArguments.propTypes = {
  landingPage: landingPageType.isRequired,
  onUpdate: func.isRequired
}

export default styled(SalesArguments)`
  flex-direction: column;

  .arguments {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

import React from 'react'
import { toast } from 'react-toastify'
import { uniq } from 'lodash'

export const combineErrors = ({ errors, uuid, field, fields }) => {
  const errorMessage = errors.filter((e) => (
    e.uuid === uuid && (
      (field && e.field === field) || (fields && fields.includes(e.field))
    )
  )).map((e) => e.error).join(' ')

  const force = errors.some((error) => error.force)

  return errorMessage.length
    ? { errorMessage, force }
    : null
}

export const errorIsPresent = ({ errors, uuid, field }) => {
  const r = errors.filter((e) => (
    e.uuid === uuid && e.field === field
  ))
  return r.length > 0
}

export const detalizeError = (errorMessage) => {
  return <div key={ errorMessage }><p className="mb-0">{ errorMessage }</p></div>
}

export const createValidatableActionHandler = ({
  validate, validatablePayload, detalizeErrors, customDetalizeErrorsHandler,
}) => (
  (successAction, failAction) => {
    try {
      validate({
        payload: validatablePayload,
        propogateError: true,
        force: true
      })

      successAction()

    } catch(e) {
      if (e.name === 'ValidationError') {
        if (detalizeErrors) {
          let detalizedMessages

          if (customDetalizeErrorsHandler) {
            detalizedMessages = customDetalizeErrorsHandler(e)
          } else {
            detalizedMessages = uniq(e.errors).map((message) => detalizeError(message))
          }

          toast.error(
            <>
              {detalizedMessages}
            </>
          )
        } else {
          toast.error(e.message)
        }

        if (failAction) {
          failAction()
        }
      } else {
        throw e
      }
    }
  }
)

import React, { useState, useCallback } from 'react'
import { bool, object } from 'prop-types'
import { t } from 'ttag'
import styled from 'styled-components'
import { useMutation } from 'react-apollo'
import { get, groupBy, uniqBy } from 'lodash'

import originalAssetAssignments from 'graphql/fragments/originalAssetAssignments'
import Card       from 'shared/components/ui/Card'
import MediaList  from 'shared/components/MediaList'
import mediaTypes from 'shared/components/Media/mediaTypes'
import ConfirmationModal from 'shared/components/Gallery/Lightbox/ConfirmationModal'
import Documents from 'shared/components/Documents'
import attachAssetMutation     from 'graphql/mutations/assetable/attachFile'
import removeAssetAssignmentMutation from 'graphql/mutations/assetable/removeAssetAssignment'
import { buildFragmentParams } from 'shared/helpers/graphql'

import EditAssetModal from './EditAssetModal'

const emptyArray = []

const Media = ({ project, className, disableShowings }) => {
  const [editingAssetUuid, setEditingAssetUuid] = useState(null)
  const [attachAsset] = useMutation(attachAssetMutation)
  const [runRemoveAssetAssignment] = useMutation(removeAssetAssignmentMutation)

  const getAssets = useCallback(() => {
    return uniqBy(project.originalAssetAssignments.map(({ asset }) => asset), 'uuid')
  }, [project.originalAssetAssignments])

  const assetsByType = groupBy(getAssets(), 'mediaType')

  const [usedInCampaigns, setUsedInCampaigns] = useState([])
  const [assetToRemove, setAssetToRemove] = useState(undefined)

  if (!project) {
    return null
  }

  const resetRemovalConfirmation = () => {
    setAssetToRemove(undefined)
    setUsedInCampaigns([])
  }

  const handleModalClose = () => {
    setEditingAssetUuid(null)
  }

  const confirmAssetAssignmentRemoval = () => {
    removeAssetAssignment(assetToRemove, true)
  }

  const handleAssetEdit = (uuid) => {
    setEditingAssetUuid(uuid)
  }

  const removeAssetAssignment = (removedAsset, confirmRemoval = false) => {
    const assetAssignment = project?.originalAssetAssignments
      .find(({ asset }) => asset.uuid === removedAsset.uuid)

    if (!assetAssignment) {
      return
    }

    return runRemoveAssetAssignment({
      variables: {
        confirmRemoval,
        assetAssignmentUuid: assetAssignment.uuid,
      },
      update: (cache, data) => {
        const usedIn = get(data, 'data.removeAssetAssignment.usedInCampaigns').length

        if (usedIn > 0 && !confirmRemoval) {
          return
        }

        const fragmentParams = buildFragmentParams(
          { __typename: 'Project', uuid: project.uuid },
          originalAssetAssignments
        )
        const fragmentData = cache.readFragment(fragmentParams)
        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            originalAssetAssignments: [
              ...fragmentData.originalAssetAssignments.filter(a => a.uuid !== assetAssignment.uuid),
            ]
          }
        })
      },
    }).then((response) => {
      if (!confirmRemoval) {
        setAssetToRemove(removedAsset)
        setUsedInCampaigns(get(response, 'data.removeAssetAssignment.usedInCampaigns'))
      } else {
        resetRemovalConfirmation()
      }
    })
  }

  const attachProjectAsset = (uploadedAssets) => {
    uploadedAssets.forEach(({ signedBlobId, mediaType }) => {
      attachAsset({
        variables: {
          mediaType,
          blobId: signedBlobId,
          assetableUuid: project.uuid,
          assetableType: 'Project',
        },
        update: (cache, response) => {
          const fragmentParams = buildFragmentParams(
            { __typename: 'Project', uuid: project.uuid },
            originalAssetAssignments
          )
          const fragmentData = cache.readFragment(fragmentParams)
          const assetAssignment = get(response, 'data.attachFileToAssetable.assetAssignment')

          cache.writeFragment({
            ...fragmentParams,
            data: {
              ...fragmentData,
              originalAssetAssignments: [
                ...fragmentData.originalAssetAssignments,
                assetAssignment
              ]
            }
          })
        }
      })
    })
  }

  return (
    <Card collapsible collapsed header={ t`Media` } className="mt-2 medias-card">
      <div className={ className }>
        <div className="medias">
          { mediaTypes.map((mediaType) => (
            <MediaList
              key={ mediaType }
              mediaType={ mediaType }
              handleAssetEdit={ handleAssetEdit }
              assets={ assetsByType[mediaType] || emptyArray }
              handleAssetsChange={ attachProjectAsset }
              handleAssetRemoval={ (asset) => removeAssetAssignment(asset, false) }
            />
          )) }
        </div>
        { !disableShowings && (
          <Documents
            showings={ project.showings }
            readOnly={ false }
            showableUuid={ project.uuid }
            showableType='Project'
          />
        )}
      </div>

      <ConfirmationModal
        onHide={ resetRemovalConfirmation }
        onConfirm={ confirmAssetAssignmentRemoval }
        usedInCampaigns={ usedInCampaigns }
      />
      { editingAssetUuid && (<EditAssetModal
        assetUuid={ editingAssetUuid }
        assets={ getAssets() }
        handleModalClose={ handleModalClose } />) }
    </Card>
  )
}

Media.propTypes = {
  project: object.isRequired,
  disableShowings: bool
}

Media.defaultProps = {
  disableShowings: false
}

export default styled(Media)`
  display: flex;
  flex-direction: row;

  .medias {
    padding-right: 1rem;
    flex: 7;
  }
`

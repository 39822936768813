import { gql } from 'apollo-boost'

import showing from 'graphql/fragments/showing'

export default gql`
  mutation updateShowing(
    $uuid: ID!,
    $input: ShowingInput!
  ) {
    updateShowing(
      uuid: $uuid,
      input: $input
    ) {
      showing {
        ... Showing
      }
    }
  }
  ${showing}
`

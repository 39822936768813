import React from 'react'

import matchType   from 'shared/types/matchType'
import ProductView from 'shared/components/Product'

function Product({ match }) {
  return (
    <ProductView uuid={ match.params.uuid } />
  )
}

Product.propTypes = {
  match: matchType.isRequired
}

export default Product

import React from 'react'
import styled from 'styled-components'
import AsyncSelect from 'react-select/async'
import { t } from 'ttag'
import { func } from 'prop-types'

import useGeolocationSearch from './useGeolocationSearch'
import Option from './Option'

const radiusForType = (type) => (
  type === 'city' ? 25000 : 15000
)

const LocationSelect = ({ onChange, ...props }) => {
  const search = useGeolocationSearch()

  const handleChange = ({ key, type }) => {
    onChange({
      key,
      type,
      radius: radiusForType(type),
    })
  }

  const loadOptions = (query, callback) => {
    search(query).then((locations) => {
      callback(locations.map((location) => ({
        value: location.key,
        ...location
      })))
    })
  }

  return (
    <Wrapper data-test-id='location-select-wrapper'>
      <AsyncSelect
        components={{ Option }}
        loadOptions={ loadOptions }
        placeholder={ t`Type to add more locations` }
        noOptionsMessage={ () => t`No options` }
        value={ null }
        onChange={ handleChange }
        { ...props }
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex-grow: 1;
`

LocationSelect.propTypes = {
  onChange: func
}

LocationSelect.defaultProps = {
  onChange: () => {}
}

export default LocationSelect

import React                    from 'react'
import styled                   from 'styled-components'
import { string, func }         from 'prop-types'
import { Switch as BaseSwitch } from 'pretty-checkbox-react'

const Switch = ({ className, onChange, ...props }) => {
  return (
    <BaseSwitch
      shape="fill"
      className={ className }
      onChange={ onChange }
      { ...props }
    />
  )
}

Switch.propTypes = {
  className: string.isRequired,
  onChange: func.isRequired,
}

export default styled(Switch)`
  &.pretty {
    margin: 0;
  }

  &.pretty.p-switch .state label:before,
  &.pretty.p-switch .state label:after {
    border: solid 1px #abafba;
    transform: scale(1);
    top: calc((0% - (100% - 1em)) - 10%);
  }

  &.pretty.p-switch .state label:after {
    background-color: #FFF !important
  }

  &.pretty.p-switch.p-fill input:checked ~ .state:before {
    background: linear-gradient(to bottom, #7dddb8, #47b881);
    border: solid 1px #c5c9d2;
  }

  &.pretty.p-switch input:checked ~ .state:before {
    border: solid 1px #c5c9d2;
  }
`

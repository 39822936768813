import React from 'react'

import ForgotPasswordForm from 'user/components/ForgotPasswordForm'

import EmailSuccessfullySent from './EmailSuccessfullySent'

class ForgotPassword extends React.Component {
  state = {
    email: null
  }

  setEmail = (email) => this.setState({ email })

  render() {
    if (this.state.email) {
      return (
        <EmailSuccessfullySent email={ this.state.email } />
      )
    }

    return (
      <ForgotPasswordForm onEmailSent={ this.setEmail } />
    )
  }
}

export default ForgotPassword

import { dateTime, link, noWrap } from 'shared/components/ui/Table/formatters'

export default [{
  dataField: 'id',
  hidden: true
}, {
  dataField: 'name',
  text: 'Product title',
  formatter: (cell, row) => noWrap(link(cell, `/admin/products/${row.uuid}`)),
  style: { width: '40%' },
  sort: true
}, {
  dataField: 'company.name',
  text: 'Company',
  sort: true
}, {
  dataField: 'owner',
  text: 'Owner',
  sort: true
}, {
  dataField: 'promotableType',
  text: 'Category',
  sort: true
}, {
  dataField: 'createdAt',
  text: 'created',
  formatter: dateTime,
  sort: true
}]

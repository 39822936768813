import gql from 'graphql-tag'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  fragment CampaignAssetAssignments on Campaign {
    assetAssignments {
      ... AssetAssignment
    }
  }
  ${assetAssignment}
`

import React from 'react'
import { uniqBy } from 'lodash'

import { detalizeError } from '../../helpers/inputValidation'

import UnassignedAudiencesErrorMessage from './UnassignedAudienceErrorMessage'

const handleError = (innerError, error) => {
  switch (innerError.params.label) {
  case 'UnassignedAudiences':
    return <UnassignedAudiencesErrorMessage
      key={ innerError.message }
      message={ innerError.message }
      unassignedAudiences={ error.value[0].unassignedAudiences } />
  default:
    return detalizeError(innerError.message)
  }
}

const detalizeErrorHandler = (error) => {
  return uniqBy(error.inner, 'message').map((innerError) => handleError(innerError, error))
}

export default detalizeErrorHandler

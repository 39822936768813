import React  from 'react'
import styled from 'styled-components'
import SlickSlider from 'react-slick'
import classNames from 'classnames'
import { node, bool, number } from 'prop-types'

import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'

const Slider = ({ children, className, ...props }) => {
  return (
    <SlickSlider { ...props } className={ classNames(className, { static: !props.infinite }) }>
      { children }
    </SlickSlider>
  )
}

Slider.propTypes = {
  children: node.isRequired,
  infinite: bool,
  nextArrow: node,
  prevArrow: node,
  speed: number,
}

Slider.defaultProps = {
  speed: 500,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
}

export default styled(Slider)`
  .slick-slide {
    padding: 0 .5rem

    & > div {
      line-height: 0
    }
  }

  &.static {
    padding: 0

    .slick-track {
      margin: 0
    }

    .slick-slide:first-child {
      padding-left: 0
    }
  }

  .slick-arrow {
    height: auto
    width: auto
  }

  .slick-arrow:before {
    display: none
  }

  .slick-next {
    right: -15px;
  }

  .slick-prev {
    left: -15px;
  }
`

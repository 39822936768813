import styled from 'styled-components'

import { borderGreyColor, darkRoseColor } from 'shared/style/colors'

export default styled.div`
  border: 2px solid ${borderGreyColor}
  color: ${borderGreyColor}
  border-radius: .5rem;
  display: inline-block;
  height: 7rem;
  margin-right: 1rem;
  overflow: hidden;
  width: 10rem;
  vertical-align: top;
  margin-bottom: 1.5rem;

  .drop-over {
    border-style: dashed;
    opacity: .5
  }

  &:last-child {
    margin-right: 0
  }

  &.invalid {
    border: 2px solid ${darkRoseColor}
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%
  }
`

import React from 'react'

import Asc from 'shared/components/ui/Table/SortCaret/Asc'
import Desc from 'shared/components/ui/Table/SortCaret/Desc'
import Default from 'shared/components/ui/Table/SortCaret/Default'

const sortCaret = (order) => {
  if (order === 'asc') {
    return <Asc />
  } else if (order === 'desc') {
    return <Desc />
  } else {
    return <Default />
  }
}

export default sortCaret

import React, { useEffect } from 'react'
import { array, string } from 'prop-types'

import HandleValue from './HandleValue'
import MergedValues from './MergedValues'
import { haveIntersection } from './utils'

const HandleValues = ({ handles, suffix }) => {
  const handleRefs = handles.map(_el => React.createRef())
  const mergedValuesRef = React.createRef()

  useEffect(() => {
    if (handleRefs.length !== 2) return

    const [first, second] = handleRefs.map(ref => ref.current)
    const mergedElement = mergedValuesRef.current

    if (haveIntersection(first, second)) {
      first.style.opacity = 0
      second.style.opacity = 0
      mergedElement.style.opacity = 1
    } else {
      first.style.opacity = 1
      second.style.opacity = 1
      mergedElement.style.opacity = 0
    }
  }, [handles])

  return (
    <>
      {handles.map((handle, idx) => (
        <HandleValue
          key={ handle.id }
          ref={ handleRefs[idx] }
          handle={ handle }
          suffix={ suffix }
        />
      ))}
      <MergedValues handles={ handles } suffix={ suffix } ref={ mergedValuesRef } />
    </>
  )
}

HandleValues.propTypes = {
  handles: array.isRequired,
  suffix: string
}

HandleValues.defaultProps = {
  suffix: undefined
}

export default HandleValues

import React from 'react'
import { t } from 'ttag'
import { string, shape, array } from 'prop-types'

import Section from './Section'
import ColorSelect from './ColorSelect'

const DefaultColor = ({ project: { uuid, defaultColor, companyColors } }) => {
  return (
    <Section
      title={ t`Default color` }
      data-test-id='default-color-select-wrapper'>
      <ColorSelect
        selectedColor={ defaultColor }
        projectUuid={ uuid }
        companyColors={ companyColors }
      />
    </Section>
  )
}

DefaultColor.propTypes = {
  project: shape({
    uuid: string.isRequired,
    companyColors: array,
    defaultColor: string
  }).isRequired
}

export default DefaultColor

import gql from 'graphql-tag'

import creativeSet from 'graphql/fragments/creativeSet'

export default gql`
  mutation createCreativeSet($campaignUuid: String!, $type: CreativeSetTypesType!) {
    createCreativeSet(campaignUuid: $campaignUuid, type: $type) {
      creativeSet {
        ... CreativeSet
      }
    }
  }
  ${creativeSet}
`

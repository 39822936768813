import styled from 'styled-components'

import { cardBoxShadow } from 'shared/style/placeholders'
import {
  altoGreyColor,
  buttonBorderColor,
  componentBackgroundColor,
  greyishWhiteColor,
  labelColor,
  textGreyColor
} from 'shared/style/colors'

export const Container = styled.div`
  ${cardBoxShadow};

  background-color: ${componentBackgroundColor};
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
`

export const MainInfo = styled.div`
  padding: 1rem 1.5rem;

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    color: #787878;
    font-size: 0.875rem;
  }
`

export const AdditionalInfo = styled.div`
  border-top: solid 1px ${buttonBorderColor};
  background-color: ${greyishWhiteColor};
  display: flex;
  padding: 0.5rem 1.5rem;
`

export const Section = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  border-right: solid 1px ${altoGreyColor};
  margin-right: 1.5rem;
  padding-right: 1.5rem;

  &:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0rem;
  }
`

export const InfoLabel = styled.div`
  color: ${textGreyColor};
  font-size: 0.75rem;
  padding-top: 0.25rem;
  margin-bottom: 0.5rem;
`

export const InfoValue = styled.div`
  align-items: center;
  color: ${labelColor};
  display: flex;
  font-size: 0.75rem;
  padding-bottom: 0.25rem;
  white-space: nowrap;

  > :not(:last-child) {
    margin-right: 0.5rem;
  }
`

import React, { useMemo } from 'react'
import { t }      from 'ttag'
import styled     from 'styled-components'
import { Field }  from 'formik'
import { object } from 'prop-types'

import { labelColor } from 'shared/style/colors'
import useCountries   from 'shared/hooks/useCountries'
import useMapCenter   from 'shared/hooks/useMapCenter'
import FieldErrorMessage from 'shared/components/ui/FieldErrorMessage'
import Map               from 'shared/components/NewStage/StageDetails/Details/Map'
import BuildingTypesSelect
  from 'shared/components/ProductDetails/StageDetails/Details/BuildingTypesSelect'
import useDetailsForm    from 'shared/components/ProductDetails/BuildingDetails/useDetailsForm'
import DetailsRow        from 'shared/components/Project/Readonly/Stages/DetailsRow'

const DetailsForm = ({ className, building }) => {
  const {
    onLocationChange,
    onBuildingTypesChange
  } = useDetailsForm(building.uuid)

  const location = building.location || {}
  const { countries } = useCountries()
  const country = useMemo(() => (
    countries.find(
      ({ alpha2 }) => alpha2 === location.countryCode
    ) || {}
  ), [countries.length, location.countryCode])

  const pointMapToCoordinates = building.location ? useMapCenter(location).mapCenter : undefined

  return (
    <div className={ className } data-test-id="readonly-stage-details-panel">
      <aside className="details-column">
        <DetailsRow title={ t`Building name` }>
          { building.name }
        </DetailsRow>
        <DetailsRow title={ t`Address` }>
          { location?.address }
        </DetailsRow>
        <DetailsRow title={ t`Country` }>
          { country.name }
        </DetailsRow>
        <DetailsRow title={ t`Zip code` }>
          { location?.zipCode }
        </DetailsRow>
        <DetailsRow title={ t`Location` }>
          <Map
            pointMapToCoordinates={ pointMapToCoordinates }
            onLocationChange={ onLocationChange }
            marker={{
              latitude: location.latitude, longitude: location.longitude
            }}
          />
        </DetailsRow>
      </aside>

      <aside className="details-column">
        <DetailsRow title={ t`Unit types` }>
          <FieldErrorMessage name="buildingTypes">
            <Field
              name="buildingTypes"
              as={ BuildingTypesSelect }
              onChange={ onBuildingTypesChange }
            />
          </FieldErrorMessage>
        </DetailsRow>
      </aside>
    </div>
  )
}

DetailsForm.propTypes = {
  building: object.isRequired
}

export default styled(DetailsForm)`
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;

  .details-column {
    width: 45%;
  }

  small {
    display: block;
    color: ${labelColor}
  }
`

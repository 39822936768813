import React       from 'react'
import { func }    from 'prop-types'
import { Formik }  from 'formik'
import queryString from 'query-string'

import { setNewPassword }    from 'shared/helpers/auth'
import { pickErrors }        from 'shared/helpers/errors'
import validationSchema      from 'user/components/ResetPasswordForm/validationSchema'
import ResetPasswordFormView from 'user/components/ResetPasswordForm/ResetPasswordForm'

const ResetPasswordForm = ({ onSuccessfulReset }) => {
  const resetPasswordToken = queryString.parse(location.search).reset_password_token
  const initialValues = {
    reset_password_token: resetPasswordToken,
    password_confirmation: '',
    password: '',
  }

  return (
    <Formik
      validationSchema={ validationSchema }
      initialValues={ initialValues }
      onSubmit={ (values, { setSubmitting, setErrors }) => {
        setNewPassword(values)
          .then(() => {
            setSubmitting(false)

            onSuccessfulReset()
          })
          .catch((error) => {
            setSubmitting(false)

            setErrors(
              pickErrors(
                error,
                [
                  'reset_password_token',
                  'password_confirmation',
                  'password'
                ]
              )
            )
          })
      } }
    >
      {({
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <ResetPasswordFormView
          errors={ errors }
          touched={ touched }
          handleChange={ handleChange }
          handleSubmit={ handleSubmit }
          isSubmitting={ isSubmitting }
        />
      )}
    </Formik>
  )
}

ResetPasswordForm.propTypes = {
  onSuccessfulReset: func.isRequired
}

export default ResetPasswordForm

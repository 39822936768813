import gql from 'graphql-tag'

import channel from 'graphql/fragments/channel'

export default gql`
  mutation populateTargeting($campaignUuid: ID!) {
    populateTargeting(campaignUuid: $campaignUuid) {
      channels {
        ... Channel
      }
      errors
    }
  }
  ${channel}
`

import styled from 'styled-components'

import {
  borderGreyColor,
  buttonTextColor,
  textHeaderColor,
  bluishColor,
  galleryGreyColor
} from 'shared/style/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${borderGreyColor};
  border-radius: 3px;
  margin-bottom: 1.5rem;

  textarea {
    width: 100%;
    resize: none;
    border: none;
    outline: none;

    padding: 0.5rem;
  }
`

export const TabBar = styled.div`
  border-bottom: solid 1px ${borderGreyColor};
  display: flex;
  line-height: 2.25rem;
`

export const Tab = styled.div`
  color: ${textHeaderColor};
  cursor: pointer;
  font-weight: 500;
  padding: 0 1rem;

  opacity: ${props => props.active ? 1 : 0.5};

  span:not(:last-child) {
    margin-right: 0.5rem;
  }
`

export const SaveButton = styled.button`
  color: ${bluishColor};
  border-radius: 3px;
  border: none;
  font-size: 0.825rem;
  font-weight: 500;
  line-height: 1;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;

  :hover {
    background-color: ${galleryGreyColor}
  }
`

export const ActivityDetails = styled.div`
  width: 13rem;
  padding: 1rem;

  > :not(:last-child) {
    margin-bottom: 0.5rem;
  }

  input.form-control {
    border-radius: 4px;
    border: solid 1px ${borderGreyColor};
    box-shadow: none;
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem 0.375rem;
    height: auto;
  }
`

export const VerticalSeparator = styled.div`
  border-right: solid 1px ${borderGreyColor};
  margin: 1rem 0;
`

export const ActivityNotes = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  padding: 1rem;

  textarea {
    border: solid 1px ${borderGreyColor};
    border-radius: 4px;
    height: 4.4375rem;
    font-size: 0.875rem;
  }

  ${SaveButton} {
    margin: 0;
  }
`

export const ActivityTypeLabel = styled.label`
  position: relative;
  margin: 0 0.45rem;

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }

  div {
    align-items: center;
    display: flex;
    border-radius: 4px;
    border: solid 2px transparent;
    justify-content: center;
    height: 1.875rem;
    width: 1.875rem;
  }

  input:checked + div {
    border-color: ${bluishColor};
  }
`

export const Input = styled.input`
  border-radius: 4px;
  border: solid 1px ${borderGreyColor};
  color: ${buttonTextColor};
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.5rem 0.75rem 0.375rem;
  width: 100%;
`

import { gql } from 'apollo-boost'

import user from 'graphql/fragments/user'

export default gql`
  mutation sendConfirmationEmail($uuid: ID!) {
    sendConfirmationEmail(uuid: $uuid) {
      user {
        ... User
      }
    }
  }
  ${user}
`

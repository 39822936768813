import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { t } from 'ttag'
import { arrayOf, shape, string, any } from 'prop-types'

import { buttonTextColor } from 'shared/style/colors'

import Option from './Option'
import SingleValue from './SingleValue'

const components = { Option, SingleValue }

const ImageSelect = (props) => {
  return (
    <Select
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
      components={ components }
    />
  )
}

ImageSelect.propTypes = {
  options: arrayOf(shape({
    src: string.isRequired,
    value: any.isRequired,
    label: string,
  })).isRequired
}

export default styled(ImageSelect)`
  [class*="-control"] {
    & > * {
      height: 100%;
    }
  }

  .image-select--option {
    align-items: center;
    display: flex;

    img {
      width: 2rem;
      margin-right: 0.625rem;
    }

    span {
      color: ${buttonTextColor};
      font-size: 0.75rem;
    }
  }

  .image-select--value-container {
    height: 100%;

    img {
      width: 100%;
      margin-top: 10%
    }
  }
`

import { filter, flatten, flow, map, sortBy, sortedUniq } from 'lodash/fp'

export default function uniqEnabledThumbnails(creatives) {
  return flow(
    flatten,
    filter(({ enabled }) => {
      return enabled
    }),
    sortBy(({ assetAssignment }) => {
      return assetAssignment.asset?.position
    }),
    map(({ assetAssignment }) => {
      return assetAssignment.asset?.thumbnail
    }),
    filter(Boolean),
    sortedUniq,
  )(creatives)
}

import gql from 'graphql-tag'

import landingPage from 'graphql/fragments/landingPage'

export default gql`
  mutation CloneLandingPage(
    $uuid: ID!
  ) {
    cloneLandingPage(
      uuid: $uuid
    ) {
      landingPage {
        ...LandingPage
      }
    } 
  }
  ${landingPage}
`

import Cookie                   from 'js-cookie'
import { addLocale, useLocale } from 'ttag'

const allLocales = [
  { key: 'en_US', label: 'English' },
  { key: 'nb_NO', label: 'Norwegian (bokmål)' },
  { key: 'sv_SE', label: 'Swedish' },
  { key: 'id_ID', label: 'Indonesian' }
]

const defaultLocale = 'en_US'

const url = new URL(window.location.href)
const params = new URLSearchParams(url.search.slice(1))

const safeLocale = (localeKey) => (
  allLocales.find((locale) => locale.key === localeKey) && localeKey
)

const currentLocale = safeLocale(params.get('locale'))
      || safeLocale(Cookie.get('locale'))
      || defaultLocale

if (currentLocale !== defaultLocale) {
  const translationObj = require(`./locales/${currentLocale}.po.json`)
  addLocale(currentLocale, translationObj)
  useLocale(currentLocale)
}

// See, https://airtable.com/tbljXPChADYp45NPR/viwVj9z3E4eFQIuJA/recdIMTFdeM1j30Q4?blocks=hide
const availableLocales = allLocales.filter((locale) => {
  return locale.key !== 'id_ID' || process.env.RELEASE_STAGE === 'staging'
})

const notUsedLocales = availableLocales.filter((locale) => locale.key !== currentLocale)

const changeLocale = (locale) => {
  if (availableLocales.find((availableLocale) => availableLocale.key === locale)) {
    Cookie.set('locale', locale)

    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    const localeShortForm = locale.replace(/_.+$/, '')
    params.set('locale', localeShortForm)
    window.location.search = params.toString()
  }
}

export {
  defaultLocale,
  currentLocale,
  availableLocales,
  notUsedLocales,
  changeLocale
}

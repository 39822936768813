import { gql } from 'apollo-boost'

import campaign from 'graphql/fragments/campaign'

export default gql`
  mutation deleteCampaign($uuid: ID!) {
    deleteCampaign(uuid: $uuid) {
      campaign {
        ... Campaign
      }
    }
  }
  ${campaign}
`

import { gql } from 'apollo-boost'

export default gql`
  query Stages($projectUuid: ID!) {
    stages(projectUuid: $projectUuid) {
      id
      name
    }
  }
`

import { gql } from 'apollo-boost'

import campaign from 'graphql/fragments/campaign'

export default gql`
  mutation assignCampaignDate(
    $uuid: ID!,
    $startDate: Iso8601DateTime,
    $endDate: Iso8601DateTime
  ) {
    assignCampaignDate(
      uuid: $uuid,
      startDate: $startDate,
      endDate: $endDate
    ) {
      campaign {
        ... Campaign
      }
    }
  }
  ${campaign}
`

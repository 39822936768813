import gql from 'graphql-tag'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  fragment ProjectAssetAssignments on Project {
    originalAssetAssignments {
      ... AssetAssignment
    }
  }
  ${assetAssignment}
`

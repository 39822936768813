import * as Yup from 'yup'
import { t } from 'ttag'

export default Yup.object().shape({
  name: Yup.string()
    .required(t`Please fill a campaign name`),

  startDate: Yup.date()
    .required(t`Please fill start and end date`),

  endDate: Yup.date()
    .required(t`Please fill start and end date`),

  dsaBeneficiary: Yup.string()
    .required(t`Please fill in the beneficiary`),

  dsaPayor: Yup.string()
    .required(t`Please fill in the payor`),
})

import React, { useState } from 'react'
import { t } from 'ttag'

import projectType from 'shared/types/projectType'
import Modal from 'shared/components/ui/Modal'

import DownloadAction from './DownloadAction'

const DocumentsExportMenuItem = ({ project }) => {
  const [showModal, setShowModal] = useState(false)
  const text = t`Download all documents`

  const onClickAction = (e) => {
    e.preventDefault()
    setShowModal(true)
  }

  return (
    <>
      <a href='#' onClick={ onClickAction }>{ text }</a>

      <Modal
        show={ showModal }
        onHide={ () => setShowModal(false) }
      >
        <Modal.Header closeButton>{ text }</Modal.Header>
        <Modal.Body>
          <DownloadAction project={ project } showModal={ showModal } />
        </Modal.Body>
      </Modal>
    </>
  )
}

DocumentsExportMenuItem.propTypes = {
  project: projectType.isRequired
}

export default DocumentsExportMenuItem

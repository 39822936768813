import styled from 'styled-components'

import {
  galleryGreyColor,
  bluishColor,
  lightBlueColor,
  shuttleGray,
  componentBackgroundColor
} from 'shared/style/colors'

export const Container = styled.div`
  display: flex;
  margin: 1rem 0;
`

export const Arrows = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: -0.9375rem;
  width: 1.875rem;
`

export const ArrowTop = styled.div`
  ::before {
    content: '';
    position: absolute;
    border-color: transparent;
    border-right: solid 0.1875rem ${componentBackgroundColor};
    border-radius: 1px;
    z-index: 2;
    top: 0;
    right: 0.5625rem;
    height: 1.4375rem;
    width: 1.0625rem;
    transform: rotate(-30deg);
  }

  ::after {
    content: '';
    position: absolute;
    background-color: ${props => props.active ? bluishColor : galleryGreyColor};
    border-color: transparent;
    z-index: 1;
    top: 0;
    right: 0.75rem;
    height: 1.4375rem;
    width: 1.0625rem;
    transform: rotate(-30deg);
  }
`

export const ArrowBottom = styled.div`
  ::before {
    content: '';
    position: absolute;
    border-color: transparent;
    border-right: solid 0.1875rem ${componentBackgroundColor};
    border-radius: 1px;
    z-index: 1;
    bottom: 0;
    right: 0.5625rem;
    height: 1.4375rem;
    width: 1.0625rem;
    transform: rotate(30deg);
  }

  ::after {
    content: '';
    position: absolute;
    background-color: ${props => props.active ? bluishColor : galleryGreyColor};
    border-color: transparent;
    z-index: 1;
    bottom: 0;
    right: 0.75rem;
    height: 1.4375rem;
    width: 1.0625rem;
    transform: rotate(30deg);
  }
`

export const Tab = styled.div`
  color: ${props => props.active ? componentBackgroundColor : shuttleGray};
  cursor: pointer;
  background-color: ${props => props.active ? bluishColor : galleryGreyColor};
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 0.75rem;
  font-weight: 600;
  padding: .5rem 1rem .5rem 2rem;
  position: relative;
  white-space: nowrap;

  :first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  :last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  :hover {
    color: ${componentBackgroundColor};
    background-color: ${lightBlueColor};
   }

   :hover ${ArrowTop}::after {
     background-color: ${lightBlueColor};
   }

   :hover ${ArrowBottom}::after {
     background-color: ${lightBlueColor};
   }
  }
`

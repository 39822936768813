import gql from 'graphql-tag'

export default gql`
  mutation RemoveLandingPageGalleryImage(
    $uuid: ID!
  ) {
    removeLandingPageGalleryImage(
      uuid: $uuid
    ) {
      galleryImage {
        uuid
      }
    }
  }
`

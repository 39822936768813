import React from 'react'

import mutation from 'graphql/mutations/campaign/specifications/updateCampaignGoals'
import toastError from 'shared/toastError'

export default function updateCampaignGoals({ client, campaignUuid, name, value }) {
  let coercedValue = value

  if (['number', 'cpa'].includes(name)) {
    coercedValue = parseFloat(coercedValue)
  }

  client.mutate({
    mutation,
    variables: {
      campaignUuid, goals: { [name]: coercedValue }
    }
  }).then((response) => {
    const errors = response?.data?.updateCampaignGoals?.errors

    if (errors.length) {
      toastError(
        <>
          { errors.map(({ text }) => <p className="mb-0" key={ text }>{ text }</p>) }
        </>
      )
    }
  })
}

import gql from 'graphql-tag'

export default gql`
  query Project($uuid: ID!) {
    project(uuid: $uuid) {
      uuid
      product {
        uuid
        company {
          enabledFeatures
        }
        newsPageBaseUrl
        newsPosts {
          uuid
          newsType
          title
          description
          callToActionText
          callToActionDestinationUrl
          publishedAt
          updatedAt
          editedAt
          state
          viewsCount
          ctaClicksCount
          sharesCount
          reactionsCount
          media {
            uuid
            state
            asset {
              uuid
              media
              thumbnail
              position
              mediaType
              deletable
            }
          }
        }
      }
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'ttag'
import styled from 'styled-components'

import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import FormLabel from 'shared/components/ui/FormLabel'

import PeriodPicker from './PeriodPicker'

const enabledTimeUnits = ['custom', 'quarter', 'year', 'month']

const Phase = ({ className, phase, updatePhase }) => {
  const handleTitleChange = (value) => {
    updatePhase(phase.uuid, { title: value })
  }

  const handlePeriodChange = (value) => {
    updatePhase(phase.uuid, value)
  }

  return (
    <div className={ className }>
      <div className='form-inline w-100'>
        <div className='w-50'>
          <FormLabel>{ t`Description` }</FormLabel>
          <InputWrapper>
            <ThriftyInput
              data-test-id='title-field'
              className='w-100'
              type="text"
              value={ phase.title }
              onUpdate={ handleTitleChange }
            />
          </InputWrapper>
        </div>
        <div className='w-50 pl-5'>
          <PeriodPicker
            handleChange={ handlePeriodChange }
            phasePeriod={ phase.phasePeriod }
            startDate={ phase.startDate }
            endDate={ phase.endDate }
            enabledTimeUnits={ enabledTimeUnits }
          />
        </div>
      </div>
    </div>
  )
}

Phase.propTypes = {
  phase: PropTypes.object.isRequired,
  updatePhase: PropTypes.func.isRequired

}

export default styled(Phase)`
  margin-top: 10px;

  .form-inline label {
    display: flex;
    justify-content: left;
    margin-bottom: 0;
    align-items: flex-start;
  }

  .form-inline {
    justify-content: space-between;
    align-items: center;
  }

  input, textarea {
    box-shadow: none;
  }
`

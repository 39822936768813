import React from 'react'
import { arrayOf } from 'prop-types'
import { get } from 'lodash'
import { t } from 'ttag'

import MultiSelect from 'shared/components/ui/MultiSelect/MultiSelect'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import { textColor } from 'shared/style/colors'
import creativeSetType from 'shared/types/creativeSetType'
import landingPageType from 'shared/types/landingPageType'

import Option from './Option'
import ValueContainer from './ValueContainer'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1
  }),
  option: provided => ({
    ...condensedSelectStyles.option(provided),
    alignItems: 'center',
    backgroundColor: 'white',
    color: textColor,
    display: 'flex',
    fontSize: '0.875',
    lineHeight: '1rem',
    padding: '0.125rem 0.75rem',
    whiteSpace: 'nowrap'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  })
}

const CreativeSetsSelect = ({ creativeSets, landingPage, ...props }) => {
  const options = creativeSets.map(({ uuid, name, ...rest }) => ({
    value: uuid,
    label: name || t`Unnamed creative set`,
    ...rest
  }))

  const selectedOptions = options.filter(
    option => get(option, 'landingPage.uuid') === landingPage.uuid
  )

  return (
    <MultiSelect
      isClearable={ false }
      options={ options }
      value={ selectedOptions }
      styles={ styles }
      components={{ Option, ValueContainer }}
      hideSelectedOptions={ false }
      landingPageUuid={ landingPage.uuid }
      { ...props }
    />
  )
}

CreativeSetsSelect.propTypes = {
  creativeSets: arrayOf(creativeSetType).isRequired,
  landingPage: landingPageType.isRequired
}

export default CreativeSetsSelect

import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'react-apollo'
import _ from 'lodash'

import removeAssetAssignmentMutation from 'graphql/mutations/assetable/removeAssetAssignment'
import createAssetAssignmentMutation from 'graphql/mutations/assetable/createAssetAssignment'
import moveToPositionMutation from 'graphql/mutations/assetable/moveToPosition'
import attachAssetMutation from 'graphql/mutations/assetable/attachFile'
import snapshotAssetAssignmentsQuery from 'graphql/queries/snapshots/assetAssignments'

import originalAssetAssignments from '/graphql/fragments/originalAssetAssignments'

import { buildFragmentParams } from 'shared/helpers/graphql'
import UploadContainer from 'shared/components/Media/AssetAssignments/UploadContainer'
import AssetContainer from 'shared/components/Media/AssetAssignments/AssetContainer'
import DraggableAssetContainer
  from 'shared/components/Media/AssetAssignments/DraggableAssetContainer'
import EmptyAsset from 'shared/components/Media/AssetAssignments/EmptyAsset'
import AssetLabel from 'shared/components/ui/AssetLabel'

import SelectableImage from './SelectableProjectImage'

const AssignableAssets = ({
  className,
  projectAssetAssignments,
  assetSourceUuid,
  assetSourceType,
  assetableUuid,
  assetableType,
  mediaType
}) => {
  const [updatingAssets, setUpdatingAssets] = useState([])

  const [runAttachAsset] = useMutation(attachAssetMutation)
  const [runRemoveAssetAssignment] = useMutation(removeAssetAssignmentMutation)
  const [runCreateAssetAssignment] = useMutation(createAssetAssignmentMutation)
  const [moveAssetToPosition] = useMutation(moveToPositionMutation)

  const {
    data: { snapshotAssetAssignments: assetAssignments } = { snapshotAssetAssignments: [] },
    refetch: refetchAssetAssignments
  } = useQuery(snapshotAssetAssignmentsQuery, {
    variables: { snapshotUuid: assetableUuid },
  })

  useEffect(() => {
    refetchAssetAssignments()
  }, [projectAssetAssignments])

  const assets = useMemo(() => {
    const imageAssets = projectAssetAssignments
      .map(assetAssignment => assetAssignment.asset)
      .filter(asset => asset.mediaType === mediaType)

    return _.uniqBy(imageAssets, 'uuid')
  }, [projectAssetAssignments, mediaType])

  const sortedAssetAssignments = useMemo(() => {
    return assetAssignments.sort((first, second) => {
      return first.portalPositionDraft - second.portalPositionDraft
    })
  }, [assetAssignments])

  const selectedAssets = assetAssignments.map(assignment => assignment.asset.uuid)

  const unselectedAssets = assets.filter(asset => !selectedAssets.includes(asset.uuid))

  const removeImageAssignment = (uuid) => {
    if (updatingAssets.includes(uuid)) { return }

    const deletingAssignment = assetAssignments.find(value => value.asset.uuid === uuid)
    if (!deletingAssignment) { return }

    runAssetAssignmentAction(async () => {
      await removeAssetAssignment(deletingAssignment.uuid)
    }, uuid)
  }

  const addImageAssignment = (uuid) => {
    if (selectedAssets.includes(uuid)) { return }

    runAssetAssignmentAction(async () => {
      await addAssetAssignment(uuid)
    }, uuid)
  }

  const runAssetAssignmentAction = async (asyncAction, uuid) => {
    setUpdatingAssets(prev => [...prev, uuid])
    await asyncAction(uuid)
    setUpdatingAssets(prev => prev.filter(v => v !== uuid))
  }

  const removeAssetAssignment = (uuid) => {
    return runRemoveAssetAssignment({
      variables: {
        confirmRemoval: true,
        assetAssignmentUuid: uuid
      },
      refetchQueries:[{
        query: snapshotAssetAssignmentsQuery,
        variables: { snapshotUuid: assetableUuid }
      }],
      errorPolicy: 'all',
    })
  }

  const addAssetAssignment = (assetUuid) => {
    return runCreateAssetAssignment({
      variables: {
        assetableUuid: assetableUuid,
        assetableType: assetableType,
        assetUuid: assetUuid,
        mediaType: mediaType
      },
      refetchQueries:[{
        query: snapshotAssetAssignmentsQuery,
        variables: { snapshotUuid: assetableUuid }
      }]
    })
  }

  const handleFileUpload = async ({ mediaType, blobId }) => {
    const result = await runAttachAsset({
      variables: {
        mediaType,
        blobId,
        assetableUuid: assetSourceUuid,
        assetableType: assetSourceType
      },
      update: (cache, response) => {
        const fragmentParams = buildFragmentParams(
          { __typename: assetSourceType, uuid: assetSourceUuid },
          originalAssetAssignments
        )
        const fragmentData = cache.readFragment(fragmentParams)
        const assetAssignment = _.get(response, 'data.attachFileToAssetable.assetAssignment')

        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            originalAssetAssignments: [
              ...fragmentData.originalAssetAssignments,
              assetAssignment
            ]
          }
        })
      }
    })

    const newAsset = result.data.attachFileToAssetable.assetAssignment.asset
    if (!newAsset) { return }

    addAssetAssignment(newAsset.uuid)
  }

  const handleAssetDrop = (draggedAsset, targetAsset) => {
    moveAssetToPosition({
      variables: {
        uuid: draggedAsset.uuid,
        newPosition: targetAsset.portalPositionDraft.toString()
      }
    })
  }

  return (
    <div className={ className }>
      { sortedAssetAssignments.map(assetAssignment => (
        <DraggableAssetContainer
          key={ assetAssignment.uuid }
          data-test-id={ `${assetAssignment.uuid}-asset-container` }
          asset={ assetAssignment }
          onDragStart={ (e) => e.preventDefault() }
          onDrop={ handleAssetDrop }
        >
          <SelectableImage
            isSelected
            busy={ updatingAssets.includes(assetAssignment.asset.uuid) }
            onClickHandler={ removeImageAssignment }
            asset={ assetAssignment.asset }
          />
          <AssetLabel className="asset-label" asset={ assetAssignment.asset }/>
        </DraggableAssetContainer>
      )) }

      { unselectedAssets.map(asset => (
        <AssetContainer
          key={ asset.uuid }
          data-test-id={ `${asset.uuid}-asset-container` }
          asset={ asset }
          onDragStart={ (e) => e.preventDefault() }
        >
          <SelectableImage
            isSelected={ false }
            busy={ updatingAssets.includes(asset.uuid) }
            onClickHandler={ addImageAssignment }
            asset={ asset }
          />
          <AssetLabel className="asset-label" asset={ asset }/>
        </AssetContainer>
      )) }

      <UploadContainer
        onFileUploaded={ handleFileUpload }
        mediaType={ 'image' }
        data-test-id='upload-container'
        assetAssignments={ assetAssignments }
      >
        <AssetContainer>
          <EmptyAsset clickable mediaType="image" data-test-id='empty-asset' />
        </AssetContainer>
      </UploadContainer>
    </div>
  )
}

AssignableAssets.propTypes = {
  assetSourceType: PropTypes.string.isRequired,
  assetSourceUuid: PropTypes.string.isRequired,
  assetableType: PropTypes.string.isRequired,
  assetableUuid: PropTypes.string.isRequired,
  projectAssetAssignments: PropTypes.array.isRequired,
  mediaType: PropTypes.string
}

AssignableAssets.defaultProps = {
  mediaType: 'image'
}

export default AssignableAssets

import React, { forwardRef } from 'react'
import styled from 'styled-components'

import StyledDatepicker from 'shared/components/ui/Datepickers/StyledDatepicker'

const PhaseDatePicker = (props) => {
  // eslint-disable-next-line react/display-name,react/prop-types
  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      readOnly
      ref={ ref }
      className='form-control'
      value={ value }
      type='text'
      onClick={ onClick }
      onChange={ onChange }
    />
  ))

  return (
    <StyledDatepicker
      customInput={ <ExampleCustomInput /> }
      { ...props }
    />
  )
}

export default styled(PhaseDatePicker)`
  input {
    text-align: center; 
  }
`

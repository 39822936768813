import { gql } from 'apollo-boost'

export default gql`
  mutation removeAssetAssignment(
    $assetAssignmentUuid: ID!,
    $confirmRemoval: Boolean
  ) {
    removeAssetAssignment(
      assetAssignmentUuid: $assetAssignmentUuid,
      confirmRemoval: $confirmRemoval
    ) {
      usedInAssetables {
        uuid
        assetAssignments {
          uuid
          portalPositionDraft
        }
      }
      usedInCampaigns {
        uuid
        startDate
        endDate
      }
      errors
    }
  }
`

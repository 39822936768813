import gql from 'graphql-tag'

export default gql`
  mutation updateBuilding(
    $buildingUuid: ID!
    $input: BuildingInput
    $location: LocationInput
  ) {
    updateBuilding(
      buildingUuid: $buildingUuid
      input: $input
      location: $location
    ) {
      building {
        uuid
        name
        buildingTypes
        location {
          address
          countryCode
          zipCode
          latitude
          longitude
        }
        constructionStartPhasePeriod
        constructionStartAt
        constructionStartAtIsoFormat
        constructionEndAt
        constructionEndAtIsoFormat
        movingStartPhasePeriod
        movingStartAt
        movingStartAtIsoFormat
        movingEndAt
        movingEndAtIsoFormat
      }
    }
  }
`

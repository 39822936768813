import React from 'react'

import matchType from 'shared/types/matchType'
import PortalEdit from 'shared/components/ProjectPortalEdit'

const ProjectPortalEdit = ({ match }) => {
  return (
    <PortalEdit uuid={ match.params.uuid } />
  )
}

ProjectPortalEdit.propTypes = {
  match: matchType.isRequired
}

export default ProjectPortalEdit

import { t } from 'ttag'

import numberFormatter from '../Ads/numberFormatter'

import locationFormatter from './locationFormatter'
import locationSortFunc  from './locationSortFunc'

export default [{
  dataField: 'uuid',
  hidden: true
}, {
  dataField: 'name',
  text: t`Audience name`,
  sort: true
}, {
  dataField: 'locations',
  text: t`Location`,
  sort: true,
  formatter: locationFormatter,
  sortFunc: locationSortFunc
}, {
  dataField: 'totalImpressions',
  text: t`Impressions`,
  formatter: numberFormatter,
  sort: true
}, {
  dataField: 'totalClicks',
  text: t`Clicks`,
  formatter: numberFormatter,
  sort: true
}, {
  dataField: 'averageCpl',
  text: t`CPL`,
  formatter: numberFormatter,
  sort: true
}, {
  dataField: 'ctr',
  text: t`CTR`,
  formatter: numberFormatter,
  sort: true
}, {
  dataField: 'cpc',
  text: t`CPC`,
  formatter:  numberFormatter,
  sort: true
}, {
  dataField: 'leadsCount',
  text: t`Leads`,
  formatter: numberFormatter,
  sort: true
}]

import React from 'react'
import styled from 'styled-components'
import { string, func, number } from 'prop-types'

import ThriftyCurrencyInput from 'shared/components/ui/ThriftyInput/BigNumber'

const TotalPriceInput = ({ totalPrice, onUpdate, uuid, className, ...props }) => {
  const handleInput = totalPrice => onUpdate(
    { uuid, attributes: { totalPrice: parseFloat(totalPrice) } }
  )

  return (
    <ThriftyCurrencyInput
      className={ className }
      min={ 0 }
      value={ totalPrice }
      onUpdate={ handleInput }
      data-test-id='unit-price-input'
      { ...props }
    />
  )
}

TotalPriceInput.propTypes = {
  onUpdate: func.isRequired,
  uuid: string.isRequired,
  totalPrice: number
}

TotalPriceInput.defaultProps = {
  totalPrice: 0
}

export default styled(TotalPriceInput)`
  box-shadow: none !important;
  height: 2.375rem;
  font-size: .75rem !important
`

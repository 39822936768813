import { gql } from 'apollo-boost'

import SnapshotCustomFacility from 'graphql/fragments/snapshotCustomFacility'

export default gql`
  mutation updateSnapshotCustomFacility(
    $uuid: ID!
    $snapshotUuid: ID!
    $input: SnapshotCustomFacilityInput!
  ) {
    updateSnapshotCustomFacility(
      uuid: $uuid
      snapshotUuid: $snapshotUuid
      input: $input
    ) {
      customFacility {
       ... SnapshotCustomFacility
      }
    }
  }
  ${SnapshotCustomFacility}
`


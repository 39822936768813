import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { arrayOf, shape, string, number } from 'prop-types'

import { labelColor } from 'shared/style/colors'
import { formatPrice } from 'shared/helpers/currency'
import { tableBorderValue } from 'shared/style/placeholders'

const Totals = ({ className, channels, currency }) => {
  const sum = channels.reduce((sum, channel) => (sum + channel.spending), 0)

  return (
    <h5 className={ className }>
      <span>
        { t`Total` }
      </span>

      <span data-testid='total-budget'>
        { formatPrice(sum, currency) }
      </span>
    </h5>
  )
}

Totals.propTypes = {
  channels: arrayOf(shape({
    spending: number.isRequired
  })).isRequired,
  currency: string.isRequired,
}

export default styled(Totals)`
  border-top: ${tableBorderValue}
  color: ${labelColor}
  display: flex
  justify-content: space-between
  font-size: 1.125rem
  margin-top: .75rem
  padding: .5rem 0 0 0
`

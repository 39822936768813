import { useMutation } from 'react-apollo'

import deleteUserMutation from 'graphql/mutations/users/deleteUser'

export default function useDeleteUser() {
  const [deleteUser, variables] = useMutation(deleteUserMutation)

  return {
    deleteUser,
    ...variables
  }
}

import gql from 'graphql-tag'

export default gql`
  fragment Offer on Offer {
    uuid
    buyer {
      name
      type
    }
    conditions
    expiresAt
    receivedAt
    signedDocumentUrl
    unitId
    altUnitsCount
  }
`

import React from 'react'
import styled from 'styled-components'
import { object } from 'prop-types'

import useTabBar from 'shared/hooks/useTabBar'

import tabs from './TabBar/tabs'

const BuildingDetails = ({ building, ...props }) => {
  const { tabBar, CurrentComponent } = useTabBar(tabs)

  return (
    <Container>
      { tabBar }

      <CurrentComponent building={ building } { ...props } />
    </Container>
  )
}

const Container = styled.div`
  margin-top: -1.1rem;
  margin-left: -1.45rem;
  margin-right: -1.45rem;
`

BuildingDetails.propTypes = {
  building: object.isRequired
}

export default BuildingDetails

import React       from 'react'
import styled      from 'styled-components'
import queryString from 'query-string'
import { Form }    from 'react-bootstrap'
import { t }       from 'ttag'
import {
  string,
  shape,
  func,
  bool
} from 'prop-types'

import Button from 'shared/components/ui/Button'

const ResetPasswordForm = ({
  handleSubmit,
  className,
  handleChange,
  touched,
  errors,
  isSubmitting
}) => {
  const resetPasswordToken = queryString.parse(location.search).reset_password_token

  return (
    <Form noValidate onSubmit={ handleSubmit } className={ className }>
      <h1>{ t`Set password` }</h1>

      { !!resetPasswordToken || (
        <p className="text-danger">
          { t`Your url must contain "reset_password_token" parameter to be able to reset password` }
        </p>
      ) }

      { (errors.reset_password_token && touched.reset_password_token) && (
        <p className="text-danger">
          { t`Token` } { errors.reset_password_token }
        </p>
      ) }

      <Form.Control
        name="reset_password_token"
        type="hidden"
      />

      <input type="hidden" name="reset_password_token" value={ resetPasswordToken }/>

      <Form.Label htmlFor="password">{ t`New password` }</Form.Label>
      <Form.Control
        name="password"
        type="password"
        id="password"
        onChange={ handleChange }
        isInvalid={ touched.password && errors.password }
      />
      <Form.Control.Feedback type="invalid">
        {errors.password}
      </Form.Control.Feedback>

      <Form.Label htmlFor="password-confirmation">{ t`Repeat new password` }</Form.Label>
      <Form.Control
        name="password_confirmation"
        type="password"
        id="password-confirmation"
        onChange={ handleChange }
        isInvalid={ touched.password_confirmation && errors.password_confirmation }
      />
      <Form.Control.Feedback type="invalid">
        {errors.password_confirmation}
      </Form.Control.Feedback>

      <Button
        type="submit"
        aria-label="Submit"
        disabled={ isSubmitting }
      >
        { t`Set password` }
      </Button>
    </Form>
  )
}

ResetPasswordForm.propTypes = {
  className: string.isRequired,
  errors: shape({
    password: string,
    password_confirmation: string,
  }).isRequired,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired,
  touched: shape({
    password: bool,
    password_confirmation: bool,
  }).isRequired
}

export default styled(ResetPasswordForm)`
  max-width: 32rem
  width: 100%
  padding: 1rem
  padding-bottom: 5rem

  h1 {
    margin-bottom: 1rem
  }

  ${Button} {
    width: 13.375rem
    margin-top: 1.4rem
  }
`

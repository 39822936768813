import { gql } from 'apollo-boost'

export default gql`
  query CampaignMostRecentPublications($campaignUuid: ID!) {
    campaignMostRecentPublications(campaignUuid: $campaignUuid) {
      uuid
      status
      scheduledAt
      startedAt
      finishedAt
      failedAt
      error {
        klass
        message
        backtrace
      }
      channel {
        uuid
        type
      }
    }
  }
`

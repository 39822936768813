import React from 'react'

import NewLead from 'shared/components/NewLead'

const NewLeadPage = () => {
  return (
    <NewLead />
  )
}

export default NewLeadPage

import React from 'react'
import { t } from 'ttag'

import propertyType from 'shared/types/propertyType'
import Column from 'shared/components/ui/TwoColumnList/Column'

import Address from '../Address'

import VisitsHours from './VisitsHours'
import Price from './Price'

const LeftColumn = ({ promotable }) => {
  const { location, visitsHours, price, totalPrice, currency } = promotable
  const columnItems = []

  if (location) {
    columnItems.push({ label: t`Address`, value: <Address location={ location }/> })
  }

  if (location.country) {
    columnItems.push({ label: t`Country`, value: location.country })
  }

  columnItems.push(
    ...visitsHours
      .filter(({ startAt, endAt }) => startAt && endAt)
      .map((visitsHours, i) => ({
        label: `Showing time ${i + 1}`,
        value: <VisitsHours visitsHours={ visitsHours } />
      }))
  )

  if (price) {
    columnItems.push({
      label: 'Price',
      value: <Price price={ price } currency={ currency } />
    })
  }

  if (totalPrice) {
    columnItems.push({
      label: 'Total price',
      value: <Price price={ totalPrice } currency={ currency } />,
    })
  }

  return <Column column={ columnItems }/>
}

LeftColumn.propTypes = {
  promotable: propertyType.isRequired
}

export default LeftColumn

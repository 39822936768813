import React from 'react'
import { string } from 'prop-types'

import Progress from './Progress'
import Note from './Note'
import Activity from './Activity'
import RecordIcon from './RecordIcon'
import {
  RecordContainer,
} from './elements'

const presenters = {
  NoteRecord: Note,
  ProgressRecord: Progress,
  ActivityRecord: Activity
}

const Record = props => {
  const PresenterComponent = presenters[props.__typename]

  if (!PresenterComponent) return null

  return (
    <RecordContainer>
      <RecordIcon { ...props } />
      <PresenterComponent { ...props } />
    </RecordContainer>
  )
}

Record.propTypes = {
  __typename: string.isRequired
}

export default Record

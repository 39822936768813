import React from 'react'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { object }   from 'prop-types'

import dashboardAnalyticsAverageLcrQuery from 'graphql/queries/dashboardAnalytics/averageLcr'
import LoadingBlock                      from 'shared/components/ui/LoadingBlock'

import Card                from './Card'
import Chart               from './LinearChart'
import formatCurrentValues from './formatCurrentValues'

const LcrChart = ({ filter }) => {
  const {
    data: {
      dashboardAnalyticsAverageLcr: {
        averageLcr = {}
      } = {}
    } = {},
    loading
  } = useQuery(dashboardAnalyticsAverageLcrQuery, {
    variables: { filter }
  })

  if (loading) {
    return (
      <Card header={ t`LCR` }>
        <LoadingBlock />
      </Card>
    )
  }

  const { averageLcrValue } = formatCurrentValues({ averageLcr })

  return (
    <Chart
      name={ t`Lead conversion rate (LCR)` }
      comment={ t`Average LCR` }
      dataTestId='lcr-chart'
      dataset={ averageLcr.dataset }
      currentValue={ averageLcrValue }
    />
  )
}

LcrChart.propTypes = {
  filter: object
}

LcrChart.defaultProps = {
  filter: {}
}

export default LcrChart

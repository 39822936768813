import React     from 'react'
import pluralize from 'pluralize'
import { func, arrayOf } from 'prop-types'

import UserRoleNavLink from 'routes/UserRoleNavLink'
import Modal           from 'shared/components/ui/Modal'
import Button          from 'shared/components/ui/Button'
import campaignType    from 'shared/types/campaignType'
import { displayDate } from 'shared/helpers/date'

const ConfirmationModal = ({ onHide, onConfirm, usedInCampaigns }) => {
  return usedInCampaigns && (
    <Modal
      centered
      show={ !!(usedInCampaigns && usedInCampaigns.length) }
      onHide={ onHide }
    >
      <Modal.Body>
        <p>
          This asset is also used in { pluralize('campaign', usedInCampaigns.length) }:
        </p>

        {
          usedInCampaigns && (
            usedInCampaigns.map((campaign) => (
              <div key={ campaign.uuid }>
                <UserRoleNavLink
                  target="_blank"
                  to={ `/campaigns/${campaign.uuid}` }
                >
                  {
                    campaign.startDate && `Start date: ${displayDate(campaign.startDate)}  `
                  }
                  {
                    campaign.endDate && `End date: ${displayDate(campaign.endDate)}`
                  }
                  {
                    !(campaign.startDate || campaign.endDate) && 'Draft'
                  }
                </UserRoleNavLink>
              </div>
            ))
          )
        }

        <p className="mt-3 mb-0">
          Please, confirm you want to delete it.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ onConfirm }>
          Delete asset
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  onConfirm: func.isRequired,
  onHide: func.isRequired,
  usedInCampaigns: arrayOf(campaignType)
}

ConfirmationModal.defaultProps = {
  usedInCampaigns: null
}

export default ConfirmationModal

import { useEffect, useState } from 'react'
import moment from 'moment'

import usePopover from 'shared/components/ui/Popover/usePopover'

const formatDate = (selected, showTimeInput, showTimeSelectOnly) => {
  if (!selected) {
    return ''
  }

  const displayFormat = showTimeSelectOnly ? (
    'HH:mm'
  ) : (
    showTimeInput ? 'DD/MM/YY - HH:mm' : 'DD/MM/YY'
  )

  return moment(selected).format(displayFormat)
}

export default function useDatepicker(
  selected, onChange, showReset, showTimeInput, showTimeSelectOnly
) {
  const [date, setDate] = useState(selected)
  const { isOpened, open, close, container, target } = usePopover()
  const selectedDateFormatted = formatDate(selected, showTimeInput, showTimeSelectOnly)

  const saveDate = () => {
    setDate(date)
    onChange(date)
    close()
  }

  const resetDate = !showReset ? null : () => {
    setDate(null)
    onChange(null)
    close()
  }

  useEffect(() => {
    setDate(selected)
  }, [selected && selected.toString()])

  return {
    date,
    selectedDateFormatted,
    isOpened,
    open,
    container,
    target,
    setDate,
    saveDate,
    resetDate
  }
}

import React from 'react'
import { shape, string } from 'prop-types'

import LandingPageContentComponent from 'shared/components/LandingPageContent'

const LandingPageContent = ({ match }) => {
  const { params: { campaignUuid, uuid } } = match

  return (
    <LandingPageContentComponent
      uuid={ uuid }
      campaignUuid={ campaignUuid }
    />
  )
}

LandingPageContent.propTypes = {
  match: shape({
    params: shape({
      campaignUuid: string.isRequired,
      uuid: string.isRequired
    }).isRequired
  }).isRequired
}

export default LandingPageContent

import React from 'react'
import { t } from 'ttag'
import { number, shape, string } from 'prop-types'

import { IconMail, IconPhone } from 'shared/components/ui/icons'
import SummaryFooter from 'shared/components/ui/SummaryCard/Footer'
import Section       from 'shared/components/ui/SummaryCard/Footer/Section'
import Body          from 'shared/components/ui/SummaryCard/Footer/Body'

const Footer = ({ company }) => {
  const {
    email,
    phoneNumber,
    childrenCount,
    topParent,
    location
  } = company
  const address = location?.address
  const topParentName = topParent?.name

  const mailLink = (
    email && <a href={ `mailto:${email}` }>{email}</a>
  )

  const phoneLink = (
    phoneNumber && <a href={ `tel:${phoneNumber}` }>{phoneNumber}</a>
  )

  return (
    <SummaryFooter>
      <Section title={ t`Top parent` }>
        <Body title={ topParentName } />
      </Section>
      <Section title={ t`Branches` }>
        <Body title={ childrenCount } />
      </Section>
      <Section title={ t`Mail` }>
        <Body icon={ <IconMail /> } title={ mailLink } />
      </Section>
      <Section title={ t`Phone` }>
        <Body icon={ <IconPhone /> } title={ phoneLink } />
      </Section>
      <Section title={ t`Address` }>
        <Body title={ address }/>
      </Section>
    </SummaryFooter>
  )
}

Footer.propTypes = {
  company: shape({
    email: string,
    phoneNumber: string,
    childrenCount: number,
    topParent: shape({
      name: string
    }),
    location: shape({
      address: string
    })
  }).isRequired
}

export default Footer

import React from 'react'
import { string, node } from 'prop-types'
import styled from 'styled-components'

import campaignType from 'shared/types/campaignType'
import { unsetCardPadding } from 'shared/style/placeholders'
import { textMainInfoHeaderColor } from 'shared/style/colors'

const Image = ({ children, className, campaign }) => {
  return (
    <div
      className={ className }
      style={{ backgroundColor: campaign.backgroundColor }}
    >
      { children }
    </div>
  )
}

Image.propTypes = {
  campaign: campaignType.isRequired,
  children: node,
  src: string, // eslint-disable-line react/no-unused-prop-types
}

Image.defaultProps = {
  children: null,
  src: undefined
}

export default styled(Image)`
  ${unsetCardPadding}

  background-image: linear-gradient(to right, rgba(255,255,255,.75), rgba(255,255,255,.75)),
                    url(${({ src }) => src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  min-height: 8rem;

  .image-header {
    align-self: center;
    color: ${textMainInfoHeaderColor};
    display: table-cell;
    justify-self: center;
    font-size: 2.625rem;
    font-weight: 600;
    margin: 0;
    width: 100%;
    text-align: center;
  }
`

import React from 'react'
import { t } from 'ttag'
import { object } from 'prop-types'
import { toast } from 'react-toastify'
import { useMutation } from 'react-apollo'

import requestSpendingMutation
  from 'graphql/mutations/campaign/specifications/requestSpending'

import UpdateSectionButton from '../UpdateSectionButton'

const UpdateSpendingButton = ({ campaign }) => {
  const [requestSpending, { loading }] = useMutation(
    requestSpendingMutation,
    { variables: { campaignUuid: campaign.uuid } }
  )

  const requestSpendingWithNotification = () => {
    requestSpending().then(({ data: { requestSpending } }) => {
      if (requestSpending.errors.length) {
        toast.error(
          <>
            { requestSpending.errors }
          </>
        )
      }
    })
  }

  return (
    <UpdateSectionButton
      campaign={ campaign }
      loading={ loading }
      caption={ t`Request spending based on goals` }
      onClick={ requestSpendingWithNotification }
    />
  )
}

UpdateSpendingButton.propTypes = {
  campaign: object.isRequired
}

export default UpdateSpendingButton

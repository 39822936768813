import React  from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import agentType from 'shared/types/agentType'
import { IconUser } from 'shared/components/ui/icons'

const Image = ({ className, agent, mediaType, label }) => {
  const providedMediaType = mediaType
  const imageMedia = agent.assetAssignments?.find(({ mediaType }) => {
    return mediaType === providedMediaType
  })

  const image = imageMedia
    ? <img src={ imageMedia.asset.thumbnail } alt={ label } />
    : <IconUser />

  return (
    <span className={ className }>
      { image }
    </span>
  )
}

Image.propTypes = {
  agent: agentType.isRequired,
  label: string.isRequired,
  mediaType: string.isRequired
}

export default styled(Image)`
  margin-right: .5rem
  img {
    border-radius: 0.6rem
    height: 6rem
  }
`

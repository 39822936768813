import React from 'react'

import Card from 'shared/components/ui/Card'

import LoadingBlock from './LoadingBlock'

export default function LoadingCard() {
  return (
    <Card className="mb-2">
      <LoadingBlock />
    </Card>
  )
}

import { useQuery } from '@apollo/react-hooks'

import companiesQuery from 'graphql/queries/companies'

const useCompanies = () => {
  const { data } = useQuery(companiesQuery)

  const companies = data?.companies || []

  return {
    companies
  }
}

export default useCompanies

import React, { useState } from 'react'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { t } from 'ttag'
import { toast } from 'react-toastify'

import updateLeadMutation from 'graphql/mutations/campaign/updateLead'
import deleteLeadMutation from 'graphql/mutations/campaign/deleteLead'
import leadType from 'shared/types/leadType'
import leadFragment from 'graphql/fragments/lead'
import LeadFormModal from 'shared/components/LeadForm/Modal'
import { buildFragmentParams } from 'shared/helpers/graphql'
import redirectTo from 'shared/helpers/redirectTo'
import LoadingCard from 'shared/components/ui/LoadingCard'

import Header from './Header'
import Details from './Details'

const Lead = ({ lead }) => {
  const onDelete = () => {
    if (confirm(t`Are you sure?`)) {
      deleteLead().then(() => {
        toast.success(t`Lead successfully deleted`)
        redirectTo('/leads')
      })
    }
  }

  const [deleteLead, { loading }] = useMutation(
    deleteLeadMutation,
    { variables: { uuid: lead.uuid } }
  )

  const client = useApolloClient()
  const [isEditing, setIsEditing] = useState(false)

  const handleChange = input => client.mutate({
    mutation: updateLeadMutation,
    variables: { uuid: lead.uuid, input },
    update: (cache, data) => {
      const updatedLead = data.data.updateLead.lead

      const fragmentParams = buildFragmentParams(
        { __typename: 'Lead', uuid: lead.uuid },
        leadFragment
      )

      cache.writeFragment({
        ...fragmentParams,
        data: updatedLead
      })

      toast.success(t`Lead has been updated`)
    }
  })

  if (loading) {
    return (
      <LoadingCard />
    )
  }

  return (
    <div>
      <Header lead={ lead } onDelete={ onDelete } onStartEdit={ () => setIsEditing(true) } />
      <Details lead={ lead } onChange={ handleChange } />

      <LeadFormModal
        title={ t`Update lead details` }
        lead={ lead }
        show={ isEditing }
        onHide={ () => setIsEditing(false) }
        onSubmit={ handleChange }
      />
    </div>
  )
}

Lead.propTypes = {
  lead: leadType.isRequired
}

export default Lead

import React from 'react'
import { useDrop } from 'react-dnd'
import { func, object } from 'prop-types'
import { t } from 'ttag'
import { useApolloClient } from '@apollo/react-hooks'
import { get } from 'lodash'

import mimeTypes from 'shared/constants/mimeTypes'
import landingPageType from 'shared/types/landingPageType'
import EmptyAsset from 'shared/components/Media/AssetAssignments/EmptyAsset'
import SelectedAsset from 'shared/components/Media/AssetAssignments/SelectedAsset'
import UploadButton from 'shared/components/Media/UploadButton'
import { mediaTypeDropEvents } from 'shared/components/Media/mediaTypes'

import uploadLogoToCampaign from '../uploadLogoToCampaign'
import {
  SectionHeader,
  HeaderContainer,
  LogoContainer,
  HeaderImageWrapper,
  LogoWrapper
} from '../elements'

const HeaderImageAndLogo = ({ landingPage, campaign, onUpdate }) => {
  const client = useApolloClient()

  const handleHeaderImageDrop = ({ asset: { uuid } }) =>
    onUpdate({ headerImageUuid: uuid })

  const handleLogoDrop = ({ asset: { uuid } }) =>
    onUpdate({ logoUuid: uuid })

  const handleHeaderImageRemove = async () => onUpdate({ headerImageUuid: null })
  const handleLogoRemove = async () => onUpdate({ logoUuid: null })

  const handleUploadLogo = async (...args) => {
    const { data } = await uploadLogoToCampaign(...args)
    const assetUuid = get(data, 'attachFileToAssetable.assetAssignment.asset.uuid')

    onUpdate({ logoUuid: assetUuid })
  }

  const [_headerProps, dropHeaderImage] = useDrop({
    accept: mediaTypeDropEvents.image,
    drop: handleHeaderImageDrop
  })

  const [_logoProps, dropLogo] = useDrop({
    accept: [mediaTypeDropEvents.image, mediaTypeDropEvents.logo],
    drop: handleLogoDrop
  })

  const headerImage = landingPage.headerImage || {}
  const logo = landingPage.logo || {}

  return (
    <HeaderContainer>
      <div>
        <SectionHeader>{ t`Header image` }</SectionHeader>
        <HeaderImageWrapper ref={ dropHeaderImage }>
          {headerImage.asset ? (
            <SelectedAsset
              allowedSize
              assetAssignment={ headerImage }
              onAssetAssignmentRemove={ handleHeaderImageRemove }
              assetableType={ headerImage.__typename }
              key={ headerImage.uuid }
              displayEnabled={ false }
            />
          ) : (
            <EmptyAsset mediaType="image" />
          )}
        </HeaderImageWrapper>
      </div>

      <LogoContainer data-test-id="logo-container">
        <SectionHeader>{ t`Logo` }</SectionHeader>
        <LogoWrapper ref={ dropLogo }>
          {logo.asset ? (
            <SelectedAsset
              allowedSize
              assetAssignment={ logo }
              onAssetAssignmentRemove={ handleLogoRemove }
              assetableType={ logo.__typename }
              key={ logo.uuid }
              displayEnabled={ false }
            />
          ) : (
            <EmptyAsset mediaType="image" />
          )}
        </LogoWrapper>

        <UploadButton
          client={ client }
          assetable={ campaign }
          attachFileToAssetable={ handleUploadLogo }
          mimeTypes={ mimeTypes.logo }
        />
      </LogoContainer>
    </HeaderContainer>
  )
}

HeaderImageAndLogo.propTypes = {
  campaign: object.isRequired,
  landingPage: landingPageType.isRequired,
  onUpdate: func.isRequired
}
export default HeaderImageAndLogo

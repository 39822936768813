import React, { useCallback } from 'react'
import { func, string } from 'prop-types'
import { t } from 'ttag'

import FormSelect from './FormSelect'

const options = [
  { value: 'leads_high', label: t`Leads High` },
  { value: 'leads_low', label: t`Leads Low` },
  { value: 'impressions', label: t`Impressions` },
  { value: 'visits', label: t`Visits` },
  // Disabled for MVP
  // https://github.com/marketertechnologies/marketer/issues/1448
  // { value: 'video_playthroughs', label: t`Video Playthroughs` }
]

const GoalSelect = ({ onChange, goalType, targetLqs, ...props }) => {
  const handleChange = useCallback(({ value }) => {
    if (value === 'leads_high') {
      onChange({ target: { name: 'targetLqs', value: 'high' } })
      onChange({ target: { name: 'goalType', value: 'leads' } })
    } else if (value === 'leads_low') {
      onChange({ target: { name: 'targetLqs', value: 'low' } })
      onChange({ target: { name: 'goalType', value: 'leads' } })
    } else {
      onChange({ target: { name: 'goalType', value } })
    }
  }, [])

  const defaultValue = options.find(({ value }) => (
    value === (goalType === 'leads' ? `${goalType}_${targetLqs}` : goalType)
  ))

  return (
    <FormSelect
      { ...props }
      defaultValue={ defaultValue }
      onChange={ handleChange }
      options={ options }
    />
  )
}

GoalSelect.propTypes = {
  goalType: string.isRequired,
  onChange: func.isRequired,
  targetLqs: string.isRequired
}

export default GoalSelect

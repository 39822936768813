import { toast }            from 'react-toastify'

import validateFileSize     from './validateFileSize'
import validateFileType     from './validateFileType'
import getFileMetadata      from './getFileMetadata'
import createDirectUpload   from './createDirectUpload'
import runDirectUpload      from './runDirectUpload'
import checkVideoLength     from './checkVideoLength'

const completeFileUpload = (
  { client, file, allowedMimeTypes, mediaType, fileMeta, validateVideoSize }) => {
  return validateFileSize(file)
    .then((file) => validateFileType(file, allowedMimeTypes))
    .then((file) => checkVideoLength(file, mediaType, validateVideoSize))
    .then((file) => getFileMetadata({ file, fileMeta }))
    .then((metaData) => createDirectUpload({ client, input: metaData }))
    .then(({ data }) => runDirectUpload({ data, file }))
    .catch((error) => {
      console.log(error) // eslint-disable-line no-console
      toast.error(error)
    })
}

export default completeFileUpload

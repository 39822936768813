import * as Yup from 'yup'
import { t }    from 'ttag'

export default Yup.object().shape({
  name: Yup.string()
    .required(t`Stage name is required`),

  address: Yup.string()
    .required(t`Address is required`),

  countryCode: Yup.string()
    .required(t`Country is required`),

  location: Yup.object().test(
    'coordinates',
    t`Please, drop a pin`,
    ({ latitude, longitude }) => latitude && longitude
  ),

  buildingTypes: Yup.array().
    min(1, t`Please add at least 1 unit type.`).
    required(t`Please add at least 1 unit type.`).
    nullable(),

  unitsCount: Yup.number().
    required(t`Please fill units count.`).
    positive(t`Units count must be greater than zero.`).
    integer(t`Units count must be integer.`),

  lowerPrice: Yup.number().
    required(t`Please fill in price from.`).
    nullable().
    positive(t`Price from must be greater than zero.`),

  upperPrice: Yup.number().
    required(t`Please fill in price to.`).
    nullable().
    min(Yup.ref('lowerPrice'), t`Price to can not be less than Price from.`),

  lowerSizeM2: Yup.number().
    required(t`Please fill in size from.`).
    nullable().
    positive(t`Size from must be greater than zero.`),

  upperSizeM2: Yup.number().
    required(t`Please fill in size to.`).
    nullable().
    min(Yup.ref('lowerSizeM2'), t`Size to can not be less than Size from.`)
})

import gql from 'graphql-tag'

export default gql`
  fragment VitecDocument on VitecDocument {
    uuid
    docType
    head
    extension
    lastChanged
    signStatus
    approvalDate
    approvedBy
    url
  }
`

import React  from 'react'
import styled from 'styled-components'
import { string, bool, node } from 'prop-types'

import { IconEmptyImage } from 'shared/components/ui/icons'
import { borderGreyColor } from 'shared/style/colors'

const EmptyAsset = ({ className, isOver, title, ...props }) => {
  return (
    <div
      className={ `${className} ${isOver ? 'is-over' : ''}` }
      { ...props }
    >
      <div className="inner">
        <IconEmptyImage />

        <span>{ title }</span>
      </div>
    </div>
  )
}

EmptyAsset.propTypes = {
  className: string.isRequired,
  isOver: bool.isRequired,
  title: node,
}

EmptyAsset.defaultProps = {
  title: ''
}

export default styled(EmptyAsset)`
  border: 2px solid ${borderGreyColor};
  color: ${borderGreyColor};
  cursor: pointer;
  border-radius: .5rem;
  font-size: .65rem
  padding-top: 75%
  position: relative
  text-align: center
  height: 7rem

  &.is-over {
    border-style: dashed
  }

  .inner {
    flex-direction: column
    justify-content: center
    display: flex
    align-items: center
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%

    > span {
      margin: 0 0.4rem
    }

    [class^=icon] {
      font-size: 2rem
      margin-bottom: .5rem
    }
  }
`

import React, { useState } from 'react'
import styled              from 'styled-components'
import { t }               from 'ttag'
import useReactRouter      from 'use-react-router'
import Select              from 'react-select'
import { useQuery, useApolloClient } from 'react-apollo'
import { object } from 'prop-types'

import credentials                    from 'config/credentials'
import { userRolePath }               from 'routes/AppRedirect'
import projectAssetAssignments        from 'graphql/queries/projectAssetAssignments'
import publishProjectToPortalMutation from 'graphql/mutations/projects/publishToPortal'
import Card                           from 'shared/components/ui/Card'
import Button                         from 'shared/components/ui/Button'
import ThriftyInput                   from 'shared/components/ui/ThriftyInput'
import Switch                         from 'shared/components/ui/Switch'
import { buttonTextColor }            from 'shared/style/colors'

import ProjectImage          from './ProjectImage'
import SelectAssets          from './SelectAssets'
import SelectStageAssets     from './SelectStageAssets'
import Agents                from './Agents'
import VirtualStage          from './VirtualStage'
import ProjectStateSelect    from './ProjectStateSelect'
import updateProjectMutation from './updateProjectMutation'
import updateStageMutation   from './updateStageMutation'

const EditForm = ({ className, project }) => {
  const projectSelectValue = { type: 'Project', value: project.uuid, label: project.name }
  const [selectedPromotable, setSelectedPromotable] = useState(projectSelectValue)
  const [selectedStage, setSelectedStage] = useState(null)

  const client = useApolloClient()
  const { history } = useReactRouter()

  const { data, loading } = useQuery(
    projectAssetAssignments,
    { variables: { uuid: project.uuid } }
  )

  if (loading) return null

  const handlePreview = () => {
    window.open(`${credentials.portalHost}/listings/${project.uuid}/preview`, '_blank')
  }

  const handlePublish = () => {
    if (window.confirm(t`Are you sure you want to publish?`)) {
      client.mutate({
        mutation: publishProjectToPortalMutation,
        variables: { uuid: project.uuid }
      })
      history.push(userRolePath(`/projects/${project.uuid}`))
    }
  }

  const handleSave = input => {
    client.mutate({
      mutation: updateProjectMutation,
      variables: { uuid: project.uuid, input }
    })
  }

  const handleStageSave = input => {
    client.mutate({
      mutation: updateStageMutation,
      variables: { stageUuid: selectedStage.uuid, input }
    })
    const stage = selectedStage
    Object.keys(input).forEach((key) => {
      stage[key] = input[key]
    })
    setSelectedStage(stage)
  }

  const handleReset = () => {
    handleSave({ customDescriptionDraft: project.sourceDescription })
  }

  const handleStageReset = () => {
    handleStageSave({ customDescriptionDraft: selectedStage?.sourceDescription })
  }

  const descriptionsHaveDiff = project.customDescriptionDraft !== project.sourceDescription
  const resetNeeded = !project.autoDescriptionEnabled && descriptionsHaveDiff

  const stageDescsDiff = selectedStage?.customDescriptionDraft !== selectedStage?.sourceDescription
  const resetStageNeeded = !selectedStage?.autoDescriptionEnabled && stageDescsDiff

  const projectWithAssetAssignments = Object.assign(project, {
    assetAssignments: data.project.assetAssignments
  })

  const selectProjectStagesOptions = () => {
    const stages = project.completeStages.map((element) => {
      return { type: 'Stage', value: element.uuid, label: element.name }
    })
    return [
      projectSelectValue,
      ...stages
    ]
  }

  const setPromotable = (value) => {
    if (value.type === 'Project') setSelectedStage(null)
    else {
      const stage = project.completeStages.find((element) => {
        return element.uuid === value.value
      })
      setSelectedStage(stage)
    }
    setSelectedPromotable(value)
  }

  return (
    <Card
      className={ `project-portal-edit-form ${className}` }
      header={ t`Edit Portal details` }
    >
      <Group>
        <label>{ t`Listing type` }</label>
        <ProjectStateSelect
          name="saleStateDraft"
          onChange={ (value) => handleSave(value) }
          value={ project.saleStateDraft }
        />
      </Group>

      <Group>
        <label>{ t`Show on Portal` }</label>
        <StyledSwitch
          name="portalEnabledDraft"
          onChange={
            () => handleSave({ portalEnabledDraft: !project.portalEnabledDraft })
          }
          checked={ project.portalEnabledDraft }
          className="toggle-switch"
        />
      </Group>

      <Group>
        <label>{ t`Show as multistage` }</label>
        <StyledSwitch
          name="multistage"
          onChange={
            () => handleSave({ multistage: !project.multistage })
          }
          checked={ project.multistage }
          className="toggle-switch"
        />
      </Group>

      <div className='stage-select'>
        <Select
          value={ selectedPromotable }
          onChange={ (value) => setPromotable(value) }
          options={ selectProjectStagesOptions() }
        />
      </div>

      {selectedPromotable.type === 'Project' && project.portalEnabledDraft && (
        <>
          <Group>
            <label>{ t`Auto` }</label>
            <StyledSwitch
              name="autoDescriptionEnabled"
              onChange={
                () => handleSave({ autoDescriptionEnabled: !project.autoDescriptionEnabled })
              }
              checked={ project.autoDescriptionEnabled }
              className="toggle-switch"
            />
          </Group>

          <Group>
            <label>{ t`Project Description` }</label>
            {resetNeeded && (
              <Button type="button" onClick={ handleReset }>
                { t`Reset` }
              </Button>
            )}
            {project.autoDescriptionEnabled ? (
              <ThriftyInput
                disabled
                as="textarea"
                name="sourceDescription"
                rows={ 6 }
                value={ project.sourceDescription }
              />
            ) : (
              <ThriftyInput
                name="customDescriptionDraft"
                as="textarea"
                rows={ 6 }
                disabled={ project.autoDescriptionEnabled }
                value={ project.customDescriptionDraft }
                onUpdate={
                  customDescriptionDraft => handleSave({
                    customDescriptionDraft: customDescriptionDraft
                  })
                }
              />
            )}
          </Group>

          <Group>
            <label>{ t`Project title` }</label>
            <ThriftyInput
              name="titleDraft"
              value={ project.titleDraft }
              onUpdate={
                titleDraft => handleSave({ titleDraft: titleDraft })
              }
            />
          </Group>

          <Group>
            <label>{ t`Project headline` }</label>
            <ThriftyInput
              name="headlineDraft"
              value={ project.headlineDraft }
              onUpdate={
                headlineDraft => handleSave({ headlineDraft: headlineDraft })
              }
            />
          </Group>

          <Group>
            <label>{ t`Project flat finder URL` }</label>
            <ThriftyInput
              name="flatFinderUrl"
              value={ project.flatFinderUrl }
              onUpdate={
                flatFinderUrl => handleSave({ flatFinderUrl: flatFinderUrl })
              }
            />
          </Group>

          <Group>
            <label>{ t`Project website` }</label>
            <ThriftyInput
              name="linkDraft"
              value={ project.linkDraft }
              onUpdate={
                linkDraft => handleSave({ linkDraft: linkDraft })
              }
            />
          </Group>

          {project.saleStateDraft === 'coming_for_sale' && (
            <VirtualStage project={ project } />
          )}

          <Group>
            <label>{ t`Project logo` }</label>
            <ProjectImage project={ project } label={ t`Project logo` } mediaType='project_logo' />
          </Group>

          <Group>
            <label>{ t`Developer logo` }</label>
            <ProjectImage
              project={ project }
              label={ t`Developer logo` }
              mediaType='developer_logo'
            />
          </Group>

          <Agents project={ project } />

          <SelectAssets project={ projectWithAssetAssignments } />
        </>
      )}

      {selectedStage && project.portalEnabledDraft && (
        <>
          <Group>
            <label>{ t`Auto` }</label>
            <StyledSwitch
              name="autoDescriptionEnabled"
              onChange={
                () => (
                  handleStageSave(
                    { autoDescriptionEnabled: !selectedStage.autoDescriptionEnabled }
                  )
                )
              }
              checked={ selectedStage.autoDescriptionEnabled }
              className="toggle-switch"
            />
          </Group>

          <Group>
            <label>{ t`Stage Description` }</label>
            {resetStageNeeded && (
              <Button type="button" onClick={ handleStageReset }>
                { t`Reset` }
              </Button>
            )}
            {selectedStage.autoDescriptionEnabled ? (
              <ThriftyInput
                disabled
                as="textarea"
                name="sourceDescription"
                rows={ 6 }
                value={ selectedStage.sourceDescription }
              />
            ) : (
              <ThriftyInput
                name="customDescriptionDraft"
                as="textarea"
                rows={ 6 }
                disabled={ selectedStage.autoDescriptionEnabled }
                value={ selectedStage.customDescriptionDraft }
                onUpdate={
                  customDescriptionDraft => handleStageSave({
                    customDescriptionDraft: customDescriptionDraft
                  })
                }
              />
            )}
          </Group>

          <Group>
            <label>{ t`Stage title` }</label>
            <ThriftyInput
              name="titleDraft"
              value={ selectedStage.titleDraft }
              onUpdate={
                titleDraft => handleStageSave({ titleDraft: titleDraft })
              }
            />
          </Group>

          <Group>
            <label>{ t`Stage headline` }</label>
            <ThriftyInput
              name="headlineDraft"
              value={ selectedStage.headlineDraft }
              onUpdate={
                headlineDraft => handleStageSave({ headlineDraft: headlineDraft })
              }
            />
          </Group>

          <Group>
            <label>{ t`Stage flat finder URL` }</label>
            <ThriftyInput
              name="flatFinderUrl"
              value={ selectedStage.flatFinderUrl }
              onUpdate={
                flatFinderUrl => handleStageSave({ flatFinderUrl: flatFinderUrl })
              }
            />
          </Group>

          <Group>
            <label>{ t`Stage website` }</label>
            <ThriftyInput
              name="linkDraft"
              value={ selectedStage.linkDraft }
              onUpdate={
                linkDraft => handleStageSave({ linkDraft: linkDraft })
              }
            />
          </Group>

          <SelectStageAssets stage={ selectedStage } />
        </>
      )}

      <Button type="button" onClick={ handlePreview }>
        { t`Preview` }
      </Button>
      <Button variant="primary" type="button" onClick={ handlePublish }>
        { t`Publish` }
      </Button>
    </Card>
  )
}

const Group = styled.div`
  margin-bottom: 1rem;

  input {
    box-shadow: none;
  }

  label {
    color: ${buttonTextColor};
    font-size: 0.875rem;
    margin-right: .5rem;
  }
`

const StyledSwitch = styled(Switch)`
  width: 2.5rem;
  flex: auto;
  margin-left: .5rem;

  .state label:before, .state label:after {
    width: calc(1em + 5px);
    height: calc(1em + 5px);
    top: calc(0% - (100% - 1em)) !important;
  }
`

EditForm.propTypes = {
  project: object.isRequired
}

export default styled(EditForm)`
  .stage-select {
    width: 20rem;
    margin-bottom: 1rem;
  }

  .virtual-stage {
    display: flex;
  }
`

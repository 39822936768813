import React from 'react'
import { func } from 'prop-types'
import { t } from 'ttag'
import Select from 'react-select'

const TouchableSelect = ({ setTouched, ...props }) => {
  const handleBlur = () => setTouched(true)

  return (
    <Select
      onBlur={ handleBlur }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

TouchableSelect.propTypes = {
  setTouched: func
}

TouchableSelect.defaultProps = {
  setTouched: undefined
}

export default TouchableSelect

import React from 'react'
import AsyncSelect from 'react-select/async'
import { useApolloClient } from 'react-apollo'
import { t } from 'ttag'
import { arrayOf, shape, string } from 'prop-types'

import MultiSelect from 'shared/components/ui/MultiSelect/MultiSelect'
import query from 'graphql/queries/facebookLanguageTargeting'

import Option from './Option'

const styles = {
  container: provided => ({
    ...provided,
    width: '100%'
  }),
  control: provided => ({
    ...provided,
    fontSize: '0.8rem'
  }),
  option: provided => ({
    ...provided,
    fontSize: '0.8rem'
  })
}

const valueToOption = el => ({
  ...el,
  label: el.name,
  value: el.id
})

const buildOptions = data => {
  return data.map(valueToOption)
}

const LanguageTargetingSelect = ({ value: selectedValues, ...props }) => {
  const client = useApolloClient()

  const value = (selectedValues || []).map(valueToOption)

  const loadOptions = async term => {
    const { data: { facebookLanguageTargeting } } = await client.query({
      query: query,
      variables: { query: term }
    })
    return buildOptions(facebookLanguageTargeting)
  }

  return (
    <MultiSelect
      component={ AsyncSelect }
      loadOptions={ loadOptions }
      value={ value }
      styles={ styles }
      components={{ Option }}
      placeholder={ t`Add language targeting` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

LanguageTargetingSelect.propTypes = {
  value: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired
    })
  )
}

LanguageTargetingSelect.defaultProps = {
  value: []
}

export default LanguageTargetingSelect

import { useState } from 'react'
import { t } from 'ttag'

import mimeTypes from 'shared/constants/mimeTypes'
import toastError from 'shared/toastError'

const areFilesAllowed = (files, mediaType) => {
  return [...files].every((file) => {
    return mimeTypes[mediaType].includes(file.type)
  })
}

const useImageDrop = ({ uploadFiles, mediaType, validateVideoSize }) => {
  const [isOver, setIsOver] = useState(false)

  const handleDragOver = (e) => {
    e.preventDefault()

    setIsOver(true)
  }

  const handleDrop  = (e) => {
    e.preventDefault()

    if (areFilesAllowed(e.dataTransfer.files, mediaType)) {
      uploadFiles([...e.dataTransfer.files], validateVideoSize)
    } else {
      toastError(t`Wrong file type`)
    }

    setIsOver(false)
  }

  const handleDragLeave = () => {
    setIsOver(false)
  }

  return {
    handleDragOver,
    handleDrop,
    handleDragLeave,
    isOver
  }
}

export default useImageDrop

import React from 'react'
import { Formik, Field } from 'formik'
import { t } from 'ttag'
import { shape, string, func } from 'prop-types'
import { get } from 'lodash'

import IconLeadContact from 'shared/assets/icons/svg/new/leadContact.svg'
import { IconPhone } from 'shared/components/ui/icons'
import IconLeadShowing from 'shared/assets/icons/svg/new/leadShowing.svg'
import IconLeadBid from 'shared/assets/icons/svg/new/leadBid.svg'
import UserSelect from 'shared/components/UserSelect'
import placeholderMapping from 'shared/constants/leadActivityType'
import Datepicker from 'shared/components/ui/Datepickers/Datepicker'

import validationSchema from './validationSchema'
import {
  SaveButton,
  ActivityDetails,
  ActivityNotes,
  VerticalSeparator,
  ActivityTypeLabel,
  Input
} from './elements'

const NewActivityForm = ({ lead, onSubmit: handleSubmit }) => {
  const initialValues = {
    title: '',
    recordedAt: new Date(),
    activityType: 'phone_call',
    text: '',
    involvedUserUuid: ''
  }

  return (
    <Formik
      initialValues={ initialValues }
      validationSchema={ validationSchema }
      onSubmit={ handleSubmit }
    >
      {({ values, touched, errors, handleSubmit, setFieldValue }) => (
        <form onSubmit={ handleSubmit } data-test-id='lead-activity-form'>
          <div className="d-flex">
            <ActivityDetails>
              <Field
                name="title"
                as={ Input }
                placeholder={ placeholderMapping[values.activityType] }
              />

              <div className="d-flex" data-test-id='lead-activity-types'>
                <ActivityTypeLabel>
                  <Field type="radio" name="activityType" value="phone_call" />
                  <div><IconPhone /></div>
                </ActivityTypeLabel>

                <ActivityTypeLabel>
                  <Field type="radio" name="activityType" value="lead_contacted" />
                  <div><IconLeadContact /></div>
                </ActivityTypeLabel>

                <ActivityTypeLabel>
                  <Field type="radio" name="activityType" value="showing" />
                  <div><IconLeadShowing /></div>
                </ActivityTypeLabel>

                <ActivityTypeLabel>
                  <Field type="radio" name="activityType" value="bid_placed" />
                  <div><IconLeadBid /></div>
                </ActivityTypeLabel>
              </div>

              <Field
                showTimeInput
                name="recordedAt"
                as={ Datepicker }
                isInvalid={ touched.recordedAt && errors.recordedAt }
                selected={ values.recordedAt }
                onChange={ value => setFieldValue('recordedAt', value) }
              />
            </ActivityDetails>

            <VerticalSeparator />

            <ActivityNotes>
              <Field name="text" as="textarea" />

              <div className="d-flex justify-content-between">
                <Field
                  name="involvedUserUuid"
                  as={ UserSelect }
                  companyUuid={ get(lead, 'project.product.company.uuid') }
                  value={ values.involvedUserUuid }
                  onChange={ ({ value }) => setFieldValue('involvedUserUuid', value) }
                />

                <SaveButton type="submit">{ t`Save` }</SaveButton>
              </div>
            </ActivityNotes>
          </div>
        </form>
      )}
    </Formik>
  )
}

NewActivityForm.propTypes = {
  lead: shape({
    project: shape({
      product: shape({
        company: shape({
          uuid: string.isRequired
        }).isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  onSubmit: func.isRequired
}

export default NewActivityForm

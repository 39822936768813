import React, { useEffect } from 'react'
import styled from 'styled-components'
import { object } from 'prop-types'
import { t } from 'ttag'

import Table from 'shared/components/ui/Table'
import { localDateTime } from 'shared/helpers/date'
import Card from 'shared/components/ui/Card'

import Heading from './Heading'
import Error from './Error'

const Details = ({ publication, className }) => {
  const detailsCardParentRef = React.createRef()

  useEffect(() => {
    detailsCardParentRef.current.firstChild.style.opacity = 1
  }, [detailsCardParentRef])

  const columns = [
    {
      dataField: 'scheduledAt',
      text: t`Scheduled at`,
      formatter: (cell, _row) => localDateTime(cell),
    },
    {
      dataField: 'startedAt',
      text: t`Started at`,
      formatter: (cell, _row) => localDateTime(cell),
    },
    {
      dataField: 'finishedAt',
      text: t`Finished at`,
      formatter: (cell, _row) => localDateTime(cell),
    },
    {
      dataField: 'failedAt',
      text: t`Failed at`,
      formatter: (cell, _row) => localDateTime(cell),
    }]

  const tableOptions = {
    columns,
    data: [publication],
    keyField: 'uuid',
    bordered: false
  }

  const hasFailed = publication.status === 'failed'

  return (
    <div className={ className } ref={ detailsCardParentRef }>
      <Card
        collapsible
        collapsed
        style={{ opacity: 0 }}
        header={ <Heading publication={ publication } /> }
      >
        <div className="publication-details">
          <Table tableOptions={ tableOptions } />
          { hasFailed && publication.error && (<Error error={ publication.error }/>) }
        </div>
      </Card>
    </div>
  )
}

Details.propTypes = {
  publication: object.isRequired
}

export default styled(Details)`
  .card {
    transition: opacity 0.2s ease-in-out;
    box-shadow: none;
    border-radius: none;
    margin-top: 10px;
  }

  .card-header {
    border-bottom: 0;
    padding: 0.5rem 0.75rem;
  }
`

import React from 'react'
import { get } from 'lodash'
import { func } from 'prop-types'

import leadType from 'shared/types/leadType'
import UserSelect from 'shared/components/UserSelect'

import LeadQualityScore from './LeadQualityScore'
import LeadStatus from './LeadStatus'
import LeadProgress from './LeadProgress'
import ContactMeans from './ContactMeans'
import ContactTime from './ContactTime'
import Notes from './Notes'
import Activity from './Activity'
import {
  Container,
  UserSelectContainer
} from './elements'

const Details = ({ lead, onChange }) => {
  const handleUserChange = ({ value: userUuid }) => onChange({ userUuid })
  const handleQualityScoreChange = ({ value: qualityScore }) => onChange({ qualityScore })
  const handleStatusChange = ({ value: status }) => onChange({ status })
  const handleProgressChange = progress => onChange({ progress })

  return (
    <Container>
      <div className="d-flex justify-content-between" data-test-id='lead-quality-wrapper'>
        <UserSelectContainer>
          <UserSelect
            companyUuid={ get(lead, 'project.product.company.uuid') }
            value={ lead?.user?.uuid }
            onChange={ handleUserChange }
          />
        </UserSelectContainer>
        <div className="d-flex">
          <LeadQualityScore value={ lead.qualityScore } onChange={ handleQualityScoreChange } />
          <LeadStatus value={ lead.status } onChange={ handleStatusChange } />
        </div>
      </div>

      {
        lead.contactInfo && <ContactMeans contactInfo={ lead.contactInfo }/>
      }

      {
        lead.contactInfo && <ContactTime contactInfo={ lead.contactInfo } />
      }

      <LeadProgress value={ lead.progress } onChange={ handleProgressChange } />

      <Notes lead={ lead } />

      <Activity lead={ lead } />
    </Container>
  )
}

Details.propTypes = {
  lead: leadType.isRequired,
  onChange: func.isRequired
}

export default Details

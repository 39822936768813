import React, { useState } from 'react'
import { withApollo }      from 'react-apollo'
import { t }               from 'ttag'
import styled              from 'styled-components'

import Button           from 'shared/components/ui/Button'
import apolloClientType from 'shared/types/apolloClientType'
import channelType      from 'shared/types/channelType'
import campaignType     from 'shared/types/campaignType'
import { isUserAdmin }  from 'shared/helpers/auth'
import toastError       from 'shared/toastError'

import { addTargetAudience } from '../addTargetAudience'

import credentialsValidationSchema from './credentialsValidationSchema'
import Search from './Search'

const AddAudienceButtons = ({
  className,
  client,
  channel,
  campaign
}) => {
  const addBlankAudience = () => {
    credentialsValidationSchema
      .validate({
        areFacebookCredentialsValid: campaign.areFacebookCredentialsValid,
        areGmpCredentialsValid: campaign.areGmpCredentialsValid,
        channelType: channel.type
      }).then(function(_valid) {
        addTargetAudience({ client, channelUuid: channel.uuid })
      }).catch(function(err) {
        toastError(<>{ err.message }</>)
      })
  }

  const [showSearch, setShowSearch] = useState(false)

  const isPortal = channel.type === 'portal'
  const isCrudable = isUserAdmin() || !isPortal

  if (!isCrudable) {
    return(
      <p>
        { t`These audiences have been generated automatically` }
      </p>
    )
  }

  return (
    <div className={ className }>
      <div className='buttons-group'>
        <Button onClick={ () => setShowSearch(true) }>
          { t`Clone audience` }
        </Button>
        <Button onClick={ addBlankAudience } data-test-id='add-audience-button'>
          { t`Add audience` }
        </Button>
      </div>

      <Search
        channel={ channel }
        show={ showSearch }
        onHide={ () => setShowSearch(false) }
      />
    </div>
  )
}

AddAudienceButtons.propTypes = {
  campaign: campaignType.isRequired,
  channel: channelType.isRequired,
  client: apolloClientType.isRequired
}

export default styled(withApollo(AddAudienceButtons))`
  .buttons-group {
    display: flex
    flex-direction: row-reverse
    margin-bottom: 1rem
  }
`

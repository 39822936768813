import React  from 'react'
import Select from 'react-select'
import { t }  from 'ttag'

import { inputBoxShadowValue } from 'shared/style/boxShadowValues'
import { redColor }            from 'shared/style/colors'

const styles = {
  container: provided => ({
    ...provided,
    boxShadow: inputBoxShadowValue,
  }),
  control: (provided, { selectProps }) => ({
    ...provided,
    borderColor: selectProps.isInvalid ? redColor : provided.borderColor
  })
}

const FormSelect = props => {
  return (
    <Select
      placeholder=""
      noOptionsMessage={ () => t`No options` }
      styles={ styles }
      { ...props }
    />
  )
}

export default FormSelect

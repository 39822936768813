import React from 'react'
import { oneOfType, number, array, string, func, node } from 'prop-types'

import { bluishColor } from 'shared/style/colors'
import Chart from 'shared/components/Chart'

import Card from '../Card'

import ChartHeader from './Header'
import chartOptions from './chartOptions'

const LinearChart = ({ currentValue, dataset, name, comment, tooltipFormatter, dataTestId }) => {
  const chartData = [
    {
      name: name,
      seriesData: dataset,
      color: bluishColor
    }
  ]

  if (tooltipFormatter) {
    chartData[0].tooltipFormatter = tooltipFormatter
  }

  return (
    <Card header={ name } dataTestId={ dataTestId }>
      <ChartHeader
        boldText={ currentValue }
        comment={ comment } />

      <div className='chart-container'>
        <Chart
          data={ chartData }
          chartOptions={ chartOptions }
        />
      </div>
    </Card>
  )
}

LinearChart.propTypes = {
  comment: string,
  currentValue: oneOfType([string, number, node]),
  dataTestId: string,
  dataset: array,
  name: string,
  tooltipFormatter: func
}

LinearChart.defaultProps = {
  currentValue: 0,
  dataTestId: null,
  dataset: [],
  name: '',
  comment: '',
  tooltipFormatter: undefined
}

export default LinearChart

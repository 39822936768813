import React, { useState, useEffect, cloneElement } from 'react'
import { shape, bool, string, node } from 'prop-types'

import InputFeedback from './InputFeedback'

const ValidatableWrapper = ({ error, children, ...props }) => {
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    error && error.force && setTouched(true)
  }, [error])

  const isInvalid = error && touched

  let validatableChildren = Array.isArray(children) ? children : Array(children)
  validatableChildren = validatableChildren.map((child, idx) => {
    const extendedAttrs = child.key
      ? { isInvalid, setTouched }
      : { isInvalid, setTouched, key: idx }
    return cloneElement(child, extendedAttrs)
  })

  return (
    <InputFeedback error={ isInvalid && error.errorMessage || '' } { ...props }>
      { validatableChildren }
    </InputFeedback>
  )
}

ValidatableWrapper.propTypes = {
  children: node.isRequired,
  error: shape({
    errorMessage: string,
    force: bool
  })
}

ValidatableWrapper.defaultProps = {
  error: null
}

export default ValidatableWrapper

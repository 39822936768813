import moment from 'moment'

export const defaultFormatter = (value) => {
  return moment.utc(value).format('YYYY-MM-DD')
}

export const formatWithEndOf = (value, endOfUnit) => {
  return moment.utc(value).endOf(endOfUnit).format('YYYY-MM-DD')
}

export const formatWithStartOf = (value, startOfUnit) => {
  return moment.utc(value).startOf(startOfUnit).format('YYYY-MM-DD')
}

export const getStartOf = (unitOfTime) => {
  return moment.utc().startOf(unitOfTime).format('YYYY-MM-DD')
}

export const getEndOf = (unitOfTime) => {
  return moment.utc().endOf(unitOfTime).format('YYYY-MM-DD')
}

import React            from 'react'
import { string, func } from 'prop-types'

import useCreateUpdateCreativeSet from './useCreateUpdateCreativeSet'
import useSetAudiences            from './useSetAudiences'
import CreativeSetFormView        from './CreativeSetFormView'

const CreativeSetForm = ({ uuid, setPreviewField, ...props }) => {
  const createUpdateCreativeSet = useCreateUpdateCreativeSet()
  const onAudiencesSet          = useSetAudiences(uuid)

  const onTitleUpdate   = (event) => setPreviewField('title', event.target.value)
  const onContentUpdate = (event) => setPreviewField('content', event.target.value)
  const onDescriptionUpdate = (event) => setPreviewField('description', event.target.value)

  const onNameUpdate  = createUpdateCreativeSet(uuid, 'name')
  const onTitleBlur   = createUpdateCreativeSet(uuid, 'title')
  const onContentBlur = createUpdateCreativeSet(uuid, 'content')
  const onDescriptionBlur = createUpdateCreativeSet(uuid, 'description')

  return (
    <CreativeSetFormView
      { ...props }
      uuid={ uuid }
      onAudiencesSet={ onAudiencesSet }
      onNameUpdate={ onNameUpdate }
      onTitleUpdate={ onTitleUpdate }
      onContentUpdate={ onContentUpdate }
      onDescriptionUpdate={ onDescriptionUpdate }
      onTitleBlur={ onTitleBlur }
      onContentBlur={ onContentBlur }
      onDescriptionBlur={ onDescriptionBlur }
    />
  )
}

CreativeSetForm.propTypes = {
  setPreviewField: func.isRequired,
  uuid: string.isRequired
}

export default CreativeSetForm

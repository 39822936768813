import React        from 'react'
import { t }        from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { object }   from 'prop-types'

import dashboardAnalyticsTotalLeadsQuery from 'graphql/queries/dashboardAnalytics/totalLeads'
import LoadingBlock                      from 'shared/components/ui/LoadingBlock'
import Chart                             from 'shared/components/Chart'
import { bluishColor }                   from 'shared/style/colors'

import Card                from './Card'
import ChartHeader         from './LinearChart/Header'
import formatCurrentValues from './formatCurrentValues'

const chartOptions = {
  chart: {
    height: 150,
    width: 800,
    marginRight: 150,
    yAxis: {
      gridLineWidth: 0
    },
    xAxis: {
      gridLineWidth: 1
    }
  },
  plotOptions: {
    spline: {
      marker: {
        enabled: false
      }
    },
    series: {
      marker: {
        radius: 1
      }
    }
  }
}

const TotalLeadsChart = ({ filter }) => {
  const {
    data: {
      dashboardAnalyticsTotalLeads: {
        totalLeads = {}
      } = {}
    } = {},
    loading
  } = useQuery(dashboardAnalyticsTotalLeadsQuery, {
    variables: { filter }
  })

  if (loading) {
    return (
      <Card header={ t`Leads` }>
        <LoadingBlock />
      </Card>
    )
  }

  const { totalLeadsValue } = formatCurrentValues({ totalLeads })

  const chartData = [
    {
      name:       t`Leads`,
      seriesData: totalLeads.dataset || [],
      color:      bluishColor
    }
  ]

  return (
    <Card header={ t`Leads` } dataTestId='total-leads-chart'>
      <ChartHeader
        boldText={ totalLeadsValue }
        comment={ t`Total number of leads` } />

      <div className='chart-container'>
        <Chart
          data={ chartData }
          chartOptions={ chartOptions }
        />
      </div>
    </Card>
  )
}

TotalLeadsChart.propTypes = {
  filter: object
}

TotalLeadsChart.defaultProps = {
  filter: {}
}

export default TotalLeadsChart

import React from 'react'
import { t } from 'ttag'
import { func, string } from 'prop-types'
import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'

import { menuItemShadow } from 'shared/style/placeholders'

import OptionsToggle from './OptionsToggle'

const options = [
  { value: 'include', label: t`Include` },
  { value: 'exclude', label: t`Exclude` }
]

const OptionsDropdown = ({ onChange, selectedOperator, className }) => {
  const selectedOption = options.find((option) => option.value === selectedOperator)
  const optionsToShow = options.filter((option) => option !== selectedOption)

  return (
    <Dropdown drop='left' className={ className }>
      <Dropdown.Toggle
        variant="link"
        as={ OptionsToggle }
      />

      <Dropdown.Menu>
        { optionsToShow.map(({ value, label }) => (
          <Dropdown.Item key={ value } onClick={ () => onChange(value) }>
            { label }
          </Dropdown.Item>
        )) }
      </Dropdown.Menu>
    </Dropdown>
  )
}

OptionsDropdown.propTypes = {
  className: string.isRequired,
  onChange: func.isRequired,
  selectedOperator: string.isRequired
}

export default styled(OptionsDropdown)`
  .dropdown-menu {
    ${menuItemShadow}

    min-width: 6.5rem
    padding: 0
    text-align: center
  }

  .dropdown-item {
    font-size: 0.8rem
    width: 6.5rem
  }
`

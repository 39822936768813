import React from 'react'

import { UnitsTable } from './UnitsTable'

const Units = (props) => {
  return (
    <UnitsTable { ...props } />
  )
}

export default Units

import React from 'react'
import { node } from 'prop-types'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink, from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import ActionCable from 'actioncable'
import ActionCableLink from 'graphql-ruby-client/dist/subscriptions/ActionCableLink'
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory'
import { t } from 'ttag'

import { notifyGraphqlError, notifyNetworkError } from 'sentryClient'
import { data as introspectionQueryResultData } from 'GraphqlSchema.json'
import { dataIdFromObject } from 'shared/helpers/graphql'
import { getToken, setUserPermissions, clearToken } from 'shared/helpers/auth'
import redirectTo, { redirectToLogin } from 'shared/helpers/redirectTo'

import fetchWithProgress  from './fetchWithProgress'
import { typeDefs } from './resolvers'
import toastError from './toastError'

const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData })

const cache = new InMemoryCache({ fragmentMatcher, dataIdFromObject })

const httpLink = new HttpLink({ uri: '/graphql' })

const missingAccessRightsError = (
  <>
    <p className="mb-2">
      { t`You don’t have access to this project` }
    </p>
    <p className="mb-1">
      { t`You need access to the project in your CRM or be invited by email.` }
    </p>
    <p className="mb-0">
      { t`Contact the responsible agent to request access.` }
    </p>
  </>
)

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: `Bearer ${getToken()}`,
      JWT_AUD: window.navigator.userAgent
    }
  })

  return forward(operation)
})

const handlePermissionsFromSuccessResponse = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.data.permissions !== undefined) setUserPermissions(response.data.permissions)
    return response
  })
})

const getUnauthorizedError = (graphQLErrors) => {
  return graphQLErrors.find(
    (error) => (error.extensions && error.extensions.code === 'unauthorized')
  )
}

const getProjectNotFoundError = (graphQLErrors) => {
  return graphQLErrors.find(
    (error) => (error.message === 'Project not found')
  )
}

const getAssetAssignmentNotFoundError = (graphQLErrors) => {
  return graphQLErrors.find(
    (error) => (error.message === 'AssetAssignment not found')
  )
}

const handleErrorResponse = onError((errorResponse) => {
  const { networkError, graphQLErrors } = errorResponse

  if (networkError) {
    if (networkError.statusCode >= 500) {
      return toastError(t`Invalid server response`)
    }

    notifyNetworkError(errorResponse)

    if (networkError.statusCode === 401) {
      clearToken()

      return redirectToLogin()
    }

    return toastError(networkError?.response?.statusText)
  }

  if (graphQLErrors?.length) {
    notifyGraphqlError(errorResponse)
    console.error('GraphQL errors', graphQLErrors) // eslint-disable-line

    const unauthorizedError = getUnauthorizedError(graphQLErrors)
    const projectNotFoundError = getProjectNotFoundError(graphQLErrors)
    const assetAssignmentNotFoundError = getAssetAssignmentNotFoundError(graphQLErrors)

    if (unauthorizedError) {
      setUserPermissions(unauthorizedError.extensions.details.details.permissions)
      redirectTo('/')
      toastError(t`You are not authorized to perform this action`, { autoClose: 3000 })
    } else if(projectNotFoundError) {
      toastError(missingAccessRightsError, { autoClose: 10000 })
    } else if(assetAssignmentNotFoundError) {
      // NOOP
    } else {
      toastError()
    }
  }
})

const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss'
const cable = ActionCable.createConsumer(`${protocol}://${window.location.host}/cable`)

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
  )
}

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
)

const client = new ApolloClient({
  link: from([
    authMiddleware,
    handlePermissionsFromSuccessResponse,
    handleErrorResponse,
    link
  ]),
  cache,
  typeDefs,
  resolvers: {},
  fetch: fetchWithProgress
})

const ConfiguredApolloProvider = ({ children }) => {
  return (
    <ApolloProvider client={ client }>
      <ApolloHooksProvider client={ client }>
        {children}
      </ApolloHooksProvider>
    </ApolloProvider>
  )
}

ConfiguredApolloProvider.propTypes = {
  children: node.isRequired
}

export default ConfiguredApolloProvider

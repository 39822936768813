import React    from 'react'
import styled   from 'styled-components'
import { node } from 'prop-types'

import { canCreate, canRead } from 'shared/helpers/auth'

import Logo      from './Logo'
import NewButton from './NewButton'

const Sidebar = ({ className, children }) => {
  const showNew = canCreate('campaigns') || canCreate('projects') || canCreate('stages')
    || (canCreate('leads') && (canRead('leads_projects') || canRead('projects')))

  return (
    <div className={ className }>
      <div className="logo">
        <Logo />
      </div>

      {showNew && (
        <NewButton />
      )}

      { children }
    </div>
  )
}

Sidebar.propTypes = {
  children: node.isRequired,
}

export default styled(Sidebar)`
  .logo {
    display: flex
    justify-content: flex-start
    margin-bottom: 1.75rem
  }

  .logo a:hover {
    cursor: pointer;
  }

  .logo img {
    height: 1.5rem;
  }

  .logo .title {
    font-size: 0.625rem
    line-height: 0.75rem
    letter-spacing: 0.25px
    padding-left: .5rem
  }
`

import styled from 'styled-components'

import { cardPaddingXSize, cardPaddingYSize } from 'shared/style/sizes'
import { textGreyColor, textColor }           from 'shared/style/colors'

export default styled.table`
  margin-left: -${cardPaddingXSize}
  margin-right: -${cardPaddingXSize}
  margin-bottom: -${cardPaddingYSize}
  width: calc(100% + ${cardPaddingXSize} + ${cardPaddingXSize});
  font-size: 0.875rem
  margin-top: .25rem  
  
  tbody tr {
    border-top: solid 0.5px rgba(151, 151, 151, 0.6);
  }
  
  tbody td {
    padding: 0.75rem
  }
  
  tbody td p {
    margin-bottom: 0
  }
  
  tr th:first-child,
  tr td:first-child {
    padding-left: ${cardPaddingXSize}
  }
  
  th {
    color: ${textGreyColor}
    font-weight: 500
    padding: .25rem .5rem .5rem
  }
  
  th:first-child {
    font-weight: 400
  }
  
  .setting-name {
    color: ${textColor}
    font-size: 1rem
  }
`

import { gql } from 'apollo-boost'

import campaign from 'graphql/fragments/campaign'

export default gql`
  mutation removeAudienceLocation(
    $uuid: ID!
  ) {
    removeAudienceLocation(
      uuid: $uuid
    ) {
      audienceLocation {
        uuid
      }
      campaign {
        ... Campaign
      }
    }
  }
  ${campaign}
`

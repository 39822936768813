import React from 'react'
import styled from 'styled-components'
import { string, arrayOf, number } from 'prop-types'
import { max } from 'lodash'

import { havelockBlue } from 'shared/style/colors.js'
import { StatName } from 'shared/components/StatsIndicator/elements'

const CostTrend = ({ className, name, values }) => {
  const graphWidth = 80
  const graphHeight = 20
  const lineWidth = 1.5

  const maxPointY = graphHeight - lineWidth / 2

  const maxValue = max(values)
  const linePoints = values.map((el, idx) => {
    const x = graphWidth / values.length * (idx + 1)

    if (maxValue === 0) return `L ${x},${maxPointY}`

    const y = maxPointY - maxPointY / maxValue * el

    return `L ${x},${y}`
  }).join(' ')

  const flatLine = `L ${graphWidth},${maxPointY}`

  const line = linePoints || flatLine

  return (
    <div className={ className }>
      <StatName>{ name }</StatName>
      <svg
        viewBox={ `0 0 ${graphWidth} ${graphHeight}` }
        height="1.3125rem"
        width="5rem"
      >
        <path
          d={ `M 0,${maxPointY} ${line}` }
          stroke={ havelockBlue }
          strokeWidth={ lineWidth }
          fill="transparent"
        />
      </svg>
    </div>
  )
}

CostTrend.propTypes = {
  className: string.isRequired,
  name: string.isRequired,
  values: arrayOf(number).isRequired
}

export default styled(CostTrend)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 4rem;
`

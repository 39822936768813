import React            from 'react'
import { t }            from 'ttag'
import { debounce }     from 'lodash'
import { string, func } from 'prop-types'

import { debounceTimeout } from 'shared/constants'

const invokeDebounced = debounce(
  (onSearch, value) => onSearch(value),
  debounceTimeout
)

const Search = (props) => {
  const { onSearch, searchText, className } = props

  const handleChange = (e) => invokeDebounced(onSearch, e.target.value)

  return (
    <input
      className={ `form-control ${className}` }
      type="text"
      placeholder={ t`Search...` }
      defaultValue={ searchText }
      onChange={ handleChange }
    />
  )
}

Search.propTypes = {
  onSearch: func.isRequired,
  className: string,
  searchText: string
}

Search.defaultProps = {
  className: '',
  searchText: ''
}

export default Search

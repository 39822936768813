import { css } from 'styled-components'

import { cardPaddingXSize, cardPaddingYSize } from 'shared/style/sizes'
import { buttonBorderColor } from 'shared/style/colors'
import { inputBoxShadowValue } from 'shared/style/boxShadowValues'

export const formInputShadow = css`
  box-shadow: 0 1px 8px -2px rgba(0, 0, 0, 0.3);
`
export const roundButtonShadow = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
`
export const roundButtonFocusShadow = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
`
export const cardBoxShadow = css`
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
`
export const menuItemShadow = css`
  box-shadow: 0 0.125rem 0.438rem -0.188rem #9c9c9c
`
export const cardBorderRadius = css`
  border-radius: 3px;
`
export const cardPaddingX = css`
  padding-left: ${cardPaddingXSize}
  padding-right: ${cardPaddingXSize}
`
export const cardPaddingY = css`
  padding-top: ${cardPaddingYSize}
  padding-bottom: ${cardPaddingYSize}
`
export const popoverPadding = css`
  padding: 0.6875rem 0.875rem
`
export const inputBoxShadow = css`
  box-shadow: ${inputBoxShadowValue}
`
export const inputHoverBoxShadow = css`
  box-shadow: 0px 2px 5px -1px #9c9c9c
`
export const cardBorderValue = css`
  1px solid rgba(0, 0, 0, 0.125);
`
export const cardBorder = css`
  border: ${cardBorderValue}
`
export const defaultBorderValue = css`
  1px solid ${buttonBorderColor};
`
export const activeBorderValue = css`
  1px solid #9c9c9c;
`
export const tableBorderValue = css`
  solid 0.5px rgba(151, 151, 151, 0.6);
`
export const panelBackground = css`
  background: #f6fafc;
`
export const unsetCardPadding = css`
  margin-bottom: -${cardPaddingYSize}
  margin-top: -${cardPaddingYSize}
  margin-left: -${cardPaddingXSize}
  margin-right: -${cardPaddingXSize}
`
// requires parent DOM element to have `position: relative`
export const centeredImage = css`
  position: absolute
  top: 0
  left: 0
  height: 100%
  object-fit: cover
  object-position: center
  width: 100%
`

import { gql } from 'apollo-boost'

import facility from 'graphql/fragments/facility'

export default gql`
  query Facilities($query: PageInput!) {
    facilities(query: $query) {
      total
      items {
        ... Facility
      }
    }
  }
  ${facility}
`

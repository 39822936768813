import React from 'react'
import { t } from 'ttag'

import { EmptyAssetContainer } from '../EmptyAsset'
import AssetContainer from '../AssetContainer'

const AssetUploading = () => (
  <AssetContainer>
    <EmptyAssetContainer>
      { t`Uploading...` }
    </EmptyAssetContainer>
  </AssetContainer>
)

export default AssetUploading

import { gql } from 'apollo-boost'

import stageUnitParking from 'graphql/fragments/stageUnitParking'

export default gql`
  mutation updateParkings(
    $uuid: ID!
    $attributes: StageUnitParkingInput!
  ) {
    updateParkings(
      uuid: $uuid
      attributes: $attributes
    ) {
      parkings {
        ... StageUnitParking
      }
    }
  }
${stageUnitParking}
`

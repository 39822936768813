import React,  { useEffect, useState } from 'react'
import { t }               from 'ttag'
import { get }             from 'lodash'
import { toast }           from 'react-toastify'
import { useApolloClient } from 'react-apollo'

import archiveProjectMutation  from 'graphql/mutations/projects/archive'
import updateProjectMutation   from 'graphql/mutations/projects/update'
import ProjectSummary          from 'shared/components/ProjectSummary'
import Leads                   from 'shared/components/LeadsList'
import redirectTo              from 'shared/helpers/redirectTo'
import { canUpdate, isUserAdmin } from 'shared/helpers/auth'
import projectType             from 'shared/types/projectType'

import Actions        from '../Actions'
import Breadcrumbs    from '../Breadcrumbs'
import CampaignsList  from '../CampaignsList'
import Analytics      from '../Analytics'
import Stakeholders   from '../Stakeholders'
import ShowExtraUnits from '../ShowExtraUnits'
import ProjectInformation from '../ProjectInformation'
import Offers           from '../Offers'
import { isProjectOffersEnabled } from '../helpers'

import Stages           from './Stages'
import Buildings        from './Buildings'
import EditProjectModal from './EditProjectModal'
import Media            from './Media'

const disabledCompanyNames = [
  'DNB'
]

const Project = ({ project, className }) => {
  const [isEditing, setIsEditing] = useState(false)

  const client = useApolloClient()

  const _handleArchiveProject = () => {
    if (confirm(t`Are you sure you want to archive this project?`)) {
      client.mutate({
        mutation: archiveProjectMutation,
        variables: { uuid: project.uuid }
      }).then((response) => {
        const errors = get(response, 'data.archiveProject.errors')
        if (errors) {
          toast.error(errors.map((e) => e.message).join('. '))
        } else {
          toast.success(t`Project "${project.name}" archived.`)
          redirectTo('/projects')
        }
      })
    }
  }

  const buildCompaniesInputs = (companies) => {
    return companies.filter((company) => {
      return company.empty === undefined || !company.empty
    }).map((company) => {
      return {
        uuid: company.uuid,
        category: company.category
      }
    })
  }

  const handleSave = input => {
    const districtUuid = input.districtUuid
    const companiesInputs = buildCompaniesInputs(input.companies)
    const { countryCode, address, zipCode, latitude, longitude } = input.location

    delete(input.districtUuid)
    delete(input.location)
    delete(input.companies)

    client.mutate({
      mutation: updateProjectMutation,
      variables: {
        uuid: project.uuid,
        districtUuid,
        input,
        location: { countryCode, address, zipCode, latitude, longitude },
        companies: companiesInputs
      }
    })

    setIsEditing(false)
  }

  const companyName = project.product.company.name
  const available = isUserAdmin() || !disabledCompanyNames.includes(companyName)

  const isSnapshotEnabled = (project) => {
    return project.product.company.enabledFeatures.includes('snapshot')
  }

  const isProjectNewsEnabled = (project) => {
    return project.product.company.enabledFeatures.includes('project_news')
  }

  const isPropertyPickerEnabled = (project) => {
    return project.product.company.enabledFeatures.includes('property_picker')
  }

  useEffect(() => {
    if (!isSnapshotEnabled(project) || !project.readonly) return
    // eslint-disable-next-line max-len
    alert(t`This project is read-only with data coming from an external integration - any data added might be overwritten`)
  })

  return (
    <div className={ [className, 'mb-5'].join(' ') }>
      <Breadcrumbs name={ project.name }/>

      <div className="position-relative">
        <ProjectSummary
          project={ project }
          disabled={ !available }
        />
        <Actions
          available={ available }
          isSnapshotEnabledFlag={ isSnapshotEnabled(project) }
          isSalesReportEnabledFlag={ project.salesReportEnabled }
          isProjectNewsEnabledFlag={ isProjectNewsEnabled(project) }
          isPropertyPickerEnabledFlag={ isPropertyPickerEnabled(project) }
          projectName={ project.name }
          projectUuid={ project.uuid }
          setIsEditing={ setIsEditing }
        />
      </div>

      { isSnapshotEnabled(project) && <ProjectInformation project={ project } /> }

      { !isSnapshotEnabled(project) && <Stakeholders
        project={ project }
        disabled={ !available }
      /> }

      {canUpdate('sales_reports') && (<ShowExtraUnits project={ project } />)}

      {available && (
        <div>
          <CampaignsList projectUuid={ project.uuid } />

          <Analytics projectUuid={ project.uuid } />

          <Stages
            collapsed
            stages={ project.completeStages }
            projectReadOnly={ project.readonly }
          />

          <Buildings
            collapsed
            buildings={ project.completeBuildings }
            projectReadOnly={ project.readonly }
          />

          <Leads projectUuid={ project.uuid } className='leads-card' />

          {isProjectOffersEnabled(project)
            && <Offers collapsed className='offers-card' projectUuid={ project.uuid } />}

          <Media project={ project } disableShowings={ isSnapshotEnabled(project) }  />

          <EditProjectModal
            project={ project }
            show={ isEditing }
            onHide={ () => setIsEditing(false) }
            onSave={ handleSave }
          />
        </div>
      )}
    </div>
  )
}

Project.propTypes = {
  project: projectType.isRequired,
}

export default Project

import React      from 'react'
import { string } from 'prop-types'

import ResourceQuery from 'shared/components/ResourceQuery'
import campaignsQuery from 'graphql/queries/campaigns'

import CampaignsCard from './CampaignsCard'

const CampaignsList = ({ projectUuid }) => {
  const phase = 'assembly,review,scheduled,live,paused,cancelled,finished'

  return (
    <ResourceQuery query={ campaignsQuery }
      variables={{ query: { page: 1, perPage: 999, filter: { project: [projectUuid], phase } } }}
      fetchPolicy='cache-first'
    >
      {campaigns => (
        <CampaignsCard campaigns={ campaigns.items } />
      )}
    </ResourceQuery>
  )
}

CampaignsList.propTypes = {
  projectUuid: string.isRequired
}

export default CampaignsList

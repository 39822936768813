import { get } from 'lodash'

import { formatNumberWithLocale } from 'shared/helpers/number'

export const formatPrice = (sum, currency) => {
  return `${formatNumberWithLocale(sum)} ${currency ? currency.toUpperCase() : ''}`
}

export const formatNok = (sum) => {
  return `${formatNumberWithLocale(sum)},-`
}

export const campaignCurrency = (campaign) => (
  get(campaign, 'product.promotable.currency', 'NOK')
)

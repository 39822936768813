import React from 'react'

import matchType from 'shared/types/matchType'
import CreateStage from 'shared/components/NewStage/CreateStage'

const CreateStagePage = ({ match }) => (
  <CreateStage projectUuid={ match.params.uuid } />
)

CreateStagePage.propTypes = {
  match: matchType.isRequired
}

export default CreateStagePage

import React from 'react'
import { string, array, func } from 'prop-types'

import useChannelTypes from 'shared/hooks/useChannelTypes'
import Select          from 'shared/components/ui/Select'
import { channelPresentation } from 'shared/constants/channelPresentation'
import { isUserAdmin }         from 'shared/helpers/auth'

const ChannelSelect = ({ onChange, selectedChannels, type }) => {
  const channelTypes = useChannelTypes()
  const options = channelTypes
    .filter((type) => isUserAdmin() || (type !== 'portal'))
    .map(type => ({
      value: type,
      label: channelPresentation[type] || type
    }))
  const selectedOption = options.find(({ value }) => (
    value.toLowerCase() === type.toLowerCase()
  ))

  const handleChange = ({ value }) => {
    onChange({ target: { name: 'type', value } })
  }

  const isOptionDisabled = ({ value }) => {
    return !!selectedChannels.find(({ type }) => type === value)
  }

  return (
    <Select
      small
      className="w-50 spending-select"
      options={ options }
      onChange={ handleChange }
      defaultValue={ options[0] }
      isOptionDisabled={ isOptionDisabled }
      value={ selectedOption }
    />
  )
}

ChannelSelect.propTypes = {
  onChange: func.isRequired,
  type: string.isRequired,
  selectedChannels: array
}

ChannelSelect.defaultProps = {
  selectedChannels: []
}

export default ChannelSelect

import React from 'react'
import styled from 'styled-components'
import { IconCaretUp, IconCaretDown } from 'cabanaico'

const Div = styled.div`
  display: inline-flex
  flex-direction: column
  vertical-align: middle
`

const Default = () => {
  return (
    <Div>
      <IconCaretUp />
      <IconCaretDown />
    </Div>
  )
}

export default Default

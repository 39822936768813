import { arrayOf, number, string, shape } from 'prop-types'

import assetType from 'shared/types/assetType'
import salesArgumentType from 'shared/types/stageSalesArgumentType'

export default shape({
  uuid: string.isRequired,
  name: string,
  projectName: string,
  productUuid: string,
  unitsCount: number,
  activeCampaignsCount: number,
  image: assetType,
  salesArguments: arrayOf(salesArgumentType)
})

import React       from 'react'
import { memoize } from 'lodash'

import AppRedirect from 'routes/AppRedirect'

const withAppRedirect = (Page) => {
  const NewPageWithAppRedirect = (props) => (
    <AppRedirect { ...props }>
      <Page { ...props } />
    </AppRedirect>
  )

  NewPageWithAppRedirect.displayName = `${Page.displayName}WithAppRedirect`

  return NewPageWithAppRedirect
}

export default memoize(withAppRedirect)

import React, { forwardRef } from 'react'
import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { oneOfType, elementType, string } from 'prop-types'

import {
  inputBoxShadow,
  cardBorderRadius,
  inputHoverBoxShadow,
  defaultBorderValue,
  activeBorderValue
} from 'shared/style/placeholders'
import {
  buttonTextColor,
  bluishColor,
  buttonBorderColor
} from 'shared/style/colors'

const variants = {
  primary: {
    backgroundColor: bluishColor,
    borderColor: bluishColor,
    color: '#FFF'
  },
  default: {
    backgroundColor: '#FFF',
    borderColor: buttonBorderColor,
    color: buttonTextColor,
  }
}

const propertyGetter = (property) => ({ variant }) => (
  get(variants, `${variant}.${property}`, variants.default[property])
)

const Button = forwardRef(({ component: ComponentProp, ...props }, ref) => (
  <ComponentProp { ...props } ref={ ref } />
))

Button.displayName = 'Button'

Button.propTypes = {
  component: oneOfType([elementType, string])
}

Button.defaultProps = {
  component: 'button'
}

export const buttonStyle = css`
  ${inputBoxShadow}
  ${cardBorderRadius}

  border: ${defaultBorderValue}
  background-color: ${propertyGetter('backgroundColor')}
  color: ${propertyGetter('color')}
  display: inline-block;
  padding: .25rem .75rem
  white-space: nowrap

  &[disabled] {
    opacity: .6
  }

  &:not([disabled]) {
    &:hover, &:active, &:focus {
      ${inputHoverBoxShadow}
    }
  }

  &:hover {
    color: ${propertyGetter('color')}
    text-decoration: none;
  }

  &.active {
    border: ${activeBorderValue}
  }

  svg {
    margin-right: 10px
  }
`

export default styled(Button)`
  ${buttonStyle}
`


import gql from 'graphql-tag'

export default gql`
  fragment ProductStakeholder on Stakeholder {
    uuid
    createdAt
    user {
      uuid
      name
      synced
    }
    role
    responsibility
    permission
    leadNotification
    weeklyReport
  }
`

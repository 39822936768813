import { gql } from 'apollo-boost'

import DashboardDataset from 'graphql/fragments/dashboardDataset'

export default gql`
  query DashboardAnalyticsAdSpend($filter: JSON) {
    dashboardAnalyticsAdSpend(filter: $filter) {
      currency
      adSpend {
        ... DashboardDataset
      }
    }
  }
  ${DashboardDataset}
`

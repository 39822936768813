import { gql } from 'apollo-boost'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  query SnapshotAssetAssignments($snapshotUuid: ID!) {
    snapshotAssetAssignments(snapshotUuid: $snapshotUuid) {
      ... AssetAssignment
    }
  }
  ${assetAssignment}
`

import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { func, arrayOf, number, shape, bool, string } from 'prop-types'
import ReactMapboxGl, { ZoomControl } from 'react-mapbox-gl'
import { invoke } from 'lodash'

import { redColor } from 'shared/style/colors'
import DropPinButton from 'shared/components/ui/DropPinButton'
import credentials from 'config/credentials'

import Marker from './Marker'

const MapBox = ReactMapboxGl({
  accessToken: credentials.mapboxApiKey
})

const Map = ({ marker, pointMapToCoordinates, onLocationChange, isInvalid, mapHeight }) => {
  const [map, setMap] = useState()
  const [dropPinModeEnabled, setDropPinMode] = useState(false)
  const [center, setCenter] = useState()

  useEffect(() => {
    if (!map) return

    map.getCanvas().style.cursor = dropPinModeEnabled ? 'crosshair' : ''
    map.on('click', handleMapClick)

    return () => { map.off('click', handleMapClick) }
  }, [map, dropPinModeEnabled])

  useEffect(() => {
    const { latitude, longitude } = marker

    if (latitude && longitude) setCenter([longitude, latitude])
  }, [marker.latitude, marker.longitude])

  useEffect(() => {
    if (pointMapToCoordinates) setCenter(pointMapToCoordinates)
  }, [pointMapToCoordinates])

  useEffect(() => {
    if (!map || !center) return

    map.flyTo({ center, duration: 200, linear: true })
  }, [map, center])

  const triggerDropPinMode = (e) => {
    e.preventDefault()
    setDropPinMode(!dropPinModeEnabled)
  }

  const handleMapClick = (event) => {
    if (!dropPinModeEnabled) return

    onLocationChange({
      latitude:  event.lngLat.lat,
      longitude: event.lngLat.lng
    })

    setDropPinMode(false)
  }

  const onMapLoad = map => setMap(map)

  const onDragEnd = e => {
    const {
      target: {
        _lngLat: {
          lng: longitude,
          lat: latitude
        }
      }
    } = e

    onLocationChange({ longitude, latitude })
  }

  const mapCanvasContainer = invoke(map, 'getCanvasContainer')

  return (
    <Wrapper className={ isInvalid ? 'with-error' : '' } mapHeight={ mapHeight }>
      <DropPinButton onClick={ triggerDropPinMode } disabled={ !map } />

      <MapBox
        style="mapbox://styles/mapbox/streets-v11"
        onStyleLoad={ onMapLoad }
        containerStyle={{
          height: mapHeight,
          width: '100%'
        }}
      >
        <ZoomControl />
        {
          mapCanvasContainer && createPortal(
            (<Marker map={ map } onDragEnd={ onDragEnd } { ...marker } />),
            mapCanvasContainer
          )
        }
      </MapBox>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: ${props => props.mapHeight};
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 0;

  &.with-error {
    border-color: ${redColor}
  }

  .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    margin-left: -10rem;
  }

  ${DropPinButton} {
    bottom: 0.5rem;

    button {
      font-size: 0.75rem;
      line-height: 1.5rem;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

Map.propTypes = {
  marker: shape({
    latitude: number,
    longitude: number
  }).isRequired,
  isInvalid: bool,
  mapHeight: string,
  onLocationChange: func,
  pointMapToCoordinates: arrayOf(number)
}

Map.defaultProps ={
  onLocationChange: () => {},
  pointMapToCoordinates: undefined,
  isInvalid: false,
  mapHeight: '9rem'
}

export default Map

import React from 'react'
import { components } from 'react-select'
import { shape, string, number } from 'prop-types'

import { formatNumberWithLocale } from 'shared/helpers/number'

const Option = props => {
  return (
    <components.Option { ...props }>
      <div className="d-flex justify-content-between">
        <span>{props.data.name}</span>
        <span>{formatNumberWithLocale(props.data.audienceSize)}</span>
      </div>
    </components.Option>
  )
}

Option.propTypes = {
  data: shape({
    name: string.isRequired,
    audienceSize: number.isRequired
  }).isRequired
}
export default Option

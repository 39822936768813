import React      from 'react'
import { string } from 'prop-types'
import { t }      from 'ttag'

import PageHeader    from 'shared/components/ui/PageHeader'
import ResourceQuery from 'shared/components/ResourceQuery'

import buildingQuery from './buildingQuery'
import BuildingDetails from './BuildingDetails'

const NewBuilding = ({ uuid }) => {
  return (
    <ResourceQuery
      query={ buildingQuery }
      variables={{ uuid }}
    >
      {building => (
        <>
          <PageHeader title={ t`Create new building` + ` - ${building.projectName}` } />
          <BuildingDetails building={ building } />
        </>
      )}
    </ResourceQuery>
  )
}

NewBuilding.propTypes = {
  uuid: string.isRequired
}

export default NewBuilding

import { t } from 'ttag'

export const channelPresentation = {
  facebook: t`Facebook`,
  instagram: t`Instagram`,
  portal: t`Portal`,
  gmp: t`Display`,
  predefined: t`Display - Predefined`,
  facebook_lead_ad: t`Facebook Lead`,
  facebook_dynamic_creative: t`Facebook Dynamic Creative`
}

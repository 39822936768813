import React from 'react'
import { arrayOf, func, number, string } from 'prop-types'
import styled from 'styled-components'

import Tab from 'shared/components/ui/TabBar/Tab'

const TabBar = ({ tabs, activeTab, enabledTabs, completeTabs, onChange, className }) => {
  return <div className={ `d-flex ${className}` }>
    {tabs.map((title, idx) =>
      <Tab
        key={ idx /* eslint-disable-line react/no-array-index-key */ }
        title={ title }
        showArrow={ idx < (tabs.length - 1) }
        active={ idx === activeTab }
        enabled={ !enabledTabs || enabledTabs.includes(idx) }
        complete={ completeTabs && completeTabs.includes(idx) }
        onClick={ () => onChange(idx) } />
    )}
  </div>
}

TabBar.propTypes = {
  activeTab: number.isRequired,
  onChange: func.isRequired,
  tabs: arrayOf(string).isRequired,
  completeTabs: arrayOf(number),
  enabledTabs: arrayOf(number)
}

TabBar.defaultProps = {
  enabledTabs: null,
  completeTabs: null
}

export default styled(TabBar)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`


import { t } from 'ttag'

import Forecast     from '../Forecast'
import Media        from '../Media'
import Targeting    from '../Targeting'
import CreativeSets from '../CreativeSets'
import LandingPages from '../LandingPages'

export default [{
  title: t`Budget`,
  name: 'forecast',
  component: Forecast
},{
  title: t`Media`,
  name: 'media',
  component: Media
},{
  title: t`Targeting`,
  name: 'targeting',
  component: Targeting
},{
  title: t`Ads`,
  name: 'ads',
  component: CreativeSets
},{
  title: t`Landing pages`,
  name: 'landing-pages',
  component: LandingPages
}]

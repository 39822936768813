import React      from 'react'
import { object } from 'prop-types'

import LeftColumn  from './LeftColumn'
import RightColumn from './RightColumn'

const VirtualStage = ({ project }) => {
  const snapshot = project.snapshots.find((element) => element.saleState === 'coming_for_sale')

  return (
    <div className='virtual-stage'>
      <LeftColumn snapshot={ snapshot } unitSet={ snapshot.unitSets[0] } />
      <RightColumn unitSet={ snapshot.unitSets[0] } projectCurrency={ project.currency } />
    </div>
  )
}

VirtualStage.propTypes = {
  project: object.isRequired
}

export default VirtualStage

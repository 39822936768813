import gql from 'graphql-tag'

import audience from 'graphql/fragments/audience'

export default gql`
  mutation CloneAudience($channelUuid: ID!, $uuid: ID!) {
    cloneAudience(channelUuid: $channelUuid, uuid: $uuid) {
      audience {
        ...Audience
      }
    }
  }
  ${audience}
`

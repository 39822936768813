import React  from 'react'

export { default as LocationOperatorIcon } from './LocationOperatorIcon'
export { default as MapMarkerIcon } from './MapMarkerIcon'

export const IconArchive = () => <span className="icon-archive"/>

export const IconTable = () => <span className="icon-table"/>

export const IconMarketer = () => <span className="icon-marketer"/>

export const IconEmptyImage = () => <span className="icon-empty-image"/>

export const IconShape = () => <span className="icon-shape"/>

export const IconPath = () => <span className="icon-path"/>

export const IconEdit = () => <span className="icon-edit"/>

export const IconClose = () => <span className="icon-close"/>

export const IconMail = () => <span className="icon-mail"/>

export const IconPhone = () => <span className="icon-phone"/>

export const IconBuilding = () => <span className="icon-building"/>

export const IconUserMono = () => <span className="icon-user1"/>

export const IconCase = () => <span className="icon-case"/>

export const IconLetterHeart = () => (
  <span className="icon-letter-heart">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconZoomIn = () => (
  <span className="icon-zoom-plus">
    <span className="path1" />
    <span className="path2" />
    <span className="path3" />
  </span>
)

export const IconZoomOut = () => (
  <span className="icon-zoom-minus">
    <span className="path1" />
    <span className="path2" />
    <span className="path3" />
  </span>
)

export const IconTrash = () => (
  <span className="icon-trash">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconTwoTables = () => (
  <span className="icon-two-tables">
    <span className="path1" />
    <span className="path2" />
    <span className="path3" />
    <span className="path4" />
    <span className="path5" />
    <span className="path6" />
    <span className="path7" />
    <span className="path8" />
    <span className="path9" />
    <span className="path10" />
    <span className="path11" />
  </span>
)

export const IconInvoice = () => (
  <span className="icon-invoice">
    <span className="path1" />
    <span className="path2" />
    <span className="path3" />
    <span className="path4" />
    <span className="path5" />
  </span>
)

export const IconLetterShadow = () => (
  <span className="icon-letter-shadow">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconDashboard = () => (
  <span className="icon-dashboard">
    <span className="path1"/>
    <span className="path2"/>
  </span>
)

export const IconClipboardList = () => (
  <span className="icon-clipboard-list">
    <span className="path1"/>
    <span className="path2"/>
    <span className="path3"/>
    <span className="path4"/>
    <span className="path5"/>
    <span className="path6"/>
    <span className="path7"/>
    <span className="path8"/>
  </span>
)

export const IconFolderThunder = () => (
  <span className="icon-folder-thunder">
    <span className="path1"/>
    <span className="path2"/>
  </span>
)

export const IconGroup = () => (
  <span className="icon-group">
    <span className="path1"/>
    <span className="path2"/>
  </span>
)

export const IconHomeHeart = () => (
  <span className="icon-home-heart">
    <span className="path1"/>
    <span className="path2"/>
  </span>
)

export const IconMailNotification = () => (
  <span className="icon-mail-notification">
    <span className="path1"/>
    <span className="path2"/>
  </span>
)

export const IconNotification = () => (
  <span className="icon-notification" />
)

export const IconPosition = () => (
  <span className="icon-position">
    <span className="path1" />
    <span className="path2" />
    <span className="path3" />
  </span>
)

export const IconSelectedFile = () => (
  <span className="icon-selected-file">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconThunderMove = () => (
  <span className="icon-thunder-move">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconShieldThunder = () => (
  <span className="icon-shield-thunder">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconTools = () => (
  <span className="icon-tools">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconFolderUser = () => (
  <span className="icon-folder-user">
    <span className="path1" />
    <span className="path2" />
    <span className="path3" />
  </span>
)

export const IconUser = () => (
  <span className="icon-user">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconChat = () => (
  <span className="icon-chat">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconFilter = () => (
  <span className="icon-filter" />
)

export const IconQuestion = () => (
  <span className="icon-question">
    <span className="path1" />
    <span className="path2" />
    <span className="path3" />
  </span>
)

export const IconSuccess = () => (
  <span className="icon-success">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconChart = () => (
  <span className="icon-chart">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconEarth = () => (
  <span className="icon-earth" />
)

export const IconMailLightning = () => (
  <span className="icon-mail-lightning">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconRobots = () => (
  <span className="icon-robots">
    <span className="path1" />
    <span className="path2" />
  </span>
)

export const IconStack = () => (
  <span className="icon-stack">
    <span className="path1" />
    <span className="path2" />
    <span className="path3" />
  </span>
)

import { gql } from 'apollo-boost'

import campaign from 'graphql/fragments/campaign'

export default gql`
  mutation createCustomAudienceLocation(
    $audienceUuid: ID!
    $latitude: Float!
    $longitude: Float!
    $operator: String!
    $locationType: String!
    $radius: Float
    $country: String
    $placeName: String
  ) {
    createCustomAudienceLocation(
      audienceUuid: $audienceUuid
      latitude: $latitude
      longitude: $longitude
      radius: $radius
      operator: $operator
      country: $country
      placeName: $placeName
      locationType: $locationType
    ) {
      audienceLocation {
        uuid
        latitude
        longitude
        radius
        operator
        country
        placeName
        title
        locationType
      }
      campaign {
        ... Campaign
      }
    }
  }
  ${campaign}
`

import React from 'react'
import { t } from 'ttag'
import { func } from 'prop-types'

import leadType from 'shared/types/leadType'
import { localDateTime } from 'shared/helpers/date'
import Button from 'shared/components/ui/Button'
import { IconTwoTables, IconMail, IconPhone } from 'shared/components/ui/icons'
import formatUnits from 'shared/helpers/stages/unitsSummary'

import {
  Container,
  MainInfo,
  AdditionalInfo,
  Section,
  InfoLabel,
  InfoValue
} from './elements'

const Header = ({ lead, onDelete, onStartEdit }) => {
  return (
    <Container>
      <MainInfo>
        <div className="d-flex justify-content-between">
          <h2>{ lead.fullName }</h2>
          <div>
            <Button
              onClick={ onDelete }
              data-test-id='delete-lead-button'
              className='mr-2'
            >
              { t`Delete lead` }
            </Button>
            <Button
              onClick={ onStartEdit }
              data-test-id='update-lead-details-button'
            >
              { t`Update details` }
            </Button>
          </div>
        </div>

        <h3>{ lead.description || t`Lead` }</h3>
      </MainInfo>
      <AdditionalInfo data-test-id='lead-additional-info'>
        { lead.project && (
          <Section>
            <InfoLabel>{ t`Project` }</InfoLabel>
            <InfoValue>
              <IconTwoTables />
              <span>{ lead.project.name }</span>
            </InfoValue>
          </Section>
        )}

        { lead.stage && (
          <Section>
            <InfoLabel>{ t`Stage` }</InfoLabel>
            <InfoValue>
              <span>{ lead.stage.name }</span>
            </InfoValue>
          </Section>
        )}

        { lead.units.length > 0 && (
          <Section>
            <InfoLabel>{ t`Units` }</InfoLabel>
            <InfoValue>
              <span>{ formatUnits(lead.units.map(el => el.unitId)) }</span>
            </InfoValue>
          </Section>
        )}

        <Section>
          <InfoLabel>{ t`Mail` }</InfoLabel>
          <InfoValue>
            <IconMail />
            <a href={ `mailto:${ lead.email }` }>{ lead.email }</a>
          </InfoValue>
        </Section>

        <Section>
          <InfoLabel>{ t`Phone` }</InfoLabel>
          <InfoValue>
            <IconPhone />
            <span>{ lead.phoneFormatted }</span>
          </InfoValue>
        </Section>

        <Section>
          <InfoLabel>{ t`Address` }</InfoLabel>
          <InfoValue>
            <span>{ lead.address }</span>
          </InfoValue>
        </Section>

        <Section>
          <InfoLabel>{ t`Created` }</InfoLabel>
          <InfoValue>
            <span>{ localDateTime(lead.createdAt) }</span>
          </InfoValue>
        </Section>
      </AdditionalInfo>
    </Container>
  )
}

Header.propTypes = {
  lead: leadType.isRequired,
  onDelete: func.isRequired,
  onStartEdit: func
}

Header.defaultProps = {
  onStartEdit: () => {}
}

export default Header

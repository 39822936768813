import React from 'react'
import { func, string } from 'prop-types'

import BasePhoneCodeSelect from 'shared/components/PhoneCodeSelect'

const PhoneCodeSelect = ({ name, value, onChange }) => {
  const setFieldValue = (value) => {
    onChange({ target: { name, value } })
  }

  return (
    <BasePhoneCodeSelect
      value={ value }
      onChange={ setFieldValue }
    />
  )
}

PhoneCodeSelect.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  value: string.isRequired
}

export default PhoneCodeSelect

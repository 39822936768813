import * as Yup from 'yup'
import { t }    from 'ttag'

const EIENDOM_DOMAIN = 'eiendom.no'
const NEWBUILDS_DOMAIN = 'newbuilds.com'
const DOMAINS_WITH_PATH_VALIDATION = [
  EIENDOM_DOMAIN,
  NEWBUILDS_DOMAIN
]

const parseURL = (url) => {
  try {
    return new URL(url)
  } catch {
    return null
  }
}

export default Yup.array().
  min(1, t`Please add at least 1 landing page.`).
  of(
    Yup.object().shape({
      custom: Yup.boolean(),
      portal: Yup.boolean(),

      name: Yup.string().required(t`Please fill in a name.`).nullable(),

      url:  Yup
        .string()
        .required(t`Please fill in an URL.`)
        .url(t`Please enter a valid URL`)
        .nullable()
        .test({
          test: function(url) {
            const parsedUrl = parseURL(url)

            if (DOMAINS_WITH_PATH_VALIDATION.includes(parsedUrl?.hostname?.replace('www.',''))) {
              return parsedUrl.pathname.replace('/', '') !== ''
            } else {
              return true
            }
          },
          message: t`URL path is invalid.`
        }),

      intro: Yup.string().nullable().when(['custom', 'portal'], {
        is: (custom, portal) => !(portal || custom),
        then: Yup.string().required(t`Please update landing page content.`).nullable()
      }),

      unassignedCreativeSets: Yup.array().
        max(0, t`Please connect all creative sets to a landing page.`),

      template: Yup.object().nullable().when(['custom', 'portal'], {
        is: (custom, portal) => !(portal || custom),
        then: Yup.object().required(t`Please select a template.`).nullable()
      })
    })
  )

const errorsToObject = (validationErrors, force = null, fullPath = true) => (
  validationErrors.reduce((obj, err) => {
    let key = err.path

    if (!fullPath) {
      const pieces = err.path.split('.')
      key = pieces[pieces.length-1]
    }

    obj[key] = {
      errorMessage: err.message,
      force: force !== null ? force: err.force
    }
    return obj
  }, {})
)

export default errorsToObject

import React, { useEffect, useState } from 'react'
import { t }               from 'ttag'
import { array, func }     from 'prop-types'
import { debounce }        from 'lodash'

import { debounceTimeout } from 'shared/constants'
import MultiSelect         from 'shared/components/ui/MultiSelect/MultiSelect'

import Option         from './Option'
import ValueContainer from './ValueContainer'
import Styles         from './Styles'

const components = { ValueContainer, Option }

const invokeDebounced = debounce(
  (onChange, value) => onChange(value),
  debounceTimeout
)

const Filter = ({ options, selectedOptions, onChange, ...props }) => {
  const [currentOptions, setCurrentOptions] = useState(selectedOptions)

  useEffect(() => {
    if (selectedOptions === currentOptions) return

    setCurrentOptions(selectedOptions)
  }, [selectedOptions])

  const values = currentOptions.map((value) => ({
    value: value, key: value
  }))

  const handleChange = (selected) => {
    const optionsValues = selected.map((option) => option.value)
    invokeDebounced(onChange, optionsValues)
    setCurrentOptions(optionsValues)
  }

  return (
    <MultiSelect
      styles={ Styles }
      hideSelectedOptions={ false }
      value={ values }
      placeholder={ t`Filter` }
      noOptionsMessage={ () => t`No options` }
      onChange={ handleChange }
      components={ components }
      options={ options }
      { ...props }
    />
  )
}

Filter.propTypes = {
  onChange: func.isRequired,
  options: array.isRequired,
  selectedOptions: array.isRequired
}

export default Filter

import columns from './columns'

const SIZE_PER_PAGE = 5

const defaultSorted = [{
  dataField: 'totalImpressions',
  order: 'desc'
}]

export default function useAdsTable(ads, campaign) {
  let creativeSetGroupIndex = 0
  let creativeSetGroup
  const data = ads.map((ad) => {
    if (ad.creativeSet?.name === creativeSetGroup?.name) {
      creativeSetGroupIndex++
    } else {
      creativeSetGroupIndex = 0
      creativeSetGroup = ad.creativeSet
    }

    let banners
    if (ad.creativeSet.banners.length > 0) {
      banners = [ad.creativeSet.banners[creativeSetGroupIndex % ad.creativeSet.banners.length]]
    }

    return {
      ...ad,
      ...ad.summary,
      banners,
      campaign: campaign,
    }
  })

  const tableOptions = {
    data,
    columns,
    defaultSorted,
    bordered: false,
    keyField: 'uuid',
    remote: { pagination: false },
  }

  const paginationOptions = data.length > SIZE_PER_PAGE ? ({
    sizePerPage: SIZE_PER_PAGE,
    totalSize: data.length,
  }) : null

  return {
    tableOptions,
    paginationOptions
  }
}

import gql from 'graphql-tag'

import agent from 'graphql/fragments/agent'

export default gql`
  mutation updateAgent(
    $agentUuid: ID!
    $projectUuid: ID!
    $input: AgentInput!
  ) {
    updateAgent(
      agentUuid: $agentUuid
      projectUuid: $projectUuid
      input: $input
    ) {
      agent {
        ... Agent
      }
    }
  }
  ${agent}
`

import React from 'react'

import AdsTable from './AdsTable'

const Ads = (props) => {
  return (
    <AdsTable { ...props } />
  )
}

export default Ads

import React from 'react'
import { t } from 'ttag'

import LoadingBlock  from 'shared/components/ui/LoadingBlock'
import PageContainer from 'shared/components/NewAutoCampaign/CampaignForm/PageContainer'
import FormContainer from 'shared/components/NewAutoCampaign/CampaignForm/FormContainer'

const LoadingForm = () => {
  return (
    <PageContainer>
      <FormContainer>
        <h2 className="form-header">
          <LoadingBlock />
          {
            t`Loading, please wait.. Contact Marketer if Campaign form is not shown`
          }
        </h2>
      </FormContainer>
    </PageContainer>
  )
}

export default LoadingForm

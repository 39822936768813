import React  from 'react'
import styled from 'styled-components'
import { arrayOf, func, number, string } from 'prop-types'

import Tab from 'shared/components/ui/TabBar/Tab'

const TabBar = ({ tabs, activeTab, onChange, className }) => {
  return <div className={ `d-flex ${className}` }>
    {tabs.map((title, idx) =>
      <Tab
        key={ idx /* eslint-disable-line react/no-array-index-key */ }
        title={ title }
        showArrow={ idx < (tabs.length - 1) }
        active={ idx === activeTab }
        onClick={ () => onChange(idx) } />
    )}
  </div>
}

TabBar.propTypes = {
  activeTab: number.isRequired,
  onChange: func.isRequired,
  tabs: arrayOf(string).isRequired,
}

export default styled(TabBar)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  
  ${Tab} {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    
    &:last-child {
      border-right: none
    }
    
    .arrow-top {
      display: none
    }
    .arrow-bottom {
      display: none
    }
`

import React from 'react'
import { bool, func, string, object } from 'prop-types'
import { pick } from 'lodash'

import Modal from 'shared/components/ui/Modal'

import { Header } from './elements'
import Form from './Form'

const PostFormModal = ({
  post,
  title,
  show,
  state,
  onHide,
  onSubmit,
  handleAssetDelete,
  assetUpload
}) => {
  const handleSubmit = async (values, fullSave, publish) => {
    await onSubmit(values, fullSave, publish)
    if (fullSave) onHide(false)
  }

  const initialValues = {
    ...pick(
      post,
      'newsType',
      'titleDraft',
      'descriptionDraft',
      'callToActionTextDraft',
      'callToActionDestinationUrlDraft',
      'publishedAt'
    )
  }

  return (
    <Modal centered show={ show } onHide={ onHide }>
      <Modal.Header closeButton>
        <Header>{ title }</Header>
      </Modal.Header>

      <Form
        medias={ post?.media }
        initialValues={ initialValues }
        onSubmit={ handleSubmit }
        onAssetDelete={ handleAssetDelete }
        assetUpload={ assetUpload }
        state={ state }
      />
    </Modal>
  )
}

PostFormModal.propTypes = {
  assetUpload: func.isRequired,
  handleAssetDelete: func.isRequired,
  onSubmit: func.isRequired,
  show: bool.isRequired,
  title: string.isRequired,
  onHide: func,
  post: object,
  state: string
}

PostFormModal.defaultProps = {
  onHide: () => {},
  post: null,
  state: null
}

export default PostFormModal

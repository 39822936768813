import React   from 'react'
import { get } from 'lodash'
import { t }   from 'ttag'
import { useApolloClient } from 'react-apollo'
import { array, shape, string, func } from 'prop-types'

import Table         from 'shared/components/Stakeholders/Table'
import NewItemButton from 'shared/components/ui/NewItemButton'

import addStakeholder    from './addStakeholder'
import updateStakeholder from './updateStakeholder'
import deleteStakeholder from './deleteStakeholder'

const Stakeholders = ({ product, errors, validate }) => {
  const client = useApolloClient()

  const handleAddClick = () => (
    addStakeholder(client, product)
  )
  const revalidateOnUpdate = ({ data: { updateProductStakeholder: { stakeholder } } }) => {
    if (!validate) { return }
    validate({ updated: stakeholder })
  }
  const handleUserUpdate = ({ userUuid, stakeholderUuid }) => {
    updateStakeholder(client, product.uuid, stakeholderUuid, { userUuid }).
      then(revalidateOnUpdate)
  }
  const handleResponsibilityUpdate = ({ stakeholderUuid, responsibility }) => (
    updateStakeholder(client, product.uuid, stakeholderUuid, { responsibility }).
      then(revalidateOnUpdate)
  )
  const handlePermissionUpdate = ({ stakeholderUuid, permission }) => (
    updateStakeholder(client, product.uuid, stakeholderUuid, { permission })
  )
  const handleSettingsUpdate = ({ stakeholderUuid, name, enabled }) => (
    updateStakeholder(client, product.uuid, stakeholderUuid, { [name]: enabled })
  )
  const handleStakeholderDelete = ({ stakeholderUuid }) => (
    deleteStakeholder(client, product, stakeholderUuid)
  )

  return (
    <>
      <Table
        stakeholders={ product.stakeholders }
        companyUuid={ get(product, 'company.uuid') }
        errors={ errors }
        validate={ validate }
        onUserUpdate={ handleUserUpdate }
        onResponsibilityUpdate={ handleResponsibilityUpdate }
        onPermissionUpdate={ handlePermissionUpdate }
        onSettingsUpdate={ handleSettingsUpdate }
        onStakeholderDelete={ handleStakeholderDelete }
      />

      <NewItemButton onClick={ handleAddClick } data-test-id="new-stakeholder-button" >
        { t`Add new stakeholder` }
      </NewItemButton>
    </>
  )
}

Stakeholders.propTypes = {
  product: shape({
    __typename: string.isRequired,
    stakeholders: array.isRequired,
    uuid: string.isRequired
  }).isRequired,
  errors: array,
  validate: func
}

Stakeholders.defaultProps = {
  errors: [],
  validate: undefined
}

export default Stakeholders

import React from 'react'
import { t } from 'ttag'

import { formatNumberWithLocale } from 'shared/helpers/number'
import { formatDateForReport }    from 'shared/helpers/date'
import allUnitTypes               from 'shared/constants/buildingTypes'
import { unitStates }             from 'shared/constants/unitStates'
import { lightGreyColor }         from 'shared/style/colors'

import Checkbox from './Checkbox'
import InlineButton from './InlineButton'

const UnitFormatter = (value, _unit) => {
  if (value === undefined || value === null) return null

  const labelValue = allUnitTypes.find((element) => {
    return element.value === value.toLowerCase()
  })

  if (labelValue === undefined) return <div>{ value }</div>

  return <div>{ labelValue.label }</div>
}

const StatusFormatter = (value, _unit) => unitStates[value]

const CheckboxFormatter = (value, _unit) => <Checkbox value={ value } />

const SizeFormatter = (value, _unit) => {
  if (value) return <div>{ value } m<sup>2</sup></div>

  return null
}

const PriceFormatter = (value, _unit) => <div>{ formatNumberWithLocale(value) }</div>

const DateFormatter = (value, _unit) => <div>{ formatDateForReport(value) }</div>

const DocumentsFormatter = (value, unit) => {
  if (value === 0) return <span style={{ color: lightGreyColor }}>{ t`No documents` }</span>

  return <InlineButton quantity={ value } unit={ unit } />
}

const columnsConfiguration = (opts) => {
  const config = [
    {
      text: '',
      dataField: 'uuid',
      hidden: true
    },
    {
      text: t`Unit`,
      dataField: 'unitId'
    },
    {
      text: t`Rooms`,
      dataField: 'roomCount'
    },
    {
      text: t`Type`,
      dataField: 'unitType',
      formatter: UnitFormatter
    },
    {
      text: t`Usable area`,
      dataField: 'usableAreaM2',
      formatter: SizeFormatter
    },
    {
      text: t`Livable area`,
      dataField: 'livableAreaM2',
      formatter: SizeFormatter
    },
    {
      text: t`Price`,
      dataField: 'normalizedPriceWithCollectiveDebt',
      formatter: PriceFormatter
    },
    {
      text: t`Status`,
      dataField: 'state',
      formatter: StatusFormatter
    },
    {
      text: t`Reservations`,
      dataField: 'reservations',
      formatter: CheckboxFormatter
    },
    {
      text: t`Sold date`,
      dataField: 'soldAt',
      formatter: DateFormatter
    }
  ]

  if (opts.hideDocumentsColumn) return config

  config.push({
    text: '',
    dataField: 'vitecDocumentsCount',
    formatter: DocumentsFormatter
  })

  return config
}

export default columnsConfiguration

import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'
import { t } from 'ttag'
import { arrayOf, bool, string, shape, func } from 'prop-types'

import { isUserAdmin }         from 'shared/helpers/auth'
import { channelPresentation } from 'shared/constants/channelPresentation'
import { altoGreyColor, greyishBrownColor } from 'shared/style/colors'

import AddNewButton from './AddNewButton'

const NewCreativeSetView = ({ className, channels, dynamicCreativesEnabled, onChannelSelect }) => {
  const createChannelSelectHandler = channelType => (
    useCallback(() => onChannelSelect(channelType), [channelType])
  )

  const renderOptions = () => {
    return channels.map(({ type, enabled }) => {
      switch(type) {
      case 'facebook':
        return (
          <div key='facebookAdd'>
            { renderOption('facebook', enabled) }
            { renderOption('facebook_lead_ad', enabled) }
            { dynamicCreativesEnabled && renderOption('facebook_dynamic_creative', enabled) }
          </div>
        )
      case 'gmp':
        return (
          <div key='gmpAdd'>
            { renderOption('gmp', enabled) }
            { renderOption('predefined', enabled) }
          </div>
        )
      default:
        return renderOption(type, enabled)
      }
    })
  }

  const renderOption = (type, enabled) => {
    if (!isUserAdmin() && type === 'portal') return

    return (
      <Dropdown.Item
        key={ type } onClick={ createChannelSelectHandler(type) }
        data-test-id='channel-item'
        disabled={ !enabled }
      >
        { channelPresentation[type] }
      </Dropdown.Item>
    )
  }

  return (
    <Dropdown className={ className }>
      <Dropdown.Toggle as={ AddNewButton } />

      <Dropdown.Menu>
        <Dropdown.Item disabled>
          { t`Select channel` }
        </Dropdown.Item>
        { renderOptions() }
      </Dropdown.Menu>
    </Dropdown>
  )
}

NewCreativeSetView.propTypes = {
  channels: arrayOf(shape({
    type: string.isRequired,
    title: string.isRequired,
    enabled: bool.isRequired
  })).isRequired,
  dynamicCreativesEnabled: bool.isRequired,
  onChannelSelect: func.isRequired
}

export default styled(NewCreativeSetView)`
  padding: .25rem 0 0

  .dropdown-item {
    color: ${greyishBrownColor}
    font-size: .875rem;
    padding: .375rem
  }

  .dropdown-item.disabled {
    color: ${altoGreyColor}
    padding: 0 .375rem
  };

  .dropdown-item:active {
    background-color: #FFF;
    color: ${greyishBrownColor}
  }
`

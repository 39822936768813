import React, { useRef } from 'react'
import styled from 'styled-components'
import { Slider as ReactCompoundSlider, Handles, Tracks } from 'react-compound-slider'
import { array, func, number, string } from 'prop-types'

import Handle from './Handle'
import HandleValues from './HandleValues'
import Track from './Track'

const Slider = ({ className, domain, step, value, suffix, onChange }) => {
  const railRef = useRef()

  const handleSlideStart = () => {
    railRef.current.focus()
  }

  return (
    <ReactCompoundSlider
      className={ className }
      domain={ domain }
      values={ value }
      step={ step }
      mode={ 2 }
      onChange={ onChange }
      onSlideStart={ handleSlideStart }
    >
      <div className='slider-rail' tabIndex={ 10 } ref={ railRef } />

      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map(handle => (
              <Handle
                key={ `handle-${handle.id}` }
                handle={ handle }
                getHandleProps={ getHandleProps }
              />
            ))}
            <HandleValues handles={ handles } suffix={ suffix } />
          </div>
        )}
      </Handles>

      <Tracks left={ value.length === 1 } right={ false }>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <Track
                key={ id }
                source={ source }
                target={ target }
                getTrackProps={ getTrackProps }
              />
            ))}
          </div>
        )}
      </Tracks>
    </ReactCompoundSlider>
  )
}

Slider.propTypes = {
  domain: array.isRequired,
  value: array.isRequired,
  onChange: func,
  step: number,
  suffix: string,
}

Slider.defaultProps = {
  step: 1,
  suffix: undefined,
  onChange: () => {}
}

export default styled(Slider)`
  position: relative;
  height: 3rem;
  width: 100%;

  .slider-rail {
    position: absolute;
    width: 100%;
    height: 0.5rem;
    margin-top: 0.8rem;
    border-radius: 4px;
    background-color: #dadada;
    outline: none;
  }
`

import gql from 'graphql-tag'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  fragment Agent on Agent {
    uuid
    name
    nameDraft
    phoneNumber
    phoneNumberDraft
    email
    emailDraft
    title
    titleDraft
    position
    assetAssignments {
      ... AssetAssignment
    }
  }
  ${assetAssignment}
`

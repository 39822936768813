import { gql } from 'apollo-boost'

export default gql`
  query CustomAudiences($campaignUuid: ID!, $query: String) {
    googleCustomAudiences(campaignUuid: $campaignUuid, query: $query) {
      id
      name
      subtype
    }
  }
`

import React   from 'react'
import Select  from 'react-select'
import { get } from 'lodash'
import { t }   from 'ttag'
import { oneOfType, string, number, func } from 'prop-types'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    marginRight: '0.5rem',
    minWidth: '5rem'
  })
}

const NewsTypeSelect = ({ value, onChange, ...props }) => {
  const allOptions = [
    { label: t`General/social update`, value: 'general' },
    { label: t`Achieved sales rate`, value: 'sales_rate' },
    { label: t`Construction start`, value: 'construction_start' },
    { label: t`Regulation decision`, value: 'regulation' },
    { label: t`Information regarding options`, value: 'info_options' },
    { label: t`Information regarding area development / neighborhood`, value: 'info_area' },
    { label: t`New sales phases`, value: 'new_sales' },
    { label: t`New project photos`, value: 'new_photos' }
  ]
  const selectedOption = allOptions.find(el => el.value === value)
  const handleChange = option => onChange(get(option, 'value', null))

  return (
    <div className='news-type-select'>
      <Select
        value={ selectedOption }
        options={ allOptions }
        onChange={ handleChange }
        isDisabled={ false }
        isSearchable={ false }
        placeholder=''
        noOptionsMessage={ () => t`No options` }
        styles={ styles }
        { ...props }
      />
    </div>
  )
}

NewsTypeSelect.propTypes = {
  onChange: func,
  value: oneOfType([string, number])
}

NewsTypeSelect.defaultProps = {
  onChange: () => {},
  value: ''
}

export default NewsTypeSelect

import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { t } from 'ttag'

import ThriftyInput from 'shared/components/ui/ThriftyInput'
import BigNumberInput from 'shared/components/ui/ThriftyInput/BigNumber'
import landingPageType from 'shared/types/landingPageType'

import { SectionHeader } from '../elements'

const FlatFinder = ({ landingPage, onUpdate, className }) => {
  return (
    <div className={ className }>
      <div className="url">
        <SectionHeader>{ t`URL` }</SectionHeader>
        <ThriftyInput
          value={ landingPage.flatFinderUrl }
          onUpdate={ flatFinderUrl => onUpdate({ flatFinderUrl }) }
          data-test-id='flat-finder-url-input'
        />
      </div>
      <div className="height">
        <SectionHeader>{ t`Height` } (px)</SectionHeader>
        <BigNumberInput
          value={ landingPage.flatFinderHeight }
          onUpdate={ flatFinderHeight => onUpdate({ flatFinderHeight }) }
          data-test-id='flat-finder-height-input'
        />
      </div>
    </div>
  )
}

FlatFinder.propTypes = {
  landingPage: landingPageType.isRequired,
  onUpdate: func.isRequired
}

export default styled(FlatFinder)`
  display: flex;

  .url {
    flex-grow: 1;
  }

  .height {
    margin-left: 1rem;
    width: 10.5rem;
  }
`

import { gql } from 'apollo-boost'

export default gql`
  mutation removeAsset(
    $uuid: ID!
    $totalRemove: Boolean
  ) {
    removeAsset(
      uuid: $uuid
      totalRemove: $totalRemove
    ) {
      asset {
        uuid
      }
    }
  }
`

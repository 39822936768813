import { gql } from 'apollo-boost'

import landingPage from 'graphql/fragments/landingPage'

export default gql`
  mutation populateCampaignTexts($uuid: ID!) {
    populateCampaignTexts(uuid: $uuid) {
      creativeSets {
        uuid
        name
        title
        description
        content
        color
        adsCount
      }
      landingPages {
        ... LandingPage
      }
      errors
    }
  }
  ${landingPage}
`

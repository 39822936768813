import React from 'react'

import matchType from 'shared/types/matchType'
import NewStage from 'shared/components/NewStage'

const StageFormPage = ({ match }) => <NewStage uuid={ match.params.uuid } />

StageFormPage.propTypes = {
  match: matchType.isRequired
}

export default StageFormPage

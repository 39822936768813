import gql from 'graphql-tag'

import lead from 'graphql/fragments/lead'
import activityLog from 'graphql/fragments/leadActivityLog'

export default gql`
  mutation UpdateLead(
    $uuid: ID!
    $input: CampaignLeadInput!
  ) {
    updateLead(
      uuid: $uuid
      input: $input
    ) {
      lead {
        ... Lead
        ... ActivityLog
        address
        project {
          uuid
          name

          product {
            uuid
            company {
              uuid
            }
          }
        }
        stage {
          uuid
          name
        }

        units {
          uuid
          unitId
        }
      }
    }
  }
  ${lead}
  ${activityLog}
`

import styled from 'styled-components'

import { cardBoxShadow } from 'shared/style/placeholders'
import { componentBackgroundColor } from 'shared/style/colors'

export const Container = styled.div`
  ${cardBoxShadow};

  background-color: ${componentBackgroundColor};
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;
`

export const UserSelectContainer = styled.div`
  width: 13rem;
`

export const LeadQualityScoreContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: .5rem;

  > label {
    font-size: 0.825rem;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: .5rem;
  }
`

export const ContactInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  > label {
    font-size: 0.825rem;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: .5rem;
  }
`


import React from 'react'
import { t } from 'ttag'
import Select from 'react-select'
import { object } from 'prop-types'
import styled from 'styled-components'
import { useMutation } from 'react-apollo'

import { buttonTextColor } from 'shared/style/colors'
import facingDirections from 'shared/constants/facingDirections'
import FormLabel from 'shared/components/ui/FormLabel'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import updateStageUnitBalconyMutation
  from 'graphql/mutations/projects/stages/balconies/updateStageUnitBalconyMutation'

const StageUnitBalcony = ({ balcony, className }) => {
  const [runUpdateBalconyMutation] = useMutation(updateStageUnitBalconyMutation)
  const handleUpdate = (fieldName, value) => {
    const data = { [fieldName]: value }
    return runUpdateBalconyMutation({
      variables: {
        uuid: balcony.uuid,
        input: data
      }
    })
  }

  const getSelectedItem = (selectedValue) => {
    return facingDirections.find((val) => (val.value === selectedValue ))
  }

  return (
    <div className={ className }>
      <span className='balcony-name'>{ balcony.name }</span>
      <div className='form-inline'>
        <div className='group w-50'>
          <FormLabel>{ t`Size` }</FormLabel>
          <div className="controls-with-status">
            <div className="controls">
              <InputWrapper className='w-100 pr-2'>
                <ThriftyInput
                  className='w-100'
                  type='number'
                  step='0.01'
                  value={ balcony.size }
                  onUpdate={ (value) => handleUpdate('size', parseFloat(value)) }
                />
              </InputWrapper>
            </div>
          </div>
        </div>
        <div className='group w-50'>
          <FormLabel>{ t`Facing direction` }</FormLabel>
          <div className="controls-with-status">
            <div className="controls">
              <InputWrapper className='w-100 pl-2'>
                <Select
                  value={ getSelectedItem(balcony.facingDirection) }
                  onChange={ (result) => handleUpdate('facingDirection', result.value) }
                  options={ facingDirections }
                />
              </InputWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

StageUnitBalcony.propTypes = {
  balcony: object.isRequired
}

export default styled(StageUnitBalcony)`
  padding: 0 0 1rem;

  .form-inline {
    justify-content: space-between;
    align-items: center;
  }

  input, textarea {
    box-shadow: none;
  }

  .balcony-name {
    font-size: .9rem;
    color: ${buttonTextColor};
  }
`

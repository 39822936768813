import { BlobUpload } from '@rails/activestorage/src/blob_upload'

const directUpload = ({ url, headers, file }) => {
  const upload = new BlobUpload({ file, directUploadData: { url, headers } })

  return new Promise((resolve, reject) => {
    upload.create(error => {
      error ? reject(error) : resolve()
    })
  })
}

const runDirectUpload = ({ data, file }) => {
  const {
    createDirectUpload: {
      directUpload: { url, headers, blobId, signedBlobId }
    }
  } = data

  return directUpload({
    url: url,
    headers: JSON.parse(headers),
    file: file,
  }).then(() => ({ blobId, signedBlobId }))
}

export default runDirectUpload

import gql from 'graphql-tag'

import landingPage from 'graphql/fragments/landingPage'

export default gql`
  mutation CreateLandingPage(
    $campaignUuid: ID!
    $input: CampaignLandingPageInput!
  ) {
    createLandingPage(
      campaignUuid: $campaignUuid
      input: $input
    ) {
      landingPage {
        ... LandingPage
      }
    }
  }
  ${landingPage}
`

import { gql } from 'apollo-boost'

export default gql`
  mutation UpdateProject(
    $uuid: ID!
    $districtUuid: ID
    $input: ProjectInput!
    $location: LocationInput
    $companies: [CompanyAttributes!]
  ) {
    updateProject(
      uuid: $uuid
      districtUuid: $districtUuid
      location: $location
      input: $input
      companies: $companies
    ) {
      project {
        uuid
        name
        sourceDescription
        customDescriptionDraft
        titleDraft
        linkDraft
        portalEnabledDraft
        autoDescriptionEnabled
        salesReportEnabled
        headlineDraft
        flatFinderUrl
        multistage
        saleStateDraft
        completeStages {
          uuid
          name
          autoDescriptionEnabled
          sourceDescription
          customDescriptionDraft
          titleDraft
          headlineDraft
          flatFinderUrl
          linkDraft
        }
      }
    }
  }
`

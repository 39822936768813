import * as Yup from 'yup'
import { t }    from 'ttag'

import {
  MAXIMUM_AMOUNT_OF_ADS_PER_AUDIENCE
} from '../CreativeSets/buildCreativeSetsValidationPayload'

const gmpChannelType = 'gmp'

export default Yup.array().
  min(1, t`Please add at least 1 creative set.`).
  of(
    Yup.object().shape({
      channel: Yup.object(),

      name: Yup.string().required(t`Please fill in a name.`).nullable(),
      unassignedAudiences: Yup.array().
        max(0, t`Please connect all audiences to the creative sets.`).label('UnassignedAudiences'),
      manyAdsAudiencesNames: Yup.string(),
      manyAdsAudiences: Yup.array().when(
        'manyAdsAudiencesNames', (manyAdsAudiencesNames, schema) => {
          const message
            = t`
                Following audiences have more than
                ${ MAXIMUM_AMOUNT_OF_ADS_PER_AUDIENCE }
                ads:
                ${ manyAdsAudiencesNames }
              `
          return schema.max(0, message)
        }
      ),
      creativeSetAudiences: Yup.array().
        min(0, t`Please select at least 1 audience.`),
      creatives: Yup.array().
        min(1, t`Please select at least 1 media.`).compact((creative) => !creative.enabled),
      color: Yup.string().nullable().when(
        'channel', (channel, schema) => {
          if (channel?.type !== gmpChannelType) return schema
          if (channel?.belongsToPropertyCampaign) return schema

          return schema.required(t`Please select color.`)
        }
      ),
      logo: Yup.string().nullable().when(
        'channel', (channel, schema) => {
          if (channel?.type !== gmpChannelType) return schema
          if (channel?.belongsToPropertyCampaign) return schema

          return schema.required(t`Please select logo.`)
        }
      )
    })
  )

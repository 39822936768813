import gql from 'graphql-tag'

import landingPage from 'graphql/fragments/landingPage'

export default gql`
  query CampaignLandingPage($uuid: ID!) {
    campaignLandingPage(uuid: $uuid) {
      ... LandingPage
    }
  }
  ${landingPage}
`

import React, { memo } from 'react'
import { t }           from 'ttag'
import styled          from 'styled-components'
import { get }         from 'lodash'
import { useMutation } from 'react-apollo'
import { string, array } from 'prop-types'

import updateParkingsMutation
  from 'graphql/mutations/projects/stages/units/parkings/updateMutation'
import stageUnitFragment       from 'graphql/fragments/stageUnit'
import { buildFragmentParams } from 'shared/helpers/graphql'

import NumberInput from './NumberInput'

const Parkings = ({ className, parkings, unitUuid }) => {
  const [runUpdateParkingsMutation] = useMutation(updateParkingsMutation)

  const handleChange = ({ key, attributes }) => {
    return runUpdateParkingsMutation({
      variables: {
        uuid: key,
        attributes: attributes
      },
      update: (cache, result) => {
        const object = { __typename: 'StageUnit', uuid: unitUuid }
        const fragmentParams = buildFragmentParams(object, stageUnitFragment)
        const fragmentData = cache.readFragment(fragmentParams)
        const newParkings = get(result, 'data.updateParkings.parkings')
        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            parkings: newParkings
          }
        })
      }
    })
  }

  return (
    <div className={ className }>
      {parkings.map((parking) => (
        <div key={ parking.uuid }>
          <label>{ t`Optional parking price` }</label>
          <NumberInput
            paramName='price'
            value={ parking.price }
            onChange={ handleChange }
            formKey={ parking.uuid }
          />
        </div>
      ))}
    </div>
  )
}

Parkings.propTypes = {
  unitUuid: string.isRequired,
  parkings: array
}

Parkings.defaultProps = {
  parkings: []
}

export default styled(memo(Parkings))`
  display: flex;

  > div {
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'ttag'

import FormLabel from 'shared/components/ui/FormLabel'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'

import PhaseDatePicker from './PhaseDatePicker'
import { defaultFormatter, formatWithEndOf } from './phaseDateFormatter'

const YearSelect = ({ dateChangeHandler, startDate }) => {
  const updateDate = (startDateValue) => {
    dateChangeHandler(
      defaultFormatter(startDateValue),
      formatWithEndOf(startDateValue, 'year')
    )
  }

  return(
    <>
      <FormLabel>{ t`Phase start` }</FormLabel>
      <InputWrapper>
        <PhaseDatePicker
          showYearPicker
          data-test-id='year-picker'
          selected={ startDate }
          onChange={ updateDate }
          dateFormat='yyyy' />
      </InputWrapper>
    </>
  )
}

YearSelect.propTypes = {
  dateChangeHandler: PropTypes.func.isRequired,
  startDate: PropTypes.object
}

YearSelect.defaultProps = {
  startDate: null
}

export default YearSelect

import React      from 'react'
import { string } from 'prop-types'
import { t }      from 'ttag'

import { timelineDateTime } from 'shared/helpers/date'

const AvatarRow = ({ url, createdAt }) => {
  return (
    <div className="avatar-row">
      <span className="avatar-url">
        <img src={ url } alt={ t`User avatar` } />
      </span>

      <span className="avatar-created-at">
        { timelineDateTime(createdAt) }
      </span>
    </div>
  )
}

AvatarRow.propTypes = {
  createdAt: string.isRequired,
  url: string.isRequired,
}

export default AvatarRow

import React, { useState } from 'react'
import { pick, get } from 'lodash'
import { t }         from 'ttag'
import styled        from 'styled-components'
import { string, shape, object, arrayOf, array, func } from 'prop-types'

import creativeSetType     from 'shared/types/creativeSetType'
import ValidatableWrapper  from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import { combineErrors }   from 'shared/helpers/inputValidation'
import { altoGreyColor, bluishColor, white, greyishBrownColor } from 'shared/style/colors'
import useCreativeSetData
  from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/useCreativeSetData'
import CreativeSetForm
  from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/CreativeSetForm'
import CreativeSetMedia
  from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/CreativeSetMedia'
import RemoveCreativeSet
  from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/RemoveCreativeSet'
import Preview
  from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/Preview'
import BannerPreviewSelect
  from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/BannerPreviewSelect'

/* eslint-disable max-len */
const PrevChevron = () => (
  <svg width="15" height="22" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="nonzero"
      fill="#4a4a4a"
      d="m 6.53575,10.87975 7.071,-7.071 c 0.781,-1.105 0.781,-2.047 0,-2.828 -0.78,-0.782 -1.724,-0.782 -2.828,0 l -9.9,9.899 9.9,9.9 c 0.943,0.941 1.884,0.941 2.828,0 0.943,-0.943 0.943,-1.886 0,-2.829 l -7.07,-7.071 z" />
  </svg>
)

const NextChevron = () => (
  <svg width="15" height="22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.657 11l-7.071 7.071c-.781 1.105-.781 2.047 0 2.828.78.782 1.724.782 2.828 0l9.9-9.899-9.9-9.9C3.471.159 2.53.159 1.586 1.1c-.943.943-.943 1.886 0 2.829L8.656 11z"
      fill="#4A4A4A"
      fillRule="nonzero" />
  </svg>
)
/* eslint-enable max-len */

const CreativeSet = (props) => {
  const {
    creativeSet,
    company,
    bannerTemplateCollections,
    audiences,
    className,
    facebookPageLogo,
    facebookPageName,
    onClone,
    ...restProps
  } = props
  const {
    assets,
    typeAudiences,
    previewAsset,
    setPreviewAsset,
    previewAssetMediaType,
    previewAssetIndex,
    previewBanner,
    banners,
    bannerTemplateCollection,
    setBannerTemplateCollection,
    setPreviewBanner,
    previewFields,
    setPreviewField,
    uniqueAds,
    isGmp,
    isPredefined
  } = useCreativeSetData({ audiences, creativeSet })

  const errorsOnAds = combineErrors({ errors: props.errors, uuid: creativeSet.uuid, field: 'ads' })

  const [gmpContentErrors] = useState([])

  const handleClone = () =>
    onClone(creativeSet)

  const setPreviousAsset = () => {
    if (previewAssetIndex === 0) {
      setPreviewAsset(assets[assets.length - 1])
      return
    }

    setPreviewAsset(assets[previewAssetIndex - 1])
  }

  const setNextAsset = () => {
    if (previewAssetIndex === assets.length - 1) {
      setPreviewAsset(assets[0])
      return
    }

    setPreviewAsset(assets[previewAssetIndex + 1])
  }

  const campaign = restProps.campaign
  const campaignProperty = campaign.property

  const promotableType = campaign.promotableType

  const showBannerPreviewSelector = isGmp && !isPredefined

  return (
    <div className={ className }>
      <aside className="creative-set--side-bar">

        <CreativeSetForm
          adsCount={ creativeSet.adsCount }
          setPreviewField={ setPreviewField }
          allAudiences={ typeAudiences }
          channelType={ creativeSet.channel.type }
          isPredefined={ isPredefined }
          gmpContentErrors={ gmpContentErrors }
          bannerTemplateCollections={ bannerTemplateCollections }
          bannerTemplateCollection={ bannerTemplateCollection }
          setBannerTemplateCollection={ setBannerTemplateCollection }
          company={ company }
          creativeSet={ creativeSet }
          { ...pick(creativeSet, ['uuid', 'name', 'audiences', 'title', 'content', 'description']) }
          { ...restProps }
        />

        <ValidatableWrapper error={ errorsOnAds } >
          <CreativeSetMedia
            creativeSet={ creativeSet }
            previewAsset={ previewAsset }
            setPreviewAsset={ setPreviewAsset }
            { ...restProps }
          />
        </ValidatableWrapper>

        <Actions>
          <RemoveCreativeSet uuid={ creativeSet.uuid }/>

          <CloneButton
            type="button"
            className="btn btn-info"
            onClick={ handleClone }
            data-test-id="clone-creative-set"
          >
            {t`Duplicate`}
          </CloneButton>
        </Actions>

      </aside>

      <div className="preview-container">
        { showBannerPreviewSelector && (
          <BannerPreviewSelect
            usedBanners={ creativeSet.banners }
            previewBanner={ previewBanner }
            setPreviewBanner={ setPreviewBanner }
          />
        ) }

        <div className="preview-slider">
          { showBannerPreviewSelector && assets.length > 1
            && <button
              type="button"
              className="preview-nav preview-prev"
              onClick={ setPreviousAsset }>
              <PrevChevron />
            </button>
          }
          { showBannerPreviewSelector && assets.length > 1
            && <button type="button" className="preview-nav preview-next" onClick={ setNextAsset }>
              <NextChevron />
            </button>
          }

          <Preview
            property={ campaignProperty }
            location={ campaignProperty.location }
            promotableType={ promotableType }
            banners={ banners }
            previewAsset={ previewAsset }
            previewAssetMediaType={ previewAssetMediaType }
            previewBanner={ previewBanner || creativeSet.banners[0] }
            previewWithBanners={ creativeSet.previewWithBanners }
            uniqueAds={ uniqueAds }
            company={ company }
            campaign={ campaign }
            companyName={ company.name }
            color={ creativeSet.color }
            logo={ creativeSet.logo }
            type={ get(creativeSet, 'channel.type') }
            facebookPageName={ facebookPageName }
            facebookPageLogo={ facebookPageLogo }
            assets={ assets }
            address={ campaignProperty ? campaignProperty.location.address : null }
            city={ campaignProperty ? campaignProperty.location.city : null }
            bannerTemplateCollection={ bannerTemplateCollection }
            { ...previewFields }
          />
        </div>
      </div>

    </div>
  )
}

CreativeSet.propTypes = {
  audiences: arrayOf(shape({
    type: string,
    name: string,
    uuid: string
  })).isRequired,
  bannerTemplateCollections: arrayOf(shape({
    name: string,
    uuid: string
  })).isRequired,
  company: object.isRequired,
  creativeSet: creativeSetType.isRequired,
  errors: array,
  facebookPageLogo: string,
  facebookPageName: string,
  onClone: func
}

CreativeSet.defaultProps = {
  errors: [],
  facebookPageLogo: null,
  facebookPageName: null,
  onClone: () => { }
}

const Actions = styled.div`
  bottom: 1.5rem;
  display: inline-block;
  font-size: .75rem;
  position: absolute;

  button {
    margin-left: 0.5rem;
  }
`

const CloneButton = styled.button`
  background-color: ${bluishColor};
  color: ${white};
`

export default styled(CreativeSet)`
  border-radius: 0.625rem;
  border: solid 2px ${altoGreyColor};
  display: flex;
  justify-content: space-between;
  padding: 1.875rem;
  position: relative

  .creative-set--side-bar {
    width: 60%;
    border-right: solid 2px ${altoGreyColor};
    padding-right: 1.875rem;
    padding-bottom: 1.875rem
  }

  .remove-creative-set {
    color: ${greyishBrownColor};
  }

  .preview-container {
    width: 37%;
  }

  .preview-slider {
    position: relative;
  }

  .preview-nav {
    position: absolute;
    top: 35%;
    background-color: transparent;
    border: none;
  }

  .preview-prev {
    left: -27px;
  }

  .preview-next {
    right: -27px;
  }
`

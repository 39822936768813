import React               from 'react'
import styled              from 'styled-components'
import { number }  from 'prop-types'
import { t }               from 'ttag'
import { useApolloClient } from 'react-apollo'

import agentsQuery         from 'graphql/queries/projects/agents'
import updateAgentMutation from 'graphql/mutations/projects/agents/update'
import removeAgentMutation from 'graphql/mutations/projects/agents/remove'
import ThriftyInput        from 'shared/components/ui/ThriftyInput'
import { buttonTextColor, galleryGreyColor, darkRoseColor } from 'shared/style/colors'
import agentType           from 'shared/types/agentType'
import projectType         from 'shared/types/projectType'

import AgentImage from './AgentImage'

const EditForm = ({ agent, project, index }) => {
  const client = useApolloClient()

  const handleRemove = () => {
    client.mutate({
      mutation: removeAgentMutation,
      variables: { agentUuid: agent.uuid, projectUuid: project.uuid },
      refetchQueries: [
        {
          query: agentsQuery,
          variables: { projectUuid: project.uuid }
        }
      ]
    })
  }

  const handleSave = input => {
    client.mutate({
      mutation: updateAgentMutation,
      variables: { agentUuid: agent.uuid, input, projectUuid: project.uuid },
      refetchQueries: [
        {
          query: agentsQuery,
          variables: { projectUuid: project.uuid }
        }
      ]
    })
  }

  return (
    <Agent>
      <label className="agent-index">{ `${index}` }</label>
      <button
        type="button"
        className="agent-remove-button"
        onClick={ () => handleRemove() }>
        ✖
      </button>
      <Group>
        <label>{ t`Agent name` }</label>
        <ThriftyInput
          name="nameDraft"
          value={ agent.nameDraft }
          onUpdate={
            nameDraft => handleSave({ nameDraft })
          }
        />
      </Group>

      <Group>
        <label>{ t`Agent Phone Number` }</label>
        <ThriftyInput
          name="phoneNumberDraft"
          value={ agent.phoneNumberDraft }
          onUpdate={
            phoneNumberDraft => handleSave({ phoneNumberDraft })
          }
        />
      </Group>

      <Group>
        <label>{ t`Agent Email` }</label>
        <ThriftyInput
          name="emailDraft"
          value={ agent.emailDraft }
          onUpdate={
            emailDraft => handleSave({ emailDraft })
          }
        />
      </Group>

      <Group>
        <label>{ t`Agent title` }</label>
        <ThriftyInput
          name="titleDraft"
          value={ agent.titleDraft }
          onUpdate={
            titleDraft => handleSave({ titleDraft })
          }
        />
      </Group>

      <Group>
        <label>{ t`Agent Picture` }</label>
        <AgentImage
          agent={ agent }
          project={ project }
          label={ t`Agent Picture` }
          mediaType='image' />
      </Group>
    </Agent>
  )
}

const Group = styled.div`
  margin-bottom: 1rem;

  input {
    box-shadow: none;
  }

  label {
    color: ${buttonTextColor};
    font-size: 0.875rem;
    margin-right: .5rem;
  }
`

const Agent = styled.div`
  border-radius: 0.5rem;
  border: 2px solid ${galleryGreyColor};
  padding: 1rem;
  margin-bottom: 2rem;
  position: relative;

  .agent-index {
    position: absolute;
    top: 5px;
    left: 5px;
    color: ${buttonTextColor};
    font-size: 0.875rem;
    margin-right: .5rem;
  }

  .agent-remove-button {
    right: 5px;
    top: 5px;
    color: ${darkRoseColor};
    user-select: none;
    border-radius: 0 0 0 .25rem;
    background-color: white;
    border: none;
    padding: 0;
    position: absolute;
    z-index: 1;

    &:focus {
      border: none;
    }
  }
`

EditForm.propTypes = {
  agent: agentType.isRequired,
  index: number.isRequired,
  project: projectType.isRequired
}

export default EditForm

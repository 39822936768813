import React from 'react'
import { Card } from 'react-bootstrap'
import { any, bool, func } from 'prop-types'

import Caret from 'shared/components/ui/Caret'
import StaticHeader from 'shared/components/ui/Card/StaticHeader'

const Header = props => {
  const {
    collapsible,
    content,
    isOpened,
    onToggleCollapse
  } = props

  const renderHeader = content => {
    if (typeof content === 'string') {
      return <StaticHeader>{ content }</StaticHeader>
    } else if (typeof content.type === 'string') {
      return content
    } else {
      return React.cloneElement(content, { opened: isOpened })
    }
  }
  const className = collapsible ? 'collapsible' : ''
  const onClick = collapsible ? onToggleCollapse : undefined

  return (
    <Card.Header className={ className } onClick={ onClick }>
      { renderHeader(content) }

      { collapsible && (
        <Caret
          className="caret"
          opened={ isOpened }
          data-test-id="card-caret"
        />
      )}
    </Card.Header>
  )
}

Header.propTypes = {
  collapsible: bool,
  content: any,
  isOpened: bool,
  onToggleCollapse: func
}

Header.defaultProps = {
  content: null,
  collapsible: false,
  isOpened: true,
  onToggleCollapse: () => {}
}

export default Header

import React from 'react'
import styled from 'styled-components'
import { withApollo } from 'react-apollo'
import { t } from 'ttag'
import { bool, shape, string, array, func } from 'prop-types'

import { buttonBorderColor } from 'shared/style/colors'
import apolloClientType from 'shared/types/apolloClientType'
import { combineErrors } from 'shared/helpers/inputValidation'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import renameAudienceMutation from 'graphql/mutations/campaign/targeting/renameAudienceMutation'

import buildAudiencesValidationPayload from '../buildAudiencesValidationPayload'

const TITLE_PLACEHOLDER = t`Audience name`

const AudienceName = props => {
  const {
    className,
    audience,
    client,
    readOnly,
    errors,
    validate,
    setValidationPending,
    additionalText
  } = props

  const uuid = audience.uuid

  const handleNameInput = name => {
    setValidationPending(true)
    client
      .mutate({
        mutation: renameAudienceMutation,
        variables: { uuid, name }
      })
      .then(
        ({
          data: {
            renameAudience: { campaign }
          }
        }) => {
          if (!validate) {
            return
          }
          const payload = buildAudiencesValidationPayload(campaign).map((subPayload) => ({
            ...subPayload,
            campaign
          }))
          validate({ payload: payload })
        }
      )
  }

  const errorOnName = combineErrors({ errors, uuid, field: 'name' })

  if (!audience.name) {
    audience.name = TITLE_PLACEHOLDER
    handleNameInput(TITLE_PLACEHOLDER)
  }

  if (readOnly) {
    return (
      <div className={ `audience-name ${className}` }>
        { audience.name }
        { additionalText && <span className='additional-text'>{ additionalText }</span> }
      </div>
    )
  }

  return (
    <InputWrapper error={ errorOnName }>
      <Input
        autoSelect
        type="text"
        value={ audience.name }
        onUpdate={ handleNameInput }
        data-test-id="audience-name-input"
        onClick={ (e) => { e.stopPropagation() } }
      />
    </InputWrapper>
  )
}

const Input = styled(ThriftyInput)`
  border: 1px solid ${buttonBorderColor}
  border-radius: 4px
  box-shadow: none
  font-size: 0.875rem
  padding: .4rem .5rem
  width: 25rem
`

AudienceName.propTypes = {
  audience: shape({
    uuid: string.isRequired
  }).isRequired,
  client: apolloClientType.isRequired,
  readOnly: bool.isRequired,
  additionalText: string,
  errors: array,
  setValidationPending: func,
  validate: func
}

AudienceName.defaultProps = {
  additionalText: null,
  errors: [],
  validate: undefined,
  setValidationPending: undefined
}

export default styled(withApollo(AudienceName))`
  .additional-text {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
  }
`

import React     from 'react'
import { Badge } from 'react-bootstrap'
import styled    from 'styled-components'
import { shape, string } from 'prop-types'

import { campaignCountdownMarkerColor, greenColor } from 'shared/style/colors'

import getTitleForPhase from './getTitleForPhase'

const PhaseBadge = ({ className, campaign }) => {
  return (
    <div className={ className }>
      <Badge pill className={ campaign.phase }>
        { getTitleForPhase(campaign) }
      </Badge>
    </div>
  )
}

PhaseBadge.propTypes = {
  campaign: shape({
    phase: string.isRequired
  }).isRequired
}

export default styled(PhaseBadge)`
  text-align: center
  padding: .5rem 0 1rem

  .badge {
    background-color: ${campaignCountdownMarkerColor}
    color: #FFF
    cursor: default
    font-weight: normal
    padding: .35rem .5rem
    min-width: 5.625rem
  }
  
  .badge.live {
    background-color: ${greenColor}
  }
`

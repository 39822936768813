import React, { useState } from 'react'
import styled     from 'styled-components'

import LoginStyle        from 'shared/style/global/login'
import ResetPasswordForm from 'user/components/ResetPasswordForm'
import { clearToken }    from 'shared/helpers/auth'

import ResetPasswordSuccessful from './ResetPasswordSuccessful'

const ResetPassword = ({ className }) => {
  const [resetSuccessful, setResetSuccessful] = useState(false)

  const handleSuccessfulReset = () => {
    setResetSuccessful(true)
    clearToken()
  }

  return (
    <>
      <LoginStyle/>

      <div className={ className }>
        { resetSuccessful ? (
          <ResetPasswordSuccessful />
        ) : (
          <ResetPasswordForm onSuccessfulReset={ handleSuccessfulReset } />
        )}
      </div>
    </>
  )
}

export default styled(ResetPassword)`
  display: flex
  height: 100%
  width: 100%
  align-items: center
  justify-content: center
`

import { t } from 'ttag'

export default function sessionStatusFormatter(_, { current, expired }){
  if (current) {
    return t`Current session`
  }

  if (expired) {
    return t`Expired`
  } else {
    return t`Active`
  }
}

import React    from 'react'
import { node } from 'prop-types'
import styled   from 'styled-components'
import { Modal as BootstrapModal } from 'react-bootstrap'

import { cardBoxShadow }     from 'shared/style/placeholders'
import { greyishWhiteColor } from 'shared/style/colors'

const Modal = ({ children, ...props }) => (
  <BootstrapModal { ...props }>
    { children }
  </BootstrapModal>
)

Modal.propTypes = {
  children: node.isRequired
}

Modal.Body   = BootstrapModal.Body
Modal.Header = BootstrapModal.Header
Modal.Title  = BootstrapModal.Title
Modal.Footer = BootstrapModal.Footer
Modal.Dialog = BootstrapModal.Dialog

export default styled(Modal)`
  .modal-content {
    ${cardBoxShadow}
  }

  .modal-footer {
    background: ${greyishWhiteColor}
  }
`

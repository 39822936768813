import React from 'react'
import styled from 'styled-components'
import { string, object } from 'prop-types'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

import { textGreyColor } from 'shared/style/colors'

import Categories from './Categories'

const SourceSummary = ({ className, name, value, percentage, categories }) => {
  const categoriesEmpty = Object.keys(categories).length === 0
  const Summary = (
    <div className={ className }>
      <div className='name'>
        { name }
      </div>
      <div className='value'>
        { value }
      </div>
      <div className='percentage'>
        { percentage }
      </div>
    </div>
  )

  if(categoriesEmpty) { return Summary }

  return (
    <OverlayTrigger
      trigger={ ['hover', 'focus'] }
      placement='right'
      overlay={
        <Popover>
          <Popover.Content>
            <Categories sources={ categories } maxNumberOfSources={ 10 }/>
          </Popover.Content>
        </Popover>
      }
    >
      { Summary }
    </OverlayTrigger>
  )
}

SourceSummary.propTypes = {
  categories: object.isRequired,
  name:       string.isRequired,
  percentage: string.isRequired,
  value:      string.isRequired
}

export default styled(SourceSummary)`
  padding-top: 0.75rem
  padding-left: 0.5rem
  line-height: 1.25rem

  .name {
    font-size: 0.85rem
    color: ${textGreyColor}
    margin-bottom: 0.25rem
  }

  .value {
    font-weight: 600
    font-size: 1.4rem
    color: ${(props) => props.color}
  }

  .percentage {
    font-size: 0.85rem
    color: ${(props) => props.color}
  }
`

import React, { useState, useEffect }                   from 'react'
import { bool, string, func, array, number, node, any } from 'prop-types'
import { t }                                            from 'ttag'
import styled                                           from 'styled-components'

import { createValidatableActionHandler } from 'shared/helpers/inputValidation'
import { fillArray } from 'shared/helpers/array'
import Card          from 'shared/components/ui/Card'
import PrimaryButton from 'shared/components/ui/PrimaryButton'
import TabBar        from 'shared/components/ui/TabBar'

const ValidatableTabs = ({
  className,
  title,
  collapsible,
  collapsed,
  tabs,
  activeTab,
  setActiveTab,
  validate,
  validatablePayload,
  validationPending,
  detalizeErrors,
  customDetalizeErrorsHandler,
  onFinalize,
  finalActionName,
  publishingMode,
  validationMode,
  stopValidationMode,
  loadingTabContent,
  disableValidation,
  children
}) => {
  const lastTabIdx = tabs.length - 1
  const [enabledTabs, setEnabledTabs] = useState([0])
  const [completeTabs, setCompleteTabs] = useState([])
  const [nextClicked, setNextClicked] = useState(false)

  useEffect(() => {
    setCompleteTabs(fillArray(activeTab))
    setEnabledTabs(fillArray(activeTab + 1))
  }, [activeTab])

  const withValidation = createValidatableActionHandler({
    validate,
    validatablePayload,
    detalizeErrors,
    customDetalizeErrorsHandler
  })

  const validateAndGoNextTab = async () => {
    if (disableValidation) {
      return setActiveTab(Math.min((activeTab + 1), lastTabIdx))
    }

    await withValidation(
      () => { setActiveTab(Math.min((activeTab + 1), lastTabIdx)) },
      () => { if (stopValidationMode) stopValidationMode() }
    )
  }

  const finalize = () => {
    if (stopValidationMode) stopValidationMode()
    withValidation(onFinalize)
  }

  const handleNextClick = () => setNextClicked(true)

  useEffect(() => {
    if (!validationMode  || validationPending || nextClicked || loadingTabContent) return

    handleNextClick()
  }, [validationMode, validationPending, nextClicked, loadingTabContent])

  useEffect(() => {
    if (validationPending || !nextClicked || loadingTabContent) return

    activeTab < lastTabIdx
      ? validateAndGoNextTab()
      : finalize()

    setNextClicked(false)
  }, [validationPending, nextClicked, loadingTabContent])

  const nextButtonText = activeTab < lastTabIdx ? t`Next` : finalActionName

  const actionButtonDisabled = (publishingMode && activeTab === lastTabIdx)
    || validationPending || nextClicked || loadingTabContent

  return (
    <div className={ className } >
      <Card
        className='mb-2 pb-3'
        collapsed={ collapsed }
        collapsible={ collapsible }
        padded={ false }
        header={ title } >

        <TabBar
          tabs={ tabs.map(el => el.title) }
          activeTab={ activeTab }
          enabledTabs={ enabledTabs }
          completeTabs={ completeTabs }
          onChange={ setActiveTab } />

        { children }
      </Card>

      <PrimaryButton
        className='mt-2 mb-4 next-button'
        onClick={ handleNextClick }
        disabled={ actionButtonDisabled }
        data-test-id='next-button'>
        { nextButtonText }
      </PrimaryButton>
    </div>
  )
}

ValidatableTabs.propTypes = {
  activeTab: number.isRequired,
  children: node.isRequired,
  className: string.isRequired,
  loadingTabContent: bool.isRequired,
  onFinalize: func.isRequired,
  setActiveTab: func.isRequired,
  tabs: array.isRequired,
  title: string.isRequired,
  collapsed: bool,
  collapsible: bool,
  customDetalizeErrorsHandler: func,
  detalizeErrors: bool,
  disableValidation: bool,
  finalActionName: string,
  publishingMode: bool,
  stopValidationMode: func,
  validatablePayload: any,
  validate: func,
  validationMode: bool,
  validationPending: bool
}

ValidatableTabs.defaultProps = {
  collapsible: false,
  collapsed: true,
  customDetalizeErrorsHandler: undefined,
  detalizeErrors: undefined,
  finalActionName: t`Finish`,
  publishingMode: false,
  stopValidationMode: undefined,
  validate: undefined,
  validatablePayload: undefined,
  validationMode: false,
  validationPending: false,
  disableValidation: false
}

export default styled(ValidatableTabs)`
  position: relative

  .next-button {
    position: absolute
    right: 0
  }
`

import React  from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'

import { bluishColor, whiteButtonHoverBackground } from 'shared/style/colors'

const UserMenuAction = ({ className, children, ...props }) => {
  return (
    <button
      type="button"
      className={ `${className} dropdown-item` }
      { ...props }
    >
      { children }
    </button>
  )
}

UserMenuAction.propTypes = {
  children: node.isRequired
}

export default styled(UserMenuAction)`
  color: ${bluishColor}

  &:active {
    background: #FFF
    color: ${bluishColor}
    outline: none
  }

  &:hover {
    background-color: ${whiteButtonHoverBackground};
    color: ${bluishColor}
  }

  a {
    display: inline-block;
    width: 100%;
  }

  a:hover {
    text-decoration: none
  }
`

import React from 'react'
import { func, shape, arrayOf, number } from 'prop-types'

import iconClose from 'shared/assets/icons/close.svg'
import assetType from 'shared/types/assetType'

import DeleteAssetButton from './DeleteAssetButton'

const Header = (props) => {
  const {
    components: { CloseButton },
    getStyles,
    views,
    currentIndex,
    modalProps
  } = props

  const asset = views[currentIndex].asset

  return (
    <div className="d-flex justify-content-end">
      <DeleteAssetButton
        asset={ asset }
        style={ getStyles('headerClose') }
      />

      <CloseButton
        getStyles={ getStyles }
        innerProps={{
          onClick: modalProps.onClose
        }}
      >
        <img src={ iconClose } alt="Close media"/>
      </CloseButton>
    </div>
  )
}

Header.propTypes = {
  components: shape({
    CloseButton: func
  }).isRequired,
  currentIndex: number.isRequired,
  getStyles: func.isRequired,
  modalProps: shape({
    onClose: func.isRequired,
  }).isRequired,
  views: arrayOf(shape({
    asset: assetType
  })).isRequired
}

export default Header

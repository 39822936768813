import { gql } from 'apollo-boost'

import stageUnitBalcony from 'graphql/fragments/stageUnitBalcony'

export default gql`
  mutation deleteStageUnitBalcony(
    $uuid: ID!
  ) {
    deleteStageUnitBalcony(
      uuid: $uuid
    ) {
      balcony {
        ... StageUnitBalcony
      }
    }
  }
  ${stageUnitBalcony}
`

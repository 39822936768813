import { set, get, cloneDeep } from 'lodash'

import { getUser }               from 'shared/helpers/auth'
import attachImageToUserMutation from 'graphql/mutations/users/attachImageToUser'
import admin                     from 'graphql/queries/users/admin'

export default function attachImageToUser({ client, blobId }) {
  return client.mutate({
    mutation: attachImageToUserMutation,
    variables: { blobId },
    update: (cache, response) => {
      const query = { query: admin, variables: { uuid: getUser().uuid } }
      const data = cloneDeep(cache.readQuery(query))
      const avatars = get(response, 'data.attachImageToUser.user.avatars', [])

      set(data, 'admin.avatars', avatars)

      cache.writeQuery({ ...query, data })
    }
  })
}

import React      from 'react'
import { Formik } from 'formik'
import { object } from 'prop-types'

import validationSchema
  from 'shared/components/ProductDetails/StageDetails/Details/validationSchema'

import DetailsForm from './DetailsForm'

const Details = ({ building, ...props }) => {
  const location = building.location
  const initialValues = {
    name: building.name,
    address: location?.address,
    countryCode: location?.countryCode,
    zipCode: location?.zipCode,
    location: {
      latitude: location?.latitude,
      longitude: location?.longitude
    },
    buildingTypes: building.buildingTypes
  }

  return (
    <Formik initialValues={ initialValues } validationSchema={ validationSchema }>
      <DetailsForm building={ building } { ...props }  />
    </Formik>
  )
}

Details.propTypes = {
  building: object.isRequired
}

export default Details

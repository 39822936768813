import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { string, func, bool, object } from 'prop-types'
import styled from 'styled-components'
import { get, isUndefined, omit } from 'lodash'
import { t } from 'ttag'

import projectsQuery from 'graphql/queries/projects'
import Sorter from 'shared/components/ui/Sorter'
import Search from 'shared/components/ui/Search'
// Temporary disabled: https://github.com/marketertechnologies/marketer/issues/2041
// Re enabled for https://marketer.atlassian.net/browse/MT-2
import Switch from 'shared/components/ui/Switch'
import PageHeader from 'shared/components/ui/PageHeader'
import Pagination from 'shared/components/ui/Pagination'
import { ensureNumber } from 'shared/helpers/number'
import {
  paginatedSearchTextChangeHandler,
  paginatedSearchPageChangeHandler
} from 'shared/helpers/paginatedSearch'
import useUrlParams     from 'shared/hooks/useUrlParams'
import { iconsGreyColor } from 'shared/style/colors'

import ProjectListView from './ProjectListView'

const ITEMS_PER_PAGE = 15

const sorterOptions = [
  { value: 'name',       label: t`Name` },
  { value: 'created_at', label: t`Created at` },
  { value: 'updated_at', label: t`Updated at` }
]

const ProjectsList = ({
  className, projectCardLinkBuilder, pageTitle, campaignsLiveCount, customFilter
}) => {
  const [urlParams, setUrlParams] = useUrlParams()

  const sortBy = urlParams['sort_by']
  const sortOrder = urlParams['sort_order']

  const selectedSorterOption = isUndefined(sortBy) ? 'created_at' : sortBy
  const selectedSorterDirection = isUndefined(sortOrder) ? 'desc' : sortOrder

  useEffect(() => {
    if (urlParams.sort_by && urlParams.sort_order) {
      return
    }

    setUrlParams({
      ...omit(urlParams, ['page', 'sort_by', 'sort_order']),
      sort_by: selectedSorterOption,
      sort_order: selectedSorterDirection
    })
  })

  const search = urlParams.search
  const onlyActive = (urlParams.onlyActive === 'true')
  const page = ensureNumber(urlParams.page, 1)

  const defaultFilter = {
    only_active: onlyActive
  }
  const filter = Object.assign(defaultFilter, customFilter)

  const { data } = useQuery(projectsQuery, {
    variables: {
      query: {
        page,
        sortBy: sortBy,
        sortOrder: sortOrder,
        search,
        filter
      }
    },
    fetchPolicy: 'no-cache'
  })

  const projects = get(data, 'projects.items', [])
  const projectsTotal = get(data, 'projects.total', 0)
  const pageCount = Math.ceil(projectsTotal / ITEMS_PER_PAGE)

  // Temporary disabled: https://github.com/marketertechnologies/marketer/issues/2041
  // Re enabled for https://marketer.atlassian.net/browse/MT-2
  const onActiveFilterChange = (event) => {
    const onlyActive = event.target.checked
    setUrlParams(
      onlyActive
        ? { ...omit(urlParams, ['page']), onlyActive }
        : { ...omit(urlParams, ['page', 'onlyActive']) }
    )
  }

  const handleSorterChange = (option, direction) => {
    setUrlParams({
      ...omit(urlParams, ['page', 'sort_by', 'sort_order']),
      sort_by: option,
      sort_order: direction
    })
  }

  const handleSearchChange = paginatedSearchTextChangeHandler(urlParams, setUrlParams)
  const handlePageChange = paginatedSearchPageChangeHandler(urlParams, setUrlParams)

  return (
    <div className={ className }>
      <PageHeader title={ pageTitle }>
        <div className='d-flex'>
          {/*
            Temporary disabled: https://github.com/marketertechnologies/marketer/issues/2041
            Re enabled for https://marketer.atlassian.net/browse/MT-2
          */}

          <Switch
            onChange={ onActiveFilterChange }
            checked={ onlyActive }
            className='hide-inactive-toggle'
          >
            { t`Hide inactive` }
          </Switch>
          <Sorter
            options={ sorterOptions }
            selectedOption={ selectedSorterOption }
            selectedDirection={ selectedSorterDirection }
            onChange={ handleSorterChange }
          />

          <Search
            searchText={ search }
            onSearch={ handleSearchChange }
            className='search-input ml-2'
          />
        </div>

      </PageHeader>

      <ProjectListView
        campaignsLiveCount={ campaignsLiveCount }
        projects={ projects }
        buildLink={ projectCardLinkBuilder }
      />

      <Pagination
        page={ page }
        pageCount={ pageCount }
        onPageChange={ handlePageChange }
      />
    </div>
  )
}

ProjectsList.propTypes = {
  className: string.isRequired,
  pageTitle: string.isRequired,
  projectCardLinkBuilder: func.isRequired,
  campaignsLiveCount: bool,
  customFilter: object
}

ProjectsList.defaultProps = {
  campaignsLiveCount: true,
  customFilter: {}
}

export default styled(ProjectsList)`
  position: relative
  margin-bottom: 2rem

  .controls {
    position: absolute
    right: 0.5rem
  }

  .hide-inactive-toggle {
    padding-top: 10px
    display: inline-block
    font-size: 0.875rem
    font-weight: 300
    color: ${iconsGreyColor}
    margin-right: 0.5rem
  }

  .search-input {
    max-width: 12rem
    display: inline-block
    margin-right: 1rem
  }
`

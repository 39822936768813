import updateCreativeMutation from 'graphql/mutations/campaign/creatives/update'
import { buildFragmentParams } from 'shared/helpers/graphql'
import creativeSetFragment from 'graphql/fragments/creativeSet'

const useUpdateCreative = () => {
  return (client, uuid, enabled) => (
    client.mutate({
      mutation: updateCreativeMutation,
      variables: { uuid, input: { enabled: enabled } },
      update: (cache, data) => {
        if (!data.data.updateCreative.creativeSetUuid) { return }

        const fragmentParams = buildFragmentParams(
          { __typename: 'CreativeSet', uuid: data.data.updateCreative.creativeSetUuid },
          creativeSetFragment
        )
        const fragmentData = cache.readFragment(fragmentParams)

        const creatives = fragmentData.creatives.map((creative) => {
          if (creative.uuid === uuid) creative.enabled = enabled
          return creative
        })

        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            creatives: creatives,
            adsCount: data.data.updateCreative.adsCount,
            creativeSetAudiences: data.data.updateCreative.creativeSetAudiences
          }
        })
      }
    })
  )
}

export default useUpdateCreative

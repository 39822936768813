import React    from 'react'
import { func } from 'prop-types'

import Arrow from 'shared/assets/icons/svg/new/arrow.svg'

const styleWithRotate = { transform: 'rotate(180deg)' }

const PrevArrow = ({ className, onClick }) => {
  return (
    <div className={ className } onClick={ onClick }>
      <Arrow style={ styleWithRotate } />
    </div>
  )
}

PrevArrow.propTypes = {
  onClick: func
}

PrevArrow.defaultProps = {
  onClick: () => {}
}

export default PrevArrow

import React       from 'react'
import { t }       from 'ttag'
import styled      from 'styled-components'
import { Popover } from 'react-bootstrap'

// eslint-disable-next-line react/prefer-stateless-function
class OptimizationPopover extends React.Component {
  render() {
    return (
      <Popover { ...this.props }>
        <Popover.Title as="h3">{t`Channel Optimization`}</Popover.Title>
        <Popover.Content>
          {t`Turn on channel optimization for us
          to automatically allocate your budget
          the way we think it will best reach it’s goal.`}
        </Popover.Content>
      </Popover>
    )
  }
}

OptimizationPopover.Title = Popover.Title
OptimizationPopover.Content = Popover.Content

export default styled(OptimizationPopover)`
  .popover-header {
    background: #FFF
    font-weight: 600
  }
  
  .popover-body {
    color: #666
    font-size: 1rem
  }
`

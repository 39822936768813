import React    from 'react'
import styled   from 'styled-components'
import { Form } from 'react-bootstrap'
import Select   from 'react-select'
import { t }    from 'ttag'
import { object, func } from 'prop-types'

import { redColor } from 'shared/style/colors'
import useCountries from 'shared/hooks/useCountries'

const styles = {
  indicatorsContainer: (provided) => ({
    ...provided,
    color: 'red',
    padding: 0
  })
}

const PhoneSelect = ({ values, touched, errors, onChange, className }) => {
  const phoneCodeIsInvalid = touched.phoneCode && errors.phoneCode
  const phoneNumberIsInvalid = touched.phoneNumber && errors.phoneNumber
  const isInvalid = phoneCodeIsInvalid || phoneNumberIsInvalid

  const { uniqueDialCodes } = useCountries()
  const phoneCodesOptions = uniqueDialCodes.map((dialCode) => (
    { value: dialCode, label: `+${dialCode}` }
  ))
  const selectedPhoneCode = phoneCodesOptions.find((option) => (
    option.value === values.phoneCode
  ))

  return (
    <div className={ className }>
      <div className={ `phone-select-controls ${isInvalid && 'is-invalid'}` }>
        <div data-test-id='phone-select-wrapper'>
          <Select
            placeholder=""
            noOptionsMessage={ () => t`No options` }
            styles={ styles }
            options={ phoneCodesOptions }
            value={ selectedPhoneCode }
            onChange={ ({ value }) => {
              onChange({ target: { value, name: 'phoneCode' } })
            } }
          />
        </div>

        <Form.Control
          name="phoneNumber"
          type="number"
          value={ values.phoneNumber }
          onChange={ onChange }
          data-test-id="phone-number"
          isInvalid={ phoneNumberIsInvalid }
        />
      </div>
      <Form.Control.Feedback type="invalid">
        { errors.phoneCode && (
          <p className="my-0">{ errors.phoneCode }</p>
        )}
        { errors.phoneNumber && (
          <p className="my-0">{ errors.phoneNumber }</p>
        )}
      </Form.Control.Feedback>
    </div>
  )
}

PhoneSelect.propTypes = {
  errors: object.isRequired,
  onChange: func.isRequired,
  touched: object.isRequired,
  values: object.isRequired,
}

export default styled(PhoneSelect)`
  display: inline-block
  width: 60%;

  .phone-select-controls {
    display: flex;
    justify-content: space-between
  }

  .phone-select-controls .form-control {
    height: 2.375rem
  }

  .phone-select-controls > div:first-child {
    margin-right: 1rem
    width: 60%;
  }

  .phone-select-controls input {
    padding-right: 0.75rem
  }

  .phone-select-controls.is-invalid + .invalid-feedback {
    display: block
    margin-top: .5rem
    padding-left: 0
  }

  .phone-select-controls.is-invalid [class$=control] {
    border-color: ${redColor}
  }

  [class$=indicatorContainer] {
    padding: .5rem .25rem
  }
`

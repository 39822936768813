import React from 'react'

import Card from 'shared/components/ui/Card'

const NotFound = () => (
  <Card>
    <h5 className="text-muted text-center p-5">
      Oops! Requested page does not exist :(
    </h5>
  </Card>
)

export default NotFound

import React from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { bool, shape, func } from 'prop-types'

import Container from './Container'
import View      from './View'
import Header    from './Header'

const components = { View, Header, Container }

const modalStyles = {
  dialog: (styles) => ({ ...styles, width: '100%' })
}

const Lightbox = ({ state, controls }) => {
  const views = state.assets.map((asset) => ({ source: asset.media, asset }))

  return (
    <ModalGateway>
      { state.isOpened && (
        <Modal onClose={ controls.close } allowFullscreen={ false } styles={ modalStyles }>
          <Carousel
            views={ views }
            components={ components }
            currentIndex={ state.openedKey }
            trackProps={{
              infinite: true,
              onViewChange: (index) => {
                [...document.querySelectorAll('video')].forEach((video) => {
                  video.pause()
                })

                controls.open(index)
              }
            }}
          />
        </Modal>
      ) }
    </ModalGateway>
  )
}

Lightbox.propTypes = {
  controls: shape({
    next: func,
    previous: func,
    close: func,
  }).isRequired,
  state: shape({
    isOpened: bool.isRequired,
  }).isRequired,
}

export default Lightbox

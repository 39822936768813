import React from 'react'
import { t } from 'ttag'
import { string, func, array } from 'prop-types'
import pluralize from 'pluralize'

import Select       from 'shared/components/ui/ThriftyInput/TouchableSelect'
import { redColor } from 'shared/style/colors'
import allOptions   from 'shared/constants/buildingTypes'

const styles = {
  container: provided => ({
    ...provided,
    minWidth: '9rem'
  }),
  control: (provided, { selectProps }) => ({
    ...provided,
    borderColor: selectProps.isInvalid ? redColor : provided.borderColor
  })
}

const UnitTypeSelect = ({ value, onUpdate, uuid, stageBuildingTypes, ...props }) => {
  const handleChange = ({ value: unitType }) => {
    return onUpdate({ uuid, attributes: { unitType } })
  }

  const allowedUnitTypes = stageBuildingTypes.map((type) => pluralize.singular(type))
  const options = allOptions.filter((option) => allowedUnitTypes.includes(option.value))

  return (
    <Select
      value={ options.find((option) => (option.value === value)) }
      options={ options }
      onChange={ handleChange }
      styles={ styles }
      data-test-id='unit-select'
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

UnitTypeSelect.propTypes = {
  onUpdate: func.isRequired,
  uuid: string.isRequired,
  stageBuildingTypes: array,
  value: string
}

UnitTypeSelect.defaultProps = {
  value: undefined,
  stageBuildingTypes: []
}

export default UnitTypeSelect

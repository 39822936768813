const mediaTypeDropEvents = {
  video: 'SAVE_VIDEO_ASSET',
  banner: 'SAVE_BANNER_ASSET',
  image: 'SAVE_IMAGE_ASSET',
  logo: 'SAVE_LOGO_ASSET',
  floorplan: 'SAVE_FLOORPLAN_ASSET'
}

const mediaTypes = ['logo', 'image', 'video', 'floorplan']

export { mediaTypeDropEvents }

export default mediaTypes

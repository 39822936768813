import attachFileMutation        from 'graphql/mutations/assetable/attachFile'
import campaignCreativeSetsQuery from 'graphql/queries/campaignCreativeSets'
import campaignAssetAssignments  from 'graphql/fragments/campaignAssetAssignments'
import updateAssetableAssetAssignmentsCache
  from 'shared/helpers/updateAssetableAssetAssignmentCache'

export default function attachFileToCampaign({ client, blobId, campaignUuid, mediaType }) {
  const assetableUuid = campaignUuid
  const assetableType = 'Campaign'

  return client.mutate({
    mutation: attachFileMutation,
    variables: { blobId, assetableUuid, assetableType, mediaType },
    refetchQueries: [{
      query: campaignCreativeSetsQuery,
      variables: { campaignUuid: assetableUuid }
    }],
    update: (store, {
      data: {
        attachFileToAssetable: { assetAssignment }
      }
    }) => updateAssetableAssetAssignmentsCache({
      store,
      assetableUuid: campaignUuid,
      assetableType: 'Campaign',
      assetableFragment: campaignAssetAssignments,
      assetAssignment
    })
  })
}

import { countDifferenceInDays, todayOrFeatureDate } from 'shared/helpers/date'

const minChannelSpending = (campaign, channel_type, minSpending) => {
  const channel = campaign.channels.find((el) => el.type === channel_type)
  if (!channel) {
    return
  }

  let audiencesAmount = 0
  if (channel?.audiences) {
    const audiences = channel.audiences.filter((audience) => !audience.deleted)
    audiencesAmount = audiences.length
  }

  const todayOrStartDate = todayOrFeatureDate(campaign.startDate)
  const remainingCampaignDurationDays = countDifferenceInDays(campaign.endDate, todayOrStartDate)

  const spentSoFar = channel.lastInsightSummary.totalSpent
  return (remainingCampaignDurationDays * audiencesAmount * minSpending) + spentSoFar
}

export default minChannelSpending

import gql from 'graphql-tag'

import { buildFragmentParams } from 'shared/helpers/graphql'
import assetAssignment         from 'graphql/fragments/assetAssignment'

const STAGE_ASSET_ASSIGNMENTS_FRAGMENT = gql`
  fragment Stage on Stage {
    assetAssignments {
      ... AssetAssignment
    }
  }
  ${assetAssignment}
`

const updateStageAssetsCache = ({ store, assetableUuid, assetAssignment }) => {
  const fragmentParams = buildFragmentParams(
    { uuid: assetableUuid, __typename: 'Stage' },
    STAGE_ASSET_ASSIGNMENTS_FRAGMENT
  )
  const fragmentData = store.readFragment(fragmentParams)

  store.writeFragment({
    ...fragmentParams,
    data: {
      ...fragmentData,
      assetAssignments: [
        ...fragmentData.assetAssignments,
        assetAssignment
      ]
    }
  })
}

export default updateStageAssetsCache

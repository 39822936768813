import axios from 'axios'
import jwtDecode from 'jwt-decode'
import pluralize from 'pluralize'
import Cookie    from 'js-cookie'

import sentryClient from 'sentryClient'
import redirectTo   from 'shared/helpers/redirectTo'

const TOKEN_STORAGE_KEY = 'userToken'
const USER_PERMISSIONS = 'userPermissions'

const extractToken = (response) => {
  if (!(response.headers && response.headers.authorization)) {
    throw new Error('No "Authorization" header in response')
  }

  const [,token] = response.headers.authorization.split(' ')

  if (!token) {
    throw new Error('Could not get token from "Authorization" header')
  }

  return token
}

export const isUserAdmin = () => {
  const user = getUser()

  return user && user.role === 'admin'
}

export const getUser = () => {
  try {
    return jwtDecode(localStorage.getItem(TOKEN_STORAGE_KEY))
  } catch (_e) {
    return null
  }
}

export const setErrorClientUser = (errorClient) => {
  const user = getUser()

  if (!user) {
    return
  }
  errorClient.setUser(user.uuid, user.email, user.name)
}

export const canRead = (group) => {
  if (isUserAdmin()) return true

  const userPermissions = getUserPermissions()
  if (hasNullPermissions(userPermissions)) return true
  if (userPermissions.length === 0) return false
  if (hasDefaultCompanyPermissions(userPermissions)) return true

  const lastCompanyRoles = userPermissions[userPermissions.length - 1].roles
  return hasOneOfThePermissions(lastCompanyRoles, group)
}

export const can = (group, permission) => {
  if (isUserAdmin()) return true

  const userPermissions = getUserPermissions()
  if (hasNullPermissions(userPermissions)) return true
  if (userPermissions.length === 0) return false
  if (hasDefaultCompanyPermissions(userPermissions)) return true

  const lastCompanyRoles = userPermissions[userPermissions.length - 1].roles
  return hasPermission(lastCompanyRoles, `${group}:${permission}`)
}

export const canUpdate = (group) => {
  if (isUserAdmin()) return true

  const userPermissions = getUserPermissions()
  if (hasNullPermissions(userPermissions)) return true
  if (userPermissions.length === 0) return false
  if (hasDefaultCompanyPermissions(userPermissions)) return true

  const lastCompanyRoles = userPermissions[userPermissions.length - 1].roles
  if (hasPermission(lastCompanyRoles, `${group}:update`)) return true
  return hasPermission(lastCompanyRoles, `${group}:create`)
}

export const canCreate = (group) => {
  if (isUserAdmin()) return true

  const userPermissions = getUserPermissions()
  if (hasNullPermissions(userPermissions)) return true
  if (userPermissions.length === 0) return false
  if (hasDefaultCompanyPermissions(userPermissions)) return true

  const lastCompanyRoles = userPermissions[userPermissions.length - 1].roles
  return hasPermission(lastCompanyRoles, `${group}:create`)
}

export const hasNullPermissions = (userPermissions) => {
  return userPermissions === null || userPermissions === undefined
}

export const hasDefaultCompanyPermissions = (userPermissions) => {
  return !userPermissions[userPermissions.length - 1].with_user_roles
}

export const hasOneOfThePermissions = (permissions, value) => {
  if (hasPermission(permissions, `${value}:read`)) return true
  if (hasPermission(permissions, `${value}:update`)) return true
  return hasPermission(permissions, `${value}:create`)
}

export const hasPermission = (permissions, value) => {
  return permissions.includes(value)
}

export const getToken = () => localStorage.getItem(TOKEN_STORAGE_KEY)

export const setToken = (token) => localStorage.setItem(TOKEN_STORAGE_KEY, token)

export const clearToken = () => localStorage.removeItem(TOKEN_STORAGE_KEY)

export const getUserPermissions = () => {
  let perms = localStorage.getItem(USER_PERMISSIONS)
  // Some times, localStore has the actual "undefined" string, which JSON fails at
  if (perms === 'undefined') perms = undefined

  return perms !== undefined ? JSON.parse(localStorage.getItem(USER_PERMISSIONS)) : perms
}

export const setUserPermissions = (value) => {
  localStorage.setItem(USER_PERMISSIONS, JSON.stringify(value))
}

export const login = (userType, { email, password, remember_me }) => {
  return axios
    .post(
      `/${pluralize(userType)}/sign_in`,
      { [userType]: { email, password, remember_me } },
      { headers: { JWT_AUD: window.navigator.userAgent } }
    )
    .then((response) => {
      const token = extractToken(response)
      const decodedToken = jwtDecode(token)
      setUserPermissions(decodedToken.company_permissions)
      setToken(token)

      if (!Cookie.get('locale')) {
        const locale = response?.data?.settings?.default_language || 'en_US'
        Cookie.set('locale', locale)
      }
    })
    .then(() => setErrorClientUser(sentryClient))
}

export const logout = () => {
  const user = getUser()

  if (!user) return

  return axios
    .delete('/users/sign_out', {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    })
    .then(() => {
      clearToken()
      redirectTo('/logged-out', { loggedOutUserType: user.role })
    })
}

export const resetPassword = ({ email }) => {
  return axios
    .post('/users/password', { user: { email } })
}

export const setNewPassword = ({ password, password_confirmation, reset_password_token }) => {
  return axios
    .put('/users/password/1', {
      user: { password, password_confirmation, reset_password_token }
    })
}

import React, { useEffect } from 'react'
import { t } from 'ttag'
import styled from 'styled-components'
import { shape, integer } from 'prop-types'
import { useLazyQuery } from 'react-apollo'
import { Spinner } from 'react-bootstrap'
import { isEqual } from 'lodash'

import SubHeader from 'shared/components/ui/Card/SubHeader'
import { textGreyColor } from 'shared/style/colors'
import { formatNumberWithLocale } from 'shared/helpers/number'
import audiencePotentialReachQuery from 'graphql/queries/audiences/potentialReach'
import usePrevious from 'shared/hooks/usePrevious.js'

const Grey = styled.span`
  color: ${textGreyColor}
`

const PotentialReach = ({ audience }) => {
  const platformLocationUpdateAts = {
    platformAudience: audience.platformAudience.updatedAt,
    locations: audience.locations.map((location) => location.updatedAt)
  }

  const previousPlatformLocationUpdateAts = usePrevious(platformLocationUpdateAts)

  const [getPotentialReach, { data, loading }] = useLazyQuery(
    audiencePotentialReachQuery,
    { fetchPolicy: 'network-only' }
  )

  useEffect(() => {
    if (
      previousPlatformLocationUpdateAts
        && !isEqual(platformLocationUpdateAts, previousPlatformLocationUpdateAts)
    ) {
      getPotentialReach({ variables: { uuid: audience.uuid } })
    }
  }, [audience])

  const potentialReach = data ? data.audiencePotentialReach.potentialReach : audience.potentialReach

  if (!potentialReach) {
    return null
  }

  return (
    <div>
      <SubHeader className="mt-4">{ t`Potential reach` }</SubHeader>
      {
        loading
          ? (
            <Spinner animation="border" variant="secondary" size="sm" />
          ) : (
            <Grey>
              { formatNumberWithLocale(potentialReach) } { t`people` }
            </Grey>
          )
      }
    </div>
  )
}

PotentialReach.propTypes = {
  audience: shape({
    potentialReach: integer
  }).isRequired,
}

export default PotentialReach

import gql from 'graphql-tag'

export const typeDefs = gql`
  extend type Query {
    currentCampaignUuid: ID
  }

  extend type Mutation {
    setCurrentCampaignUuid(uuid: ID!): Boolean
  }
`

import fileType from 'file-type'
import _ from 'lodash'

const validateFileType = (file, allowedMimeTypes) => (
  new Promise((resolve, reject) => {
    const blob = file.slice(0, fileType.minimumBytes)
    const reader = new FileReader()

    reader.onloadend = (e) => {
      if (e.target.readyState !== FileReader.DONE) { return }

      const targetType = fileType(new Uint8Array(e.target.result))

      if (_.isEmpty(allowedMimeTypes)
          || (targetType?.mime && allowedMimeTypes.includes(targetType.mime))
      ) {
        resolve(file)
      } else {
        reject(`File type ${targetType?.mime || ''} is not supported.`)
      }
    }

    reader.readAsArrayBuffer(blob)
  })
)

export default validateFileType

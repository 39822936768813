import React from 'react'
import { useMutation } from 'react-apollo'

import populateTargetingMutation from 'graphql/mutations/campaign/populateTargeting'
import toastError from 'shared/toastError'

export default function usePopulateTargeting(campaignUuid) {
  const [runPopulateTargeting, { loading }] = useMutation(populateTargetingMutation, {
    variables: { campaignUuid }
  })

  const populateTargeting = () => (
    runPopulateTargeting().then(({ data: { populateTargeting } }) => {
      if (populateTargeting.errors && populateTargeting.errors.length) {
        toastError(
          <>
            { populateTargeting.errors }
          </>
        )
      }
    })
  )

  return {
    populateTargeting,
    loading
  }
}

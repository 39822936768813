import React from 'react'
import { bool, object, shape } from 'prop-types'
import { Formik } from 'formik'

import stageType from 'shared/types/stageType'

import DetailsWithSnapshots from '../DetailsWithSnapshots'

import validationSchema from './validationSchema'
import DetailsForm      from './DetailsForm'

const Details = ({ product, ...props }) => {
  const { promotable: stage, company } = product
  const location = stage.location || {}
  const initialValues = {
    name: stage.name,
    address: location.address,
    countryCode: location.countryCode,
    zipCode: location.zipCode,
    location: {
      latitude: location.latitude,
      longitude: location.longitude
    },
    buildingTypes: stage.buildingTypes,
    unitsCount: stage.unitsCount,
    unitsSold: stage.unitsSold,
    lowerPrice: stage.lowerPrice,
    upperPrice: stage.upperPrice,
    priceRangeIsSubjectToChange: stage.priceRangeIsSubjectToChange || false,
    lowerSizeM2: stage.lowerSizeM2,
    upperSizeM2: stage.upperSizeM2,
    sizeRangeIsSubjectToChange: stage.sizeRangeIsSubjectToChange || false,
    attractiveness: [stage.attractiveness || 1],
  }

  const { initialTouched, validateOnMount } = props

  const isSnapshotEnabled = company.enabledFeatures.includes('snapshot')

  if (isSnapshotEnabled) {
    return <DetailsWithSnapshots liveValidationEnabled stage={ stage } product={ product } />
  }

  return (
    <Formik
      initialTouched={ initialTouched }
      validateOnMount={ validateOnMount }
      initialValues={ initialValues }
      validationSchema={ validationSchema }
    >
      <DetailsForm stage={ stage } { ...props }  />
    </Formik>
  )
}

Details.propTypes = {
  product: shape({
    promotable: stageType.isRequired
  }).isRequired,
  initialTouched: object,
  validateOnMount: bool
}

Details.defaultProps = {
  initialTouched: undefined,
  validateOnMount: undefined
}

export default Details

import React from 'react'

import IconLeadChat     from 'shared/assets/icons/svg/new/leadChat.svg'
import IconLeadProgress from 'shared/assets/icons/svg/new/leadProgress.svg'
import IconLeadDownload from 'shared/assets/icons/svg/new/leadDownload.svg'
import IconLeadAwaiting from 'shared/assets/icons/svg/new/leadAwaiting.svg'
import IconLeadContact  from 'shared/assets/icons/svg/new/leadContact.svg'
import IconLeadShowing  from 'shared/assets/icons/svg/new/leadShowing.svg'
import IconLeadBid      from 'shared/assets/icons/svg/new/leadBid.svg'
import { IconPhone }    from 'shared/components/ui/icons'

import { RecordIconContainer } from './elements'

const findProgressIcon = statusAfter => {
  switch(statusAfter) {
  case 'contact_attempted':
    return IconLeadContact
  case 'registered':
    return IconLeadDownload
  case 'awaiting_decision':
    return IconLeadAwaiting
  default:
    return IconLeadProgress
  }
}

const findActivityIcon = activityType => {
  switch(activityType) {
  case 'phone_call':
    return IconPhone
  case 'lead_contacted':
    return IconLeadContact
  case 'showing':
    return IconLeadShowing
  case 'bid_placed':
    return IconLeadBid
  default:
    return IconLeadProgress
  }
}

const findIcon = ({ __typename: type, statusAfter, activityType }) => {
  switch(type) {
  case 'NoteRecord':
    return IconLeadChat
  case 'ProgressRecord':
    return findProgressIcon(statusAfter)
  case 'ActivityRecord':
    return findActivityIcon(activityType)
  default:
    return IconLeadChat
  }
}

const RecordIcon = props => {
  const Icon = findIcon(props)

  return (
    <RecordIconContainer>
      <Icon />
    </RecordIconContainer>
  )
}

export default RecordIcon

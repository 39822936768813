import gql from 'graphql-tag'

export default gql`
  fragment Specification on Specification {
    uuid
    goals
    channels
    features
    forecasts
    channelOptimizationEnabled
    blueprintTemplate
    name
  }
`

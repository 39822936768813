import gql from 'graphql-tag'

export default gql`
  mutation addLeadNote(
    $leadUuid: ID!
    $title: String
    $text: String!
  ) {
    addLeadNote(
      leadUuid: $leadUuid
      title: $title
      text: $text
    ) {
      note {
        uuid
        createdAt
        title
        text
        author {
          uuid
          name
        }
      }
    }
  }
`

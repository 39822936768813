import React       from 'react'
import { array }   from 'prop-types'
import { Overlay } from 'react-bootstrap'

import { ShowingsListContainer } from './elements'
import Popover                   from './Popover'
import ShowingsList              from './ShowingsList'
import ShowingsToggle            from './ShowingsToggle'
import useShowingsListOverlay    from './useShowingsListOverlay'

const ShowingsListOverlay = ({ showings }) => {
  const {
    isOpened,
    toggle,
    close,
    target,
    container,
    sortedShowings,
    firstShowing
  } = useShowingsListOverlay(showings)

  return (
    <ShowingsListContainer ref={ container }>
      <ShowingsToggle
        showing={ firstShowing }
        onClick={ toggle }
        target={ target }
      />

      <Overlay
        placement="bottom"
        show={ isOpened }
        container={ container.current }
        target={ target.current }
      >
        <Popover onClose={ close }>
          <ShowingsList showings={ sortedShowings } />
        </Popover>
      </Overlay>
    </ShowingsListContainer>
  )
}

ShowingsListOverlay.propTypes = {
  showings: array.isRequired
}

export default ShowingsListOverlay

import { gql } from 'apollo-boost'

export default gql`
  query GeolocationSearch($campaignUuid: ID!, $query: String) {
    googleGeolocationSearch(campaignUuid: $campaignUuid, query: $query) {
      key
      name
      type
      countryName
    }
  }
`

import React from 'react'
import { array, number, func, string, shape, oneOfType, bool } from 'prop-types'

import { ensureNumber } from 'shared/helpers/number'
import Search           from 'shared/components/ui/Search'

import createOnChangeHandlers from './createOnChangeHandlers'
import Table                  from './Table'

const DEFAULT_SIZE_PER_PAGE = 25

const ItemsList = ({
  totalCount,
  className,
  items,
  columns,
  params,
  filter: Filter,
  setUrlParams,
  hasSearchEnabled,
  perPage
}) => {
  const { search, sortBy, sortOrder } = params
  const page = ensureNumber(params.page, 1)

  const [onTableChange, onSearchChange] = createOnChangeHandlers(params, setUrlParams)

  const showPagination = totalCount > perPage

  const paginationOptions = showPagination
    ? {
      page: page,
      sizePerPage: perPage,
      totalSize: totalCount
    }
    : null

  const remoteOptions = {
    filter: !!Filter,
    pagination: showPagination,
    sort: true,
    search: hasSearchEnabled
  }

  const searchOptions = hasSearchEnabled
    ? {
      delay: 1000,
      searchText: search,
      onSearch: onSearchChange
    }
    : null

  const defaultSorted = sortBy && sortOrder
    ? [{ dataField: sortBy, order: sortOrder }]
    : []

  const tableOptions = {
    columns,
    defaultSorted,
    onTableChange,
    data: items,
    remote: remoteOptions,
    keyField: 'uuid',
    options: {
      hideSizePerPage: true,
      sizePerPage: 25,
    },
    bordered: false
  }

  return (
    <Table
      className={ className }
      paginationOptions={ paginationOptions }
      searchPanel={ Search }
      filter={ Filter }
      searchOptions={ searchOptions }
      tableOptions={ tableOptions }
    />
  )
}

ItemsList.propTypes = {
  columns: array.isRequired,
  params: shape({
    page: oneOfType([number, string]),
    search: string
  }).isRequired,
  setUrlParams: func.isRequired,
  filter: func,
  hasSearchEnabled: bool,
  items: array,
  perPage: number,
  totalCount: number
}

ItemsList.defaultProps = {
  items: [],
  filter: null,
  totalCount: undefined,
  hasSearchEnabled: true,
  perPage: DEFAULT_SIZE_PER_PAGE
}

export default ItemsList

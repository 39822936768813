import { useApolloClient } from 'react-apollo'

import { buildFragmentParams }   from 'shared/helpers/graphql'
import creativeSetFragment       from 'graphql/fragments/creativeSet'
import updateCreativeSetMutation from 'graphql/mutations/campaign/creativeSets/update'

const useCreateUpdateCreativeSet = () => {
  const client = useApolloClient()

  const createUpdateCreativeSet = (uuid, fieldName) => {
    return (value) => {
      client.mutate({
        mutation: updateCreativeSetMutation,
        variables: {
          uuid: uuid,
          input: { [fieldName]: value }
        },
        update: (cache, _data) => {
          const fragmentParams = buildFragmentParams(
            { __typename: 'CreativeSet', uuid: uuid },
            creativeSetFragment
          )
          const fragmentData = client.readFragment(fragmentParams)

          cache.writeFragment({
            ...fragmentParams,
            data: {
              ...fragmentData,
              [fieldName]: value
            }
          })
        }
      })
    }
  }

  return createUpdateCreativeSet
}

export default useCreateUpdateCreativeSet

import React from 'react'
import styled from 'styled-components'
import { string, node } from 'prop-types'

import { textColor } from 'shared/style/colors'

const Header = ({ className, title, children }) => (
  <div className={ className }>
    <h2>{ title }</h2>
    { children && <div>{ children }</div> }
  </div>
)

Header.propTypes = {
  className: string.isRequired,
  title: string.isRequired,
  children: node
}

Header.defaultProps = {
  children: undefined
}

export default styled(Header)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  h2 {
    color: ${textColor}
    font-size: 1.5rem;
    margin: 0;
  }
`

import React, { forwardRef } from 'react'
import styled     from 'styled-components'
import { node }   from 'prop-types'
import { Button } from 'react-bootstrap'

import { formInputShadow } from 'shared/style/placeholders'
import PlusIcon from 'shared/components/ui/icons/PlusIcon'

const PrimaryButton = forwardRef(({ children, ...props }, ref) => (
  <Button ref={ ref } { ...props }>
    { children }
  </Button>
))

PrimaryButton.propTypes = {
  children: node.isRequired
}

PrimaryButton.displayName = 'PrimaryButton'

export default styled(PrimaryButton)`
  ${formInputShadow}
  align-items: center;
  display: flex;
  font-size: .875rem
  padding: 0.25rem 1.75rem

  ${PlusIcon} {
    path:nth-child(2) { fill: white; }
  }

  > :not(:last-child) {
    margin-right: 0.5rem;
  }
`

import React from 'react'
import { t } from 'ttag'
import classNames from 'classnames'
import { bool, object } from 'prop-types'

import { can } from 'shared/helpers/auth'
import Card from 'shared/components/ui/Card'

import StakeholdersTable from './StakeholdersTable'

const Stakeholders = ({ className, project, disabled }) => {
  if (!can('users', 'invite')) return null

  return (
    <div data-test-id='stakeholders'>
      <Card
        collapsible
        collapsed
        header={ t`Stakeholders` }
        padded={ false }
        className={ classNames(className, 'mt-2') }
      >
        <StakeholdersTable project={ project } disabled={ disabled } />
      </Card>
    </div>
  )
}

Stakeholders.propTypes = {
  disabled: bool.isRequired,
  project: object.isRequired
}

export default Stakeholders

import query from 'graphql/queries/blobPreview'

const getBlobPreview = ({ client, ...data }) => {
  return (
    client
      .query({
        query,
        variables: { blobId: data.blobId }
      })
      .then(({ data: { blobPreview } }) => {
        return {
          ...data,
          ...blobPreview
        }
      })
  )
}

export default getBlobPreview

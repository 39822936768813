import React from 'react'

import productType from 'shared/types/productType'
import { timelineDateTime } from 'shared/helpers/date'
import Card from 'shared/components/ui/Card'
import Paragraph from 'shared/components/ui/Paragraph'
import Small from 'shared/components/ui/Small'

const Timeline = ({ product }) => {
  const events = [{
    name: 'Product captured by scraper',
    date: product.createdAt
  }]

  return (
    <Card collapsible header="Timeline">
      { events.map(({ name, date }) => (
        <div key={ date }>
          <Paragraph className="mb-0">{ name }</Paragraph>
          <Small>{ timelineDateTime(date) }</Small>
        </div>
      )) }
    </Card>
  )
}

Timeline.propTypes = {
  product: productType.isRequired
}

export default Timeline

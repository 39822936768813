import React from 'react'
import axios from 'axios'
import { string } from 'prop-types'
import { t } from 'ttag'
import { saveAs } from 'file-saver'

import { getToken } from '../../helpers/auth'

const ExportLeadsButton = ({ campaignUuid, campaignName }) => {
  const handleExportLeads = (e) => {
    // hack for preserving the css by having a href on the anchor
    e.preventDefault()

    axios.get(`/campaigns/${campaignUuid}/leads.xlsx`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      responseType: 'blob'
    }).then((response) => {
      const file = new Blob(
        [response.data],
        { type: response.headers['content-type'] }
      )

      if (file) saveAs(file, `Leads_${campaignName}_${new Date().toISOString()}.xlsx`)
    })
  }

  return (
    <a href={ '' } onClick={ (e) => handleExportLeads(e) }>
      { t`Export leads` }
    </a>
  )
}

ExportLeadsButton.propTypes = {
  campaignName: string.isRequired,
  campaignUuid: string.isRequired
}

export default ExportLeadsButton

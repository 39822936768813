import React from 'react'
import styled from 'styled-components'
import { object } from 'prop-types'

import campaignType from 'shared/types/campaignType'
import { cardBorderValue } from 'shared/style/placeholders'

import * as components from './Sections'

const Footer = ({ className, campaign, components: externalComponents }) => {
  const project = campaign.project
  const property = campaign.property
  const {
    CampaignPackage,
    Customer,
    Period,
    Project,
    Property,
    Stages,
    Units
  } = { ...components, ...externalComponents }

  return (
    <section className={ className }>
      {property
        ? (
          <>
            <Customer customerName={ property.customerName } />
            <Property property={ property } />
            <CampaignPackage campaign={ campaign } />
            <Period campaign={ campaign } />
          </>
        ) : (
          <>
            <Customer customerName={ project.customerName } />
            <Project project={ project } />
            <Units unitsCount={ project.unitsCount } unitsSold={ project.unitsSold } />
            <Stages stages={ project.stagesCount } />
          </>
        )
      }
    </section>
  )
}

Footer.propTypes = {
  campaign: campaignType.isRequired,
  components: object
}

Footer.defaultProps = {
  components: {}
}

export default styled(Footer)`
  display: flex;
  padding-bottom: .2rem;
  padding-top: .2rem;

  & > * {
    flex-grow: 1;
    flex-basis: 0;
    border-left: ${cardBorderValue}
    padding-left: 1.625rem
  }

  & > *:first-child {
    border-left: none;
    padding-left: 0
  }
`

import { gql } from 'apollo-boost'

export default gql`
  query GoogleUrlBundles($campaignUuid: ID!, $query: String) {
    googleUrlBundles(campaignUuid: $campaignUuid, query: $query) {
      uuid
      name
      urlsCount
    }
  }
`

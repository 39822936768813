import { find } from 'lodash'
import { number } from 'prop-types'

import projectType from 'shared/types/projectType'

const projectUnits = ({ project, stageTab }) => {
  const allStages = project.completeStages
  const currentStage = project.completeStages[stageTab - 1]

  const stageSelection = stageTab === 0 ? allStages : [currentStage]

  const getUnits = (stages) => stages.flatMap((stage) => stage.units)
  const vitecDocumentsPresent = (data) => !!find(data, 'vitecDocumentsCount')

  const currentStageUnits = getUnits(stageSelection)
  const allUnits = getUnits(allStages)

  return {
    currentStageUnits,
    allUnits,
    vitecDocumentsPresentOnCurrentStage: vitecDocumentsPresent(currentStageUnits),
    vitecDocumentsPresentOnProject: vitecDocumentsPresent(allUnits)
  }
}

projectUnits.propTypes = {
  project: projectType.isRequired,
  stageTab: number.isRequired
}

export default projectUnits

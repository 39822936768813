import React  from 'react'
import styled from 'styled-components'
import { t } from 'ttag'

import UserRoleNavLink from 'routes/UserRoleNavLink'
import { bluishColor, blackColor } from 'shared/style/colors'
import { roundButtonShadow } from 'shared/style/placeholders'

const NewButton = ({ className }) => {
  return (
    <UserRoleNavLink className={ className } to={ '/new' } data-test-id="new-button" >
      <span className="icon">+</span>
      { t `New` }
    </UserRoleNavLink>
  )
}

export default styled(NewButton)`
  align-items: center
  color: ${blackColor}
  display: flex
  font-size: 0.875rem
  font-weight: 600
  line-height: 0
  margin-bottom: 2rem

  &:hover {
    text-decoration: none
    color: ${blackColor}
  }

  .icon {
    ${roundButtonShadow}
    background: ${bluishColor}
    border: 2px solid #FFF;
    border-radius: 2rem;
    color: #FFF
    display: inline-block
    height: 1.9375rem
    font-size: 1.75rem
    font-weight: 500
    line-height: 1.5rem
    margin-right: 0.5625rem
    text-align: center
    width: 1.9375rem
  }
`

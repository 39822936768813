import createAssetAssignmentMutation from 'graphql/mutations/assetable/createAssetAssignment'
import updateAssetableAssetAssignmentsCache
  from 'shared/helpers/updateAssetableAssetAssignmentCache'
import stageAssetAssignments from 'graphql/fragments/stageAssetAssignments'

export default function createCreateAssetAssignment(client, stageUuid) {
  return function createAssetAssignment(asset) {
    client.mutate({
      mutation: createAssetAssignmentMutation,
      variables: {
        assetableUuid: stageUuid,
        assetableType: 'Stage',
        assetUuid:      asset.uuid
      },
      update: (store, {
        data: {
          createAssetAssignment: { assetAssignment }
        }
      }) => updateAssetableAssetAssignmentsCache({
        store,
        assetableUuid: stageUuid,
        assetableType: 'Stage',
        assetableFragment: stageAssetAssignments,
        assetAssignment
      })
    })
  }
}

import { arrayOf, shape, string, boolean } from 'prop-types'

import propertyType from 'shared/types/propertyType'
import locationType from 'shared/types/locationType'

export default shape({
  uuid: string.isRequired,
  name: string.isRequired,
  createdAt: string.isRequired,
  state: string,
  link: string,
  primaryAgent: string,
  secondaryAgents: arrayOf(string),
  owner: string,
  sold: boolean,
  property: propertyType,
  location: locationType
})

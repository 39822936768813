import { buildFragmentParams } from 'shared/helpers/graphql'
import stageFragment from 'graphql/fragments/stage'
import deleteSalesArgumentMutation from
  'graphql/mutations/projects/stages/deleteSalesArgumentMutation'

const deleteSalesArgument = (client, stage, salesArgument) => {
  client.mutate({
    mutation: deleteSalesArgumentMutation,
    variables: {
      uuid: salesArgument.uuid
    },
    update: (cache, { data: { deleteStageSalesArgument: { salesArgument } } }) => {
      const fragmentParams = buildFragmentParams(stage, stageFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          salesArguments: fragmentData.salesArguments.filter(
            ({ uuid }) => uuid !== salesArgument.uuid
          )
        }
      })
    }
  })
}

export default deleteSalesArgument

import React from 'react'

import projectType from 'shared/types/projectType'
import Column from 'shared/components/ui/TwoColumnList/Column'
import { formatPrice } from 'shared/helpers/currency'

const RightColumn = ({ promotable }) => {
  const {
    lowerPrice,
    upperPrice,
    lowerSizeM2,
    upperSizeM2
  } = promotable

  const columnItems = []

  const priceRange = `${formatPrice(lowerPrice, '')} – ${formatPrice(upperPrice, '')}`
  const sizeRange = `${lowerSizeM2} sq. m – ${upperSizeM2} sq. m`

  columnItems.push({ label: 'Price range', value: priceRange })
  columnItems.push({ label: 'Size range', value: sizeRange })

  return <Column column={ columnItems }/>
}

RightColumn.propTypes = {
  promotable: projectType.isRequired
}

export default RightColumn

import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { bool } from 'prop-types'

import projectType from 'shared/types/projectType'

import stateDescriptions from './stateDescriptions'
import query from './projectVitecDocumentsQuery'
import downloadDocuments from './downloadDocuments'

const DownloadAction = ({ project, showModal }) => {
  if (!showModal) return null

  const [stateMessage, setStateMessage] = useState(stateDescriptions.init)

  const {
    loading,
    error,
    data
  } = useQuery(query, { variables: { uuid: project.uuid } })

  useEffect(
    () => downloadDocuments(loading, error, data, project, setStateMessage),
    [loading, error, data]
  )

  return stateMessage
}

DownloadAction.propTypes = {
  project: projectType.isRequired,
  showModal: bool.isRequired
}

export default DownloadAction

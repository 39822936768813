import React from 'react'

import 'pretty-checkbox/src/pretty-checkbox.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'shared/assets/icons/style.css'
import 'shared/assets/fonts/style.css'
import 'nprogress/nprogress.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import AppRouter from 'AppRouter'
import ApolloProvider from 'shared/ApolloProvider'
import AppErrorBoundary from 'shared/AppErrorBoundary'
import Notifications from 'shared/Notifications'

import './locale'
import './timeZone'

export default function App() {
  return (
    <>
      <AppErrorBoundary>
        <ApolloProvider>
          <AppRouter />
        </ApolloProvider>
      </AppErrorBoundary>
      <Notifications />
    </>
  )
}

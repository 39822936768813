import redirectTo from '../../../helpers/redirectTo'

const onSemiAutoCampaignCreated = ({ errors }) => {
  if (errors) {
    return
  }

  redirectTo('/campaign_creation_in_progress')
}

export default onSemiAutoCampaignCreated

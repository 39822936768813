import { t } from 'ttag'

const LanguageData = [
  { name: t`English`, id: 1000 },
  { name: t`German`, id: 1001 },
  { name: t`French`, id: 1002 },
  { name: t`Spanish`, id: 1003 },
  { name: t`Italian`, id: 1004 },
  { name: t`Japanese`, id: 1005 },
  { name: t`Danish`, id: 1009 },
  { name: t`Dutch`, id: 1010 },
  { name: t`Finnish`, id: 1011 },
  { name: t`Korean`, id: 1012 },
  { name: t`Norwegian`, id: 1013 },
  { name: t`Portuguese`, id: 1014 },
  { name: t`Swedish`, id: 1015 },
  { name: t`Chinese (simplified)`, id: 1017 },
  { name: t`Chinese (traditional)`, id: 1018 },
  { name: t`Arabic`, id: 1019 },
  { name: t`Bulgarian`, id: 1020 },
  { name: t`Czech`, id: 1021 },
  { name: t`Greek`, id: 1022 },
  { name: t`Hindi`, id: 1023 },
  { name: t`Hungarian`, id: 1024 },
  { name: t`Indonesian`, id: 1025 },
  { name: t`Icelandic`, id: 1026 },
  { name: t`Hebrew`, id: 1027 },
  { name: t`Latvian`, id: 1028 },
  { name: t`Lithuanian`, id: 1029 },
  { name: t`Polish`, id: 1030 },
  { name: t`Russian`, id: 1031 },
  { name: t`Romanian`, id: 1032 },
  { name: t`Slovak`, id: 1033 },
  { name: t`Slovenian`, id: 1034 },
  { name: t`Serbian`, id: 1035 },
  { name: t`Ukrainian`, id: 1036 },
  { name: t`Turkish`, id: 1037 },
  { name: t`Catalan`, id: 1038 },
  { name: t`Croatian`, id: 1039 },
  { name: t`Vietnamese`, id: 1040 },
  { name: t`Urdu`, id: 1041 },
  { name: t`Filipino`, id: 1042 },
  { name: t`Estonian`, id: 1043 },
  { name: t`Thai`, id: 1044 },
  { name: t`Bengali`, id: 1056 },
  { name: t`Persian`, id: 1064 },
  { name: t`Malayalam`, id: 1098 },
  { name: t`Marathi`, id: 1101 },
  { name: t`Malay`, id: 1102 },
  { name: t`Tamil`, id: 1130 },
  { name: t`Telugu`, id: 1131 }
]

export default LanguageData

import React from 'react'
import styled from 'styled-components'
import { components } from 'react-select'
import { shape, string } from 'prop-types'

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.825rem;
`

const OptionLabel = styled.div`
  font-weight: bold;
`

const OptionType = styled.div`
  color: #777;
  text-transform: capitalize;
`

const Description = styled.div`
  font-size: 0.825rem;
  color: #777;
`

const buildPlaceName = ({ primaryCity, region, countryName }) => {
  if (primaryCity) {
    return primaryCity
  } else {
    return [region, countryName].filter(Boolean).join(', ')
  }
}

const Option = (props) => {
  const { data } = props

  return (
    <components.Option { ...props }>
      <Title>
        <OptionLabel>{ data.name }</OptionLabel>
        <OptionType>{ data.type }</OptionType>
      </Title>
      <Description>{ buildPlaceName(data) }</Description>
    </components.Option>
  )
}

Option.propTypes = {
  data: shape({
    label: string,
    placeName: string,
    placeType: string
  }).isRequired
}

export default Option

import { buildFragmentParams } from 'shared/helpers/graphql'
import addLeadActivityMutation from 'graphql/mutations/campaign/addLeadActivity'
import leadActivityLog from 'graphql/fragments/leadActivityLog'

export default (client, lead, input) =>
  client.mutate({
    mutation: addLeadActivityMutation,
    variables: { leadUuid: lead.uuid, input },
    update: (cache, { data: { addLeadActivity: { activity } } }) => {
      const fragmentParams = buildFragmentParams(lead, leadActivityLog)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          activityLog: [
            activity,
            ...fragmentData.activityLog
          ]
        }
      })
    }
  })

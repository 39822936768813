import React  from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { arrayOf, string, func, object, shape, node, array } from 'prop-types'
import { get } from 'lodash'

import AdPreview from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/Preview/AdPreview'
import Slider from 'shared/components/ui/Slider'
import { bluishColor, buttonBorderColor } from 'shared/style/colors'

const BannerTemplateCollectionsSlider = ({
  usedBannerTemplateCollection,
  bannerTemplateCollections,
  setBannerTemplateCollection,
  creativeSet,
  className,
  promotableType,
  campaign
}) => {
  const slidesToShow = 4
  const infinite = bannerTemplateCollections.length > slidesToShow

  const settings = {
    infinite,
    slidesToShow,
    speed: 500,
    slidesToScroll: slidesToShow
  }

  return (
    <Slider { ...settings } className={ className }>
      { bannerTemplateCollections.map((bannerTemplateCollection) => {
        const buttonClassName = classNames(
          'banner-wrapper',
          { 'is-selected': usedBannerTemplateCollection?.uuid === bannerTemplateCollection?.uuid }
        )

        return (
          <button
            key={ bannerTemplateCollection.uuid }
            className={ buttonClassName }
            onClick={ () => setBannerTemplateCollection(bannerTemplateCollection) }
            type="button"
          >
            <div className="d-flex justify-content-center align-items-center">
              <AdPreview
                bannerOnly
                promotableType={ promotableType }
                previewAsset={ bannerTemplateCollection.thumbnailAsset?.thumbnail }
                banners={ [bannerTemplateCollection.thumbnailBanner] }
                color={ creativeSet.color }
                logo={ creativeSet.logo }
                type={ get(creativeSet, 'channel.type') }
                title={ creativeSet.title }
                content={ creativeSet.content }
                description={ creativeSet.description }
                domain={ creativeSet.domain }
                campaign={ campaign }
              />
            </div>
          </button>
        )
      }) }
    </Slider>
  )
}

BannerTemplateCollectionsSlider.propTypes = {
  asset: object.isRequired,
  bannerTemplateCollections: arrayOf(shape({
    name: string,
    uuid: string,
    thumbnailBanner: shape({
      uuid: string
    })
  })).isRequired,
  children: node.isRequired,
  creativeSet: object.isRequired,
  setBannerTemplateCollection: func.isRequired,
  usedBannerTemplateCollection: shape({
    uuid: string
  }).isRequired,
  banners: array,
  campaign: object,
  companyLogo: string,
  companyName: string,
  content: string,
  domain: string,
  promotableType: string,
  title: string
}

BannerTemplateCollectionsSlider.defaultProps = {
  banners: [],
  campaign: {},
  companyLogo: '',
  companyName: '',
  content: '',
  domain: '',
  promotableType: '',
  title: ''
}

export default styled(BannerTemplateCollectionsSlider)`
  .banner-wrapper {
    color: ${buttonBorderColor};
    font-size: 0.85rem;
    line-height: 1;
    width: 70px;
    background-color: transparent;
    border: none;
    padding: 0;

    &.is-selected .align-items-center {
      color: ${bluishColor};
      border: 1px solid ${bluishColor};
    }

    .align-items-center {
      border: 1px solid ${buttonBorderColor};
      border-radius: .25rem;
      width: 134px;
      height: 103px;
      overflow: hidden;

      div {
        border: none !important;
      }
    }

    img {
      overflow: hidden;
      object-fit: contain;
    }
  }
`

import React from 'react'
import { number, string, bool, func, oneOfType } from 'prop-types'

import Button from 'shared/components/ui/Button'

const PageButton = ({ page, active, disable, title, onPageChange }) => {
  return (
    <Button
      disabled={ disable }
      key={ page }
      className={ `${active && 'active'} text-nowrap` }
      onClick={ () => onPageChange(page) }>
      { title }
    </Button>
  )
}

PageButton.propTypes = {
  active: bool.isRequired,
  disable: bool.isRequired,
  onPageChange: func.isRequired,
  page: oneOfType([number, string]).isRequired,
  title: string.isRequired,
}

export default PageButton

import { gql } from 'apollo-boost'

export default gql`
  query GeolocationSearch($query: String) {
    facebookGeolocationSearch(query: $query) {
      key
      name
      type
      countryCode
      countryName
      region
      primaryCity
    }
  }
`

import React from 'react'
import { t } from 'ttag'

export const renderFloorPlans = (plans) => {
  if (plans.length === 0) return t`Not uploaded`

  return plans.map((plan, index) => {
    return (
      <p className='floorplan' key={ plan.uuid }>
        <a href={ plan?.media } target="_blank" rel="noreferrer noopener">
          { t`Floor plan` + ` ${index + 1}` }
        </a>
      </p>
    )
  })
}

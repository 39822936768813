import React from 'react'
import { func } from 'prop-types'
import { components } from 'react-select'

import ColorOption from './ColorOption'

const SingleValue = (props) => {
  const [firstSelectedOption] = props.getValue()

  return (
    <components.SingleValue { ...props }>
      <ColorOption
        color={ firstSelectedOption.value }
        name={ firstSelectedOption.label }
      />
    </components.SingleValue>
  )
}

SingleValue.propTypes = {
  getValue: func.isRequired
}

export default SingleValue

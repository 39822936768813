import gql from 'graphql-tag'

import stageUnit from 'graphql/fragments/stageUnit'

export default gql`
  fragment Building on Building {
    uuid
    name
    projectName
    projectUuid
    buildingTypes
    location {
      address
      countryCode
      zipCode
      latitude
      longitude
    }
    constructionStartPhasePeriod
    constructionStartAt
    constructionStartAtIsoFormat
    constructionEndAt
    constructionEndAtIsoFormat
    movingStartPhasePeriod
    movingStartAt
    movingStartAtIsoFormat
    movingEndAt
    movingEndAtIsoFormat
    units {
      ... StageUnit
    }
  }
  ${stageUnit}
`

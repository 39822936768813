import React from 'react'
import styled from 'styled-components'
import { components } from 'react-select'
import { array, shape } from 'prop-types'

import formatUnits from 'shared/helpers/stages/unitsSummary'

const ValueLabel = styled.span`
  font-size: 0.75rem;
`

const ValueContainer = props => {
  const {
    selectProps: { value }
  } = props

  const selectedOptions = (value || []).filter(el => el)

  return (
    <components.ValueContainer { ...props }>
      <ValueLabel>{formatUnits(selectedOptions.map(el => el.label))}</ValueLabel>
    </components.ValueContainer>
  )
}

ValueContainer.propTypes = {
  selectProps: shape({
    value: array
  }).isRequired
}

export default ValueContainer

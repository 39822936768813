import { useQuery } from 'react-apollo'
import { get }      from 'lodash'

import insightsQuery from 'graphql/queries/campaigns/insights'

export default function useInsights(campaignUuid) {
  const result = useQuery(insightsQuery, { variables: { campaignUuid } })

  result.insights = get(result, 'data.campaignInsights')

  return result
}

import React from 'react'
import { string, number, oneOfType } from 'prop-types'

import CircularGauge from 'shared/components/CircularGauge'

import { StatName, StatValue, Container } from './elements'

const Stats = ({ size, name, value, percentage, dataTestId, postfix }) => {

  return (
    <Container size={ size } data-test-id={ dataTestId }>
      <CircularGauge value={ percentage } postfix={ postfix } />
      <div>
        <StatName>{ name }</StatName>
        <StatValue>{ value }</StatValue>
      </div>
    </Container>
  )
}

Stats.propTypes = {
  name: string.isRequired,
  dataTestId: string,
  percentage: number,
  postfix: string,
  size: string,
  value: oneOfType([string, number])
}

Stats.defaultProps = {
  dataTestId: null,
  size: 'small',
  value: '',
  postfix: '%',
  percentage: 0
}

export default Stats

import React from 'react'
import { func, bool } from 'prop-types'

import Checkbox from 'shared/components/ui/Checkbox'

const FormikCheckbox = ({ onChange, value, ...props }) => {
  const handleChange = (e) => {
    onChange(e.target.checked)
  }

  return (
    <Checkbox
      onChange={ handleChange }
      checked={ value }
      { ...props }
    />
  )
}

FormikCheckbox.propTypes = {
  onChange: func.isRequired,
  value: bool
}

FormikCheckbox.defaultProps = {
  value: undefined
}

export default FormikCheckbox

import gql from 'graphql-tag'

import ad from 'graphql/fragments//ad'

export default gql`
  mutation setCreativeSetAudiences($uuid: ID!, $audienceUuids: [ID!]!) {
    setCreativeSetAudiences(uuid: $uuid, audienceUuids: $audienceUuids) {
      creativeSet {
        adsCount
        creativeSetAudiences {
          uuid
          creativeSetUuid
          audience {
            uuid
            name
            adsCount
          }
          ads {
            ... Ad
          }
        }
      }
      audiences {
        uuid
        creativeSetAudiences {
          uuid
          creativeSetUuid
        }
      }
    }
  }
  ${ad}
`

import { gql } from 'apollo-boost'

export default gql`
  query ProjectAnalytics(
    $projectUuid: ID!,
    $dataName: String!
  ) {
    projectAnalytics(
      projectUuid: $projectUuid,
      dataName: $dataName
    ) {
      data {
        date
        value
      }
    }
  }
`

import React, { useState, useCallback } from 'react'
import { useApolloClient }              from 'react-apollo'
import { toast }                        from 'react-toastify'
import { t }                            from 'ttag'

import ImageEditor from 'shared/components/ui/ImageEditor'

import UploadButton from './UploadButton'
import uploadImage  from './uploadImage'

const ImageUploader = () => {
  const client = useApolloClient()
  const [fileMeta, setFileMeta] = useState(null)
  const [fileDataURI, setFileDataURI] = useState(null)
  const uploadCroppedImage = useCallback(() => {
    uploadImage({ client, fileDataURI, fileMeta })
      .then(() => setFileDataURI(null))
      .then(() => toast.success(t`New avatar has been uploaded`))
  }, [fileDataURI])

  return (
    <>
      { fileDataURI && (
        <ImageEditor
          fileDataURI={ fileDataURI }
          setFileDataURI={ setFileDataURI }
          onUpload={ uploadCroppedImage }
        />
      ) }
      <UploadButton
        setFileDataURI={ setFileDataURI }
        setFileMeta={ setFileMeta }
        data-test-id='upload-button'
      />
    </>
  )
}

ImageUploader.propTypes = {
}

export default ImageUploader

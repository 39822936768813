import React from 'react'
import { object } from 'prop-types'
import { t } from 'ttag'
import styled from 'styled-components'

import Showings from 'shared/components/Documents/Showings'
import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'

const Viewings = ({ className, stage: { uuid, showings } }) => {

  return (
    <EmbeddedCardSection titleText={ t`Viewings` }>
      <Showings
        showFieldLabels
        readOnly={ false }
        showHeading={ false }
        showTimeSeparator={ false }
        showings={ showings }
        showableUuid={ uuid }
        showableType='Stage'
        className={ className }
        data-test-id='showing'
      />
    </EmbeddedCardSection>
  )
}

Viewings.propTypes = {
  stage: object.isRequired
}

export default styled(Viewings)`

  .form-control {
    box-shadow: none
  }

  li {
    display: flex;
    justify-content: space-evenly;
    border-top: none;
    margin-top: 0.25rem

    > .datepicker {
      width: 34%;
      margin-right: 0 !important
    }

    > .timepickers {
      width: calc(66% - 3rem);

      & > .shared-datepicker {
        margin-left: 3rem;
        width: calc(50% - 3rem)
      }
    }

    > a:last-child {
      margin-left: 2rem
    }
  }

  .add-new-showing-button {
    justify-content: flex-start !important;
    margin-top: 1rem;
  }

`

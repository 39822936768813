import { useApolloClient } from '@apollo/react-hooks'
import { t } from 'ttag'
import { toast } from 'react-toastify'

import { buildFragmentParams } from 'shared/helpers/graphql'
import projectFragment from 'graphql/fragments/project'
import updateProjectMutation from 'graphql/mutations/projects/updateDefaultTemplate'

export default function useSetTemplate(projectUuid) {
  const client = useApolloClient()

  return ({ value }) => {
    client.mutate({
      mutation: updateProjectMutation,
      variables: {
        uuid: projectUuid,
        input: { defaultTemplateUuid: value }
      },
      update: (cache, _data) => {
        const fragmentParams = buildFragmentParams(
          { __typename: 'Project', uuid: projectUuid },
          projectFragment
        )
        const fragmentData = client.readFragment(fragmentParams)

        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            defaultTemplateUuid: value
          }
        })

        toast.success(t`Project template is updated`)
      }
    })
  }
}

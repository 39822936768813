import React, { useState, useCallback } from 'react'
import { useApolloClient }              from 'react-apollo'
import { toast }                        from 'react-toastify'
import { t }                            from 'ttag'
import { string } from 'prop-types'

import ImageEditor from 'shared/components/ui/ImageEditor'
import projectType from 'shared/types/projectType'

import UploadButton     from './UploadButton'
import uploadImage from './uploadImage'

const Uploader = ({ project, label, mediaType }) => {
  const client = useApolloClient()
  const [fileMeta, setFileMeta] = useState(null)
  const [fileDataURI, setFileDataURI] = useState(null)
  const uploadCroppedImage = useCallback(() => {
    uploadImage({ client, project, fileDataURI, fileMeta, mediaType })
      .then(() => setFileDataURI(null))
      .then(() => toast.success(t`${label} has been uploaded`))
  }, [fileDataURI])

  return (
    <>
      { fileDataURI && (
        <ImageEditor
          fileDataURI={ fileDataURI }
          setFileDataURI={ setFileDataURI }
          onUpload={ uploadCroppedImage }
        />
      ) }
      <UploadButton
        setFileDataURI={ setFileDataURI }
        setFileMeta={ setFileMeta }
        data-test-id='upload-button'
      />
    </>
  )
}

Uploader.propTypes = {
  label: string.isRequired,
  mediaType: string.isRequired,
  project: projectType.isRequired
}

export default Uploader

import gql from 'graphql-tag'

export default gql`
  fragment SnapshotUnitSet on SnapshotUnitSet {
    uuid
    buildingType
    ownershipType
    unitsCount
    unitsSold
    upperSizeM2
    lowerSizeM2
    upperBedroomCount
    lowerBedroomCount
    lowerPrice
    upperPrice
    priceSubjectToChange
    sizeSubjectToChange
    bedroomsSubjectToChange
    floorNumber
  }
`

import React from 'react'
import { object } from 'prop-types'
import styled from 'styled-components'

import { localDate, displayTime } from 'shared/helpers/date'
import { labelColor } from 'shared/style/colors'

const Showing = ({ className, startDate, endDate }) => {
  if (startDate === null) return null

  return (
    <li className={ className }>
      <span className="showing-date">
        { localDate(startDate) }
      </span>
      <span className="showing-time">
        { displayTime(startDate) } - { displayTime(endDate) }
      </span>
    </li>
  )
}

Showing.propTypes = {
  endDate: object,
  startDate: object
}

Showing.defaultProps = {
  endDate: undefined,
  startDate: undefined,
}

export default styled(Showing)`
  color: ${labelColor};
  list-style: none;
  padding: .5rem 1rem 0;

  &:last-child {
    padding-bottom: .5rem;
  }

  .showing-date {
    font-weight: bolder;
    margin-right: .25rem
  }
`

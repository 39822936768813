import React  from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import { dateInThePast } from 'shared/helpers/date'
import Datepicker            from 'shared/components/ui/Datepickers/Datepicker'
import { greyishBrownColor } from 'shared/style/colors'
import { tableBorderValue }  from 'shared/style/placeholders'

import DeleteButton from './DeleteButton'
import useShowing   from './useShowing'

const Showing = ({ className, uuid, startDate, endDate }) => {
  if (startDate !== null && dateInThePast(startDate)) return null

  const {
    selectedStartDate,
    selectedEndDate,
    handleDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    minStartDate
  } = useShowing({ uuid, startDate, endDate })

  return (
    <li className={ className } data-test-id="showing">
      <div className="datepicker" data-test-id="showing-datepicker">
        <Datepicker
          showTimeInput={ false }
          minDate={ minStartDate }
          onChange={ handleDateChange }
          selected={ selectedStartDate }
        />
      </div>

      <div className="timepickers" data-test-id="showing-timepickers">
        <Datepicker
          showTimeSelect
          showTimeSelectOnly
          showTimeInput={ false }
          minDate={ minStartDate }
          selected={ selectedStartDate }
          onChange={ handleStartTimeChange }
          timeIntervals={ 15 }
          timeCaption={ null }
          dateFormat="HH:mm"
        />
        &nbsp;-&nbsp;
        <Datepicker
          showTimeSelect
          showTimeSelectOnly
          showTimeInput={ false }
          selected={ selectedEndDate }
          onChange={ handleEndTimeChange }
          timeIntervals={ 15 }
          timeCaption={ null }
          dateFormat="HH:mm"
        />

      </div>

      <DeleteButton uuid={ uuid } />
    </li>
  )
}

Showing.propTypes = {
  uuid: string.isRequired,
  endDate: string,
  startDate: string
}

Showing.defaultProps = {
  endDate: undefined,
  startDate: undefined,
}

export default styled(Showing)`
  align-items: center;
  border-top: ${tableBorderValue};
  color: ${greyishBrownColor};
  display: flex;
  list-style: none;
  padding: 0.625rem;

  &:last-child {
    border-bottom: ${tableBorderValue};
  }

  .datepicker {
    margin-right: 2rem;
    width: 6rem;
  }

  .timepickers {
    white-space: nowrap;

    .shared-datepicker {
      display: inline-block;
      width: 4rem;
    }
  }

  ${DeleteButton} {
    margin-left: .5rem;
  }
`

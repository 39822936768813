import * as Yup from 'yup'
import { t }    from 'ttag'

/* eslint-disable max-len */
// RegEx visualisation -> https://github.com/marketertechnologies/marketer/pull/10204#discussion_r1000561869
const urlSchema = /^(?:http|https):\/\/[a-z0-9_-]+(?:\.[a-z0-9_-]+)+(?:\/(?!\/))?(?:\/[a-z0-9_-]+)*(?:\/?#[a-z0-9_-]+)?(?:\/(?!\/))?(?:\?[a-z0-9_-]+=[a-z0-9_-]+(?:&[a-z0-9_-]+=[a-z0-9_-]+)*)?$/i
/* eslint-enable max-len */

export default Yup.object().shape({
  titleDraft: Yup.string().required(t`Please fill a title`),
  descriptionDraft: Yup.string().required(t`Please fill a description`)
    .max(300, t`Description must be at most 300 characters`),
  callToActionTextDraft: Yup.string().required(t`Please fill a call to action text`),
  callToActionDestinationUrlDraft: Yup.string()
    .required(t`Please fill a destination url`)
    .matches(urlSchema, t`Please provide valid destination url`),
  assets: Yup.array().min(1, t`Please add at least 1 image or video`)
})

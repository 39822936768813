import { flatten, pick, uniqBy } from 'lodash'
import { useState } from 'react'
import { useApolloClient } from 'react-apollo'

import setCreativeSetBannerTemplateCollectionMutation
  from 'graphql/mutations/campaign/creativeSets/setCreativeSetBannerTemplateCollection'

import uniqEnabledThumbnails from './uniqEnabledThumbnails'

const getDefaultPreviewAsset = (creativeSet) => {
  return uniqEnabledThumbnails(creativeSet.creatives)[0]
}

export default function useCreativeSetData({ audiences, creativeSet }) {
  const isGmp = creativeSet.type === 'gmp'
  const isPredefined = creativeSet.type === 'predefined'
  const isFacebook = creativeSet.type === 'facebook'
  const isFacebookLeadAd = creativeSet.type === 'facebook_lead_ad'
  const isFacebookDynamicCreative = creativeSet.type === 'facebook_dynamic_creative'
  const client = useApolloClient()

  const setBannerTemplateCollection = ({ uuid }) => {
    client.mutate({
      mutation: setCreativeSetBannerTemplateCollectionMutation,
      variables: {
        uuid: creativeSet.uuid,
        bannerTemplateCollectionUuid: uuid
      },
      update: (cache, { data: { setCreativeSetBannerTemplateCollection } }) => {
        setCurrentBannerTemplateCollection(
          setCreativeSetBannerTemplateCollection.creativeSet.bannerTemplateCollection
        )
      }
    })
  }

  const assets = uniqEnabledThumbnails(creativeSet.creatives)

  const [
    bannerTemplateCollection, setCurrentBannerTemplateCollection
  ] = useState(creativeSet.bannerTemplateCollection)

  const typeAudiences = audiences.filter(({ type, platformAudience: { dynamicCreative } }) => {
    const typeMatch = type === creativeSet.channelType
    const dynamicCreativeMatch = !!dynamicCreative === isFacebookDynamicCreative

    return typeMatch && dynamicCreativeMatch
  })

  const defaultPreviewAsset = getDefaultPreviewAsset(creativeSet)

  const banners = creativeSet.banners

  const defaultPreviewBanner = creativeSet.banners.filter((banner) => {
    return banner.bannerTemplateCollection?.uuid === creativeSet.bannerTemplateCollectionUuid
  })[0]

  const [previewAsset, setPreviewAsset] = useState(defaultPreviewAsset)
  const previewAssetIndex = uniqEnabledThumbnails(creativeSet.creatives).indexOf(previewAsset)
  const [previewBanner, setPreviewBanner] = useState(defaultPreviewBanner)

  const previewAssetMediaType = creativeSet.creatives
    .find(cr => cr?.assetAssignment?.asset?.thumbnail === previewAsset)
    ?.assetAssignment?.mediaType

  const setDefaultPreviewBanner = () => setPreviewBanner(defaultPreviewBanner)

  const [previewFields, setPreviewFields] = useState(
    pick(creativeSet, ['title', 'content', 'description', 'domain'])
  )

  const setPreviewField = (name, value) => setPreviewFields({ ...previewFields, [name]: value })

  const enabledAds = flatten(creativeSet.creativeSetAudiences.map(({ ads }) => ads))
    .filter(({ enabled, deletedAt }) => { return enabled && !deletedAt })

  const uniqueAds = uniqBy(enabledAds, 'asset.uuid')
  const adsCount = enabledAds.length

  return {
    assets,
    typeAudiences,
    previewAsset,
    setPreviewAsset,
    previewAssetMediaType,
    previewAssetIndex,
    previewBanner,
    banners,
    bannerTemplateCollection,
    setBannerTemplateCollection,
    setPreviewBanner,
    setDefaultPreviewBanner,
    previewFields,
    setPreviewField,
    uniqueAds,
    adsCount,
    isGmp,
    isPredefined,
    isFacebook,
    isFacebookLeadAd
  }
}

import React, { memo } from 'react'
import { t }           from 'ttag'
import { groupBy }     from 'lodash'
import { array, func } from 'prop-types'

import Card       from 'shared/components/ui/Card'
import MediaList  from 'shared/components/MediaList'
import mediaTypes from 'shared/components/Media/mediaTypes'

const Media = ({ assets, handleAssetsChange }) => {
  const assetsByType = groupBy(assets, 'mediaType')

  return (
    <Card header={ t`Add new media` } className="mt-2" >
      { mediaTypes.map((mediaType) => (
        <MediaList
          key={ mediaType }
          mediaType={ mediaType }
          assets={ assetsByType[mediaType] || [] }
          handleAssetsChange={ handleAssetsChange }
        />
      )) }
    </Card>
  )
}

Media.propTypes = {
  assets: array.isRequired,
  handleAssetsChange: func.isRequired
}

export default memo(Media, (prev, next) => (
  prev.handleAssetsChange === next.handleAssetsChange && prev.assets === next.assets
))

import React from 'react'
import { t } from 'ttag'
import { array } from 'prop-types'
import { Overlay } from 'react-bootstrap'

import Calendar
  from 'shared/assets/icons/svg/calendar.svg'
import Section
  from 'shared/components/ProjectSummary/Footer/Sections/Section'
import Body
  from 'shared/components/ProjectSummary/Footer/Sections/Body'
import useShowingsListOverlay
  from 'shared/components/ProjectSummary/Footer/Sections/Showings/useShowingsListOverlay'
import Popover
  from 'shared/components/ProjectSummary/Footer/Sections/Showings/Popover'
import ShowingsToggle
  from 'shared/components/ProjectSummary/Footer/Sections/Showings/ShowingsToggle'

import Showing from './Showing'

const Showings = ({ showings }) => {
  const {
    isOpened,
    toggle,
    close,
    target,
    container,
    sortedShowings,
    firstShowing
  } = useShowingsListOverlay(showings)

  return (
    <Section title={ t`Showings` }>
      <Body>
        <div ref={ container }>
          <Calendar />

          <ShowingsToggle
            showing={ firstShowing }
            onClick={ toggle }
            target={ target }
          />

          <Overlay
            placement="bottom"
            show={ isOpened }
            container={ container.current }
            target={ target.current }
          >
            <Popover onClose={ close }>
              <ul className="m-0 p-0">
                { sortedShowings.map((showing) => (
                  <Showing key={ showing.uuid } { ...showing } />
                )) }
              </ul>
            </Popover>
          </Overlay>
        </div>
      </Body>
    </Section>
  )
}

Showings.propTypes = {
  showings: array.isRequired
}

export default Showings

import mutation from 'graphql/mutations/campaign/cloneLandingPage'
import { buildFragmentParams } from 'shared/helpers/graphql'
import campaignLandingPagesFragment from 'graphql/fragments/campaignLandingPages'

const cloneLandingPage = (client, campaign, landingPage) =>
  client.mutate({
    mutation,
    variables: { uuid: landingPage.uuid },
    update: (cache, { data: { cloneLandingPage: { landingPage } } }) => {
      const fragmentParams = buildFragmentParams(campaign, campaignLandingPagesFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          landingPages: [
            ...fragmentData.landingPages,
            landingPage
          ]
        }
      })
    }
    
  })

export default cloneLandingPage

import React, { useState } from 'react'
import { t }               from 'ttag'
import { useQuery }        from '@apollo/react-hooks'
import styled              from 'styled-components'
import { get }             from 'lodash'
import { number }          from 'prop-types'

import projectType     from 'shared/types/projectType'
import LoadingBlock    from 'shared/components/ui/LoadingBlock'
import Tab             from 'shared/components/ui/TabBar/Tab'

import Card from '../Card'

import projectDemographicAnalyticsQuery from './projectDemographicAnalyticsQuery'
import Graph from './Graph'

const initialTab = 0
const tabsData = [
  {
    index: 0,
    roomCount: -1,
    title: t`Total`,
    name: 'total'
  },
  {
    index: 1,
    roomCount: 0,
    title: t`0-room`,
    name: 'zero'
  },
  {
    index: 2,
    roomCount: 1,
    title: t`1-room`,
    name: 'one'
  },
  {
    index: 3,
    roomCount: 2,
    title: t`2-room`,
    name: 'two'
  },
  {
    index: 4,
    roomCount: 3,
    title: t`3-room`,
    name: 'three'
  },
  {
    index: 5,
    roomCount: 4,
    title: t`4-room`,
    name: 'four'
  },
  {
    index: 6,
    roomCount: 5,
    title: t`5-room`,
    name: 'five'
  },
  {
    index: 7,
    roomCount: 6,
    title: t`6-room`,
    name: 'six'
  }
]

const Demographic = ({ className, project, stageTab }) => {
  const [selectedTab, setSelectedTab] = useState(initialTab)

  const allRooms = project.completeStages.flatMap((stage) => {
    return stage.units
  }).map((unit) => {
    return unit.roomCount ? unit.roomCount : 0
  })
  const availableRooms = [...new Set(allRooms)].sort()

  const availableTabs = tabsData.filter((tab) => {
    return tab.index === 0 || availableRooms.includes(tab.roomCount)
  })

  const { data } = useQuery(
    projectDemographicAnalyticsQuery,
    {
      variables: {
        scopedTo:  (stageTab === 0 ? 'Project' : 'Stage'),
        uuid:      (stageTab === 0 ? project.uuid : project.completeStages[stageTab - 1].uuid),
        roomCount: tabsData[selectedTab].roomCount.toString()
      }
    }
  )
  const demographicAnalyticsData = get(data, 'projectDemographicAnalytics.data', null)

  if (demographicAnalyticsData === null) {
    return (
      <Card className={ className } >
        <LoadingBlock />
      </Card>
    )
  }

  return (
    <Card className={ className } headerText={ t`Buyers demographics` }>
      <div data-test-id='demographic-card'>
        <div className={ 'd-flex sales-header' }>
          { availableTabs.map((tab) => (
            <Tab
              key={ tab.index }
              title={ tab.title }
              showArrow={ tab.index < (tabsData.length - 1) }
              active={ tab.index === selectedTab }
              onClick={ () => setSelectedTab(tab.index) }
            />
          ))}
        </div>
        {demographicAnalyticsData && (
          <div className='graphs'>
            <Graph
              data={ demographicAnalyticsData.age }
              dataTotal={ demographicAnalyticsData.total.with_age }
              title={ t`Age` }
              index={ 0 }
            />
            <Graph
              data={ demographicAnalyticsData.location }
              dataTotal={ demographicAnalyticsData.total.with_location }
              title={ t`Location` }
              index={ 1 }
            />
          </div>
        )}
      </div>
    </Card>
  )
}

Demographic.propTypes = {
  project: projectType.isRequired,
  stageTab: number.isRequired
}

export default styled(Demographic)`
  .sales-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 2rem;
  }

  .graphs {
    display: flex;
  }

  ${Tab} {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    padding-right: 0;

    &:last-child {
      border-right: none
    }

    .arrow-top {
      display: none
    }

    .arrow-bottom {
      display: none
    }
  }
`

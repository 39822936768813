import React from 'react'
import styled from 'styled-components'
import { components } from 'react-select'
import { bool, node, shape, string } from 'prop-types'
import { t } from 'ttag'
import { get } from 'lodash'

import Checkbox from 'shared/components/ui/Checkbox'

const Option = props => {
  const {
    isSelected,
    children,
    data,
    selectProps: { landingPageUuid: currentLandingPageUuid }
  } = props

  const landingPageUuid = get(data, 'landingPage.uuid')
  const landingPageName = get(data, 'landingPage.name')

  const connectionMessage = landingPageUuid
    && landingPageUuid !== currentLandingPageUuid
    && `${t`Connected to`} LP ${landingPageName}`

  return (
    <components.Option { ...props }>
      <div>{ children }</div>
      <Connection>{connectionMessage}</Connection>
      <Checkbox
        readOnly
        shadow={ false }
        checked={ isSelected }
      />
    </components.Option>
  )
}

const Connection = styled.div`
  font-size: 0.625rem;
  width: 100%;
  text-align: right;
  padding: 0 1rem;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

Option.propTypes = {
  children: node.isRequired,
  data: shape({
    landingPage: shape({
      uuid: string.isRequired
    })
  }).isRequired,
  isSelected: bool.isRequired,
  selectProps: shape({
    landingPageUuid: string.isRequired
  }).isRequired
}

export default Option

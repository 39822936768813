import React, { useCallback } from 'react'
import { t }                  from 'ttag'
import { func, string, bool } from 'prop-types'
import Select                 from 'react-select'

import { timeZones } from 'shared/helpers/date'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const options = timeZones().map(({ name, offset }) => ({
  label: `(${offset}) ${name}`,
  value: name
}))

const TimeZoneSelect = ({ onChange, name, value, ...props }) => {
  const setFieldValue = useCallback(({ value }) => {
    onChange({ target: { name, value } })
  }, [])

  return (
    <Select
      value={ options.find((option) => (option.value === value)) }
      options={ options }
      styles={ condensedSelectStyles }
      onChange={ setFieldValue }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

TimeZoneSelect.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  isInvalid: bool,
  value: string,
}

TimeZoneSelect.defaultProps = {
  isInvalid: false,
  value: undefined,
}

export default TimeZoneSelect

import gql from 'graphql-tag'

export default gql`
  mutation createCampaignLandingPageAgent(
    $landingPageUuid: ID!
  ) {
    createCampaignLandingPageAgent(
      landingPageUuid: $landingPageUuid
    ) {
      agent {
        uuid
        name
        phoneNumber
        email
        title
      }
    }
  }
`

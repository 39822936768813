import React           from 'react'
import { useMutation } from 'react-apollo'
import { t }           from 'ttag'
import { toast }       from 'react-toastify'

import signOutSessionsMutation from 'graphql/mutations/users/signOutSessions'
import sessionsQuery           from 'graphql/queries/users/sessions'
import Button                  from 'shared/components/ui/Button'

const SignOutSessionsButton = () => {
  const [signOutSessions, { loading }] = useMutation(signOutSessionsMutation, {
    refetchQueries: [{ query: sessionsQuery }],
    update: (_cache, _data) => {
      toast.success(t`Sign out all other sessions successfully`)
    }
  })

  return (
    <Button onClick={ signOutSessions } disabled={ loading }>
      { t`Sign out all other sessions` }
    </Button>
  )
}

export default SignOutSessionsButton

import React from 'react'
import { number, object } from 'prop-types'
import styled from 'styled-components'
import { t } from 'ttag'

const Categories = ({ className, sources, maxNumberOfSources }) => {
  const sortedSources = Object.entries(sources).sort((a, b) => b[1] - a[1])
  const displayableSources = sortedSources.slice(0, maxNumberOfSources)
  const otherSourcesCount = sortedSources
    .slice(maxNumberOfSources)
    .reduce((acc, [_, count]) => acc + count, 0)

  if (otherSourcesCount > 0) { displayableSources.push([t`Other`, otherSourcesCount]) }

  return (
    <div className={ className }>
      {
        displayableSources.map(([source, leadsCount]) => {
          return (
            <div className="source" key={ source } >
              <div className="label">{source}:</div>
              <div className="count">{leadsCount}</div>
            </div>
          )
        })
      }
    </div>
  )
}

Categories.propTypes = {
  maxNumberOfSources: number.isRequired,
  sources: object.isRequired
}

export default styled(Categories)`
  font-size: 0.75rem;
  width: 150px;

  .source {
    display: flex;
  }

  .label {
    flex-basis: 80%
  }

  .count {
    flex-basis: 20%;
    font-weight: bold;
    text-align: right
  }

`

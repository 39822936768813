import React from 'react'
import { t } from 'ttag'
import { number } from 'prop-types'

import Body    from './Body'
import Section from './Section'

const Campaigns = ({ count, live }) => {
  const title = t`${count} (${live} live)`

  return (
    <Section title={ t`Campaigns` }>
      <Body title={ title } onCLick={ () => {} } />
    </Section>
  )
}

Campaigns.propTypes = {
  count: number.isRequired,
  live: number.isRequired,
}

export default Campaigns

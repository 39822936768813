import React, { useState, useCallback } from 'react'
import { useApolloClient }              from 'react-apollo'
import { toast }                        from 'react-toastify'
import { t }                            from 'ttag'
import { func, object }                 from 'prop-types'

import ImageEditor from 'shared/components/ui/ImageEditor'

import UploadButton from './UploadButton'
import uploadImage  from './uploadImage'

const Uploader = ({ agent, refetchLandingPage }) => {
  const client = useApolloClient()
  const [fileMeta, setFileMeta] = useState(null)
  const [fileDataURI, setFileDataURI] = useState(null)
  const mediaType = 'image'
  const uploadCroppedImage = useCallback(() => {
    uploadImage({ client, agent, fileDataURI, fileMeta, mediaType })
      .then(() => setFileDataURI(null))
      .then(() => toast.success(t`Agent Picture has been uploaded`))
      .then(() => { refetchLandingPage() })
  }, [fileDataURI])

  return (
    <>
      { fileDataURI && (
        <ImageEditor
          fileDataURI={ fileDataURI }
          setFileDataURI={ setFileDataURI }
          onUpload={ uploadCroppedImage }
        />
      )}
      <UploadButton
        setFileDataURI={ setFileDataURI }
        setFileMeta={ setFileMeta }
        data-test-id='upload-button'
      />
    </>
  )
}

Uploader.propTypes = {
  agent: object.isRequired,
  refetchLandingPage: func.isRequired
}

export default Uploader

import React    from 'react'
import { func } from 'prop-types'

import BigNumber from 'shared/components/ui/ThriftyInput/BigNumber'

const FormikBigNumber = ({ onChange, onBlur: _, ...props }) => {
  return (
    <BigNumber onUpdate={ onChange } { ...props } />
  )
}

FormikBigNumber.propTypes = {
  onChange: func.isRequired,
  onBlur: func
}
FormikBigNumber.defaultProps = {
  onBlur: undefined
}

export default FormikBigNumber

import { useApolloClient } from 'react-apollo'

import geolocationSearch      from 'graphql/queries/google/geolocationSearch'
import useCurrentCampaignUuid from 'shared/hooks/useCurrentCampaignUuid'

export default function useGeolocationSearch() {
  const campaignUuid = useCurrentCampaignUuid()
  const client = useApolloClient()

  const search = (query) => (
    client
      .query({
        query: geolocationSearch,
        variables: { query, campaignUuid }
      })
      .then(
        ({ data }) => data.googleGeolocationSearch
      )
  )

  return search
}

import React            from 'react'
import styled           from 'styled-components'
import { components }   from 'react-select'
import { array, shape } from 'prop-types'

const ValueLabel = styled.span`
  font-size: 0.9rem
  white-space: nowrap
`

const ValueContainer = props => {
  const {
    options,
    selectProps: { value }
  } = props

  const selectedValues = value.map(({ value }) => value)
  const label = options.reduce((names, { value, label }) => {
    if (selectedValues.includes(value)) {
      names.push(label)
    }

    return names
  }, []).join(', ')

  return (
    <components.ValueContainer { ...props }>
      <ValueLabel>{label}</ValueLabel>
    </components.ValueContainer>
  )
}

ValueContainer.propTypes = {
  options: array.isRequired,
  selectProps: shape({
    value: array
  }).isRequired
}

export default ValueContainer

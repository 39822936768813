import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 960px;

  a.no-text-decoration {
    text-decoration: none
  }

  a {
    padding-bottom: 2rem;
  }

  a .card {
    margin: 0 2.5rem 0 0;
  }

  a:nth-child(5n) .card {
    margin-right: 0;
  }
`

import gql from 'graphql-tag'

export default gql`
  query Campaign($uuid: ID!) {
    campaign(uuid: $uuid) {
      goalType
      goalNumber
      locale
      countryCode
      durationInDays
      name
      product {
        uuid
      }
    }
  }
`

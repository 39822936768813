import gql from 'graphql-tag'

export default gql`
  mutation duplicateCampaign(
    $attributes: CampaignInputType!
    $goals: CampaignGoalsInputType
    $blueprintTemplateUuid: ID,
    $sourceCampaignUuid: ID!,
  ) {
    duplicateCampaign(
      attributes: $attributes,
      goals: $goals,
      blueprintTemplateUuid: $blueprintTemplateUuid
      sourceCampaignUuid: $sourceCampaignUuid
    ) {
      errors
      campaign {
        uuid
      }
    }
  }
`

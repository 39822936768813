import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { t } from 'ttag'

import LoadingBlock from 'shared/components/ui/LoadingBlock'
import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'
import Navbar from 'shared/components/ui/Navbar'

import ProjectStatusTab from './ProjectStatusTab'

const tabs = {
  under_development: t`Under development`,
  coming_for_sale: t`Coming for sale`,
  for_sale: t`For sale`
}

const ProjectInformationStatus = ({ project }) => {
  const [snapshots, setSnapshots] = useState(project.snapshots)
  const [currentItem, setCurrentItem] = useState(project.saleState)

  useEffect(() => {
    setSnapshots(project.snapshots)
  }, project.snapshots)

  const navItems = Object.keys(tabs).map(key => ({ type: key, title: tabs[key] }))
  const getCurrentSnapshot = useCallback(() => {
    return snapshots.find(value => value.saleState === currentItem)
  }, [currentItem, snapshots])

  const [currentSnapshot, setCurrentSnapshot] = useState(() => getCurrentSnapshot())

  useEffect(() => {
    setCurrentSnapshot(getCurrentSnapshot())
  }, [currentItem, snapshots])

  useEffect(() => {
    setCurrentItem(project.saleState)
  }, [project.saleState])

  useEffect(() => {
    setCurrentSnapshot(getCurrentSnapshot())
  }, [currentItem, snapshots])

  if (snapshots.length === 0 || !currentSnapshot) {
    return (<LoadingBlock />)
  }

  return (
    <EmbeddedCardSection titleText={ t`Project status information` }>
      <Navbar
        navItems={ navItems }
        currentItem={ currentItem }
        data-test-id='navbar'
        setCurrentItem={ setCurrentItem } />
      { currentSnapshot && (<ProjectStatusTab
        className="mt-3"
        project={ project }
        data-test-id='status-tab'
        snapshots={ snapshots }
        setSnapshots={ setSnapshots }
        activeTab={ currentItem }
        tabs={ tabs }
        currentSnapshot={ currentSnapshot }
      />) }
    </EmbeddedCardSection>
  )
}

ProjectInformationStatus.propTypes = {
  project: PropTypes.object.isRequired
}

export default ProjectInformationStatus

import styled from 'styled-components'

import { cardBoxShadow }                      from 'shared/style/placeholders'
import { greyishWhiteColor, textHeaderColor } from 'shared/style/colors'

const FormContainer = styled.div`
  ${cardBoxShadow};

  background-color: white;
  border-radius: 4px;
  width: 37rem;

  .form-header {
    padding: 1.875rem;
    font-size: 1.375rem;
    letter-spacing 0.01375rem;
    text-align: center;
  }

  .form-group {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .form-footer {
    align-items: center;
    background-color: ${greyishWhiteColor};
    display: flex;
    height: 3.625rem;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .btn-back {
    color: ${textHeaderColor};
    cursor: pointer;
    text-decoration: underline;
  }
`

export default FormContainer

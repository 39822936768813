import React, { useState }  from 'react'
import styled               from 'styled-components'
import { t }                from 'ttag'
import { Row, Col }         from 'react-bootstrap'

import PageHeader           from 'shared/components/ui/PageHeader'
import { canRead }          from 'shared/helpers/auth'
import redirectTo           from 'shared/helpers/redirectTo'

import TotalLeadsChart      from './TotalLeadsChart'
import LeadsStats           from './LeadsStats'
import CtrChart             from './CtrChart'
import CplChart             from './CplChart'
import LeadSourcesChart     from './LeadSourcesChart'
import LcrChart             from './LcrChart'
import ActiveCampaignsChart from './ActiveCampaignsChart'
import AdSpendChart         from './AdSpendChart'
import ImpressionsChart     from './ImpressionsChart'
import VisitorsChart        from './VisitorsChart'
import DashboardFilters     from './Filters'

const Dashboard = ({ className }) => {
  const [filter, setFilter ] = useState({
    start_date: null, end_date: null, project_uuid: null
  })

  if (!canRead('analytics')) {
    if (canRead('projects')) redirectTo('/projects')
    else if (canRead('sales_reports')) redirectTo('/sales_reports')
    else if (canRead('campaigns')) redirectTo('/campaigns')
    else redirectTo('/new')
  }

  return (
    <div className={ className }>
      <PageHeader title={ t`Analytics` }>
        <DashboardFilters filter={ filter } setFilter={ setFilter } />
      </PageHeader>

      <Row className='dashboard-row'>
        <Col className='dashboard-col' sm={ 8 }>
          <TotalLeadsChart filter={ filter } />
        </Col>

        <Col className='dashboard-col' sm={ 4 }>
          <LeadsStats filter={ filter } />
        </Col>
      </Row>

      <Row className='dashboard-row'>
        <Col className='dashboard-col'>
          <CplChart filter={ filter } />
        </Col>

        <Col className='dashboard-col'>
          <CtrChart filter={ filter }/>
        </Col>
      </Row>

      <Row className='dashboard-row'>
        <Col className='dashboard-col'>
          <LeadSourcesChart filter={ filter }/>
        </Col>

        <Col className='dashboard-col'>
          <LcrChart filter={ filter }/>
        </Col>
      </Row>

      <Row className='dashboard-row'>
        <Col className='dashboard-col'>
          <ActiveCampaignsChart filter={ filter } />
        </Col>

        <Col className='dashboard-col'>
          <AdSpendChart filter={ filter } />
        </Col>
      </Row>

      <Row className='dashboard-row'>
        <Col className='dashboard-col'>
          <ImpressionsChart filter={ filter } />
        </Col>

        <Col className='dashboard-col'>
          <VisitorsChart filter={ filter } />
        </Col>
      </Row>
    </div>
  )
}

export default styled(Dashboard)`
  .form-control {
    box-shadow: none;
  }

  [data-test-id=project-select-wrapper] > div > div {
    min-height: 36px;
  }

  [data-test-id=project-select-wrapper] {
    font-size: .9rem;
    min-width: 200px;
  }

  h2 + div {
    display: inherit;
    font-size: 0.9rem
    .shared-datepicker {
      max-width: 120px;
      margin-left: 0.5rem;
    }
  }

  .dashboard-row {
    margin-bottom: 0.5rem;
    margin-right: -0.25rem;
    margin-left: -0.25rem
  }

  .dashboard-col {
    padding-left: 0.25rem;
    padding-right: 0.25rem
  }
`

const imagesCache = {}

/**
 * Caches images in base64 to avoid image reloading on component's rerender
 * @param url of the image
 * @return string data url or regular url
 */
export default function getImageUrl(url) {
  if (imagesCache[url]) {
    return imagesCache[url]
  }

  fetch(url)
    .then(response => response.blob())
    .then((response) => {
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        imagesCache[url] = fileReader.result
      }
      fileReader.readAsDataURL(response)
    })

  return url
}

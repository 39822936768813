import { useMutation }      from 'react-apollo'
import { useFormikContext } from 'formik'

import updateStageMutation from 'graphql/mutations/projects/stages/updateStage'

export default function useDetailsForm(stageUuid) {
  const formikContext = useFormikContext()
  const [mutate] = useMutation(updateStageMutation)

  const updateStage = (input, location) => (
    mutate({
      variables: {
        stageUuid,
        input: input,
        location: location
      }
    })
  )

  const createFieldChangeHandler = (fieldName, onValidCallback) => (value) => {
    formikContext.setFieldValue(fieldName, value)
    formikContext.setFieldTouched(fieldName, true)
    formikContext.validateForm({ ...formikContext.values, [fieldName]: value }).then((errors) => {
      if (!errors[fieldName]) {
        onValidCallback(fieldName, value)
      }
    })
  }

  const onNameChange = createFieldChangeHandler('name', (fieldName, value) => {
    updateStage({ [fieldName]: value })
  })

  const onAddressChange = createFieldChangeHandler('address', (fieldName, value) => {
    updateStage({}, { [fieldName]: value })
  })

  const onCountryCodeChange = createFieldChangeHandler('countryCode', (fieldName, value) => {
    formikContext.setFieldTouched('zipCode', true)
    formikContext.setFieldTouched('location', true)
    updateStage({}, { [fieldName]: value })
  })

  const onZipCodeChange = createFieldChangeHandler('zipCode', (fieldName, value) => {
    formikContext.setFieldTouched('location', true)
    updateStage({}, { [fieldName]: value })
  })

  const onLocationChange = createFieldChangeHandler('location', (fieldName, value) => {
    updateStage({}, value)
  })

  const onBuildingTypesChange = createFieldChangeHandler('buildingTypes', (fieldName, value) => {
    updateStage({ [fieldName]: value })
  })

  const onUnitsCountChange = createFieldChangeHandler('unitsCount', (fieldName, value) => {
    updateStage({ [fieldName]: Number(value) })
  })

  const onUnitsSoldChange = createFieldChangeHandler('unitsSold', (fieldName, value) => {
    updateStage({ [fieldName]: Number(value) })
  })

  const onLowerPriceChange = createFieldChangeHandler('lowerPrice', (fieldName, value) => {
    updateStage({ [fieldName]: Number(value) })
  })

  const onUpperPriceChange = createFieldChangeHandler('upperPrice', (fieldName, value) => {
    updateStage({ [fieldName]: Number(value) })
  })

  const onPriceRangeIsSubjectToChangeChange = createFieldChangeHandler(
    'priceRangeIsSubjectToChange',
    (fieldName, value) => {
      updateStage({ [fieldName]: value })
    }
  )

  const onLowerSizeM2Change = createFieldChangeHandler('lowerSizeM2', (fieldName, value) => {
    updateStage({ [fieldName]: Number(value) })
  })

  const onUpperSizeM2Change = createFieldChangeHandler('upperSizeM2', (fieldName, value) => {
    updateStage({ [fieldName]: Number(value) })
  })

  const onSizeIsSubjectToChangeChange = createFieldChangeHandler(
    'sizeRangeIsSubjectToChange',
    (fieldName, value) => {
      updateStage({ [fieldName]: value })
    }
  )

  const onAttractivenessChange = createFieldChangeHandler('attractiveness', (fieldName, value) => {
    updateStage({ [fieldName]: value[0] })
  })

  return {
    ...formikContext,
    onNameChange,
    onAddressChange,
    onCountryCodeChange,
    onZipCodeChange,
    onLocationChange,
    onBuildingTypesChange,
    onUnitsCountChange,
    onUnitsSoldChange,
    onLowerPriceChange,
    onUpperPriceChange,
    onPriceRangeIsSubjectToChangeChange,
    onLowerSizeM2Change,
    onUpperSizeM2Change,
    onSizeIsSubjectToChangeChange,
    onAttractivenessChange
  }
}

import React from 'react'

const ShadowDefinition = () => (
  <defs>
    <filter
      id="drop-shadow"
      x="-40%"
      y="-40%"
      height="180%"
      width="180%"
    >
      <feDropShadow dx="0" dy="5" stdDeviation="9" floodColor="rgba(0, 0, 0, 0.2)"/>
    </filter>
  </defs>
)

export default ShadowDefinition

import React, { useState } from 'react'
import { useApolloClient, useQuery } from 'react-apollo'

import projectType from 'shared/types/projectType'
import ProjectSummary from 'shared/components/ProjectSummary'
import Leads from 'shared/components/LeadsList'
import { canUpdate, isUserAdmin } from 'shared/helpers/auth'
import updateProjectMutation  from 'graphql/mutations/projects/update'
import projectAssetAssignments from 'graphql/queries/projectAssetAssignments'

import Actions          from '../Actions'
import EditProjectModal from '../Regular/EditProjectModal'
import Breadcrumbs      from '../Breadcrumbs'
import CampaignsList    from '../CampaignsList'
import Analytics        from '../Analytics'
import Stakeholders     from '../Stakeholders'
import ShowExtraUnits   from '../ShowExtraUnits'
import Offers from '../Offers'
import { isProjectOffersEnabled } from '../helpers'

import Showings     from './Showings'
import Stages       from './Stages'
import Buildings    from './Buildings'
import ProjectMedia from './ProjectMedia'

const footerComponents = { Showings }

const disabledCompanyNames = [
  'DNB'
]

const Readonly = ({ project }) => {
  const [isEditing, setIsEditing] = useState(false)

  const client = useApolloClient()

  const { data, loading } = useQuery(
    projectAssetAssignments,
    {
      variables: { uuid: project.uuid }
    }
  )

  if (loading) {
    return null
  }

  const buildCompaniesInputs = (companies) => {
    return companies.filter((company) => {
      return company.empty === undefined || !company.empty
    }).map((company) => {
      return {
        uuid: company.uuid,
        category: company.category
      }
    })
  }

  const handleSave = input => {
    const districtUuid = input.districtUuid
    const companiesInputs = buildCompaniesInputs(input.companies)
    const { countryCode, address, zipCode, latitude, longitude } = input.location

    delete(input.districtUuid)
    delete(input.location)
    delete(input.companies)

    client.mutate({
      mutation: updateProjectMutation,
      variables: {
        uuid: project.uuid,
        districtUuid, input,
        location: { countryCode, address, zipCode, latitude, longitude },
        companies: companiesInputs
      }
    })

    setIsEditing(false)
  }

  const isSnapshotEnabled = (project) => {
    return project.product.company.enabledFeatures.includes('snapshot')
  }

  const isProjectNewsEnabled = (project) => {
    return project.product.company.enabledFeatures.includes('project_news')
  }

  const isPropertyPickerEnabled = (project) => {
    return project.product.company.enabledFeatures.includes('property_picker')
  }

  const companyName = project.product.company.name
  const available = isUserAdmin() || !disabledCompanyNames.includes(companyName)

  return (
    <div>
      <Breadcrumbs name={ project.name }/>

      <div className="position-relative">
        <ProjectSummary
          project={ project }
          footerComponents={ footerComponents }
          disabled={ !available }
        />
        <Actions
          available={ available }
          isSnapshotEnabledFlag={ isSnapshotEnabled(project) }
          isSalesReportEnabledFlag={ project.salesReportEnabled }
          isProjectNewsEnabledFlag={ isProjectNewsEnabled(project) }
          isPropertyPickerEnabledFlag={ isPropertyPickerEnabled(project) }
          projectUuid={ project.uuid }
          projectName={ project.name }
          setIsEditing={ setIsEditing }
        />
      </div>

      <Stakeholders
        project={ project }
        disabled={ !available }
      />

      {canUpdate('sales_reports') && (<ShowExtraUnits project={ project } />)}

      {available && (
        <div>
          <CampaignsList projectUuid={ project.uuid } />

          <Analytics projectUuid={ project.uuid } />

          <Stages collapsed stages={ project.completeStages } />

          <Buildings collapsed buildings={ project.completeBuildings } />

          <Leads projectUuid={ project.uuid } />

          {isProjectOffersEnabled(project)
            && <Offers collapsed className="offers-card" projectUuid={ project.uuid } />}

          <ProjectMedia
            assetAssignments={ data.project.assetAssignments }
            showings={ project.showings }
            projectUuid={ project.uuid }
          />

          <EditProjectModal
            project={ project }
            show={ isEditing }
            onHide={ () => setIsEditing(false) }
            onSave={ handleSave }
          />
        </div>
      )}
    </div>
  )
}

Readonly.propTypes = {
  project: projectType.isRequired
}

export default Readonly

import { gql } from 'apollo-boost'

export default gql`
  mutation attachImageToUser(
    $blobId: String!
  ) {
    attachImageToUser(
      blobId: $blobId
    ) {
      user {
        avatars {
          createdAt
          url
        }
      }
    }
  }
`

import React from 'react'

import stageType from 'shared/types/stageType'
import Card from 'shared/components/ui/Card'
import Details from 'shared/components/ProductDetails/StageDetails/Details'

const StageCard = ({ stage, ...props }) => {
  const initialTouched = {
    name: true,
    address: true,
    countryCode: true,
    zipCode: true,
    location: {
      latitude: true,
      longitude: true
    },
    buildingTypes: true,
    unitsCount: true,
    unitsSold: true,
    lowerPrice: true,
    upperPrice: true,
    priceRangeIsSubjectToChange: true,
    lowerSizeM2: true,
    upperSizeM2: true,
    sizeRangeIsSubjectToChange: true,
    attractiveness: true
  }

  return (
    <Card className='mb-2'>
      <Details
        validateOnMount
        product={ stage.product }
        initialTouched={ initialTouched }
        { ...props }
      />
    </Card>
  )
}

StageCard.propTypes = {
  stage: stageType.isRequired
}

export default StageCard

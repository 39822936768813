export default function buildInitialValues({ ...user }) {
  const phoneNumber = user.phoneNumber || {}
  const settings    = user.settings || {}

  return {
    email: user.email,
    name: user.name,
    phoneCode: phoneNumber.code,
    phoneNumber: phoneNumber.number,
    defaultLanguage: settings.defaultLanguage,
    timezone: settings.timezone,
  }
}

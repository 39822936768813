const emptyCompany = () => {
  const timestamp = (new Date()).getTime().toString()

  return {
    uuid:     timestamp,
    category: null,
    empty:    true
  }
}

export default function companyHandlers(companies, setCompanies) {
  const addCompany = () => {
    setCompanies([...companies, emptyCompany()])
  }

  const updateCompanyUuid = (existedCompany, companyUuid) => {
    setCompanies(companies.map((company) => {
      if (company.uuid === existedCompany.uuid) {
        company.uuid = companyUuid
        company.empty = false
      }
      return company
    }))
  }

  const updateCompanyCategory = (existedCompany, category) => {
    setCompanies(companies.map((company) => {
      if (company.uuid === existedCompany.uuid) {
        company.category = category
      }
      return company
    }))
  }

  return {
    addCompany,
    updateCompanyUuid,
    updateCompanyCategory
  }
}

import React           from 'react'
import { array, bool } from 'prop-types'
import styled          from 'styled-components'

import Card            from 'shared/components/ui/Card'
import Header          from 'shared/components/ui/Card/StaticHeader'
import ResourceQuery   from 'shared/components/ResourceQuery'
import BuildingDetails from 'shared/components/ProductDetails/BuildingDetails'
import buildingQuery   from 'shared/components/NewBuilding/buildingQuery'

const Buildings = ({ buildings, collapsed, ...props }) => {
  return (
    <>
      {
        buildings.map((building) => (
          <Card
            collapsible
            className='mb-2 stage-card'
            collapsed={ collapsed }
            key={ building.uuid }
            dataTestId={ `building-${building.uuid}-panel` }
            header={ (
              <div className="d-flex justify-content-between">
                <Header className="align-self-center">
                  { building.name }
                </Header>
              </div>
            ) }
          >
            <ResourceQuery
              query={ buildingQuery }
              variables={{ uuid: building.uuid }}
              fetchPolicy='cache-first'
            >
              {building => (
                <BuildingDetails building={ building } { ...props } />
              )}
            </ResourceQuery>
          </Card>
        ))
      }
    </>
  )
}

Buildings.propTypes = {
  buildings: array,
  collapsed: bool
}

Buildings.defaultProps = {
  buildings: [],
  collapsed: false
}

export default styled(Buildings)`
  .missing-data-indicator {
    margin: 0
  }
`

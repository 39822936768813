import React, { useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { shape, array, func } from 'prop-types'

import stageType from 'shared/types/stageType'
import EditUnitFloorplansModal
  from 'shared/components/ProductDetails/StageDetails/Units/EditUnitFloorplansModal'

import Table from './Table'
import NewItemButton from './NewItemButton'
import createUnit from './createUnit'
import updateUnit from './updateUnit'
import deleteUnit from './deleteUnit'

const Units = ({ product, errors, validate }) => {
  const [isUnitFloorplansEditing, setIsUnitFloorplansEditing] = useState(false)
  const [currentUnit, setCurrentUnit] = useState(null)

  const stage = product.promotable
  const client = useApolloClient()

  // TODO: Snapshot validation
  const isSnapshotEnabled = product.company.enabledFeatures.includes('snapshot')

  const handleAddClick = () => createUnit(client, stage)

  const handleUpdate = ({ uuid, attributes }) => updateUnit(client, uuid, attributes).
    then(({ data: { updateStageUnit: { unit } } }) => {
      if (!validate || isSnapshotEnabled) { return }
      validate({ updated: unit })
    })

  const handleDelete = ({ uuid }) => deleteUnit(client, stage, uuid)

  const uploadFloorPlanClick = (unit) => {
    setCurrentUnit(unit)
    setIsUnitFloorplansEditing(true)
  }

  return (
    <>
      <Table
        stage={ stage }
        onUpdate={ handleUpdate }
        onDelete={ handleDelete }
        errors={ errors }
        isSnapshotEnabled={ isSnapshotEnabled }
        handleUploadFloorPlanClick={ uploadFloorPlanClick }
      />

      <NewItemButton onClick={ handleAddClick } />

      {currentUnit && (
        <EditUnitFloorplansModal
          unit={ currentUnit }
          show={ isUnitFloorplansEditing }
          onHide={ () => setIsUnitFloorplansEditing(false) }
        />
      )}
    </>
  )
}

Units.propTypes = {
  product: shape({
    promotable: stageType.isRequired
  }).isRequired,
  errors: array,
  validate: func
}

Units.defaultProps = {
  errors: [],
  validate: undefined
}

export default Units

import React, { useCallback } from 'react'
import { t }                  from 'ttag'
import { func }               from 'prop-types'
import styled                 from 'styled-components'

import { textGreyColor } from 'shared/style/colors'
import PlusIcon from 'shared/components/ui/icons/PlusIcon'

const NewChannelButton = ({ className, onClick }) => {
  const handleClick = useCallback((e) => {
    e.preventDefault()
    onClick()
  }, [onClick])

  return (
    <div className={ className }>
      <PlusIcon />

      <a href="#" onClick={ handleClick } data-test-id='add-new-channel' >
        { t`Add new channel` }
      </a>
    </div>
  )
}

NewChannelButton.propTypes = {
  onClick: func.isRequired,
}

export default styled(NewChannelButton)`
  margin: .5rem 0 0 0

  a {
    color: ${textGreyColor}
    display: inline-flex
    font-size: 0.75rem
    align-items: center
  }

  ${PlusIcon} {
    margin-right: .75rem
  }
`

import { toUpper } from 'lodash'

import targetingValidationSchema from './targetingValidationSchema'
import minChannelSpending from './minChannelSpending'

const MIN_SPENDING_PER_DAY = {
  'AUD': 2,
  'NOK': 12,
  'SEK': 12,
  'USD': 1.5,
  'GBP': 1.2,
  'EUR': 1.3
}

export default ({ payload }) => {
  if (payload.length === 0) return targetingValidationSchema.validateSync([])

  const audiencesPayload = payload.map((audience) => (
    {
      type: audience.platformAudience.__typename.toLowerCase(),
      ...audience
    }
  ))
  const campaign = audiencesPayload[0].campaign
  const currency = toUpper(campaign.product.promotable.currency)
  const minSpending = MIN_SPENDING_PER_DAY[currency]

  const minFacebookChannelSpending = minChannelSpending(campaign, 'facebook', minSpending)
  const minInstagramChannelSpending = minChannelSpending(campaign, 'instagram', minSpending)
  const minPortalChannelSpending = minChannelSpending(campaign, 'portal', minSpending)

  targetingValidationSchema.validateSync(audiencesPayload, {
    abortEarly: false,
    context: {
      currency: currency,
      minSpending: minSpending,
      minFacebookChannelSpending: minFacebookChannelSpending,
      minInstagramChannelSpending: minInstagramChannelSpending,
      minPortalChannelSpending: minPortalChannelSpending
    }
  })
}

import React  from 'react'
import styled from 'styled-components'
import { arrayOf, shape, string } from 'prop-types'

import { textColor }       from 'shared/style/colors'
import { cardBorderValue } from 'shared/style/placeholders'
import { cardPaddingYSize, cardPaddingXSize } from 'shared/style/sizes'

import AvatarRow from './AvatarRow'

const ImagesTable = ({ className, avatars }) => {
  return (
    <div className={ className }>
      { avatars.map((avatar) => (
        <AvatarRow key={ avatar.url } { ...avatar } />
      )) }
    </div>
  )
}

ImagesTable.propTypes = {
  avatars: arrayOf(shape({
    createdAt: string.isRequired,
    url: string.isRequired,
  })).isRequired
}

export default styled(ImagesTable)`
  margin-bottom: -${cardPaddingYSize};
  margin-top: -${cardPaddingYSize};
  margin-left: -${cardPaddingXSize};
  margin-right: -${cardPaddingXSize};

  .avatar-row {
    border-bottom: ${cardBorderValue};
    align-items: center
    display: flex
    justify-content: flex-start
    padding: .75rem ${cardPaddingXSize};
  }
  
  .avatar-row:last-child {
    border-bottom: none;
  }

  .avatar-url {
    border-radius: 2.375rem
    height: 2.375rem
    margin-right: 1rem
    position: relative
    overflow: hidden
    width: 2.375rem
  }
  
  .avatar-url img {
    position: absolute
    top: 0
    left: 0
    height: 100%
    object-fit: cover
    object-position: center
    width: 100%
  }
  
  .avatar-created-at {
    color: ${textColor}
    font-size: .875rem
  }
`

import { t } from 'ttag'

import creativeSetAudienceFormatter from './creativeSetAudienceFormatter'
import creativeSetAudienceSortFunc  from './creativeSetAudienceSortFunc'
import adPreviewFormatter           from './adPreviewFormatter'
import numberFormatter              from './numberFormatter'

export default [{
  dataField: 'uuid',
  hidden: true
}, {
  dataField: 'asset',
  text: t`Preview`,
  formatter: adPreviewFormatter
}, {
  text: t`Creative set/audience`,
  dataField: 'creativeSetAudience',
  formatter: creativeSetAudienceFormatter,
  sortFunc: creativeSetAudienceSortFunc,
  sort: true
}, {
  dataField: 'totalImpressions',
  text: t`Impressions`,
  formatter: numberFormatter,
  sort: true
}, {
  dataField: 'totalClicks',
  text: t`Clicks`,
  formatter: numberFormatter,
  sort: true
}, {
  dataField: 'averageCpl',
  text: t`CPL`,
  formatter: numberFormatter,
  sort: true
}, {
  dataField: 'ctr',
  text: t`CTR`,
  formatter: numberFormatter,
  sort: true
}, {
  dataField: 'cpc',
  text: t`CPC`,
  formatter:  numberFormatter,
  sort: true
}, {
  dataField: 'leadsCount',
  text: t`Leads`,
  formatter: numberFormatter,
  sort: true
}]

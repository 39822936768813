import React from 'react'
import styled from 'styled-components'
import AsyncSelect from 'react-select/async'
import { t } from 'ttag'
import { func, array } from 'prop-types'

import useGeolocationSearch from './useGeolocationSearch'
import Option from './Option'

const LocationSelect = ({ onChange, currentLocations, ...props }) => {
  const search = useGeolocationSearch()

  const handleChange = ({ key, type }) => {
    onChange({
      key,
      type
    })
  }

  const loadOptions = (query, callback) => {
    search(query).then((locations) => {
      const availableLocations = locations.filter((newLocation) => {
        return !currentLocations.find((location) => (newLocation.key === location.externalId))
      })
      callback(availableLocations.map((location) => ({
        value: location.key,
        ...location
      })))
    })
  }

  return (
    <Wrapper data-test-id='location-select-wrapper'>
      <AsyncSelect
        components={{ Option }}
        loadOptions={ loadOptions }
        placeholder={ t`Type to add more locations` }
        noOptionsMessage={ () => t`No options` }
        value={ null }
        onChange={ handleChange }
        { ...props }
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-left: 0.5rem;
  flex-grow: 1;
`

LocationSelect.propTypes = {
  currentLocations: array,
  onChange: func
}

LocationSelect.defaultProps = {
  onChange: () => {},
  currentLocations: []
}

export default LocationSelect

import React, { useState, useRef } from 'react'
import { t } from 'ttag'
import { Overlay, Popover } from 'react-bootstrap'
import styled from 'styled-components'
import { arrayOf, shape, string, func, bool } from 'prop-types'

import { textGreyColor, textColor, lightBlueColor, greyishWhiteColor } from 'shared/style/colors'
import Button from 'shared/components/ui/Button'

import useCampaignActions from '../CampaignEdit/Header/CampaignActions/useCampaignActions'

const CampaignActions = ({ className, actions, campaign }) => {
  const [isShown, setIsShown] = useState(false)
  const showPopover = () => setIsShown(true)
  const hidePopover = () => setIsShown(false)
  const target = useRef()
  const { phase, uuid } = campaign
  const { setArchivedPhase, setAssemblyPhase } = useCampaignActions(phase, uuid)
  let campaignActions = []
  if (campaign.canSetPhase.archived) {
    campaignActions = [
      ...actions,
      { label: t`Archive campaign`, action: () => setArchivedPhase() },
    ]
  }
  if (campaign.canSetPhase.unarchived) {
    campaignActions = [
      ...actions,
      { label: t`Unarchive campaign`, action: () => setAssemblyPhase() },
    ]
  }

  const handleAction = action => () => {
    action()
    hidePopover()
  }

  return (
    <>
      <Button className={ `${className} menu-toggle` } onClick={ showPopover } ref={ target }>
        <span>
          &hellip;
        </span>
      </Button>

      <Overlay
        rootClose
        trigger='click'
        show={ isShown }
        placement='left'
        onHide={ hidePopover }
        target={ target.current }
      >
        <Popover className={ className }>
          <Popover.Content>
            <div className='menu-header' >{ t`Actions` }</div>

            <div className='menu-list' >
              { campaignActions.map(({ label, action }) => (
                <div key={ label }>
                  <button onClick={ handleAction(action) } type='button'>{ label }</button>
                </div>
              ))}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  )
}

CampaignActions.propTypes = {
  actions: arrayOf(
    shape({
      label: string.isRequired,
      action: func.isRequired
    })
  ).isRequired,
  campaign: shape({
    phase: string.isRequired,
    uuid: string.isRequired,
    canSetPhase: shape({
      archived: bool,
    }).isRequired,
  }).isRequired
}

export default styled(CampaignActions)`
  &.menu-toggle {
    padding: 0.1rem 0.5rem;
  }

  &.menu-toggle span {
    bottom: 0.25rem;
    position: relative;
  }

  .menu-header {
    color: ${textGreyColor}
    font-size: 0.75rem
    margin-bottom: 0.25rem
  }

  .menu-list div {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-bottom: 0.25rem
    font-size: 0.875rem
    padding: 0 0.75rem;
  }

  .menu-list div:hover {
    background-color: ${greyishWhiteColor};

    button {
      color: ${textColor};
    }
  }

  .menu-list button {
    color: ${lightBlueColor};
    border: none;
    background-color: transparent;
    padding: 0;
  }
`

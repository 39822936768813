import React from 'react'

import matchType from 'shared/types/matchType'
import ProjectView from 'shared/components/Project'

const Project = ({ match }) => {
  return (
    <ProjectView uuid={ match.params.uuid } />
  )
}

Project.propTypes = {
  match: matchType.isRequired
}

export default Project

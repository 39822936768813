import React, { useState, useRef } from 'react'
import { t }  from 'ttag'
import { Overlay, Popover } from 'react-bootstrap'
import styled from 'styled-components'
import { string, shape, bool } from 'prop-types'

import { userRolePath }  from 'routes/AppRedirect'
import { localDateTime } from 'shared/helpers/date'
import Button            from 'shared/components/ui/Button'
import { textGreyColor, textColor, lightBlueColor, greyishWhiteColor } from 'shared/style/colors'

import ExportLeadsButton from '../../../Campaign/ExportLeadsButton'

import useCampaignActions from './useCampaignActions'

const CampaignActions = ({
  className,
  uuid,
  phase,
  startDate,
  endDate,
  canSetPhase,
  name,
  promotableUuid,
  promotableType
}) => {
  const {
    setPausedPhase,
    setResumedPhase,
    setArchivedPhase,
    setUnarchivedPhase,
  } = useCampaignActions(phase, uuid)

  const [isShown, setIsShown] = useState(false)
  const showPopover = () => setIsShown(true)
  const hidePopover = () => setIsShown(false)
  const target = useRef()
  const allowsDuplication = promotableType === 'Stage'

  const links = [
    {
      href: userRolePath(`/stages/${promotableUuid}/campaigns/${uuid}/duplicate`),
      label: t`Duplicate`,
      visible: allowsDuplication,
      dataTestId: 'duplicate-button'
    }
  ]

  const buttons = [
    {
      action: () => setPausedPhase(),
      label: t`Pause`,
      visible: canSetPhase.paused,
      dataTestId: 'pause-campaign-button',
    },
    {
      action: () => setResumedPhase(),
      label: t`Resume`,
      visible: canSetPhase.resumed,
      dataTestId: 'resume-campaign-button',
    },
    {
      action: () => setArchivedPhase(),
      label: t`Archive`,
      visible: canSetPhase.archived,
      dataTestId: 'archive-campaign-button',
    },
    {
      action: () => setUnarchivedPhase(),
      label: t`Unarchive`,
      visible: canSetPhase.unarchived,
      dataTestId: 'unarchive-campaign-button',
    }
  ]

  const handleAction = action => () => {
    action()
    hidePopover()
  }

  return (
    <div className={ className }>
      <div className="form-control date-range">
        {localDateTime(startDate)} &mdash; {localDateTime(endDate)}
      </div>

      <Button className={ `${className} menu-toggle` } onClick={ showPopover } ref={ target }>
        <span>
          &hellip;
        </span>
      </Button>

      <Overlay
        rootClose
        trigger='click'
        show={ isShown }
        placement='left'
        onHide={ hidePopover }
        target={ target.current }
      >
        <Popover className={ className }>
          <Popover.Content>
            <div className='menu-header' >{ t`Actions` }</div>

            <div className='menu-list' >
              { links.filter(link => link.visible).map(link => (
                <div key={ link.dataTestId }>
                  <a
                    href={ link.href }
                    data-test-id={ link.dataTestId }
                  >
                    { link.label }
                  </a>
                </div>
              ))}
              <div key="export-leads-button">
                <ExportLeadsButton campaignUuid={ uuid } campaignName={ name }/>
              </div>
              
              { buttons.filter(button => button.visible).map(button => (
                <div key={ button.dataTestId }>
                  <button
                    onClick={ handleAction(button.action) }
                    type='button'
                    data-test-id={ button.dataTestId }
                  >
                    { button.label }
                  </button>
                </div>
              ))}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  )
}

CampaignActions.propTypes = {
  canSetPhase: shape({
    pause: bool,
    live: bool
  }).isRequired,
  endDate: string.isRequired,
  name: string.isRequired,
  phase: string.isRequired,
  promotableType: string.isRequired,
  promotableUuid: string.isRequired,
  startDate: string.isRequired,
  uuid: string.isRequired
}

export default styled(CampaignActions)`
  display: flex
  justify-content: flex-end
  padding: 1rem

  .menu-toggle {
    padding: 0.1rem 0.5rem;
  }

  .menu-toggle span {
    bottom: 0.25rem;
    position: relative;
  }
  
  .menu-header {
    color: ${textGreyColor}
    font-size: 0.75rem
    margin-bottom: 0.25rem
  }

  .menu-list div {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-bottom: 0.25rem
    font-size: 0.875rem
    padding: 0 0.75rem;
  }

  .menu-list div:hover {
    background-color: ${greyishWhiteColor};

    button {
      color: ${textColor};
    }
  }

  .menu-list button {
    color: ${lightBlueColor};
    border: none;
    background-color: transparent;
    padding: 0;
  }
  
  &.popover {
    padding: 0;
  }
  
  .form-control {
    height: 1.875rem
    margin-left: .625rem
    margin-right: .625rem
    width: 8.75rem
  }

  .form-control.date-range {
    width: auto;
    padding-top: 0.25rem;
  }
`

import { gql } from 'apollo-boost'

export default gql`
  mutation finalizeCampaign($uuid: ID!) {
    finalizeCampaign(uuid: $uuid) {
      campaign {
        uuid
        phase
      }
      errors
    }
  }
`

import React, { useState, useRef } from 'react'
import { t } from 'ttag'
import { Overlay, Popover } from 'react-bootstrap'
import styled from 'styled-components'
import { func } from 'prop-types'

import { textGreyColor, textColor, lightBlueColor, greyishWhiteColor } from 'shared/style/colors'
import Button from 'shared/components/ui/Button'

const ContextMenu = ({ className,  children }) => {
  const [isShown, setIsShown] = useState(false)
  const showPopover = () => setIsShown(true)
  const hidePopover = () => setIsShown(false)
  const target = useRef()

  const handleAction = action => () => {
    if (action) action()
    hidePopover()
  }

  return (
    <>
      <Button
        className={ `${className} menu-toggle` }
        onClick={ showPopover }
        ref={ target }
        data-test-id='project-menu'
      >
        <span>
          &hellip;
        </span>
      </Button>

      <Overlay
        rootClose
        trigger='click'
        show={ isShown }
        placement='left'
        onHide={ hidePopover }
        target={ target.current }
      >
        <Popover className={ className }>
          <Popover.Content>
            <div className='menu-header'>{ t`Actions` }</div>

            <div className='menu-list' data-test-id='menu-list'>
              { children(handleAction) }
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  )
}

ContextMenu.propTypes = {
  children: func.isRequired
}

export default styled(ContextMenu)`
  position: absolute;
  z-index: 2;
  top: 1rem;
  right: 1rem

  &.menu-toggle {
    padding: 0.1rem 0.5rem;
  }

  &.menu-toggle span {
    bottom: 0.25rem;
    position: relative;
  }

  .menu-header {
    color: ${textGreyColor}
    font-size: 0.75rem
    margin-bottom: 0.25rem
  }

  .menu-list div {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-bottom: 0.25rem
    font-size: 0.875rem
    padding: 0 0.75rem;
  }

  .menu-list div:hover {
    background-color: ${greyishWhiteColor};

    button {
      color: ${textColor};
    }
  }

  .menu-list button {
    color: ${lightBlueColor};
    border: none;
    background-color: transparent;
    padding: 0;
  }
`

import React, { useState }     from 'react'
import { useApolloClient }     from '@apollo/react-hooks'
import { object, array, func } from 'prop-types'

import EditUnitFloorplansModal
  from 'shared/components/ProductDetails/StageDetails/Units/EditUnitFloorplansModal'
import NewItemButton   from 'shared/components/NewStage/StageDetails/Units/NewItemButton'
import updateUnit      from 'shared/components/NewStage/StageDetails/Units/updateUnit'

import Table      from './Table'
import createUnit from './createUnit'
import deleteUnit from './deleteUnit'

const Units = ({ building, errors, validate }) => {
  const client = useApolloClient()
  const [isUnitFloorplansEditing, setIsUnitFloorplansEditing] = useState(false)
  const [currentUnit, setCurrentUnit] = useState(null)

  const handleAddClick = () => createUnit(client, building)

  const handleUpdate = ({ uuid, attributes }) => updateUnit(client, uuid, attributes).
    then(({ data: { updateStageUnit: { unit } } }) => {
      if (!validate) { return }
      validate({ updated: unit })
    })

  const handleDelete = ({ uuid }) => deleteUnit(client, building, uuid)

  const uploadFloorPlanClick = (unit) => {
    setCurrentUnit(unit)
    setIsUnitFloorplansEditing(true)
  }

  return (
    <>
      <Table
        building={ building }
        onUpdate={ handleUpdate }
        onDelete={ handleDelete }
        handleUploadFloorPlanClick={ uploadFloorPlanClick }
        errors={ errors }
      />

      <NewItemButton onClick={ handleAddClick } />

      {currentUnit && (
        <EditUnitFloorplansModal
          unit={ currentUnit }
          show={ isUnitFloorplansEditing }
          onHide={ () => setIsUnitFloorplansEditing(false) }
        />
      )}
    </>
  )
}

Units.propTypes = {
  building: object.isRequired,
  errors: array,
  validate: func
}

Units.defaultProps = {
  errors: [],
  validate: undefined
}

export default Units

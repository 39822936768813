import React            from 'react'
import { string, node } from 'prop-types'
import styled           from 'styled-components'
import { Container }    from 'react-bootstrap'

import LoginStyle from 'shared/style/global/login'

import LeftPanel from './LeftPanel'

const LoginLayout = ({ className, children }) => {
  return (
    <Container fluid className={ className }>
      <LoginStyle/>

      <LeftPanel />

      <div className="right-panel">
        { children }
      </div>
    </Container>
  )
}

LoginLayout.propTypes = {
  children: node.isRequired,
  className: string.isRequired,
}

export default styled(LoginLayout)`
  align-items: center
  display: flex
  min-height: 100%
  max-width: 2000px
  height: 100%
  padding-left: 0
  padding-right: 0

  & > * {
    flex: 0 0 50%;
  }

  ${LeftPanel} {
    height: 100%
  }

  .right-panel {
    max-width: 700px
    padding-left: 5.5rem
    padding-right: 5.5rem
  }
`

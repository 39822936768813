import React      from 'react'
import { func }   from 'prop-types'
import { Formik } from 'formik'

import createOnSubmit         from 'user/components/ForgotPasswordForm/createOnSubmit'
import validationSchema       from 'user/components/ForgotPasswordForm/validationSchema'
import ForgotPasswordFormView from 'user/components/ForgotPasswordForm/ForgotPasswordForm'

const ForgotPasswordForm = ({ onEmailSent }) => {
  return (
    <Formik
      validationSchema={ validationSchema }
      initialValues={{ email: '' }}
      onSubmit={ createOnSubmit(onEmailSent) }
    >
      {({
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <ForgotPasswordFormView
          errors={ errors }
          touched={ touched }
          handleChange={ handleChange }
          handleSubmit={ handleSubmit }
          isSubmitting={ isSubmitting }
        />
      )}
    </Formik>
  )
}

ForgotPasswordForm.propTypes = {
  onEmailSent: func.isRequired
}

export default ForgotPasswordForm

import React, { useState } from 'react'
import { t }               from 'ttag'
import { useQuery }        from '@apollo/react-hooks'
import { get }             from 'lodash'
import { number }          from 'prop-types'
import styled              from 'styled-components'

import projectType         from 'shared/types/projectType'
import LoadingBlock        from 'shared/components/ui/LoadingBlock'
import Chart               from 'shared/components/Chart'
import Tab                 from 'shared/components/ui/TabBar/Tab'
import ChartHeader         from 'shared/components/Dashboard/LinearChart/Header'
import formatCurrentValues from 'shared/components/Dashboard/formatCurrentValues'
import { bluishColor }     from 'shared/style/colors'

import Card from '../Card'

import projectSalesLeadsAnalyticsQuery from './projectSalesLeadsAnalyticsQuery'

const initialTab = 1
const tabsData = [
  {
    index: 0,
    title: t`Total`,
    name: null
  },
  {
    index: 1,
    title: t`Last 7 days`,
    name: 'week'
  },
  {
    index: 2,
    title: t`Last 30 days`,
    name: 'month'
  },
  {
    index: 3,
    title: t`Last year`,
    name: 'year'
  }
]

const chartOptions = {
  chart: {
    height: 150,
    width: 690,
    yAxis: {
      gridLineWidth: 0
    },
    xAxis: {
      gridLineWidth: 1
    }
  },
  plotOptions: {
    spline: {
      marker: {
        enabled: false
      }
    },
    series: {
      marker: {
        radius: 1
      }
    }
  }
}

const LeadsAnalytics = ({ className, project, stageTab }) => {
  const [selectedTab, setSelectedTab] = useState(initialTab)

  const { data, loading } = useQuery(
    projectSalesLeadsAnalyticsQuery,
    {
      variables: {
        scopedTo:   (stageTab === 0 ? 'Project' : 'Stage'),
        uuid:       (stageTab === 0 ? project.uuid : project.completeStages[stageTab - 1].uuid),
        periodName: tabsData[selectedTab].name
      }
    }
  )
  const totalLeads = get(data, 'projectSalesLeadsAnalytics.totalLeads', null)

  if (loading) {
    return (
      <Card header={ t`Leads` }>
        <LoadingBlock />
      </Card>
    )
  }

  const { totalLeadsValue } = formatCurrentValues({ totalLeads })

  const chartData = [
    {
      name:       t`Leads`,
      seriesData: totalLeads.dataset || [],
      color:      bluishColor
    }
  ]

  return (
    <Card className={ className } headerText={ t`Leads` } dataTestId='total-leads-chart'>
      <div className={ 'd-flex sales-header' }>
        { tabsData.map((tab) => (
          <Tab
            key={ tab.index }
            title={ tab.title }
            showArrow={ tab.index < (tabsData.length - 1) }
            active={ tab.index === selectedTab }
            onClick={ () => setSelectedTab(tab.index) }
          />
        ))}
      </div>

      <div className='chart-container'>
        <ChartHeader
          boldText={ totalLeadsValue }
          comment={ t`Total number of leads` } />

        <Chart
          data={ chartData }
          chartOptions={ chartOptions }
        />
      </div>
    </Card>
  )
}

LeadsAnalytics.propTypes = {
  project: projectType.isRequired,
  stageTab: number.isRequired
}

export default styled(LeadsAnalytics)`
  .sales-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  ${Tab} {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    padding-right: 0;

    &:last-child {
      border-right: none
    }

    .arrow-top {
      display: none
    }

    .arrow-bottom {
      display: none
    }
  }

  .chart-container {
    padding: 0 1.45rem 1.1rem !important;
  }
`

import React from 'react'
import { t } from 'ttag'
import { array, string } from 'prop-types'
import { useApolloClient } from 'react-apollo'

import setCreativeSetLogoMutation from 'graphql/mutations/campaign/creativeSets/setCreativeSetLogo'
import Label from 'shared/components/CampaignInfo/CreativeSets/CreativeSet/Label'

import Select from './Select'

const LogoSelect = ({ className, logoUuid, creativeSetUuid, logos, ...props }) => {
  const client = useApolloClient()

  const setLogo = ({ value }) => {
    client.mutate({
      mutation: setCreativeSetLogoMutation,
      variables: {
        uuid: creativeSetUuid,
        assetUuid: value
      }
    })
  }

  return (
    <div className={ className }>
      <Label>{ t`Select Logo` }</Label>

      <Select
        logos={ logos }
        logoUuid={ logoUuid }
        onChange={ setLogo }
        { ...props }
      />
    </div>
  )
}

LogoSelect.propTypes = {
  creativeSetUuid: string.isRequired,
  logos: array.isRequired,
  logoUuid: string,
}

LogoSelect.defaultProps = {
  logoUuid: undefined
}

export default LogoSelect

import React, { useState } from 'react'
import { Formik }          from 'formik'
import { useApolloClient } from 'react-apollo'

import createOnSubmit   from './createOnSubmit'
import useAssets        from './useAssets'
import validationSchema from './validationSchema'
import ProjectFormView  from './ProjectFormView'

const initialValues = {
  districtUuid: undefined,
  ownershipType: '',
  name: '',
  prospect: null
}

const ProjectForm = () => {
  const client = useApolloClient()
  const [assets, addAssets] = useAssets([])
  const [stakeholders, setStakeholders] = useState([])
  const [companies, setCompanies] = useState([])

  return (
    <Formik
      initialValues={ initialValues }
      validationSchema={ validationSchema }
      onSubmit={ createOnSubmit(client, assets, stakeholders, companies) }
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting
      }) => (
        <ProjectFormView
          errors={ errors }
          values={ values }
          touched={ touched }
          assets={ assets }
          stakeholders={ stakeholders }
          companies={ companies }
          isSubmitting={ isSubmitting }
          handleSubmit={ handleSubmit }
          handleChange={ handleChange }
          setFieldValue={ setFieldValue }
          setStakeholders={ setStakeholders }
          setCompanies={ setCompanies }
          handleAssetsChange={ addAssets }
        />
      )}
    </Formik>
  )
}

export default ProjectForm

import { t, msgid, ngettext } from 'ttag'

class CampaignStats {
  constructor(campaign) {
    this.campaign = campaign
  }

  get goalTypePresentation() {
    const mapping = {
      impressions: t`Impressions`,
      visits: t`Visits`,
      leads: t`Leads`,
      lead_generation: t`Leads`,
      video_playthroughs: t`Video playthroughs`
    }

    return mapping[this.goalType]
  }

  get actualGoalNumber() {
    const mapping = {
      impressions: 'totalImpressions',
      visits: 'totalClicks',
      leads: 'leadsCount',
      lead_generation: 'leadsCount',
      video_playthroughs: 'totalReach' // ???
    }

    const insigtField = mapping[this.goalType]

    return this.campaign.insights[insigtField]
  }

  get goalPercentage() {
    if (this.plannedGoalNumber === 0) return 0

    return this.actualGoalNumber / this.plannedGoalNumber * 100
  }

  get costPerGoalUnitPresentation() {
    const mapping = {
      impressions: t`CTR`,
      visits: t`CPC`,
      leads: t`CPL`,
      lead_generation: t`CPL`,
      video_playthroughs: t`CTR`
    }

    return mapping[this.goalType]
  }

  get costPerGoalUnit() {
    const mapping = {
      impressions: 'averageCtr',
      visits: 'averageCpc',
      leads: 'averageCpl',
      lead_generation: 'averageCpl',
      video_playthroughs: 'averageCtr' // ???
    }

    const insigtField = mapping[this.goalType]

    return this.campaign.insights[insigtField]
  }

  get costPerGoalUnitPercentage() {
    if (this.plannedCostPerGoalUnit === 0) return 0

    return this.costPerGoalUnit / this.plannedCostPerGoalUnit * 100
  }

  get plannedCostPerGoalUnit() {
    if (this.goalNumber === 0) return 0

    return this.budget / this.plannedGoalNumber
  }

  get totalSpent() {
    return this.campaign.insights.totalSpent
  }

  get spentPercentage() {
    if (this.budget === 0) return 0

    return this.totalSpent / this.budget * 100
  }

  get timeSpentPresentation() {
    if (!this.daysPast) return t`N/A`

    return (
      ngettext(msgid`${this.daysPast} day`, `${this.daysPast} days`, this.daysPast)
    )
  }

  get timePercentage() {
    if (this.durationInDays === 0) return 0

    const percentage = (this.daysSinceStart / this.durationInDays) * 100

    return Math.min(100, Math.max(0, percentage))
  }

  get trend() {
    const mapping = {
      impressions: 'ctrTrend',
      visits: 'cpcTrend',
      leads: 'cplTrend',
      lead_generation: 'cplTrend',
      video_playthroughs: 'ctrTrend'
    }

    const trendField = mapping[this.goalType]

    return this.campaign.insights[trendField]
  }

  get budget() {
    return parseInt(this.campaign.budget, 10)
  }

  get plannedGoalNumber() {
    return this.campaign.specification.goals.number
  }

  get goalType() {
    return this.campaign.specification.goals.type
  }

  get daysSinceStart() {

    return this.campaign.daysSinceStart
  }

  get durationInDays() {
    return this.campaign.durationInDays
  }

  get daysPast() {
    return (Math.round(Math.min(this.daysSinceStart, this.durationInDays)))
  }

}

export default CampaignStats

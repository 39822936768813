import React, { useEffect, useState }  from 'react'
import styled from 'styled-components'
import { object, string, number } from 'prop-types'
import Chart from 'chart.js'

import createTooltip from './createTooltip.js'
import useDataSets   from './useDataSets'

const Graph = ({ className, title, data, dataTotal, index }) => {
  const [maxElement, setMaxElement] = useState(null)

  useEffect(() => {
    let mostPopularElement = null

    Object.entries(data).forEach(([key, value]) => {
      if (mostPopularElement === null || mostPopularElement.value < value) {
        mostPopularElement = { label: key, value: value }
      }
    })

    setMaxElement(mostPopularElement)
  }, [data])

  useEffect(() => {
    if (dataTotal !== 0) {
      const { dataAsPercentage, backgroundColors, borderColors } = useDataSets(data, dataTotal)

      const graphData = {
        datasets: [{
          data: dataAsPercentage,
          backgroundColor: backgroundColors,
          borderColor: borderColors
        }],
        labels: Object.keys(data)
      }

      const chart = new Chart(document.getElementById(`demographic-pie-${index}`), {
        type: 'doughnut',
        data: graphData,
        options: {
          tooltips: {
            enabled: false,
            backgroundColor: '#000',
            bodyFontSize: 14,
            callbacks: {
              label: (tooltipItem, data) => {
                const label = data.labels[tooltipItem.index] || ''
                const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                return `${label}: ${Math.round(value)}%`
              }
            },
            custom: createTooltip
          },
          cutoutPercentage: 80,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          }
        }
      })

      return () => {
        chart.destroy()
      }
    }
  }, [data])

  const renderMostPopularElement = () => {
    if (maxElement === null) {
      return (
        <div className='graph-total-data'>
          <p className='graph-total-data-amount'>0</p>
        </div>
      )
    }

    return (
      <div className='graph-total-data'>
        <p className='graph-total-data-amount'>{ maxElement.label }</p>
        <p className='graph-total-data-value'>
          { dataTotal === 0 ? 0 : (Math.round(100 * (100 * maxElement.value / dataTotal)) / 100) }%
        </p>
      </div>
    )
  }

  const renderGraph = () => {
    if (dataTotal === 0) return null

    return <canvas id={ `demographic-pie-${index}` } width={ '131' } height={ '131' } />
  }

  return (
    <div
      className={ `${className}` }
      data-test-id={ `demographic-details-${index}` }
    >
      <div className='graph'>
        { renderGraph() }
        { renderMostPopularElement() }
      </div>
      <div className='title'>{ title }</div>
    </div>
  )
}

Graph.propTypes = {
  data: object.isRequired,
  dataTotal: number.isRequired,
  index: number.isRequired,
  title: string.isRequired
}

export default styled(Graph)`
  width: 50%;
  padding: 0 1.125rem;

  .title {
    text-align: center;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .graph {
    position: relative;
    width: 8.1875rem;
    height: 8.1875rem;
    margin: 0 auto 1rem;

    .graph-total-data {
      position: absolute;
      left: 20%;
      top: 20%;
      width: 60%;
      height: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .graph-total-data-amount {
        font-size: .9rem;
        line-height: .9rem;
        color: #4a4a4a;
        font-weight: 600;
        margin: 0;
      }

      .graph-total-data-value {
        font-size: .75rem;
        font-weight: 600;
        line-height: .75rem;
        color: #4a4a4a;
        margin: 0;
      }
    }
  }
`

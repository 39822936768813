import React    from 'react'
import { t }    from 'ttag'
import styled   from 'styled-components'
import { func } from 'prop-types'

import Button from 'shared/components/ui/Button'

const DatepickerActions = ({ className, onClose, onReset }) => {
  return (
    <div className={ className }>
      <Button
        type="button"
        onClick={ onClose }
        data-test-id="datepicker-close"
      >
        { t`Close` }
      </Button>
      { onReset && <Button
        type="button"
        onClick={ onReset }
        data-test-id="datepicker-close"
      >
        { t`Reset` }
      </Button> }
    </div>
  )
}

DatepickerActions.propTypes = {
  onClose: func.isRequired,
  onReset: func
}

DatepickerActions.defaultProps = {
  onReset: null
}

export default styled(DatepickerActions)`
  display: flex;
  justify-content: center;
  padding-bottom: .5rem;
  
  ${Button} {
    width: 5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`

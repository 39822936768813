import gql from 'graphql-tag'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  fragment AgentPromotion on AgentPromotion {
    uuid
    customerName
    assetAssignments {
      ... AssetAssignment
    }
  }
  ${assetAssignment}
`

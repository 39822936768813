import React, { useState } from 'react'
import { t } from 'ttag'
import { object, func, bool } from 'prop-types'
import { useApolloClient } from 'react-apollo'
import { toast } from 'react-toastify'

import useUrlParams from 'shared/hooks/useUrlParams'
import ValidatableTabs from 'shared/components/ValidatableTabs'
import { lessThanOneHourFromNow } from 'shared/helpers/date'
import finalizeCampaign from 'graphql/mutations/campaign/finalizeCampaign'

import detalizeErrorHandler from '../Campaign/detalizeErrorsHandler'

import buildValidator from './validations/buildValidator'
import tabs from './TabBar/tabs'
import ContentContainer from './ContentContainer'

const CampaignInfo = ({ campaign, validationMode, stopValidationMode, onChangeActiveTab }) => {
  const client = useApolloClient()

  const [urlParams, setUrlParams] = useUrlParams()
  const initialTab = campaign.currentContentStep

  const [activeTab, setActiveTab] = useState(initialTab)
  const [errors, setErrors] = useState([])
  const [validatablePayload, setValidatablePayload] = useState()
  const [validationPending, setValidationPending] = useState()
  const [loadingTabContent, setLoadingTabContent] = useState(true)

  const handleSwitchActiveTab = (newTabNumber) => {
    delete urlParams.infoTab
    setLoadingTabContent(true)
    setUrlParams(urlParams)
    setActiveTab(newTabNumber)
    onChangeActiveTab(newTabNumber)
  }

  const ActiveTabContent = tabs[activeTab].component
  const validate = buildValidator({
    setValidationPending,
    setErrors,
    validatorNumber: activeTab
  })
  const refreshWholePage = () => {
    window.location.reload()
  }

  const assemblyPhase = campaign.phase === 'assembly'
  const onFinalize = async () => {
    if (assemblyPhase && lessThanOneHourFromNow(campaign.startDate)) {
      toast.error(t`Start time must be at least 1 hour from now`)

      return
    }

    if (lessThanOneHourFromNow(campaign.endDate)) {
      toast.error(t`End time must be at least 1 hour from now`)

      return
    }

    const result = await client.mutate({
      mutation: finalizeCampaign,
      variables: { uuid: campaign.uuid }
    })

    if (result.data.finalizeCampaign.errors) {
      const errors = result.data.finalizeCampaign.errors
      toast.error(
        <>
          { errors.map((message) => <p className='mb-0' key={ message }> { message }</p>) }
        </>
      )
    } else {
      refreshWholePage()
    }
  }
  const finalActionName = assemblyPhase ? t`Finish` : t`Update`

  return (
    <>
      <ValidatableTabs
        collapsible
        detalizeErrors
        customDetalizeErrorsHandler={ detalizeErrorHandler }
        campaign={ campaign }
        collapsed={ false }
        tabs={ tabs }
        title={ t`Campaign Information` }
        validate={ validate }
        validatablePayload={ validatablePayload }
        onFinalize={ onFinalize }
        publishingMode={ !!campaign.publishStartedAt }
        activeTab={ activeTab }
        setActiveTab={ handleSwitchActiveTab }
        validationPending={ validationPending }
        validationMode={ validationMode }
        stopValidationMode={ stopValidationMode }
        finalActionName={ finalActionName }
        loadingTabContent={ loadingTabContent }
      >
        <ContentContainer>
          <ActiveTabContent
            client={ client }
            campaign={ campaign }
            errors={ errors }
            validate={ validate }
            onLoading={ () => { setLoadingTabContent(true) } }
            onLoaded={ () => { setLoadingTabContent(false) } }
            setValidatablePayload={ setValidatablePayload }
            setValidationPending={ setValidationPending }
          />
        </ContentContainer>
      </ValidatableTabs>
    </>
  )
}

CampaignInfo.propTypes = {
  campaign: object.isRequired,
  onChangeActiveTab: func,
  stopValidationMode: func,
  validationMode: bool
}

CampaignInfo.defaultProps = {
  onChangeActiveTab: () => {},
  stopValidationMode: undefined,
  validationMode: false
}

export default CampaignInfo

import { pick } from 'lodash'

export default function buildInputValues(formValues) {
  const attributes = pick(formValues, ['email', 'name', 'phoneCode', 'phoneNumber'])
  attributes['phoneNumber'] = (attributes['phoneNumber'] || '').toString()

  return {
    variables: {
      attributes: {
        ...attributes,
        settings: pick(formValues, ['timezone', 'defaultLanguage'])
      }
    }
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'ttag'

import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'

import StakeholdersTable from '../Stakeholders/StakeholdersTable'

const ProjectStakeholders = ({ className, project }) => {
  return (
    <EmbeddedCardSection titleText={ t`Stakeholders` } className={ className }>
      <StakeholdersTable data-test-id='stakeholders-table' project={ project } disabled={ false } />
    </EmbeddedCardSection>
  )
}

ProjectStakeholders.propTypes = {
  project: PropTypes.object.isRequired
}

export default ProjectStakeholders

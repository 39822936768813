import React from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { useDrop } from 'react-dnd'
import { object } from 'prop-types'

import landingPageType from 'shared/types/landingPageType'
import EmptyAsset from 'shared/components/Media/AssetAssignments/EmptyAsset'
import UploadContainer from 'shared/components/Media/AssetAssignments/UploadContainer'
import SelectedAsset from 'shared/components/Media/AssetAssignments/SelectedAsset'
import { mediaTypeDropEvents } from 'shared/components/Media/mediaTypes'
import attachFileToCampaign from 'shared/components/CampaignInfo/Media/attachFileToCampaign'

import { GalleryImagesContainer, GalleryAssetWrapper } from '../elements'

import addGalleryImage from './addGalleryImage'
import removeGalleryImage from './removeGalleryImage'

const GalleryImages = ({ campaign, landingPage }) => {
  const client = useApolloClient()

  const { galleryImages = [] } = landingPage

  const emptyAssets = Array(Math.max(1, 4 - galleryImages.length)).fill(0)

  const handleImageDrop = ({ asset: { uuid } }) =>
    addGalleryImage({ client, landingPage, assetUuid: uuid })

  const [_imageProps, drop] = useDrop({ accept: mediaTypeDropEvents.image, drop: handleImageDrop })

  const handleRemove = async (uuid) => removeGalleryImage({ client, landingPage, uuid })

  const galleryImagesWithAssets = galleryImages.filter((image) => {
    return image.asset !== null
  })

  const onAssetCreate = ({ client, blobId, mediaType }) => {
    attachFileToCampaign({ client, blobId, mediaType, campaignUuid: campaign.uuid })
      .then(({ data }) => {
        const asset = data.attachFileToAssetable.assetAssignment.asset
        handleImageDrop({ asset })
      })
  }

  return (
    <GalleryImagesContainer ref={ drop }>
      {galleryImagesWithAssets.map(image => (
        <GalleryAssetWrapper key={ image.uuid }>
          <SelectedAsset
            allowedSize
            assetAssignment={ image }
            onAssetAssignmentRemove={ handleRemove }
            assetableType={ image.__typename }
            displayEnabled={ false }
          />
        </GalleryAssetWrapper>
      ))}

      {emptyAssets.map((_, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <UploadContainer onFileUploaded={ onAssetCreate } mediaType={ 'image' } key={ idx }>
          <GalleryAssetWrapper>
            <EmptyAsset clickable mediaType="image" />
          </GalleryAssetWrapper>
        </UploadContainer>
      ))}
    </GalleryImagesContainer>
  )
}

GalleryImages.propTypes = {
  campaign: object.isRequired,
  landingPage: landingPageType.isRequired
}

export default GalleryImages

import React        from 'react'
import styled       from 'styled-components'
import { t }        from 'ttag'
import { number }   from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { get }      from 'lodash'

import projectType from 'shared/types/projectType'
import Table from 'shared/components/ui/Table'

import Card from '../Card'

import useLeadsTable from './useLeadsTable'
import leadsQuery from './leadsQuery'

const Leads = ({ className, project, stageTab }) => {
  const { data } = useQuery(
    leadsQuery,
    {
      variables: { projectUuid: project.uuid }
    }
  )
  const leads = get(data, 'projectLeads', [])

  const { tableOptions, paginationOptions } = useLeadsTable(project, stageTab, leads)

  return (
    <Card headerText={ t`Buyers` }>
      <div className={ className } data-test-id='leads-list-card'>
        <Table
          tableOptions={ tableOptions }
          paginationOptions={ paginationOptions }
          key={ stageTab }
        />
      </div>
    </Card>
  )
}

Leads.propTypes = {
  project: projectType.isRequired,
  stageTab: number.isRequired
}

export default styled(Leads)`
  .pagination {
    padding-top: 1rem
    padding-right: 1rem
  }
  .pagination button {
    margin-left: .5rem
  }
`

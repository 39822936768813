import { gql } from 'apollo-boost'

export default gql`
  mutation createFacilityDetail(
    $projectUuid: ID!
    $facilityUuid: ID!
    $description: String!
  ) {
    createFacilityDetail(
      projectUuid: $projectUuid
      facilityUuid: $facilityUuid
      description: $description
    ) {
      detail {
        uuid
        title
        detailUuid
        description
      }
    }
  }
`

import { t } from 'ttag'
import { toast } from 'react-toastify'

import redirectTo from 'shared/helpers/redirectTo'

const onCampaignCreated = (campaign) => {
  if (!campaign) {
    return
  }

  toast.success(t`Campaign created`)

  redirectTo(`/campaigns/${campaign.uuid}`)
}

export default onCampaignCreated

import { buildFragmentParams } from 'shared/helpers/graphql'
import landingPageFragment from 'graphql/fragments/landingPage'
import addGalleryImageMutation
  from 'graphql/mutations/campaign/landingPages/addGalleryImage'

export default ({ client, landingPage, assetUuid }) => {
  client.mutate({
    mutation: addGalleryImageMutation,
    variables: {
      uuid: landingPage.uuid,
      assetUuid
    },
    update: (cache, { data: { addLandingPageGalleryImage: { galleryImage } } }) => {
      const fragmentParams = buildFragmentParams(landingPage, landingPageFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          galleryImages: [
            ...fragmentData.galleryImages,
            galleryImage
          ]
        }
      })
    }
  })
}

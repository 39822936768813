import { toast } from 'react-toastify'
import { useState } from 'react'
import { useApolloClient } from 'react-apollo'

import completeFileUpload from 'shared/helpers/files/completeFileUpload'
import allowedMimeTypes from 'shared/constants/campaigns/allowedMimeTypes'

const useFilesUpload = ({ onFileUploaded, mediaType }) => {
  const client = useApolloClient()
  const [isUploading, setIsUploading] = useState(false)

  const uploadFiles = (files, validateVideoSize) => {
    setIsUploading(true)

    files.reduce(
      (prevUpload, file) => {
        return prevUpload.then(() => {
          return uploadFile(file, validateVideoSize)
        })
      },
      Promise.resolve()
    ).finally(() => setIsUploading(false))
      .catch((error) => {
        toast.error('Failed to upload file')
        throw error
      })
  }

  const uploadFile = (file, validateVideoSize) => {
    const fileName = file.name

    return completeFileUpload({ client, file, allowedMimeTypes, mediaType, validateVideoSize })
      .then(({ signedBlobId }) => onFileUploaded({ client, mediaType, blobId: signedBlobId }))
      .then(() => toast.success(`File "${fileName}" was uploaded!`))
  }

  return {
    isUploading,
    uploadFiles
  }
}

export default useFilesUpload

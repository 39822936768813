import React, { useEffect, useState } from 'react'
import { useQuery, useApolloClient } from '@apollo/react-hooks'
import { string } from 'prop-types'

import updateCampaignMutation   from 'graphql/mutations/campaign/update'
import campaignQuery            from 'graphql/queries/campaign'
import { campaignCurrency }     from 'shared/helpers/currency'
import LoadingBlock             from 'shared/components/ui/LoadingBlock'
import Leads                    from 'shared/components/LeadsList'
import CampaignInfo             from 'shared/components/CampaignInfo'
import PropertyCampaignInfo     from 'shared/components/PropertyCampaignInfo'
import CampaignPublications     from 'shared/components/CampaignPublications'
import ProductDetails           from 'shared/components/Campaign/ProductDetails'
import { canRead }              from 'shared/helpers/auth'

import Header       from './Header'
import Stats        from './Stats'
import Analytics    from './Analytics'
import AdsAudiences from './AdsAudiences'

const CampaignEdit = ({ uuid }) => {
  const {
    data: { campaign } = {},
    loading,
  } = useQuery(campaignQuery, { variables: { uuid } })

  const client = useApolloClient()

  useEffect(() => {
    client.cache.writeData({
      data: {
        currentCampaignUuid: uuid
      }
    })
  }, [uuid])

  const saveCampaignActiveTab = newTab => {
    client.mutate({
      mutation: updateCampaignMutation,
      variables: { uuid: uuid, input: { currentContentStep: newTab.toString() } }
    })
  }

  const [campaignFinalized, setCampaignFinalized] = useState(false)
  const [campaignValidationMode, setCampaignValidationMode] = useState(false)

  if (!campaign) return null

  if (loading) {
    return (
      <LoadingBlock />
    )
  }

  const currency = campaignCurrency(campaign)
  const CampaignInfoComponent = campaign.property ? PropertyCampaignInfo : CampaignInfo

  return (
    <div className="mb-5">
      <Header campaign={ campaign } />

      <CampaignPublications
        campaign={ campaign }
        campaignFinalized={ campaignFinalized }
        validationMode={ campaignValidationMode }
        onCampaignValidate={ () => { setCampaignValidationMode(true) } }
      />

      <Stats campaign={ campaign } />

      {!campaign.property && (
        <ProductDetails product={ campaign.product } readOnly={ campaign.project.readonly } />
      )}

      { canRead('analytics')
        && <Analytics campaignUuid={ campaign.uuid } currency={ currency } /> }

      { canRead('leads')
        && <Leads campaignUuid={ campaign.uuid } /> }

      { canRead('analytics')
        && <AdsAudiences campaign={ campaign } /> }

      <CampaignInfoComponent
        onCampaignFinalizeEditing={ () => { setCampaignFinalized(true) } }
        onCampaignResetEditing={ () => { setCampaignFinalized(false) } }
        onChangeActiveTab={ saveCampaignActiveTab }
        validationMode={ campaignValidationMode }
        stopValidationMode={ () => { setCampaignValidationMode(false) } }
        campaign={ campaign }
      />
    </div>
  )
}

CampaignEdit.propTypes = {
  uuid: string.isRequired
}

export default CampaignEdit

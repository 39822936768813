import React from 'react'
import { t } from 'ttag'
import { shape, string, array, func } from 'prop-types'
import { toast } from 'react-toastify'

import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'

import {
  facebookPositions,
  instagramPositions,
  audienceNetworkPositions,
  messengerPositions
} from './positions'
import PositionsGroup from './PositionsGroup'

const FacebookPlacements = ({ platformAudience, errors, update }) => {
  const handleFacebookPositionsChange = facebookPositions => {
    if (
      storyFacebookPositionWithoutFeed(facebookPositions)
        && !platformAudience.facebookPositions.includes('feed')
    ) {
      toast.error(
        t`
          If you want to use Facebook Stories as placement you have to also choose
          the Facebook Feed.
        `
      )

      return
    }

    update({ facebookPositions: filteredFacebookPositions(facebookPositions) })
  }

  const storyFacebookPositionWithoutFeed = (facebookPositions) => {
    return facebookPositions.includes('story') && !facebookPositions.includes('feed')
  }

  const filteredFacebookPositions = (facebookPositions) => {
    return (
      storyFacebookPositionWithoutFeed(facebookPositions)
        ? facebookPositions.filter(position => position !== 'story')
        : facebookPositions
    )
  }

  const handleInstagramPositionsChange = instagramPositions =>
    update({ instagramPositions })

  const handleAudienceNetworkPositionsChange = audienceNetworkPositions =>
    update({ audienceNetworkPositions })

  const handleMessengerPositionsChange = messengerPositions =>
    update({ messengerPositions })

  return (
    <InputWrapper error={ errors }>
      <PositionsGroup
        name={ t`Facebook` }
        options={ facebookPositions }
        selected={ platformAudience.facebookPositions }
        onChange={ handleFacebookPositionsChange }
        dataTestId="facebook-positions" />

      <InputWrapper error={ errors }>
        <PositionsGroup
          name="Instagram"
          options={ instagramPositions }
          selected={ platformAudience.instagramPositions }
          onChange={ handleInstagramPositionsChange }
          dataTestId="instagram-positions" />
      </InputWrapper>

      <PositionsGroup
        name={ t`Audience Network` }
        options={ audienceNetworkPositions }
        selected={ platformAudience.audienceNetworkPositions }
        onChange={ handleAudienceNetworkPositionsChange } />

      <PositionsGroup
        name={ t`Messenger` }
        options={ messengerPositions }
        selected={ platformAudience.messengerPositions }
        onChange={ handleMessengerPositionsChange } />
    </InputWrapper>
  )
}

FacebookPlacements.propTypes = {
  platformAudience: shape({
    platform: string,
    facebookPositions: array,
    instagramPositions: array,
    audienceNetworkPositions: array,
    messengerPositions: array
  }).isRequired,
  update: func.isRequired,
  errors: shape({
    errorMessage: string
  })
}

FacebookPlacements.defaultProps = {
  errors: {}
}

export default FacebookPlacements


import React from 'react'
import { object } from 'prop-types'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { t } from 'ttag'

import FacebookIcon from 'shared/components/ui/icons/channels/FacebookIcon'
import InstagramIcon from 'shared/components/ui/icons/channels/InstagramIcon'
import GmpIcon from 'shared/components/ui/icons/channels/GmpIcon'
import PortalIcon from 'shared/components/ui/icons/channels/PortalIcon'

const Heading = ({ publication, className }) => {
  const channelsIcons = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    gmp: GmpIcon,
    portal: PortalIcon
  }

  const statusClasses = {
    scheduled: 'secondary',
    in_progress: 'secondary',
    succeeded: 'success',
    failed: 'danger'
  }

  const translatedStatus = {
    scheduled: t`starting soon`,
    in_progress: t`started`,
    succeeded: t`succeeded`,
    failed: t`failed`
  }

  const formatChannelName = (string) => (string.charAt(0).toUpperCase() + string.slice(1))

  const ChannelIcon = channelsIcons[publication.channel.type]
  const statusClass = `text-${ statusClasses[publication.status] }`
  const inProgress = publication.status === 'in_progress'
  const succeeded = publication.status === 'succeeded'
  const failed = publication.status === 'failed'

  return (
    <div className={ className }>
      <ChannelIcon content={ formatChannelName(publication.channel.type) } />
      <div>
        <span className={ statusClass }>
          <small>
            { inProgress && (<Spinner className="mr-1" animation="border"/>) }
            { succeeded && (<span className="mr-1">✔</span>) }
            { failed && (<span className="mr-1">⨯</span>) }
            { translatedStatus[publication.status] }
          </small>
        </span>
      </div>
    </div>
  )
}

Heading.propTypes = {
  publication: object.isRequired
}

export default styled(Heading)`
  .spinner-border {
    width: 0.75rem !important;
    height: 0.75rem !important;
    border-width: 0.15rem;
    vertical-align: baseline;
  }
`

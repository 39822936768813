import React from 'react'
import { withApollo } from 'react-apollo'
import { shape, string, array, func } from 'prop-types'
import { t } from 'ttag'

import apolloClientType from 'shared/types/apolloClientType'
import SubHeader from 'shared/components/ui/Card/SubHeader'
import ToggleGroup from 'shared/components/ui/ToggleGroup'
import { combineErrors } from 'shared/helpers/inputValidation'
import buildAudiencesValidationPayload
  from 'shared/components/CampaignInfo/Targeting/buildAudiencesValidationPayload'

import updateFacebookMarketableAudience from '../updateFacebookMarketableAudience'

import FacebookPlacements from './FacebookPlacements'
import InstagramPlacements from './InstagramPlacements'
import PortalPlacements from './PortalPlacements'

const platforms = [
  { value: 'both', label: t`Both` },
  { value: 'mobile', label: t`Mobile` },
  { value: 'desktop', label: t`Desktop` }
]

const instagramPlatforms = [
  { value: 'mobile', label: t`Mobile` }
]

const Placements = ({ client, audience, errors, validate }) => {
  const { uuid, platformAudience } = audience

  const errorsOnPositions = combineErrors({ field: 'platformPositions', uuid, errors })

  const updateWithRevalidation = ({ ...args }) => (
    updateFacebookMarketableAudience(client, uuid, { ...args }).then(({ data }) => {
      if(!validate) { return }

      const campaign = data.updateFacebookMarketableAudience.campaign
      const payload = buildAudiencesValidationPayload(campaign).map((subPayload) => ({
        ...subPayload,
        campaign
      }))

      validate({ payload: payload })
    })
  )

  const handlePlatformChange = placementPlatform =>
    updateWithRevalidation({ placementPlatform })

  return (
    <>
      <SubHeader>{ t`Display on` }</SubHeader>
      <ToggleGroup
        name="placementPlatform"
        options={ platformAudience.__typename === 'Instagram' ? instagramPlatforms : platforms }
        value={ platformAudience.platform }
        onChange={ handlePlatformChange } />

      {
        platformAudience.__typename === 'Facebook' && <FacebookPlacements
          platformAudience={ platformAudience }
          errors={ errorsOnPositions }
          update={ updateWithRevalidation }
        />
      }

      { platformAudience.__typename === 'Instagram' && <InstagramPlacements
        platformAudience={ platformAudience }
        errors={ errorsOnPositions }
        update={ updateWithRevalidation }
      />
      }

      {
        platformAudience.__typename === 'Portal' && <PortalPlacements
          platformAudience={ platformAudience }
          errors={ errorsOnPositions }
          update={ updateWithRevalidation }
        />
      }
    </>
  )
}

Placements.propTypes = {
  audience: shape({
    uuid: string.isRequired,
    platformAudience: shape({
      platform: string,
    })
  }).isRequired,
  client: apolloClientType.isRequired,
  errors: array,
  validate: func
}

Placements.defaultProps = {
  errors: [],
  validate: undefined
}

export default withApollo(Placements)

import gql from 'graphql-tag'

export default gql`
  mutation removeCreativeSet($uuid: String!) {
    removeCreativeSet(uuid: $uuid) {
      creativeSets {
        uuid
        creativeSetAudiences {
          uuid
          audience {
            uuid
            adsCount
          }
        }
      }
      audiences {
        uuid
        creativeSetAudiences {
          uuid
          creativeSetUuid
        }
      }
    }
  }
`

import gql from 'graphql-tag'

export default gql`
  mutation UpdateNewsPost(
    $uuid: ID!
    $attributes: ProductNewsPostInput!
    $publish: Boolean!
    $fullSave: Boolean!
  ) {
    updateNewsPost(
      uuid: $uuid
      attributes: $attributes
      publish: $publish
      fullSave: $fullSave
    ) {
      post {
        uuid
        publishedAt
        updatedAt
        editedAt
        state
        viewsCount
        ctaClicksCount
        sharesCount
        reactionsCount
        media {
          uuid
          state
          asset {
            uuid
            media
            thumbnail
            position
            mediaType
            deletable
          }
        }
      }
    }
  }
`

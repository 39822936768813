import { redColor } from 'shared/style/colors'

export default {
  control: (provided, { selectProps } = {}) => ({
    ...provided,
    minHeight: '2rem',
    borderColor: selectProps && selectProps.isInvalid ? redColor : provided.borderColor
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '0.875rem'
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: '0.875rem'
  }),
  option: provided => ({
    ...provided,
    fontSize: '0.875rem',
  }),
  input: provided => ({
    ...provided,
    margin: 0
  }),
  valueContainer: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '0.1875rem 0.25rem',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '0.1875rem 0.25rem',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    marginTop: '0.375rem',
    marginBottom: '0.375rem'
  }),
  menu: provided => ({
    ...provided,
    zIndex: 1000
  })
}

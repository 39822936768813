import React, { useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend    from 'react-dnd-html5-backend'

import AssetAssignments from 'shared/components/Media/AssetAssignments'
import projectType      from 'shared/types/projectType'

import attachFileToProject from '../attachFileToProject'

import createRemoveAssetAssignment from './createRemoveAssetAssignment'
import createCreateAssetAssignment from './createCreateAssetAssignment'
import useMoveAssetToPosition      from './useMoveAssetToPosition'
import useUpdateAssetAssignment    from './useUpdateAssetAssignment'

const SelectAssets = ({ project }) => {
  const mediaTypesShown = ['image', 'banner', 'video']

  const creativeAssignments = project.assetAssignments.map((assetAssignment) => {
    const extras = {
      assetAssignmentUuid: assetAssignment.uuid,
      portalEnabledDraft:  assetAssignment.portalEnabledDraft,
      enabled:             assetAssignment.portalEnabledDraft
    }
    assetAssignment.asset = Object.assign({}, assetAssignment.asset, extras)
    return assetAssignment
  })

  const attachFileToAssetable = useCallback(({ client, blobId, mediaType }) => {
    return attachFileToProject({ client, blobId, mediaType, projectUuid: project.uuid })
  }, [project.uuid])

  const selectAssetAssignment = useUpdateAssetAssignment()
  const { moveAssetToPosition } = useMoveAssetToPosition()

  const handleAssetDrop = (draggedAsset, targetAsset) => {
    return moveAssetToPosition({
      variables: {
        uuid: draggedAsset.uuid,
        newPosition: targetAsset.portalPositionDraft.toString()
      }
    })
  }

  return (
    <DndProvider backend={ HTML5Backend }>
      <AssetAssignments
        draggable
        onDrop={ handleAssetDrop }
        creativeAssignments={ creativeAssignments }
        mediaTypes={ mediaTypesShown }
        assetable={ project }
        attachFileToAssetable={ attachFileToAssetable }
        createRemoveAssetAssignment={ createRemoveAssetAssignment }
        createCreateAssetAssignment={ createCreateAssetAssignment }
        selectAssetAssignment={ selectAssetAssignment }
      />
    </DndProvider>
  )
}

SelectAssets.propTypes = {
  project: projectType.isRequired
}

export default SelectAssets

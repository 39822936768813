const emptyStakeholder = () => {
  const timestamp = (new Date()).getTime().toString()

  return {
    uuid: timestamp,
    leadNotification: false,
    weeklyReport: false,
    permission: 'write'
  }
}

export default function stakeholderHandlers(stakeholders, setStakeholders) {

  const addStakeholder = () => {
    setStakeholders([...stakeholders, emptyStakeholder()])
  }

  const removeStakeholder = ({ stakeholderUuid }) => {
    setStakeholders(stakeholders.filter(({ uuid }) => uuid !== stakeholderUuid))
  }

  const updateStakeholder = (stakeholderUuid, callback) => {
    setStakeholders(stakeholders.map((stakeholder) => {
      if (stakeholder.uuid === stakeholderUuid) {
        return callback(stakeholder)
      }

      return stakeholder
    }))
  }

  const updatePermission = ({ stakeholderUuid, permission }) => {
    updateStakeholder(stakeholderUuid, (stakeholder) => {
      return {
        ...stakeholder,
        permission
      }
    })
  }

  const updateResponsibility = ({ stakeholderUuid, responsibility }) => {
    updateStakeholder(stakeholderUuid, (stakeholder) => {
      return {
        ...stakeholder,
        responsibility
      }
    })
  }

  const updateSettings = ({ stakeholderUuid, name, enabled }) => {
    updateStakeholder(stakeholderUuid, (stakeholder) => {
      return {
        ...stakeholder,
        [name]: enabled
      }
    })
  }

  const updateUser = ({ stakeholderUuid, userUuid }) => {
    updateStakeholder(stakeholderUuid, (stakeholder) => {
      return {
        ...stakeholder,
        user: {
          uuid: userUuid
        }
      }
    })
  }

  return {
    addStakeholder,
    removeStakeholder,
    updatePermission,
    updateResponsibility,
    updateSettings,
    updateUser
  }
}


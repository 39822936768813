import { string, number, shape, oneOfType } from 'prop-types'

import assetType from 'shared/types/assetType'

export default shape({
  propertyType: oneOfType([number, string]).isRequired,
  price: oneOfType([number, string]),
  totalPrice: oneOfType([number, string]),
  currency: string,
  description: string,
  shortDescription: string,
  name: string.isRequired,
  customerName: string.isRequired,
  activeCampaignsCount: number,
  image: assetType
})

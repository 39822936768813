import React, { useState, useEffect } from 'react'
import { func, arrayOf } from 'prop-types'

import audienceLocationType from 'shared/types/audienceLocationType'
import audienceType from 'shared/types/audienceType'

import { fitCircleBounds, fitAllLocations } from '../helpers'

import MapView from './MapView'

const Map = ({
  audience,
  locations,
  selectedLocation,
  highlightedLocation,
  onMarkerClick,
  ...props
}) => {
  const [map, setMap] = useState()

  useEffect(() => {
    if (!map || !selectedLocation) return

    fitCircleBounds(map, selectedLocation)
  }, [map, selectedLocation])

  useEffect(() => {
    if (!map) return

    fitAllLocations(map, locations)
  }, [map, locations])

  const handleMapLoad = (map) => {
    setMap(map)
    fitAllLocations(map, locations)
  }

  return (
    <MapView
      map={ map }
      audience={ audience }
      locations={ locations }
      highlightedLocation={ highlightedLocation }
      onMapLoad={ handleMapLoad }
      onFitBoundsButtonClick={ fitAllLocations }
      onMarkerClick={ onMarkerClick }
      { ...props }
    />
  )
}

Map.defaultProps = {
  audience: null,
  selectedLocation: null,
  highlightedLocation: null
}

Map.propTypes = {
  locations: arrayOf(audienceLocationType).isRequired,
  onMarkerClick: func.isRequired,
  audience: audienceType,
  highlightedLocation: audienceLocationType,
  selectedLocation: audienceLocationType
}

export default Map

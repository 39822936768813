import dataURItoBlob      from 'shared/helpers/files/dataURItoBlob'
import mimeTypes          from 'shared/constants/mimeTypes'
import completeFileUpload from 'shared/helpers/files/completeFileUpload'
import attachFileMutation from 'graphql/mutations/assetable/attachFile'

export default function uploadImage({ client, agent, fileDataURI, fileMeta, mediaType }) {
  const file = dataURItoBlob(fileDataURI)
  const allowedMimeTypes = mimeTypes.logo

  return completeFileUpload({ client, file, allowedMimeTypes, fileMeta })
    .then(({ signedBlobId }) => {
      const blobId = signedBlobId
      const assetableUuid = agent.uuid
      const assetableType = 'campaigns_landingpages_agent'

      return client.mutate({
        mutation: attachFileMutation,
        variables: { blobId, assetableUuid, assetableType, mediaType }
      })
    })
}

import React, { useRef, useState, useEffect } from 'react'
import { func, arrayOf } from 'prop-types'
import styled from 'styled-components'

import audienceLocationType from 'shared/types/audienceLocationType'

import ListItem from './ListItem'

const List = ({
  onRemove,
  onItemClick,
  onLocationChange,
  onHighlight,
  locations,
  highlightedLocation,
  className
}) => {
  const listContainer = useRef()

  const [prevLocationsLength, setPrevLocationsLength] = useState(locations.length)

  useEffect(() => {
    if(prevLocationsLength && (prevLocationsLength < locations.length)) {
      const list = listContainer.current
      const maxScrollTop = list.scrollHeight - list.clientHeight
      list.scrollTop = Math.max(maxScrollTop, 0)
    }
    setPrevLocationsLength(locations.length)
  }, [locations.length])

  const isHiglightedLocation = (location) => (
    !!highlightedLocation && highlightedLocation.uuid === location.uuid
  )

  if (locations.length === 0) {
    return null
  }

  return (
    <div className={ className } ref={ listContainer }>
      {
        locations.map((location) => {
          return <ListItem
            key={ location.uuid }
            location={ location }
            onRemove={ onRemove }
            onLocationChange={ onLocationChange }
            onClick={ onItemClick }
            onHighlight={ onHighlight }
            highlighted={ isHiglightedLocation(location) }
          />
        })
      }
    </div>
  )
}

List.defaultProps = {
  highlightedLocation: null
}

List.propTypes = {
  locations: arrayOf(audienceLocationType).isRequired,
  onHighlight: func.isRequired,
  onItemClick: func.isRequired,
  onLocationChange: func.isRequired,
  onRemove: func.isRequired,
  highlightedLocation: audienceLocationType
}

export default styled(List)`
  margin-bottom: 0.25rem
  max-height: 40vh
  overflow-y: scroll
  font-size: 0.8rem

  border-color: rgb(204, 204, 204);
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.063rem;
`

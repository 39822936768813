import React from 'react'
import { components } from 'react-select'
import { shape, string } from 'prop-types'

const Option = props => {
  return (
    <components.Option { ...props }>
      <div className="d-flex justify-content-between">
        <span>{props.data.name}</span>
      </div>
    </components.Option>
  )
}

Option.propTypes = {
  data: shape({
    name: string.isRequired
  }).isRequired
}
export default Option

import React          from 'react'
import { upperFirst } from 'lodash'
import { t }          from 'ttag'
import { arrayOf, shape, string } from 'prop-types'

import UserRoleNavLink from 'routes/UserRoleNavLink'

const UserLocationInfo = ({ user }) => {
  const rolesString = user.roles.map(({ roleName }) => upperFirst(roleName)).join(', ')

  return (
    <>
      <div className="info-row">
        <label>{ t`Companies` }</label>
        <div>
          { user.companies.map((company) => (
            <p key={ company.uuid } className="mb-1">
              <UserRoleNavLink to={ `companies/${company.uuid}` }>
                { company.name }
              </UserRoleNavLink>
            </p>
          )) }
        </div>
      </div>
      <p className="info-row">
        <label>{ t`Role` }</label>
        <span>{ rolesString }</span>
      </p>
    </>
  )
}

UserLocationInfo.propTypes = {
  user: shape({
    companies: arrayOf(shape({
      location: shape({
        city: string
      }),
      name: string
    })).isRequired,
    roles: arrayOf(shape({
      roleName: string
    }))
  }).isRequired
}

export default UserLocationInfo

import React from 'react'

import matchType   from 'shared/types/matchType'
import NewBuilding from 'shared/components/NewBuilding'

const BuildingFormPage = ({ match }) => <NewBuilding uuid={ match.params.uuid } />

BuildingFormPage.propTypes = {
  match: matchType.isRequired
}

export default BuildingFormPage

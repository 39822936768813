import { gql } from 'apollo-boost'

export default gql`
  mutation createProject(
    $input: ProjectInput!,
    $districtUuid: ID,
    $stakeholders: [StakeholderAttributes!]
    $companies: [CompanyAttributes!]
  ) {
    createProject(
      input: $input,
      districtUuid: $districtUuid,
      stakeholders: $stakeholders,
      companies: $companies
    ) {
      project {
        uuid
      }
    }
  }
`

import updateUnitMutation from 'graphql/mutations/projects/stages/units/updateUnitMutation'

const updateUnit = (client, uuid, attributes) => (
  client.mutate({
    mutation: updateUnitMutation,
    variables: { uuid, attributes }
  })
)

export default updateUnit

import React                        from 'react'
import { element, func, oneOfType } from 'prop-types'
import { Route as BaseRoute }       from 'react-router-dom'

import withAppRedirect from './withAppRedirect'

const Route = ({ component, ...props }) => {
  return <BaseRoute { ...props } component={ withAppRedirect(component) } />
}

Route.propTypes = {
  component: oneOfType([element, func]).isRequired
}

export default Route

import React           from 'react'
import { useQuery }    from 'react-apollo'
import { t }           from 'ttag'
import { useMutation } from '@apollo/react-hooks'
import { omit }        from 'lodash'

import matchType          from 'shared/types/matchType'
import NewCampaignForm    from 'shared/components/NewAutoCampaign/CampaignForm'
import facebookPagesQuery from 'graphql/queries/companies/facebookPages'
import createMutation     from 'graphql/mutations/campaign/create'

import onCampaignCreated from './onCampaignCreated'
import onCampaignCreationFailed from './onCampaignCreationFailed'
import LoadingForm from './LoadingForm'
import stageQuery  from './stageQuery'

const CampaignForm = ({ match }) => {
  const [createCampaign, { loading: loadingCreateCampaign }] = useMutation(createMutation)
  const uuid = match.params.uuid

  const { data, loading: loadingStage } = useQuery(
    stageQuery,
    { variables: { uuid: match.params.uuid } }
  )

  const {
    data: facebookPagesData,
    loading: loadingFacebookPages
  } = useQuery(facebookPagesQuery, {
    variables: { stageUuid: uuid }
  })

  if (loadingStage || loadingFacebookPages || loadingCreateCampaign) {
    return <LoadingForm />
  }

  const facebookPages    = facebookPagesData.companyFacebookPages?.facebookPages
  const facebookPageUuid = facebookPagesData.companyFacebookPages?.defaultFacebookPageUuid
  const campaignsCount = data?.stage?.campaignsCount

  const onSubmit = (values, actions) => {
    const attributes = omit(values, ['goalNumber', 'goalType', 'targetLqs'])
    const goals      = {
      number: values.goalNumber,
      type:   values.goalType,
      lqs:    values.targetLqs
    }

    createCampaign({ variables: { attributes, goals } })
      .then(({ data: { createCampaign } }) => {
        onCampaignCreationFailed(createCampaign.errors)
        onCampaignCreated(createCampaign.campaign)
      })
      .finally(() => actions.setSubmitting(false))
  }

  const formValues = {
    stageUuid: uuid,
    name: `${t`Campaign`} ${(campaignsCount || 0) + 1}`,
    facebookPageUuid
  }

  return (
    <NewCampaignForm
      formValues={ formValues }
      facebookPages={ facebookPages }
      onSubmit={ onSubmit }
    />
  )
}

CampaignForm.propTypes = {
  match: matchType.isRequired
}

export default CampaignForm

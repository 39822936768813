import React from 'react'

import matchType   from 'shared/types/matchType'
import StagesSelect from 'shared/components/NewAutoCampaign/StagesSelect'

const Stages = ({ match }) => <StagesSelect projectUuid={ match.params.uuid } />

Stages.propTypes = {
  match: matchType.isRequired
}

export default Stages

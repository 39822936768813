import React from 'react'
import styled from 'styled-components'
import { bool } from 'prop-types'

import Checkbox from 'shared/components/ui/Checkbox'

const SoldCheckbox = ({ className, value }) => {
  return (
    <div className={ className }>
      <Checkbox
        readOnly
        shadow={ false }
        checked={ value }
      />
    </div>
  )
}

SoldCheckbox.propTypes = {
  value: bool
}

SoldCheckbox.defaultProps = {
  value: false
}

export default styled(SoldCheckbox)`
  height: 2.25rem;
  display: flex;
  align-items: center;
`

import { gql } from 'apollo-boost'

import campaign from 'graphql/fragments/channel'

export default gql`
  mutation createGmpAudienceLocation(
    $audienceUuid: ID!
    $gmpId: String!
    $operator: String!
  ) {
    createGmpAudienceLocation(
      audienceUuid: $audienceUuid
      gmpId: $gmpId
      operator: $operator
    ) {
      audienceLocation {
        uuid
        latitude
        longitude
        radius
        operator
        country
        placeName
        title
        locationType
      }
      campaign {
        channels {
          ... Channel
        }
      }
    }
  }
  ${campaign}
`

import gql from 'graphql-tag'

import productStakeholder from 'graphql/fragments/productStakeholder'
import stage from 'graphql/fragments/stage'
import project from 'graphql/fragments/project'
import property from 'graphql/fragments/property'
import agentPromotion from 'graphql/fragments/agentPromotion'
import asset from 'graphql/fragments/asset'

export default gql`
  fragment Product on Product {
    uuid
    link
    name
    owner
    createdAt
    company {
      uuid
      name
      enabledFeatures
      logo {
        uuid
        thumbnail
      }
    }
    stakeholders {
      ... ProductStakeholder
    }
    promotableType
    promotable {
      uuid
      propertyType
      currency
      logos {
        ... Asset
      }
      assets {
        ... Asset
      }
      ... on Property {
        ... Property
      }
      ... on Project {
        ... Project
      }
      ... on Stage {
        ... Stage
      }
      ... on AgentPromotion {
        ... AgentPromotion
      }
    }
  }
  ${productStakeholder}
  ${property}
  ${stage}
  ${asset}
  ${project}
  ${agentPromotion}
`

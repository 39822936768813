import React from 'react'
import { Form, Row, Col }    from 'react-bootstrap'
import { any, string, node } from 'prop-types'

const FormField = ({ label, touched, errors, children }) => {
  const isInvalid = touched && errors

  return (
    <Form.Group as={ Row }>
      <Form.Label column sm="4">{ label }</Form.Label>
      <Col sm="8">
        { children }

        <Form.Control.Feedback type="invalid" className={ isInvalid && 'd-block' }>
          { errors }
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}

FormField.propTypes = {
  children: node.isRequired,
  label: string.isRequired,
  errors: string,
  touched: any
}

FormField.defaultProps = {
  touched: false,
  errors: ''
}

export default FormField

import React, { useState, useRef } from 'react'
import { OverlayTrigger, Popover, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { func, string, number } from 'prop-types'
import Slider from 'rc-slider'

import 'rc-slider/assets/index.css'

import Button from 'shared/components/ui/Button'

const MIN_RADIUS = 15
const MAX_RADIUS = 80

const RadiusControl = ({ value, onRadiusChange, className }) => {
  const inputRef = useRef()
  const overlayRef = useRef()

  const [controlValue, setControlValue] = useState(value / 1000)

  const handleRadiusSliderAfterChange = (value) => {
    setControlValue(value)
    onRadiusChange(value * 1000)
  }

  const handleRadiusSliderChange = (value) => {
    setControlValue(value)
    inputRef.current.value = value
  }

  const handleRadiusInputChange = (event) => {
    const roundedValue = Math.round(100 * parseFloat(event.target.value)) / 100

    const value = event.target.value.length
      ? Math.max(Math.min(roundedValue, MAX_RADIUS), MIN_RADIUS)
      : MIN_RADIUS

    setControlValue(value)
    onRadiusChange(value * 1000)
  }

  const handleKeyDown = (event) => {
    if(event.key === 'Enter') {
      handleRadiusInputChange(event)
      overlayRef.current.hide()
    }
  }

  const popover = (
    <Popover className={ className }>
      <Popover.Content>
        <Slider
          min={ MIN_RADIUS }
          max={ MAX_RADIUS }
          value={ controlValue }
          onAfterChange={ handleRadiusSliderAfterChange }
          onChange={ handleRadiusSliderChange }
        />
        <RadiusInput
          min={ MIN_RADIUS }
          max={ MAX_RADIUS }
          ref={ inputRef }
          type='number'
          defaultValue={ controlValue }
          size='sm'
          onBlur={ handleRadiusInputChange }
          onKeyDown={ handleKeyDown }
        />
        &nbsp;
        km
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger
      rootClose
      ref={ overlayRef }
      trigger='click'
      placement='right'
      overlay={ popover }
      className={ className }
    >
      <ToggleButton>
        +
        &nbsp;
        { controlValue }
        &nbsp;
        km
      </ToggleButton>
    </OverlayTrigger>
  )
}

RadiusControl.defaultProps = {
  value: null
}

RadiusControl.propTypes = {
  className: string.isRequired,
  onRadiusChange: func.isRequired,
  value: number
}

const RadiusInput = styled(Form.Control)`
  width: 4.25rem
  margin-left: 1rem
`

const ToggleButton = styled(Button)`
  margin-left: 0.5rem

  &:focus {
    outline: none
  }
`

export default styled(RadiusControl)`
  .rc-slider {
    width: 6rem
  }

  .popover-body {
    min-height: 2rem
    display: flex
    flex-direction: row
    align-items: center
  }
`

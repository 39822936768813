import { gql } from 'apollo-boost'

export default gql`
  mutation removeSnapshotCustomFacility($uuid: ID!) {
    removeSnapshotCustomFacility(uuid: $uuid) {
      customFacility {
        uuid
      }
    }
  }
`

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { node, func, array, string } from 'prop-types'
import classNames from 'classnames'

import AssetContainer from '../AssetContainer'

import AssetUploading from './AssetUploading'
import useFilesUpload from './useFilesUpload'
import useAssetDrop from './useAssetDrop'
import useImageDrop from './useImageDrop'
import useUploadOnClick from './useUploadOnClick'

const UploadContainer = ({
  className,
  children,
  onAssetDrop,
  onFileUploaded,
  assetAssignments,
  mediaType,
  channelType,
  onIsUploadingChange
}) => {

  const { uploadFiles, isUploading } = useFilesUpload({ onFileUploaded, mediaType })

  useEffect(() => {
    onIsUploadingChange(isUploading)
  }, [isUploading])

  const validateVideoSize = channelType === 'instagram'
  const {
    handleDragOver,
    handleDrop,
    handleDragLeave,
    isOver: imageIsOver
  } = useImageDrop({ uploadFiles, mediaType, validateVideoSize })
  const [{ isOver }, drop] = useAssetDrop({ assetAssignments, mediaType, onAssetDrop })
  const { handleClick, fileInput } = useUploadOnClick({ uploadFiles, mediaType, validateVideoSize })

  return (
    <div
      ref={ drop }
      onDragOver={ handleDragOver }
      onDragLeave={ handleDragLeave }
      onDrop={ handleDrop }
      onClick={ handleClick }
      data-test-id="upload-container"
      data-testid="upload-container"
      className={ classNames(className, { 'drop-over': isOver || imageIsOver }) }
    >
      { fileInput }

      { isUploading ? (
        <AssetUploading />
      ) : (
        children
      )}
    </div>
  )
}

UploadContainer.propTypes = {
  children: node.isRequired,
  mediaType: string.isRequired,
  assetAssignments: array,
  channelType: string,
  onAssetDrop: func,
  onFileUploaded: func,
  onIsUploadingChange: func
}

UploadContainer.defaultProps = {
  assetAssignments: [],
  channelType: '',
  onAssetDrop: () => {},
  onFileUploaded: () => {},
  onIsUploadingChange: () => { }
}

export default styled(UploadContainer)`
  display: inline;

  &.drop-over {
    ${AssetContainer} {
      border-style: dashed;
      opacity: 0.5;
    }
  }
`

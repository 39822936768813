import React from 'react'
import { string } from 'prop-types'

import ResourceQuery from 'shared/components/ResourceQuery'

import ReadonlyProject from './Readonly'
import RegularProject from './Regular'
import projectQuery from './projectQuery'

const Project = ({ uuid }) => {
  return (
    <ResourceQuery query={ projectQuery } variables={{ uuid }} fetchPolicy="network-only">
      {(project) => (
        project.readonly && !project.snapshotsEnabled ? (
          <ReadonlyProject project={ project } />
        ) : (
          <RegularProject project={ project } />
        )
      )}
    </ResourceQuery>
  )
}

Project.propTypes = {
  uuid: string.isRequired
}

export default Project

import gql from 'graphql-tag'

import format from 'graphql/fragments/format'

export default gql`
  fragment Banner on Banner {
    uuid
    name
    format {
      ... Format
    }
    bannerTemplateCollection {
      uuid
    }
  }
  ${format}
`

import { gql } from 'apollo-boost'

import lead from 'graphql/fragments/lead'
import activityLog from 'graphql/fragments/leadActivityLog'

export default gql`
  query Lead($uuid: ID!) {
    lead(uuid: $uuid) {
      ... Lead
      ... ActivityLog
      address
      project {
        uuid
        name

        product {
          uuid
          company {
            uuid
          }
        }
      }
      stage {
        uuid
        name
      }

      units {
        uuid
        unitId
      }
    }
  }
  ${lead}
  ${activityLog}
`

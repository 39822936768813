import gql from 'graphql-tag'

import asset from 'graphql/fragments/asset'

export default gql`
  fragment BannerTemplateCollection on BannerTemplateCollection {
    uuid
    name
    promotableType
    channelType
    assetsAmount
    thumbnailAsset {
      ... Asset
    }
    thumbnailBanner {
      uuid
    }
 }
  ${asset}
`

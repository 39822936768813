import gql from 'graphql-tag'

import creativeSet from 'graphql/fragments/creativeSet'

export default gql`
  mutation setCreativeSetBannerTemplateCollection($uuid: ID!, $bannerTemplateCollectionUuid: ID!) {
    setCreativeSetBannerTemplateCollection(
      uuid: $uuid, bannerTemplateCollectionUuid: $bannerTemplateCollectionUuid
    ) {
      creativeSet {
        ... CreativeSet
      }
    }
  }
  ${creativeSet}
`

import React                   from 'react'
import { Formik }              from 'formik'
import { Form }                from 'react-bootstrap'
import { t }                   from 'ttag'
import { array, object, func } from 'prop-types'
import { toast }               from 'react-toastify'
import moment                  from 'moment'

import history                                   from 'browserHistory'
import { dateInThePast, lessThanOneHourFromNow } from 'shared/helpers/date'
import PrimaryButton                             from 'shared/components/ui/PrimaryButton'
import { isUserAdmin }                           from 'shared/helpers/auth'

import PageContainer from '../CampaignForm/PageContainer'
import FormContainer from '../CampaignForm/FormContainer'

import Fieldset                       from './Fieldset'
import validationSchema               from './validationSchema'
import useInitialValues               from './useInitialValues'
import useSource                      from './useSource'
import useEmVestStartDateInDeadPeriod from './useEmVestStartDateInDeadPeriod'

const PropertyCampaignForm = ({
  property,
  formValues,
  facebookPages,
  onSubmit
}) => {
  const handleGoBack = (e) => {
    e.preventDefault()
    history.goBack()
  }

  const source = useSource(property)

  const handleOnSubmit = (values, actions) => {
    if (!isUserAdmin() && useEmVestStartDateInDeadPeriod(values.startDate, source)) {
      toast.error(t`Start date must be before Friday 15:00 or after Monday 08:00`)
      return
    }

    if (!isUserAdmin() && dateInThePast(moment(values.startDate).subtract(24, 'hour'))) {
      toast.error(t`Start time must be at least 24 hours from now`)
      return
    }

    if (isUserAdmin() && lessThanOneHourFromNow(values.startDate)) {
      toast.error(t`Start time must be at least 1 hour from now`)
      return
    }

    if (dateInThePast(moment(values.endDate).subtract(24, 'hour'))) {
      toast.error(t`End date must be in the future`)
      return
    }

    if (moment(values.endDate).isBefore(moment(values.startDate))) {
      toast.error(t`The end date must be greater than the start date`)
      return
    }

    if (!values.blueprintTemplateUuid) {
      toast.error(t`Please select a campaign package`)
      return
    }

    onSubmit(values, actions)
  }

  const initialValues = useInitialValues(property, formValues)
  return (
    <PageContainer>
      <FormContainer>
        <h2 className="form-header">{ t`Set start and end dates, select campaign package` }</h2>

        <Formik
          enableReinitialize
          initialValues={ initialValues }
          validationSchema={ validationSchema }
          onSubmit={ handleOnSubmit }
        >
          { ({ handleSubmit, ...props }) => (
            <Form className="campaign-form" onSubmit={ handleSubmit }>
              <Fieldset
                facebookPages={ facebookPages }
                property={ property }
                { ...props }
              />

              <div className="form-footer">
                <a href="#" onClick={ handleGoBack } className="btn-back">{ t`Go back` }</a>
                <PrimaryButton type="submit" data-test-id="campaign-submit">
                  { t`Submit` }
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </PageContainer>
  )
}

PropertyCampaignForm.propTypes = {
  facebookPages: array.isRequired,
  onSubmit: func.isRequired,
  property: object.isRequired,
  formValues: object
}

PropertyCampaignForm.defaultProps = {
  formValues: {}
}

export default PropertyCampaignForm

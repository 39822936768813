import React from 'react'
import { arrayOf, shape, string, func, number } from 'prop-types'

const View = ({ getStyles, views, index }) => {
  const mediaStyle = {
    height: 'auto',
    maxHeight: '75vh',
    maxWidth: '100%',
    userSelect: 'none'
  }

  const asset = views[index].asset
  if (asset.mediaType === 'logo') {
    mediaStyle.backgroundColor = asset.backgroundColor
  }

  return (
    <div style={ getStyles('view') }>
      { views[index].asset.mediaType === 'video' ? (
        <video controls style={ mediaStyle }>
          <source src={ views[index].source } />
        </video>
      ) : (
        <img src={ views[index].source } alt={ `Media ${index}` } style={ mediaStyle } />
      ) }
    </div>
  )
}

View.propTypes = {
  getStyles: func.isRequired,
  index: number.isRequired,
  views: arrayOf(shape({
    mediaType: string
  })).isRequired
}

export default View

import React             from 'react'
import { arrayOf, func } from 'prop-types'

import UserRoleNavLink from 'routes/UserRoleNavLink'
import propertyType    from 'shared/types/propertyType'
import CardList        from 'shared/components/ui/CardDeck/List'

import PropertyCard from './PropertyCard'

const PropertyListView = ({ properties, buildLink }) => {
  return (
    <CardList>
      {
        properties.map((property) =>
          <UserRoleNavLink
            key={ property.uuid }
            to={ buildLink(property) }
            className={ 'no-text-decoration' }
            data-test-id='property-item'
          >
            <PropertyCard property={ property } />
          </UserRoleNavLink>
        )
      }
    </CardList>
  )
}

PropertyListView.propTypes = {
  buildLink: func.isRequired,
  properties: arrayOf(propertyType).isRequired
}

export default PropertyListView

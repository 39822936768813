import React from 'react'
import styled from 'styled-components'
import { arrayOf, func, shape, string } from 'prop-types'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap'

import { inputBoxShadow, inputHoverBoxShadow } from 'shared/style/placeholders'
import {
  textColor,
  buttonBorderColor,
  buttonTextColor,
  havelockBlue
} from 'shared/style/colors'

const ToggleGroup = ({ name, options, value, onChange }) => (
  <Group type="radio" name={ name } value={ value } onChange={ onChange }>
    {options.map(({ value, label }) =>
      <Button name={ name } value={ value } key={ value }>{ label }</Button>
    )}
  </Group>
)

const Group = styled(ToggleButtonGroup)`
  ${inputBoxShadow}

  label:focus,
  label.focus {
    box-shadow: none
  }

  label.active {
    background-color: ${havelockBlue} !important
    border-color: #ced4da !important
  }
`

const Button = styled(ToggleButton)`
  background-color: white
  border-color: #ced4da
  color: ${textColor}
  cursor: pointer
  font-size: 0.875rem
  line-height: 1.75rem
  padding: 0 1.5rem

  &:hover {
    ${inputHoverBoxShadow}
  }

  &.btn-primary {
    background-color: white
    border-color: ${buttonBorderColor}
    color: ${buttonTextColor}
  }

  &.btn-primary:hover {
    color: ${buttonTextColor}
  }
`

ToggleGroup.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  options: arrayOf(shape({
    value: string.isRequired,
    label: string.isRequired
  })).isRequired,
  value: string.isRequired
}

export default ToggleGroup

import React     from 'react'
import { Media } from 'react-bootstrap'
import { shape, string } from 'prop-types'

import Card from 'shared/components/ui/Card'

import Footer from './Footer'

const Summary = ({ company }) => {
  const logoUrl = company?.logo?.thumbnail
  return (
    <Card
      className="mb-2"
      footer={ <Footer company={ company } /> }
    >
      <Media>
        { logoUrl && (
          <img
            height={ 56 }
            width={ 56 }
            src={ logoUrl }
            className="mr-3 rounded-circle"
            alt="Logo"
          />
        ) }
        <Media.Body>
          <h5>
            { company.name }
          </h5>
        </Media.Body>
      </Media>
    </Card>
  )
}

Summary.propTypes = {
  company: shape({
    name: string.isRequired
  }).isRequired
}

export default Summary

import React from 'react'
import styled from 'styled-components'

import DeleteIcon from 'shared/assets/icons/svg/trash.svg'

const DeleteButton = ({ className, ...props }) => (
  <button className={ className } type="button" data-test-id='delete-button' { ...props }>
    <DeleteIcon />
  </button>
)

export default styled(DeleteButton)`
  border: none;
  border-radius: 4px;
  background-color: white;
  padding: 0.5rem 1rem;

  :hover {
    background-color: #f0f0f0;
  }
`

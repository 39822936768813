import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { array } from 'prop-types'

import Card from 'shared/components/ui/Card'
import DraggableGallery from 'shared/components/Media/DraggableGallery'
import AssetsContainer from 'shared/components/ui/AssetsContainer'

import { MediaContainer, Hr } from './elements'
import useMediaAlignment from './useMediaAlignment'

const Media = ({ className, assetAssignments, projectAssetAssignments, stageAssetAssignments }) => {
  const filterType = (assignments, type) => (
    assignments
      .map(assignment => assignment.asset)
      .filter(asset => asset.mediaType === type)
  )

  const mediaStyle = useMediaAlignment()
  const projectLogos = filterType(projectAssetAssignments, 'logo')
  const assets = filterType(assetAssignments, 'image')
  const campaignLogos = filterType(assetAssignments, 'logo')
  const stageAssets = filterType(stageAssetAssignments, 'image')
  const projectMedia = filterType(projectAssetAssignments, 'image')

  return (
    <MediaContainer className={ className }>
      <Card header={ t`Media` } style={ mediaStyle }>
        <h5>{ t`Project logos` }</h5>
        <DraggableGallery assets={ projectLogos } />
        <Hr />
        <h5>{ t`Logos` }</h5>
        <DraggableGallery assets={ campaignLogos } />
        <Hr />
        <h5>{ t`Images` }</h5>
        <DraggableGallery assets={ assets } />
        <Hr />
        <h5>{ t`Stage images` }</h5>
        <DraggableGallery assets={ stageAssets } />
        <Hr />
        <h5>{ t`Project images` }</h5>
        <DraggableGallery assets={ projectMedia } />
      </Card>
    </MediaContainer>
  )
}

Media.propTypes = {
  assetAssignments: array,
  projectAssetAssignments: array,
  stageAssetAssignments: array
}

Media.defaultProps = {
  assetAssignments: [],
  projectAssetAssignments: [],
  stageAssetAssignments: []
}

export default styled(Media)`
  ${Card} {
    position: fixed;
    width: 20rem;

    .card-body {
      max-height: 80vh;
      overflow: scroll;
    }
  }

  ${AssetsContainer} {
    grid-template-columns: repeat(2, calc(100%/2 - .65rem));
    margin: 0
  }
`

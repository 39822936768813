import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'

import { textColor } from 'shared/style/colors'

const Placeholder = ({ className }) => (
  <div className={ className }>
    <strong>{ t`Custom site` }</strong>
    <p>{ t`No preview available for custom websites` }</p>
  </div>
)

export default styled(Placeholder)`
  align-items: center;
  color: ${textColor};
  display: flex;
  font-size: 0.875rem;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`

import React from 'react'
import { shape, string, array, func } from 'prop-types'

import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'

import { instagramPositions } from './positions'
import PositionsGroup from './PositionsGroup'

const InstagramPlacements = ({ platformAudience, errors, update }) => {
  const handleInstagramPositionsChange = instagramPositions =>
    update({ instagramPositions })

  return (
    <InputWrapper error={ errors }>
      <PositionsGroup
        name="Instagram"
        options={ instagramPositions }
        selected={ platformAudience.instagramPositions }
        onChange={ handleInstagramPositionsChange } />
    </InputWrapper>
  )
}

InstagramPlacements.propTypes = {
  platformAudience: shape({
    platform: string,
    instagramPositions: array
  }).isRequired,
  update: func.isRequired,
  errors: shape({
    errorMessage: string
  })
}

InstagramPlacements.defaultProps = {
  errors: {}
}

export default InstagramPlacements

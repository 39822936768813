import React from 'react'
import { Router, Switch, Route as BaseRoute } from 'react-router-dom'

import Route  from 'routes/Route'
import routes from 'routes/index'
import NotFound from 'shared/components/pages/NotFound'
import ResetPassword   from 'user/components/pages/ResetPassword'
import withLoginLayout from 'shared/components/LoginLayout/withLoginLayout'
import browserHistory from 'browserHistory'

const AppRouter = () => (
  <Router history={ browserHistory }>
    <Switch>
      {routes.map((routeProps) => (
        <Route key={ routeProps.path } { ...routeProps }/>
      ))}

      <BaseRoute path="/reset-password" component={ withLoginLayout(ResetPassword) } />

      <Route component={ NotFound } />
    </Switch>
  </Router>
)

export default AppRouter

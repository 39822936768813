import { gql } from 'apollo-boost'

export default gql`
  mutation updateAssignment(
    $uuid: ID!,
    $input: AssetInput!
  ) {
    updateAsset(
      uuid: $uuid
      input: $input
    ) {
      asset {
        uuid
        title
        altText
        description
      }
    }
  }
`

import { t } from 'ttag'

import Ads       from '../Ads'
import Audiences from '../Audiences'

export default [{
  title: t`Ads`,
  component: Ads
}, {
  title: t`Audiences`,
  component: Audiences
}]

import { object, func, bool } from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { t } from 'ttag'
import styled from 'styled-components'
import { omit } from 'lodash'

import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'
import Location from 'shared/components/Location'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import { iconsGreyColor } from 'shared/style/colors'
import performCustomValidation from 'shared/helpers/performCustomValidation'

import mainDetailsValidationSchema from './mainDetailsValidationSchema'

const MainDetails = ({ className, stage, updateStage, errors, liveValidationEnabled }) => {
  const [name, setName] = useState(stage.name)
  const [location, setLocation] = useState(stage.location || {})
  const [formErrors, setFormErrors] = useState(errors)

  useEffect(() => {
    setFormErrors(errors)
  }, [errors])

  useEffect(() => {
    setName(stage.name)

    if (liveValidationEnabled) {
      performCustomValidation(
        { name, location },
        mainDetailsValidationSchema,
        setFormErrors,
        false
      )
    }
  }, [stage.name])

  useEffect(() => {
    setLocation(stage.location)

    if (liveValidationEnabled) {
      performCustomValidation(
        { name, location },
        mainDetailsValidationSchema,
        setFormErrors,
        false
      )
    }
  }, [stage.location])

  const updateLocation = useCallback((locationChanges) => {
    const input = { name, location: { ...location, ...locationChanges } }
    let errorKeys = []

    if (liveValidationEnabled) {
      const errors = performCustomValidation(
        input, mainDetailsValidationSchema, setFormErrors, false
      )
      errorKeys = Object.keys(errors)
      if (errorKeys.length >= Object.keys(location).length) {
        return
      }
    }

    const filteredChanges = omit(locationChanges, errorKeys)
    setLocation((prev) => ({ ...prev, ...filteredChanges }))

    if (Object.keys(filteredChanges).length) {
      updateStage({}, filteredChanges)
    }
  }, [name, location])

  const updateName = useCallback((newName) => {
    if (liveValidationEnabled) {
      const errors = performCustomValidation(
        { name: newName, location },
        mainDetailsValidationSchema,
        setFormErrors,
        false
      )
      setName(newName)

      if (Object.keys(errors).includes('name')) {
        return
      }
    }
    updateStage({ name: newName }, {})
  }, [name, location])

  return (
    <EmbeddedCardSection titleText=''>
      <div className={ className }>
        <div className="group">
          <label>{ t`Stage` }</label>
          <InputWrapper error={ formErrors.name }>
            <ThriftyInput
              type="text"
              data-test-id='name-field'
              value={ name }
              onUpdate={ updateName }
            />
          </InputWrapper>
        </div>
      </div>
      <Location
        location={ location }
        data-test-id='location'
        updateLocation={ updateLocation }
        errors={ formErrors } />
    </EmbeddedCardSection>
  )
}

MainDetails.propTypes = {
  stage: object.isRequired,
  updateStage: func.isRequired,
  errors: object,
  liveValidationEnabled: bool
}

MainDetails.defaultProps = {
  errors: {},
  liveValidationEnabled: false
}

export default styled(MainDetails)`
  input {
    box-shadow: none;
  }

  label {
    color: ${iconsGreyColor};
    display: block;
    font-size: 0.85rem;
    font-weight: normal;
    margin: 0;
  }

  .group {
    margin-bottom: 10px;
  }
`

import React from 'react'
import { t } from 'ttag'
import { object } from 'prop-types'

import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'

import Stakeholders from '../Stakeholders'

const StageStakeholders = ({ product, className }) => {
  return (
    <EmbeddedCardSection titleText={ t`Stakeholders` } className={ className }>
      <Stakeholders product={ product } />
    </EmbeddedCardSection>
  )
}

StageStakeholders.propTypes = {
  product: object.isRequired
}

export default StageStakeholders

import { gql } from 'apollo-boost'

export default gql`
  mutation updateUserNotifications(
    $event: NotificationEvent!,
    $destination: NotificationDestination!,
    $enabled: Boolean!
  ) {
    updateUserNotifications(
      event: $event,
      destination: $destination,
      enabled: $enabled
    )
  }
`

import { t } from 'ttag'

import SalesArguments from 'shared/components/ProductDetails/StageDetails/SalesArguments'

import Stakeholders from './Stakeholders'
import Details from './Details'
import Units from './Units'
import StagesMedia from './StagesMedia'

export default [{
  title: t`Stakeholders`,
  component: Stakeholders
}, {
  title: t`Stage details`,
  component: Details
}, {
  title: t`Unit information`,
  component: Units
}, {
  title: t`Sales arguments`,
  component: SalesArguments
}, {
  title: t`Media`,
  component: StagesMedia
}]

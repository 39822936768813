export const exactRatio = (assetSizes, formatSizes) => {
  if (!assetSizes || assetSizes.length !== 2) return false

  return (assetSizes[0] / assetSizes[1]).toFixed(2) === (formatSizes[0] / formatSizes[1]).toFixed(2)
}

export const ratioCloseToSquare = (assetSizes) => {
  if (!assetSizes || assetSizes.length !== 2) return false

  const assetRatio = (assetSizes[0] / assetSizes[1]).toFixed(2)

  return 0.8 <= assetRatio && assetRatio <= 1.91
}

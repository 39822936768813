import React from 'react'
import Select from 'react-select'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { string } from 'prop-types'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import { inputBoxShadowValue } from 'shared/style/boxShadowValues'
import landingPageTemplatesQuery from 'graphql/queries/landingPageTemplates'

import useSetTemplate from './useSetTemplate'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    boxShadow: inputBoxShadowValue,
  }),
}

const TemplatesSelect = ({ className, value, projectUuid, ...props }) => {
  const setTemplate = useSetTemplate(projectUuid)
  const { data, loading } = useQuery(landingPageTemplatesQuery)

  const options = loading ? [] : data.landingPageTemplates.map(el => ({
    value: el.uuid,
    label: el.name
  }))

  const currentOption = value && options.find(el => el.value === value)

  return (
    <div className={ className } data-test-id="templates-select-wrapper">
      <Select
        onChange={ setTemplate }
        value={ currentOption }
        options={ options }
        styles={ styles }
        placeholder={ t`Select...` }
        noOptionsMessage={ () => t`No options` }
        { ...props }
      />
    </div>
  )
}

TemplatesSelect.propTypes = {
  projectUuid: string.isRequired,
  value: string
}

TemplatesSelect.defaultProps = {
  value: ''
}

export default TemplatesSelect

import { gql } from 'apollo-boost'

import DashboardDataset from 'graphql/fragments/dashboardDataset'

export default gql`
  query DashboardAnalyticsOtherMonthlyLeads($filter: JSON) {
    dashboardAnalyticsOtherMonthlyLeads(filter: $filter) {
      otherMonthlyLeads {
        ... DashboardDataset
      }
    }
  }
  ${DashboardDataset}
`

import moment from 'moment'
import { useMutation } from 'react-apollo'

import updateShowingMutation from 'graphql/mutations/showings/update'
import { jsDate, copyTime } from 'shared/helpers/date'

export default function useShowing({ uuid, startDate, endDate }) {
  const [updateShowing] = useMutation(updateShowingMutation)

  const handleDateChange = (value) => {
    const selectedDate = moment(value).set({ hours: 12, minutes: 0 })
    let newStartDate = moment(selectedDate)
    let newEndDate   = moment(selectedDate)

    if (startDate) {
      newStartDate = copyTime(newStartDate, moment(startDate))
    }

    if (endDate) {
      newEndDate = copyTime(newStartDate, moment(endDate))
    }

    updateShowing({
      variables: { uuid, startDate: newStartDate.toISOString(), endDate: newEndDate.toISOString() }
    })
  }

  const handleSingleDateChange = (dateKey, value) => {
    const selectedDate = moment(value)

    updateShowing({
      variables: { uuid, [dateKey]: selectedDate.toISOString() }
    })
  }

  const handleStartTimeChange = (value) => handleSingleDateChange('startDate', value)

  const handleEndTimeChange = (value) => handleSingleDateChange('endDate', value)

  const minStartDate = new Date()

  return {
    handleDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    minStartDate,
    selectedStartDate: startDate ? jsDate(startDate) : undefined,
    selectedEndDate: endDate ? jsDate(endDate) : undefined,
  }
}

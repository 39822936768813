import { t } from 'ttag'

import composeErrorData from './composeErrorData'

export default ({
  validatePayload,
  payload,
  context,
  propogateError,
  setErrors,
  setValidationPending,
  updated,
  force
}) => {
  try {
    validatePayload({ payload, updated, context })
    setErrors([])
  } catch(e) {
    if (e.name === 'ValidationError') {
      setErrors(
        composeErrorData(
          payload,
          e.inner.filter((errorData) => errorData.path),
          force
        )
      )
      if (propogateError) {
        e.message = e.message.replace('errors occurred', t`errors occurred`)
        throw e
      }
    } else {
      throw e
    }
  } finally {
    if(setValidationPending) setValidationPending(false)
  }
}

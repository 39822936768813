import { useState } from 'react'

export default function useAssets(initialValue) {
  const [assets, setAssets] = useState(initialValue)

  const addAssets = (newAssets) => {
    setAssets([...assets, ...newAssets])
  }

  return [assets, addAssets]
}

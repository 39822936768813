import errorsToObject from './errorsToObject'

const performCustomValidation = (input, schema, setFormErrors, fullErrorPath = true) => {
  try {
    schema.validateSync(input, { abortEarly: false })
    setFormErrors({})

    return {}
  } catch (e) {
    const validationErrors = errorsToObject(
      e.inner.filter((errorData) => errorData.path),
      true,
      fullErrorPath
    )

    setFormErrors(validationErrors)

    return validationErrors
  }
}

export default performCustomValidation

import React from 'react'
import { string, func } from 'prop-types'
import { t } from 'ttag'

import DeleteButton from 'shared/components/ui/DeleteButton'

const ActionsCell = ({ onStakeholderDelete, stakeholderUuid }) => {
  const handleDelete = () => {
    if (confirm(t`Are you sure you want to remove stakeholder?`)) {
      onStakeholderDelete({ stakeholderUuid })
    }
  }

  return <DeleteButton onClick={ handleDelete } />
}

ActionsCell.propTypes = {
  onStakeholderDelete: func.isRequired,
  stakeholderUuid: string.isRequired
}

export default ActionsCell

import React from 'react'

import Card from 'shared/components/ui/Card'
import TwoColumnList from 'shared/components/ui/TwoColumnList'

const MarketerTeam = () => {
  return (
    <Card collapsible header="Marketer team">
      <TwoColumnList
        leftColumn={ [
          { label: 'CSM Team', value: 'Team 2' },
          { label: 'CSM team lead', value: 'Anders holm' },
          { label: 'CSM', value: 'Marius Josefsen' }
        ] }
        rightColumn={ [
          { label: 'Targeter', value: 'Thomas Amundsen' },
          { label: 'Creator', value: 'Marius Kås' },
          { label: 'Designer', value: 'Jakob Hermansen' },
          { label: 'Sales Developer', value: 'Jon Martinsen' },
        ] }
      />
    </Card>
  )
}

export default MarketerTeam

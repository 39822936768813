import updateBuildingMutation from './updateBuildingMutation'

const updateBuilding = (client, building, input, location) => {
  return client.mutate({
    mutation: updateBuildingMutation,
    variables: {
      buildingUuid: building.uuid,
      input:        input,
      location:     location
    }
  })
}

export default updateBuilding

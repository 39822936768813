import gql from 'graphql-tag'

export default gql`
  fragment DashboardDataset on DashboardDataset {
    currentValue
    dataset {
      date
      value
    }
  }
`
import propertyPickerGenerateToken from 'graphql/mutations/propertyPicker/generateTokenMutation'

const redirectToPropertyPicker = ({ projectUuid, accessToken, domain }) => {
  const params = new URLSearchParams()
  params.append('token', accessToken)
  params.append('redirect', `/projects/${projectUuid}/property-explorer/configurator`)
  const propertyPickerProjectUrl = `${domain}/auth?${params.toString()}`

  window.open(propertyPickerProjectUrl, '_blank')
}

export const propertyPickerRedirect = ({ projectUuid, client }) => {
  client
    .mutate({
      mutation: propertyPickerGenerateToken,
      variables: { projectUuid: projectUuid }
    })
    .then((response) => {
      const {
        data: {
          propertyPickerTokensGenerate: {
            token: { value: accessToken, domain }
          }
        }
      } = response

      redirectToPropertyPicker({ projectUuid, accessToken, domain })
    })
}

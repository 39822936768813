import { gql } from 'apollo-boost'

export default gql`
  query District {
    districts {
      uuid
      title
    }
  }
`

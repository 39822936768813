import React from 'react'
import axios from 'axios'
import { t } from 'ttag'
import { func, number } from 'prop-types'
import { saveAs } from 'file-saver'

import projectType from 'shared/types/projectType'

import { getToken } from '../../../helpers/auth'

const ExcelExportMenuItem = ({ project, stageTab, onClick }) => {
  const handleExportLeads = (e) => {
    e.preventDefault()

    let url = `/projects/${project.uuid}/export_sales_report`

    if (stageTab > 0) {
      const stage = project.completeStages[stageTab - 1]
      url = `${url}/stages/${stage.uuid}`
    }

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      responseType: 'blob'
    }).then((response) => {
      const file = new Blob(
        [response.data],
        { type: response.headers['content-type'] }
      )

      // attachment; filename="name.xlsx"; filename*=UTF-8''name.xlsx ->
      // name.xlsx
      const fileName = response.headers['content-disposition'].split('filename*=UTF-8\'\'')[1]

      if (file) saveAs(file, fileName)
    })

    onClick()
  }

  return (
    <a
      href=''
      target='_blank'
      onClick={ (e) => handleExportLeads(e) }
      rel='noopener noreferrer'
    >
      { t`Export to Excel` }
    </a>
  )
}

ExcelExportMenuItem.propTypes = {
  project: projectType.isRequired,
  stageTab: number.isRequired,
  onClick: func
}

ExcelExportMenuItem.defaultProps = {
  onClick: undefined
}

export default ExcelExportMenuItem

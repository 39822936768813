import React from 'react'
import { t } from 'ttag'
import { string } from 'prop-types'

import Button from 'shared/components/ui/Button'
import useIsOpened from 'shared/hooks/useIsOpened'

import NewUserForm from './NewUserForm'

const AddUserButton = ({ companyUuid }) => {
  const { isOpened, open, close } = useIsOpened(false)

  return (
    <>
      <Button onClick={ open }>
        { t`Add user` }
      </Button>

      <NewUserForm
        show={ isOpened }
        close={ close }
        companyUuid={ companyUuid }
      />
    </>
  )
}

AddUserButton.propTypes = {
  companyUuid: string.isRequired
}

export default AddUserButton

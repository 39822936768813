import React from 'react'
import classNames from 'classnames'
import { Form, Row, Col } from 'react-bootstrap'
import { t } from 'ttag'
import { object, func, array } from 'prop-types'
import { toast } from 'react-toastify'

import {
  todayDate, lessThanOneHourFromNow, sinceDate, agoNowDate
}                 from 'shared/helpers/date'
import Datepicker from 'shared/components/ui/Datepickers/Datepicker'

import FormField          from './FormField'
import GoalSelect         from './GoalSelect'
import FacebookPageSelect from './FacebookPageSelect'
import GoalNumberField    from './GoalNumberField'
import LocaleFormField    from './LocaleFormField'

const FieldSet = props => {
  const {
    facebookPages,
    values,
    errors,
    touched,
    handleChange
  } = props

  const handleTimeChange = ({ name, value }) => {
    if (lessThanOneHourFromNow(value)) {
      return (
        name === 'startDate'
          ? toast.error(t`Start time must be at least 1 hour from now`)
          : toast.error(t`End time must be at least 1 hour from now`)
      )
    }

    handleChange({ target: { name: name, value } })
  }

  return (
    <>
      {
        facebookPages.length > 1
          && <FormField
            label={ t`Facebook Page` }
            touched={ touched.facebookPage }
            errors={ errors.facebookPage }
          >
            <div data-test-id='goal-type-select-wrapper'>
              <FacebookPageSelect
                stageUuid={ values.stageUuid }
                facebookPageUuid={ values.facebookPageUuid }
                facebookPages={ facebookPages }
                isInvalid={ touched.facebookPageUuid && errors.facebookPageUuid }
                onChange={ handleChange }
              />
            </div>
          </FormField>
      }

      <FormField
        label={ t`Campaign name` }
        touched={ touched.name }
        errors={ errors.name }
      >
        <Form.Control
          name="name"
          isInvalid={ touched.name && errors.name }
          defaultValue={ values.name }
          data-test-id='name-input'
          onChange={ handleChange } />

      </FormField>

      <FormField
        label={ t`Start & end date` }
        touched={ touched.startDate || touched.endDate }
        errors={ errors.startDate || errors.endDate }
      >
        <Row>
          <Col sm="6">
            <div data-test-id='date-start-input-wrapper'>
              <Datepicker
                shouldCloseOnSelect
                disabledKeyboardNavigation={ false }
                className={ classNames({ 'is-invalid': touched.startDate && errors.startDate }) }
                minDate={ todayDate() }
                maxDate={ sinceDate(agoNowDate('1', 'day'), '1', 'year') }
                selected={ values.startDate }
                onChange={ value => handleTimeChange({ name: 'startDate', value }) } />
            </div>
          </Col>
          <Col sm="6">
            <div data-test-id='date-end-input-wrapper'>
              <Datepicker
                shouldCloseOnSelect
                disabledKeyboardNavigation={ false }
                minDate={ values.startDate && values.startDate }
                maxDate={ sinceDate(agoNowDate('1', 'day'), '1', 'year') }
                className={ classNames({ 'is-invalid': touched.startDate && errors.startDate }) }
                selected={ values.endDate }
                onChange={ value => handleTimeChange({ name: 'endDate', value }) } />
            </div>
          </Col>
        </Row>
      </FormField>

      <FormField
        label={ t`Goal type` }
        touched={ touched.goalType }
        errors={ errors.goalType }
      >
        <div data-test-id='goal-type-select-wrapper'>
          <GoalSelect
            goalType={ values.goalType }
            targetLqs={ values.targetLqs }
            isInvalid={ touched.goalType && errors.goalType }
            onChange={ handleChange }
          />
        </div>
      </FormField>

      <FormField
        label={ t`Goal number` }
        touched={ touched.goalNumber }
        errors={ errors.goalNumber }
      >
        <GoalNumberField
          defaultValue={ values.goalNumber }
          isInvalid={ touched.goalNumber && errors.goalNumber }
          data-test-id='goal-input'
          onChange={ handleChange }
        />
      </FormField>

      <FormField
        label={ t`Beneficiary` }
        touched={ touched.dsaBeneficiary }
        errors={ errors.dsaBeneficiary }
      >
        <Form.Control
          name="dsaBeneficiary"
          isInvalid={ touched.dsaBeneficiary && errors.dsaBeneficiary }
          defaultValue={ values.dsaBeneficiary }
          data-test-id='dsaBeneficiary-input'
          onChange={ handleChange } />
      </FormField>

      <FormField
        label={ t`Payor` }
        touched={ touched.dsaPayor }
        errors={ errors.dsaPayor }
      >
        <Form.Control
          name="dsaPayor"
          isInvalid={ touched.dsaPayor && errors.dsaPayor }
          defaultValue={ values.dsaPayor }
          data-test-id='dsaPayor-input'
          onChange={ handleChange } />
      </FormField>

      <LocaleFormField
        defaultValue={ [values.locale, values.countryCode].join('_') }
        values={ values }
        touched={ touched }
        errors={ errors }
        onChange={ handleChange }
      />
    </>
  )
}

FieldSet.propTypes = {
  errors: object.isRequired,
  facebookPages: array.isRequired,
  handleChange: func.isRequired,
  touched: object.isRequired,
  values: object.isRequired
}

export default FieldSet

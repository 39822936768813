import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { shape, string } from 'prop-types'

import { isUserAdmin } from 'shared/helpers/auth'

import FacebookAudience from '../FacebookAudience'
import GoogleAudience from '../GoogleAudience'

import Header from './Header'
import { AudienceContainer } from './elements'

const componentByType = {
  Facebook: FacebookAudience,
  Instagram: FacebookAudience,
  Portal: FacebookAudience,
  Gmp: GoogleAudience
}

const Audience = props => {
  const {
    className,
    audience,
    audience: {
      platformAudience: { __typename: platformAudienceType }
    }
  } = props
  const PlatformAudienceComponent = componentByType[platformAudienceType]

  const isPortal = platformAudienceType === 'Portal'
  const isCollapsible = isUserAdmin() || !isPortal
  const containerClass = isCollapsible ? 'audience-container' : 'audience-readonly-container'

  return (
    <div
      data-test-id='audience-container-wrapper'
      className={ classNames(className, containerClass) }
    >
      <AudienceContainer
        collapsed
        collapsible={ isCollapsible }
        header={ <Header audience={ audience } isCollapsible={ isCollapsible } { ...props } /> }
      >
        { isCollapsible && <PlatformAudienceComponent
          platformAudienceType={ platformAudienceType } { ...props }
        /> }
      </AudienceContainer>
    </div>
  )
}

Audience.propTypes = {
  audience: shape({
    platformAudience: shape({
      __typename: string.isRequired
    }).isRequired
  }).isRequired
}

export default styled(Audience)`
  &.audience-readonly-container {
    .card-body {
      padding: 0 !important;
    }
  }

`

import React from 'react'
import { Spinner } from 'react-bootstrap'
import { string } from 'prop-types'
import { isEmpty } from 'lodash'

const LoadingBlock = ({ comment }) => {
  return (
    <div className="loading-block text-center py-4">
      <Spinner animation="border" variant="secondary" />
      { !isEmpty(comment)
        && <div
          className="text-secondary"
          data-test-id="loading-comment">{comment}</div> }
    </div>
  )
}

LoadingBlock.propTypes = {
  comment: string
}

LoadingBlock.defaultProps = {
  comment: ''
}

export default LoadingBlock

import { gql } from 'apollo-boost'

import stageUnit from 'graphql/fragments/stageUnit'

export default gql`
  mutation createStageUnit(
    $stageUuid: ID!
    $attributes: UnitAttributes!
  ) {
    createStageUnit(
      stageUuid: $stageUuid
      attributes: $attributes
    ) {
      unit {
        ... StageUnit
      }
    }
  }
  ${stageUnit}
`

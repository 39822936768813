import React, { useState, useEffect } from 'react'
import { t } from 'ttag'
import { get } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { string, func, elementType, array } from 'prop-types'
import Select from 'react-select'

import usersQuery from 'graphql/queries/companies/users'
import { redColor } from 'shared/style/colors'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    minWidth: '13rem',
  }),
  control: (provided, { selectProps }) => ({
    ...condensedSelectStyles.control(provided),
    borderColor: selectProps.isInvalid ? redColor : provided.borderColor
  })
}

const UserSelect = ({
  value, companyUuid, stakeholderOptions, component: SelectComponent, ...props
}) => {
  const [options, setOptions] = useState([])
  const { data } = useQuery(
    usersQuery,
    { variables: { companyUuid }, skip: !companyUuid }
  )

  useEffect(() => {
    const users = get(data, 'companyUsers', [])
    setOptions(users.map(({ uuid: value, nameWithEmail: label }) => ({ label, value })))
  }, [data])

  useEffect(() => {
    if (stakeholderOptions.length === 0) return

    const stakeholder = stakeholderOptions[stakeholderOptions.length - 1]
    const match = options.find((option) => {
      return option.value === stakeholder.value
    })
    if (!match && options.length > 0) addNewStakeholderToOptions(stakeholder)
  }, [stakeholderOptions])

  const addNewStakeholderToOptions = (stakeholder) => {
    const newOptions = options
    newOptions.push(stakeholder)
    setOptions(newOptions)
  }

  const findUserValueInOptions = (array1, array2) => {
    if (!value) return

    const optionsMatch = array1.find((option) => option.value === value)
    if (optionsMatch) return optionsMatch

    const stakeholdersMatch = array2.find((option) => option.value === value)
    if (stakeholdersMatch) return stakeholdersMatch
  }

  return (
    <SelectComponent
      value={ findUserValueInOptions(options, stakeholderOptions) }
      options={ options }
      styles={ styles }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

UserSelect.propTypes = {
  companyUuid: string,
  component: elementType,
  onChange: func,
  stakeholderOptions: array,
  value: string
}

UserSelect.defaultProps = {
  companyUuid: undefined,
  onChange: () => {},
  stakeholderOptions: [],
  value: undefined,
  component: Select
}

export default UserSelect

import React from 'react'
import { object, string } from 'prop-types'
import styled from 'styled-components'
import JSONPretty from 'react-json-pretty'
import { isEmpty, omit } from 'lodash'

import { AdditionalInfoContainer } from './elements'

const AdditionalInfo = ({ className, additionalInfo, leadMessage }) => {
  const nonDisplayedInfo = omit(additionalInfo, [
    'terms',
    'campaign_uuid',
    'message',
    'contact_time',
    'contact_by_sms',
    'contact_by_mail',
    'contact_by_phone',
    'contact_other_time',
    'contact_during_the_day',
    'contact_during_the_evening'
  ])

  return (
    <AdditionalInfoContainer className={ className }>
      {leadMessage && (
        <div className='mb-2'>
          { leadMessage }
        </div>
      )}
      {!isEmpty(nonDisplayedInfo) && (
        <JSONPretty id="json-pretty" data={ nonDisplayedInfo } />
      )}
    </AdditionalInfoContainer>
  )
}

AdditionalInfo.propTypes = {
  additionalInfo: object,
  leadMessage: string
}

AdditionalInfo.defaultProps = {
  additionalInfo: {},
  leadMessage: null
}

export default styled(AdditionalInfo)`
  overflow-wrap: break-word;
`

import React from 'react'
import { t } from 'ttag'
import { func, string, array } from 'prop-types'

import Card          from 'shared/components/ui/Card'
import Table         from 'shared/components/Stakeholders/Table'
import NewItemButton from 'shared/components/ui/NewItemButton'

import stakeholderHandlers from './stakeholderHandlers'

const Stakeholders = ({ companyUuid, stakeholders, setStakeholders }) => {
  const {
    addStakeholder,
    removeStakeholder,
    updatePermission,
    updateResponsibility,
    updateSettings,
    updateUser
  } = stakeholderHandlers(stakeholders, setStakeholders)

  return (
    <Card
      header={ t`Stakeholders` }
      padded={ false }
      className="mt-2 pb-3"
    >
      <Table
        companyUuid={ companyUuid }
        stakeholders={ stakeholders }
        onPermissionUpdate={ updatePermission }
        onStakeholderDelete={ removeStakeholder }
        onResponsibilityUpdate={ updateResponsibility }
        onSettingsUpdate={ updateSettings }
        onUserUpdate={ updateUser }
      />
      <NewItemButton onClick={ addStakeholder } data-test-id='add-new-stakeholder-button'>
        { t`Add new stakeholder` }
      </NewItemButton>
    </Card>
  )
}

Stakeholders.propTypes = {
  setStakeholders: func.isRequired,
  stakeholders: array.isRequired,
  companyUuid: string,
}

Stakeholders.defaultProps = {
  companyUuid: undefined,
}

export default Stakeholders

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { arrayOf, func, shape, string, bool } from 'prop-types'

import Checkbox from 'shared/components/ui/Checkbox'

const PositionsGroup = ({ name, options, selected, onChange, isInvalid, dataTestId }) => {
  const isSelected = value => selected.find(el => el === value) !== undefined
  const allSelected = options.reduce((acc, { value }) => acc && isSelected(value), true)

  const groupToggleRef = React.createRef()

  useEffect(() => {
    groupToggleRef.current.indeterminate = selected.length > 0 && !allSelected
  }, [selected])

  const handleToggleAll = e => {
    if (e.target.checked) {
      onChange(options.map(el => el.value))
    } else {
      onChange([])
    }
  }

  const handleToggle = e => {
    const { target: { checked, value } } = e

    if (checked) {
      onChange([...selected, value])
    } else {
      onChange(selected.filter(el => el !== value))
    }
  }

  return (
    <Group className={ isInvalid ? 'is-invalid' : '' } data-test-id={ dataTestId }>
      <Checkbox
        checked={ allSelected }
        ref={ groupToggleRef }
        onChange={ handleToggleAll }
        label={ name } />

      {options.map(({ value, label }) => (
        <Checkbox
          key={ value }
          value={ value }
          checked={ isSelected(value) }
          onChange={ handleToggle }
          label={ label } />
      ))}
    </Group>
  )
}

const Group = styled.div`
  padding: 0.75rem 0
  margin: 0.75rem 0
  padding-left: 0.5rem

  &.is-invalid {
    padding: calc(0.75rem - 0.063rem) 0;
    margin: 0.75rem 0;
    padding-left: calc(0.5rem - 0.063rem);

    border-color: red;
    border-radius: 0.25rem
    border-style: solid
    border-width: 0.063rem
  }
`

PositionsGroup.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  options: arrayOf(shape({
    label: string.isRequired,
    value: string.isRequired
  })).isRequired,
  selected: arrayOf(string).isRequired,
  dataTestId: string,
  isInvalid: bool
}

PositionsGroup.defaultProps = {
  dataTestId: '',
  isInvalid: false
}

export default PositionsGroup

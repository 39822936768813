import gql from 'graphql-tag'

export default gql`
  query Countries(
    $locale: String!
  ) {
    countries(
      locale: $locale
    ) {
      name
      alpha2
      countryCode
    }
  }
`

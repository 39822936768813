import gql from 'graphql-tag'

export default gql`
  mutation addLeadActivity(
    $leadUuid: ID!
    $input: LeadActivityInput!
  ) {
    addLeadActivity(
      leadUuid: $leadUuid
      input: $input
    ) {
      activity {
        uuid
        createdAt
        text
        title
        recordedAt
        activityType
        involvedUser {
          uuid
          name
        }
        author {
          uuid
          name
        }
      }
    }
  }
`

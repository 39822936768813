import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'

const Paragraph = styled.p`
  color: #2e334a
  font-size: 1.1rem
  line-height: 1.75
  margin-top: 1.5rem
  margin-bottom: .75rem
`

const SubHeader = ({ children }) => <Paragraph>{ children }</Paragraph>

SubHeader.propTypes = {
  children: node
}

SubHeader.defaultProps = {
  children: ''
}

export default SubHeader

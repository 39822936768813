import React            from 'react'
import styled           from 'styled-components'
import { string, func } from 'prop-types'

import ThriftyInput from 'shared/components/ui/ThriftyInput'

const NumberInput = ({ paramName, onChange, value, className, uuid }) => {
  const handleInput = (paramValue) => {
    const attributes = {}
    attributes[paramName] = parseInt(paramValue)
    onChange({ uuid, attributes: attributes })
  }

  return (
    <div>
      <ThriftyInput
        className={ className }
        type='text'
        onUpdate={ handleInput }
        data-test-id={ `${paramName}-input` }
        value={ value }
      />
    </div>
  )
}

NumberInput.propTypes = {
  onChange: func.isRequired,
  paramName: string.isRequired,
  uuid: string.isRequired,
  value: string
}

NumberInput.defaultProps = {
  value: ''
}

export default styled(NumberInput)`
  display: inline-block
  box-shadow: none !important;
  height: 2.375rem
  font-size: .75rem !important
`

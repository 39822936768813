import { gql } from 'apollo-boost'

import asset from 'graphql/fragments/asset'

export default gql`
  query Product($uuid: ID!) {
    product(uuid: $uuid) {
      uuid
      name
      link
      promotableType
      createdAt
      owner
      primaryAgent
      secondaryAgents
      promotable {
        uuid
        propertyType
        assets {
          ... Asset
        }
        ... on Property {
          uuid
          description
          shortDescription
          facilities
          setupComplete
          price
          totalPrice
          currency
          location {
            address
            city
            country
            zipCode
            state
          }
          visitsHours {
            startAt,
            endAt
          }
        }
        ... on Project {
          uuid
          name
          buildingTypes
          buildingsCount
          unitsCount
          unitsSold
          percentageSold
          lowerPrice
          upperPrice
          lowerSizeM2
          upperSizeM2
          locations {
            address
            city
            country
            zipCode
            state
          }
        }
      }
      company {
        uuid
        name
      }
    }
  }
  ${asset}
`

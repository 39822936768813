import React  from 'react'
import { t }  from 'ttag'
import styled from 'styled-components'

import SidebarBase from 'shared/components/Layout/Sidebar'
import SidebarLink from 'shared/components/Layout/Sidebar/SidebarLink'
import LinksGroup  from 'shared/components/Layout/Sidebar/LinksGroup'
import {
  IconDashboard,
  // IconMailLightning,
  IconTwoTables,
  IconHomeHeart,
  IconPosition,
  // IconTools,
  // IconGroup
} from 'shared/components/ui/icons'
import getImageUrl  from 'shared/helpers/getImageUrl'
import usersImage   from 'shared/assets/images/users.png'
import analyseImage from 'shared/assets/images/analyse.png'

const Sidebar = (props) => {
  return (
    <SidebarBase { ...props }>
      <LinksGroup>
        <SidebarLink exact to="/admin">
          <IconDashboard /> { t`Analytics` }
        </SidebarLink>
        {/* Hidden for MVP */}
        {/*<SidebarLink>*/}
        {/*  <IconMailLightning /> { t`Reports` }*/}
        {/*</SidebarLink>*/}

        <SidebarLink to="/admin/projects">
          <IconTwoTables /> { t`Projects` }
        </SidebarLink>
        <SidebarLink to="/admin/sales_reports">
          <span className="custom-icon">
            <img
              src={ getImageUrl(analyseImage) }
              alt={ t`Analysis` }
            />
          </span> { t`Analysis` }
        </SidebarLink>
        <SidebarLink to="/admin/campaigns">
          <IconHomeHeart /> { t`Campaigns` }
        </SidebarLink>
        <SidebarLink to="/admin/leads">
          <IconPosition /> { t`Leads` }
        </SidebarLink>

        <SidebarLink to="/admin/users">
          <span className="custom-icon">
            <img
              src={ getImageUrl(usersImage) }
              alt={ t`Users` }
            />
          </span> { t`Users` }
        </SidebarLink>

        {/* Hidden for MVP */}
        {/*<SidebarLink>*/}
        {/*  <IconGroup /> { t`Organization` }*/}
        {/*</SidebarLink>*/}
      </LinksGroup>

      {/* Hidden for MVP */}
      {/*<LinksGroup>*/}
      {/*  <SidebarLink>*/}
      {/*    <IconTools /> { t`Settings` }*/}
      {/*  </SidebarLink>*/}
      {/*</LinksGroup>*/}
    </SidebarBase>
  )
}

export default styled(Sidebar)`
  .custom-icon {
    margin-right: .5rem;

    img {
      width: 1rem;
      height: 1rem;
    }
  }
`

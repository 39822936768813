import React  from 'react'
import { t }  from 'ttag'
import styled from 'styled-components'
import { number, array, object } from 'prop-types'

import StagesDetailsInfoBlock from './StagesDetailsInfoBlock'

const StagesDetailsInfo = ({ className, tabs, activeTab, salesDetails }) => {
  return (
    <div className={ className }>
      <StagesDetailsInfoBlock
        border
        index={ 0 }
        title={ tabs[activeTab].title }
        data={ salesDetails.total }
      />
      <StagesDetailsInfoBlock
        rooms
        index={ 1 }
        title={ t`Sold` }
        data={ salesDetails.sold }
      />
      <StagesDetailsInfoBlock
        rooms
        index={ 2 }
        title={ t`Reserved` }
        data={ salesDetails.reserved }
      />
      <StagesDetailsInfoBlock
        rooms
        index={ 3 }
        title={ t`Available` }
        data={ salesDetails.available }
      />
    </div>
  )
}

StagesDetailsInfo.propTypes = {
  activeTab: number.isRequired,
  salesDetails: object.isRequired,
  tabs: array.isRequired
}

export default styled(StagesDetailsInfo)`
  padding: 1.125rem 0;
  display: flex;
  flex-direction: row;
`

import React from 'react'
import { t } from 'ttag'
import { object } from 'prop-types'

import { localDateTime } from 'shared/helpers/date'

import Body from './Body'
import Section from './Section'

const Period = ({ campaign }) => {
  const period = `${ localDateTime(campaign.startDate) } - ${ localDateTime(campaign.endDate) }`

  return (
    <Section title={ t`Period` }>
      <Body
        title={ period }
        onCLick={ () => {} }
      />
    </Section>
  )
}

Period.propTypes = {
  campaign: object.isRequired
}

export default Period

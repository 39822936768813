import React  from 'react'
import { t }  from 'ttag'
import styled from 'styled-components'
import { OverlayTrigger } from 'react-bootstrap'

import Question from 'shared/assets/icons/svg/question.svg'

import OptimizationPopover from './OptimizationPopover'

const popover = (
  <OptimizationPopover>
    <OptimizationPopover.Title as="h3">
      { t`Channel Optimization` }
    </OptimizationPopover.Title>
    <OptimizationPopover.Content>
      { t`Turn on channel optimization for us
          to automatically allocate your budget
          the way we think it will best reach it’s goal.` }
    </OptimizationPopover.Content>
  </OptimizationPopover>
)

const OptimizationMessage = ({ className }) => {
  return (
    <div className={ className }>
      <OverlayTrigger placement="right" overlay={ popover }>
        <Question />
      </OverlayTrigger>
    </div>
  )
}

export default styled(OptimizationMessage)`
  display: inline-block
  
  svg {
    cursor: default
    height: 1.5rem
    width: 1.5rem
  }
`

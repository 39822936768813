import mediaValidationSchema from './mediaValidationSchema'

const buildImagesValidationPayload = (campaign) => {
  const assetAssignments = campaign.assetAssignments
  const assets = assetAssignments.map((assignment) => assignment.asset)
  const imageAssets = assets.filter((asset) => asset.mediaType === 'image')

  return imageAssets
}

export default ({ payload }) => {
  mediaValidationSchema.validateSync(
    buildImagesValidationPayload(payload),
    { abortEarly: false }
  )
}

import Profile                 from 'shared/components/pages/Profile'
import Company                 from 'shared/components/pages/Company'
import Dashboard               from 'shared/components/pages/Dashboard'
import AdminAfterOauth         from 'shared/components/pages/AdminAfterOauth'
import NotFound                from 'shared/components/pages/NotFound'
import New                     from 'shared/components/pages/New'
import Production              from 'shared/components/pages/Production'
import Leads                   from 'shared/components/pages/Leads'
import SalesReports            from 'shared/components/pages/SalesReports'
import Projects                from 'shared/components/pages/Projects'
import Project                 from 'shared/components/pages/Project'
import ProjectNewsEdit         from 'shared/components/pages/ProjectNewsEdit'
import ProjectPortalEdit       from 'shared/components/pages/ProjectPortalEdit'
import ProjectSalesReport      from 'shared/components/pages/ProjectSalesReport'
import NewProject              from 'shared/components/pages/NewProject'
import NewStageProjects        from 'shared/components/pages/NewStage/Projects'
import NewStageCreate          from 'shared/components/pages/NewStage/Create'
import NewStageForm            from 'shared/components/pages/NewStage/StageForm'
import NewBuildingProjects     from 'shared/components/pages/NewBuilding/Projects'
import NewBuildingCreate       from 'shared/components/pages/NewBuilding/Create'
import NewBuildingForm         from 'shared/components/pages/NewBuilding/BuildingForm'
import Campaign                from 'shared/components/pages/Campaign'
import CampaignEdit            from 'shared/components/pages/CampaignEdit'
import CampaignChangelogs      from 'shared/components/pages/CampaignChangelogs'
import Promotable              from 'shared/components/pages/Promotable'
import Product                 from 'shared/components/pages/Product'
import NewAutoCampaignProjects from 'shared/components/pages/NewAutoCampaign/Projects'
import NewAutoCampaignProperties
  from 'shared/components/pages/NewAutoCampaign/Properties'
import NewAutoCampaignStages   from 'shared/components/pages/NewAutoCampaign/Stages'
import NewAutoCampaignStagesMissingData
  from 'shared/components/pages/NewAutoCampaign/Stages/MissingData'
import NewCampaignForm         from 'shared/components/pages/NewAutoCampaign/CampaignForm'
import DuplicateCampaignForm   from 'shared/components/pages/NewAutoCampaign/DuplicateCampaignForm'
import NewPropertyCampaignForm from 'shared/components/pages/NewAutoCampaign/PropertyCampaignForm'
import LandingPageContent      from 'shared/components/pages/LandingPageContent'
import NewLead                 from 'shared/components/pages/NewLead'
import Lead                    from 'shared/components/pages/Lead'
import Users                   from 'shared/components/pages/Users'
import CampaignCreationInProgress from 'shared/components/pages/CampaignCreationInProgress'
import { withLayout }          from 'admin/components/Layout'

export default [
  { path: '/admin', exact: true, component: withLayout(Dashboard) },
  { path: '/admin_after_oauth', exact: true, component: withLayout(AdminAfterOauth) },
  { path: '/admin/products/:uuid', component: withLayout(Product) },
  { path: '/admin/products', component: withLayout(Promotable) },
  {
    path: '/admin/campaigns/new/stages/:uuid/missing_data',
    component: withLayout(NewAutoCampaignStagesMissingData)
  },
  {
    path: '/admin/campaigns/new/projects/:uuid/stages',
    component: withLayout(NewAutoCampaignStages)
  },
  { path: '/admin/campaigns/new/projects', component: withLayout(NewAutoCampaignProjects) },
  { path: '/admin/campaigns/new/properties', component: withLayout(NewAutoCampaignProperties) },
  { path: '/admin/campaigns/:uuid/edit', component: withLayout(CampaignEdit) },
  { path: '/admin/campaigns/:uuid/change_logs', component: withLayout(CampaignChangelogs) },
  {
    path: '/admin/campaigns/:campaignUuid/landing_pages/:uuid/edit',
    component: withLayout(LandingPageContent)
  },
  { path: '/admin/campaigns/:uuid', component: withLayout(Campaign) },
  { path: '/admin/campaigns', component: withLayout(Production) },
  { exact: true,
    path: '/admin/campaign_creation_in_progress',
    component: withLayout(CampaignCreationInProgress) },
  { path: '/admin/profile', component: withLayout(Profile) },
  { path: '/admin/companies/:uuid', exact: true, component: withLayout(Company) },
  { path: '/admin/new', component: withLayout(New) },
  { path: '/admin/stages/new/projects', component: withLayout(NewStageProjects) },
  { path: '/admin/stages/:uuid/new', component: withLayout(NewStageForm) },
  { path: '/admin/buildings/new/projects', component: withLayout(NewBuildingProjects) },
  { path: '/admin/buildings/:uuid/new', component: withLayout(NewBuildingForm) },
  { path: '/admin/projects/:uuid/news', component: withLayout(ProjectNewsEdit) },
  { path: '/admin/projects/:uuid/portal/edit', component: withLayout(ProjectPortalEdit) },
  { path: '/admin/projects/:uuid/stages/create', component: withLayout(NewStageCreate) },
  { path: '/admin/projects/:uuid/buildings/create', component: withLayout(NewBuildingCreate) },
  { path: '/admin/projects/new', component: withLayout(NewProject) },
  { path: '/admin/projects/:uuid', component: withLayout(Project) },
  { path: '/admin/projects', component: withLayout(Projects) },

  { path: '/admin/sales_reports', exact: true, component: withLayout(SalesReports) },
  { path: '/admin/sales_reports/projects/:uuid', component: withLayout(ProjectSalesReport) },

  { path: '/admin/stages/:uuid/campaigns/new', component: withLayout(NewCampaignForm) },
  {
    path: '/admin/stages/:uuid/campaigns/:campaignUuid/duplicate',
    component: withLayout(DuplicateCampaignForm)
  },
  { path: '/admin/properties/:uuid/campaigns/new', component: withLayout(NewPropertyCampaignForm) },
  { path: '/admin/leads', exact: true, component: withLayout(Leads) },
  { path: '/admin/leads/new', exact: true, component: withLayout(NewLead) },
  { path: '/admin/leads/:uuid', exact: true, component: withLayout(Lead) },
  { path: '/admin/users', exact: true, component: withLayout(Users) },
  { path: '/admin/*', component: withLayout(NotFound) },
]

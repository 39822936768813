import React from 'react'
import { t } from 'ttag'
import { shape, string, number } from 'prop-types'
import { round } from 'lodash'

import CampaignStats    from 'shared/CampaignStats'
import Card             from 'shared/components/ui/Card'
import StatsIndicator   from 'shared/components/StatsIndicator'
import LoadingCard      from 'shared/components/ui/LoadingCard'
import useSpecification from 'admin/hooks/useSpecification'
import useInsights      from 'admin/hooks/useInsights'
import { campaignCurrency, formatPrice } from 'shared/helpers/currency'

const Stats = ({ campaign }) => {
  const {
    durationInDays,
    daysSinceStart,
    budget,
  } = campaign

  const { loading: loadingInsights, insights } = useInsights(campaign.uuid)
  const { loading: loadingSpecification, specification } = useSpecification(campaign.uuid)

  if (loadingSpecification || loadingInsights) {
    return (
      <LoadingCard />
    )
  }

  const campaignStats = new CampaignStats({ ...campaign, insights, specification })

  if (campaign.promotableType === 'Property') {
    let visits = insights.totalClicks
    let postfix = ''
    if (visits >= 1000) {
      visits = round(visits/1000, 1)
      postfix = 'K'
    }
    return (
      <Card collapsible className="mb-2" header={ t`Main stats` } dataTestId="main-stats-panel">
        <div className="d-flex justify-content-around">
          <StatsIndicator
            size="medium"
            percentage={ campaignStats.timePercentage }
            name={ t`Time spent` }
            dataTestId="time-spent-stats-panel"
          />
  
          <StatsIndicator
            size="medium"
            percentage={ campaignStats.spentPercentage }
            name={ t`Budget spent` }
            dataTestId="budget-spent-stats-panel"
          />
  
          <StatsIndicator
            size="medium"
            percentage={ visits }
            name={ t`Visits` }
            postfix={ postfix }
            dataTestId="campaign-goal-stats-panel"
          />
        </div>
      </Card>
    )
  } else {
    const goalNumber = specification.goals.number
    const totalSpent = insights.totalSpent
    const currency = campaignCurrency(campaign)
    const daysPast = Math.min(daysSinceStart, durationInDays)
    return (
      <Card collapsible className="mb-2" header={ t`Main stats` } dataTestId="main-stats-panel">
        <div className="d-flex justify-content-around">
          <StatsIndicator
            size="medium"
            percentage={ campaignStats.timePercentage }
            name={ t`Time spent` }
            value={ `${daysPast.toFixed()}d / ${durationInDays.toFixed()}d` }
            dataTestId="time-spent-stats-panel"
          />
  
          <StatsIndicator
            size="medium"
            percentage={ campaignStats.spentPercentage }
            name={ t`Budget spent` }
            value={ `${formatPrice(totalSpent)} / ${formatPrice(budget)} ${currency}` }
            dataTestId="budget-spent-stats-panel"
          />
  
          <StatsIndicator
            size="medium"
            percentage={ campaignStats.goalPercentage }
            name={ `${campaignStats.goalTypePresentation} ${t`vs goal`}` }
            value={ `${campaignStats.actualGoalNumber} / ${goalNumber} ` }
            dataTestId="campaign-goal-stats-panel"
          />
        </div>
      </Card>
    )
  }
}

Stats.propTypes = {
  campaign: shape({
    budget: string,
    durationInDays: number.isRequired,
    daysSinceStart: number.isRequired,
  }).isRequired
}

export default Stats

import React  from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import ReactDatepicker from 'react-datepicker'

import { tableBorderValue } from 'shared/style/placeholders'
import { buttonTextColor, iconsGreyColor } from 'shared/style/colors'
import arrow from '!file-loader!shared/assets/icons/svg/new/arrow.svg'

import { containerPadding } from './Container'

const StyledDatepicker = ({ className, onChange, ...props }) => {
  const saveValidDate = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      onChange(date)
    }
  }

  return (
    <ReactDatepicker
      calendarClassName={ className }
      onChange={ saveValidDate }
      { ...props }
    />
  )
}

StyledDatepicker.propTypes = {
  onChange: func.isRequired
}

export default styled(StyledDatepicker)`
  &.react-datepicker--time-only {
    display: flex;
    overflow: auto;
    width: 100%;
    justify-content: center;
    padding-top: .5rem;
  }

  .react-datepicker__current-month {
    color: #697386;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .react-datepicker__navigation {
    border: none;
    top: 1.7rem;
    width: .35rem;

    &:focus {
      outline-width: 2px;
    }

    &--previous {
      background-image: url(${arrow});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      transform: rotate(180deg);
      left: 24%
    }

    &--next {
      background-image: url(${arrow});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      right: 24%
    }
  }

  .react-datepicker__day {
    border-radius: 0;
    font-size: 0.6875rem;
    line-height: 1.75rem;
    margin: 0;
    width: 1.75rem;

    &-names {
      margin-top: .25rem;
    }

    &-name {
      color: #8d8d8f;
      font-size: 0.4375rem;
      margin: 0;
    }

    &--disabled {
      color: #8d8d8f;
    }

    &--selected {
      background-color: ${iconsGreyColor};
      color: #FFF;
    }
  }

  .react-datepicker__time-container {
    .react-datepicker__time {
      &-box {
        border-radius: 0;
      }
    }
  }

  .react-datepicker__time-list-item {
    &--selected {
      background-color: ${iconsGreyColor} !important;
      color: #FFF !important;
    }
  }

  .react-datepicker__header {
    background: #FFF;
    border: none;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__input-time-container {
     align-items: center;
     border-top: ${tableBorderValue};
     border-bottom: ${tableBorderValue};
     display: flex;
     justify-content: space-between;
     margin: 1.5rem -${containerPadding} 0;
     padding: .75rem calc(${containerPadding} + .9rem);
     width: calc(100% + 2 * ${containerPadding})
  }

  .react-datepicker-time__caption {
    color: ${buttonTextColor};
    font-weight: 600;
    font-size: 0.875rem;
  }

  input.react-datepicker-time__input {
    border: ${tableBorderValue};
    border-radius: .25rem;
    color: ${buttonTextColor};
    padding: 0.175rem 0.35rem;
    width: initial;

    &:focus {
      outline-width: 2px;
    }
  }
`

import { gql } from 'apollo-boost'

export default gql`
  query Companies {
    companies {
      uuid
      propertiesEnabled
    }
  }
`

import React from 'react'
import { t } from 'ttag'
import { useApolloClient } from 'react-apollo'

import agentsQuery  from 'graphql/queries/projects/agents'
import addAgentMutation from 'graphql/mutations/projects/agents/create'
import ResourceQuery from 'shared/components/ResourceQuery'
import Button from 'shared/components/ui/Button'
import projectType from 'shared/types/projectType'

import EditAgent from './editForm'

const Agents = ({ project }) => {
  const client = useApolloClient()
  const handleCreate = () => {
    client.mutate({
      mutation: addAgentMutation,
      variables: { projectUuid: project.uuid },
      refetchQueries: [
        {
          query: agentsQuery,
          variables: { projectUuid: project.uuid }
        }
      ]
    })
  }

  const variables = { projectUuid: project.uuid }
  return (
    <ResourceQuery query={ agentsQuery } variables={ variables } fetchPolicy="network-only">
      {agents => (
        <>
          {agents.map((agent, index) => (
            <EditAgent key={ agent.uuid }
              index={ index + 1 }
              agent={ agent }
              project={ project } />
          ))}
          {(agents.length < 5)
            && <Button className="mb-2" type="button" onClick={ handleCreate }>
              {agents.length < 1 ? t`Add agent`: t`Add another agent`}
            </Button>
          }
        </>
      )}
    </ResourceQuery>
  )
}

Agents.propTypes = {
  project: projectType.isRequired
}

export default Agents

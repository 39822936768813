import handlePayloadValidation from 'shared/components/ValidatableTabs/handlePayloadValidation'

import stakeholdersValidationSchema from './stakeholdersValidationSchema'
import stageValidationSchema from './stageValidationSchema'
import unitsValidationSchema from './unitsValidationSchema'
import salesArgumentsValidationSchema from './salesArgumentsValidationSchema'
import stageWithSnapshotsValidationSchema from './stageWithSnapshotsValidationSchema'
import unitsWithSnapshotsValidationSchema from './unitsWithSnapshotsValidationSchema'

const validateStakeholders = ({ payload, updated } = {}) => {
  let stakeholdersToValidate = payload
  if (updated) {
    stakeholdersToValidate = payload.map((s) => (
      s.uuid === updated.uuid ? updated : s
    ))
  }
  stakeholdersValidationSchema.validateSync(stakeholdersToValidate, { abortEarly: false })
}

const validateStageDetails = ({ payload, updated, context } = {}) => {
  const isSnapshotEnabled = context.enabledFeatures.includes('snapshot')
  let stageToValidate = payload
  if (isSnapshotEnabled) {
    stageToValidate = { ...payload, stakeholders: context.product.stakeholders }
  }

  if (updated) {
    stageToValidate = updated
  }

  const schema = isSnapshotEnabled ? stageWithSnapshotsValidationSchema : stageValidationSchema

  schema.validateSync(stageToValidate, { abortEarly: false })
}

const validateUnits = ({ payload, updated, context } = {}) => {
  let unitsToValidate = payload
  if (updated) {
    unitsToValidate = payload.map((s) => (
      s.uuid === updated.uuid ? updated : s
    ))
  }
  const schema = context.isSnapshotEnabled
    ? unitsWithSnapshotsValidationSchema : unitsValidationSchema

  schema.validateSync(unitsToValidate, { abortEarly: false, context })
}

const validateSalesArguments = ({ payload, updated } = {}) => {
  let salesArgumentsToValidate = payload
  if (updated) {
    salesArgumentsToValidate = payload.map((s) => (
      s.uuid === updated.uuid ? updated : s
    ))
  }
  salesArgumentsValidationSchema.validateSync(salesArgumentsToValidate, { abortEarly: false })
}

const validateMedia = () => {}

const fetchValidationData = ({ product, validatorNumber }) => {
  const isSnapshotEnabled = product.company.enabledFeatures.includes('snapshot')
  const validationArray = [
    [
      validateStageDetails,
      product.promotable,
      {
        enabledFeatures: product.company.enabledFeatures,
        product: product
      }
    ],
    [validateUnits, product.promotable.units, {
      stage: product.promotable,
      isSnapshotEnabled
    }],
    [validateSalesArguments, product.promotable.salesArguments]
  ]

  if (!product.company.enabledFeatures.includes('snapshot')) {
    validationArray.unshift([validateStakeholders, product.stakeholders])
    validationArray.push([validateMedia, []])
  }

  return validationArray[validatorNumber]
}

const buildValidator = ({ product, setErrors, validatorNumber }) => {
  const [validatePayload, payload, context] = fetchValidationData({ product, validatorNumber })

  return ({ propogateError, updated, force } = {}) => handlePayloadValidation({
    validatePayload,
    payload,
    context,
    propogateError,
    setErrors,
    updated,
    force
  })
}

export default buildValidator

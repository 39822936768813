import React                      from 'react'
import { t }                      from 'ttag'
import styled                     from 'styled-components'
import { object, string, number } from 'prop-types'

import { formatNumberWithLocale } from 'shared/helpers/number'

const StagesExtraUnitsDetailsInfoBlock = ({ className, title, data, index }) => {
  return (
    <div
      className={ className }
      data-test-id={ `extra-units-details-${index}` }
    >
      <div className='title'>{ title }</div>

      <div className='graph'>
        <div className='graph-total-data'>
          <p className='graph-total-data-amount'>{ data.amount }</p>
          <p className='graph-total-data-value'>
            { `${data.percent}%` }
          </p>
        </div>
      </div>

      <div className='bottom'>
        <p>{ t`Value` }</p>
        <p>
          <span>NOK</span>
          { formatNumberWithLocale(data.value) }
        </p>
      </div>
    </div>
  )
}

StagesExtraUnitsDetailsInfoBlock.propTypes = {
  data:  object.isRequired,
  index: number.isRequired,
  title: string.isRequired
}

export default styled(StagesExtraUnitsDetailsInfoBlock)`
  width: 25%;
  padding: 0 1.125rem;

  .title {
    text-align: center;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem;
    color: #4a4a4a;
    margin-bottom: 1.25rem;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 60px;
  }

  .graph {
    position: relative;
    width: 8.1875rem;
    height: 8.1875rem;
    margin: 0 auto 1rem;

    .graph-total-data {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .graph-total-data-amount {
        font-size: 2.25rem;
        line-height: 2.25rem;
        color: #4a4a4a;
        font-weight: 600;
        margin: 0;
      }

      .graph-total-data-value {
        font-size: .875rem;
        font-weight: 600;
        line-height: 1.75rem;
        color: #4a4a4a;
        margin: 0;
      }
    }
  }

  .bottom {
    p {
      margin: 0 0 .3125rem;
      text-align: center;

      &:nth-of-type(1) {
        font-size: .625rem;
        font-weight: 400;
        color: rgba(74, 74, 74, 0.65);
      }

      &:nth-of-type(2) {
        font-size: 1.1875rem;
        line-height: 1.875rem;
        font-weight: 500;
        color: #4a4a4a;

        span {
          font-size: 1rem;
          margin-right: .25rem;
          color: rgba(74, 74, 74, 0.65);
        }
      }
    }
  }
`

import React  from 'react'
import styled from 'styled-components'
import { array, shape, string } from 'prop-types'

import AdPreview  from './AdPreview'
import PreviewAll from './PreviewAll'

const Preview = ({ className, uniqueAds, previewBanner, ...props }) => {
  return (
    <div className={ className }>
      <AdPreview { ...props } banners={ [previewBanner] } />
      <PreviewAll { ...props } ads={ uniqueAds } />
    </div>
  )
}

Preview.propTypes = {
  previewBanner: shape({
    uuid: string
  }),
  uniqueAds: array
}

Preview.defaultProps = {
  previewBanner: null,
  uniqueAds: []
}

export default styled(Preview)``

import { buildFragmentParams } from 'shared/helpers/graphql'
import removeLandingPageMutation from 'graphql/mutations/campaign/removeLandingPage'
import campaignLandingPagesFragment from 'graphql/fragments/campaignLandingPages'

import refreshCreativeSetsInCache from './refreshCreativeSetsInCache'

const removeLandingPage = (client, campaign, uuid) => {
  client.mutate({
    mutation: removeLandingPageMutation,
    variables: { uuid: uuid },
    update: cache => {
      const fragmentParams = buildFragmentParams(campaign, campaignLandingPagesFragment)
      const fragmentData = cache.readFragment(fragmentParams)
      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          landingPages: fragmentData.landingPages.filter(el => el.uuid !== uuid)
        }
      })

      refreshCreativeSetsInCache(cache, campaign.uuid, uuid)
    }
  })
}

export default removeLandingPage

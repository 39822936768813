import React, { useState, useCallback } from 'react'
import { t } from 'ttag'
import PropTypes from 'prop-types'
import { useApolloClient } from 'react-apollo'

import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'
import ActiveStatus from 'shared/components/Snapshots/ActiveStatus'

import updateProject from './updateProject'

const ProjectStatus = ({ project, className }) => {
  const saleStates = [
    {
      key: 'under_development',
      title: t`Under development`
    },
    {
      key: 'coming_for_sale',
      title: t`Coming for sale`
    },
    {
      key: 'for_sale',
      title: t`For sale`
    }
  ]

  const client = useApolloClient()
  const [selectedRadio, setSelectedRadio] = useState(project.saleState)

  const updateProjectSaleState = useCallback((value) => {
    setSelectedRadio(value)
    updateProject(client, project, { saleState: value, saleStateDraft: value })
  }, [selectedRadio])

  return (
    <EmbeddedCardSection
      titleText={ t`Active status` }
      className={ className }>
      <ActiveStatus
        title={ t`Active project status` }
        saleState={ project.saleState }
        updateSaleState={ updateProjectSaleState }
        saleStates={ saleStates }
        radioButtonName='projectSaleState'
        recordUuid={ project.uuid }
        data-test-id='active-status'
      />
    </EmbeddedCardSection>
  )
}

ProjectStatus.propTypes = {
  project: PropTypes.object.isRequired
}

export default ProjectStatus

import React from 'react'
import { t } from 'ttag'
import { useQuery }    from '@apollo/react-hooks'
import { object }      from 'prop-types'

import dashboardAnalyticsAverageCplQuery from 'graphql/queries/dashboardAnalytics/averageCpl'
import LoadingBlock                      from 'shared/components/ui/LoadingBlock'
import { formatPrice }                   from 'shared/helpers/currency'

import Card                from './Card'
import Chart               from './LinearChart'
import formatCurrentValues from './formatCurrentValues'

const CplChart = ({ filter }) => {
  const {
    data: {
      dashboardAnalyticsAverageCpl: {
        averageCpl = {},
        currency
      } = {}
    } = {},
    loading
  } = useQuery(dashboardAnalyticsAverageCplQuery, {
    variables: { filter }
  })

  if (loading) {
    return (
      <Card header={ t`Cost per lead (CPL)` }>
        <LoadingBlock />
      </Card>
    )
  }

  const { averageCplValue } = formatCurrentValues({ averageCpl, currency })

  return (
    <Chart
      name={ t`Cost per lead (CPL)` }
      comment={ t`Average amount spent on ads per lead` }
      dataTestId='cpl-chart'
      dataset={ averageCpl.dataset }
      currentValue={ averageCplValue }
      tooltipFormatter={ (value) => formatPrice(value, currency) }
    />
  )
}

CplChart.propTypes = {
  filter: object
}

CplChart.defaultProps = {
  filter: {}
}

export default CplChart

import gql from 'graphql-tag'

export default gql`
  mutation createTargetingItem($name: String!, $subtype: String!, $externalId: String!) {
    createTargetingItem(name: $name, subtype: $subtype, externalId: $externalId) {
      targetingItem {
        uuid
        name
        subtype
        externalId
      }
    }
  }
`

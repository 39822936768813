import { useApolloClient } from '@apollo/react-hooks'

import redirectTo from 'shared/helpers/redirectTo'
import createMutation from 'graphql/mutations/projects/stages/create'

export default ({ projectUuid }) => {
  const client = useApolloClient()

  client.mutate({
    mutation: createMutation,
    variables: {
      projectUuid: projectUuid
    },
    update: (_cache, { data: { createStage: { stage } } }) => {
      redirectTo(`/stages/${stage.uuid}/new`)
    }
  })

  return null
}

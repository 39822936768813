import React, { useState, useCallback }   from 'react'
import { t }                              from 'ttag'
import styled                             from 'styled-components'
import { arrayOf, shape, string, object, array } from 'prop-types'
import HTML5Backend                       from 'react-dnd-html5-backend'
import { DndProvider }                    from 'react-dnd'
import { cloneDeep }                      from 'lodash'

import AllMedia         from 'shared/components/Media/AllMedia'
import AssetAssignments from 'shared/components/Media/AssetAssignments'
import Modal            from 'shared/components/ui/Modal'
import MoreMedia        from 'shared/assets/icons/svg/more-media.svg'
import attachFileToCampaign
  from 'shared/components/CampaignInfo/Media/attachFileToCampaign'
import createCreateAssetAssignment
  from 'shared/components/CampaignInfo/Media/createCreateAssetAssignment'
import createRemoveAssetAssignment
  from 'shared/components/CampaignInfo/Media/createRemoveAssetAssignment'

import useUpdateCreative from './useUpdateCreative'

const SelectAds = ({ campaign, className, creativeSet, ...props }) => {
  const [show, setShow] = useState(false)

  const handleClose = useCallback(() => setShow(false), [])
  const handleShow  = useCallback(() => setShow(true), [])

  const attachFileToAssetable = useCallback(({ client, blobId, mediaType }) => {
    return attachFileToCampaign({ client, blobId, mediaType, campaignUuid: campaign.uuid })
  }, [campaign.uuid])

  const mediaTypesShown = (() => {
    switch(creativeSet.type) {
    case 'gmp':
      return ['image', 'video']
    case 'predefined':
      return ['banner', 'video']
    default:
      return ['image', 'banner', 'video']
    }
  })()

  const creativeAssignmets = creativeSet.creatives.map((creative) => {
    const assignment = creative.assetAssignment
    const extras = { uuid: creative.uuid, enabled: creative.enabled }
    assignment.asset = Object.assign({}, assignment.asset, extras)
    return assignment
  })

  const selectAssetAssignment = useUpdateCreative()

  const availableAssets = campaign.product.promotable.assetAssignments
    .map((assignment) => {
      const asset = cloneDeep(assignment.asset)
      asset.assignmentUuid = assignment.uuid
      return asset
    })

  return (
    <>
      <a href="#" onClick={ handleShow } className={ className } data-test-id="select-media">
        <MoreMedia />
        <span className="select-ads-title">
          { t`select media` }
        </span>
      </a>

      <Modal centered size="xl" show={ show } onHide={ handleClose }>
        <Modal.Header closeButton>
          <Header>{ t`Enable/disable media` }</Header>
        </Modal.Header>
        <ModalBody>
          <DndProvider backend={ HTML5Backend }>
            <AssetAssignments
              creativeAssignments={ creativeAssignmets }
              mediaTypes={ mediaTypesShown }
              assetable={ campaign }
              attachFileToAssetable={ attachFileToAssetable }
              createRemoveAssetAssignment={ createRemoveAssetAssignment }
              createCreateAssetAssignment={ createCreateAssetAssignment }
              selectAssetAssignment={ selectAssetAssignment }
              channelType={ creativeSet.channel.type }
              enabledCreativesLimit={ creativeSet.enabledCreativesLimit }
              { ...props }
            />

            { availableAssets.length > 0 && (
              <AllMedia
                assets={ availableAssets }
                sourceTitle={ campaign.product.promotableType }
              />
            ) }
          </DndProvider>
        </ModalBody>
      </Modal>
    </>
  )
}

SelectAds.propTypes = {
  campaign: object.isRequired,
  creativeSet: shape({
    uuid: string.isRequired,
    creativeSetAudiences: arrayOf(shape({
      ads: arrayOf(shape({
        asset: shape({
          thumbnail: string
        }).isRequired
      })).isRequired
    })).isRequired
  }).isRequired,
  enabledAssets: array.isRequired
}

const Header = styled.h3`
  margin: 0;
  font-size: 1.25rem;
`

const ModalBody = styled(Modal.Body)`
  padding: 1em 1.45em;
`

export default styled(SelectAds)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .select-ads-title {
    font-size: 0.625rem;
    font-weight: 500;
    color: #8898aa;
    margin-top: .25rem
  }
`

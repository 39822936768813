import React from 'react'
import { bool } from 'prop-types'
import { Badge } from 'react-bootstrap'

const SetupComplete = ({ complete }) => {
  return (
    complete
      ? <Badge variant="primary">Setup complete</Badge>
      : <Badge variant="danger">Setup incomplete</Badge>
  )
}

SetupComplete.propTypes = {
  complete: bool.isRequired
}

export default SetupComplete

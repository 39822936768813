import React from 'react'
import { components } from 'react-select'
import { string, shape } from 'prop-types'

const Option = (props) => {
  return (
    <components.Option { ...props }>
      <div
        className="image-select--option"
        style={{ background: props.selectProps.selectedColor }}
      >
        <img src={ props.data.src } alt={ props.data.label } />
        <span>{ props.data.label }</span>
      </div>
    </components.Option>
  )
}

Option.propTypes = {
  data: shape({
    src: string,
    label: string
  }).isRequired,
  selectProps: shape({
    selectedColor: string
  }).isRequired
}

export default Option

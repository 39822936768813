import React             from 'react'
import { shape, string } from 'prop-types'

import Card        from 'shared/components/ui/Card'
import ProfileForm from 'shared/components/ProfileForm'

import Header from './Header'

const UserInfo = ({ user }) => {
  return (
    <Card header={ <Header /> }>
      <ProfileForm user={ user }/>
    </Card>
  )
}

UserInfo.propTypes = {
  user: shape({
    uuid: string.isRequired
  }).isRequired
}

export default UserInfo

import { buildFragmentParams } from 'shared/helpers/graphql'
import stageUnitsList from 'graphql/fragments/stageUnitsList'
import deleteUnitMutation from 'graphql/mutations/projects/stages/units/deleteUnitMutation'

const deleteUnit = (client, stage, uuid) => {
  client.mutate({
    mutation: deleteUnitMutation,
    variables: { uuid },
    update: (cache, { data: { deleteStageUnit: { unit } } }) => {
      const fragmentParams = buildFragmentParams(stage, stageUnitsList)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          units: fragmentData.units.filter(({ uuid }) => uuid !== unit.uuid)
        }
      })
    }
  })
}

export default deleteUnit

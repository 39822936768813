import { gql } from 'apollo-boost'

export default gql`
  mutation createStage(
    $projectUuid: ID!
  ) {
    createStage(
      projectUuid: $projectUuid
    ) {
      stage {
        uuid
      }
    }
  }
`

import React             from 'react'
import { string, shape } from 'prop-types'
import { t }             from 'ttag'
import { useMutation }   from 'react-apollo'

import Button                       from 'shared/components/ui/Button'
import removeTargetAudienceMutation from 'graphql/mutations/campaign/targeting/removeTargetAudience'
import { buildFragmentParams }      from 'shared/helpers/graphql'
import campaignFragment             from 'graphql/fragments/campaign'
import useCurrentCampaignUuid       from 'shared/hooks/useCurrentCampaignUuid'

const RemoveAudienceButton = ({ audience }) => {
  const campaignUuid = useCurrentCampaignUuid()

  const [runRemoveTargetAudienceMutation] = useMutation(
    removeTargetAudienceMutation,
    {
      variables: { audienceUuid: audience.uuid },
      update: (cache, { data: { removeTargetAudience } }) => {
        if (!removeTargetAudience) {
          return
        }

        const object         = { __typename: 'Campaign', uuid: campaignUuid }
        const fragmentParams = buildFragmentParams(object, campaignFragment)
        const fragmentData   = cache.readFragment(fragmentParams)

        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            channels: fragmentData.channels.map((channel) => {
              return {
                ...channel,
                audiences: channel.audiences.map((element) => {
                  if (element.uuid === audience.uuid) element.deleted = true
                  return element
                })
              }
            })
          }
        })
      }
    }
  )

  const removeTargetAudience = () => {
    let confirmText = t`Are you sure you want to remove audience`
    if (audience.name) {
      confirmText += ` "${audience.name}"`
    }
    confirmText += '?'
    if (confirm(confirmText)) {
      runRemoveTargetAudienceMutation()
    }
  }

  return (
    <Button
      onClick={ removeTargetAudience }
      data-test-id='delete-audience-button'
      className='delete-button'
    >
      { t`Delete audience` }
    </Button>
  )
}

RemoveAudienceButton.propTypes = {
  audience: shape({
    uuid: string,
    name: string,
    __typename: string
  }).isRequired
}

export default RemoveAudienceButton

import React from 'react'
import styled from 'styled-components'
import { array, func, number, string, shape } from 'prop-types'
import { t } from 'ttag'

import DeleteButton from 'shared/components/ui/DeleteButton'
import { combineErrors } from 'shared/helpers/inputValidation'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import UnitsSelect from 'shared/components/UnitsSelect'

import ArgumentApplicationSelect from './ArgumentApplicationSelect'

const Item = ({ className, idx, stage, argument, handleUpdate, handleDelete, errors }) => {
  const { text, appliesTo, units } = argument
  const unitUuids = units.map(unit => unit.uuid)

  const handleUpdateText = text => handleUpdate(argument, { text })

  const handleAppliesToChange = ({ value }) => {
    const input = { appliesTo: value }

    if (value !== 'specific') input.unitUuids = []

    handleUpdate(argument, input)
  }

  const handleUnitsChange = unitUuids => handleUpdate(argument, { unitUuids })

  const handleDeleteClick = () => {
    if (confirm(t`Are you sure you want to remove sales argument?`)) {
      handleDelete(argument)
    }
  }

  const errorsOnText = combineErrors({ errors, uuid: argument.uuid, field: 'text' })

  return (
    <li className={ className }>
      <div className="item-index">{ idx }</div>

      <InputWrapper error={ errorsOnText } data-test-id='sales-argument-input-wrapper'>
        <ThriftyInput
          className="form-control-sm"
          value={ text }
          onUpdate={ handleUpdateText }
        />
      </InputWrapper>

      <ArgumentApplicationSelect
        value={ appliesTo }
        onChange={ handleAppliesToChange }/>

      <UnitsSelect
        stageUuid={ stage.uuid }
        value={ unitUuids }
        isDisabled={ appliesTo !== 'specific' }
        onChange={ handleUnitsChange } />

      <DeleteButton onClick={ handleDeleteClick } />
    </li>
  )
}

Item.propTypes = {
  argument: shape({
    uuid: string.isRequired,
    text: string,
    appliesTo: string.isRequired,
    units: array.isRequired
  }).isRequired,
  className: string.isRequired,
  handleDelete: func.isRequired,
  handleUpdate: func.isRequired,
  idx: number.isRequired,
  stage: shape({
    uuid: string.isRequired
  }).isRequired,
  errors: array
}

Item.defaultProps = {
  errors: []
}

export default styled(Item)`
  display: flex;
  padding: 0.325rem 0;
  align-items: center;

  > * {
    margin-right: 0.5rem;
  }

  .item-index {
    font-size: 0.875rem;
    margin-left: .5rem;
    line-height: 2rem;
    text-align: center;
    width: 2rem;
  }

  .form-control {
    box-shadow: none;
    max-width: 37rem;
    font-size: 0.75rem;
    height: 2rem;
  }

  > button {
    margin-left: auto;
  }

  .input-wrapper {
    width: 100%
  }

  .input-wrapper.with-error {
    position: relative
    top: 0.73rem
  }
`

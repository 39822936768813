import React from 'react'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

const Notifications = () => {
  return (
    <ToastContainer
      hideProgressBar
      draggable={ false }
      bodyClassName="p-2"
      style={{ width: 330 }}
    />
  )
}

export default Notifications

import React from 'react'
import styled, { css } from 'styled-components'
import { Form, Spinner } from 'react-bootstrap'
import { Field } from 'formik'
import { NavLink } from 'react-router-dom'
import { func, shape, bool, string } from 'prop-types'
import { t } from 'ttag'

import Button from 'shared/components/ui/Button'
import { isIE } from 'shared/helpers/browser'

const isAdminLogin = document.location.pathname.match(/^\/admin/)

const submitGoogleOmniauthForm = (event) => {
  event.preventDefault()
  document.getElementById('google-login-omniauth-form').submit()
}

const GoogleLoginLink = () => (
  <>
    <a href="#" id="google-login" onClick={ submitGoogleOmniauthForm }>Sign-in using Google</a>
    <form id="google-login-omniauth-form" action="/users/auth/google" method='POST' />
  </>
)

const FormView = ({ handleSubmit, className, touched, errors, isRegularUser, isSubmitting }) => (
  <Form noValidate onSubmit={ handleSubmit } className={ className } id="marketer-login">
    <h1>{t`Welcome to M2`}</h1>

    <Form.Label htmlFor="email">{t`Email address`}</Form.Label>
    <Field
      as={ Form.Control }
      name="email"
      id="email"
      type="email"
      isInvalid={ touched.email && errors.email }
    />
    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>

    <Form.Label htmlFor="password">{t`Password`}</Form.Label>
    <Field
      as={ Form.Control }
      name="password"
      id="password"
      type="password"
      isInvalid={ touched.password && errors.password }
    />
    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>

    {isRegularUser && (
      <NavLink to="/forgot-password">{t`Forgot your username or password?`}</NavLink>
    )}

    <Button type="submit" aria-label="Submit" disabled={ isSubmitting }>
      {t`Sign in`}
      {isSubmitting && <Spinner animation="border" />}
    </Button>
    {isAdminLogin && (<GoogleLoginLink/>)}
  </Form>
)

FormView.propTypes = {
  className: string.isRequired,
  errors: shape({
    email: string,
    password: string
  }).isRequired,
  handleSubmit: func.isRequired,
  isRegularUser: bool.isRequired,
  isSubmitting: bool.isRequired,
  touched: shape({
    email: bool,
    password: bool
  }).isRequired
}

const fieldStyle = isIE()
  ? css`
      .form-control {
        line-height: 1;
        padding: 0.5rem;
      }

      .form-label {
        margin-bottom: 0.5rem;
      }
    `
  : null

export default styled(FormView)`
  ${fieldStyle};

  display: block;
  width: 100%

  a {
    display: block;
    margin-top: 1.5rem;
  }

  ${Button} {
    display: block;
    margin-top: 5.7rem
    position: relative;
    width: 8.5rem;
  }

  #google-login-omniauth-form {
    display: hidden;
  }
`

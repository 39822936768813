import React, { useCallback, memo }   from 'react'
import { string, func }               from 'prop-types'
import { isEqual }                    from 'lodash'
import { t }                          from 'ttag'

import Select from 'shared/components/ui/Select'

const OwnershipSelect = ({ name, value, onChange, className }) => {

  const ownershipTypes = [
    { value: 'owned', label: t`Owned` },
    { value: 'cooperative', label: t`Cooperative` },
    { value: 'stock', label: t`Stock` },
    { value: 'bond', label: t`Bond` },
    { value: 'other', label: t`Other` },
  ]
  
  const saveFormValue = useCallback(({ value }) => {
    onChange({ target: { name, value } })
  }, [onChange])

  return (
    <Select
      className={ className }
      noOptionsMessage={ () => t`No options` }
      value={ ownershipTypes.find((option) => value === option.value) }
      onChange={ saveFormValue }
      options={ ownershipTypes }
    />
  )
}

OwnershipSelect.propTypes = {
  onChange: func.isRequired,
  name: string,
  value: string
}

OwnershipSelect.defaultProps = {
  name: '',
  value: ''
}

export default memo(OwnershipSelect, isEqual)

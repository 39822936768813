import React from 'react'
import { func, object } from 'prop-types'

import IconTrash from 'shared/assets/icons/svg/trash.svg'

const DeleteButton = ({ onClick, style }) => {
  return (
    <button
      type="button"
      style={ style }
      onClick={ onClick }
    >
      <IconTrash />
    </button>
  )
}

DeleteButton.propTypes = {
  onClick: func.isRequired,
  style: object.isRequired
}

export default DeleteButton

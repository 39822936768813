import * as Yup from 'yup'
import { t } from 'ttag'

export default Yup.object().shape({
  fullName: Yup.string()
    .required(t`Please fill a lead name`),

  email: Yup.string()
    .required(t`Please fill an email`)
    .nullable(),

  phoneCode: Yup.string(t`Please fill a phone`)
    .required(t`Please fill a phone`)
    .nullable(),

  phoneNumber: Yup.string(t`Please fill a phone`)
    .required(t`Please fill a phone`)
    .nullable(),

  projectUuid: Yup.string()
    .required(t`Please fill a project`)
    .nullable()
})

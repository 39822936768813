import { gql } from 'apollo-boost'

export default gql`
  mutation UpdateDefaultColor(
    $uuid: ID!
    $input: ProjectInput!
  ) {
    updateProject(
      uuid: $uuid
      input: $input
    ) {
      project {
        uuid
        defaultColor
      }
    }
  }
`

import { t } from 'ttag'

import Media from 'shared/components/NewBuilding/BuildingDetails/Media'

import Details from '../Details'
import Units from '../Units'

export default [
  {
    title: t`Building details`,
    component: Details
  },
  {
    title: t`Unit information`,
    component: Units
  },
  {
    title: t`Media`,
    component: Media
  }
]

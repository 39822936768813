import React, { useCallback } from 'react'
import { t } from 'ttag'

import ProjectsList from 'shared/components/ProjectsList'
import { canRead } from 'shared/helpers/auth'
import redirectTo from 'shared/helpers/redirectTo'
import toastError from 'shared/toastError'

const Projects = () => {
  if (!canRead('projects')) {
    redirectTo('/')
    toastError(t`You are not authorized to perform this action`, { autoClose: 3000 })
  }

  const projectCardLinkBuilder = useCallback((project) => `/projects/${project.uuid}`, [])

  return (
    <ProjectsList
      pageTitle={ t`Projects` }
      projectCardLinkBuilder={ projectCardLinkBuilder }
    />
  )
}

export default Projects

import { useQuery } from '@apollo/react-hooks'

import { currentLocale } from 'locale'
import countriesQuery from 'graphql/queries/countries'

const useCountries = () => {
  const { data } = useQuery(countriesQuery, { variables: { locale: currentLocale } })

  const countries = data?.countries || []
  const dialCodes = countries.map(({ countryCode }) => countryCode).filter(el => el)
  const uniqueDialCodes = Array.from(new Set(dialCodes))

  return {
    countries,
    dialCodes,
    uniqueDialCodes
  }
}

export default useCountries

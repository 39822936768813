import * as Yup from 'yup'

export default Yup.object().shape({
  email: Yup.string()
    .email('Seems like it\'s a wrong email')
    .required('Please fill an email'),

  password: Yup.string()
    .required('Please fill a password')
})

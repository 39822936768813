import { useQuery } from 'react-apollo'

import specificationQuery from 'graphql/queries/campaigns/specification'

export default function useSpecification(campaignUuid) {
  const result = useQuery(specificationQuery, { variables: { campaignUuid } })

  result.specification = result?.data?.campaignSpecification

  return result
}

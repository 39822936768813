import React, { useCallback } from 'react'
import { t } from 'ttag'

import PropertiesList from 'shared/components/PropertiesList'

const Properties = () => {
  const propertyCardLinkBuilder = useCallback(
    (property) => `/properties/${property.uuid}/campaigns/new`
    , []
  )

  return (
    <PropertiesList
      pageTitle={ t`Create campaign - Choose property` }
      propertyCardLinkBuilder={ propertyCardLinkBuilder }
    />
  )
}

export default Properties

import { array, shape, number } from 'prop-types'

export default shape({
  leadsCount: number,
  totalSpent: number,
  averageCpc: number,
  averageCpl: number,
  averageCtr: number,
  cpcTrend: array,
  cplTrend: array,
  ctrTrend: array,
  totalClicks: number,
  totalImpressions: number,
  totalReach: number
})

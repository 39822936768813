import { buildFragmentParams } from 'shared/helpers/graphql'
import deleteStakeholderMutation
  from 'graphql/mutations/productStakeholders/deleteStakeholderMutation'
import productFragment from 'graphql/fragments/product'

const deleteStakeholder = (client, product, uuid) => {
  client.mutate({
    mutation: deleteStakeholderMutation,
    variables: { productUuid: product.uuid, uuid },
    update: (cache, { data: { deleteProductStakeholder: { stakeholder } } }) => {
      const fragmentParams = buildFragmentParams(product, productFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          stakeholders: fragmentData.stakeholders.filter(({ uuid }) => uuid !== stakeholder.uuid)
        }
      })
    }
  })
}

export default deleteStakeholder

import React    from 'react'
import { t }    from 'ttag'
import { func } from 'prop-types'
import Select   from 'react-select'
import { uniq } from 'lodash'

import condensedSelectStyles   from 'shared/style/condensedSelectStyles'
import { inputBoxShadowValue } from 'shared/style/boxShadowValues'
import landingPageType         from 'shared/types/landingPageType'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    boxShadow: inputBoxShadowValue,
  }),
}

const UrlsSelect = ({ landingPage, onChange, ...props }) => {
  const allUrls = uniq([landingPage.url].concat(landingPage.urlsBasedOnCompanyDomains))
  const options = allUrls.map((url) => ({
    value: url,
    label: url
  }))

  const selectedOption = options.filter(
    option => option.value === landingPage.url
  )

  return (
    <Select
      value={ selectedOption }
      onChange={ option => onChange(option.value) }
      options={ options }
      styles={ styles }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

UrlsSelect.propTypes = {
  landingPage: landingPageType.isRequired,
  onChange: func.isRequired
}

export default UrlsSelect

import React  from 'react'
import styled from 'styled-components'
import { number, string, bool } from 'prop-types'

const Block = ({ className, text, percentage, isProject }) => {
  return (
    <div
      className={ className }
      data-test-id={ `sales-percentage-${isProject ? 'project' : 'stage'}` }
    >
      <div className='header flex-wrap'
        id={ `${isProject ? 'sales-percentage-project-header' : null}` }
      >
        <p className='header-text'>{ text }</p>
        <p className='header-value'>{ parseFloat(percentage).toFixed(1) }%</p>
      </div>
      <div className='percentage-block'>
        <div className='percentage-value' style={{ width: `${percentage}%` }} />
      </div>
    </div>
  )
}

Block.propTypes = {
  percentage: number.isRequired,
  text: string.isRequired,
  isProject: bool
}

Block.defaultProps = {
  isProject: undefined
}

export default styled(Block)`
  width: 25%;
  margin: 1rem 0;
  padding: 0 1.5625rem;
  border-right: 1px solid rgb(234, 234, 234);

  &:nth-of-type(4n) {
    border-right: 0;
  }

  .header {
    position: relative;

    &-text {
      height: 100%;
      font-size: .875rem;
      line-height: 1.4;
      font-weight: 600;
      color: #4a4a4a;
      margin-bottom: .5rem;
      width: 80%;
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-value {
      position: absolute;
      top: 0;
      right: 0;
      font-size: .75rem;
      font-weight: 500;
      line-height: 1.5rem;
      color: #828282;
      margin-bottom: .5rem;
    }
  }

  .percentage-block {
    display: block;
    position: relative;
    height: .75rem;
    border-radius: .125rem;
    background: #d8eaec;
    white-space: normal;
    overflow: hidden;

    .percentage-value {
      position: absolute;
      left: 0;
      top: 0;
      height: .75rem;
      border-radius: 2px;
      background: #026865;
    }
  }
`

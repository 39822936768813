import { useMutation } from 'react-apollo'

import createUserMutation from 'graphql/mutations/users/createUser'

export default function useCreateUser() {
  const [createUser, variables] = useMutation(createUserMutation)

  return {
    createUser,
    ...variables
  }
}

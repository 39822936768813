import attachFileMutation from 'graphql/mutations/assetable/attachFile'

import updateCampaignAssetsCache from './updateCampaignAssetsCache'

const uploadLogoToCampaign = ({ client, blobId, assetable }) => {
  const assetableUuid = assetable.uuid
  const assetableType = 'Campaign'

  return client.mutate({
    mutation: attachFileMutation,
    variables: { blobId, assetableUuid, assetableType, mediaType: 'logo' },
    update: (store, {
      data: {
        attachFileToAssetable: { assetAssignment }
      }
    }) => updateCampaignAssetsCache({ store, assetableUuid, assetAssignment })
  })
}

export default uploadLogoToCampaign

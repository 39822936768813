import gql from 'graphql-tag'

import project from 'graphql/fragments/projects_list_item'

export default gql`
  query Projects($query: PageInput!) {
    projects(query: $query) {
      total
      items {
        ... ProjectsListItem
      }
    }
  }
  ${project}
`

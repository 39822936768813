import styled from 'styled-components'

import { greyishBrownColor } from 'shared/style/colors'

export default styled.h5`
  color: ${greyishBrownColor}
  font-weight: 600
  font-size: 1.2rem
  margin-bottom: 0
`

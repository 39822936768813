import { useMutation } from 'react-apollo'
import { get }         from 'lodash'

import createCreativeSetMutation from 'graphql/mutations/campaign/creativeSets/create'
import campaignCreativeSetsQuery from 'graphql/queries/campaignCreativeSets'
import useCurrentCampaignUuid    from 'shared/hooks/useCurrentCampaignUuid'

const useCreateCreativeSet = () => {
  const campaignUuid = useCurrentCampaignUuid()
  const [createCreativeSet] = useMutation(createCreativeSetMutation)

  return (type) => {
    createCreativeSet({
      variables: {
        type,
        campaignUuid
      },
      update: (cache, response) => {
        const creativeSet = get(response, 'data.createCreativeSet.creativeSet')
        const query = { query: campaignCreativeSetsQuery, variables: { campaignUuid } }
        const { campaignCreativeSets } = cache.readQuery(query)
        const creativeSets = [creativeSet, ...campaignCreativeSets]
        cache.writeQuery({ ...query, data: { campaignCreativeSets: creativeSets } })
      }
    })
  }
}

export default useCreateCreativeSet

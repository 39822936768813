import { gql } from 'apollo-boost'

export default gql`
  mutation attachPostMedia(
    $blobId: String!
    $uuid: String!
    $mediaType: AssetMediaType
  ) {
    attachPostMedia(
      blobId: $blobId
      uuid: $uuid
      mediaType: $mediaType
    ) {
      media {
        uuid
        state
        asset {
          uuid
          media
          thumbnail
          position
          mediaType
          deletable
        }
      }
    }
  }
`

import gql from 'graphql-tag'

export default gql`
  mutation removeCampaignLandingPageAgent(
    $uuid: ID!
  ) {
    removeCampaignLandingPageAgent(
      uuid: $uuid
    ) {
      agent {
        uuid
        name
        phoneNumber
        email
        title
      }
    }
  }
`

import Profile          from 'shared/components/pages/Profile'
import Company          from 'shared/components/pages/Company'
import New              from 'shared/components/pages/New'
import Production       from 'shared/components/pages/Production'
import Dashboard        from 'shared/components/pages/Dashboard'
import Ads              from 'shared/components/pages/Ads'
import NewLead          from 'shared/components/pages/NewLead'
import Leads            from 'shared/components/pages/Leads'
import Lead             from 'shared/components/pages/Lead'
import Users            from 'shared/components/pages/Users'
import SalesReports     from 'shared/components/pages/SalesReports'
import Projects         from 'shared/components/pages/Projects'
import ProjectNewsEdit  from 'shared/components/pages/ProjectNewsEdit'
import NewProject       from 'shared/components/pages/NewProject'
import NewStageProjects from 'shared/components/pages/NewStage/Projects'
import NewStageCreate   from 'shared/components/pages/NewStage/Create'
import NewStageForm     from 'shared/components/pages/NewStage/StageForm'
import NewBuildingProjects     from 'shared/components/pages/NewBuilding/Projects'
import NewBuildingCreate       from 'shared/components/pages/NewBuilding/Create'
import NewBuildingForm         from 'shared/components/pages/NewBuilding/BuildingForm'
import Project          from 'shared/components/pages/Project'
import Campaign         from 'shared/components/pages/Campaign'
import CampaignEdit     from 'shared/components/pages/CampaignEdit'
import CampaignChangelogs from 'shared/components/pages/CampaignChangelogs'
import Product          from 'shared/components/pages/Product'
import Promotable       from 'shared/components/pages/Promotable'
import ProjectSalesReport      from 'shared/components/pages/ProjectSalesReport'
import LandingPageContent      from 'shared/components/pages/LandingPageContent'
import NewAutoCampaignProjects from 'shared/components/pages/NewAutoCampaign/Projects'
import NewAutoCampaignStages   from 'shared/components/pages/NewAutoCampaign/Stages'
import DuplicateCampaignForm   from 'shared/components/pages/NewAutoCampaign/DuplicateCampaignForm'
import NewPropertyCampaignForm from 'shared/components/pages/NewAutoCampaign/PropertyCampaignForm'
import NewAutoCampaignStagesMissingData
  from 'shared/components/pages/NewAutoCampaign/Stages/MissingData'
import NewAutoCampaignProperties
  from 'shared/components/pages/NewAutoCampaign/Properties'
import NewCampaignForm from 'shared/components/pages/NewAutoCampaign/CampaignForm'
import { withLayout } from 'user/components/Layout'
import CampaignCreationInProgress from 'shared/components/pages/CampaignCreationInProgress'

export default [
  { path: '/', exact: true, component: withLayout(Dashboard) },
  { path: '/ads', exact: true, component: withLayout(Ads) },
  { path: '/new', exact: true, component: withLayout(New) },
  { path: '/profile', exact: true, component: withLayout(Profile) },
  { path: '/companies/:uuid', exact: true, component: withLayout(Company) },
  { path: '/products', exact: true, component: withLayout(Promotable) },
  { path: '/products/:uuid', exact: true, component: withLayout(Product) },
  { path: '/campaigns', exact: true, component: withLayout(Production) },
  { path: '/campaigns/:uuid', exact: true, component: withLayout(Campaign) },
  { path: '/campaigns/:uuid/edit', exact: true, component: withLayout(CampaignEdit) },
  { path: '/campaigns/:uuid/change_logs', component: withLayout(CampaignChangelogs) },
  {
    exact: true,
    path: '/campaigns/new/stages/:uuid/missing_data',
    component: withLayout(NewAutoCampaignStagesMissingData)
  },
  {
    exact: true,
    path: '/campaigns/new/projects/:uuid/stages',
    component: withLayout(NewAutoCampaignStages)
  },
  { path: '/campaigns/new/properties', component: withLayout(NewAutoCampaignProperties) },
  { path: '/campaigns/new/projects', exact: true, component: withLayout(NewAutoCampaignProjects) },
  {
    exact: true,
    path: '/campaigns/:campaignUuid/landing_pages/:uuid/edit',
    component: withLayout(LandingPageContent)
  },
  { exact: true,
    path: '/campaign_creation_in_progress',
    component: withLayout(CampaignCreationInProgress) },
  { path: '/stages/:uuid/campaigns/new', component: withLayout(NewCampaignForm) },
  { path: '/leads', exact: true, component: withLayout(Leads) },
  { path: '/leads/new', exact: true, component: withLayout(NewLead) },
  { path: '/leads/:uuid', exact: true, component: withLayout(Lead) },
  { path: '/users', exact: true, component: withLayout(Users) },
  { path: '/projects', exact: true, component: withLayout(Projects) },
  { path: '/projects/new', exact: true, component: withLayout(NewProject) },
  { path: '/projects/:uuid', exact: true, component: withLayout(Project) },
  { path: '/projects/:uuid/news', component: withLayout(ProjectNewsEdit) },
  { path: '/projects/:uuid/stages/create', exact: true, component: withLayout(NewStageCreate) },
  { path: '/projects/:uuid/buildings/create', component: withLayout(NewBuildingCreate) },

  { path: '/sales_reports', exact: true, component: withLayout(SalesReports) },
  { path: '/sales_reports/projects/:uuid', component: withLayout(ProjectSalesReport) },

  { path: '/stages/new/projects', exact: true, component: withLayout(NewStageProjects) },
  { path: '/buildings/new/projects', component: withLayout(NewBuildingProjects) },
  { path: '/properties/:uuid/campaigns/new', component: withLayout(NewPropertyCampaignForm) },
  {
    path: '/stages/:uuid/campaigns/:campaignUuid/duplicate',
    component: withLayout(DuplicateCampaignForm)
  },
  { path: '/stages/:uuid/new', exact: true, component: withLayout(NewStageForm) },
  { path: '/buildings/:uuid/new', component: withLayout(NewBuildingForm) },
]

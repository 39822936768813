import styled from 'styled-components'

import { textColor } from 'shared/style/colors'

export default styled.h4`
  color: ${textColor}
  font-size: 1.25rem
  font-weight: 600
  letter-spacing: 0.2px
`

import updateProjectMutation from 'graphql/mutations/projects/update'

const updateProject = (client, project, input = {}, location = {}, companies = []) => {
  return client.mutate({
    mutation: updateProjectMutation,
    variables: { uuid: project.uuid, input, location, companies }
  })
}

export default updateProject

import { gql } from 'apollo-boost'

export default gql`
  query CampaignInsights($campaignUuid: ID!) {
    campaignInsights(campaignUuid: $campaignUuid) {
      leadsCount
      totalSpent
      totalImpressions
      totalClicks
      totalReach
    }
  }
`

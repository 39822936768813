import updateSalesArgumentMutation
  from 'graphql/mutations/projects/stages/updateSalesArgumentMutation'

const updateSalesArgument = (client, salesArgument, input) => (
  client.mutate({
    mutation: updateSalesArgumentMutation,
    variables: {
      uuid: salesArgument.uuid,
      input
    }
  })
)

export default updateSalesArgument

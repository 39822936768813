import React from 'react'
import AsyncSelect from 'react-select/async'
import { t } from 'ttag'
import { useApolloClient } from 'react-apollo'
import { arrayOf, shape, string } from 'prop-types'

import MultiSelect from 'shared/components/ui/MultiSelect/MultiSelect'
import useCurrentCampaignUuid from 'shared/hooks/useCurrentCampaignUuid'
import query from 'graphql/queries/facebookCustomAudiences'

const styles = {
  container: provided => ({
    ...provided,
    width: '25rem'
  }),
  control: provided => ({
    ...provided,
    fontSize: '0.8rem'
  }),
  option: provided => ({
    ...provided,
    fontSize: '0.8rem'
  })
}

const valueToOption = el => ({
  ...el,
  label: el.name,
  value: el.id
})

const buildOptions = data => {
  const options = data.map(valueToOption)

  const groups = Array.from(new Set(options.map(el => el.subtype)))

  return groups.map(subtype => ({
    label: subtype,
    options: options.filter(el => el.subtype === subtype)
  }))
}

const CustomAudienceSelect = ({ value: selectedValues, ...props }) => {
  const campaignUuid = useCurrentCampaignUuid()
  const client = useApolloClient()

  const value = (selectedValues || []).map(valueToOption)

  const loadOptions = async term => {
    const { data: { facebookCustomAudiences } } = await client.query({
      query: query,
      variables: { query: term, campaignUuid }
    })

    return buildOptions(facebookCustomAudiences)
  }

  return (
    <MultiSelect
      component={ AsyncSelect }
      loadOptions={ loadOptions }
      value={ value }
      styles={ styles }
      placeholder={ t`Add a previously created Custom or Lookalike Audience` }
      noOptionsMessage={ () => t`No Options` }
      { ...props }
    />
  )
}

CustomAudienceSelect.propTypes = {
  value: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
    subtype: string.isRequired
  }))
}

CustomAudienceSelect.defaultProps = {
  value: []
}

export default CustomAudienceSelect

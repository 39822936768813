import React from 'react'
import { func }        from 'prop-types'
import classNames from 'classnames'

import assetType from 'shared/types/assetType'
import AssetContainer from 'shared/components/ui/Asset/Container'

const Asset = ({ asset, onClick, handleAssetRemoval, className }) => {
  const handleClick = (e) => {
    if (handleAssetRemoval && e.target.dataset.action === 'delete') {
      handleAssetRemoval(asset)
    } else if (onClick) {
      onClick(asset)
    }
  }

  const finalClassName = classNames(className, 'asset-container')

  return (
    <AssetContainer
      onClick={ handleClick }
      className={ finalClassName }
      style={{ backgroundColor: (asset.mediaType === 'logo' && asset.backgroundColor) }}
    >
      { handleAssetRemoval && (
        <button type="button" data-action='delete' data-test-id='remove-asset-button'>
          ✖
        </button>
      ) }

      <span>{ asset.originalFilename }</span>

    </AssetContainer>
  )
}

Asset.propTypes = {
  asset: assetType.isRequired,
  handleAssetRemoval: func,
  onClick: func
}

Asset.defaultProps = {
  handleAssetRemoval: undefined,
  onClick: undefined
}

export default Asset

import React  from 'react'
import styled from 'styled-components'
import { arrayOf, object } from 'prop-types'

import creativeSetType from 'shared/types/creativeSetType'

import CreativeSet from './CreativeSet'

const CreativeSetsList = ({ creativeSets, className, ...props }) => {
  return (
    <div className={ className }>
      { creativeSets.map((creativeSet) => (
        <CreativeSet
          creativeSet={ creativeSet }
          key={ creativeSet.uuid }
          { ...props }
        />
      )) }
    </div>
  )
}

CreativeSetsList.propTypes = {
  campaign: object.isRequired,
  creativeSets: arrayOf(creativeSetType).isRequired,
}

export default styled(CreativeSetsList)`
  padding: 1.5rem 0

  ${CreativeSet} {
    margin: 0 0 1.5rem
  }
`

import gql from 'graphql-tag'

import snapshotUnitSet from 'graphql/fragments/snapshotUnitSet'
import snapshotCustomFacility from 'graphql/fragments/snapshotCustomFacility'
import snapshotPhase from 'graphql/fragments/snapshotPhase'
import assetAssignment from 'graphql/fragments/assetAssignment'
import facility from 'graphql/fragments/facility'

export default gql`
  fragment Snapshot on Snapshot {
    uuid
    headline
    headlineDraft
    customDescription
    customDescriptionDraft
    buildingTypes
    planning
    salesStartAt
    constructionStartAt
    movingStartAt
    facilities {
      ... Facility
    }
    saleState
    phases {
      ... SnapshotPhase
    }
    unitSets {
      ... SnapshotUnitSet
    },
    customFacilities {
      ... SnapshotCustomFacility

    }
    assetAssignments {
      ... AssetAssignment
    }
  }
  ${snapshotPhase}
  ${snapshotUnitSet}
  ${snapshotCustomFacility}
  ${assetAssignment}
  ${facility}
`

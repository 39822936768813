import React from 'react'
import Select from 'react-select'
import { t } from 'ttag'
import { string } from 'prop-types'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    width: '8rem'
  })
}

const IncomeRangeSelect = ({ defaultValue, value, ...props }) => {
  const defaultOption = defaultValue ? options.find(el => el.value === defaultValue) : options[0]
  const selectedOption = value ? options.find(el => el.value === value) : defaultOption

  return(
    <Select
      value={ selectedOption }
      options={ options }
      styles={ styles }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

IncomeRangeSelect.propTypes = {
  defaultValue: string,
  value: string
}

IncomeRangeSelect.defaultProps = {
  defaultValue: null,
  value: null
}

export const options = [
  { label: `${t`Lower`} 50%`,  value: 'INCOME_RANGE_0_50' },
  { label: `${t`Top`} 41-50%`, value: 'INCOME_RANGE_50_60' },
  { label: `${t`Top`} 31-40%`, value: 'INCOME_RANGE_60_70' },
  { label: `${t`Top`} 21-30%`, value: 'INCOME_RANGE_70_80' },
  { label: `${t`Top`} 11-20%`, value: 'INCOME_RANGE_80_90' },
  { label: `${t`Top`} 10%`,    value: 'INCOME_RANGE_90' }
]

export default IncomeRangeSelect

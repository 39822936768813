import gql from 'graphql-tag'

export default gql`
  mutation updateCampaignLandingPageAgent(
    $uuid:  ID!
    $input: CampaignLandingPageAgentInput!
  ) {
    updateCampaignLandingPageAgent(
      uuid:  $uuid
      input: $input
    ) {
      agent {
        uuid
        name
        phoneNumber
        email
        title
      }
    }
  }
`

import React from 'react'
import styled from 'styled-components'
import { string, func } from 'prop-types'
import { t } from 'ttag'

import Button from 'shared/components/ui/Button'
import { LocationOperatorIcon } from 'shared/components/ui/icons'

const DropPinButton = ({ className, currentOperator, onClick, ...props }) => {
  return (
    <div className={ className }>
      <Button onClick={ onClick } data-test-id='pin-button' { ...props }>
        <LocationOperatorIcon operator={ currentOperator } />
        { t`Drop Pin` }
      </Button>
    </div>
  )
}

DropPinButton.propTypes = {
  className: string.isRequired,
  onClick: func.isRequired,
  currentOperator: string
}

DropPinButton.defaultProps = {
  currentOperator: 'include'
}

export default styled(DropPinButton)`
  position: absolute
  bottom: 1rem
  right: 0.8rem
  z-index: 10

  ${LocationOperatorIcon} {
    margin-right: 0.5rem
    position: relative
    top: 0.125rem
  }
`

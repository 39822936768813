import React from 'react'
import { t } from 'ttag'

import StaticHeader from 'shared/components/ui/Card/StaticHeader'

import ImageUploader from './ImageUploader'

const Header = () => {
  return (
    <div className="d-flex justify-content-between">
      <StaticHeader className="align-self-center">
        { t`User images` }
      </StaticHeader>

      <ImageUploader />
    </div>
  )
}

export default Header

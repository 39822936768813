import React from 'react'
import { object } from 'prop-types'
import { get } from 'lodash'
import { IconAdd, IconMinus } from 'cabanaico'
import { t } from 'ttag'
import { useMutation } from 'react-apollo'
import styled from 'styled-components'

import stageUnitFragment from 'graphql/fragments/stageUnit'
import { buildFragmentParams } from 'shared/helpers/graphql'
import Button from 'shared/components/ui/Button'
import createStageUnitBalconyMutation
  from 'graphql/mutations/projects/stages/balconies/createStageUnitBalconyMutation'
import deleteStageUnitBalconyMutation
  from 'graphql/mutations/projects/stages/balconies/deleteStageUnitBalconyMutation'

import StageUnitBalcony from './StageUnitBalcony'

const StageUnitBalconies = ({ className, stageUnit }) => {
  const [runCreateBalconyMutation] = useMutation(createStageUnitBalconyMutation)
  const [runDeleteBalconyMutation] = useMutation(deleteStageUnitBalconyMutation)

  const addBalcony = () => {
    const newBalcony = {
      size: 0,
      facingDirection: 'north',
      name: `Balcony ${stageUnit.balconies.length + 1}`
    }

    return runCreateBalconyMutation({
      variables: {
        unitUuid: stageUnit.uuid,
        input: newBalcony
      },
      update: (cache, response) => {
        const balcony = get(response, 'data.createStageUnitBalcony.balcony')
        const object = { __typename: 'StageUnit', uuid: stageUnit.uuid }
        const fragmentParams = buildFragmentParams(object, stageUnitFragment)
        const fragmentData = cache.readFragment(fragmentParams)
        const newBalconies = [...fragmentData.balconies, balcony]
        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            balconies: newBalconies
          }
        })
      }
    })
  }

  const removeBalcony = () => {
    const newBalconies = stageUnit.balconies
    const removedBalcony = newBalconies.pop()
    return runDeleteBalconyMutation({
      variables: { uuid: removedBalcony.uuid },
      update: (cache) => {
        const object = { __typename: 'StageUnit', uuid: stageUnit.uuid }
        const fragmentParams = buildFragmentParams(object, stageUnitFragment)
        const fragmentData = cache.readFragment(fragmentParams)
        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            balconies: newBalconies
          }
        })
      }
    })
  }

  return (
    <div className={ className }>
      <div className='balconies-list'>
        { stageUnit.balconies.map(balcony => (
          <StageUnitBalcony balcony={ balcony } key={ balcony.uuid } className='w-100' />
        ))}
      </div>
      <div className='balcony-buttons'>
        <Button
          variant='default'
          className='phase-btn'
          onClick={ addBalcony }>
          <IconAdd /> { t`Add balcony` }
        </Button>
        <Button
          variant='default'
          className='phase-btn'
          onClick={ removeBalcony }
          disabled={ stageUnit.balconies.length === 0 }>
          <IconMinus /> { t`Remove balcony` }
        </Button>
      </div>
    </div>
  )
}

StageUnitBalconies.propTypes = {
  stageUnit: object.isRequired
}

export default styled(StageUnitBalconies)`
  width: 60%;

  .balcony-buttons {
    margin-top: 1rem;

    .phase-btn {
      &:nth-of-type(1) {
        margin-right: .5rem;
      }
    }
  }
`

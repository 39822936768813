import gql from 'graphql-tag'

import asset from './asset'

export default gql`
  fragment AssetAssignment on AssetAssignment {
    uuid
    mediaType
    portalEnabled
    portalEnabledDraft
    portalPosition
    portalPositionDraft
    asset {
      ... Asset
    }
  }
  ${asset}
`

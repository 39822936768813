import React from 'react'
import { t } from 'ttag'
import { array, arrayOf, number, string, shape, func } from 'prop-types'
import { Field, FieldArray } from 'formik'

import apolloClientType from 'shared/types/apolloClientType'
import audienceType from 'shared/types/audienceType'
import campaignType from 'shared/types/campaignType'
import Checkbox from 'shared/components/ui/Checkbox'
import SubHeader from 'shared/components/ui/Card/SubHeader'

import DuplicateAudienceLocations from '../DuplicateAudienceLocations'

import AgeSelect from './AgeSelect'
// import IncomeRangeSelect from './IncomeRangeSelect'
import DetailedTargetingSelect from './DetailedTargetingSelect'
import LanguageTargetingSelect from './LanguageTargetingSelect'
import Location from './Location'
import CustomAudiences from './CustomAudiences'
import {
  LeftColumn as Container,
  Separator,
  CheckboxGroup,
  Label,
  Group,
  ErrorMessage
} from './elements'

const genders = [
  { label: t`Men`, value: 'Male' },
  { label: t`Women`, value: 'Female' },
  { label: t`Unknown`, value: 'Undetermined' }
]

const parentalStatuses = [
  { label: t`Parent`, value: 'PARENT' },
  { label: t`Not a parent`, value: 'NOT_A_PARENT' },
  { label: t`Unknown`, value: 'UNDETERMINED' }
]

const LeftColumn = (
  {
    audience, allAudiences, campaign, client, formikBag: { values, errors, touched, setFieldValue }
  }
) => {
  const convertOptions = options => options && options.map(
    ({ id, name }) => ({ id, name })
  )

  return <Container>
    <SubHeader>{t`Age`}</SubHeader>
    <div className="d-flex">
      <Field
        as={ AgeSelect }
        aria-label="min age"
        name="ageFrom"
        mode="from"
        value={ values.ageFrom }
        onChange={ ({ value }) => setFieldValue('ageFrom', value) }
      />

      <Separator>–</Separator>

      <Field
        as={ AgeSelect }
        aria-label="max age"
        mode="to"
        value={ values.ageTo }
        onChange={ ({ value }) => setFieldValue('ageTo', value) }
      />
    </div>

    {(touched.ageFrom || touched.ageTo) && (errors.ageFrom || errors.ageTo) && (
      <ErrorMessage>
        {[errors.ageFrom, errors.ageTo].filter(el => el).join(', ')}
      </ErrorMessage>
    )}

    <SubHeader>{t`Gender`}</SubHeader>
    <FieldArray
      name="gender"
      render={ arrayHelpers => {
        const handleChange = e => {
          const { checked, value } = e.target

          if (checked) {
            arrayHelpers.push(value)
          } else {
            const idx = values.gender.indexOf(value)
            arrayHelpers.remove(idx)
          }
        }

        return (
          <CheckboxGroup>
            {genders.map(({ label, value }) => (
              <Checkbox
                key={ value }
                label={ label }
                value={ value }
                checked={ values.gender.includes(value) }
                shadow={ false }
                onChange={ handleChange }
              />
            ))}
          </CheckboxGroup>
        )
      } }
    />

    {values.gender.length === 0 && (
      <ErrorMessage>
        {t`Please choose at least 1 gender`}
      </ErrorMessage>
    )}

    <SubHeader>{t`Parental status`}</SubHeader>
    <FieldArray
      name="parentalStatus"
      render={ arrayHelpers => {
        const handleChange = e => {
          const { checked, value } = e.target

          if (checked) {
            arrayHelpers.push(value)
          } else {
            const idx = values.parentalStatus.indexOf(value)
            arrayHelpers.remove(idx)
          }
        }

        return (
          <CheckboxGroup>
            {parentalStatuses.map(({ label, value }) => (
              <Checkbox
                key={ value }
                label={ label }
                value={ value }
                shadow={ false }
                checked={ values.parentalStatus.includes(value) }
                onChange={ handleChange }
              />
            ))}
          </CheckboxGroup>
        )
      } }
    />

    {values.parentalStatus.length === 0 && (
      <ErrorMessage>
        {t`Please choose at least 1 parental status`}
      </ErrorMessage>
    )}

    <SubHeader>{t`Language targeting`}</SubHeader>
    <Group>
      <LanguageTargetingSelect
        value={ values.languageTargeting }
        onChange={ values => { setFieldValue('languageTargeting', convertOptions(values)) } }
      />
    </Group>

    {/* Income range should be hidden (not available in Norway)
    <SubHeader>{t`Income range`}</SubHeader>
    <div className="d-flex">
      <Field
        as={ IncomeRangeSelect }
        name="incomeFrom"
        defaultValue={ 'Top 10%' }
        value={ values.incomeFrom }
        onChange={ ({ value }) => setFieldValue('incomeFrom', value) }
      />

      <Separator>–</Separator>

      <Field
        as={ IncomeRangeSelect }
        name="incomeTo"
        defaultValue={ 'Lower 50%' }
        value={ values.incomeTo }
        onChange={ ({ value }) => setFieldValue('incomeTo', value) }
      />
    </div>

    {(touched.incomeFrom || touched.incomeTo) && (errors.incomeFrom || errors.incomeTo) && (
      <ErrorMessage>
        {[errors.incomeFrom, errors.incomeTo].filter(el => el).join(', ')}
      </ErrorMessage>
    )}*/}

    <Location
      audience={ audience }
      client={ client }
    />

    <DuplicateAudienceLocations allAudiences={ allAudiences } currentAudience={ audience } />
    <CustomAudiences audience={ audience } />

    <SubHeader>{t`Detailed targeting`}</SubHeader>
    <Group>
      <Label>{t`Include`}</Label>
      <DetailedTargetingSelect
        campaign={ campaign }
        value={ values.detailedTargetingInclude }
        placeholder={ t`Add interests or behaviors` }
        noOptionsMessage={ () => t`No options` }
        onChange={ values => { setFieldValue('detailedTargetingInclude', values) } }
      />
    </Group>
    <Group>
      <Label>{t`Exclude`}</Label>
      <DetailedTargetingSelect
        campaign={ campaign }
        value={ values.detailedTargetingExclude }
        placeholder={ t`Add interests or behaviors` }
        noOptionsMessage={ () => t`No options` }
        onChange={ values => setFieldValue('detailedTargetingExclude', values) }
      />
    </Group>
  </Container>
}

LeftColumn.propTypes = {
  allAudiences: arrayOf(
    shape({
      name: string,
      uuid: string
    })
  ).isRequired,
  campaign: campaignType.isRequired,
  client: apolloClientType.isRequired,
  formikBag: shape({
    setFieldValue: func.isRequired,
    values: shape({
      parentalStatus: array.isRequired,
      gender: array.isRequired,
      ageFrom: number,
      ageTo: number,
      incomeFrom: string,
      incomeTo: string,
      detailedTargetingInclude: array,
      detailedTargetingExclude: array,
    }).isRequired
  }).isRequired,
  audience: audienceType
}

LeftColumn.defaultProps = {
  audience: null
}

export default LeftColumn

import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import { Switch } from 'pretty-checkbox-react'

import Card from 'shared/components/ui/Card'
import LabeledList from 'shared/components/ui/LabeledList'
import JustifiedDiv from 'shared/components/ui/JustifiedDiv'
import Paragraph from 'shared/components/ui/Paragraph'

const Listings = ({ className }) => {
  const listItems = [{
    labelVariant: 'secondary',
    labelText: 'Compatible',
    content: (
      <JustifiedDiv>
        <Paragraph className="mb-0">
          Finn.no
        </Paragraph>
        <Paragraph className="mb-0">
          <Switch shape="fill" >
            Connection Status
          </Switch>
        </Paragraph>
      </JustifiedDiv>
    )
  }, {
    labelVariant: 'danger',
    labelText: 'Uncompatible',
    content: (
      <JustifiedDiv>
        <Paragraph className="mb-0">
          Zillow
        </Paragraph>
        <Paragraph className="mb-0">
          <Switch shape="fill" >
            Connection Status
          </Switch>
        </Paragraph>
      </JustifiedDiv>
    )
  }, {
    labelVariant: 'secondary',
    labelText: 'Compatible',
    content: (
      <JustifiedDiv>
        <Paragraph className="mb-0">
          Marketer Portal
        </Paragraph>
        <Paragraph className="mb-0">
          <Switch disabled shape="fill" >
            Connection Status
          </Switch>
        </Paragraph>
      </JustifiedDiv>
    )
  }]

  return (
    <Card
      collapsible
      className={ className }
      header="Listings"
      bodyProps={{ className: 'p-0' }}
    >
      <LabeledList items={ listItems }/>
    </Card>
  )
}

Listings.propTypes = {
  className: string.isRequired
}

export default styled(Listings)`
  .pretty {
    margin-right: 0
  }
  
  .pretty.p-switch .state::before {
    transform: scale(1.25)
  }
  
  .pretty.p-switch .state label::before,
  .pretty.p-switch .state label::after {
    transform: scale(1)
  }

  .pretty.p-switch .state label {
    margin-left: 5px;
  }
  
  .pretty.p-switch input:checked ~ .state::before {
    border-color: #5450f7
    background-color: #5450f7 !important
  }
`

import moment    from 'moment/moment'
import pluralize from 'pluralize'
import { t } from 'ttag'

export default function createdAtFormatter(cell) {
  let differenceString = ''

  const dateNow = moment()
  const createdAt = moment(cell)

  const daysDifference = dateNow.diff(createdAt, 'days')

  if (daysDifference > 0) {
    differenceString = pluralize(t`day`, daysDifference, true)
  } else {
    const hoursDifference = dateNow.diff(createdAt, 'hours')
    differenceString = pluralize(t`hour`, hoursDifference, true)
  }

  return t`${differenceString} ago`
}

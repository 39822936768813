import React    from 'react'
import { func } from 'prop-types'

import ThriftyInput from 'shared/components/ui/ThriftyInput'

const FormikThriftyInput = ({ onChange, ...props }) => {
  return (
    <ThriftyInput onUpdate={ onChange } { ...props } />
  )
}

FormikThriftyInput.propTypes = {
  onChange: func.isRequired
}

export default FormikThriftyInput

import gql from 'graphql-tag'

export default gql`
  mutation DiscardChangeset($changesetKey: String!) {
    discardChangeset(changesetKey: $changesetKey) {
      discardedChangeset {
        key
      }
    }
  }
`

import React from 'react'
import Select from 'react-select'
import { t } from 'ttag'
import { oneOf } from 'prop-types'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    width: '10rem'
  })
}

const options = [
  { value: 'active_lead', label: t`Active lead` },
  { value: 'won_lead', label: t`Won lead` },
  { value: 'lost_lead', label: t`Lost lead` },
]

const LeadStatus = ({ value, ...props }) => {
  const selectedOption = options.find(el => el.value === value)

  return (
    <Select
      value={ selectedOption }
      options={ options }
      styles={ styles }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

LeadStatus.propTypes = {
  value: oneOf(options.map(el => el.value)).isRequired
}

export default LeadStatus

import dataURItoBlob      from 'shared/helpers/files/dataURItoBlob'
import mimeTypes from 'shared/constants/mimeTypes'
import completeFileUpload from 'shared/helpers/files/completeFileUpload'

import attachImageToUser from './attachImageToUser'

export default function uploadImage({ client, fileDataURI, fileMeta }) {
  const file = dataURItoBlob(fileDataURI)
  const allowedMimeTypes = mimeTypes.image

  return completeFileUpload({ client, file, allowedMimeTypes, fileMeta })
    .then(({ signedBlobId }) => attachImageToUser({ client, blobId: signedBlobId }))
}

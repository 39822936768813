import React      from 'react'
import { t }      from 'ttag'
import { array } from 'prop-types'

import Calendar from 'shared/assets/icons/svg/calendar.svg'

import Section from '../Section'
import Body    from '../Body'

import ShowingsListOverlay from './ShowingsListOverlay'

const Showings = ({ showings }) => {
  return (
    <Section title={ t`Showings` }>
      <Body>
        <Calendar />

        <ShowingsListOverlay showings={ showings } />
      </Body>
    </Section>
  )
}

Showings.propTypes = {
  showings: array.isRequired
}

export default Showings

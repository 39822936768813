import React                  from 'react'
import styled                 from 'styled-components'
import { string, node, bool } from 'prop-types'

import Arrow           from 'shared/assets/icons/svg/new/arrow.svg'
import UserRoleNavLink from 'routes/UserRoleNavLink'

const ItemCard = ({ className, icon, title, subtitle, to, disabled, ...props }) => {
  return (
    <UserRoleNavLink
      to={ to }
      className={ `${className} ${disabled ? 'feature-disabled' : ''}` }
      { ...props }
    >
      { icon && (
        <div className="item-icon">
          { icon }
        </div>
      ) }

      <div className="item">
        <h5 className="item-title">{ title }</h5>
        <p className="item-subtitle">{ subtitle }</p>
      </div>

      <Arrow className="item-arrow" />
    </UserRoleNavLink>
  )
}

ItemCard.propTypes = {
  subtitle: string.isRequired,
  title: string.isRequired,
  to: string.isRequired,
  disabled: bool,
  icon: node,
}

ItemCard.defaultProps = {
  disabled: false,
  icon: null
}

export default styled(ItemCard)`
  align-items: center;
  background: #FFF;
  border: 1px solid #e6eaee;
  border-radius: 0.3125rem;
  display: flex;
  margin-bottom: 0.625rem;
  padding: 1.4375rem;
  width: 50%;
  transition: border-color .2s ease;
  
  &:hover {
    text-decoration: none;
    border-color: #cfcfd0;
  }
  
  .item-icon {
    margin-top: -.25rem;
    margin-right: 1rem
  }
  
  .item-title {
    color: #1a173b;
    font-size: 0.9375rem;
    margin-bottom: 0.375rem
  }

  .item-subtitle {
    color: #637280;
    font-size: 0.875rem;
    margin-bottom: 0
  }
  
  .item-arrow {
    display: block;
    margin-left: auto;
    opacity: .25;
    transition: opacity .2s ease;
    width: .85rem
  }
  
  &:hover .item-arrow {
    opacity: .5
  }
`

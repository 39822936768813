import React       from 'react'
import { IconAdd } from 'cabanaico'
import styled      from 'styled-components'
import { bool, node }    from 'prop-types'

import Button from 'shared/components/ui/Button'

const NewItemButton = ({ children, displayIcon, ...props }) => {
  return (
    <Button { ...props } type="button">
      { displayIcon && <IconAdd /> }
      { children }
    </Button>
  )
}

NewItemButton.propTypes = {
  children: node.isRequired,
  displayIcon: bool
}

NewItemButton.defaultProps = {
  displayIcon: true
}

export default styled(NewItemButton)`
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: .3rem .5rem
`

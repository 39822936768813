import React, { createContext, useState } from 'react'

import Table from 'shared/components/ui/Table'

import DocumentsModal from '../../DocumentsModal'

import compileColumnsConfiguration from './columns'
import projectUnitsType from './projectUnitsType'

const UnitsTableContext = createContext({
  showModal: false,
  setShowModal: () => {},
  currentUnitUuid: null,
  setCurrentUnitUuid: () => {},
  currentUnitName: '',
  setCurrentUnitName: () => {}
})

const UnitsTable = ({ unitsData }) => {
  const units = unitsData.currentStageUnits

  const columnsConfiguration = compileColumnsConfiguration(
    { hideDocumentsColumn: !unitsData.vitecDocumentsPresentOnCurrentStage }
  )

  const tableOptions = {
    data: units,
    columns: columnsConfiguration,
    defaultSorted: [],
    bordered: false,
    keyField: 'uuid',
    remote: { pagination: false }
  }

  const SIZE_PER_PAGE = 5

  const paginationOptions = units.length > SIZE_PER_PAGE ? ({
    sizePerPage: SIZE_PER_PAGE,
    totalSize: units.length,
  }) : null

  const [showModal, setShowModal] = useState(false)
  const [currentUnitUuid, setCurrentUnitUuid] = useState(null)
  const [currentUnitName, setCurrentUnitName] = useState('')

  const modalState = {
    showModal,
    setShowModal,
    currentUnitUuid,
    setCurrentUnitUuid,
    currentUnitName,
    setCurrentUnitName
  }

  return (
    <UnitsTableContext.Provider value={ modalState }>
      <Table
        tableOptions={ tableOptions }
        paginationOptions={ paginationOptions }
      />
      <DocumentsModal />
    </UnitsTableContext.Provider>
  )
}

UnitsTable.propTypes = {
  unitsData: projectUnitsType.isRequired
}

export { UnitsTable, UnitsTableContext }

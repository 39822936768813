import attachFileMutation from 'graphql/mutations/assetable/attachFile'

import projectQuery from '../projectQuery'

import updateProjectAssetsCache from './updateProjectAssetsCache'

const attachFileToProject = ({ client, blobId, projectUuid, mediaType, refetchQueries = [] }) => {
  const assetableUuid = projectUuid
  const assetableType = 'Project'

  return client.mutate({
    mutation: attachFileMutation,
    variables: { blobId, assetableUuid, assetableType, mediaType },
    refetchQueries: [{
      query: projectQuery,
      variables: { uuid: assetableUuid }
    }, ...refetchQueries],
    update: (store, {
      data: {
        attachFileToAssetable: { assetAssignment }
      }
    }) => updateProjectAssetsCache({ store, assetableUuid, assetAssignment })
  })
}

export default attachFileToProject

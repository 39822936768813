import { resetPassword } from 'shared/helpers/auth'
import { pickErrors }    from 'shared/helpers/errors'

export default function createOnSubmit(onEmailSent) {
  return function onSubmit(values, { setSubmitting, setErrors }) {
    setSubmitting(true)

    resetPassword(values)
      .then(() => onEmailSent(values.email))
      .catch((error) => {
        setErrors(pickErrors(error, ['email']))
      })
      .finally(() => setSubmitting(false))
  }
}

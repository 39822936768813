import updateLandingPageMutation from 'graphql/mutations/campaign/updateLandingPage'

const createLandingPage = (client, landingPage, input) => (
  client.mutate({
    mutation: updateLandingPageMutation,
    variables: { uuid: landingPage.uuid, input }
  })
)

export default createLandingPage

import React          from 'react'
import { t }          from 'ttag'
import styled         from 'styled-components'
import useReactRouter from 'use-react-router'

import { userRolePath }      from 'routes/AppRedirect'
import useCreateLead         from 'shared/hooks/useCreateLead'
import { cardBoxShadow }     from 'shared/style/placeholders'
import { buttonBorderColor } from 'shared/style/colors'
import LeadForm              from 'shared/components/LeadForm'
import { Header }            from 'shared/components/LeadForm/elements'

const NewLead = ({ className }) => {
  const { history } = useReactRouter()
  const { createLead } = useCreateLead()

  const handleSubmit = (input) => {
    createLead({ variables: { input } }).then(() => {
      history.push(userRolePath('/leads'))
    })
  }

  return (
    <div className={ className }>
      <Header>{ t`Create new lead` }</Header>

      <LeadForm
        onHide={ history.goBack }
        onSubmit={ handleSubmit }
      />
    </div>
  )
}

NewLead.propTypes = {
}

export default styled(NewLead)`
  ${cardBoxShadow};

  width: 50%;
  margin: 0 auto;
  background: #FFF;
  border-radius: 4px;
  
  ${Header} {
    padding: 1rem 1rem;
    border-bottom: 1px solid ${buttonBorderColor};
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
`

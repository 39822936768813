import React from 'react'
import { Redirect } from 'react-router-dom'

import { getUser } from 'shared/helpers/auth'

export default function withAuth(Page) {
  return function PageWithAuth (props) {
    const user = getUser()

    return (
      user
        ? <Page { ...props } user={ user } />
        : <Redirect to="/login" />
    )
  }
}

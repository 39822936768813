import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'
import { array, func, bool } from 'prop-types'

import NewItemButton       from 'shared/components/ui/NewItemButton'
import { isUserAdmin }     from 'shared/helpers/auth'

import Company from './Company'

const Companies = ({
  className,
  showLogo,
  companies,
  updateCompanyUuid,
  updateCompanyCategory,
  addCompany,
  deleteCompany
}) => {
  if (companies === undefined) return null

  const selectedCompaniesUuids = companies.map(company => company.uuid)
  const isAdmin = isUserAdmin()

  const renderCompanies = () => {
    return companies.map((company) => {
      return (
        <Company
          key={ company.uuid }
          showLogo={ showLogo }
          isAdmin={ isAdmin }
          company={ company }
          selectedCompaniesUuids={ selectedCompaniesUuids }
          updateCompanyUuid={ updateCompanyUuid }
          updateCompanyCategory={ updateCompanyCategory }
          deleteCompany={ deleteCompany }
        />
      )
    })
  }

  return (
    <div className={ className }>
      {renderCompanies()}

      {isAdmin && (
        <NewItemButton onClick={ addCompany } data-test-id='add-new-company-button'>
          { t`Add new company` }
        </NewItemButton>
      )}
    </div>
  )
}

Companies.propTypes = {
  addCompany: func.isRequired,
  companies: array.isRequired,
  deleteCompany: func.isRequired,
  updateCompanyCategory: func.isRequired,
  updateCompanyUuid: func.isRequired,
  showLogo: bool
}

Companies.defaultProps = {
  showLogo: false
}

export default styled(Companies)`
  button {
    margin: .5rem 0 1rem;
  }
`

import React from 'react'
import { number, string, oneOfType } from 'prop-types'

import { formatPrice } from 'shared/helpers/currency'

const Price = ({ price, currency }) => {
  return (
    <span>
      { formatPrice(parseFloat(price), currency) }
    </span>
  )
}

Price.propTypes = {
  currency: string.isRequired,
  price: oneOfType([string, number]).isRequired
}

export default Price

import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import LoadingBlock from 'shared/components/ui/LoadingBlock'
import matchType from 'shared/types/matchType'
import Lead from 'shared/components/Lead'
import leadQuery from 'graphql/queries/lead'

const LeadPage = ({ match }) => {
  const { uuid } = match.params
  const { data, loading, error } = useQuery(leadQuery, { variables: { uuid } })

  if (loading) return <LoadingBlock />

  if (error) return null

  return <Lead lead={ data.lead } />
}

LeadPage.propTypes = {
  match: matchType.isRequired
}

export default LeadPage

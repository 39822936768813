import { gql } from 'apollo-boost'

import campaign from 'graphql/fragments/campaign'

export default gql`
  mutation createFacebookAudienceLocation(
    $audienceUuid: ID!
    $fbId: String!
    $operator: String!
    $locationType: String!
    $radius: Float
  ) {
    createFacebookAudienceLocation(
      audienceUuid: $audienceUuid
      fbId: $fbId
      radius: $radius
      operator: $operator
      locationType: $locationType
    ) {
      audienceLocation {
        uuid
        latitude
        longitude
        radius
        operator
        country
        placeName
        title
        locationType
      }
      campaign {
        ... Campaign
      }
    }
  }
  ${campaign}
`

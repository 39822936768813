import React, { useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'

const GoalNumberField = ({ ...props }) => {
  const input = useRef(null)

  useEffect(() => {
    input?.current?.focus?.()
  }, [])

  return (
    <Form.Control
      name="goalNumber"
      type="number"
      ref={ input }
      data-test-id='goal-input'
      { ...props }
    />
  )
}

export default GoalNumberField

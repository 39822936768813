import { gql } from 'apollo-boost'

export default gql`
  mutation deleteProductStakeholder(
    $productUuid: ID!
    $uuid: ID!
  ) {
    deleteProductStakeholder(
      productUuid: $productUuid
      uuid: $uuid
    ) {
      stakeholder {
        uuid
      }
    }
  }
`

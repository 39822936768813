import React from 'react'

import matchType    from 'shared/types/matchType'
import CampaignChangelogs from 'shared/components/CampaignChangelogs'

const CampaignChangelogsPage = ({ match }) => (
  <CampaignChangelogs uuid={ match.params.uuid } />
)

CampaignChangelogsPage.propTypes = {
  match: matchType.isRequired
}

export default CampaignChangelogsPage

import { gql } from 'apollo-boost'

import stageUnit from 'graphql/fragments/stageUnit'

export default gql`
  mutation updateStageUnit(
    $uuid: ID!
    $attributes: UnitAttributes!
  ) {
    updateStageUnit(
      uuid: $uuid
      attributes: $attributes
    ) {
      unit {
        ... StageUnit
      }
    }
  }
  ${stageUnit}
`

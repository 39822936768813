import React, { useEffect, useCallback, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { array, func, string } from 'prop-types'
import { get } from 'lodash'

import MultiSelect from 'shared/components/ui/MultiSelect/MultiSelect'
import stageQuery from 'graphql/queries/stage'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

import ValueContainer from './ValueContainer'
import Option from './Option'

const styles = {
  ...condensedSelectStyles,
  control: provided => ({
    ...condensedSelectStyles.control(provided),
    minWidth: '12rem',
    flexGrow: 1
  }),
  option: provided => ({
    ...condensedSelectStyles.option(provided),
    backgroundColor: 'white',
    padding: '0.125rem 0.75rem'
  })
}

const UnitsSelect = ({ stageUuid, value, onChange, ...props }) => {
  const previousStageUuid = useRef(stageUuid)

  const { loading, data } = useQuery(stageQuery, {
    variables: { uuid: stageUuid },
    skip: !stageUuid,
    fetchPolicy: 'network-only'
  })
  const units = loading ? [] : get(data, 'stage.units', [])

  const options = units
    .filter(unit => unit.unitId)
    .map(unit => ({
      value: unit.uuid,
      label: unit.unitId
    }))

  const selectedOptions = value.map(uuid =>
    options.find(({ value }) => value === uuid)
  )

  const handleChange = useCallback(
    options => onChange(options.map(option => option.value)),
    [onChange]
  )

  useEffect(() => {
    if (previousStageUuid.current !== stageUuid) {
      previousStageUuid.current = stageUuid
      handleChange([])
    }
  }, [stageUuid, handleChange])

  return (
    <div data-test-id='unit-select-wrapper'>
      <MultiSelect
        isDisabled={ !stageUuid }
        options={ options }
        value={ selectedOptions }
        onChange={ handleChange }
        components={{ ValueContainer, Option }}
        styles={ styles }
        hideSelectedOptions={ false }
        { ...props } />
    </div>
  )
}

UnitsSelect.propTypes = {
  onChange: func,
  stageUuid: string,
  value: array,
}

UnitsSelect.defaultProps = {
  value: [],
  onChange: () => {},
  stageUuid: null
}

export default UnitsSelect

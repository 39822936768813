import { gql } from 'apollo-boost'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  query Campaign($uuid: ID!) {
    campaign(uuid: $uuid) {
      uuid
      assetAssignments {
        ... AssetAssignment
      }
      project {
        uuid
        assetAssignments {
          ... AssetAssignment
        }
      }
      stage {
        assetAssignments {
          ... AssetAssignment
        }
      }
    }
  }
  ${assetAssignment}
`

import React from 'react'
import { t, ngettext, msgid } from 'ttag'
import styled from 'styled-components'
import { includes } from 'lodash'
import { FormControl } from 'react-bootstrap'
import { string, func, arrayOf, number, shape, array, bool, object } from 'prop-types'

import { channelPresentation } from 'shared/constants/channelPresentation'
import { formInputShadow } from 'shared/style/placeholders'
import { buttonTextColor, greyishBrownColor } from 'shared/style/colors'
import { combineErrors } from 'shared/helpers/inputValidation'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import InputFeedback from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'
import AudiencesSelect from 'shared/components/CampaignInfo/AudiencesSelect'

import Label from '../Label'

import BannerTemplateCollectionSelect from './BannerTemplateCollectionSelect'

const CreativeSetFormView = (props) => {
  const {
    className,
    uuid,
    name,
    title,
    content,
    adsCount,
    description,
    allAudiences,
    bannerTemplateCollection,
    setBannerTemplateCollection,
    bannerTemplateCollections,
    onAudiencesSet,
    onNameUpdate,
    onTitleUpdate,
    onTitleBlur,
    onContentUpdate,
    onContentBlur,
    onDescriptionUpdate,
    onDescriptionBlur,
    validate,
    errors,
    gmpContentErrors,
    isPredefined,
    creativeSet,
    campaign
  } = props

  const errorsOnName = combineErrors({ errors, uuid, field: 'name' })
  const errorsOnAudiences = combineErrors({ errors, uuid, field: 'creativeSetAudiences' })

  const errorsOnTitle = combineErrors({ errors: gmpContentErrors, uuid, field: 'title' })
  const errorsOnContent = combineErrors({ errors: gmpContentErrors, uuid, field: 'content' })

  const handleTitleBlur = (event) => onTitleBlur(event.target.value)
  const handleContentBlur = (event) => onContentBlur(event.target.value)
  const handleDescriptionBlur = (event) => onDescriptionBlur(event.target.value)

  const handleNameUpdate = (args) => onNameUpdate(args)

  const handleAudiencesSet = (value) => onAudiencesSet(value).then((creativeSets) => {
    if(validate) {
      validate({ payload: creativeSets })
    }
  })

  const selectedAudiences = creativeSet.creativeSetAudiences.map((creativeSetAudience) => {
    return { uuid: creativeSetAudience.audience.uuid, name: creativeSetAudience.audience.name }
  })

  const promotableType = campaign?.promotableType
  const creativeSetType = creativeSet.type

  const showBannerTemplateCollections = (
    promotableType === 'AgentPromotion'
    || (creativeSetType === 'gmp' && !isPredefined)
  ) && bannerTemplateCollections.length > 1

  const isFacebookTypeChannel = includes(
    ['facebook', 'portal', 'facebook_lead_ad', 'facebook_dynamic_creative'],
    creativeSetType
  )

  const filteredAudiences = allAudiences.filter(audience => {
    if (creativeSet.type !== 'facebook_dynamic_creative') {
      return true
    }

    const isAttached = audience.creativeSetAudiences.length > 0
    const isAttachedToCurrent = audience.creativeSetAudiences
      .map(csa => csa.creativeSetUuid).indexOf(creativeSet.uuid) > -1
    
    return !isAttached || isAttachedToCurrent
  })

  return (
    <div className={ className }>
      <div className="summary" data-test-id="creative-set-summary">
        { channelPresentation[creativeSetType] } - { ngettext(
          msgid`${adsCount} ad`,
          `${adsCount} ads`,
          adsCount
        ) }
      </div>

      <div className="name-audience">
        <InputWrapper
          error={ errorsOnName }
          className='adset-name-wrapper'
        >
          <ThriftyInput
            value={ name }
            placeholder={ t`Creative set name` }
            onUpdate={ handleNameUpdate }
            data-test-id='creative-set-name-input'
          />
        </InputWrapper>

        <InputWrapper
          error={ errorsOnAudiences }
          className='audience-select-wrapper audience'
          data-test-id='audience-select-wrapper'
        >
          <AudiencesSelect
            onChange={ handleAudiencesSet }
            audiences={ selectedAudiences }
            allAudiences={ filteredAudiences }
          />
        </InputWrapper>
      </div>

      <div className="inputs">
        { showBannerTemplateCollections && (
          <>
            <BannerTemplateCollectionSelect
              promotableType={ promotableType }
              creativeSetUuid={ uuid }
              creativeSet={ creativeSet }
              usedBannerTemplateCollection={ bannerTemplateCollection }
              bannerTemplateCollections={ bannerTemplateCollections }
              setBannerTemplateCollection={ setBannerTemplateCollection }
              campaign={ campaign }
            />
          </>
        ) }

        { creativeSetType !== 'instagram' && !isPredefined && (
          <InputFeedback error={ errorsOnTitle?.errorMessage }>
            <Label>{ t`Title` }</Label>
            <FormControl
              defaultValue={ title }
              isInvalid={ !!errorsOnTitle }
              onBlur={ handleTitleBlur }
              onChange={ onTitleUpdate }
              data-test-id='title-input'
            />
          </InputFeedback>
        ) }

        { isFacebookTypeChannel && (
          <>
            <Label>{ t`Description` }</Label>
            <FormControl
              rows="2"
              as="textarea"
              defaultValue={ description }
              onBlur={ handleDescriptionBlur }
              onChange={ onDescriptionUpdate }
              data-test-id='description-input'
            />
          </>
        ) }

        { !isPredefined && (
          <InputFeedback error={ errorsOnContent?.errorMessage }>
            <Label>{ t`Main text` }</Label>
            <FormControl
              rows="3"
              as="textarea"
              isInvalid={ !!errorsOnContent }
              defaultValue={ content }
              onBlur={ handleContentBlur }
              onChange={ onContentUpdate }
              data-test-id='main-text-input'
            />
          </InputFeedback>
        ) }
      </div>
    </div>
  )
}

CreativeSetFormView.propTypes = {
  allAudiences: arrayOf(shape({
    name: string,
    uuid: string
  })).isRequired,
  campaign: object.isRequired,
  creativeSet: object.isRequired,
  isPredefined: bool.isRequired,
  onAudiencesSet: func.isRequired,
  onContentBlur: func.isRequired,
  onContentUpdate: func.isRequired,
  onDescriptionBlur: func.isRequired,
  onDescriptionUpdate: func.isRequired,
  onNameUpdate: func.isRequired,
  onTitleBlur: func.isRequired,
  onTitleUpdate: func.isRequired,
  setBannerTemplateCollection: func.isRequired,
  uuid: string.isRequired,
  adsCount: number,
  bannerTemplateCollection: shape({
    name: string,
    uuid: string
  }),
  bannerTemplateCollections: arrayOf(shape({
    name: string,
    uuid: string
  })),
  content: string,
  description: string,
  errors: array,
  gmpContentErrors: array,
  name: string,
  title: string,
  validate: func
}

CreativeSetFormView.defaultProps = {
  adsCount: 0,
  bannerTemplateCollection: null,
  bannerTemplateCollections: [],
  content: '',
  description: '',
  gmpContentErrors: [],
  name: '',
  title: '',
  validate: undefined,
  errors: []
}

export default styled(CreativeSetFormView)`
  input, textarea {
    border: solid 1px #dbdbdb
  }

  input, textarea, [class$=control] {
    color: ${buttonTextColor}
  }

  .form-control {
    box-shadow: none !important;
    font-size: 0.875rem !important;
    padding: 0.225rem 0.5rem !important
  }

  .form-control {
    ${formInputShadow};
    height: auto;
    box-shadow: none !important;
  }

  [class$=indicatorContainer]:first-child {
    padding: 0 .25rem
  }

  [class$=placeholder] {
    color: ${buttonTextColor}
  }

  .name-audience {
    display: flex;

    .audience-select-wrapper {
      margin-left: .75rem;
      position: relative;
      box-sizing: border-box;
      -webkit-box-flex: 1;
      flex-grow: 1;
      width: 100%;
    }

    .adset-name-wrapper {
      height: auto;
      width: 100%
    }
  }

  .summary {
    color: ${greyishBrownColor};
    font-size: 0.875rem;
    font-style: italic;
    margin-bottom: 1.25rem
  }

  .inputs {
    margin-top: 1rem
  }
`

import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import { string, array } from 'prop-types'
import { Alert } from 'react-bootstrap'
import { t } from 'ttag'
import styled from 'styled-components'

import facilityDetailsQuery from 'graphql/queries/facilities/details'
import Button from 'shared/components/ui/Button'
import Modal from 'shared/components/ui/Modal'

import FacilityDetail from './FacilityDetail'

const FacilityDetails = ({ className, projectUuid, facilities }) => {
  const [showModal, setShowModal] = useState(false)
  const { data } = useQuery(facilityDetailsQuery, { variables: { projectUuid } })
  const useFacilityUuids = facilities.map(v => v.uuid)
  const displayFacilities = data
    ? data.facilityDetails.filter(val => useFacilityUuids.includes(val.uuid)) : []
  const hideModal = () => {
    setShowModal(false)
  }

  return (
    <div className={ className }>
      <Modal
        show={ showModal }
        onHide={ hideModal }
        size='lg'
      >
        <Modal.Header>
          { t`Edit facility descriptions` }
        </Modal.Header>
        <Modal.Body>
          <Alert variant='secondary' className='info-alert'>
            <span className='mr-2'>ⓘ</span>
            {/* eslint-disable-next-line max-len */}
            { t`Facility descriptions are assigned directly to the project, they are available for all sale states` }
          </Alert>
          { displayFacilities.map(facilityDetail => {
            return (
              <FacilityDetail
                facilityDetail={ facilityDetail }
                projectUuid={ projectUuid }
                className='w-100'
                key={ facilityDetail.uuid } />
            )
          }) }
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={ hideModal }>{ t`Close` }</Button>
        </Modal.Footer>
      </Modal>
      <Button className='edit-btn' onClick={ () => setShowModal(true) }>
        { t`Edit facility descriptions` }
      </Button>
    </div>
  )
}

FacilityDetails.propTypes = {
  facilities: array.isRequired,
  projectUuid: string.isRequired
}

export default styled(FacilityDetails)`
  .edit-btn {
    margin: .7rem 0;
  }
`

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { useApolloClient, useQuery } from 'react-apollo'
import { Dropdown } from 'react-bootstrap'
import { array, shape, arrayOf, func } from 'prop-types'
import { toast } from 'react-toastify'

import landingPageType from 'shared/types/landingPageType'
import campaignCreativeSetsQuery from 'graphql/queries/campaignCreativeSets'
import { productionPhases } from 'shared/constants/campaigns/phases'
import LoadingBlock from 'shared/components/ui/LoadingBlock'

import { withPopulatedTexts } from '../PopulateTexts'

import LandingPage from './LandingPage'
import DropdownToggle from './DropdownToggle'
import createLandingPage from './createLandingPage'
import buildLandingPagesValidationPayload from './buildLandingPagesValidationPayload'
import removeLandingPage from './removeLandingPage'
import revalidateLandingPages from './revalidateLandingPages'
import cloneLandingPage from './cloneLandingPage'

const LandingPages = ({ className, campaign, onLoaded, errors, validate, ...props }) => {
  const { data, loading } = useQuery(
    campaignCreativeSetsQuery, { variables: { campaignUuid: campaign.uuid } }
  )
  const creativeSets = data ? data.campaignCreativeSets : []

  const { landingPages } = campaign
  const client = useApolloClient()
  const campaignUuid = campaign.uuid
  const setValidatablePayload = props.setValidatablePayload

  useEffect(() => {
    if(!loading && onLoaded) {
      onLoaded()
    }
  }, [loading])

  useEffect(
    () => {
      revalidateLandingPages({ client, campaignUuid, creativeSets, validate })

      if(setValidatablePayload) {
        setValidatablePayload(
          buildLandingPagesValidationPayload({ creativeSets, landingPages })
        )
      }
    },
    [JSON.stringify(creativeSets), JSON.stringify(landingPages)]
  )

  const handleAddAsTemplate = () => createLandingPage(client, campaign, {})
  const handleAddCustom = () => createLandingPage(client, campaign, { subtype: 'custom' })

  const handleRemove = uuid => removeLandingPage(client, campaign, uuid)

  const handleClone = uuid => {
    cloneLandingPage(client, campaign, uuid)
    toast.success(t`Landing page has been cloned`)
  }

  if (loading) return <LoadingBlock />

  return (
    <div className={ className }>
      <Dropdown alignRight as={ DropdownWrapper }>
        <Dropdown.Toggle as={ DropdownToggle } />
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={ handleAddAsTemplate }
            data-test-id='new-template-item'
          >
            { t`Template` }
          </Dropdown.Item>
          <Dropdown.Item
            onClick={ handleAddCustom }
            data-test-id='new-custom-item'
          >
            { t`Custom website` }
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      { landingPages.map(el => (
        <div
          data-test-id='landing-page-wrapper'
          key={ el.uuid }
        >
          <LandingPage
            campaign={ campaign }
            publishable={ productionPhases.includes(campaign.phase) }
            landingPage={ el }
            creativeSets={ creativeSets }
            onRemove={ handleRemove }
            onClone={ handleClone }
            errors={ errors }
            validate={ validate }
            { ...props }
          />
        </div>
      ))}
    </div>
  )
}

LandingPages.propTypes = {
  campaign: shape({
    landingPages: arrayOf(landingPageType)
  }).isRequired,
  errors: array,
  onLoaded: func,
  setValidatablePayload: func,
  validate: func
}

LandingPages.defaultProps = {
  errors: [],
  onLoaded: undefined,
  setValidatablePayload: undefined,
  validate: undefined
}

const DropdownWrapper = styled.span`
  align-self: flex-end;
`

export default styled(withPopulatedTexts(LandingPages))`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`

import { useApolloClient } from '@apollo/react-hooks'

import redirectTo from 'shared/helpers/redirectTo'

import createBuildingMutation from './createBuildingMutation'

export default ({ projectUuid }) => {
  const client = useApolloClient()

  client.mutate({
    mutation: createBuildingMutation,
    variables: {
      projectUuid: projectUuid
    },
    update: (_cache, { data: { createBuilding: { building } } }) => {
      redirectTo(`/buildings/${building.uuid}/new`)
    }
  })

  return null
}

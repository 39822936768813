import React from 'react'
import { shape, string } from 'prop-types'

import propertyType from 'shared/types/propertyType'

import Websites from '../Websites'
import Amenities from '../Amenities'

import LeftColumn from './LeftColumn'
import RightColumn from './RightColumn'

const PropertyDetails = ({ className, product: { promotable } }) => {
  return (
    <div className={ className }>
      <div className="details-columns">
        <aside>
          <LeftColumn promotable={ promotable } />
        </aside>
        <aside>
          <RightColumn promotable={ promotable }/>
        </aside>
      </div>

      <div className="details-columns">
        <aside>
          <Websites main="https://STUB.com" secondaries={ [ 'https://STUB2.com' ] } />
        </aside>
      </div>

      <Amenities amenities={ promotable.facilities }/>
    </div>
  )
}

PropertyDetails.propTypes = {
  className: string.isRequired,
  product: shape({
    promotable: propertyType.isRequired,
  }).isRequired
}

export default PropertyDetails

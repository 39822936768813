import React  from 'react'
import { t }  from 'ttag'
import Select from 'react-select'
import { Form, Row, Col } from 'react-bootstrap'
import { array, func } from 'prop-types'

import CompanySelect from 'shared/components/CompanySelect'
import NewItemButton from 'shared/components/ui/NewItemButton'

import companyHandlers from './companyHandlers'

const categories = [
  { label: t`Developer`, value: 0 },
  { label: t`Agent`, value: 1 },
  { label: t`Marketing`, value: 2 },
  { label: t`Broker`, value: 3 }
]

const Companies = ({ companies, setCompanies }) => {
  const {
    addCompany,
    updateCompanyUuid,
    updateCompanyCategory
  } = companyHandlers(companies, setCompanies)

  const renderTitle = (index) => {
    if (index === 0) return t`Main organization`
    return t`Organization`
  }

  const selectedCompaniesUuids = () => {
    return companies.map((company) => {
      return company.uuid
    })
  }

  const renderCompanies = () => {
    return companies.map((company, index) => {
      return (
        <Form.Group as={ Row } key={ company.uuid }>
          <Form.Label column sm="3">{ renderTitle(index) }</Form.Label>
          <Col sm="5" data-test-id='company'>
            <CompanySelect
              name="companyUuid"
              value={ company.uuid }
              onChange={ ({ target: { value: value } }) => updateCompanyUuid(company, value) }
              selectedCompaniesUuids={ selectedCompaniesUuids() }
            />
          </Col>
          <Col sm="4">
            <Select
              placeholder={ t`Category` }
              value={ company.category }
              onChange={ (value) => updateCompanyCategory(company, value) }
              options={ categories }
            />
          </Col>
        </Form.Group>
      )
    })
  }

  return (
    <div>
      {renderCompanies()}

      <NewItemButton onClick={ addCompany } data-test-id='add-new-company-button'>
        { t`Add new company` }
      </NewItemButton>
    </div>
  )
}

Companies.propTypes = {
  companies: array.isRequired,
  setCompanies: func.isRequired
}

export default Companies

import gql from 'graphql-tag'

import stageSalesArgument from 'graphql/fragments/stageSalesArgument'

export default gql`
  mutation createStageSalesArgument(
    $stageUuid: ID!,
    $input: StageSalesArgumentInput!
  ) {
    createStageSalesArgument(
      stageUuid: $stageUuid,
      input: $input
    ) {
      salesArgument {
        ... StageSalesArgument
      }
    }
  }
  ${stageSalesArgument}
`

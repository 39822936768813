import { useApolloClient } from 'react-apollo'

import geolocationSearch from 'graphql/queries/facebook/geolocationSearch'

export default function useGeolocationSearch() {
  const client = useApolloClient()

  const search = (query) => (
    client
      .query({
        query: geolocationSearch,
        variables: { query, }
      })
      .then(
        ({ data }) => data.facebookGeolocationSearch
      )
  )

  return search
}

import React from 'react'
import { t } from 'ttag'
import { shape, bool } from 'prop-types'

import Checkbox from 'shared/components/ui/Checkbox'

import { ContactInfoContainer } from './elements'

const ContactMeans = ({ contactInfo }) => {
  return (
    <ContactInfoContainer style={{ maxWidth: '50%' }}>
      <label>{ t`Allowed means of contact` }</label>

      <Checkbox
        disabled
        checked={ contactInfo.contactByPhone }
        label={ t`Phone call` }
      />

      <Checkbox
        disabled
        checked={ contactInfo.contactByMail }
        label={ t`Mail` }
      />

      <Checkbox
        disabled
        checked={ contactInfo.contactBySms }
        label={ t`SMS` }
      />

      <Checkbox
        disabled
        checked={ contactInfo.contactByWhatsApp }
        label={ t`WhatsApp` }
      />
    </ContactInfoContainer>
  )
}

ContactMeans.propTypes = {
  contactInfo: shape({
    contactByPhone: bool,
    contactByMail: bool,
    contactBySms: bool,
    contactByWhatsApp: bool,
  })
}

ContactMeans.defaultProps = {
  contactInfo: {}
}

export default ContactMeans

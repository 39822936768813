import React from 'react'
import { string } from 'prop-types'

import ResourceQuery from 'shared/components/ResourceQuery'
import productQuery from 'shared/components/Product/productQuery'
import MainInfo from 'shared/components/Product/MainInfo'
import CustomerInfo from 'shared/components/Product/CustomerInfo'
import ProductDetails from 'shared/components/Product/ProductDetails'
import Images from 'shared/components/Product/Images'
import ProjectDetails from 'shared/components/Product/ProjectDetails'
import MarketerTeam from 'shared/components/Product/MarketerTeam'
import Timeline from 'shared/components/Product/Timeline'
import Campaigns from 'shared/components/Product/Campaigns'
import Listings from 'shared/components/Product/Listings'

const Product = ({ uuid }) => {
  return (
    <ResourceQuery query={ productQuery } variables={{ uuid }}>
      {(product) => {
        const { description, assets } = product.promotable

        const descriptions = [{ name: 'Description', description }]

        return (
          <>
            <MainInfo product={ product }/>
            <hr className="border-top-0"/>
            <CustomerInfo product={ product }/>
            <hr className="border-top-0"/>
            <ProductDetails product={ product }/>
            <hr className="border-top-0"/>
            <Images assets={ assets }/>
            <hr className="border-top-0"/>
            <ProjectDetails descriptions={ descriptions }/>
            <hr className="border-top-0"/>
            <MarketerTeam/>
            <hr className="border-top-0"/>
            <Campaigns/>
            <hr className="border-top-0"/>
            <Listings/>
            <hr className="border-top-0"/>
            <Timeline product={ product }/>
          </>
        )
      }}
    </ResourceQuery>
  )
}

Product.propTypes = {
  uuid: string.isRequired,
}

export default Product

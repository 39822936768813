import React from 'react'
import { object, func } from 'prop-types'
import { t } from 'ttag'

import DeleteButtonTag from 'shared/components/ui/DeleteButton'

const DeleteButton = ({ onDelete, user }) => {
  const handleDelete = () => {
    if (confirm(t`Are you sure you want to remove user ${user.name}?`)) {
      onDelete(user)
    }
  }

  return <DeleteButtonTag onClick={ handleDelete } />
}

DeleteButton.propTypes = {
  onDelete: func.isRequired,
  user: object.isRequired
}

export default DeleteButton

import { createGlobalStyle } from 'styled-components'

import { bluishColor } from 'shared/style/colors'
import {
  inputBoxShadow,
  cardBoxShadow,
  panelBackground
} from 'shared/style/placeholders'

export default createGlobalStyle`
  html {
    min-height: 100%
  }

  body {
    background-color: #F6F6F6
    min-height: 100vh
  }

  #app {
    min-height: 100vh
  }

  * {
    transition: background, background-color, border-color, color, box-shadow .2s ease
  }

  .card {
    ${cardBoxShadow}
  }

  .card-footer {
    ${panelBackground}
  }

  .form-control {
    ${inputBoxShadow}
    font-size: .9rem

    &.is-invalid {
      background-image: none

      &:focus {
        box-shadow: none
      }
    }
  }

  .btn-primary {
    background-color: ${bluishColor}
    border-color: ${bluishColor}
  }

  .feature-disabled {
    opacity: .5 !important
    pointer-events: none !important
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance:textfield;
  }
`

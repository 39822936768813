import React from 'react'

import { changeLocale, notUsedLocales } from 'locale'

import UserMenuAction from './UserMenuAction'

const LocalesSelector = () => {
  return (
    <>
      { notUsedLocales.map(({ key, label }) => (
        <UserMenuAction key={ key } onClick={ () => changeLocale(key) }>
          { label }
        </UserMenuAction>
      )) }
    </>
  )
}

LocalesSelector.propTypes = {
}

export default LocalesSelector

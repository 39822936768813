import React from 'react'

import matchType from 'shared/types/matchType'
import PortalSalesReportView from 'shared/components/ProjectSalesReport'

const ProjectSalesReport = ({ match }) => {
  return (
    <PortalSalesReportView uuid={ match.params.uuid } />
  )
}

ProjectSalesReport.propTypes = {
  match: matchType.isRequired
}

export default ProjectSalesReport

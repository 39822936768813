import PropTypes from 'prop-types'
import React from 'react'
import { useApolloClient } from 'react-apollo'
import { t } from 'ttag'

import Location from 'shared/components/Location'

import EmbeddedCardSection from '../../ui/EmbeddedCardSection'

import updateProject from './updateProject'

const ProjectLocation = ({ className, project }) => {
  const client = useApolloClient()
  const location = project.location || {}

  const updateLocation = (locationChanges) => {
    return updateProject(client, project, {}, locationChanges)
  }

  return (
    <EmbeddedCardSection titleText={ t`Location` } className={ className }>
      <Location
        data-test-id='location'
        location={ location }
        updateLocation={ updateLocation } />
    </EmbeddedCardSection>
  )
}

ProjectLocation.propTypes = {
  project: PropTypes.object.isRequired
}

export default ProjectLocation

import React, { useMemo } from 'react'
import { t } from 'ttag'
import { bool, array, func, oneOf, string, object } from 'prop-types'
import { OverlayTrigger } from 'react-bootstrap'

import AssetLabel     from 'shared/components/ui/AssetLabel'
import BaseGallery    from 'shared/components/Gallery/BaseGallery'
import { exactRatio } from 'shared/helpers/ratio'

import EmptyAsset          from './EmptyAsset'
import AssetContainer      from './AssetContainer'
import Header              from './Header'
import InvalidMediaPopover from './InvalidMediaPopover'
import SelectedAsset       from './SelectedAsset'
import UploadContainer     from './UploadContainer'
import DraggableAssetContainer      from './DraggableAssetContainer'

const wrongRatioPopover = (
  <InvalidMediaPopover>
    { t`Asset size does not comply predefined allowed values` }
  </InvalidMediaPopover>
)

const mediaTypeHeaders = {
  video: t`Videos`,
  banner: t`Banners`,
  image: t`Images`,
  floorplan: t`Floor plans`
}

const MediaTypeAssets = (props) => {
  const {
    assetAssignments,
    client,
    onAssetAssignmentCreate,
    onAssetAssignmentRemove,
    removedAssetAssignment,
    onAssetCreate,
    onAssetClick,
    onAssetDrop,
    mediaType,
    draggable,
    assetableType,
    channelType,
    formats,
  } = props

  const assets = useMemo(() => (
    assetAssignments.map(({ asset }) => asset)
  ), [JSON.stringify(assetAssignments)])

  const createOnClick = (asset, controls, index) => {
    if (onAssetClick) {
      // TODO: Bad decission to reveal `creativeId` here which BTW should not be part of asset.
      // Interface should be changed into: (client, asset).
      // https://github.com/marketertechnologies/marketer/issues/3858
      if (asset.uuid) {
        return () => onAssetClick(client, asset.uuid, !asset.enabled)
      } else {
        return () => onAssetClick(client, asset)
      }
    }
    return () => controls.open(index)
  }

  const AssetWrapper = draggable ? DraggableAssetContainer : AssetContainer
  const checkAllowedRatio = (asset) => {
    if (mediaType === 'video')
      return true

    if (channelType === 'predefined')
      return formats.some((format) => exactRatio(asset.sizes, [format.width, format.height]))

    if (channelType === 'instagram')
      // Temporary disabled.
      // See: https://airtable.com/tbljXPChADYp45NPR/viwUoRVBPjcH8j5jI/reckYxtuSMQ2Et52Z?blocks=hide
      // return ratioCloseToSquare(asset.sizes)
      return true

    return true
  }

  return (
    <div className={ `${mediaType}-block mb-2` }>
      <Header>{ mediaTypeHeaders[mediaType] }</Header>

      <BaseGallery assets={ assets }>
        {({ controls }) =>  (
          assetAssignments.map((assetAssignment, i) => {
            const allowedSize = checkAllowedRatio(assetAssignment.asset)

            let onClick = () => { }
            if (allowedSize) {
              onClick = createOnClick(assetAssignment.asset, controls, i)
            }

            const assetContainer = (
              <AssetWrapper
                key={ `${assetAssignment.uuid}-${assetAssignment.asset.enabled}` }
                className={ !allowedSize && 'invalid' }
                asset={ assetAssignment }
                onDrop={ onAssetDrop }
              >
                <SelectedAsset
                  onClick={ onClick }
                  previewOnClick={ () => controls.open(i) }
                  displayEnabled={ !!onAssetClick && allowedSize }
                  allowedSize={ allowedSize }
                  assetAssignment={ assetAssignment }
                  onAssetAssignmentRemove={ onAssetAssignmentRemove }
                  removedAssetAssignment={ removedAssetAssignment }
                  assetableType={ assetableType }
                />
                <AssetLabel className="asset-label" asset={ assetAssignment.asset } />
              </AssetWrapper>
            )

            const overlayTrigger = (
              <OverlayTrigger
                key={ `overlay-${assetAssignment.uuid}-${assetAssignment.asset.enabled}` }
                placement="auto"
                overlay={ !allowedSize && wrongRatioPopover }
              >
                { assetContainer }
              </OverlayTrigger>
            )

            return allowedSize ? assetContainer : overlayTrigger
          })
        )}
      </BaseGallery>

      <UploadContainer
        onAssetDrop={ onAssetAssignmentCreate }
        onFileUploaded={ onAssetCreate }
        mediaType={ mediaType }
        assetAssignments={ assetAssignments }
        channelType={ channelType }
      >
        <AssetContainer>
          <EmptyAsset clickable mediaType={ mediaType } />
        </AssetContainer>
      </UploadContainer>
    </div>
  )
}

MediaTypeAssets.propTypes = {
  assetAssignments: array.isRequired,
  client: object.isRequired,
  mediaType: oneOf(['image', 'banner', 'video', 'floorplan']).isRequired,
  onAssetAssignmentCreate: func.isRequired,
  onAssetAssignmentRemove: func.isRequired,
  onAssetCreate: func.isRequired,
  assetableType: string,
  channelType: string,
  draggable: bool,
  formats: array,
  onAssetClick: func,
  onAssetDrop: func,
  removedAssetAssignment: func
}

MediaTypeAssets.defaultProps = {
  assetableType: '',
  channelType: undefined,
  formats: [],
  onAssetClick: undefined,
  onAssetDrop: () => { },
  draggable: false,
  removedAssetAssignment: undefined
}

export default MediaTypeAssets

import { useFormikContext } from 'formik'
import { useApolloClient } from 'react-apollo'
import { get } from 'lodash'

import updateSalesArgumentMutation
  from 'graphql/mutations/projects/stages/updateSalesArgumentMutation'
import deleteSalesArgumentMutation
  from 'graphql/mutations/projects/stages/deleteSalesArgumentMutation'
import createSalesArgumentMutation
  from 'graphql/mutations/projects/stages/createSalesArgumentMutation'
import copyPreviousSalesArgumentsMutation
  from 'graphql/mutations/projects/stages/copyPreviousSalesArgumentsMutation'

import validationSchema from './validationSchema'

export default function useSalesArgumentsForm(push, remove, stageUuid) {
  const client = useApolloClient()
  const { values, setFieldValue, setFieldTouched, setValues } = useFormikContext()

  const setField = (key, attributes) => {
    setFieldValue(`salesArguments[${key}]`, attributes)
    setFieldTouched(
      `salesArguments[${key}]`,
      Object.fromEntries(Object.keys(attributes).map((touchedField) => [touchedField, true]))
    )
  }

  const updateSalesArgument = (key, input) => {
    const salesArguments = values.salesArguments.map((salesArgument, salesArgumentKey) => {
      if (salesArgumentKey !== key) return salesArgument
      return { ...salesArgument, ...input }
    })

    setField(key, salesArguments[key])

    validationSchema.validate({ salesArguments })
      .then(() => {
        client.mutate({
          mutation: updateSalesArgumentMutation,
          variables: { uuid: salesArguments[key].uuid, input }
        }).then((response) => {
          setField(key, get(response, 'data.updateStageSalesArgument.salesArgument'))
        })
      })
      .catch(() => {})
  }

  updateSalesArgument.val = values.salesArguments[3]

  const addSalesArgument = () => {
    client.mutate({
      mutation: createSalesArgumentMutation,
      variables: {
        stageUuid,
        input: {}
      }
    }).then((response) => {
      push(response.data.createStageSalesArgument.salesArgument)
    })
  }

  const deleteSalesArgument = (key) => {
    remove(key)

    if (values.salesArguments[key]) {
      client.mutate({
        mutation: deleteSalesArgumentMutation,
        variables: { uuid: values.salesArguments[key].uuid }
      })
    }
  }

  const copyPreviousSalesArguments = () => {
    client.mutate({
      mutation: copyPreviousSalesArgumentsMutation,
      variables: { stageUuid }
    }).then((response) => {
      setValues({ salesArguments: [
        ...values.salesArguments,
        ...get(response, 'data.copyPreviousSalesArguments.salesArguments')
      ] })
    })
  }

  return {
    updateSalesArgument,
    addSalesArgument,
    deleteSalesArgument,
    copyPreviousSalesArguments
  }
}

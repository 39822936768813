import React, { useCallback, useEffect, useState } from 'react'
import { object, array, bool } from 'prop-types'
import { useMutation } from 'react-apollo'

import updateStageMutation from 'graphql/mutations/projects/stages/updateStage'
import LoadingBlock from 'shared/components/ui/LoadingBlock'
import { combineErrors } from 'shared/helpers/inputValidation'

import MainDetails from './MainDetails'
import SalesStart from './SalesStart'
import Viewings from './Viewings'
import StageSaleStatus from './StageSaleStatus'
import PortalPublication from './PortalPublication'
import SalesStatusInformation from './SalesStatusInformation'
import StageStakeholders from './StageStakeholders'

const StageCreationModeContext = React.createContext(false)

const DetailsWithSnapshots = ({ stage, product, errors, creatingMode, liveValidationEnabled }) => {
  const convertExternalErrors = (errors) => {
    return errors.reduce((result, err) => {
      const keyPath = err.field.split('.')
      const finalKey = keyPath[keyPath.length-1]
      result[finalKey] = combineErrors({ errors, field: err.field, uuid: stage.uuid })
      return result
    }, {})
  }
  const [snapshots, setSnapshots] = useState(stage.snapshots)
  const [parsedErrors, setParsedErrors] = useState(() => convertExternalErrors(errors))

  useEffect(() => {
    setSnapshots(stage.snapshots)
  }, stage.snapshots)

  const [runUpdateStage] = useMutation(updateStageMutation)
  const updateStage = useCallback((input = {}, location = {}) => {
    return runUpdateStage({
      variables: {
        stageUuid: stage.uuid,
        input: input,
        location: location
      }
    })
  }, [stage])

  useEffect(() => {
    setParsedErrors(convertExternalErrors(errors))
  }, [errors])

  if (stage.snapshots.length === 0) {
    return (<LoadingBlock />)
  }

  return (
    <StageCreationModeContext.Provider value={ creatingMode }>
      <MainDetails
        stage={ stage }
        updateStage={ updateStage }
        errors={ parsedErrors }
        liveValidationEnabled={ liveValidationEnabled }
        data-test-id='main-details'
      />
      <SalesStart updateStage={ updateStage } salesStartAt={ stage.salesStartAt } />
      <Viewings stage={ stage } />
      <StageStakeholders product={ product } />
      <StageSaleStatus
        updateStage={ updateStage }
        stageUuid={ stage.uuid }
        data-test-id='stage-sales-status'
        saleState={ stage.saleState } />
      { snapshots.length
        && (<SalesStatusInformation
          snapshots={ snapshots }
          setSnapshots={ setSnapshots }
          saleState={ stage.saleState }
          currency={ stage.currency }
          data-test-id='sales-status-information'
          stage={ stage }
        />) }
      <PortalPublication
        data-test-id='portal-publication'
        portalEnabled={ stage.portalEnabled }
        updateStage={ updateStage } />
    </StageCreationModeContext.Provider>
  )
}

DetailsWithSnapshots.propTypes = {
  product: object.isRequired,
  stage: object.isRequired,
  creatingMode: bool,
  errors: array,
  liveValidationEnabled: bool
}

DetailsWithSnapshots.defaultProps = {
  creatingMode: false,
  errors: [],
  liveValidationEnabled: false
}

export { StageCreationModeContext }
export default DetailsWithSnapshots

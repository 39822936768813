import { t } from 'ttag'

import Stakeholders from '../Stakeholders'
import Details from '../Details'
import Units from '../Units'
import SalesArguments from '../SalesArguments'
import Media from '../Media'

export const originalTabs = [{
  title: t`Stakeholders`,
  component: Stakeholders
}, {
  title: t`Stage details`,
  component: Details
}, {
  title: t`Unit information`,
  component: Units
}, {
  title: t`Sales arguments`,
  component: SalesArguments
}, {
  title: t`Media`,
  component: Media
}]

export default [{
  title: t`Stage details`,
  component: Details
}, {
  title: t`Unit information`,
  component: Units
}, {
  title: t`Sales arguments`,
  component: SalesArguments
}]

import React        from 'react'
import { t }        from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import Select       from 'react-select'
import { string, any, func, object, array } from 'prop-types'

import dataToOptions from './dataToOptions'

const LoadingSelect = (props) => {
  const {
    query,
    variables,
    valueAttribute,
    labelAttribute,
    value,
    styles,
    onChange,
    placeholder,
    selectedCompaniesUuids
  } = props

  let entities = []
  let options = []

  const { data, error, loading } = useQuery(query, { variables })

  if (data && !loading && !error) {
    entities = Object.values(data)[0] || []
    options = dataToOptions(entities, valueAttribute, labelAttribute)
    if (selectedCompaniesUuids && selectedCompaniesUuids.length > 0) {
      options = options.filter((option) => {
        return option.value === value || !selectedCompaniesUuids.includes(option.value)
      })
    }
  }

  const selectedOption = options.find((option) => option.value === value)

  const handleOnChange = ({ value }) => {
    const element = entities.find(item => item.uuid === value)
    onChange({ value, element })
  }

  return (
    <Select
      isLoading={ loading }
      placeholder={ placeholder }
      value={ selectedOption }
      onChange={ handleOnChange }
      styles={ styles }
      options={ options }
      noOptionsMessage={ () => t`No options` }
    />
  )
}

LoadingSelect.propTypes = {
  onChange: func.isRequired,
  query: object.isRequired,
  labelAttribute: string,
  placeholder: string,
  selectedCompaniesUuids: array,
  styles: object,
  value: any,
  valueAttribute: string,
  variables: object,
}

LoadingSelect.defaultProps = {
  valueAttribute: 'uuid',
  labelAttribute: 'name',
  placeholder: t`Select...`,
  selectedCompaniesUuids: [],
  styles: undefined,
  value: null,
  variables: {}
}

export default LoadingSelect

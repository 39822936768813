import gql from 'graphql-tag'

import ad from './ad'
import banner from './banner'
import bannerTemplateCollection from './bannerTemplateCollection'
import landingPage from './landingPage'
import color from './color'
import creative from './creative'

export default gql`
  fragment CreativeSet on CreativeSet {
    uuid
    name
    title
    description
    content
    color
    adsCount
    channelType
    previewWithBanners
    type
    enabledCreativesLimit
    logo {
      uuid
      thumbnail
    }
    channel {
      uuid
      type
    }
    bannerTemplateCollection {
      ... BannerTemplateCollection
    }
    banners {
      ... Banner
    }
    creativeSetAudiences {
      uuid
      audience {
        uuid
        name
        adsCount
        type
      }
      ads {
        ... Ad
      }
    }
    landingPage {
      ... LandingPage
    }
    companyColors {
      ... Color
    }
    creatives {
      ... Creative
    }
  }
  ${ad}
  ${banner}
  ${bannerTemplateCollection}
  ${landingPage}
  ${color}
  ${creative}
`

import React, { useCallback, useState } from 'react'
import { t } from 'ttag'
import { useApolloClient, useMutation } from 'react-apollo'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Alert } from 'react-bootstrap'

import Switch from 'shared/components/ui/Switch'
import Button from 'shared/components/ui/Button'
import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'
import { iconsGreyColor } from 'shared/style/colors'
import credentials from 'config/credentials'
import publishProjectToPortalMutation from 'graphql/mutations/projects/publishToPortal'

import updateProject from './updateProject'

const ProjectPortalPublication = ({ className, project }) => {
  const client = useApolloClient()
  const [publishToPortal, { loading: isPublishing }] = useMutation(
    publishProjectToPortalMutation,
    { variables: { uuid: project.uuid } }
  )
  const runPublishToPortal = () => {
    return publishToPortal()
  }

  const [multistageEnabled, setMultistageEnabled] = useState(!!project.multistage)
  const [portalEnabledDraft, setPortalEnabledDraft] = useState(!!project.portalEnabledDraft)

  const onMultistageEnabledChanged = useCallback(() => {
    setMultistageEnabled(!multistageEnabled)
    updateProject(client, project,{ multistage: !multistageEnabled })
  }, [project, multistageEnabled])

  const onPortalEnabledChanged = useCallback(() => {
    setPortalEnabledDraft(!portalEnabledDraft)
    updateProject(client, project,{ portalEnabledDraft: !portalEnabledDraft })
  }, [project, portalEnabledDraft])

  const handlePreview = useCallback(() => {
    /* eslint-disable-next-line max-len */
    const previewUrl = `${credentials.portalHost}/listings/${project.uuid}/preview?sale_state=${project.saleState}`
    window.open(previewUrl, '_blank')
  }, [project.uuid, project.saleState])

  /* eslint-disable max-len */
  const handlePublish = useCallback(() => {
    const confirmationText = t`
      Please confirm that you want to publish the project.

      All information on portal and other external websites will be updated according to information provided (information on actual units is auto-published)
    `

    if (confirm(confirmationText)) {
      return runPublishToPortal()
    }
  }, [project.uuid])
  /* eslint-enable max-len */

  return (
    <EmbeddedCardSection titleText={ t`Portal publication` } className={ className }>
      { !project.portalAutoSyncEnabled && (
        <Alert variant="warning" data-test-id='portal-auto-sync-enabled-alert'>
          {
            // eslint-disable-next-line max-len
            t`Currently automatic synchronization with Portal is disabled. Manually publishing to Portal will enable it.`
          }
        </Alert>
      )}
      <div className="btn-group d-flex controls-area" role="group">
        <Switch
          className="w-100 control-item"
          data-test-id='portal-enabled-switch'
          onChange={ onPortalEnabledChanged }
          checked={ portalEnabledDraft }>
          { t`Show on portal` }
        </Switch>
        <Switch
          className="w-100 control-item"
          onChange={ onMultistageEnabledChanged }
          data-test-id='multistage-enabled-switch'
          checked={ multistageEnabled }>
          { t`Show as multistage` }
        </Switch>
        <div className="w-100 control-item">
          <div className="btn-group d-flex portal-buttons" role="group">
            <Button
              data-test-id='preview-button'
              onClick={ handlePreview }>
              { t`Preview` }
            </Button>
            <Button
              data-test-id='publish-button'
              onClick={ handlePublish }
              disabled={ isPublishing }
              variant="primary">
              { t`Publish` }
            </Button>
          </div>
        </div>
      </div>
    </EmbeddedCardSection>
  )
}

ProjectPortalPublication.propTypes = {
  project: PropTypes.object.isRequired
}

export default styled(ProjectPortalPublication)`
  .control-item {
    margin-left: 20px;

    label {
      color: ${iconsGreyColor};
    }
  }

  .radio-state {
    color: ${iconsGreyColor};
  }

  .controls-area {
    margin: 10px 0px;
    align-items: center;
  }

  .portal-buttons {
    button {
      margin-left: 5px;
    }
  }
`

import { useApolloClient } from 'react-apollo'

import campaignQuery      from 'graphql/queries/campaign'
import setCampaignPhase   from 'graphql/mutations/campaign/setCampaignPhase'

export default function useCampaignActions(phase, uuid) {
  const client = useApolloClient()

  const mergeCampaignCache = (cache, newProperties) => {
    const query = { query: campaignQuery, variables: { uuid } }
    const { campaign } = cache.readQuery(query)

    cache.writeQuery({
      ...query,
      data: {
        campaign: {
          ...campaign,
          ...newProperties
        }
      }
    })
  }

  const setPhase = (phase) => (
    client.mutate({
      mutation: setCampaignPhase,
      variables: { uuid, phase },
      update: (cache, { data: { setCampaignPhase } }) => {
        if (setCampaignPhase.errors.length) {
          // TODO: handle errors
          return
        }

        mergeCampaignCache(cache, { ...setCampaignPhase.campaign })
      }
    })
  )
  const setResumedPhase = () => setPhase('resumed')
  const setPausedPhase = () => setPhase('paused')
  const setArchivedPhase = () => setPhase('archived')
  const setUnarchivedPhase = () => setPhase('unarchived')
  const setAssemblyPhase = () => setPhase('assembly')

  return {
    setResumedPhase,
    setPausedPhase,
    setArchivedPhase,
    setUnarchivedPhase,
    setAssemblyPhase
  }
}

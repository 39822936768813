import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { string } from 'prop-types'

import {
  shuttleGray,
  campaignLiveMarkerColor,
  campaignCountdownMarkerColor,
  campaignPausedMarkerColor,
  campaignClosedMarkerColor
} from 'shared/style/colors'

const phasesPresentation = {
  assembly: t`Draft`,
  review: t`Under review`,
  scheduled: t`Countdown`,
  live: t`Live`,
  paused: t`Paused`,
  cancelled: t`Cancelled`,
  archived: t`Archived`,
  finished: t`Finished`
}

const phaseColor = {
  scheduled: campaignCountdownMarkerColor,
  live: campaignLiveMarkerColor,
  paused: campaignPausedMarkerColor,
  cancelled: campaignClosedMarkerColor
}

const CampaignPhase = ({ className, phase }) => {
  return (
    <div className={ className }>
      <Indicator phase={ phase } />
      {phasesPresentation[phase]}
    </div>
  )
}

const Indicator = styled.div`
  background-color: ${({ phase }) => phaseColor[phase] || 'grey'};
  border-radius: 100%;
  height: 0.5rem;
  margin-right: 0.3rem;
  width: 0.5rem;
`

CampaignPhase.propTypes = {
  className: string.isRequired,
  phase: string.isRequired
}

export default styled(CampaignPhase)`
  align-items: center;
  color: ${shuttleGray};
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
`

import React              from 'react'
import { t }              from 'ttag'
import { string, shape }  from 'prop-types'
import { get, camelCase } from 'lodash'

import notificationEvents from 'shared/constants/notificationEvents'
import userSettingsType   from 'shared/types/userSettingsType'
import NotificationRow from 'shared/components/Profile/Notifications/NotificationRow'
import Table           from 'shared/components/Profile/Notifications/Table'

const SettingsTable = ({ user: { uuid, settings } }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>{ t`Customize your notification settings.` }</th>
          <th>{ t`Email` }</th>
        </tr>
      </thead>
      <tbody>
        { Object.values(notificationEvents).map((event) => {
          const appEnabled = get(settings, `notifications.app.${camelCase(event.key)}`, false)
          const emailEnabled = get(settings, `notifications.email.${camelCase(event.key)}`, false)

          return (
            <NotificationRow
              { ...event }
              appEnabled={ appEnabled }
              emailEnabled={ emailEnabled }
              key={ event.key }
              uuid={ uuid }
              eventKey={ event.key }
            />
          )
        }) }
      </tbody>
    </Table>
  )
}

SettingsTable.propTypes = {
  user: shape({
    uuid: string.isRequired,
    settings: userSettingsType,
  }).isRequired,
}

export default SettingsTable

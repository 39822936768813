import React from 'react'
import { string, func, bool } from 'prop-types'

import Checkbox from 'shared/components/ui/Checkbox'

const RowCheckbox = ({ field, checked, stakeholderUuid, onSettingsUpdate }) => {
  const handleChange = (e) => (
    onSettingsUpdate({ stakeholderUuid, name: field, enabled: e.target.checked })
  )

  return (
    <Checkbox
      shadow={ false }
      checked={ checked }
      onChange={ handleChange } />
  )
}

RowCheckbox.propTypes = {
  checked: bool.isRequired,
  field: string.isRequired,
  onSettingsUpdate: func.isRequired,
  stakeholderUuid: string.isRequired,
}

export default RowCheckbox

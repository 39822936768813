import dataURItoBlob      from 'shared/helpers/files/dataURItoBlob'
import mimeTypes          from 'shared/constants/mimeTypes'
import completeFileUpload from 'shared/helpers/files/completeFileUpload'

import attachFileToProject from '../../attachFileToProject'

export default function uploadImage(
  { client, project, fileDataURI, fileMeta, mediaType, refetchQueries =[] }) {
  const file = dataURItoBlob(fileDataURI)
  const allowedMimeTypes = mimeTypes.logo

  return completeFileUpload({ client, file, allowedMimeTypes, fileMeta })
    .then(({ signedBlobId }) =>
      attachFileToProject({
        client, mediaType, blobId: signedBlobId, projectUuid: project.uuid, refetchQueries
      })
    )
}

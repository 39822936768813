import React from 'react'

import matchType    from 'shared/types/matchType'
import CampaignView from 'shared/components/Campaign'

function Campaign({ match } ) {
  return (
    <CampaignView uuid={ match.params.uuid } />
  )
}

Campaign.propTypes = {
  match: matchType.isRequired
}

export default Campaign

import React from 'react'
import { string, bool } from 'prop-types'
import styled from 'styled-components'

import productType from 'shared/types/productType'
import PropertyDetails from 'shared/components/ProductDetails/PropertyDetails'
import ProjectDetails from 'shared/components/ProductDetails/ProjectDetails'
import StageDetails from 'shared/components/ProductDetails/StageDetails'
import ReadOnlyStageDetails from 'shared/components/Project/Readonly/Stages/Stage'

const componentMapping = {
  Property: PropertyDetails,
  Project: ProjectDetails,
  Stage: StageDetails
}

const readOnlyComponentMapping = {
  Property: PropertyDetails,
  Project: ProjectDetails,
  Stage: ReadOnlyStageDetails
}

const ProductDetails = ({ className, product, readOnly, product: { promotable } }) => {
  const mapping = readOnly ? readOnlyComponentMapping : componentMapping
  const Details = mapping[product.promotableType]

  return (
    <Details
      className={ className }
      product={ product }
      promotable={ promotable }
      readOnly={ readOnly }
    />
  )
}

ProductDetails.propTypes = {
  className: string.isRequired,
  product: productType.isRequired,
  readOnly: bool
}

ProductDetails.defaultProps = {
  readOnly: false
}

export default styled(ProductDetails)`
  .details-columns {
    display: flex
    justify-content: space-between
    padding-bottom: 3rem
  }

  .details-columns aside {
    width: 43%
  }
`

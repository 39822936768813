import React from 'react'
import { t } from 'ttag'
import { array } from 'prop-types'
import styled from 'styled-components'

import MultiSelect from 'shared/components/ui/MultiSelect/MultiSelect'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import { facilityValues } from 'shared/facilityValues'

import ValueContainer from './ValueContainer'
import Option from './Option'

const components = { ValueContainer, Option }

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    flexGrow: 1
  })
}

const Data = facilityValues.map(val => val[1])

const valueToOption = element => ({
  value: Data.indexOf(element),
  label: element
})

const FacilitiesSelect = ({ className, values: selectedValues, ...props }) => {
  const selectedOptions = selectedValues.map((element) => {
    const result = Data.filter(facility => facility === element)[0]
    return {
      value: Data.indexOf(result),
      label: result
    }
  })

  return (
    <div className={ className }>
      <MultiSelect
        hideSelectedOptions={ false }
        components={ components }
        value={ selectedOptions }
        options={ Data.map(valueToOption) }
        styles={ styles }
        placeholder={ t`Select facilities` }
        noOptionsMessage={ () => t`No options` }
        { ...props }
      />
    </div>
  )
}

FacilitiesSelect.propTypes = {
  values: array.isRequired
}

export default styled(FacilitiesSelect)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

import React from 'react'
import styled from 'styled-components'
import { string, func } from 'prop-types'

import FbBusinessManagerIcons from 'shared/assets/icons/fb-business-manager.png'
import Button from 'shared/components/ui/Button'

const FitBoundsButton = ({ className, onClick }) => {
  return (
    <div className={ className }>
      <Button
        onClick={ onClick }
        className='fit-bounds-button'
      >
        <div className='fit-bounds-button-icon'/>
      </Button>
    </div>
  )
}

FitBoundsButton.propTypes = {
  className: string.isRequired,
  onClick: func.isRequired
}

export default styled(FitBoundsButton)`
  position: absolute
  top: 4.5rem
  right: 0.7rem
  z-index: 10

  .fit-bounds-button {
    width: 1.75rem
    height: 1.75rem
    padding: 0.1rem 0.4rem
    background-color: rgb(249, 249, 249);
    box-shadow: rgba(0, 0, 0, 0.3) 0rem 0.063rem 0.25rem;
  }

  .fit-bounds-button:focus {
    outline: none
  }

  div.fit-bounds-button-icon {
    width: 0.875rem
    height: 0.875rem
    background: url(${FbBusinessManagerIcons}) no-repeat center;
    background-position: -1.25rem -23rem;
    background-size: 2.188rem 28.875rem;
  }
`

import React from 'react'
import styled from 'styled-components'
import { useDrag } from 'react-dnd'

import assetType from 'shared/types/assetType'
import Asset from 'shared/components/ui/Asset'
import { mediaTypeDropEvents } from 'shared/components/Media/mediaTypes'

const DraggableAsset = (props) => {
  const [{ isDragging }, drag] = useDrag({
    item: { asset: props.asset, type: mediaTypeDropEvents[props.asset.mediaType] },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  return (
    <AssetWrapper isDragging={ isDragging } ref={ drag }>
      <Asset { ...props }  />
    </AssetWrapper>
  )
}

DraggableAsset.propTypes = {
  asset: assetType.isRequired,
}

const AssetWrapper = styled.div`
  opacity: ${({ isDragging }) => isDragging ? '.5' : '1'}
`

export default DraggableAsset

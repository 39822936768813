import React from 'react'
import styled from 'styled-components'
import { components } from 'react-select'
import { array, shape } from 'prop-types'

const ValueLabel = styled.span`
  font-size: 0.75rem;
  white-space: nowrap;
`

const ValueContainer = props => {
  const {
    selectProps: { value }
  } = props

  const label = (value || []).map(({ label }) => label).join(', ')

  return (
    <components.ValueContainer { ...props }>
      <ValueLabel>{label}</ValueLabel>
    </components.ValueContainer>
  )
}

ValueContainer.propTypes = {
  selectProps: shape({
    value: array
  }).isRequired
}

export default ValueContainer

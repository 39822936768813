import React from 'react'
import styled from 'styled-components'
import { object } from 'prop-types'

import stageQuery from 'graphql/queries/stage'
import ResourceQuery from 'shared/components/ResourceQuery'
import useTabBar from 'shared/hooks/useTabBar'

import tabs from './tabs'

const StageDetails = ({ stage }) => {
  const { tabBar, CurrentComponent } = useTabBar(tabs)

  return (
    <Container>
      { tabBar }

      <ResourceQuery
        query={ stageQuery }
        variables={{ uuid: stage.uuid }}
        fetchPolicy='cache-first'
      >
        {stage => (
          <CurrentComponent product={ stage.product } />
        )}
      </ResourceQuery>
    </Container>
  )
}

const Container = styled.div`
  margin-top: -1.1rem;
  margin-left: -1.45rem;
  margin-right: -1.45rem;

  .table {
    margin-bottom: 0;
  }
`

StageDetails.propTypes = {
  stage: object.isRequired
}

export default StageDetails

import React  from 'react'
import { func } from 'prop-types'
import { Query } from 'react-apollo'

import LoadingCard from 'shared/components/ui/LoadingCard'
import NotFound from 'shared/components/pages/NotFound'

const ResourceQuery = ({ children, ...queryProps }) => (
  <Query { ...queryProps }>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <LoadingCard />
        )
      }

      if (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return null
      }

      const resource = Object.values(data)[0]

      if (resource === null) {
        return (
          <NotFound />
        )
      }

      return (
        children(resource)
      )
    }}
  </Query>
)

ResourceQuery.propTypes = {
  children: func.isRequired
}

export default ResourceQuery

import React, { useEffect }      from 'react'
import { oneOf }  from 'prop-types'
import { Formik } from 'formik'
import { toast } from 'react-toastify'

import redirectTo         from 'shared/helpers/redirectTo'
import { canRead, login, isUserAdmin } from 'shared/helpers/auth'

import validationSchema from './validationSchema'
import FormView         from './FormView'

const LoginForm = ({ userType }) => {
  const defaultPath = () => {
    return !isUserAdmin() && canRead('sales_reports') ? '/sales_reports' : '/'
  }

  useEffect(() => {
    if (window.marketer?.alert) toast.error(window.marketer.alert)
  })

  return (
    <Formik
      initialValues={{ email: '', password: '', remember_me: '1' }}
      validationSchema={ validationSchema }
      onSubmit={ (values, { setSubmitting, setErrors }) => {
        login('user', values)
          .then(() => {
            redirectTo(defaultPath())
          })
          .catch((error) => {
            setSubmitting(false)

            if (error.response && error.response.data) {
              setErrors({ email: error.response.data.error })
            } else {
              throw error
            }
          })
      } }
    >
      {({
        errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => (
        <FormView
          isRegularUser={ userType !== 'admin' }
          handleSubmit={ handleSubmit }
          touched={ touched }
          errors={ errors }
          isSubmitting={ isSubmitting } />
      )}
    </Formik>
  )
}

LoginForm.propTypes = {
  userType: oneOf(['admin', 'user']).isRequired
}

export default LoginForm

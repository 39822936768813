import gql from 'graphql-tag'

export default gql`
  query LandingPageTemplates {
    landingPageTemplates {
      uuid
      name
      url
    }
  }
`

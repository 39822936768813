import React      from 'react'
import { get }    from 'lodash'
import { string } from 'prop-types'

import propertyType  from 'shared/types/propertyType'
import Card          from 'shared/components/ui/CardDeck/Item'
import CampaignCount from 'shared/components/CampaignCount'
import { propertyMissingDataPresentation }
  from 'shared/components/Indicators/MissingData/Presentations'

const PropertyCard = ({ property, className }) => {
  const missingData = {
    data: property.missingData,
    presentation: propertyMissingDataPresentation
  }

  return (
    <Card
      className={ className }
      thumbnail={ get(property, 'image.thumbnail') }
      header={ property.name }
      subheader={ property.customerName }
      missingData={ missingData }
    >
      <CampaignCount count={ property.activeCampaignsCount } />
    </Card>
  )
}

PropertyCard.propTypes = {
  property: propertyType.isRequired,
  className: string
}

PropertyCard.defaultProps = {
  className: ''
}

export default PropertyCard

import React    from 'react'
import { bool } from 'prop-types'
import styled   from 'styled-components'

import Card   from 'shared/components/ui/Card'
import Header from 'shared/components/ui/Card/StaticHeader'

import BuildingDetails from './BuildingDetails'

const Buildings = ({ buildings, collapsed }) => {
  return (
    buildings.map((building) => (
      <Card
        collapsible
        className="mb-2"
        collapsed={ collapsed }
        key={ building.uuid }
        header={ (
          <div className="d-flex justify-content-between">
            <Header className="align-self-center">
              { building.name }
            </Header>
          </div>
        ) }
      >
        <BuildingDetails building={ building } />
      </Card>
    ))
  )
}

Buildings.propTypes = {
  collapsed: bool.isRequired
}

export default styled(Buildings)`
  .missing-data-indicator {
    margin: 0
  }
`

import React, { useEffect, useState, useCallback } from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import { useApolloClient } from '@apollo/react-hooks'
import { find } from 'lodash'
import { t } from 'ttag'

import campaignAnalyticsQuery from 'graphql/queries/campaigns/analytics'
import campaignAnalyticsTotalQuery from 'graphql/queries/campaigns/analyticsTotal'
import { bluishColor } from 'shared/style/colors'
import { formatDuration } from 'shared/helpers/date'
import LoadingBlock from 'shared/components/ui/LoadingBlock'
import Chart from 'shared/components/Chart'
import ChartTab from 'admin/components/AnalyticsChart/ChartTab'
import Card from 'admin/components/AnalyticsChart/Card'
import { formatPrice } from 'shared/helpers/currency'
import { formatNumberWithLocale } from 'shared/helpers/number'

const initialTab = 'impressions'
let chartData = []

const Analytics = ({ campaignUuid, currency, className }) => {
  const client = useApolloClient()
  const [selectedTab, setSelectedTab] = useState(initialTab)
  const [campaignAnalytics, setCampaignAnalytics] = useState(null)
  const [campaignAnalyticsData, setCampaignAnalyticsData] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const response = await loadAnalyticsTotal()
      setCampaignAnalytics(response.data.campaignAnalyticsTotal)
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      const response = await loadAnalytics()
      setCampaignAnalyticsData(response.data.campaignAnalytics)
    }
    fetchData()
  }, [selectedTab])

  const loadAnalyticsTotal = async () => {
    const data = await client.query({
      query: campaignAnalyticsTotalQuery,
      variables: { campaignUuid }
    })
    return data
  }

  const loadAnalytics = async () => {
    const data = await client.query({
      query: campaignAnalyticsQuery,
      variables: { campaignUuid, dataName: selectedTab }
    })
    return data
  }

  const getTooltipFormatter = useCallback((tab) => (
    {
      art:         (value) => formatDuration(value, 'minutes'),
      cpc:         (value) => formatPrice(value, currency),
      ctr:         (value) => `${value}%`,
      cpl:         (value) => formatPrice(value, currency),
      impressions: (value) => formatNumberWithLocale(value),
      visitors:    (value) => formatNumberWithLocale(value),
      spend:       (value) => formatPrice(value, currency),
    }[tab]
  ), [currency])

  if (campaignAnalytics === null) {
    return (
      <Card className={ className } >
        <LoadingBlock />
      </Card>
    )
  }

  if (
    !campaignAnalytics
      || (
        campaignAnalytics.totalImpressions === 0
          && campaignAnalytics.totalVisitors === 0
      )
  ) {
    return (
      <Card className={ `${className} empty` }>
        <h3>
          { t`No data` }
        </h3>
      </Card>
    )
  }

  const formatAverageArt = (averageArt) => {
    return averageArt === 0 ? '-' : formatDuration(averageArt, 'minutes')
  }

  const formatAverageLqs = (averageLqs) => {
    return averageLqs === 0 ? '-' : averageLqs
  }

  const tabsData = [
    {
      title: t`Impressions`,
      name: 'impressions',
      value: formatNumberWithLocale(campaignAnalytics.totalImpressions)
    },
    {
      title: t`Visitors`,
      name: 'visitors',
      value: formatNumberWithLocale(campaignAnalytics.totalVisitors)
    },
    {
      title: t`CPC`,
      name: 'cpc',
      value: formatPrice(campaignAnalytics.averageCpc, currency)
    },
    {
      title: t`CTR`,
      name: 'ctr',
      value: `${campaignAnalytics.averageCtr}%`
    },
    {
      title: t`Leads`,
      name: 'leads',
      value: `${campaignAnalytics.totalLeads} / ${campaignAnalytics.goalLeads}`
    },
    {
      title: t`CPL`,
      name: 'cpl',
      value: formatPrice(campaignAnalytics.averageCpl, currency)
    },
    {
      title: t`ART`,
      name: 'art',
      value: formatAverageArt(campaignAnalytics.averageArt)
    },
    {
      title: t`LQS`,
      name: 'lqs',
      value: formatAverageLqs(campaignAnalytics.averageLqs)
    },
    {
      title: t`Spend`,
      name: 'spend',
      value: formatPrice(campaignAnalytics.totalSpent, currency)
    }
  ]

  if (campaignAnalyticsData) {
    chartData = [
      {
        name: find(tabsData, ['name', selectedTab]).title,
        seriesData: campaignAnalyticsData.data,
        color: bluishColor
      }
    ]

    const tooltipFormatter = getTooltipFormatter(selectedTab)
    if (tooltipFormatter) {
      chartData[0].tooltipFormatter = tooltipFormatter
    }
  }

  return (
    <Card className={ className }>

      <div className='d-flex justify-content-between chart-tabs'>
        <div className='d-flex'>
          { tabsData.map((tab) => (
            <ChartTab
              key={ tab.name }
              title={ tab.title }
              tab={ tab.name }
              selectedTab={ selectedTab }
              setSelectedTab={ setSelectedTab }
              value={ tab.value }
            />
          ))}
        </div>
      </div>

      {campaignAnalyticsData && (
        <Chart data={ chartData } />
      )}
    </Card>
  )
}

Analytics.propTypes = {
  campaignUuid: string.isRequired,
  currency: string
}

Analytics.defaultProps = {
  currency: 'NOK'
}

export default styled(Analytics)`
  &.empty {
    text-align: center
  }

  .short-input {
    width: 6rem
  }

  .chart-tabs h5 {
    margin-bottom: 0
  }
`

import * as Yup from 'yup'
import { t }    from 'ttag'

export default Yup.object().shape({
  channelType: Yup.string(),
  areFacebookCredentialsValid: Yup.boolean().when(
    'channelType', (channelType, schema) => {
      if (channelType === 'facebook' || channelType === 'instagram') {
        return schema.oneOf([true], t`There are no facebook credentials for your company.`)
      }
    }
  ),
  areGmpCredentialsValid: Yup.boolean().when(
    'channelType', (channelType, schema) => {
      if (channelType === 'gmp') {
        return schema.oneOf([true], t`There is no gmp configuration for your company.`)
      }
    }
  )
})

import gql from 'graphql-tag'

import bannerTemplateCollection from 'graphql/fragments/bannerTemplateCollection'

export default gql`
  query companyBannerTemplateCollections($companyUuid: ID!) {
    companyBannerTemplateCollections(companyUuid: $companyUuid) {
      ... BannerTemplateCollection
    }
  }
  ${bannerTemplateCollection}
`

import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { func } from 'prop-types'

import { IconUser } from 'shared/components/ui/icons'
import { roundButtonFocusShadow, roundButtonShadow } from 'shared/style/placeholders'

const UserMenuToggle = forwardRef((props, ref) => {
  const { onClick, className } = props

  return (
    <Button
      ref={ ref }
      variant="light"
      onClick={ onClick }
      className={ className }
      data-test-id='menu-button'
    >
      <IconUser/>
    </Button>
  )
})

UserMenuToggle.displayName = 'UserMenuToggle'

UserMenuToggle.propTypes = {
  onClick: func.isRequired
}

export default styled(UserMenuToggle)`
  ${roundButtonShadow};
  border-radius: 2rem;
  height: 1.75rem;
  padding: .1rem .025rem 0 0;
  width: 1.75rem;

  &.dropdown-toggle:hover,
  &.dropdown-toggle:focus,
  &.dropdown-toggle:active  {
    ${roundButtonFocusShadow};
    background: #f8f9fa;
    border-color: #f8f9fa
  }

  &:after {
    display: none
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'

import Card from 'shared/components/ui/Card'
import { textColor } from 'shared/style/colors'

const EmbeddedCardSection = ({ className, titleText, children }) => {
  return (
    <Card
      header={ titleText }
      className={ classNames(className, 'shadow-none', 'border-0') }>
      { children }
    </Card>
  )
}

EmbeddedCardSection.propTypes = {
  children: PropTypes.node.isRequired,
  titleText: PropTypes.string
}

EmbeddedCardSection.defaultProps = {
  titleText: undefined
}

export default styled(EmbeddedCardSection)`
  .card-header {
    h5 {
      font-weight: normal;
      color: ${textColor};
      font-size: 20px;
    }
  }
  .card-body {
    padding-bottom: 5px !important;
  }
`

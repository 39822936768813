import React from 'react'
import { t } from 'ttag'
import { shape, string, array, func } from 'prop-types'

import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'

import {
  portalPositions,
  audienceNetworkPositions,
  messengerPositions
} from './positions'
import PositionsGroup from './PositionsGroup'

const PortalPlacements = ({ platformAudience, errors, update }) => {
  const handlePortalPositionsChange = portalPositions =>
    update({ portalPositions })

  const handleAudienceNetworkPositionsChange = audienceNetworkPositions =>
    update({ audienceNetworkPositions })

  const handleMessengerPositionsChange = messengerPositions =>
    update({ messengerPositions })

  return (
    <InputWrapper error={ errors }>
      <PositionsGroup
        name={ t`Facebook` }
        options={ portalPositions }
        selected={ platformAudience.portalPositions }
        onChange={ handlePortalPositionsChange } />

      <PositionsGroup
        name={ t`Audience Network` }
        options={ audienceNetworkPositions }
        selected={ platformAudience.audienceNetworkPositions }
        onChange={ handleAudienceNetworkPositionsChange } />

      <PositionsGroup
        name={ t`Messenger` }
        options={ messengerPositions }
        selected={ platformAudience.messengerPositions }
        onChange={ handleMessengerPositionsChange } />
    </InputWrapper>
  )
}

PortalPlacements.propTypes = {
  platformAudience: shape({
    platform: string,
    portalPositions: array,
    audienceNetworkPositions: array,
    messengerPositions: array
  }).isRequired,
  update: func.isRequired,
  errors: shape({
    errorMessage: string
  })
}

PortalPlacements.defaultProps = {
  errors: {}
}

export default PortalPlacements


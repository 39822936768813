import React, { useEffect, useState } from 'react'
import { t } from 'ttag'
import styled from 'styled-components'
import { useApolloClient, useMutation } from 'react-apollo'
import { string } from 'prop-types'
import { toast } from 'react-toastify'

import updateCampaignMutation from 'graphql/mutations/campaign/update'
import deleteCampaignMutation from 'graphql/mutations/campaign/delete'
import campaignQuery from 'graphql/queries/campaign'
import ResourceQuery from 'shared/components/ResourceQuery'
import CampaignSummary from 'shared/components/CampaignSummary'
import CampaignInfo from 'shared/components/CampaignInfo'
import PropertyCampaignInfo from 'shared/components/PropertyCampaignInfo'
import redirectTo from 'shared/helpers/redirectTo'
import LoadingCard from 'shared/components/ui/LoadingCard'

import ProductDetails from './ProductDetails'
import CampaignActions from './CampaignActions'
import EditCampaignModal from './EditCampaignModal'

const Campaign = ({ uuid, className }) => {
  const [isEditing, setIsEditing] = useState(false)

  const client = useApolloClient()

  useEffect(() => {
    client.cache.writeData({
      data: {
        currentCampaignUuid: uuid
      }
    })
  }, [uuid])

  const handleSave = input => {
    client.mutate({
      mutation: updateCampaignMutation,
      variables: { uuid: uuid, input }
    })

    setIsEditing(false)
  }

  const saveCampaignActiveTab = newTab => {
    client.mutate({
      mutation: updateCampaignMutation,
      variables: { uuid: uuid, input: { currentContentStep: newTab.toString() } }
    })
  }

  const actions = [
    { label: t`Edit campaign details`, action: () => setIsEditing(true) },
    { label: t`Delete campaign`, action: () => handleDeleteCampaignClick() }
  ]

  const handleDeleteCampaignClick = () => {
    if (confirm(t`Are you sure?`)) {
      deleteCampaign().then(() => {
        toast.success(t`Campaign successfully deleted`)
        redirectTo('/campaigns')
      })
    }
  }

  const [deleteCampaign, { loading }] = useMutation(
    deleteCampaignMutation,
    { variables: { uuid: uuid } }
  )

  if (loading) {
    return (
      <LoadingCard />
    )
  }

  return (
    <ResourceQuery
      query={ campaignQuery }
      variables={{ uuid }}
    >
      {(campaign) => {
        const CampaignInfoComponent = campaign.propertyBased ? PropertyCampaignInfo : CampaignInfo
        return (
          <div className={ [className, 'mb-5'].join(' ') }>
            <div className="position-relative">
              <CampaignSummary campaign={ campaign } />
              <CampaignActions
                className='campaign-menu'
                actions={ actions }
                campaign={ campaign }
              />
            </div>

            {!campaign.propertyBased && (
              <ProductDetails
                collapsed
                product={ campaign.product }
                readOnly={ campaign.project.readonly }
              />
            )}

            <CampaignInfoComponent
              campaign={ campaign }
              onChangeActiveTab={ saveCampaignActiveTab }
            />

            <EditCampaignModal
              campaign={ campaign }
              show={ isEditing }
              onHide={ () => setIsEditing(false) }
              onSave={ handleSave }
            />
          </div>
        )
      }}
    </ResourceQuery>
  )
}

Campaign.propTypes = {
  uuid: string.isRequired,
}

export default styled(Campaign)`
  .campaign-menu {
    position: absolute;
    top: 1rem;
    right: 1rem
  }
`

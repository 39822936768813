import handlePayloadValidation from 'shared/components/ValidatableTabs/handlePayloadValidation'

import buildingValidationSchema from './buildingValidationSchema'
import unitsValidationSchema    from './unitsValidationSchema'

const validateStageDetails = ({ payload, updated } = {}) => {
  let buildingToValidate = payload
  if (updated) {
    buildingToValidate = updated
  }
  buildingValidationSchema.validateSync(buildingToValidate, { abortEarly: false })
}

const validateUnits = ({ payload, updated, context } = {}) => {
  let unitsToValidate = payload
  if (updated) {
    unitsToValidate = payload.map((s) => (
      s.uuid === updated.uuid ? updated : s
    ))
  }
  unitsValidationSchema.validateSync(unitsToValidate, { abortEarly: false, context })
}

const validateMedia = () => {}

const fetchValidationData = ({ building, validatorNumber }) => {
  return [
    [validateStageDetails,   building],
    [validateUnits,          building.units, { building: building }],
    [validateMedia,          []]
  ][validatorNumber]
}

const buildValidator = ({ building, setErrors, validatorNumber }) => {
  const [validatePayload, payload, context] = fetchValidationData({ building, validatorNumber })

  return ({ propogateError, updated, force } = {}) => handlePayloadValidation({
    validatePayload,
    payload,
    context,
    propogateError,
    setErrors,
    updated,
    force
  })
}

export default buildValidator

import React  from 'react'
import styled from 'styled-components'
import { arrayOf, string, func } from 'prop-types'

import { centeredImage } from 'shared/style/placeholders'
import Slider from 'shared/components/ui/Slider'

const AdsSlider = ({ assets, setPreviewAsset, className }) => {
  const slidesToShow = 5
  const infinite = assets.length > slidesToShow

  const settings = {
    infinite,
    slidesToShow,
    speed: 500,
    slidesToScroll: slidesToShow
  }

  return (
    <Slider { ...settings } className={ className }>
      { assets.map((thumbnail) => (
        <div className="image-wrapper" key={ thumbnail }>
          <img
            alt="ad"
            onClick={ () => setPreviewAsset(thumbnail) }
            src={ thumbnail }
          />
        </div>
      )) }
    </Slider>
  )
}

AdsSlider.propTypes = {
  assets: arrayOf(string).isRequired,
  setPreviewAsset: func.isRequired
}

export default styled(AdsSlider)`
  .image-wrapper {
    border-radius: .25rem
    height: 3rem
    overflow: hidden
    position: relative

    img {
      ${centeredImage}
      cursor: pointer
    }
  }
`

import React               from 'react'
import { t }               from 'ttag'
import styled              from 'styled-components'
import { arrayOf, string } from 'prop-types'

import assetType                              from 'shared/types/assetType'
import { cardPaddingX, cardPaddingY }         from 'shared/style/placeholders'
import { cardPaddingXSize, cardPaddingYSize } from 'shared/style/sizes'
import {
  greyishBrownColor,
  greyishWhiteColor,
  textColorLight,
  textHeaderColor
} from 'shared/style/colors'

import DraggableGallery from './DraggableGallery'
import groupAssets      from './groupAssets'

const sourceTitleTranslations = {
  Stage: t`Stage`,
  Project: t`Project`,
  Campaign: t`Campaign`
}

const AllMedia = ({ assets, sourceTitle, className }) => {
  const groupedAssets = groupAssets(assets)

  return (
    <div className={ className }>
      <h5 className="header">
        { `${sourceTitleTranslations[sourceTitle] || sourceTitle} ${t`media`}` }
      </h5>

      <h6 className="subheader">
        { t`Drag and drop the images you want to use into the correct category.` }
      </h6>

      { groupedAssets.video && (
        <>
          <h4 className="media-type-header">{ t`Videos` }</h4>
          <DraggableGallery assets={ groupedAssets.video } />
        </>
      ) }

      { groupedAssets.image && (
        <>
          <h4 className="media-type-header">{ t`Images` }</h4>
          <DraggableGallery assets={ groupedAssets.image } />
        </>
      ) }
    </div>
  )
}

AllMedia.propTypes = {
  assets: arrayOf(assetType).isRequired,
  sourceTitle: string.isRequired
}

export default styled(AllMedia)`
  ${cardPaddingX}
  ${cardPaddingY}

  background: ${greyishWhiteColor}
  margin-top: 1rem
  margin-left: -${cardPaddingXSize}
  margin-right: -${cardPaddingXSize}
  margin-bottom: -${cardPaddingYSize}

  .header {
    color: ${greyishBrownColor}
    font-weight: 600
  }

  .subheader {
    color: ${textHeaderColor}
    font-size: .9rem
    font-weight: 400
  }

  .media-type-header {
    color: ${textColorLight}
    font-size: 1.25rem
    margin: 1.25rem 0 1rem
  }
`

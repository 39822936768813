import gql from 'graphql-tag'

import showing  from 'graphql/fragments/showing'
import color    from 'graphql/fragments/color'

export default gql`
  fragment Project on Project {
    uuid
    name
    customerName
    stagesCount
    unitsCount
    unitsSold
    address
    activeCampaignsCount
    missingData
    totalCampaignsCount
    readonly
    defaultColor
    defaultTemplateUuid
    sourceDescription
    customDescription
    customDescriptionDraft
    title
    titleDraft
    link
    linkDraft
    portalEnabled
    portalEnabledDraft
    autoDescriptionEnabled
    salesReportEnabled
    ownershipType
    headline
    headlineDraft
    facilities
    flatFinderUrl
    multistage
    saleState
    saleStateDraft
    enabledExtraUnits
    portalAutoSyncEnabled
    snapshotsEnabled
    companyColors {
      ... Color
    }
    showings {
      ... Showing
    }
    district {
      uuid
    }
    location {
      address
      countryCode
      zipCode
      latitude
      longitude
    }
  }
  ${showing}
  ${color}
`

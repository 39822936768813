import React     from 'react'
import { t }     from 'ttag'
import styled    from 'styled-components'
import { array } from 'prop-types'

import { greyishBrownColor } from 'shared/style/colors'

import Showing from './Showing'
import AddNewButton from './AddNewButton'

const ShowingsList = ({ className, showings }) => {
  return (
    <div className={ className }>
      <h6>
        { t`Upcoming showings` }
      </h6>
      <ul>
        { showings.map((showing) => (
          <Showing key={ showing.uuid } { ...showing } />
        )) }
      </ul>
      <div className="p-2 d-flex justify-content-end">
        <AddNewButton />
      </div>
    </div>
  )
}

ShowingsList.propTypes = {
  showings: array.isRequired
}

export default styled(ShowingsList)`
  font-size: 0.875rem;

  h6 {
    color: ${greyishBrownColor};
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    margin-bottom: 0;
    padding: 0.625rem;
  }

  ul {
    padding: 0;
    margin: 0;
  }
`

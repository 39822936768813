import React from 'react'
import { number, string, oneOfType } from 'prop-types'
import { ordinalize } from 'inflection'

const Floor = ({ floor }) => <span>{ ordinalize((floor).toString()) }</span>

Floor.propTypes = {
  floor: oneOfType([number, string])
}

Floor.defaultProps = {
  floor: ''
}

export default Floor

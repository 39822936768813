import { t } from 'ttag'

const MAXIMUM_VIDEO_LENGTH = 120

const checkVideoLength = (file, mediaType, validateVideoSize) => (
  new Promise((resolve, reject) => {
    if ((!validateVideoSize) || (mediaType !== 'video')) {
      resolve(file)
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = () => {
        const video = document.createElement('video')
        video.preload = 'metadata'
        video.src = URL.createObjectURL(file)

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src)
          if (video.duration < MAXIMUM_VIDEO_LENGTH) {
            resolve(file)
          } else {
            reject(t`Video duration must be less than 2 minutes`)
          }
        }
      }
    }
  })
)

export default checkVideoLength

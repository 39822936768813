import gql from 'graphql-tag'

import creativeSet from 'graphql/fragments/creativeSet'

export default gql`
  mutation CloneCreativeSet($uuid: ID!) {
    cloneCreativeSet(uuid: $uuid) {
      creativeSet {
        ...CreativeSet
      }
    } 
  }
  ${creativeSet}
`

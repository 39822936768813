import { useContext } from 'react'
import { useMutation } from 'react-apollo'

import { ProjectSummaryContext } from 'shared/components/ProjectSummary/index'
import createShowingMutation from 'graphql/mutations/showings/create'
import { buildFragmentParams } from 'shared/helpers/graphql'
import projectFragment from 'graphql/fragments/project'

export default function useAddNewButton() {
  const { projectUuid } = useContext(ProjectSummaryContext)

  const [createShowing, { loading }] = useMutation(createShowingMutation, {
    variables: { showableUuid: projectUuid, showableType: 'Project' },
    skip: !projectUuid,
    update: (cache, { data }) => {
      const object = { __typename: 'Project', uuid: projectUuid }
      const fragmentParams = buildFragmentParams(object, projectFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          showings: [
            ...fragmentData.showings,
            data.createShowing.showing
          ]
        }
      })
    }
  })

  return {
    createShowing,
    loading
  }
}

import React from 'react'
import { string } from 'prop-types'
import { IconLink } from 'cabanaico'

import { getHumanUrlHost } from 'shared/helpers/url'
import Paragraph from 'shared/components/ui/Paragraph'
import Link from 'shared/components/Product/MainInfo/Link'

const Origin = ({ children }) => {
  const host = getHumanUrlHost(children)

  return (
    host && (
      <Paragraph>
        <Link href={ children } target="_blank" rel="noopener noreferrer nofollow">
          <IconLink className="mr-2" />
          { host }
        </Link>
      </Paragraph>
    )
  )
}

Origin.propTypes = {
  children: string.isRequired
}

export default Origin

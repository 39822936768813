import React, { useEffect } from 'react'
import { t }                from 'ttag'
import { useQuery, useApolloClient } from 'react-apollo'
import { toast }            from 'react-toastify'
import { get, merge } from 'lodash'
import { array, string, shape, func } from 'prop-types'

import campaignCreativeSets     from 'graphql/queries/campaignCreativeSets'
import companyBannerTemplateCollections   from 'graphql/queries/companyBannerTemplateCollections'
import campaignAssetAssignments from 'graphql/queries/campaignAssetAssignments'
import stageAssetAssignments    from 'graphql/queries/stageAssetAssignments'
import LoadingBlock             from 'shared/components/ui/LoadingBlock'

import { withPopulatedTexts } from '../PopulateTexts'

import NewCreativeSet         from './NewCreativeSet'
import CreativeSetsList       from './CreativeSetsList'
import cloneCreativeSet       from './cloneCreativeSet'
import buildCreativeSetsValidationPayload from './buildCreativeSetsValidationPayload'

const CreativeSets = ({
  campaign,
  setValidatablePayload,
  onLoaded,
  ...props
}) => {
  const { uuid, facebookPage, channels, product = {}, assetAssignments } = campaign

  const audiences = channels
    .filter(channel => channel.enabled)
    .flatMap(channel => channel.audiences)
    .filter(audience => !audience.deleted)

  const { company } = product
  const client = useApolloClient()
  const { data, loading } = useQuery(
    campaignCreativeSets,
    {
      variables: { campaignUuid: uuid },
      fetchPolicy: 'network-only'
    }
  )

  const creativeSets = data ? data.campaignCreativeSets : []

  useEffect(
    () => {
      if(setValidatablePayload) {
        setValidatablePayload(
          buildCreativeSetsValidationPayload({ audiences, creativeSets })
        )
      }
    },
    [JSON.stringify(creativeSets)]
  )

  useEffect(() => {
    if(!loading && onLoaded) {
      onLoaded()
    }
  }, [loading])

  const { data: templatesData } = useQuery(
    companyBannerTemplateCollections,
    {
      variables: { companyUuid: company.uuid },
      fetchPolicy: 'network-only'
    }
  )
  const bannerTemplateCollections = templatesData
    ? templatesData.companyBannerTemplateCollections : []

  const { data: stageAssetAssignmentsData, loading: loadingStageAssetAssignments } = useQuery(
    stageAssetAssignments,
    { variables: { uuid: campaign.product.promotable.uuid } }
  )

  const { data: campaignAssetAssignmentsData, loading: loadingCampaignAssetAssignments } = useQuery(
    campaignAssetAssignments,
    { variables: { uuid: campaign.uuid } }
  )

  if (loading || loadingCampaignAssetAssignments || loadingStageAssetAssignments) {
    return <LoadingBlock comment={ t`Generating ads...` } />
  }

  const handleClone = (creativeSet) => {
    cloneCreativeSet(client, campaign, creativeSet)
    toast.success(t`Creative Set has been cloned`)
  }

  const campaignWithAssetAssignments = merge(campaign, {
    assetAssignments: campaignAssetAssignmentsData.campaign.assetAssignments,
    product: {
      promotable: {
        assetAssignments: stageAssetAssignmentsData.stage.assetAssignments
      }
    }
  })

  return (
    <div className="px-4">
      <NewCreativeSet channels={ channels } features={ company.enabledFeatures } />

      <CreativeSetsList
        campaign={ campaignWithAssetAssignments }
        company={ company }
        audiences={ audiences }
        creativeSets={ creativeSets }
        bannerTemplateCollections={ bannerTemplateCollections }
        logos={ get(product, 'promotable.logos', []) }
        facebookPageName={ facebookPage?.name }
        facebookPageLogo={ facebookPage?.logoUrl }
        assetAssignments={ assetAssignments }
        onClone={ handleClone }
        { ...props }
      />
    </div>
  )
}

CreativeSets.propTypes = {
  campaign: shape({
    channels: array.isRequired,
    uuid: string.isRequired
  }).isRequired,
  onLoaded: func,
  setValidatablePayload: func,
}

CreativeSets.defaultProps = {
  onLoaded: undefined,
  setValidatablePayload: undefined
}

export default withPopulatedTexts(CreativeSets)

import dataURItoBlob      from 'shared/helpers/files/dataURItoBlob'
import mimeTypes          from 'shared/constants/mimeTypes'
import completeFileUpload from 'shared/helpers/files/completeFileUpload'

import attachFileToAgent from '../../attachFileToAgent'

export default function uploadImage({ client, agent, project, fileDataURI, fileMeta, mediaType }) {
  const file = dataURItoBlob(fileDataURI)
  const allowedMimeTypes = mimeTypes.logo

  return completeFileUpload({ client, file, allowedMimeTypes, fileMeta })
    .then(({ signedBlobId }) =>
      attachFileToAgent({
        client, mediaType,
        blobId: signedBlobId,
        agentUuid: agent.uuid,
        projectUuid: project.uuid
      })
    )
}

import React, { useMemo } from 'react'
import styled    from 'styled-components'
import { t }     from 'ttag'
import { Field } from 'formik'
import { bool, string, shape, func, arrayOf, number } from 'prop-types'

import FormikThriftyInput from 'shared/components/ui/FormikThriftyInput'
import FieldErrorMessage from 'shared/components/ui/FieldErrorMessage'
import DetailsRow from 'shared/components/Project/Readonly/Stages/DetailsRow'
import useCountries from 'shared/hooks/useCountries'
import { isUserAdmin } from 'shared/helpers/auth'

import CountriesSelect from './CountriesSelect'
import ZipCodeSelect   from './ZipCodeSelect'
import Map             from './Map'

const LeftColumn = (props) => {
  const {
    projectReadOnly,
    stage,
    className,
    location,
    mapCenter,
    setMapCenter,
    onNameChange,
    onAddressChange,
    onCountryCodeChange,
    onZipCodeChange,
    onLocationChange
  } = props

  const { countries } = useCountries()
  const country = useMemo(() => (
    countries.find(
      ({ alpha2 }) => alpha2 === location.countryCode
    ) || {}
  ), [countries.length, location.countryCode])

  const checkReadMode = () => {
    if (!isUserAdmin() && projectReadOnly) {
      return (
        <>
          <DetailsRow title={ t`Stage` }>
            { stage.name }
          </DetailsRow>
          <DetailsRow title={ t`Address` }>
            { location.address }
          </DetailsRow>
          <DetailsRow title={ t`Country` }>
            { country.name }
          </DetailsRow>
        </>
      )
    } else {
      return (
        <>
          <div className="group" data-test-id='stage-name-group'>
            <label>{ t`Stage` }</label>
            <FieldErrorMessage name="name">
              <Field name="name" as={ FormikThriftyInput } onChange={ onNameChange } />
            </FieldErrorMessage>
          </div>
          <div className="group" data-test-id='address-group'>
            <label>{ t`Address` }</label>
            <FieldErrorMessage name="address">
              <Field name="address" as={ FormikThriftyInput } onChange={ onAddressChange } />
            </FieldErrorMessage>
          </div>
          <div className="group" data-test-id='country-group'>
            <label>{ t`Country` }</label>
            <FieldErrorMessage name="countryCode">
              <Field name="countryCode" as={ CountriesSelect } onChange={ onCountryCodeChange } />
            </FieldErrorMessage>
          </div>
        </>
      )
    }
  }

  return (
    <div className={ className }>
      {checkReadMode()}

      <div className="group" data-test-id='zip-group'>
        <label>{ t`Zip code` }</label>

        <Field
          name="zipCode"
          as={ ZipCodeSelect }
          onChange={ onZipCodeChange }
          setMapCenter={ setMapCenter }
          countryCode={ location.countryCode }
        />
      </div>

      <div className="group" data-test-id='location-group'>
        <label>
          <div>{ t`Location` }</div>
          <i>{ t`Drag the pin to adjust your location` }</i>
        </label>
        <FieldErrorMessage name="location">
          <Map
            pointMapToCoordinates={ mapCenter }
            onLocationChange={ onLocationChange }
            marker={ location }
          />
        </FieldErrorMessage>
      </div>
    </div>
  )
}

LeftColumn.propTypes = {
  className: string.isRequired,
  location: shape({
    address: string,
    countryCode: string,
    latitude: number,
    longitude: number,
  }).isRequired,
  onAddressChange: func.isRequired,
  onCountryCodeChange: func.isRequired,
  onLocationChange: func.isRequired,
  onNameChange: func.isRequired,
  onZipCodeChange: func.isRequired,
  setMapCenter: func.isRequired,
  stage: shape({
    name: string
  }).isRequired,
  mapCenter: arrayOf(number),
  projectReadOnly: bool
}

LeftColumn.defaultProps = {
  mapCenter: undefined,
  projectReadOnly: undefined
}

export default styled(LeftColumn)`
  width: 50%;
  padding: 1.25rem 1.25rem 1.625rem 2.8125rem;

  .field-error-message {
    width: 100%
  }
`

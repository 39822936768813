import React, { useCallback } from 'react'
import { useQuery }     from '@apollo/react-hooks'
import { func, string } from 'prop-types'
import { get }          from 'lodash'

import CompanySpecificationsQuery from 'graphql/queries/companies/specification'

import FormSelect from './FormSelect'

const CampaignPackageSelect = ({ onChange, companyUuid, blueprintTemplateUuid, ...props }) => {
  const { data } = useQuery(CompanySpecificationsQuery, {
    variables: {
      companyUuid: companyUuid
    },
    fetchPolicy: 'no-cache'
  })

  const options = get(data, 'companySpecification', []).map(template => ({
    value: template.uuid,
    label: template.name
  }))

  const handleChange = useCallback(({ value }) => {
    onChange({ target: { name: 'blueprintTemplateUuid', value } })
  }, [])

  const defaultValue = options.find(option => option.value === blueprintTemplateUuid)

  return (
    <FormSelect
      { ...props }
      value={ defaultValue }
      onChange={ handleChange }
      options={ options }
    />
  )
}

CampaignPackageSelect.propTypes = {
  companyUuid: string.isRequired,
  onChange: func.isRequired,
  blueprintTemplateUuid: string
}

CampaignPackageSelect.defaultProps = {
  blueprintTemplateUuid: ''
}

export default CampaignPackageSelect

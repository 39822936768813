import React    from 'react'
import { t }    from 'ttag'
import { Form } from 'react-bootstrap'
import { array, bool, func, shape, string } from 'prop-types'
import styled from 'styled-components'

import PrimaryButton from 'shared/components/ui/PrimaryButton'

import KeyInformation from './KeyInformation'
import Stakeholders   from './Stakeholders'
import Media          from './Media'

const ProjectFormView = (props) => {
  const {
    className,
    isSubmitting,
    handleSubmit,
    handleAssetsChange,
    handleChange,
    setFieldValue,
    setStakeholders,
    stakeholders,
    setCompanies,
    companies,
    touched,
    assets,
    values,
    errors,
  } = props

  return (
    <Form className={ className } onSubmit={ handleSubmit }>
      <KeyInformation
        companies={ companies }
        setCompanies={ setCompanies }
        { ...{ values, handleChange, setFieldValue, touched, errors } }
      />

      <Stakeholders
        companyUuid={ companies[0]?.uuid }
        stakeholders={ stakeholders }
        setStakeholders={ setStakeholders }
      />

      <Media assets={ assets } handleAssetsChange={ handleAssetsChange } />

      <div className={ 'controls' }>
        <PrimaryButton
          type="submit"
          disabled={ isSubmitting }
        >
          {t`Create project`}
        </PrimaryButton>
      </div>
    </Form>
  )
}

ProjectFormView.propTypes = {
  assets: array.isRequired,
  companies: array.isRequired,
  errors: shape({
    customer: string,
    district: string,
    name: string,
  }).isRequired,
  handleAssetsChange: func.isRequired,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired,
  setCompanies: func.isRequired,
  setFieldValue: func.isRequired,
  setStakeholders: func.isRequired,
  stakeholders: array.isRequired,
  touched: shape({
    customer: bool,
    district: bool,
    name: bool,
  }).isRequired,
  values: shape({
    customer: string,
    district: string,
    name: string,
  }).isRequired
}

export default styled(ProjectFormView)`
  .controls {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
  }
`

import React from 'react'
import styled from 'styled-components'
import { func, string, array } from 'prop-types'

import OperatorSelect from './OperatorSelect'
import LocationSelect from './LocationSelect'

const AddLocationForm = ({ onSubmit, currentOperator, setCurrentOperator, currentLocations }) => {
  const handleSelect = value => onSubmit({ ...value, operator: currentOperator })

  return (
    <Container>
      <OperatorSelect
        onChange={ ({ value }) => setCurrentOperator(value) }
        selectedOperator={ currentOperator }
      />

      <LocationSelect onChange={ handleSelect } currentLocations={ currentLocations } />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.25rem;
`

AddLocationForm.propTypes = {
  currentOperator: string.isRequired,
  onSubmit: func.isRequired,
  setCurrentOperator: func.isRequired,
  currentLocations: array
}

AddLocationForm.defaultProps = {
  currentLocations: []
}

export default AddLocationForm

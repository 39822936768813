import React               from 'react'
import styled              from 'styled-components'
import { func, object }    from 'prop-types'
import { t }               from 'ttag'
import { useApolloClient } from 'react-apollo'

import { galleryGreyColor } from 'shared/style/colors'
import ThriftyInput         from 'shared/components/ui/ThriftyInput'

import { SectionHeader } from '../../elements'

import updateAgentMutation from './updateMutation'
import RemoveAgentButton   from './RemoveButton'
import AgentImage          from './AgentImage'

const Agent = ({ className, agent, refetchLandingPage }) => {
  const client = useApolloClient()
  const handleUpdate = input => {
    client.mutate({
      mutation: updateAgentMutation,
      variables: {
        uuid:  agent.uuid,
        input: input
      }
    }).then(() => {
      refetchLandingPage()
    })
  }

  return (
    <div className={ `agent ${ className }` }>
      <RemoveAgentButton agent={ agent } refetchLandingPage={ refetchLandingPage } />

      <div>
        <div>
          <SectionHeader>{ t`Title` }</SectionHeader>
          <ThriftyInput
            value={ agent.title }
            onUpdate={ title => handleUpdate({ title }) }
            data-test-id='agent-title-input'
          />
        </div>

        <div>
          <SectionHeader>{ t`Name` }</SectionHeader>
          <ThriftyInput
            value={ agent.name }
            onUpdate={ name => handleUpdate({ name }) }
            data-test-id='agent-name-input'
          />
        </div>

        <div>
          <SectionHeader>{ t`Phone number` }</SectionHeader>
          <ThriftyInput
            value={ agent.phoneNumber }
            onUpdate={ phoneNumber => handleUpdate({ phoneNumber }) }
            data-test-id='agent-phone-number-input'
          />
        </div>

        <div>
          <SectionHeader>{ t`Email` }</SectionHeader>
          <ThriftyInput
            value={ agent.email }
            onUpdate={ email => handleUpdate({ email }) }
            data-test-id='agent-email-input'
          />
        </div>
      </div>

      <div>
        <AgentImage agent={ agent } refetchLandingPage={ refetchLandingPage } />
      </div>
    </div>
  )
}

Agent.propTypes = {
  agent: object.isRequired,
  refetchLandingPage: func.isRequired
}

export default styled(Agent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.5rem;
  border: 2px solid ${galleryGreyColor};
  padding: 0.5rem;
  margin-bottom: 1rem;
  position: relative;

  & > div {
    display: flex;
    justify-content: space-between;

    &:last-child {
      justify-content: flex-start;
      margin-top: 0.5rem;
    }

    & > div {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

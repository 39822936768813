import React from 'react'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { object }   from 'prop-types'

import dashboardAnalyticsAdSpendQuery from 'graphql/queries/dashboardAnalytics/adSpend'
import LoadingBlock                   from 'shared/components/ui/LoadingBlock'
import { formatPrice }                from 'shared/helpers/currency'

import Card                from './Card'
import Chart               from './LinearChart'
import formatCurrentValues from './formatCurrentValues'

const AdSpendChart = ({ filter }) => {
  const {
    data: {
      dashboardAnalyticsAdSpend: {
        adSpend = {},
        currency
      } = {}
    } = {},
    loading
  } = useQuery(dashboardAnalyticsAdSpendQuery, {
    variables: { filter }
  })

  if (loading) {
    return (
      <Card header={ t`Ad spend` }>
        <LoadingBlock />
      </Card>
    )
  }

  const { adSpendValue } = formatCurrentValues({ adSpend, currency })

  return (
    <Chart
      name={ t`Ad spend` }
      comment={ t`Total spent on ads` }
      dataTestId='ad-spend-chart'
      dataset={ adSpend.dataset }
      currentValue={ adSpendValue }
      tooltipFormatter={ (value) => formatPrice(value, currency) }
    />
  )
}

AdSpendChart.propTypes = {
  filter: object
}

AdSpendChart.defaultProps = {
  filter: {}
}

export default AdSpendChart

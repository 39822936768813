import React            from 'react'
import { t }            from 'ttag'
import { string, func } from 'prop-types'

import Select from 'shared/components/ui/ThriftyInput/TouchableSelect'
import { redColor } from 'shared/style/colors'

const styles = {
  container: provided => ({
    ...provided,
    minWidth: '9rem'
  }),
  control: (provided, { selectProps }) => ({
    ...provided,
    borderColor: selectProps.isInvalid ? redColor : provided.borderColor
  })
}

const options = [
  {
    label: t`For sale`,
    value: 'for_sale'
  }, {
    label: t`Coming for sale`,
    value: 'coming_for_sale'
  }
]

const ProjectStateSelect = ({ value, onChange, ...props }) => {
  const handleChange = ({ value: state }) => {
    return onChange({ saleStateDraft: state })
  }

  return (
    <Select
      value={ options.find((option) => (option.value === value)) }
      options={ options }
      onChange={ handleChange }
      styles={ styles }
      data-test-id='project-state-select'
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

ProjectStateSelect.propTypes = {
  onChange: func.isRequired,
  value: string
}

ProjectStateSelect.defaultProps = {
  value: undefined
}

export default ProjectStateSelect

import { gql } from 'apollo-boost'

import property from 'graphql/fragments/property'

export default gql`
  query propertyCampaignFormData($uuid: ID!) {
    propertyCampaignFormData(uuid: $uuid) {
      property {
        ... Property
        lastShowingDate
        defaultCampaignSpecificationUuid
      }
      company {
        enabledFeatures
      }
    }
  }
  ${property}
`

import React from 'react'
import { object, func } from 'prop-types'
import { t } from 'ttag'

import ButtonTag from 'shared/components/ui/Button'

const SendConfirmationEmailButton = ({ onSendConfirmationEmail, user }) => {
  if (user.confirmedAt) {
    return ''
  }

  const handleSendConfirmationEmail = () => {
    onSendConfirmationEmail(user)
  }

  return (
    <ButtonTag
      onClick={ handleSendConfirmationEmail }
      data-test-id='send-confirmation-email-button'
    >
      { t`Resend confirmation email` }
    </ButtonTag>
  )
}

SendConfirmationEmailButton.propTypes = {
  onSendConfirmationEmail: func.isRequired,
  user: object.isRequired
}

export default SendConfirmationEmailButton

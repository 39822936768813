import React            from 'react'
import styled           from 'styled-components'
import { string, node } from 'prop-types'

import { labelColor } from 'shared/style/colors'

const InputRow = ({ label, labelFor, className, children }) => {
  const WrapperTag = labelFor ? 'div' : 'label'
  const LabelTag = labelFor ? 'label': 'span'

  return (
    <WrapperTag className={ className }>
      <LabelTag className="input-row-label" htmlFor={ labelFor }>
        { label }
      </LabelTag>
      <span className="input-row-input">
        { children }
      </span>
    </WrapperTag>
  )
}

InputRow.propTypes = {
  children: node.isRequired,
  label: string.isRequired,
  labelFor: string
}

InputRow.defaultProps = {
  labelFor: undefined
}

export default styled(InputRow)`
  color: ${labelColor};
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: space-between;
  margin-bottom: .75rem;

  .input-row-label {
    display: inline-block;
    min-width: 50%;
  }

  .input-row-input {
    width: 100%;

    .form-control {
      box-shadow: none;
      height: auto;
      padding: 0.225rem 0.5rem;
    }
  }
`

import gql from 'graphql-tag'

import audienceLocations from 'graphql/fragments/audienceLocations'
import facebookPlatformAudience from 'graphql/fragments/facebookPlatformAudience'
import instagramPlatformAudience from 'graphql/fragments/instagramPlatformAudience'
import portalPlatformAudience from 'graphql/fragments/portalPlatformAudience'
import gmpPlatformAudience from 'graphql/fragments/gmpPlatformAudience'

export default gql`
  fragment Audience on Audience {
    uuid
    name
    fallbackLatitude
    fallbackLongitude
    fallbackZipCode
    fallbackCountryCode
    deleted
    adsCount
    leadsCount
    potentialReach
    type
    published
    locations {
      ... AudienceLocations
    }
    platformAudience {
      ... FacebookPlatformAudience

      ... InstagramPlatformAudience

      ... PortalPlatformAudience

      ... GmpPlatformAudience
    }
    creativeSetAudiences {
      uuid,
      creativeSetUuid
    }
  }
  ${audienceLocations}
  ${facebookPlatformAudience}
  ${instagramPlatformAudience}
  ${portalPlatformAudience}
  ${gmpPlatformAudience}
`

import * as Yup from 'yup'
import { t } from 'ttag'

import { options as incomeOptions } from './IncomeRangeSelect'

export default Yup.object().shape({
  ageFrom: Yup.string(),
  ageTo: Yup.string().test({
    test: function(ageTo) {
      const { ageFrom } = this.parent

      if (!ageFrom || !ageTo) return true

      return ageTo >= ageFrom
    },
    message: t`Age to should be greater than age from`
  }),

  incomeFrom: Yup.string(),
  incomeTo: Yup.string().test({
    test: function(incomeTo) {
      const { incomeFrom } = this.parent

      if (!incomeFrom || !incomeTo) return true

      const incomeFromOptionIndex = incomeOptions.findIndex(el => el.value === incomeFrom)
      const incomeToOptionIndex = incomeOptions.findIndex(el => el.value === incomeTo)

      return incomeToOptionIndex >= incomeFromOptionIndex
    },
    message: t`Income to should be greater than income from`
  })
})

import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { InputGroup, Button, Form } from 'react-bootstrap'
import { bool, func, oneOfType, string } from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks'

import completeFileUpload from 'shared/helpers/files/completeFileUpload'
import { buttonBorderColor, redColor } from 'shared/style/colors'
import mimeTypes from 'shared/constants/mimeTypes'

const FileInput = ({ className, onChange, isInvalid, ...props }) => {
  const client = useApolloClient()
  const [fileName, setFileName] = useState('')

  const inputFile = useRef(null)
  const handleBrowseClick = e => {
    e.target.blur()
    inputFile.current.click()
  }

  const handleFileChange = e => {
    const element = e.target
    const allowedMimeTypes = mimeTypes.floorplan

    if (element.files.length > 0) {
      const file = element.files[0]

      completeFileUpload({ client, file, allowedMimeTypes })
        .then(({ signedBlobId }) => onChange(signedBlobId))

      setFileName(element.value.split(/(\\|\/)/g).pop())
    } else {
      setFileName('')
    }
  }

  return (
    <InputGroup className={ className }>
      <Form.Control readOnly isInvalid={ isInvalid } value={ fileName }/>
      <InputGroup.Append>
        <Form.Control
          hidden
          type="file"
          onChange={ handleFileChange }
          ref={ inputFile }
          { ...props }
        />
        <Button variant="outline-secondary" onClick={ handleBrowseClick }>
          ...
        </Button>
      </InputGroup.Append>
    </InputGroup>
  )
}

FileInput.propTypes = {
  isInvalid: oneOfType([string, bool]),
  onChange: func,
  value: string

}

FileInput.defaultProps = {
  isInvalid: false,
  onChange: () => {},
  value: ''
}

export default styled(FileInput)`
  input.form-control[readonly] {
    background-color: white;
  }

  button {
    border-color: ${buttonBorderColor};
    line-height: 1.3125rem;
  }

  ${props => props.isInvalid && css`
    button {
      border-color: ${redColor} ${redColor} ${redColor} ${buttonBorderColor};
    }

    & + .invalid-feedback {
      display: block;
    }
  `}
`

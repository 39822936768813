import { t } from 'ttag'

export default [
  { value: 'apartment', label: t`Apartments` },
  { value: 'townhouse', label: t`Townhouse` },
  { value: 'semi_detached', label: t`Semi-detached` },
  { value: 'single_family_house', label: t`Single family house` },
  { value: 'storage', label: t`Storage` },
  { value: 'residential_lot', label: t`Residential lot` },
  { value: 'leisure', label: t`Leisure` },
  { value: 'leisure_lot', label: t`Leisure lot` },
  { value: 'commercial', label: t`Commercial` },
  { value: 'commercial_lot', label: t`Commercial lot` },
  { value: 'other', label: t`Other` }
]

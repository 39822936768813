import React, { useState, useCallback, useEffect, useContext } from 'react'
import { t } from 'ttag'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from 'react-apollo'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import LoadingBlock from 'shared/components/ui/LoadingBlock'
import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'
import Navbar from 'shared/components/ui/Navbar'
import SnapshotForm from 'shared/components/Snapshots/SnapshotForm'
import Button from 'shared/components/ui/Button'
import { StageCreationModeContext }
  from 'shared/components/ProductDetails/StageDetails/DetailsWithSnapshots'
// eslint-disable-next-line max-len
import AssignableAssets from 'shared/components/Project/ProjectInformation/ProjectStatusInformation/AssignableAssets'
import FormLabel from 'shared/components/ui/FormLabel'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'
import projectQuery from 'shared/components/Project/projectQuery'
import copySnapshotMutation from 'graphql/mutations/snapshots/copy'
import snapshotAssetAssignmentsQuery from 'graphql/queries/snapshots/assetAssignments'

const SalesStatusInformation = (
  { className, snapshots, setSnapshots, saleState, currency, stage }
) => {
  const [runCopySnapshotMutation] = useMutation(copySnapshotMutation)
  const {
    data: { project: { originalAssetAssignments } } = { project: { originalAssetAssignments: [] } },
    loading: assetsLoading
  } = useQuery(
    projectQuery,
    { variables: { uuid: stage.projectUuid } }
  )
  const [currentItem, setCurrentItem] = useState(saleState)
  const [isCopying, setIsCopying] = useState(false)
  const creatingMode = useContext(StageCreationModeContext)

  const tabs = {
    coming_for_sale: t`Coming for sale`,
    for_sale: t`For sale`
  }

  useEffect(() => {
    setCurrentItem(saleState)
  }, [saleState])

  const navItems = Object.keys(tabs).map(key => ({ type: key, title: tabs[key] }))

  const getCurrentSnapshot = () => {
    return snapshots.find(value => value.saleState === currentItem)
  }

  const showCopyButton = useCallback(() => currentItem === 'for_sale', [currentItem])

  const handleCopyInformation = async () => {
    if (!confirm(t`Are you sure?`)) { return }

    setIsCopying(true)
    const currentSnapshot = getCurrentSnapshot()
    const prevSnapshot = snapshots.find(snapshot => snapshot.saleState === 'coming_for_sale')

    runCopySnapshotMutation({
      variables: {
        fromUuid: prevSnapshot.uuid,
        toUuid: currentSnapshot.uuid
      },
      refetchQueries:[{
        query: snapshotAssetAssignmentsQuery,
        variables: { snapshotUuid: currentSnapshot.uuid }
      }]
    }).then((result) => {
      setSnapshots(prev => {
        return prev.map(snapshot => {
          if (snapshot.uuid === currentSnapshot.uuid) {
            return result.data.copySnapshot.snapshot
          }
          return snapshot
        })
      })
      setIsCopying(false)
    })
  }

  // to keep the same translation key
  const prevStateName = t`Coming for sale`

  if (assetsLoading) return <LoadingBlock/>

  const snapshot = getCurrentSnapshot()
  const isSnapshotActive = snapshot.saleState === stage.saleState

  return (
    <EmbeddedCardSection
      titleText={ t`Sales stage status information` }
      className={ className }
    >
      <Navbar
        className={ className }
        navItems={ navItems }
        currentItem={ currentItem }
        setCurrentItem={ setCurrentItem }
        data-test-id='navbar'
      />

      { showCopyButton() && (<Button
        variant='primary'
        onClick={ handleCopyInformation }
        className='mb-2'
        data-test-id='copy-stage-snapshot-button'
        disabled={ isCopying }>
        { t`Copy "${prevStateName}" information` }
      </Button>) }

      <SnapshotForm
        snapshot={ snapshot }
        currency={ currency }
        setSnapshots={ setSnapshots }
        creatingMode={ creatingMode }
        isSnapshotActive={ isSnapshotActive }
        snapshotableName={ t`Stage` }
        data-test-id='snapshot-form-stage'
      />

      <div className="group">
        <FormLabel>{ t`Images` }</FormLabel>
        <InputWrapper>
          <DndProvider backend={ HTML5Backend }>
            <AssignableAssets
              className='mt-3'
              projectAssetAssignments={ originalAssetAssignments }
              mediaType={ 'image' }
              assetableUuid={ snapshot.uuid }
              assetSourceUuid={ stage.projectUuid }
              assetSourceType='Project'
              assetableType='Snapshot'
              data-test-id='assignable-assets'
            />
          </DndProvider>
        </InputWrapper>
      </div>
    </EmbeddedCardSection>
  )
}

SalesStatusInformation.propTypes = {
  currency: PropTypes.string.isRequired,
  saleState: PropTypes.string.isRequired,
  setSnapshots: PropTypes.func.isRequired,
  snapshots: PropTypes.array.isRequired,
  stage: PropTypes.object.isRequired
}

export default SalesStatusInformation

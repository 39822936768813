import React, { memo }        from 'react'
import { func, string, bool } from 'prop-types'
import { t }                  from 'ttag'
import Select                 from 'react-select'

import { availableLocales } from 'locale'

import styles from './selectStyles'

const options = availableLocales.map(({ key, label }) => ({
  label,
  value: key,
}))

const LanguageSelect = ({ onChange, name, value, isInvalid }) => {
  return (
    <span data-test-id="language-select-wrapper">
      <Select
        value={ options.find((option) => (option.value === value)) }
        className={ isInvalid ? 'is-invalid' : '' }
        styles={ styles }
        isInvalid={ isInvalid }
        onChange={ ({ value }) => {
          onChange({ target: { name, value } })
        } }
        options={ options }
        placeholder={ t`Select...` }
        noOptionsMessage={ () => t`No options` }
      />
    </span>
  )
}

LanguageSelect.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  isInvalid: bool,
  value: string
}

LanguageSelect.defaultProps = {
  isInvalid: false,
  value: undefined
}

export default memo(LanguageSelect, (prevProps, nextProps) => (
  prevProps.value === nextProps.value && prevProps.isInvalid === nextProps.isInvalid
))

import * as Yup from 'yup'
import { t } from 'ttag'

const goalTypeMaxValues = {
  leads: 10000,
  impressions: 10000000,
  visits: 10000000
}

export default Yup.object().shape({
  name: Yup.string()
    .required(t`Please fill a campaign name`),

  goalType: Yup.string()
    .required(t`Please fill a goal type`),

  targetLqs: Yup.string()
    .required(t`Please fill a target LQS`),

  goalNumber: Yup.number()
    .required(t`Please fill a goal number`)
    .positive(t`Goal number should be greater than 0`)
    .when('goalType', (goalType, schema) => {
      if (!goalTypeMaxValues[goalType]) {
        return schema
      }

      return schema.max(
        goalTypeMaxValues[goalType],
        t`Maximum goal number for ${goalType} is ${goalTypeMaxValues[goalType]}`
      )
    }),

  startDate: Yup.date()
    .required(t`Please fill start and end date`),

  endDate: Yup.date()
    .required(t`Please fill start and end date`),

  dsaBeneficiary: Yup.string()
    .required(t`Please fill in the beneficiary`),

  dsaPayor: Yup.string()
    .required(t`Please fill in the payor`),
})

import { gql } from 'apollo-boost'

import campaignLandingPages from 'graphql/fragments/campaignLandingPages'

export default gql`
  query Campaign($uuid: ID!) {
    campaign(uuid: $uuid) {
      ... CampaignLandingPages
    }
  }
  ${campaignLandingPages}
`

import { number, shape, string } from 'prop-types'

export default shape({
  uuid: string.isRequired,
  name: string,
  fallbackLatitude: number,
  fallbackLongitude: number,
  fallbackZipCode: string.isRequired,
  fallbackCountryCode: string.isRequired
})

import React  from 'react'
import styled from 'styled-components'

import stageType from 'shared/types/stageType'
import { buttonTextColor } from 'shared/style/colors'
import useMapCenter from 'shared/hooks/useMapCenter'

import LeftColumn     from './LeftColumn'
import RightColumn    from './RightColumn'
import useDetailsForm from './useDetailsForm'

const DetailsForm = ({ className, stage, ...props }) => {
  const location = stage.location || {}

  const {
    onNameChange,
    onAddressChange,
    onCountryCodeChange,
    onZipCodeChange,
    onLocationChange,
    onBuildingTypesChange,
    onUnitsCountChange,
    onUnitsSoldChange,
    onLowerPriceChange,
    onUpperPriceChange,
    onPriceRangeIsSubjectToChangeChange,
    onSizeIsSubjectToChangeChange,
    onLowerSizeM2Change,
    onUpperSizeM2Change,
    onAttractivenessChange
  } = useDetailsForm(stage.uuid)

  const { mapCenter, setMapCenter } = useMapCenter(location)

  return (
    <div className={ className } data-test-id="editable-stage-details-panel">
      <LeftColumn
        stage={ stage }
        location={ location }
        mapCenter={ mapCenter }
        setMapCenter={ setMapCenter }
        onNameChange={ onNameChange }
        onAddressChange={ onAddressChange }
        onCountryCodeChange={ onCountryCodeChange }
        onZipCodeChange={ onZipCodeChange }
        onLocationChange={ onLocationChange }
        { ...props }
      />

      <RightColumn
        stage={ stage }
        currency={ stage.currency }
        onBuildingTypesChange={ onBuildingTypesChange }
        onUnitsCountChange={ onUnitsCountChange }
        onUnitsSoldChange={ onUnitsSoldChange }
        onLowerPriceChange={ onLowerPriceChange }
        onUpperPriceChange={ onUpperPriceChange }
        onPriceRangeIsSubjectToChangeChange={ onPriceRangeIsSubjectToChangeChange }
        onSizeIsSubjectToChangeChange={ onSizeIsSubjectToChangeChange }
        onLowerSizeM2Change={ onLowerSizeM2Change }
        onUpperSizeM2Change={ onUpperSizeM2Change }
        onAttractivenessChange={ onAttractivenessChange }
        { ...props }
      />
    </div>
  )
}

DetailsForm.propTypes = {
  stage: stageType.isRequired
}

export default styled(DetailsForm)`
  display: flex;
  width: 100%;

  .group {
    display: flex;
    margin-bottom: 0.75rem;
  }

  .group > label {
    color: ${buttonTextColor};
    display: flex;
    font-size: 0.875rem;
    line-height: 2rem;
    flex-direction: column;
    flex-shrink: 0;
    width: 12.5rem;
    margin: 0;

    i {
      font-size: 0.75rem;
      line-height: 1rem;
      width: 8.5rem;
    }
  }

  .form-control {
    box-shadow: none;
    height: 2rem;
  }
`

import React       from 'react'
import styled      from 'styled-components'
import { string }  from 'prop-types'
import { NavLink } from 'react-router-dom'

import { IconSuccess } from 'shared/components/ui/icons'
import { bluishColor } from 'shared/style/colors'

const ResetPasswordSuccessful = ({ className }) => {
  return (
    <div className={ className }>
      <IconSuccess />

      <h1>Password reset complete</h1>

      <p>
        Your password has been reset.
        You may now login using your new password.
      </p>

      <NavLink to="/login" className="btn btn-primary">
        Go to login
      </NavLink>
    </div>
  )
}

ResetPasswordSuccessful.propTypes = {
  className: string.isRequired
}

export default styled(ResetPasswordSuccessful)`
  text-align: center

  h1 {
    margin-bottom: 1.65rem
    margin-top: 1.85rem
  }

  p {
    margin-bottom: 2.875rem
    max-width: 27.625rem
    font-weight: 400
  }

  img {
    display: inline
    height: 4rem
    width: 4rem
  }

  .btn-primary {
    background-color: ${bluishColor}
    border-color: ${bluishColor}
    padding: .35rem 1rem
  }
`

import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { t } from 'ttag'
import { array, object } from 'prop-types'
import { merge, cloneDeep } from 'lodash'

Highcharts.setOptions({
  lang: {
    decimalPoint: ',',
    loading: t`Loading...`,
    months: [
      t`January`, t`February`, t`March`, t`April`,
      t`May`, t`June`, t`July`, t`August`,
      t`September`, t`October`, t`November`, t`December`
    ],
    shortMonths: [
      t`Jan`, t`Feb`, t`Mar`, t`Apr`, t`May`, t`Jun`,
      t`Jul`, t`Aug`, t`Sep`, t`Oct`, t`Nov`, t`Des`
    ],
    weekdays: [
      t`Sunday`, t`Monday`, t`Tuesday`, t`Wednesday`,
      t`Thursday`, t`Friday`, t`Saturday`
    ],
    shortWeekdays: undefined,
    thousandsSep: ' ',
  }
})

const staticOptions = {
  legend: {
    layout: 'vertical',
    align: 'left',
    verticalAlign: 'top',
    x: 100,
    y: 10,
    floating: true,
    borderWidth: 1,
    borderRadius: 3,
    itemMarginBottom: 3,
    itemMarginTop: 3
  },
  chart: {
    type: 'areaspline',
    reflow: true
  },
  title: {
    text: undefined
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%d.%m.%y'
    },
    crosshair: true,
    gridLineWidth: 1
  },
  yAxis: {
    title: {
      enabled: false
    },
    gridLineWidth: 0
  },
  tooltip: {
    shared: true,
    dateTimeLabelFormats: {
      day: '%d.%m.%y'
    }
  },
  credits: {
    enabled: false
  },
  plotOptions: {
    areaspline: {
      fillOpacity: 0.2
    }
  }
}

const Chart = ({ data, chartOptions }) => {
  const series = data.map((dataset) => ({
    name: dataset.name,
    color: dataset.color,
    data: dataset.seriesData.map((datum) => [Date.parse(datum.date), datum.value]),
    tooltipFormatter: dataset.tooltipFormatter
  }))

  const tooltipFormatter = series
    .map((dataset) => dataset.tooltipFormatter)
    .filter((obj) => obj)[0]

  const baseOptions = cloneDeep(staticOptions)

  let options = {
    ...baseOptions,
    series,
    tooltip: {
      ...baseOptions,
      pointFormatter: tooltipFormatter
        ? function() {
          return `${this.series.name}: <b>${tooltipFormatter(this.y)}</b><br />`
        }
        : undefined
    }
  }

  if(series.length < 2) {
    options = {
      ...options,
      legend: { enabled: false }
    }
  }

  options = merge(options, chartOptions)

  return (
    <HighchartsReact
      highcharts={ Highcharts }
      options={ options }
    />
  )
}

Chart.propTypes = {
  chartOptions: object,
  data: array
}

Chart.defaultProps = {
  data: [],
  chartOptions: {}
}

export default Chart

import { useEffect }  from 'react'
import axios from 'axios'

import useUrlParams from 'shared/hooks/useUrlParams'
import { logout } from 'shared/helpers/auth'

const M360Logout = () => {
  const [urlParams] = useUrlParams()
  const accessToken = urlParams['accessToken']
  const redirectToRoot = () => window.location.replace('/')

  useEffect(() => {
    const m360PingEndpoint = '/api/m360/v1/ping'

    axios.get(m360PingEndpoint, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then(logout)
      .catch(redirectToRoot)

  }, [])

  return null
}

export default M360Logout

import React, { useContext } from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import { t } from 'ttag'

import Modal from 'shared/components/ui/Modal'

import { UnitsTableContext } from '../UnitsList/Units/UnitsTable'

import DocumentsList from './DocumentsList'

const DocumentsModal = ({ className }) => {
  const {
    showModal,
    setShowModal,
    currentUnitUuid,
    currentUnitName
  } = useContext(UnitsTableContext)

  const onHide = () => setShowModal(false)

  if (!currentUnitUuid) return null

  return (
    <Modal
      show={ showModal }
      onHide={ onHide }
      dialogClassName={ className }
      data-test-id='unit-documents-modal'
    >
      <Modal.Header closeButton>
        { t`Documents for unit ` + currentUnitName }
      </Modal.Header>
      <Modal.Body>
        <DocumentsList unitUuid={ currentUnitUuid } />
      </Modal.Body>
    </Modal>
  )
}

DocumentsModal.defaultProps = {
  className: null
}

DocumentsModal.propTypes = {
  className: string
}

export default styled(DocumentsModal)`
  max-width: 1000px
`

import React from 'react'
import { string, node, object } from 'prop-types'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'

import { textColor } from 'shared/style/colors'
import { inputHoverBoxShadow, cardBoxShadow } from 'shared/style/placeholders'
import MissingDataIndicator from 'shared/components/Indicators/MissingData'
import thumbnailPlaceholder from 'shared/assets/images/card-placeholder.png'

import HeaderMessage from './HeaderMessage'

const Item = ({ header, subheader, thumbnail, className, missingData, children }) => {
  return (
    <Card className={ className }>
      <div className='card-container'>
        <div
          className='thumbnail'
          style={{ backgroundImage: `url(${thumbnail || thumbnailPlaceholder})` }}
        >
          <MissingDataIndicator missingData={ missingData } />
        </div>

        <div className='summary'>
          <div className='limited-row header'>
            <HeaderMessage>{ header }</HeaderMessage>
          </div>
          <div className='limited-row subheader'>{ subheader }</div>

          { children }
        </div>
      </div>
    </Card>
  )
}

Item.propTypes = {
  children: node.isRequired,
  className: string.isRequired,
  header: string.isRequired,
  subheader: string.isRequired,
  missingData: object,
  thumbnail: string
}

Item.defaultProps = {
  missingData: undefined,
  thumbnail: undefined
}

export default styled(Item)`
  width: 12rem
  min-height: 15rem
  margin: 1rem
  border-radius: 4px
  ${cardBoxShadow}
  background-color: #ffffff;
  overflow: hidden
  color: ${textColor}
  cursor: pointer

  &:hover {
    ${inputHoverBoxShadow}
  }

  .card-container {
    height: 100%
    display: flex
    flex-direction: column
  }

  .thumbnail {
    width: 100%
    height: 7rem
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    flex: 1 0 auto
  }

  .summary {
    padding: 0.5rem 0
    text-align: center
    flex-shrink: 0
  }

  .limited-row {
    overflow: hidden
    padding-left: 0.25rem
    padding-right: 0.25rem
  }

  .header {
    max-height: 4.75rem
    font-size: 1rem
    font-weight: 600
    word-break: break-word
  }

  .subheader {
    font-size: 0.75rem
    font-weight: 500
    opacity: 0.7
  }
`

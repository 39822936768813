import React from 'react'
import { Formik } from 'formik'

import productType from 'shared/types/productType'
import validationSchema
  from 'shared/components/ProductDetails/StageDetails/Details/validationSchema'

import DetailsForm from './DetailsForm'

const Details = ({ product, ...props }) => {
  const stage = product.promotable
  const initialValues = {
    name: stage.name,
    address: location.address,
    countryCode: location.countryCode,
    zipCode: location.zipCode,
    location: {
      latitude: location.latitude,
      longitude: location.longitude
    },
    buildingTypes: stage.buildingTypes,
    unitsCount: stage.unitsCount,
    unitsSold: stage.unitsSold,
    lowerPrice: stage.lowerPrice,
    upperPrice: stage.upperPrice,
    priceRangeIsSubjectToChange: stage.priceRangeIsSubjectToChange || false,
    lowerSizeM2: stage.lowerSizeM2,
    upperSizeM2: stage.upperSizeM2,
    sizeRangeIsSubjectToChange: stage.sizeRangeIsSubjectToChange || false,
    attractiveness: [stage.attractiveness || 1],
  }

  return (
    <Formik initialValues={ initialValues } validationSchema={ validationSchema }>
      <DetailsForm stage={ stage } { ...props }  />
    </Formik>
  )
}

Details.propTypes = {
  product: productType.isRequired
}

export default Details

import React, { useRef } from 'react'
import { any, func } from 'prop-types'
import { Form } from 'react-bootstrap'

const FormInput = ({ value, onUpdate }) => {
  const inputRef = useRef()

  const handleUpdate = () => {
    const currentValue = inputRef?.current?.value
    onUpdate(currentValue)
  }

  return (
    <Form.Control
      defaultValue={ value }
      ref={ inputRef }
      onBlur={ handleUpdate }
    />
  )
}

FormInput.propTypes = {
  onUpdate: func.isRequired,
  value: any
}

FormInput.defaultProps = {
  value: null,
}

export default FormInput

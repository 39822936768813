import React  from 'react'
import { t }  from 'ttag'
import styled from 'styled-components'

import projectType from 'shared/types/projectType'

import Card   from '../Card'

import Block  from './Block'

const SalesPercentage = ({ className, project }) => {
  return (
    <Card headerText={ t`Sales percentage` }>
      <div className={ className } data-test-id='sales-percentage-card'>
        <Block
          isProject
          text={ t`Total` }
          percentage={ project.percentageSoldByValue }
        />
        { project.completeStages.map((stage) => (
          <Block
            text={ stage.name }
            percentage={ stage.percentageSoldByValue }
            key={ stage.uuid }
          />
        ))}
      </div>
    </Card>
  )
}

SalesPercentage.propTypes = {
  project: projectType.isRequired
}

export default styled(SalesPercentage)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

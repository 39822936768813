import React from 'react'

import BaseCompanySelect     from 'shared/components/CompanySelect'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const CompanySelect = (props) => {
  return (
    <BaseCompanySelect
      styles={ condensedSelectStyles }
      { ...props }
    />
  )
}

export default CompanySelect

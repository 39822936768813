import NProgress from 'nprogress'

import { progressBarDelay } from 'shared/constants'

const fetchWithProgress = (...args) => {
  const progressTimer = setTimeout(NProgress.start, progressBarDelay)

  return fetch(...args)
    .finally(() => {
      clearTimeout(progressTimer)
      NProgress.done()
    })
}

export default fetchWithProgress

import gql from 'graphql-tag'

import assetAssignment from 'graphql/fragments/assetAssignment'

export default gql`
  fragment Creative on Creative {
    uuid
    enabled
    assetAssignment {
      ... AssetAssignment
    }
  }
  ${assetAssignment}
`

import { string } from 'prop-types'
import moment     from 'moment'

const EmVest = 'em_vest'

const useEmVestStartDateInDeadPeriod = (startDate, source) => {
  if (source !== EmVest) return false

  const prevFriday = moment(startDate).utc().day(-2).startOf('day').add(14, 'hour')
  const prevMonday = moment(startDate).utc().day(1).startOf('day').add(7, 'hour')
  const nextFriday = moment(startDate).utc().day(5).startOf('day').add(14, 'hour')
  const nextMonday = moment(startDate).utc().day(8).startOf('day').add(7, 'hour')
  const inPrevDeadPeriod = moment(startDate).isBetween(prevFriday, prevMonday)
  const inNextDeadPeriod = moment(startDate).isBetween(nextFriday, nextMonday)

  return (inPrevDeadPeriod || inNextDeadPeriod)
}

useEmVestStartDateInDeadPeriod.propTypes = {
  source: string.isRequired,
  startDate: string.isRequired
}

export default useEmVestStartDateInDeadPeriod

import React, { useCallback, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { t } from 'ttag'
import styled from 'styled-components'
import { useApolloClient, useMutation } from 'react-apollo'
import { IconAdd, IconMinus } from 'cabanaico'
import { isEqual, get } from 'lodash'

import Tooltip from 'shared/components/ui/Tooltip'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import FormLabel from 'shared/components/ui/FormLabel'
import Button from 'shared/components/ui/Button'
import createSnapshotUnitSetMutation from 'graphql/mutations/snapshots/unitSets/create'
import removeSnapshotUnitSetMutation from 'graphql/mutations/snapshots/unitSets/remove'
import createSnapshotCustomFacilityMutation
  from 'graphql/mutations/snapshots/customFacilities/create'
import removeSnapshotCustomFacilityMutation
  from 'graphql/mutations/snapshots/customFacilities/remove'
import updateSnapshotCustomFacilityMutation
  from 'graphql/mutations/snapshots/customFacilities/update'
import updateFacilityAssignmentsMutation from 'graphql/mutations/facilities/updateAssignments'
import snapshotFragment from 'graphql/fragments/snapshot'
import { buildFragmentParams } from 'shared/helpers/graphql'

import FacilitySelect   from './FacilitySelect'
import updateSnapshot   from './updateSnapshot'
import SnapshotUnitSet  from './SnapshotUnitSet'
import CustomFacilities from './CustomFacilities'
import FacilityDetails  from './FacilityDetails'

const SnapshotForm = ({
  className,
  creatingMode,
  currency,
  isSnapshotActive,
  setSnapshots,
  snapshot,
  snapshotableName,
  projectUuid
}) => {
  const client = useApolloClient()
  const [focusedInput, setFocusedInput] = useState(null)
  const [runCreateSnapshotUnitSetMutation] = useMutation(createSnapshotUnitSetMutation)
  const [runRemoveSnapshotUnitSetMutation] = useMutation(removeSnapshotUnitSetMutation)
  const [runUpdateAssignmentsMutation] = useMutation(updateFacilityAssignmentsMutation)
  const [runCreateSnapshotCustomFacilityMutation] = useMutation(
    createSnapshotCustomFacilityMutation
  )
  const [runRemoveSnapshotCustomFacilityMutation] = useMutation(
    removeSnapshotCustomFacilityMutation
  )
  const [runUpdateSnapshotCustomFacilityMutation] = useMutation(
    updateSnapshotCustomFacilityMutation
  )

  const updateUnitSetState = useCallback((unitSetUuid, data) => {
    setSnapshots((prevSnapshots) => {
      return prevSnapshots.map(prevSnapshot => {
        if (prevSnapshot.uuid === snapshot.uuid) {
          const unitSets = prevSnapshot.unitSets.map((unitSet) => {
            if (unitSet.uuid === unitSetUuid) {
              return { ...unitSet, ...data }
            }
            return unitSet
          })

          return { ...prevSnapshot, unitSets }
        }
        return prevSnapshot
      })
    })
  }, [snapshot])

  const syncCache = (dataKey, dataTransformation) => {
    return (cache, response) => {
      const object = { __typename: 'Snapshot', uuid: snapshot.uuid }
      const fragmentParams = buildFragmentParams(object, snapshotFragment)
      const fragmentData = cache.readFragment(fragmentParams)
      const responseData = get(response, dataKey)

      cache.writeFragment({
        ...fragmentParams,
        data: dataTransformation({ fragmentData, responseData })
      })
    }
  }

  const addUnitSet = useCallback(() => {
    const dataKey = 'data.createSnapshotUnitSet.unitSet'
    const dataTransformation = ({ fragmentData, responseData: unitSet }) => {
      return {
        ...fragmentData,
        unitSets: [ ...fragmentData.unitSets, unitSet ]
      }
    }

    return runCreateSnapshotUnitSetMutation({
      variables: { snapshotUuid: snapshot.uuid },
      update: syncCache(dataKey, dataTransformation)
    })
  }, [snapshot])

  const removeUnitSet = useCallback(() => {
    const removedUnitSetIndex = snapshot.unitSets.length - 1
    if(removedUnitSetIndex < 1) { return }

    const removedUnitSet = snapshot.unitSets[removedUnitSetIndex]
    if(!removedUnitSet) { return }

    const dataKey = 'data.removeSnapshotUnitSet.unitSet.uuid'
    const dataTransformation = ({ fragmentData, responseData: removedUnitSetUuid }) => {
      return {
        ...fragmentData,
        unitSets: fragmentData.unitSets.filter(unitSet => unitSet.uuid !== removedUnitSetUuid)
      }
    }

    return runRemoveSnapshotUnitSetMutation({
      variables: { uuid: removedUnitSet.uuid },
      update: syncCache(dataKey, dataTransformation)
    })
  }, [snapshot])

  const addCustomFacility = useCallback(({ data }) => {
    const dataKey = 'data.createSnapshotCustomFacility.customFacility'
    const dataTransformation = ({ fragmentData, responseData: newCustomFacility }) => {
      return {
        ...fragmentData,
        customFacilities: [ ...fragmentData.customFacilities, newCustomFacility ]
      }
    }

    runCreateSnapshotCustomFacilityMutation({
      variables: { snapshotUuid: snapshot.uuid, input: data },
      update: syncCache(dataKey, dataTransformation)
    })
  }, [snapshot])

  const updateCustomFacility = useCallback(({ uuid, data }) => {
    runUpdateSnapshotCustomFacilityMutation({
      variables: { uuid: uuid, snapshotUuid: snapshot.uuid, input: data }
    })
  }, [snapshot])

  const deleteCustomFacility = useCallback(({ uuid }) => {
    const dataKey = 'data.removeSnapshotCustomFacility.customFacility.uuid'
    const dataTransformation = ({ fragmentData, responseData: removedCustomFacilityUuid }) => {
      return {
        ...fragmentData,
        customFacilities: fragmentData.customFacilities.filter((customFacility) => {
          return customFacility.uuid !== removedCustomFacilityUuid
        })
      }
    }

    runRemoveSnapshotCustomFacilityMutation({
      variables: { uuid: uuid },
      update: syncCache(dataKey, dataTransformation)
    })
  }, [snapshot])

  const removeLastFocus = useCallback(() =>  {
    if (focusedInput) {
      focusedInput.blur()
      setFocusedInput(null)
    }
  }, [])

  const handleStringFormChange = useCallback((value, fieldName) => {
    const updateInput = { [fieldName]: value }
    setSnapshots(prev => {
      return prev.map(prevSnapshot => {
        if (prevSnapshot.uuid === snapshot.uuid) {
          return { ...prevSnapshot, ...updateInput  }
        }
        return prevSnapshot
      })
    })

    updateSnapshot(client, snapshot.uuid, updateInput)
  }, [snapshot.uuid])

  const handleFacilitiesChange = useCallback((values) => {
    let facilities = []
    if (values) {
      facilities = values.map(value => ({
        key: value.value,
        uuid: value.uuid,
        title: value.label
      }))
    }

    return runUpdateAssignmentsMutation({
      variables: {
        facilitiesUuid: facilities.map(v => v.uuid),
        facilityableUuid: snapshot.uuid,
        facilityableType: 'snapshot'
      },
      update: (cache, response) => {
        const object = { __typename: 'Snapshot', uuid: snapshot.uuid }
        const fragmentParams = buildFragmentParams(object, snapshotFragment)
        const fragmentData = cache.readFragment(fragmentParams)
        const facilities = get(response, 'data.updateFacilityAssignments.facilities')
        cache.writeFragment({
          ...fragmentParams,
          data: { ...fragmentData, facilities: facilities }
        })
      }
    }).then(() => {
      setSnapshots(prev => {
        return prev.map(prevSnapshot => {
          if (prevSnapshot.uuid === snapshot.uuid) {
            return { ...prevSnapshot, facilities }
          }
          return prevSnapshot
        })
      })
    })
  }, [snapshot.uuid])

  const renderDraftStatus = useCallback((snapshot, fieldName) => {
    if (!isSnapshotActive || creatingMode ) { return }
    if (snapshot[fieldName + 'Draft'] === snapshot[fieldName]) { return }

    // eslint-disable-next-line max-len
    const tooltipContent = t`This content is in draft mode. Press Publish to update portal with the changes`

    return (
      <div className="status">
        <Tooltip content={ tooltipContent }>
          <span>ⓘ</span>
        </Tooltip>
      </div>
    )
  }, [isSnapshotActive, creatingMode])

  return (
    <div className={ className }>
      <div className="group">
        <FormLabel>{ t`${snapshotableName} headline` }</FormLabel>
        <div className="controls-with-status">
          <div className="controls">
            <InputWrapper>
              <ThriftyInput
                type="text"
                value={ snapshot.headlineDraft }
                data-test-id='headline-draft-field'
                onUpdate={ value => handleStringFormChange(value, 'headlineDraft') }
                onFocus={ e => setFocusedInput(e.target) }
              />
            </InputWrapper>
          </div>
          { renderDraftStatus(snapshot, 'headline') }
        </div>
      </div>

      <div className="group">
        <FormLabel>{ t`Description` }</FormLabel>
        <div className="controls-with-status">
          <div className="controls">
            <InputWrapper>
              <ThriftyInput
                type="text"
                as="textarea"
                data-test-id='custom-description-draft-field'
                value={ snapshot.customDescriptionDraft }
                onUpdate={ value => handleStringFormChange(value, 'customDescriptionDraft') }
                onFocus={ e => setFocusedInput(e.target) }
              />
            </InputWrapper>
          </div>
          { renderDraftStatus(snapshot, 'customDescription') }
        </div>
      </div>

      { snapshot.unitSets.map(unitSet => (
        <SnapshotUnitSet
          key={ unitSet.uuid }
          currency={ currency }
          unitSet={ unitSet }
          data-test-id={ `unit-set-${unitSet.uuid}` }
          updateUnitSetState={ updateUnitSetState }
        />
      )) }

      <div className='form-inline unit-set-buttons'>
        <Button
          variant='default'
          className='unit-btn'
          data-test-id='add-unit-set-button'
          onClick={ addUnitSet }>
          <IconAdd /> { t`Add new unit set` }
        </Button>
        <Button
          variant='default'
          className='unit-btn'
          onClick={ removeUnitSet }
          data-test-id='remove-unit-set-button'
          disabled={ snapshot.unitSets.length < 2 }
        >
          <IconMinus /> { t`Remove unit set` }
        </Button>
      </div>

      <div className="group">
        <FormLabel>{ t`Facilities` }</FormLabel>
        <InputWrapper onClick={ removeLastFocus }>
          <FacilitySelect
            data-test-id='facilities-selector'
            facilities={ snapshot.facilities }
            onChange={ handleFacilitiesChange }
          />
        </InputWrapper>
      </div>

      { projectUuid && snapshot.facilities.length !== 0 && (
        <div className="group">
          <InputWrapper>
            <FacilityDetails
              projectUuid={ projectUuid }
              facilities={ snapshot.facilities } />
          </InputWrapper>
        </div>
      ) }

      <div className="group">
        <CustomFacilities
          facilities={ snapshot.customFacilities  }
          addCustomFacility={ addCustomFacility }
          updateCustomFacility={ updateCustomFacility }
          deleteCustomFacility={ deleteCustomFacility }
        />
      </div>
    </div>
  )
}

SnapshotForm.propTypes = {
  currency: PropTypes.string.isRequired,
  setSnapshots: PropTypes.func.isRequired,
  snapshot: PropTypes.object.isRequired,
  snapshotableName: PropTypes.string.isRequired,
  creatingMode: PropTypes.bool,
  isSnapshotActive: PropTypes.bool,
  projectUuid: PropTypes.string
}

SnapshotForm.defaultProps = {
  creatingMode: false,
  isSnapshotActive: false,
  projectUuid: undefined
}

const avoidRerender = (prev, next) => {
  if (prev.creatingMode !== next.creatingMode) {
    return false
  }

  if (prev.isSnapshotActive !== next.isSnapshotActive) {
    return false
  }

  return isEqual(prev.snapshot, next.snapshot)
}

export default styled(memo(SnapshotForm, avoidRerender))`
  input, textarea {
    box-shadow: none;
  }

  .inline-input {
    width: 90%;
  }

  .group {
    margin-bottom: 10px;
  }

  .controls-with-status{
    display: flex;

    .controls {
      flex-basis: 100%
    }

    .status {
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
    }
  }

  .form-inline label {
    display: flex;
    justify-content: left;
    margin-bottom: 0;
    align-items: flex-start;
  }

  .double-input {
    width: 90%;
    display: inline-flex;
    justify-content: space-between;

    div:first-child {
      width: 58%;
    }

    div:last-child {
      width: 42%;
    }
  }

  .checkbox-wrap {
    max-width: 10%;
    text-align: center;
  }

  .checkbox-input {
    display: flex;
    justify-content: center;
  }

  .unit-set-buttons {
    margin-bottom: 20px;

    .unit-btn {
      margin-right: 10px;
    }
  }
`

import React   from 'react'
import Select  from 'react-select'
import { get } from 'lodash'
import { t }   from 'ttag'
import { bool, oneOfType, string, number, func } from 'prop-types'

import condensedSelectStyles from 'shared/style/condensedSelectStyles'
import useCompanies          from 'shared/hooks/useCompanies'

const styles = {
  ...condensedSelectStyles,
  container: provided => ({
    ...provided,
    marginRight: '0.5rem',
    minWidth: '5rem'
  })
}

const CompanySelect = ({ value, onChange, isDisabled, ...props }) => {
  const { companies } = useCompanies()

  const allOptions = companies
    .map(company => ({ label: company.name, value: company.uuid }))
  const selectedOption = allOptions.find(el => el.value === value)
  const handleChange = option => onChange(get(option, 'value', null))

  return (
    <span data-test-id='company-select-wrapper'>
      <Select
        value={ selectedOption }
        options={ allOptions }
        onChange={ handleChange }
        isDisabled={ isDisabled }
        placeholder=''
        noOptionsMessage={ () => t`No options` }
        styles={ styles }
        { ...props }
      />
    </span>
  )
}

CompanySelect.propTypes = {
  isDisabled: bool.isRequired,
  onChange: func,
  value: oneOfType([string, number])
}

CompanySelect.defaultProps = {
  onChange: () => {},
  value: ''
}

export default CompanySelect

import React, { useEffect, useCallback, useRef } from 'react'
import Select from 'react-select'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'
import { func, string } from 'prop-types'

import stagesQuery from 'graphql/queries/stages'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const StageSelect = ({ projectUuid, value, onChange, ...props }) => {
  const previousProjectUuid = useRef(projectUuid)

  const { data } = useQuery(stagesQuery, {
    variables: { projectUuid: projectUuid },
    skip: !projectUuid
  })

  const options = get(data, 'stages', []).map(stage => ({
    value: stage.uuid,
    label: stage.name,
    ...stage
  }))

  const selectedOption = options.find(el => el.value === value) || null

  const handleChange = useCallback(
    option => onChange(get(option, 'value', null)),
    [onChange]
  )

  useEffect(() => {
    if (previousProjectUuid.current !== projectUuid) {
      previousProjectUuid.current = projectUuid
      handleChange(null)
    }

  }, [projectUuid, handleChange])

  return (
    <Select
      isDisabled={ !projectUuid }
      value={ selectedOption }
      options={ options }
      onChange={ handleChange }
      styles={ condensedSelectStyles }
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

StageSelect.propTypes = {
  onChange: func.isRequired,
  projectUuid: string,
  value: string
}

StageSelect.defaultProps = {
  projectUuid: null,
  value: ''
}

export default StageSelect

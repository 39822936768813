import React from 'react'
import { t } from 'ttag'
import { Formik } from 'formik'
import { bool, func, string } from 'prop-types'

import Button from 'shared/components/ui/Button'
import Modal  from 'shared/components/ui/Modal'

import FormFields       from './FormFields'
import validationSchema from './validationSchema'
import useNewUserForm   from './useNewUserForm'

const NewUserForm = ({ show, close, companyUuid }) => {
  const { initialValues, submitForm } = useNewUserForm(companyUuid, close)

  return (
    <Formik
      initialValues={ initialValues }
      validationSchema={ validationSchema }
      onSubmit={ submitForm }
    >
      {({ handleSubmit, isSubmitting }) => (
        <Modal show={ show } onHide={ close }>
          <Modal.Header closeButton>
            <Modal.Title>{ t`New user` }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormFields />

            <div className="d-flex justify-content-end mt-2">
              <Button onClick={ close } className="mr-1">
                { t`Cancel` }
              </Button>
              <Button onClick={ handleSubmit } disabled={ isSubmitting } type="submit">
                { t`Create` }
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  )
}

NewUserForm.propTypes = {
  close: func.isRequired,
  companyUuid: string.isRequired,
  show: bool.isRequired
}

export default NewUserForm

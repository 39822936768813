import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { number, object, func } from 'prop-types'
import mapboxgl from 'mapbox-gl'

import { MapMarkerIcon } from 'shared/components/ui/icons'

const Marker = ({ map, latitude, longitude, onDragEnd }) => {
  useEffect(() => {
    if (!map || !latitude || !longitude) return

    const markerIcon = document.createElement('div')
    ReactDOM.render(
      <MapMarkerIcon className={ 'operator-include' } />,
      markerIcon
    )

    const marker = new mapboxgl.Marker({ element: markerIcon, draggable: true,  })
      .setLngLat([longitude, latitude])
      .addTo(map)

    marker.on('dragend', onDragEnd)

    return () => marker.remove()
  }, [map, latitude, longitude])

  return null
}

Marker.propTypes = {
  latitude: number,
  longitude: number,
  map: object,
  onDragEnd: func
}

Marker.defaultProps = {
  map: undefined,
  latitude: undefined,
  longitude: undefined,
  onDragEnd: () => {}
}
export default Marker

import handlePayloadValidation from 'shared/components/ValidatableTabs/handlePayloadValidation'

import validateForecast from './validateForecast'
import validateMedia from './validateMedia'
import validateTargeting from './validateTargeting'
import validateCreativeSets from './validateCreativeSets'
import validateLandingPages from './validateLandingPages'
import validateLandingPageContent from './validateLandingPageContent'

const fetchValidationData = ({ validatorNumber }) => {
  return [
    validateForecast,
    validateMedia,
    validateTargeting,
    validateCreativeSets,
    validateLandingPages,
    validateLandingPageContent
  ][validatorNumber]
}

const buildValidator = ({ setValidationPending, setErrors, validatorNumber }) => {
  const validatePayload = fetchValidationData({ validatorNumber })

  return ({ payload, propogateError, force } = {}) => handlePayloadValidation({
    validatePayload,
    payload,
    propogateError,
    setErrors,
    setValidationPending,
    force
  })
}

export default buildValidator

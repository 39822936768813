import React from 'react'

import PreviewLink from './PreviewLink'

export default function adPreviewFormatter(cell, row) {
  return (
    <PreviewLink { ...row }>
      <img src={ cell?.thumbnail } height="50" />
    </PreviewLink>
  )
}

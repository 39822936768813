import { shape, string, object } from 'prop-types'

export default shape({
  timezone: string,
  defaultLanguage: string,
  notifications: shape({
    app: object,
    email: object
  })
})

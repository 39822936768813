import React      from 'react'
import { t }      from 'ttag'
import { string } from 'prop-types'
import styled     from 'styled-components'

import globe       from 'shared/assets/icons/globe.png'
import getImageUrl from 'shared/helpers/getImageUrl'
import { greyishBrownColor, textColorLight } from 'shared/style/colors'

const AdHeader = ({ logo, companyName, className }) => {
  return (
    <div className={ className }>
      <img
        className="logo"
        src={ getImageUrl(logo) }
      />
      <div className="company-name">
        { companyName }
      </div>
      <div className="sponsored">
        Sponsored
        <img
          alt={ t`facebook 'sponsored' icon` }
          src={ getImageUrl(globe) }
          className="globe"
        />
      </div>
    </div>
  )
}

AdHeader.propTypes = {
  companyName: string,
  logo: string

}

AdHeader.defaultProps = {
  companyName: '',
  logo: ''
}

export default styled(AdHeader)`
  overflow: hidden;
  padding: 0.5625rem

  .logo {
    width: 2.5rem;
    height: 2.5rem;
    float: left;
    margin-right: 0.625rem
  }

  .company-name {
    color: ${greyishBrownColor};
    font-size: 0.8125rem;
    font-family: Helvetica;
    font-weight: bold;
    padding-top: .15rem
  }

  .sponsored {
    align-items: center;
    color: ${textColorLight};
    display: flex;
    font-size: 0.6875rem;
    font-family: Helvetica;
    margin-top: 0
  }

  .globe {
    margin-left: .25rem
  }

  .like {
    float: right
  }

  .body {
    clear: both;
    color: #000000;
    font-size: 0.6875rem;
    margin: .75rem 0 .9rem
  }
`

import React from 'react'
import { t } from 'ttag'
import PropTypes from 'prop-types'

import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'
import Switch from 'shared/components/ui/Switch'
import Button from 'shared/components/ui/Button'

const PortalPublication = ({ className, portalEnabled, updateStage }) => {
  // TODO: implement portal previews
  const handlePreview = () => {}
  const onPortalEnabledChanged = () => {
    updateStage({ portalEnabled: !portalEnabled })
  }

  return (
    <EmbeddedCardSection titleText={ t`Portal publication` } className={ className }>
      <div className="controls-area">
        <div className="btn-group d-flex controls-list" role="group">
          <Switch
            className="w-100 control-item"
            onChange={ onPortalEnabledChanged }
            data-test-id='portal-enable-switch'
            checked={ portalEnabled }>
            { t`Show on portal` }
          </Switch>
          <div className="w-100 control-item">
            <div className="btn-group d-flex portal-buttons" role="group">
              <Button
                disabled
                data-test-id='preview-button'
                onClick={ handlePreview }>
                { t`Preview` }
              </Button>
            </div>
          </div>
        </div>
      </div>
    </EmbeddedCardSection>
  )
}

PortalPublication.propTypes = {
  portalEnabled: PropTypes.bool.isRequired,
  updateStage: PropTypes.func.isRequired
}

export default PortalPublication

import { gql } from 'apollo-boost'

export default gql`
  query Buildings($projectUuid: ID!) {
    buildings(projectUuid: $projectUuid) {
      id
      name
    }
  }
`

import gql from 'graphql-tag'

import stage from 'graphql/fragments/stage'
import productWithSnapshots from 'graphql/fragments/productWithSnapshots'
import snapshot from 'graphql/fragments/snapshot'

export default gql`
  query Stage(
    $uuid: ID!
  ) {
    stage(uuid: $uuid) {
      ... Stage
      
      snapshots {
        ... Snapshot
      }
     
      currentSnapshotRecord {
        ... Snapshot
      }

      product {
        ... ProductWithSnapshots
      }
    }
  }
  ${stage}
  ${productWithSnapshots}
  ${snapshot}
`

import React from 'react'
import { object, bool } from 'prop-types'
import { t } from 'ttag'
import ReactSelect from 'react-select'

// TODO: add styles for a regular sized select
const smallStyles = {
  control: provided => ({
    ...provided,
    boxShadow: 'none',
    minHeight: 'initial',
    height: '1.9rem',
    fontSize: '0.875rem',
    fontWeight: 500
  }),
  placeholder: provided => ({
    ...provided,
    lineHeight: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '0 .2rem'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 0.5rem',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 1000
  })
}

const Select = ({ styles, small: _small, ...props }) => {
  return (
    <ReactSelect
      styles={{ ...(smallStyles), ...styles }}
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

Select.propTypes = {
  small: bool,
  styles: object
}

Select.defaultProps = {
  small: false,
  styles: undefined
}

export default Select

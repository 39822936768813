import assignCampaignDateMutation from 'graphql/mutations/campaign/assignCampaignDate'

const assignCampaignDate = ({ client, uuid, name, value, update }) => (
  client.mutate({
    mutation: assignCampaignDateMutation,
    variables: {
      uuid,
      [name]: value
    },
    update
  })
)

export default assignCampaignDate

import React                    from 'react'
import { func, number, string } from 'prop-types'
import { Editor }               from '@tinymce/tinymce-react'

import credentials from 'config/credentials'

const WysiwygEditor = ({ initialValue, onChange, height, id }) => {
  const menubar = false
  const statusbar = false
  const plugins = [
    'advlist autolink autoresize lists link image',
    'charmap print preview anchor help',
    'searchreplace visualblocks code',
    'insertdatetime media table paste wordcount'
  ]
  const toolbar
    = 'fontselect fontsizeselect | '
    + 'forecolor backcolor | '
    + 'link | '
    + 'bold italic strikethrough underline | '
    + 'alignleft aligncenter alignjustify alignright | '
    + 'bullist numlist outdent indent | '
    + 'code | '
    + 'help'
  const minHeight = (height || 500)
  const maxHeight = minHeight + 200

  return (
    <Editor
      apiKey={ credentials.tinyMceEditorApiKey }
      id={ id }
      initialValue={ initialValue }
      init={{
        plugins: plugins,
        menubar: menubar,
        statusbar: statusbar,
        toolbar: toolbar,
        height: minHeight,
        min_height: minHeight,
        max_height: maxHeight,
        autoresize_min_height: minHeight,
        autoresize_max_height: maxHeight,
        autoresize_bottom_margin: 0,
        autoresize_overflow_padding: 0,
        relative_urls : false,
        remove_script_host : false,
        convert_urls : true
      }}
      onChange={ onChange }
      data-test-id='wysiwyg-editor-text-input'
    />
  )
}

WysiwygEditor.propTypes = {
  height: number,
  id: string,
  initialValue: string,
  onChange: func
}

WysiwygEditor.defaultProps = {
  height: null,
  id: null,
  initialValue: null,
  onChange: null
}

export default WysiwygEditor

import styled from 'styled-components'

export default styled.h4`
  color: #626262
  font-size: 1rem
  font-weight: 500
  line-height: 1.2rem
  margin: .8rem 0
  text-transform: uppercase
`

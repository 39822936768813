import React from 'react'

import matchType from 'shared/types/matchType'
import MissingData from 'shared/components/NewAutoCampaign/Stages/MissingData'

const StageMissingData = ({ match }) => <MissingData stageUuid={ match.params.uuid } />

StageMissingData.propTypes = {
  match: matchType.isRequired
}

export default StageMissingData

import React from 'react'
import { arrayOf } from 'prop-types'

import Asset from 'shared/components/ui/Asset'
import assetType from 'shared/types/assetType'
import AssetsContainer from 'shared/components/ui/AssetsContainer'

import BaseGallery from './BaseGallery'

const Gallery = ({ assets }) => {
  return (
    <BaseGallery assets={ assets }>
      {({ controls }) => (
        <AssetsContainer>
          { assets.map((asset, key) => (
            <Asset
              onClick={ () => controls.open(key) }
              key={ asset.image }
              asset={ asset }
            />
          )) }
        </AssetsContainer>
      )}
    </BaseGallery>
  )
}

Gallery.propTypes = {
  assets: arrayOf(assetType).isRequired
}

export default Gallery

import gql from 'graphql-tag'

export default gql`
  fragment SnapshotPhase on SnapshotPhase {
    uuid
    title
    startDate
    endDate
    phasePeriod
  }
`

import React from 'react'
import { bool, func, string } from 'prop-types'
import styled from 'styled-components'
import { IconCaretUp, IconCaretDown } from 'cabanaico'

const Caret = ({ opened, onClick, className, ...props }) => {
  return (
    <span className={ className } onClick={ onClick } { ...props }>
      {
        opened
          ? <IconCaretUp />
          : <IconCaretDown />
      }
    </span>
  )
}

Caret.propTypes = {
  className: string.isRequired,
  opened: bool.isRequired,
  onClick: func
}

Caret.defaultProps = {
  onClick: () => {}
}

export default styled(Caret)`
  cursor: pointer
`

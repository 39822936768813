import 'core-js'
import 'cross-fetch/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

import credentials from 'config/credentials'
import App from 'App'

Sentry.init({
  dsn: credentials.sentryDsn,
  sampleRate: 0.1
})

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)
}

ReactDOM.render(
  <App />,
  document.getElementById('app')
)

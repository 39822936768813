import React from 'react'
import styled from 'styled-components'
import { string, func } from 'prop-types'

import ThriftyInput from 'shared/components/ui/ThriftyInput'

const UnitIdInput = ({ unitId, onUpdate, uuid, className, ...props }) => {
  const handleInput = unitId => onUpdate({ uuid, attributes: { unitId } })

  return (
    <ThriftyInput
      className={ className }
      type='text'
      value={ unitId }
      onUpdate={ handleInput }
      data-test-id='unit-id-input'
      { ...props }
    />
  )
}

UnitIdInput.propTypes = {
  onUpdate: func.isRequired,
  uuid: string.isRequired,
  unitId: string
}

UnitIdInput.defaultProps = {
  unitId: ''
}

export default styled(UnitIdInput)`
  display: inline-block
  box-shadow: none !important;
  height: 2.375rem
  font-size: .75rem !important
`

import React, { useMemo } from 'react'
import { array, string, func } from 'prop-types'

import ImageSelect from 'shared/components/ui/ImageSelect'
import condensedSelectStyles from 'shared/style/condensedSelectStyles'

const Select = ({ logos, logoUuid, onChange, ...props }) => {
  const options = useMemo(() => logos.map(({ uuid, thumbnail }) => ({
    value: uuid,
    src: thumbnail
  })), [JSON.stringify(logos)])

  const selectedOption = options.find(({ value }) => value === logoUuid)

  return (
    <ImageSelect
      { ...props }
      styles={ condensedSelectStyles }
      value={ selectedOption }
      options={ options }
      onChange={ onChange }
    />
  )
}

Select.propTypes = {
  logos: array.isRequired,
  onChange: func.isRequired,
  logoUuid: string
}

Select.defaultProps = {
  logoUuid: undefined
}

export default Select

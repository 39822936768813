import gql from 'graphql-tag'

export default gql`
  mutation updateCreativeSet($uuid: String!, $input: CreativeSetInput!) {
    updateCreativeSet(uuid: $uuid, input: $input) {
      creativeSet {
        uuid
        color
      }
    }
  }
`

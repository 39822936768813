import React from 'react'
import { Field } from 'formik'
import { t } from 'ttag'
import styled from 'styled-components'
import { FormControl } from 'react-bootstrap'

import FieldErrorMessage   from 'shared/components/ui/FieldErrorMessage'
import { buttonTextColor } from 'shared/style/colors'

import LanguageSelect  from './LanguageSelect'
import TimeZoneSelect  from './TimeZoneSelect'
import PhoneCodeSelect from './PhoneCodeSelect'
import AddressInput    from './AddressInput'
import CompanySelect   from './CompanySelect'

const FormFields = ({ className }) => {
  return (
    <div className={ className }>
      <FieldErrorMessage name="name">
        <label>{ t`Name` }</label>
        <Field name="name" as={ FormControl } />
      </FieldErrorMessage>

      <FieldErrorMessage name="email">
        <label>{ t`Email` }</label>
        <Field name="email" as={ FormControl } />
      </FieldErrorMessage>

      <FieldErrorMessage name="phoneNumber">
        <label>{ t`Phone` }</label>
        <div className="d-flex">
          <Field
            name="phoneCode"
            as={ PhoneCodeSelect }
          />

          <Field name="phoneNumber" as={ FormControl } type="number" />
        </div>
      </FieldErrorMessage>

      <FieldErrorMessage name="address">
        <label>{ t`Address` }</label>
        <Field name="address" as={ AddressInput } />
      </FieldErrorMessage>

      <FieldErrorMessage name="companyUuid">
        <label>{ t`Organization` }</label>
        <Field name="companyUuid" as={ CompanySelect } />
      </FieldErrorMessage>

      <FieldErrorMessage name="role">
        <label>{ t`Role` }</label>
        <Field name="role" as={ FormControl } />
      </FieldErrorMessage>

      <FieldErrorMessage name="settings.defaultLanguage">
        <label>{ t`Language` }</label>
        <Field name="settings.defaultLanguage" as={ LanguageSelect } />
      </FieldErrorMessage>

      <FieldErrorMessage name="settings.timezone">
        <label>{ t`Timezone` }</label>
        <Field name="settings.timezone" as={ TimeZoneSelect } />
      </FieldErrorMessage>
    </div>
  )
}

FormFields.propTypes = {
}

export default styled(FormFields)`
  label {
    color: ${buttonTextColor};
    font-size: 0.875rem;
    margin-bottom: .25rem
  }
  
  ${FieldErrorMessage} {
    margin-bottom: .75rem;
  }
  
  .form-control {
    box-shadow: none;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
  }
`

import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

import columnType from 'shared/types/columnType'
import Column from 'shared/components/ui/TwoColumnList/Column'

const TwoColumnList = ({ className, leftColumn, rightColumn }) => {
  return (
    <div className={ className }>
      <Column column={ leftColumn }/>
      <Column column={ rightColumn }/>
    </div>
  )
}

TwoColumnList.propTypes = {
  className: string.isRequired,
  leftColumn: columnType.isRequired,
  rightColumn: columnType.isRequired
}

export default styled(TwoColumnList)`
  display: flex
  justify-content: space-between
  
  & > div {
    width: 43%
  }
`

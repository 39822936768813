import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'

import { borderGreyColor } from 'shared/style/colors'

const EmptyLogo = ({ className }) => {
  return (
    <div className={ className }>
      <span className="icon-empty-image" />
      <span>{ t`Missing media` }</span>
    </div>
  )
}

export default styled(EmptyLogo)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: .5rem;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  border: 2px solid ${borderGreyColor}
  color: ${borderGreyColor}

  [class^=icon] {
    font-size: 1.7rem;
    margin-bottom: .5rem
    color: ${borderGreyColor}
  }
`

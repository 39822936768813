import { useContext } from 'react'
import { useMutation } from 'react-apollo'

import { ProjectSummaryContext } from 'shared/components/Documents/Showings/index'
import createShowingMutation from 'graphql/mutations/showings/create'
import { buildFragmentParams } from 'shared/helpers/graphql'
import projectFragment from 'graphql/fragments/project'
import stageFragment from 'graphql/fragments/stage'

export default function useAddNewButton() {
  const { showableUuid, showableType } = useContext(ProjectSummaryContext)

  const [createShowing, { loading }] = useMutation(createShowingMutation, {
    variables: { showableUuid: showableUuid, showableType: showableType },
    skip: !showableUuid,
    update: (cache, { data }) => {
      const object = { __typename: showableType, uuid: showableUuid }
      const usedFragment = showableType === 'Project' ? projectFragment : stageFragment
      const fragmentParams = buildFragmentParams(object, usedFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          showings: [
            ...fragmentData.showings,
            data.createShowing.showing
          ]
        }
      })
    }
  })

  return {
    createShowing,
    loading
  }
}

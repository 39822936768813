import React  from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import Instagram from 'shared/assets/icons/svg/instagram.svg'

const InstagramIcon = ({ className, content }) => (
  <>
    <Instagram className={ className } />
    { content }
  </>
)

InstagramIcon.propTypes = {
  content: string
}

InstagramIcon.defaultProps = {
  content: undefined
}

export default styled(InstagramIcon)`
  height: 0.8rem
  margin-right: 0.4rem
`

import React, { useState } from 'react'
import { t } from 'ttag'
import { v4 as generateUuid } from 'uuid'
import PropTypes from 'prop-types'
import { useApolloClient } from 'react-apollo'

import Companies from 'shared/components/Project/Regular/Companies'
import updateProject from 'shared/components/Project/ProjectInformation/updateProject'
import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'
import deleteProductCompanyMutation
  from 'shared/components/Project/Regular/deleteProductCompanyMutation'

const Organizations = ({ project, className }) => {
  const [companies, setCompanies] = useState(project.companies)
  const client = useApolloClient()

  const buildPlaceholderCompany = () => {
    return {
      id:           null,
      uuid:         generateUuid(),
      main:         false,
      logo:         null,
      category:     null,
      placeholder:  true
    }
  }

  const addCompany = () => {
    setCompanies([ ...companies, buildPlaceholderCompany() ])
  }

  const removeCompany = (company) => {
    setCompanies(companies.filter((element) => element.uuid !== company.uuid))
  }

  const handleUpdateCompanies = async (currentCompanies) => {
    const companiesPayload = prepareCompaniesUpdatePayload(currentCompanies)

    const {
      data: {
        updateProject: {
          project: {
            companies: updatedProjectCompanies
          }
        }
      }
    } = await updateProject(client, project, {}, {}, companiesPayload)

    setCompanies(
      currentCompanies.map((company) => {
        if(company.placeholder === true) { return company }
        return updatedProjectCompanies.find((c) => c.uuid === company.uuid)
      })
    )
  }

  const prepareCompaniesUpdatePayload = (companies) => {
    const existingCompanies = companies.filter((company) => !company.placeholder)
    return existingCompanies.map(({ uuid, category }) => ({ uuid, category }))
  }

  const handleDeleteCompany = (company) => {
    removeCompany(company)
    if (company.placeholder === true) { return }

    client.mutate({
      mutation: deleteProductCompanyMutation,
      variables: { id: company.id }
    })
  }

  const updateCompanyCategory = (company, value) => {
    const category = value.value

    const currentCompanies = companies.map((item) => {
      if ( item.uuid !== company.uuid ) return item
      return { ...item, category }
    })

    handleUpdateCompanies(currentCompanies)
  }

  const updateCompanyUuid = (company, { target: { element } }) => {
    const logo = element.logo && element.logo.thumbnail
    const name = element.name
    const uuid = element.uuid

    const currentCompanies = companies.map((item) => {
      if ( item.uuid !== company.uuid ) { return item }
      return { ...item, uuid, name, logo, placeholder: false }
    })

    handleUpdateCompanies(currentCompanies)
  }

  return (
    <EmbeddedCardSection
      titleText={ t`Organizations` }
      className={ className }
    >
      <Companies
        showLogo
        companies={ companies }
        data-test-id='companies'
        addCompany={ addCompany }
        updateCompanyCategory={ updateCompanyCategory }
        updateCompanyUuid={ updateCompanyUuid }
        deleteCompany={ handleDeleteCompany }
      />
    </EmbeddedCardSection>
  )
}

Organizations.propTypes = {
  project: PropTypes.object.isRequired
}

export default Organizations

import React from 'react'

import UserActions from './UserActions'

const Header = () => {

  return (
    <div className="mt-4 mb-2 d-flex justify-content-end">
      {/* Hidden for MVP */}
      {/*<Search />*/}
      <UserActions />
    </div>
  )
}

export default Header

import React      from 'react'
import { string } from 'prop-types'
import styled     from 'styled-components'

import getImageUrl from 'shared/helpers/getImageUrl'
import { altoGreyColor } from 'shared/style/colors'

const PredefinedPreview = (props) => {
  const {
    previewAsset,
    className
  } = props

  return (
    <div className={ className }>
      <div className="card">
        { previewAsset && (
          <img src={ getImageUrl(previewAsset) } alt="image" className="image" />
        ) }
      </div>
    </div>
  )
}

PredefinedPreview.propTypes = {
  previewAsset: string
}

PredefinedPreview.defaultProps = {
  previewAsset: null
}

export default styled(PredefinedPreview)`
  border: solid 2px ${altoGreyColor};
  border-radius: 5px;
  padding: .9rem;

  .card {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px rgba(151, 151, 151, 0.3);
    border-radius: 4px
    overflow: hidden;
  }

  .image {
    width: 100%
  }
`

import React, { useState } from 'react'
import styled              from 'styled-components'
import { t }               from 'ttag'
import { object, string }  from 'prop-types'

import Caret                from 'shared/components/ui/Caret'
import { formatPrice }      from 'shared/helpers/currency'
import { unitStates }       from 'shared/constants/unitStates'
import unitTypeTranslations from 'shared/constants/buildingTypes'
import Facilities           from 'shared/components/Unit/components/Facilities'
import { renderFloorPlans } from 'shared/components/Project/Readonly/renderFloorPlans'

const Unit = ({
  unit,
  unitable,
  className
}) => {
  const [opened, setOpened] = useState(false)

  return (
    <div className={ className }>
      <div className='unit-base-params'>

        <div className='collapse-button'>
          <Caret
            className="caret"
            opened={ opened }
            data-test-id="card-caret"
            onClick={ () => setOpened(!opened) }
          />
        </div>

        <p>{ unit.unitId }</p>
        <p>{ unit.bedroomCount }</p>
        <p>{ unitTypeTranslations.find((unitType) => unitType.value === unit.unitType)?.label }</p>
        <p>{ unit.sizeM2 }</p>
        <p>{ formatPrice(unit.totalPrice) }</p>
        <p>{ unit.floorNumber }</p>
        <p>{ renderFloorPlans(unit.floorplans) }</p>
        <p>{ unitStates[unit.state] }</p>
        <p>{ unitable === 'Stage' ? unit.buildingName : unit.stageName }</p>
      </div>

      {opened && (
        <div className='unit-optional-params'>
          <div className='optional-row'>
            <div className='row-label'>
              <p>{ t`Price` }</p>
            </div>
            <div className='values-block'>
              <div>
                <label>{ t`Purchase price` }</label>
                <p>{ unit.purchasePrice }</p>
              </div>
              <div>
                <label>{ t`Shared price` }</label>
                <p>{ unit.sharedPrice }</p>
              </div>
              <div>
                <label>{ t`Total costs` }</label>
                <p>{ unit.totalCosts }</p>
              </div>
              <div>
                <label>{ t`Deposit` }</label>
                <p>{ unit.deposit }</p>
              </div>
            </div>
          </div>

          <div className='optional-row'>
            <div className='row-label'>
              <p>{ t`Size` }</p>
            </div>
            <div className='values-block'>
              <div>
                <label>{ t`Rooms` }</label>
                <p>{ unit.roomCount }</p>
              </div>
              <div>
                <label>{ t`Usable area` }</label>
                <p>{ unit.usableAreaM2 }</p>
              </div>
              <div>
                <label>{ t`Lot size` }</label>
                <p>{ unit.lotSizeM2 }</p>
              </div>
            </div>
          </div>

          <div className='optional-row'>
            <div className='row-label'>
              <p>{ t`Facilities` }</p>
            </div>
            <div className='values-block'>
              <div>
                <Facilities value={ unit.facilities } />
              </div>
            </div>
          </div>

          <div className='optional-row'>
            <div className='row-label'>
              <p>{ t`Parking` }</p>
            </div>
            <div className='values-block'>
              {unit.facilities.includes('parking') && (
                <div>
                  <label>{ t`Parking slots` }</label>
                  <p>{ unit.parkingSlotsAmount }</p>
                </div>
              )}
              <div>
                <label>{ t`Optional parking cost` }</label>
                <p>{ unit.parkingPrice }</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Unit.propTypes = {
  unit: object.isRequired,
  unitable: string.isRequired
}

export default styled(Unit)`
  padding: 0 1rem;
  font-size: .75rem;

  .unit-base-params {
    display: flex;
    padding: .5rem 0;

     p {
      flex: 1;
      padding: 0 .25rem;
      margin: 0;
    }

    .collapse-button {
      flex: initial;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .unit-optional-params {
    margin-bottom: 1rem;
    border: 1px solid #dee2e6;
    border-bottom: none;

    .optional-row {
      display: flex;
      border-bottom: 1px solid #dee2e6;

      .row-label {
        width: 8rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #dee2e6;
        color: #697386;

        p {
          text-transform: uppercase;
          margin: 0;
          font-size: 1rem;
        }
      }

      .values-block {
        display: flex;
        flex: 1;
        padding: 1rem;

        > div {
          margin-right: 1rem;
          min-width: 8rem;

          label {
            font-size: .875rem;
            color: #697386;
          }

          > p {
            height: auto;
            margin: 0;
          }
        }

        .balcony-facilities {
          display: flex;
        }
      }
    }
  }
`

import gql from 'graphql-tag'

export default gql`
  mutation DeleteNewsPost(
    $uuid: ID!
  ) {
    deleteNewsPost(
      uuid: $uuid
    ) {
      post {
        uuid
      }
    }
  }
`

import React from 'react'
import classNames from 'classnames'

import PlusIcon   from 'shared/components/ui/icons/PlusIcon'

import PublishingTooltipOverlay from '../PublishingTooltipOverlay'

const discardButtonColumnFormatter = (
  _cell, row, _rowIndex, { publishingMode, discardAllDisabled, handleSingleDiscard }
) => {
  return (
    <PublishingTooltipOverlay publishingMode={ publishingMode }>
      <PlusIcon
        rotated
        onClick={ () => handleSingleDiscard(row.key) }
        className={
          classNames('delete-button', { disabled: publishingMode || discardAllDisabled })
        }
        data-testid='delete-button'
      />
    </PublishingTooltipOverlay>
  )
}

export default discardButtonColumnFormatter

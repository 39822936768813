import React from 'react'
import { string } from 'prop-types'
import { t } from 'ttag'

import StageQuery from 'graphql/queries/stageWithSnapshots'
import PageHeader from 'shared/components/ui/PageHeader'
import ResourceQuery from 'shared/components/ResourceQuery'

import StageDetails from './StageDetails'

const NewStage = ({ uuid }) => {
  return (
    <ResourceQuery
      query={ StageQuery }
      variables={{ uuid }}
    >
      {stage => (
        <>
          <PageHeader title={ t`Create new stage` + ` - ${stage.projectName}` } />
          <StageDetails product={ stage.product } />
        </>
      )}
    </ResourceQuery>
  )
}

NewStage.propTypes = {
  uuid: string.isRequired
}

export default NewStage

import React from 'react'
import { array, string } from 'prop-types'

import Card from 'shared/components/ui/Card'

import Header from './Header'
import Table  from './Table'

const Users = ({ users, companyUuid }) => {
  return (
    <Card header={ <Header companyUuid={ companyUuid } /> } padded={ false }>
      <Table users={ users } />
    </Card>
  )
}

Users.propTypes = {
  companyUuid: string.isRequired,
  users: array.isRequired
}

export default Users

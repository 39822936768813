import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { t } from 'ttag'
import moment from 'moment'
import Select from 'react-select'
import styled from 'styled-components'

import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'
import FormLabel from 'shared/components/ui/FormLabel'

import QuarterSelect from './QuarterSelect'
import YearSelect from './YearSelect'
import MonthSelect from './MonthSelect'
import DateSelect from './DateSelect'
import CustomDateSelect from './CustomDateSelect'
import { getStartOf, getEndOf } from './phaseDateFormatter'

const phasePeriods = [
  { label: t`Year`, value: 'year' },
  { label: t`Quarter`, value: 'quarter' },
  { label: t`Month`, value: 'month' },
  { label: t`Date`, value: 'date' },
  { label: t`Custom`, value: 'custom' }
]

const phaseMap = {
  year: YearSelect,
  quarter: QuarterSelect,
  month: MonthSelect,
  date: DateSelect,
  custom: CustomDateSelect
}
const PeriodPicker = ({
  className,
  handleChange,
  phasePeriod,
  startDate,
  endDate,
  enabledTimeUnits
}) => {
  const [selectedPeriodType, setSelectedPeriodType] = useState()

  const enabledOptions = () => {
    if (!enabledTimeUnits) {
      return phasePeriods
    }

    return phasePeriods.filter(type => enabledTimeUnits.includes(type.value))
  }

  const updatePeriodType = (selectedOption) => {
    const updateInput = { phasePeriod: selectedOption.value }

    switch (selectedOption.value) {
    case 'custom':
      updateInput['endDate'] = null
      break
    case 'quarter':
      updateInput['startDate'] = getStartOf('quarter')
      updateInput['endDate'] = getEndOf('quarter')
      break
    case 'year':
      updateInput['startDate'] = getStartOf('year')
      updateInput['endDate'] = getEndOf('year')
      break
    case 'month':
      updateInput['startDate'] = getStartOf('month')
      updateInput['endDate'] = getEndOf('month')
      break
    case 'date':
      updateInput['startDate'] = getStartOf('day')
      updateInput['endDate'] = getEndOf('day')
      break
    }

    setSelectedPeriodType(selectedOption.value)
    handleChange(updateInput)
  }

  const dateChangeHandler = (startDate, endDate) => {
    handleChange({ startDate, endDate })
  }

  const getSelectedItem = (selectedValue) => {
    return phasePeriods.find((val) => (val.value === selectedValue ))
  }

  const PhaseSelector = phaseMap[selectedPeriodType || phasePeriod || 'year']

  return (
    <div className={ className }>
      <div className='w-50'>
        <FormLabel>{ t`Time format` }</FormLabel>
        <InputWrapper>
          <Select
            data-test-id='period-selector'
            className='phase-period'
            value={ getSelectedItem(selectedPeriodType || phasePeriod) }
            onChange={ updatePeriodType }
            options={ enabledOptions() }
          />
        </InputWrapper>
      </div>
      <div className='w-50 pl-3'>
        <PhaseSelector
          data-test-id='phase-selector'
          dateChangeHandler={ dateChangeHandler }
          startDate={ startDate ? moment.utc(startDate).toDate(): null }
          endDate={ endDate ? moment.utc(endDate).toDate() : null }
        />
      </div>
    </div>
  )
}

PeriodPicker.propTypes = {
  enabledTimeUnits: PropTypes.array,
  endDate: PropTypes.string,
  handleChange: PropTypes.func,
  phasePeriod: PropTypes.string,
  startDate: PropTypes.string,
}

PeriodPicker.defaultProps = {
  handleChange: () => {},
  enabledTimeUnits: undefined,
  endDate: undefined,
  phasePeriod: undefined,
  startDate: undefined
}

export default styled(PeriodPicker)`
  display: flex;

  label {
    display: flex;
    justify-content: left;
    margin-bottom: 0;
    align-items: flex-start;
  }

  .phase-period {
    font-size: 0.9rem;
  }
`

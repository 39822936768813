import { gql } from 'apollo-boost'

import snapshotPhase from 'graphql/fragments/snapshotPhase'

export default gql`
  mutation updateSnapshotPhase(
    $uuid: ID!
    $phaseUuid: ID!
    $input: SnapshotPhaseInput!
  ) {
    updateSnapshotPhase(
      uuid: $uuid
      phaseUuid: $phaseUuid
      input: $input
    ) {
      phase {
       ... SnapshotPhase
      }
    }
  }
  ${snapshotPhase}
`

import removeAssetAssignmentMutation from 'graphql/mutations/assetable/removeAssetAssignment'

export default function createRemoveAssetAssignment(client, _unitUuid, _assetAssignments) {
  return function removeAssetAssignment(assetAssignmentUuid, confirmRemoval) {
    return client.mutate({
      mutation: removeAssetAssignmentMutation,
      variables: {
        assetAssignmentUuid,
        confirmRemoval
      }
    })
  }
}

import React from 'react'

import ActionsCell from 'shared/components/Stakeholders/ActionsCell'
import isReadOnly from 'shared/components/Stakeholders/isReadOnly'

const actionsFormatter = (cell, row, rowIndex, { onStakeholderDelete }) => (
  isReadOnly(row) || (
    <ActionsCell
      onStakeholderDelete={ onStakeholderDelete }
      stakeholderUuid={ row.uuid }
    />
  )
)

export default actionsFormatter

import React from 'react'
import styled from 'styled-components'
import { string, func, bool } from 'prop-types'
import classNames from 'classnames'

import { bluishColor } from 'shared/style/colors'

const NavItem = ({ label, onClick, className, active, enabled, ...props }) => {
  return (
    <div
      className={ classNames(className, { disabled: !enabled, active: active }) }
      onClick={ () => { enabled && onClick() } }
      { ...props }
    >
      { label }
    </div>
  )
}

NavItem.propTypes = {
  className: string.isRequired,
  label: string.isRequired,
  active: bool,
  enabled: bool,
  onClick: func
}

NavItem.defaultProps = {
  onClick: () => {},
  active: false,
  enabled: true
}

export default styled(NavItem)`
  background-color: #FFFFFF;
  box-shadow: inset 0 -0.25rem transparent;
  border-right: 1px solid #e3e8ee;
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding: 1.25rem 1rem 1rem 1rem;
  text-align: center;
  color: #A0A2A3;

  &.active {
    background-color: #fff;
    color: ${bluishColor}
    border-bottom-color: ${bluishColor};
    box-shadow: inset 0 -0.25rem ${bluishColor};
  }

  &.disabled {
    cursor: default;
    background: repeating-linear-gradient(
      -45deg,
      #fafcfd,
      #fafcfd 12px,
      #eeeff0 12px,
      #eeeff0 24px
    );
  }
`


import React, { useCallback } from 'react'
import { func, string, array } from 'prop-types'

import FormSelect from './FormSelect'

const FacebookPageSelect = ({
  facebookPages,
  facebookPageUuid,
  onChange,
  ...props
}) => {
  const handleChange = useCallback(({ value }) => {
    onChange({ target: { name: 'facebookPageUuid', value } })
  }, [])

  const options = facebookPages.map(facebookPage => ({
    value: facebookPage.uuid,
    label: facebookPage.title,
    ...facebookPage
  }))

  const defaultValue = options.find(option => option.value === facebookPageUuid)

  return (
    <FormSelect
      { ...props }
      value={ defaultValue }
      onChange={ handleChange }
      options={ options }
    />
  )
}

FacebookPageSelect.propTypes = {
  onChange: func.isRequired,
  propertyUuid: string.isRequired,
  facebookPageUuid: string,
  facebookPages: array
}

FacebookPageSelect.defaultProps = {
  facebookPages: [],
  facebookPageUuid: null
}

export default FacebookPageSelect

import React, { useEffect, useRef } from 'react'
import { Formik, useFormikContext } from 'formik'
import { debounce } from 'lodash'
import { useApolloClient } from '@apollo/react-hooks'
import { arrayOf, object, shape, string } from 'prop-types'

import campaignType from 'shared/types/campaignType'
import updateAudience from 'graphql/mutations/campaign/targeting/updateGmpAudience'
import createTargetingItemMutation from 'graphql/mutations/campaign/audiences/targetingItems/create'

import LeftColumn from './LeftColumn'
import RightColumn from './RightColumn'
import { AudienceContainer } from './elements'
import validationSchema from './validationSchema'

const createDebounce = (submitForm) => {
  submitForm()
}

const debouncedSubmit = debounce(createDebounce, 500)

const AutoSave = () => {
  const skipSubmit = useRef(true)
  const { submitForm, values } = useFormikContext()

  useEffect(() => {
    if (skipSubmit.current) {
      skipSubmit.current = false
      return
    }

    debouncedSubmit(submitForm)

  }, [submitForm, values])

  return null
}

const GoogleAudience = ({ audience, allAudiences, campaign }) => {
  const client = useApolloClient()
  const { platformAudience } = audience

  const createTargetingItem = async (element) => {
    const response = await client.mutate({
      mutation: createTargetingItemMutation,
      variables: { name: element.name, subtype: element.subtype, externalId: element.externalId }
    })
    return response.data.createTargetingItem.targetingItem
  }

  const checkNewValue = async (input) => {
    const existedElements = []
    const addedElements = []
    if (input !== null && input !== undefined) {
      input.forEach((element) => {
        if (element.externalId === undefined) existedElements.push(element)
        else addedElements.push(element)
      })
    }
    if (addedElements.length === 0) return existedElements

    const createdTargetingItem = await createTargetingItem(addedElements[0])
    existedElements.push(createdTargetingItem)
    return existedElements
  }

  const checkTargeting = async (previousValue, newValue) => {
    if (previousValue === newValue) return previousValue
    if (previousValue.length === 0 && newValue.length === 0) return previousValue

    const result = await checkNewValue(newValue)
    return result
  }

  const handleAudienceChange = async (formValues) => {
    const {
      urlBundles,
      detailedTargetingInclude,
      detailedTargetingExclude,
      languageTargeting,
      ...values
    } = formValues

    const newTargetingInclude = await checkTargeting(
      platformAudience.detailedTargetingInclude,
      detailedTargetingInclude
    )
    const newTargetingExclude = await checkTargeting(
      platformAudience.detailedTargetingExclude,
      detailedTargetingExclude
    )

    const convertOptionsToValues = options => options && options.map(
      ({ id, name }) => ({ id, name })
    )

    const input = {
      ...values,
      urlBundleUuids: urlBundles.map(el => el.value),
      includedTargetingItemsUuids: (newTargetingInclude || []).map(el => el.uuid),
      excludedTargetingItemsUuids: (newTargetingExclude || []).map(el => el.uuid),
      languageTargeting: convertOptionsToValues(languageTargeting)
    }
    delete input.customAudiencesInclude
    delete input.customAudiencesExclude

    client.mutate({
      mutation: updateAudience,
      variables: { uuid: audience.uuid, input }
    })
  }

  const locations = (audience.locations || []).map(el => {
    const { uuid, title, latitude, longitude } = el
    return { uuid, title, latitude, longitude }
  })

  const urlBundles = (platformAudience.urlBundles || []).map(el => ({
    label: el.name,
    value: el.uuid,
    ...el
  }))

  const detailedTargetingInclude = (platformAudience.detailedTargetingInclude || []).map(el => ({
    label: el.name,
    value: el.uuid,
    ...el
  }))

  const detailedTargetingExclude = (platformAudience.detailedTargetingExclude || []).map(el => ({
    label: el.name,
    value: el.uuid,
    ...el
  }))

  const languageTargeting = (platformAudience.languageTargeting || []).map(el => ({
    label: el.name,
    value: el.id,
    ...el
  }))

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ageFrom: platformAudience.ageFrom,
        ageTo: platformAudience.ageTo,
        incomeFrom: platformAudience.incomeFrom,
        incomeTo: platformAudience.incomeTo,
        parentalStatus: platformAudience.parentalStatus || [],
        gender: platformAudience.gender || [],
        customAudiencesInclude: platformAudience.customAudiencesInclude || [],
        customAudiencesExclude: platformAudience.customAudiencesExclude || [],
        urls: platformAudience.urls || [],
        languageTargeting,
        detailedTargetingExclude,
        detailedTargetingInclude,
        urlBundles,
        locations
      }}
      onSubmit={ handleAudienceChange }
      validationSchema={ validationSchema }
    >
      {formikBag => (
        <AudienceContainer>
          <AutoSave />

          <LeftColumn
            audience={ audience }
            allAudiences={ allAudiences }
            campaign={ campaign }
            formikBag={ formikBag }
            client={ client }
          />
          <RightColumn formikBag={ formikBag } />
        </AudienceContainer>
      )}
    </Formik>
  )
}

GoogleAudience.propTypes = {
  allAudiences: arrayOf(
    shape({
      name: string,
      uuid: string
    })
  ).isRequired,
  audience: shape({
    platformAudience: object.isRequired
  }).isRequired,
  campaign: campaignType.isRequired
}

export default GoogleAudience

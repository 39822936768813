import React from 'react'
import styled from 'styled-components'
import { string, node } from 'prop-types'

import { textGreyColor } from 'shared/style/colors'

const Section = ({ title, children }) => {
  return (
    <div>
      <Title>{ title }</Title>

      { children }
    </div>
  )
}

const Title = styled.h6`
  color: ${textGreyColor}
  font-size: .75rem
  font-weight: normal
  margin-bottom: .65rem
`

Section.propTypes = {
  children: node.isRequired,
  title: string.isRequired
}

export default Section

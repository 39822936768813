import gql from 'graphql-tag'

export default gql`
  query companyFacebookPages(
      $companyUuid: ID,
      $stageUuid: ID,
      $propertyUuid: ID
  ) {
    companyFacebookPages(
      companyUuid: $companyUuid,
      stageUuid: $stageUuid,
      propertyUuid: $propertyUuid
    ) {
      facebookPages {
        uuid
        title
      }
      defaultFacebookPageUuid
    }
  }
`

import { gql } from 'apollo-boost'

export default gql`
  query GoogleTargetingItems($query: String, $campaignUuid: ID!) {
    googleTargetingItems(query: $query, campaignUuid: $campaignUuid) {
      uuid
      name
      subtype
      externalId
    }
  }
`

import { buildFragmentParams } from 'shared/helpers/graphql'
import buildingFragment        from 'graphql/fragments/building'

import createUnitMutation from './createUnitMutation'

const createUnit = (client, building) => {
  client.mutate({
    mutation: createUnitMutation,
    variables: {
      buildingUuid: building.uuid,
      attributes: {}
    },
    update: (cache, { data: { createBuildingUnit: { unit } } }) => {
      const fragmentParams = buildFragmentParams(
        { __typename: 'Building', uuid: building.uuid },
        buildingFragment
      )
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          units: [
            ...fragmentData.units,
            unit
          ]
        }
      })
    }
  })
}

export default createUnit

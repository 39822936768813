import { FileChecksum } from '@rails/activestorage/src/file_checksum'

const calculateChecksum = (file) => (
  new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error)
        return
      }

      resolve(checksum)
    })
  })
)

const getFileMetadata = ({ file, fileMeta }) => (
  new Promise((resolve) => {
    calculateChecksum(file).then((checksum) => {
      resolve({
        checksum,
        filename: file.name || fileMeta.name,
        contentType: file.type || fileMeta.type,
        byteSize: file.size
      })
    })
  })
)

export default getFileMetadata

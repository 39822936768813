import React from 'react'
import { object, func, string } from 'prop-types'
import styled from 'styled-components'
import BootstrapTable from 'react-bootstrap-table-next'

import createPagination from 'shared/components/ui/Table/createPagination'
import sortCaret from 'shared/components/ui/Table/sortCaret'
import { buttonTextColor, textColor } from 'shared/style/colors'

class Table extends React.Component {
  static propTypes = {
    className: string.isRequired,
    dataTestId: string,
    filter: func,
    paginationOptions: object,
    searchOptions: object,
    searchPanel: func,
    tableOptions: object
  }

  static defaultProps = {
    filter: null,
    dataTestId: null,
    paginationOptions: null,
    searchOptions: null,
    searchPanel: null,
    tableOptions: {
      keyField: 'id'
    },
  }

  tableColumns = () => {
    return this.props.tableOptions.columns.map(column => ({ ...column, sortCaret }))
  }

  render() {
    const {
      className,
      dataTestId,
      searchOptions,
      paginationOptions,
      tableOptions,
      searchPanel: SearchPanel,
      filter: Filter
    } = this.props

    const pagination = paginationOptions ? createPagination(paginationOptions) : null

    const columns = this.tableColumns()

    return (
      <div className={ className } data-test-id={ dataTestId }>
        {searchOptions ? (<div className="search-bar">
          { SearchPanel && <SearchPanel { ...searchOptions } />}

          { Filter && <Filter /> }
        </div>) : null }
        <BootstrapTable { ...{ ...tableOptions, columns, pagination } } />
      </div>
    )
  }
}

export default styled(Table)`
  .react-bootstrap-table-pagination {
    ul.pagination {
      float: right

      button:first-letter {
        text-transform: uppercase
      }
    }
  }

  th, td {
    cursor: default
    padding: .7rem 1rem
    vertical-align: middle
  }

  td {
    font-size: .75rem
    color: ${textColor}
  }

  td:first-letter {
    text-transform: uppercase
  }

  tr:first-child td {
    border-top: none
  }

  .table-link {
    color: #4a4a4a
  }

  thead {
    background: #f6fafc
  }

  thead th {
    color: ${buttonTextColor}
    font-size: .875rem
    font-weight: 400
    border: none
  }

  .sortable {
    cursor: pointer
    white-space: nowrap
    user-select: none
  }

  .sortable svg {
    margin-left: .5rem
    color: #697386
  }

  .order, .react-bootstrap-table-sort-order {
    margin-left: 5px
  }

  .dropup .caret {
    border-top: 0
    border-bottom: 4px dashed
  }
`

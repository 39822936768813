import styled from 'styled-components'

import ThriftyInput from 'shared/components/ui/ThriftyInput/BigNumber'

export default styled(ThriftyInput)`
  box-shadow: none !important
  height: auto !important
  font-size: 0.875rem !important
  font-weight: 500 !important
  padding: 0.225rem 0.5rem !important
`

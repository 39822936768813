import { gql } from 'apollo-boost'

export default gql`
  query DashboardAnalyticsCategorizedLeadsByMonth($filter: JSON) {
    dashboardAnalyticsCategorizedLeadsByMonth(filter: $filter) {
      categorizedLeadsByMonth
    }
  }
`


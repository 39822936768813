import React from 'react'

import Checkbox from 'shared/components/ui/Checkbox'

const checkboxFormatter = (cell, _row, _rowIndex, { onUpdate, field }) => {
  const handleChange = () => {
    onUpdate(field)
  }

  return (
    <Checkbox
      shadow={ false }
      checked={ cell }
      onChange={ handleChange } />
  )
}

export default checkboxFormatter

import attachFileMutation from 'graphql/mutations/assetable/attachFile'
import updateAssetableAssetAssignmentsCache
  from 'shared/helpers/updateAssetableAssetAssignmentCache'
import stageAssetAssignments from 'graphql/fragments/stageAssetAssignments'

export default function attachFileToStage({ client, blobId, stageUuid, mediaType }) {
  const assetableUuid = stageUuid
  const assetableType = 'Stage'

  return client.mutate({
    mutation: attachFileMutation,
    variables: { blobId, assetableUuid, assetableType, mediaType },
    update: (store, {
      data: {
        attachFileToAssetable: { assetAssignment }
      }
    }) => updateAssetableAssetAssignmentsCache({
      store,
      assetableUuid: stageUuid,
      assetableType: 'Stage',
      assetableFragment: stageAssetAssignments,
      assetAssignment
    })
  })
}

import mutation from 'graphql/mutations/campaign/creativeSets/clone'
import campaignCreativeSetsQuery from 'graphql/queries/campaignCreativeSets'

const cloneCreativeSet = (client, campaign, creativeSet) =>
  client.mutate({
    mutation,
    variables: { uuid: creativeSet.uuid },
    update: (cache, { data: { cloneCreativeSet: { creativeSet } } }) => {
      const query = { query: campaignCreativeSetsQuery, variables: { campaignUuid: campaign.uuid } }
      const { campaignCreativeSets } = cache.readQuery(query)
      const creativeSets = [...campaignCreativeSets, creativeSet]
      cache.writeQuery({ ...query, data: { campaignCreativeSets: creativeSets } })
    }
  })

export default cloneCreativeSet

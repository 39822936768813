import { gql } from 'apollo-boost'

export default gql`
  query facilityDetails($projectUuid: ID!) {
    facilityDetails(projectUuid: $projectUuid) {
      uuid
      title
      description
      detailUuid
    }
  }
`

import React  from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'
import { func, array, object } from 'prop-types'

import Unit from 'shared/components/Unit'

const UnitsTable = ({
  className,
  stage,
  onUpdate,
  onDelete,
  handleUploadFloorPlanClick,
  units
}) => {
  const renderUnits = () => {
    return units.map((unit) =>
      <Unit
        stage={ stage }
        unit={ unit }
        onUpdate={ ({ attributes }) => onUpdate({ unit, attributes }) }
        onDelete={ onDelete }
        handleUploadFloorPlanClick={ handleUploadFloorPlanClick }
        key={ unit.uuid }
      />
    )
  }

  return (
    <div className={ className }>
      <div className='units-table-header'>
        <p />
        <p>{ t`ID` }</p>
        <p>{ t`Bedrooms` }</p>
        <p style={{ flex: 'initial', width: '9.5rem' }}>{ t`Unit type` }</p>
        <p>{ t`Size (m2)` }</p>
        <p>{ t`Total price (${ stage.currency })` }</p>
        <p style={{ flex: 'initial', width: '6.5rem' }}>{ t`Floor` }</p>
        <p style={{ flex: 'initial', width: '6rem' }}>{ t`Floor plan` }</p>
        <p style={{ flex: 'initial', width: '9rem' }}>{ t`Status` }</p>
        <p style={{ flex: 'initial', width: '9rem' }}>{ t`Building` }</p>
        <p />
      </div>
      {renderUnits()}
    </div>
  )
}

UnitsTable.propTypes = {
  handleUploadFloorPlanClick: func.isRequired,
  onDelete: func.isRequired,
  onUpdate: func.isRequired,
  stage: object.isRequired,
  units: array.isRequired,
}

export default styled(UnitsTable)`
  .units-table-header {
    display: flex;
    padding: .5rem 1rem;
    background: #f6fafc;

    p {
      flex: 1;
      margin: 0;
      padding: 0 .25rem;
      font-size: .875rem;
      color: #697386;

      &:nth-of-type(1), &:nth-last-of-type(1) {
        flex: initial;
        width: 3rem;
      }
    }
  }
`

import { gql } from 'apollo-boost'

import agent from 'graphql/fragments/agent'

export default gql`
  query ProjectAgents(
    $projectUuid: ID!
  ) {
    projectAgents(
      projectUuid: $projectUuid
    ) {
      ... Agent
    }
  }
  ${agent}
`

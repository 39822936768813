import createDirectUploadMutation from 'graphql/mutations/uploads/createDirectUpload'

const createDirectUpload = ({ client, input }) => (
  client.mutate({
    mutation: createDirectUploadMutation,
    variables: input
  })
)

export default createDirectUpload

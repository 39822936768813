import { number, string, shape } from 'prop-types'

export default shape({
  uuid: string.isRequired,
  createdAt: string.isRequired,
  fullName: string.isRequired,
  progress: string.isRequired,
  qualityScore: number.isRequired,
  updatedAt: string.isRequired,
  responseTime: number,
  audiencesName: string,
  phoneNumber: string,
  phoneFormatted: string,
  email: string,
})

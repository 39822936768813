import React            from 'react'
import { func, object } from 'prop-types'

import Image    from './Image'
import Uploader from './Uploader'

const AgentImage = ({ agent, refetchLandingPage }) => {
  return (
    <>
      <Image agent={ agent } />
      <Uploader agent={ agent } refetchLandingPage={ refetchLandingPage } />
    </>
  )
}

AgentImage.propTypes = {
  agent: object.isRequired,
  refetchLandingPage: func.isRequired
}

export default AgentImage

import { buildFragmentParams } from 'shared/helpers/graphql'
import campaignCreativeSetsQuery from 'graphql/queries/campaignCreativeSets'
import creativeSetFragment from 'graphql/fragments/creativeSet'

const refreshCreativeSetsInCache = (cache, campaignUuid, landingPageUuid) => {
  const { campaignCreativeSets } = cache.readQuery({
    query: campaignCreativeSetsQuery,
    variables: { campaignUuid: campaignUuid }
  })
  const filteredCreativeSets = campaignCreativeSets.filter((creativeSet) => {
    return creativeSet?.landingPage?.uuid === landingPageUuid
  })
  filteredCreativeSets.forEach((creativeSet) => {
    const fragmentParams = buildFragmentParams(
      { __typename: 'CreativeSet', uuid: creativeSet.uuid },
      creativeSetFragment
    )
    const fragmentData = cache.readFragment(fragmentParams)
    cache.writeFragment({
      ...fragmentParams,
      data: {
        ...fragmentData,
        landingPage: null
      }
    })
  })
}

export default refreshCreativeSetsInCache

import { useCallback, useMemo } from 'react'
import { get }                  from 'lodash'
import { toast }                from 'react-toastify'
import { t }                    from 'ttag'
import { useMutation }          from '@apollo/react-hooks'

import companyQuery       from 'graphql/queries/company'
import createUserMutation from 'graphql/mutations/users/createUser'

export default function useNewUserForm(companyUuid, onUserCreated) {
  const [createUser] = useMutation(createUserMutation)

  const initialValues = useMemo(() => ({
    companyUuid,
    name: '',
    email: '',
    phoneCode: '',
    phoneNumber: '',
    address: '',
    role: '',
    settings: {
      defaultLanguage: 'en_US',
      timezone: ''
    }
  }), [companyUuid])

  const submitForm = useCallback((values, { setSubmitting, setErrors }) => {
    setSubmitting(true)

    const attributes = {
      ...values,
      phoneNumber: values.phoneNumber.toString()
    }

    createUser({
      variables: { attributes },
      refetchQueries: [{ query: companyQuery, variables: { uuid: companyUuid } }]
    })
      .then((response) => {
        const errors = get(response, 'data.createUser.errors')

        if (errors) {
          setErrors(errors)
        } else {
          toast.success(t`New user have been created!`)
          onUserCreated()
        }
      })
      .finally(() => setSubmitting(false))
  }, [])

  return {
    initialValues,
    submitForm
  }
}

import React from 'react'
import { object, func } from 'prop-types'
import { t } from 'ttag'

import ButtonTag from 'shared/components/ui/Button'

const EditButton = ({ onEdit, user }) => {
  const handleDelete = () => {
    onEdit(user)
  }

  return (
    <ButtonTag
      onClick={ handleDelete }
      data-test-id='edit-button'
    >
      { t`Edit` }
    </ButtonTag>
  )
}

EditButton.propTypes = {
  onEdit: func.isRequired,
  user: object.isRequired
}

export default EditButton

import gql from 'graphql-tag'

import landingPage from 'graphql/fragments/landingPage'

export default gql`
  mutation UpdateLandingPage(
    $uuid: ID!
    $input: CampaignLandingPageInput!
  ) {
    updateLandingPage(
      uuid: $uuid
      input: $input
    ) {
      landingPage {
        ... LandingPage
      }
    }
  }
  ${landingPage}
`

import React from 'react'
import styled from 'styled-components'
import { components } from 'react-select'
import { object } from 'prop-types'
import { t } from 'ttag'

const ValueLabel = styled.span`
  font-size: 0.75rem;
`

const ValueContainer = props => {
  return (
    <components.ValueContainer { ...props }>
      <ValueLabel>{ t`Connect Creative sets to this landing page` }</ValueLabel>
    </components.ValueContainer>
  )
}

ValueContainer.propTypes = {
  selectProps: object.isRequired
}

export default ValueContainer

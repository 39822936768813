import React, { useState } from 'react'
import { get } from 'lodash'
import { useQuery } from 'react-apollo'
import styled from 'styled-components'
import { t } from 'ttag'
import { object } from 'prop-types'

import campaignAdsQuery from 'graphql/queries/campaignAds'
import Card              from 'shared/components/ui/Card'
import LoadingCard       from 'shared/components/ui/LoadingCard'

import tabs   from './TabBar/tabs'
import TabBar from './TabBar'

const AdsAudiences = ({ campaign, className }) => {
  const [activeTab, setActiveTab] = useState(0)
  const handleTabChange = idx => setActiveTab(idx)
  const CurrentComponent = tabs.map(el => el.component)[activeTab]
  const { data, loading } = useQuery(
    campaignAdsQuery,
    { variables: { campaignUuid: campaign.uuid } }
  )

  if (loading) {
    return (
      <LoadingCard />
    )
  }

  const ads = get(data, 'campaignAds')

  return (
    <Card
      collapsible
      className={ className }
      padded={ false }
      header={ t`Ads & audiences` }
      dataTestId="ads-audiences-panel"
    >
      <div>
        <TabBar
          tabs={ tabs.map(el => el.title) }
          activeTab={ activeTab }
          onChange={ handleTabChange } />

        <CurrentComponent campaign={ campaign } ads={ ads } />
      </div>
    </Card>
  )
}

AdsAudiences.propTypes = {
  campaign: object.isRequired
}

export default styled(AdsAudiences)`
  margin-top: 0.5rem
  margin-bottom: 0.5rem

  .react-bootstrap-table table {
    margin-bottom: 0
  }

  .pagination button {
    margin-right: .5rem
  }

  .pagination button:last-child {
    margin-right: 1rem
  }
`

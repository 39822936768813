import { gql } from 'apollo-boost'

import specification        from 'graphql/fragments/specification'
import channel              from 'graphql/fragments/channel'
import product              from 'graphql/fragments/product'
import project              from 'graphql/fragments/project'
import property             from 'graphql/fragments/property'
import campaignLandingPages from 'graphql/fragments/campaignLandingPages'

export default gql`
  fragment Campaign on Campaign {
    uuid
    name
    canceled
    budget
    orderDate
    assemblyDate
    controlDate
    clientReviewDate
    startDate
    endDate
    durationInDays
    daysSinceStart
    phase
    areFacebookCredentialsValid
    areGmpCredentialsValid
    publishStartedAt
    publishFailedAt
    backgroundColor
    propertyBased
    promotableType
    currentContentStep
    specification {
      ... Specification
    }
    specificationTemplate {
      ... Specification
    }
    advertiserId
    facebookPage {
      uuid
      name
      logoUrl
    }
    canSetPhase {
      resumed
      paused
      archived
      unarchived
      assembly
    }
    channels {
      ... Channel
    }
    assignments {
      user {
        name
      }
      role
      permission
    }
    product {
      ... Product
    }
    project {
      ... Project
    }
    property {
      ... Property
    }
    creativeSets {
      uuid
      name
      landingPage {
        uuid
        name
      }
    }
    ... CampaignLandingPages
  }
  ${specification}
  ${channel}
  ${product}
  ${campaignLandingPages}
  ${project}
  ${property}
`

import gql from 'graphql-tag'

export default gql`
  mutation CreateNewsPost(
    $productUuid: ID!
    $attributes: ProductNewsPostInput!
  ) {
    createNewsPost(
      productUuid: $productUuid
      attributes: $attributes
    ) {
      post {
        uuid
        newsType
        titleDraft
        descriptionDraft
        callToActionTextDraft
        callToActionDestinationUrlDraft
        publishedAt
        editedAt
        state
        viewsCount
        ctaClicksCount
        sharesCount
        reactionsCount
        media {
          uuid
          state
          asset {
            uuid
            media
            thumbnail
            position
            mediaType
            deletable
          }
        }
      }
    }
  }
`

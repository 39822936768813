import { useApolloClient } from '@apollo/react-hooks'

import { buildFragmentParams } from 'shared/helpers/graphql'
import creativeSetFragment from 'graphql/fragments/creativeSet'
import updateCreativeSetMutation from 'graphql/mutations/campaign/creativeSets/update'

export default function useSetColor(creativeSetUuid) {
  const client = useApolloClient()

  const fragmentParams = buildFragmentParams(
    { __typename: 'CreativeSet', uuid: creativeSetUuid },
    creativeSetFragment
  )
  const creativeSet = client.readFragment(fragmentParams)

  return ({ value }) => {
    client.mutate({
      mutation: updateCreativeSetMutation,
      variables: {
        uuid: creativeSetUuid,
        input : { color: value }
      },
      optimisticResponse: {
        updateCreativeSet: {
          __typename: 'UpdateCreativeSetPayload',
          creativeSet: {
            ...creativeSet,
            __typename: 'CreativeSet',
            color: value
          }
        }
      }
    })
  }
}

import { buildFragmentParams } from 'shared/helpers/graphql'
import landingPageFragment from 'graphql/fragments/landingPage'
import removeGalleryImageMutation
  from 'graphql/mutations/campaign/landingPages/removeGalleryImage'

export default ({ client, landingPage, uuid }) => {
  client.mutate({
    mutation: removeGalleryImageMutation,
    variables: { uuid },
    update: cache => {
      const fragmentParams = buildFragmentParams(landingPage, landingPageFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          galleryImages: fragmentData.galleryImages.filter(el => el.uuid !== uuid)
        }
      })
    }
  })
}

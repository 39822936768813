import React from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { string, shape, func, array } from 'prop-types'

import AssetAssignments from './AssetAssignments'
import AllMedia from './AllMedia'

const Media = ({
  availableAssets,
  assetAssignable,
  mediaSourceType,
  attachFileToAssetable,
  createRemoveAssetAssignment,
  removedAssetAssignment,
  createCreateAssetAssignment,
  ...props
}) => {

  const assetableType = assetAssignable.__typename

  const campaignAvailableMediaTypes = ['image', 'banner', 'video']
  const availableMediaTypes = ['image', 'video']
  const unitAvailableTypes = ['floorplan']

  let mediaTypesShown = []

  if (assetableType === 'Campaign') mediaTypesShown = campaignAvailableMediaTypes
  else if (assetableType === 'StageUnit') mediaTypesShown = unitAvailableTypes
  else mediaTypesShown = availableMediaTypes

  return (
    <DndProvider backend={ HTML5Backend }>
      <div>
        <AssetAssignments
          assetable={ assetAssignable }
          attachFileToAssetable={ attachFileToAssetable }
          createRemoveAssetAssignment={ createRemoveAssetAssignment }
          removedAssetAssignment={ removedAssetAssignment }
          createCreateAssetAssignment={ createCreateAssetAssignment }
          mediaTypes={ mediaTypesShown }
          { ...props }
        />

        { availableAssets.length > 0 && (
          <AllMedia
            assets={ availableAssets }
            sourceTitle={ mediaSourceType }
          />
        ) }
      </div>
    </DndProvider>
  )
}

Media.propTypes = {
  assetAssignable: shape({
    uuid: string.isRequired,
    assetAssignments: array
  }).isRequired,
  attachFileToAssetable: func.isRequired,
  availableAssets: array.isRequired,
  createCreateAssetAssignment: func.isRequired,
  createRemoveAssetAssignment: func.isRequired,
  mediaSourceType: string.isRequired,
  removedAssetAssignment: func
}

Media.defaultProps = {
  removedAssetAssignment: undefined
}

export default Media

import React, { useEffect, useRef } from 'react'
import queryString from 'query-string'
import { array, string, func, object } from 'prop-types'
import { pickBy, uniq } from 'lodash'

import fitIframeSize from './fitIframeSize'

const Iframe = ({
  onLoad,
  bannerName,
  className,
  color,
  logoUrl,
  assetUrl,
  content,
  address,
  city,
  agentName,
  agentTitle,
  agentPhotoUrl,
  title,
  bannerUuid,
  campaign,
  assets,
  bannerTemplateCollection,
  location,
  ...restProps
}) => {
  const iframeRef = useRef()

  let assetsUrls = []
  if (bannerTemplateCollection?.assetsAmount > 1 && assets !== undefined) {
    assets.unshift(assetUrl)
    assetsUrls = uniq(assets).slice(0, bannerTemplateCollection?.assetsAmount)
  }

  const formattedPrice = restProps.price === undefined
    ? ''
    : `${Intl.NumberFormat('no-NO').format(restProps.price)}, -`

  const previewData = {
    color,
    title,
    content,
    address,
    city,
    agent_name: agentName,
    agent_title: agentTitle,
    agent_photo_url: agentPhotoUrl,
    logo_url: logoUrl,
    asset_url: assetUrl,
    'assets_urls[]': assetsUrls,
    bedrooms: restProps.bedrooms,
    type: restProps.type,
    size: restProps.size ? `${restProps.size} m²` : undefined,
    price: formattedPrice,
    campaign_uuid: campaign.uuid,
    municipality: location?.municipality,
    district: location?.district
  }

  const previewQuery = queryString.stringify(pickBy(previewData, Boolean))

  const iframeSrc = `${window.location.origin}/banners/${bannerUuid}?${previewQuery}`

  const onIframeLoad = (event) => {
    fitIframeSize(event)
    if (onLoad) {
      onLoad({ uuid: bannerUuid, name: bannerName }, event)
    }
  }

  useEffect(() => {
    iframeRef.current.addEventListener('load', onIframeLoad)

    return () => iframeRef.current.removeEventListener('load', onIframeLoad)
  }, [iframeSrc])

  return (
    <div className={ className }>
      <iframe ref={ iframeRef } src={ iframeSrc } frameBorder="0" className="w-100" />
    </div>
  )
}

Iframe.propTypes = {
  address: string,
  agentName: string,
  agentPhotoUrl: object,
  agentTitle: string,
  assetUrl: string,
  assets: array,
  bannerName: string,
  bannerTemplateCollection: object,
  bannerUuid: string,
  campaign: object,
  city: string,
  color: string,
  content: string,
  location: object,
  logoUrl: string,
  onLoad: func,
  title: string
}

Iframe.defaultProps = {
  address: null,
  agentName: null,
  agentPhotoUrl: null,
  agentTitle: null,
  assetUrl: null,
  assets: [],
  bannerUuid: null,
  bannerName: null,
  bannerTemplateCollection: undefined,
  campaign: null,
  city: null,
  color: null,
  content: null,
  location: {},
  logoUrl: null,
  onLoad: null,
  title: null
}

export default Iframe

import React from 'react'
import styled from 'styled-components'
import { object } from 'prop-types'

const Error = ({ error, className }) => {
  return (
    <div className={ className }>
      <h6 className="text-danger">{ error.klass }</h6>
      <p className="text-secondary">{ error.message }</p>
      { Array.isArray(error.backtrace)
        ? (
          <div className="backtrace">
            { error.backtrace.map((line, index) => {
              return <code className="text-nowrap" key={ index.toString() }>{ line }</code>
            })
            }
          </div>
        )
        : null }
    </div>
  )
}

Error.propTypes = {
  error: object.isRequired
}

export default styled(Error)`
  .backtrace {
    overflow: scroll;
    height: 300px;
    max-width: 100%;
  }
`

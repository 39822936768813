import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { arrayOf, func, array } from 'prop-types'
import { useApolloClient } from 'react-apollo'
import { useMutation } from '@apollo/react-hooks'

import useCurrentCampaignUuid from 'shared/hooks/useCurrentCampaignUuid'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import ValidatableWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import { combineErrors } from 'shared/helpers/inputValidation'
import { buildFragmentParams } from 'shared/helpers/graphql'
import { textColor, bluishColor, white } from 'shared/style/colors'
import updateCreativeSetMutation from 'graphql/mutations/campaign/creativeSets/update'
import creativeSetFragment from 'graphql/fragments/creativeSet'
import landingPageType from 'shared/types/landingPageType'
import creativeSetType from 'shared/types/creativeSetType'

import revalidateLandingPages from './revalidateLandingPages'
import CreativeSetsSelect from './CreativeSetsSelect'
import Connections from './Connections'
import UrlField from './UrlField'

const LeftColumn = props => {
  const {
    className,
    landingPage,
    onUpdate,
    creativeSets,
    onRemove,
    onClone,
    errors,
    validate,
    setValidationPending
  } = props

  const campaignUuid = useCurrentCampaignUuid()
  const client = useApolloClient()
  const [updateCreativeSet] = useMutation(updateCreativeSetMutation)

  const handleNameChange = name => onUpdate({ name })
  const handleUrlChange = url => onUpdate({ url })

  const handleCreativeSetChange = (_selected, { action, option }) =>
    updateCreativeSet({
      variables: {
        uuid: option.value,
        input: {
          landingPageUuid: action === 'select-option' ? landingPage.uuid : null
        }
      },
      update: (cache, _data) => {
        const fragmentParams = buildFragmentParams(
          { __typename: 'CreativeSet', uuid: option.value },
          creativeSetFragment
        )
        const fragmentData = client.readFragment(fragmentParams)

        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...fragmentData,
            landingPage: action === 'select-option' ? landingPage : null
          }
        })
      }
    }).then(() => {
      setValidationPending(true)
      revalidateLandingPages({ client, campaignUuid, validate })
    })

  const errorsOnName = combineErrors({ errors, field: 'name', uuid: landingPage.uuid })
  const errorsOnUrl = combineErrors({ errors, field: 'url', uuid: landingPage.uuid })
  const errorsOnCreativeSets = combineErrors({
    errors,
    field: 'unassignedCreativeSets',
    uuid: landingPage.uuid
  })

  const handleRemoveClick = e => {
    e.preventDefault()

    if (!confirm(t`Are you sure you want to remove this landing page?`)) return

    onRemove(landingPage.uuid)
  }

  const handleCloneClick = () => {
    onClone(landingPage)
  }

  const creativeSetsWithLandingPages = creativeSets.filter((creativeSet) => {
    return creativeSet.channel.type !== 'portal' && creativeSet.channel.type !== 'facebook_lead_ad'
  })

  return (
    <div className={ className }>
      <Row>
        <InputWrapper error={ errorsOnName }>
          <ThriftyInput
            disabled={ landingPage.portal }
            value={ landingPage.name }
            onUpdate={ handleNameChange }
            placeholder={ t`Landing page name` }
            data-test-id="name-input"
          />
        </InputWrapper>
      </Row>

      <Row>
        <Label>{`${t`URL`}:`}</Label>
        <InputWrapper error={ errorsOnUrl }>
          <UrlField
            landingPage={ landingPage }
            onUpdate={ handleUrlChange }
            data-test-id="url-field"
          />
        </InputWrapper>
      </Row>

      {!landingPage.portal && (
        <Row>
          <InputWrapper error={ errorsOnCreativeSets } data-test-id="creative-set-select-wrapper">
            <CreativeSetsSelect
              creativeSets={ creativeSetsWithLandingPages }
              landingPage={ landingPage }
              onChange={ handleCreativeSetChange }
            />
          </InputWrapper>
        </Row>
      )}

      <Connections creativeSets={ creativeSets } landingPage={ landingPage } />

      <LandingPageActions>
        {!landingPage.portal && (
          <>
            <button
              type="button"
              className="btn btn-danger"
              onClick={ handleRemoveClick }
              data-test-id="remove-landing-page-button"
            >
              {t`Remove landing page`}
            </button>

            <CloneButton
              type="button"
              className="btn btn-info"
              onClick={ handleCloneClick }
              data-test-id="clone-landing-page-button"
            >
              {t`Duplicate`}
            </CloneButton>
          </>
        )}
      </LandingPageActions>
    </div>
  )
}

LeftColumn.propTypes = {
  creativeSets: arrayOf(creativeSetType).isRequired,
  landingPage: landingPageType.isRequired,
  errors: array,
  onClone: func,
  onRemove: func,
  onUpdate: func,
  setValidationPending: func,
  validate: func
}

LeftColumn.defaultProps = {
  onUpdate: () => {},
  onRemove: () => {},
  onClone: () => {},
  errors: [],
  validate: undefined,
  setValidationPending: undefined
}

const Row = styled.div`
  align-items: center;
  display: flex;
`

const Label = styled.label`
  color: ${textColor};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  margin: 0 1.5rem 0 0;
`
const CloneButton = styled.button`
  background-color: ${bluishColor};
  color: ${white};
`
const InputWrapper = styled(ValidatableWrapper)`
  width: 100%;
`

const LandingPageActions = styled.div`
  align-self: flex-start;
  margin-top: auto;
  padding: 0;

  button {
    border: none;
    font-size: 0.875rem;

    &:hover {
      text-decoration: underline;
    }

    :not(:last-child) {
      margin-right: 0.25rem;
    }
  }
`

export default styled(LeftColumn)`
  display: flex;
  flex-direction: column;
  width: 28rem;

  > :not(:last-child) {
    margin-bottom: 0.75rem;
  }
`

import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { t }        from 'ttag'
import {
  get, omit, isUndefined
}                   from 'lodash'
import styled       from 'styled-components'

import { ensureNumber } from 'shared/helpers/number'
import useUrlParams     from 'shared/hooks/useUrlParams'
import Filter           from 'shared/components/ui/Filter'
import Sorter           from 'shared/components/ui/Sorter'
import Pagination       from 'shared/components/ui/Pagination'
import PageHeader       from 'shared/components/ui/PageHeader'
import Search           from 'shared/components/ui/Search'
import {
  paginatedSearchTextChangeHandler,
  paginatedSearchPageChangeHandler
}                       from 'shared/helpers/paginatedSearch'
import campaignsQuery   from 'graphql/queries/campaigns'

import Item from './Item'

const ITEMS_PER_PAGE = 8

const filterOptions = [
  { value: 'assembly',  label: t`Draft` },
  { value: 'review',    label: t`Under review` },
  { value: 'scheduled', label: t`Countdown` },
  { value: 'live',      label: t`Live` },
  { value: 'paused',    label: t`Paused` },
  { value: 'finished',  label: t`Finished` },
  { value: 'archived',  label: t`Archived` }
]

const sorterOptions = [
  { value: 'name',       label: t`Name` },
  { value: 'phase',      label: t`Phase` },
  { value: 'created_at', label: t`Created at` },
  { value: 'updated_at', label: t`Updated at` }
]

const CampaignsList = () => {
  const [urlParams, setUrlParams] = useUrlParams()

  const page = ensureNumber(urlParams.page, 1)
  const search = urlParams.search
  const filterPhase = urlParams['filter[phase]']
  const sortBy = urlParams['sort_by']
  const sortOrder = urlParams['sort_order']

  const currentUserSelectedFilterOptions = [
    'assembly', 'review', 'scheduled', 'live', 'paused', 'finished'
  ]

  const selectedFilterOptions
    = isUndefined(filterPhase) ? currentUserSelectedFilterOptions : filterPhase.split(',')

  const isUrlParamsDefined = () => !!Object.keys(urlParams).length

  const selectedSorterOption = isUndefined(sortBy) ? 'name' : sortBy
  const selectedSorterDirection = isUndefined(sortOrder) ? 'asc' : sortOrder

  const { data } = useQuery(campaignsQuery, {
    variables: {
      query: {
        page,
        perPage: ITEMS_PER_PAGE,
        search,
        filter: {
          phase: selectedFilterOptions.join(',')
        },
        sortBy: sortBy,
        sortOrder: sortOrder
      }
    },
    fetchPolicy: 'no-cache',
    skip: !isUrlParamsDefined()
  })

  const campaigns = get(data, 'campaigns.items', [])
  const campaignsTotal = get(data, 'campaigns.total', 0)
  const pagesCount = Math.ceil(campaignsTotal / ITEMS_PER_PAGE)
  const handlePageChange = paginatedSearchPageChangeHandler(urlParams, setUrlParams)
  const handleSearchChange = paginatedSearchTextChangeHandler(urlParams, setUrlParams)

  const handleFilterChange = (options) => {
    setUrlParams({
      ...omit(urlParams, ['page', 'filter[phase]']),
      'filter[phase]': options.join()
    })
  }

  const handleSorterChange = (sortOption, sortDirection) => {
    setUrlParams({
      ...omit(urlParams, ['page', 'sort_by', 'sort_order']),
      sort_by: sortOption,
      sort_order: sortDirection
    })
  }

  useEffect(() => {
    if (isUrlParamsDefined()) {
      return
    }

    setUrlParams({
      ...omit(urlParams, ['page', 'filter[phase]', 'sort_by', 'sort_order']),
      'filter[phase]': selectedFilterOptions.join(),
      sort_by: selectedSorterOption,
      sort_order: selectedSorterDirection
    })
  })

  useEffect(() => {
    if (!search) return

    handleFilterChange([])
  }, [search])

  return (
    <div>
      <PageHeader title={ t`Campaigns` }>
        <CampaignsFilter className='d-flex'>
          <Sorter
            options={ sorterOptions }
            selectedOption={ selectedSorterOption }
            selectedDirection={ selectedSorterDirection }
            onChange={ handleSorterChange }
            className='sorter-input'
          />

          <Filter
            options={ filterOptions }
            selectedOptions={ selectedFilterOptions }
            onChange={ handleFilterChange }
            className='filter-input'
          />

          <Search
            searchText={ search }
            onSearch={ handleSearchChange }
            className='search-input'
          />
        </CampaignsFilter>
      </PageHeader>

      <div>
        {campaigns.map(campaign => <Item key={ campaign.uuid } campaign={ campaign } />)}
      </div>

      <Pagination
        page={ page }
        pageCount={ pagesCount }
        onPageChange={ handlePageChange }
      />
    </div>
  )
}

export default CampaignsList

export const CampaignsFilter = styled.div`
  .sorter-input, .filter-input {
    margin-right: 7px
  }

  .search-input {
    display: inline-block;
    width: auto;
  }
`

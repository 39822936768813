import React             from 'react'
import { t }             from 'ttag'
import { shape, string } from 'prop-types'

import UserRoleNavLink from 'routes/UserRoleNavLink'
import { IconBuilding } from 'shared/components/ui/icons'
import { canRead } from 'shared/helpers/auth'

import Body    from './Body'
import Section from './Section'

const Project = ({ project: { uuid, name } }) => {
  const projectLink = () => {
    if (canRead('projects')) return (
      <UserRoleNavLink to={ `/projects/${uuid}` }>{ name }</UserRoleNavLink>
    )
    return name
  }

  return (
    <Section title={ t`Project` }>
      <Body
        title={ projectLink() }
        icon={ <IconBuilding /> }
        onCLick={ () => {} }
      />
    </Section>
  )
}

Project.propTypes = {
  project: shape({
    name: string
  }).isRequired
}

export default Project

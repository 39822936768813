import gql from 'graphql-tag'

export default gql`
  mutation deleteStageSalesArgument($uuid: ID!) {
    deleteStageSalesArgument(uuid: $uuid) {
      salesArgument {
        uuid
      }
    }
  }
`

import React from 'react'

import Card from 'shared/components/ui/Card'

import Header        from './Header'
import SessionsTable from './SessionsTable'

const Sessions = () => {
  return (
    <Card className="mt-3 mb-3" header={ null }>
      <Header />
      <SessionsTable />
    </Card>
  )
}

export default Sessions

import { shape, string, number, oneOfType } from 'prop-types'

import productType from 'shared/types/productType'
import projectType from 'shared/types/projectType'

export default shape({
  product: productType,
  project: projectType,
  startDate: string,
  budget: oneOfType([number, string]),
  endDate: string,
  backgroundColor: string,
  uuid: string
})

import React, { useCallback } from 'react'
import { t } from 'ttag'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'

import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper/InputFeedback'
import ThriftyInput from 'shared/components/ui/ThriftyInput'
import EmbeddedCardSection from 'shared/components/ui/EmbeddedCardSection'
import { iconsGreyColor } from 'shared/style/colors'

const SalesStart = ({ className, updateStage, salesStartAt }) => {
  const handleTimeChange = (value) => {
    const [hour, minute] = value.split(':')
    const newDate = getDateObject().hour(hour).minute(minute).toISOString()

    updateStage({ salesStartAt: newDate })
  }

  const getDateObject = useCallback(() => {
    return salesStartAt ? moment(salesStartAt) : moment()
  }, [salesStartAt])

  const handleDateChange = (value) => {
    const input = moment(value)
    const newDate = getDateObject()
      .date(input.date())
      .month(input.month())
      .year(input.year())
      .toISOString()

    return updateStage({ salesStartAt: newDate })
  }

  const getSalesDate = useCallback(() => {
    return getDateObject().format('YYYY-MM-DD')
  }, [salesStartAt])

  const getSalesTime = useCallback(() => {
    return getDateObject().format('HH:mm')
  }, [salesStartAt])

  return (
    <EmbeddedCardSection titleText={ t`Sales start` } className={ className }>
      <div className="form-inline">
        <div className="group w-50 pr-4">
          <label>{ t`Sales start date` }</label>
          <InputWrapper>
            <ThriftyInput
              type="date"
              className="w-100"
              name="salesStartDate"
              data-test-id='sales-start-date-field'
              value={ getSalesDate() }
              onUpdate={ handleDateChange }
            />
          </InputWrapper>
        </div>
        <div className="group w-50 pl-4">
          <label>{ t`Sales start time` }</label>
          <InputWrapper>
            <ThriftyInput
              type="time"
              className="w-100"
              name="salesStartTime"
              data-test-id='sales-state-time-field'
              value={ getSalesTime() }
              onUpdate={ handleTimeChange }
            />
          </InputWrapper>
        </div>
      </div>
    </EmbeddedCardSection>
  )
}

SalesStart.propTypes = {
  updateStage: PropTypes.func.isRequired,
  salesStartAt: PropTypes.string
}

SalesStart.defaultProps = {
  salesStartAt: null
}

export default styled(SalesStart)`
  input, textarea {
    box-shadow: none;
  }
  .inline-input {
    width: 90%;
  }

  label {
    color: ${iconsGreyColor};
    display: block;
    font-size: 0.85rem;
    font-weight: normal;
    margin: 0;
  }

  .group {
    margin-bottom: 10px;
  }
`

import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'

import productType from 'shared/types/productType'
import { localDateTime } from 'shared/helpers/date'
import Paragraph from 'shared/components/ui/Paragraph'
import Origin from 'shared/components/Product/MainInfo/Origin'

const Div = styled.div`
  margin: 0 -1.25rem
`

const Footer = ({ product }) => {
  return (
    <Div>
      <Row className="mx-0">
        <Col className="px-4">
          <Paragraph isLabel>Created</Paragraph>
          <Paragraph>{ localDateTime(product.createdAt) }</Paragraph>
        </Col>
        <Col className="px-4">
          <Paragraph isLabel>Product type</Paragraph>
          <Paragraph>
            { product.promotableType }
            { product.promotable.used && '(used)' }
          </Paragraph>
        </Col>
        <Col className="px-4">
          <Paragraph isLabel>State</Paragraph>
          <Paragraph>{ product.sold ? 'Sold' : 'For sale' }</Paragraph>
        </Col>
        <Col className="px-4">
          <Paragraph isLabel>Origin</Paragraph>
          <Origin>{ product.link }</Origin>
        </Col>
        <Col className="px-4">
          <Paragraph isLabel>Id</Paragraph>
          <Paragraph>{ product.id }</Paragraph>
        </Col>
        <Col className="px-4">
          <Paragraph isLabel>CSM Team</Paragraph>
          <Paragraph>{ product.csmTeam }</Paragraph>
        </Col>
      </Row>
    </Div>
  )
}

Footer.propTypes = {
  product: productType.isRequired
}

export default Footer

import { useMutation } from 'react-apollo'

import sendConfirmationEmailMutation from 'graphql/mutations/users/sendConfirmationEmail'

export default function useSendConfirmationEmail() {
  const [sendConfirmationEmail, variables] = useMutation(sendConfirmationEmailMutation)

  return {
    sendConfirmationEmail,
    ...variables
  }
}

import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { func, object, bool } from 'prop-types'

import Button             from 'shared/components/ui/Button'
import Caret              from 'shared/components/ui/Caret'
import InputWrapper       from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import StageUnitBalconies from 'shared/components/StageUnitBalconies'
import buildingTypes      from 'shared/constants/buildingTypes'

import OwnershipTypeInput from './components/OwnershipTypeInput'
import UnitIdInput        from './components/UnitIdInput'
import BedroomCountInput  from './components/BedroomCountInput'
import UnitTypeSelect     from './components/UnitTypeSelect'
import SizeM2Input        from './components/SizeM2Input'
import TotalPriceInput    from './components/TotalPriceInput'
import FloorNumberSelect  from './components/FloorNumberSelect'
import BuildingSelect     from './components/BuildingSelect'
import StageSelect        from './components/StageSelect'
import StatusSelect       from './components/StatusSelect'
import DeleteButton       from './components/DeleteButton'
import NumberInput        from './components/NumberInput'
import Facilities         from './components/Facilities'
import Parkings           from './components/Parkings'

const Unit = ({
  stage,
  building,
  unit,
  className,
  onUpdate,
  onDelete,
  handleUploadFloorPlanClick,
  errors,
  isSnapshotEnabled
}) => {
  const [opened, setOpened] = useState(false)
  const [selectedFacilities, setSelectedFacilities] = useState(unit.facilities || [])

  const hasFacility = (value) => selectedFacilities.map(v => v.key).includes(value)

  const getUnitTypes = useCallback(() => {
    if (isSnapshotEnabled) {
      const types = stage.currentSnapshotRecord?.unitSets.map(v => v.buildingType).filter(Boolean)
      return types?.length ? [...new Set(types)] : []
    }
    return buildingTypes.map(v => v.value)
  }, [stage?.currentSnapshotRecord, isSnapshotEnabled])

  return (
    <div className={ className } data-test-id='units-table-row'>
      <div className='unit-base-params'>

        <div className='collapse-button'>
          <Caret
            className="caret"
            opened={ opened }
            data-test-id="card-caret"
            onClick={ () => setOpened(!opened) }
          />
        </div>

        <InputWrapper error={ errors && errors[unit.uuid]('unitId') } >
          <UnitIdInput
            autoSelect
            unitId={ unit.unitId }
            onUpdate={ onUpdate }
            uuid={ unit.uuid }
          />
        </InputWrapper>

        <InputWrapper error={ errors && errors[unit.uuid]('bedroomCount') } >
          <BedroomCountInput
            autoSelect
            bedroomCount={ unit.bedroomCount }
            onUpdate={ onUpdate }
            uuid={ unit.uuid }
          />
        </InputWrapper>

        <InputWrapper
          error={ errors && errors[unit.uuid]('unitType') }
          data-test-id='unit-type-select-wrapper'
        >
          <UnitTypeSelect
            value={ unit.unitType }
            stageBuildingTypes={ building ? building.buildingTypes : getUnitTypes() }
            onUpdate={ onUpdate }
            uuid={ unit.uuid }
          />
        </InputWrapper>

        <InputWrapper error={ errors && errors[unit.uuid]('sizeM2') } >
          <SizeM2Input
            autoSelect
            sizeM2={ unit.sizeM2 }
            onUpdate={ onUpdate }
            uuid={ unit.uuid }
          />
        </InputWrapper>

        <InputWrapper error={ errors && errors[unit.uuid]('totalPrice') } >
          <TotalPriceInput
            autoSelect
            totalPrice={ unit.totalPrice }
            onUpdate={ onUpdate }
            uuid={ unit.uuid }
          />
        </InputWrapper>

        <div data-test-id='floor-select-wrapper'>
          <FloorNumberSelect
            value={ unit.floorNumber }
            onUpdate={ onUpdate }
            data-test-id='floor-input'
            uuid={ unit.uuid } />
        </div>

        <div className='upload-button'>
          <Button onClick={ () => handleUploadFloorPlanClick(unit) }>
            { t`Upload` }
          </Button>
        </div>

        <StatusSelect
          value={ unit.state }
          onUpdate={ onUpdate }
          uuid={ unit.uuid }
        />

        {stage ? (
          <InputWrapper
            error={ errors && errors[unit.uuid]('buildingId') }
            data-test-id='unit-building-select-wrapper'
          >
            <BuildingSelect
              value={ unit.buildingId }
              projectUuid={ stage.projectUuid }
              onUpdate={ onUpdate }
              uuid={ unit.uuid }
            />
          </InputWrapper>
        ) : (
          <InputWrapper
            error={ errors && errors[unit.uuid]('buildingId') }
            data-test-id='unit-building-select-wrapper'
          >
            <StageSelect
              value={ unit.stageId }
              projectUuid={ building.projectUuid }
              onUpdate={ onUpdate }
              uuid={ unit.uuid }
            />
          </InputWrapper>
        )}

        <div className='delete-button'>
          <DeleteButton
            onDelete={ onDelete }
            uuid={ unit.uuid }
          />
        </div>
      </div>

      {opened && (
        <div className='unit-optional-params'>
          <div className='optional-row'>
            <div className='row-label'>
              <p>{ t`Price` }</p>
            </div>
            <div className='values-block'>
              <div>
                <label>{ t`Purchase price` }</label>
                <NumberInput
                  paramName='purchasePrice'
                  onChange={ onUpdate }
                  value={ unit.purchasePrice }
                  uuid={ unit.uuid }
                />
              </div>
              <div>
                <label>{ t`Est. monthly costs` }</label>
                <NumberInput
                  paramName='sharedPrice'
                  onChange={ onUpdate }
                  value={ unit.sharedPrice }
                  uuid={ unit.uuid }
                />
              </div>
              <div>
                <label>{ t`Transaction costs` }</label>
                <NumberInput
                  paramName='totalCosts'
                  onChange={ onUpdate }
                  value={ unit.totalCosts }
                  uuid={ unit.uuid }
                />
              </div>
              <div>
                <label>{ t`Deposit` }</label>
                <NumberInput
                  paramName='deposit'
                  onChange={ onUpdate }
                  value={ unit.deposit }
                  uuid={ unit.uuid }
                />
              </div>
              <div>
                <label>{ t`Joint debt` }</label>
                <NumberInput
                  paramName='jointDebt'
                  onChange={ onUpdate }
                  value={ unit.jointDebt }
                  uuid={ unit.uuid }
                />
              </div>
            </div>
          </div>

          <div className='optional-row'>
            <div className='row-label'>
              <p>{ t`Size` }</p>
            </div>
            <div className='values-block'>
              <div>
                <label>{ t`Rooms` }</label>
                <NumberInput
                  paramName='roomCount'
                  onChange={ onUpdate }
                  value={ unit.roomCount }
                  uuid={ unit.uuid }
                />
              </div>
              <div>
                <label>{ t`Usable area` }</label>
                <NumberInput
                  paramName='usableAreaM2'
                  onChange={ onUpdate }
                  value={ unit.usableAreaM2 }
                  uuid={ unit.uuid }
                />
              </div>
              <div>
                <label>{ t`Lot size` }</label>
                <NumberInput
                  paramName='lotSizeM2'
                  onChange={ onUpdate }
                  value={ unit.lotSizeM2 }
                  uuid={ unit.uuid }
                />
              </div>
            </div>
          </div>

          <div className='optional-row'>
            <div className='row-label'>
              <p>{ t`Facilities` }</p>
            </div>
            <div className='values-block'>
              <div>
                <Facilities
                  disabled={ false }
                  unitUuid={ unit.uuid }
                  facilities={ selectedFacilities }
                  setFacilities={ setSelectedFacilities }
                />
              </div>
            </div>
          </div>

          <div className='optional-row'>
            <div className='row-label'>
              <p>{ t`Parking` }</p>
            </div>
            <div className='values-block'>
              {hasFacility('parking') ? (
                <>
                  <div>
                    <label>{ t`Included parking slots` }</label>
                    <NumberInput
                      paramName='parkingSlotsAmount'
                      onChange={ onUpdate }
                      data-test-id='parking-slots-input'
                      value={ unit.parkingSlotsAmount }
                      uuid={ unit.uuid }
                    />
                  </div>
                  <div>
                    <Parkings
                      unitUuid={ unit.uuid }
                      parkings={ unit.parkings }
                    />
                  </div>
                </>
              ) : null}
              <div>
                <label>{ t`Optional parking cost` }</label>
                <NumberInput
                  paramName='parkingPrice'
                  onChange={ onUpdate }
                  value={ unit.parkingPrice }
                  uuid={ unit.uuid }
                />
              </div>
            </div>
          </div>
          <div className='optional-row'>
            <div className='row-label'>
              <p>{ t`Ownership` }</p>
            </div>
            <div className='values-block'>
              <div>
                <label>{ t`Ownership type` }</label>
                <OwnershipTypeInput
                  paramName='ownershipType'
                  onChange={ onUpdate }
                  value={ unit.ownershipType }
                  uuid={ unit.uuid }
                  data-test-id='ownership-type'
                />
              </div>
            </div>
          </div>
          {hasFacility('balcony') ? (
            <div className='optional-row'>
              <div className='row-label'>
                <p>{ t`Balcony` }</p>
              </div>
              <div className='values-block'>
                { <StageUnitBalconies stageUnit={ unit } /> }
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

Unit.propTypes = {
  handleUploadFloorPlanClick: func.isRequired,
  onDelete: func.isRequired,
  onUpdate: func.isRequired,
  unit: object.isRequired,
  building: object,
  errors: object,
  isSnapshotEnabled: bool,
  stage: object,
}

Unit.defaultProps = {
  building: undefined,
  errors: undefined,
  isSnapshotEnabled: false,
  stage: undefined
}

export default styled(Unit)`
  padding: 0 1rem;
  font-size: .75rem;

  .unit-base-params {
    display: flex;
    align-items: flex-start;
    padding: .5rem 0;

     > div {
      flex: 1;
      padding: 0 .25rem;

      > div {
        width: 100%;
      }
    }

    .collapse-button {
      flex: initial;
      width: 3rem;
      height: 2.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .upload-button {
      flex: initial;
      width: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .delete-button {
      flex: initial;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .unit-optional-params {
    margin-bottom: 1rem;
    border: 1px solid #dee2e6;
    border-bottom: none;

    .optional-row {
      display: flex;
      border-bottom: 1px solid #dee2e6;

      .row-label {
        width: 8rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #dee2e6;
        color: #697386;

        p {
          text-transform: uppercase;
          margin: 0;
          font-size: 1rem;
        }
      }

      .values-block {
        display: flex;
        flex: 1;
        padding: 1rem;

        > div {
          margin-right: 1rem;

          label {
            font-size: .875rem;
            color: #697386;
          }

          > div {
            height: auto;
          }
        }
      }
    }
  }
`

import React      from 'react'
import { string } from 'prop-types'

import companyQuery  from 'graphql/queries/company'
import ResourceQuery from 'shared/components/ResourceQuery'

import Summary from './Summary'
import Users from './Users'

const Company = ({ uuid }) => {
  return (
    <ResourceQuery query={ companyQuery } variables={{ uuid }}>
      {(company) => (
        <div>
          <Summary company={ company } />
          <Users users={ company.users } companyUuid={ company.uuid } />
        </div>
      )}
    </ResourceQuery>
  )
}

Company.propTypes = {
  uuid: string.isRequired
}

export default Company

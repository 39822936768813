import React from 'react'
import styled from 'styled-components'
import { string, shape, array, func } from 'prop-types'
import { useApolloClient } from '@apollo/react-hooks'

import { buttonTextColor } from 'shared/style/colors'
import stageType from 'shared/types/stageType'
// eslint-disable-next-line max-len
import DetailsWithSnapshots from 'shared/components/ProductDetails/StageDetails/DetailsWithSnapshots'

import LeftColumn from './LeftColumn'
import RightColumn from './RightColumn'
import updateStage from './updateStage'

const Details = ({
  className,
  product,
  errors,
  validate
}) => {
  const { promotable: stage, company } = product
  const client = useApolloClient()
  const isSnapshotEnabled = company.enabledFeatures.includes('snapshot')
  const handleUpdate = (input, location) => updateStage(client, stage, input, location).
    then(({ data: { updateStage: { stage } } }) => {
      if (!validate) { return }

      validate({ updated: stage })
    })

  if (isSnapshotEnabled) {
    return <DetailsWithSnapshots 
      creatingMode 
      stage={ stage } 
      product={ product } 
      errors={ errors }/>
  }

  return (
    <div className={ className }>
      <LeftColumn stage={ stage } onUpdate={ handleUpdate } errors={ errors } />
      <RightColumn stage={ stage } onUpdate={ handleUpdate } errors={ errors } />
    </div>
  )
}

Details.propTypes = {
  className: string.isRequired,
  product: shape({
    promotable: stageType.isRequired
  }).isRequired,
  errors: array,
  validate: func
}

Details.defaultProps = {
  errors: [],
  validate: undefined
}

export default styled(Details)`
  display: flex;
  width: 100%;

  .group {
    display: flex;
    margin-bottom: 0.75rem;
  }

  .group > label {
    color: ${buttonTextColor};
    display: flex;
    font-size: 0.875rem;
    line-height: 2rem;
    flex-direction: column;
    flex-shrink: 0;
    width: 12.5rem;
    margin: 0;

    i {
      font-size: 0.75rem;
      line-height: 1rem;
      width: 8.5rem;
    }
  }

  .form-control {
    box-shadow: none;
    height: 2rem;
  }
`

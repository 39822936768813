import { t } from 'ttag'

const mimeTypes = {
  image:     ['image/jpeg'],
  banner:    ['image/jpeg'],
  logo:      ['image/png', 'image/jpeg', 'image/svg+xml', 'application/xml', 'image/gif'],
  video:     ['video/mp4', 'video/quicktime', 'video/x-msvideo', 'video/vnd.avi', 'video/x-m4v'],
  floorplan: ['application/pdf', 'image/png', 'image/jpeg'],
  project_logo: ['image/png', 'image/jpeg', 'image/svg+xml', 'application/xml', 'image/gif'],
}

const typesTranslations = {
  image: t`image`,
  banner: t`banner`,
  logo: t`logo`,
  video: t`video`,
  floorplan: t`floor plan`,
  project_logo: t`project logo`,
}

export { typesTranslations }

export default mimeTypes

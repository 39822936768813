import { get } from 'lodash'
import { t } from 'ttag'
import MapboxCircle from 'mapbox-gl-circle'
import bbox from '@turf/bbox'
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'

import credentials from 'config/credentials'

const getCountry = ({ longitude, latitude }) => {
  const geocodingClient = mbxGeocoding({ accessToken: credentials.mapboxApiKey })

  return geocodingClient.reverseGeocode({
    query: [longitude, latitude]
  })
    .send()
    .then(({ body }) => {
      const countryFeature = body.features.find((feature) => feature.place_type.includes('country'))
      return countryFeature ? countryFeature.text : null
    })
}

const DEFAULT_RADIUS = 1000

const DEFAULT_LOCATION_TYPE = 'place'

const PLACE_TYPE_MAPPING = {
  address:      t`Address`,
  country:      t`Country`,
  locality:     t`Region`,
  neighborhood: t`Neighborhood`,
  place:        t`Place`,
  poi:          t`POI`,
  postcode:     t`Postcode`,
  region:       t`Region`,
  district:     t`District`
}

export const mapFitBounds = (map, bounds) => {
  map.fitBounds(bounds, { padding: 20, duration: 200, linear: true })
}

export const buildCircle = (location) => (
  new MapboxCircle(
    { lat: location.latitude || 0, lng: location.longitude || 0 },
    location.radius || DEFAULT_RADIUS
  )
)

export const fitCircleBounds = (map, location) => {
  const bounds = buildCircle(location).getBounds()
  mapFitBounds(map, [bounds.sw, bounds.ne])
}

export const fitAllLocations = (map, locations) => {
  if (!locations.length) return

  const circles = locations.map(location => buildCircle(location))
  mapFitBounds(
    map,
    bbox(
      {
        type: 'FeatureCollection',
        features: circles.map((circle) => circle._circle)
      }
    )
  )
}

export const buildNewLocation = ({ longitude, latitude, radius, operator }) => (
  getCountry({
    longitude: longitude,
    latitude: latitude
  }).then((country) => (
    {
      longitude: longitude,
      latitude: latitude,
      radius: radius,
      operator: operator,
      country: country,
      locationType: DEFAULT_LOCATION_TYPE
    }
  ))
)

export const getPlaceTypeLabel = (placeType) => (
  get(PLACE_TYPE_MAPPING, placeType, placeType)
)

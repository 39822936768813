import React      from 'react'
import { t }      from 'ttag'
import { number } from 'prop-types'

import Section from './Section'
import Body    from './Body'

const Stages = ({ stages }) => {
  return (
    <Section title={ t`Stages` }>
      <Body title={ stages } onCLick={ () => {} } />
    </Section>
  )
}

Stages.propTypes = {
  stages: number
}

Stages.defaultProps = {
  stages: 99
}

export default Stages

import React from 'react'
import { t } from 'ttag'
import { string, func } from 'prop-types'

import Select         from 'shared/components/ui/ThriftyInput/TouchableSelect'
import { redColor }   from 'shared/style/colors'
import { unitStates } from 'shared/constants/unitStates'

const styles = {
  container: provided => ({
    ...provided,
    minWidth: '9rem'
  }),
  control: (provided, { selectProps }) => ({
    ...provided,
    borderColor: selectProps.isInvalid ? redColor : provided.borderColor
  })
}

const StatusSelect = ({ value, onUpdate, uuid, ...props }) => {
  const handleChange = ({ value: state }) => {
    return onUpdate({ uuid, attributes: { state: state } })
  }

  const options = Object.entries(unitStates).map(([key, value]) => {
    return { value: key, label: value }
  })

  return (
    <Select
      value={ options.find((option) => (option.value === value)) }
      options={ options }
      onChange={ handleChange }
      styles={ styles }
      data-test-id='state-select'
      placeholder={ t`Select...` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

StatusSelect.propTypes = {
  onUpdate: func.isRequired,
  uuid: string.isRequired,
  value: string
}

StatusSelect.defaultProps = {
  value: undefined
}

export default StatusSelect

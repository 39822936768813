import React from 'react'

import matchType from 'shared/types/matchType'
import NewsEdit from 'shared/components/ProjectNewsEdit'

const ProjectNewsEdit = ({ match }) => {
  return (
    <NewsEdit uuid={ match.params.uuid } />
  )
}

ProjectNewsEdit.propTypes = {
  match: matchType.isRequired
}

export default ProjectNewsEdit

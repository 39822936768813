import React, { useRef } from 'react'
import { func } from 'prop-types'

const WaitForPreviewLoad = ({ children, onLoad }) => {
  const loaded = useRef([])

  const onChildLoad = (banner, { target }) => { 
    loaded.current.push({
      banner,
      target
    })

    if (loaded.current.length === children.length) {
      onLoad(loaded.current)
      loaded.current = []
    }
  }

  return children.map(child => 
    React.cloneElement(child, { onLoad: onChildLoad })
  )
}

WaitForPreviewLoad.propTypes = {
  onLoad: func
}

WaitForPreviewLoad.defaultProps = {
  onLoad: () => {  }
}

export default WaitForPreviewLoad

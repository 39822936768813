import React                           from 'react'
import styled                          from 'styled-components'
import { number, string, array, func } from 'prop-types'
import { useApolloClient }             from 'react-apollo'

import { buttonTextColor } from 'shared/style/colors'
import { combineErrors }   from 'shared/helpers/inputValidation'
import useCurrentCampaign  from 'shared/hooks/useCurrentCampaign'
import Input               from 'shared/components/ui/ThriftyInput/BigNumber'
import InputWrapper        from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import PlusIcon            from 'shared/components/ui/icons/PlusIcon'

import removeSpecificationChannel from './removeSpecificationChannel'
import updateSpecificationChannel from './updateSpecificationChannel'
import ChannelSelect              from './ChannelSelect'

const Channel = ({
  className,
  index,
  currency,
  type,
  spending,
  selectedChannels,
  errors,
  validate,
  setValidationPending
}) => {
  const campaign = useCurrentCampaign()
  const campaignUuid = campaign.uuid
  const client = useApolloClient()
  const validateSpecification = (specification) => {
    if (!validate) { return }
    validate({ payload: { ...campaign, specification } })
  }

  const errorsOnSpending = combineErrors({
    errors,
    uuid: campaignUuid,
    field: `specification.channels[${index}].spending`
  })

  const handleDeleteChannel = () => {
    removeSpecificationChannel({ client, campaignUuid, index })
      .then(({ data: { removeSpendingChannel: { specification } } }) => {
        validateSpecification(specification)
      })
  }

  const buildChannel = (name, value) => ({
    type,
    spending,
    [name]: name === 'spending' ? parseInt(value) : value
  })

  const handleChannelSelect = ({ target: { name, value } }) => (
    updateSpecificationChannel({
      client, campaignUuid, index, channel: buildChannel(name, value)
    })
  )

  const channelUpdateHandler = (name) => (
    (value) => {
      if(setValidationPending) setValidationPending(true)
      updateSpecificationChannel({
        client, campaignUuid, index, channel: buildChannel(name, value)
      }).then(({ data: { updateSpendingChannel: { specification } } }) => {
        validateSpecification(specification)
      })
    }
  )

  return (
    <div className={ className } data-testid='spending-channel' data-test-id='spending-channel'>
      <PlusIcon
        rotated
        onClick={ handleDeleteChannel }
        className='delete-button'
        data-testid='delete-button'
      />

      <ChannelSelect
        onChange={ handleChannelSelect }
        selectedChannels={ selectedChannels }
        type={ type }
      />

      <InputWrapper error={ errorsOnSpending } data-test-id='spending-input-wrapper'>
        <Input
          aria-label={ `spending ${type}` }
          onUpdate={ channelUpdateHandler('spending') }
          value={ spending }
          data-test-id='spending-input'
          data-testid='spending-input'
        />
      </InputWrapper>

      <span className='currency'>{ currency }</span>
    </div>
  )
}

Channel.propTypes = {
  currency: string.isRequired,
  index: number.isRequired,
  selectedChannels: array.isRequired,
  spending: number.isRequired,
  type: string.isRequired,
  errors: array,
  setValidationPending: func,
  validate: func
}

Channel.defaultProps = {
  errors: [],
  validate: undefined,
  setValidationPending: undefined
}

export default styled(Channel)`
  align-items: baseline;
  color: ${buttonTextColor};
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: .75rem;

  ${PlusIcon} {
    cursor: pointer;
    margin-right: .25rem;
    position: absolute;
    left: -1.5rem
  }

  .form-control {
    display: inline-block;
    color: ${buttonTextColor};
    width: 7.5rem;
    box-shadow: none !important;
    height: auto !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    padding: 0.225rem 0.5rem !important;
  }

  .invalid-feedback {
    max-width: 8rem;
  }

  .delete-button {
    top: 0.6rem;
  }

  [class$=singleValue] {
    color: ${buttonTextColor}
  }
`

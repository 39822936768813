import styled from 'styled-components'

import { greyishBrownColor } from 'shared/style/colors'

export default styled.h4`
  color: ${greyishBrownColor}
  font-size: 1.25rem
  margin: 0 0 1rem
  opacity: .5
  width: 80%
`

import React from 'react'
import { string, number } from 'prop-types'
import styled from 'styled-components'
import { ngettext, msgid } from 'ttag'

import {
  campaignLiveMarkerColor,
  campaignClosedMarkerColor
} from 'shared/style/colors'

const CampaignCount = ({ count, className }) => {
  return (
    <div className={ className }>
      <div className={
        `campaigns-status-marker ${count > 0 ? 'active' : 'no-active'}`
      } />
      {
        ngettext(msgid`${count} campaign live`, `${count} campaigns live`, count)
      }
    </div>
  )
}

CampaignCount.propTypes = {
  className: string.isRequired,
  count: number.isRequired
}

export default styled(CampaignCount)`
  font-size: 0.75rem
  font-weight: 500
  padding-top: 0.75rem

  .campaigns-status-marker {
    display: inline-block
    margin-right: 0.5rem
    margin-bottom: 0.1rem
    border-radius: 100%
    height: 6px
    width: 6px
  }

  .campaigns-status-marker.active {
    background-color: ${campaignLiveMarkerColor}
  }

  .campaigns-status-marker.no-active {
    background-color: ${campaignClosedMarkerColor}
  }
`

import React, { useCallback } from 'react'
import styled                 from 'styled-components'
import { cloneDeep }          from 'lodash'
import { useQuery }           from 'react-apollo'
import { object, bool }             from 'prop-types'

import { cardPaddingX, cardPaddingY } from 'shared/style/placeholders'
import Media                          from 'shared/components/Media'
import projectAssetAssignmentsQuery   from 'graphql/queries/projectAssetAssignments'

import attachFileToBuilding          from './attachFileToBuilding'
import removeAssetAssignment         from './removeAssetAssignment'
import createAssetAssignment         from './createAssetAssignment'
import buildingAssetAssignmentsQuery from './buildingAssetAssignmentsQuery'

const BuildingMedia = ({ building, className, readOnly }) => {
  const { data: buildingAssetAssignmentsData, loading: loadingBuildingAssetAssignments } = useQuery(
    buildingAssetAssignmentsQuery,
    { variables: { uuid: building.uuid } }
  )

  const { data: projectAssetAssignmentsData, loading: loadingProjectAssetAssignments } = useQuery(
    projectAssetAssignmentsQuery,
    { variables: { uuid: building.projectUuid } }
  )

  const attachFileToAssetable = useCallback(({ client, blobId, mediaType }) => {
    return attachFileToBuilding({ client, blobId, mediaType, buildingUuid: building.uuid })
  }, [building.uuid])

  if (loadingProjectAssetAssignments || loadingBuildingAssetAssignments) {
    return null
  }

  const projectAssets = projectAssetAssignmentsData.project.assetAssignments.map((assignment) => {
    const asset = cloneDeep(assignment.asset)
    asset.assignmentUuid = assignment.uuid
    return asset
  })

  const buildingWithAssetAssignments = Object.assign(building, {
    assetAssignments: buildingAssetAssignmentsData.building.assetAssignments
  })

  return (
    <div className={ className }>
      <Media
        availableAssets={ projectAssets }
        assetAssignable={ buildingWithAssetAssignments }
        mediaSourceType={ 'Project' }
        attachFileToAssetable={ attachFileToAssetable }
        createRemoveAssetAssignment={ removeAssetAssignment }
        createCreateAssetAssignment={ createAssetAssignment }
        readOnly={ readOnly }
      />
    </div>
  )
}

BuildingMedia.propTypes = {
  building: object.isRequired,
  readOnly: bool
}

BuildingMedia.defaultProps = {
  readOnly: false
}

export default styled(BuildingMedia)`
  ${cardPaddingX}
  ${cardPaddingY}
`

import { useContext }  from 'react'
import { useMutation } from 'react-apollo'

import removeMutation            from 'graphql/mutations/showings/remove'
import projectFragment           from 'graphql/fragments/project'
import stageFragment             from 'graphql/fragments/stage'
import { buildFragmentParams }   from 'shared/helpers/graphql'
import { ProjectSummaryContext } from 'shared/components/Documents/Showings/index'

export default function useDeleteButton(uuid) {
  const { showableUuid, showableType } = useContext(ProjectSummaryContext)

  const [removeShowing, { loading }] = useMutation(removeMutation, {
    variables: { uuid },
    update: (cache, { data }) => {
      if (!data.removeShowing) return

      const object = { __typename: showableType, uuid: showableUuid }
      const usedFragment = showableType === 'Project' ? projectFragment : stageFragment
      const fragmentParams = buildFragmentParams(object, usedFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          showings: fragmentData.showings.filter((showing) => showing.uuid !== uuid)
        }
      })
    }
  })

  return {
    removeShowing,
    loading
  }
}

import React from 'react'
import { arrayOf, shape, string, func } from 'prop-types'
import styled from 'styled-components'

import { cardPaddingXSize } from 'shared/style/sizes'
import { greyishWhiteColor } from 'shared/style/colors'
import { cardBorderValue } from 'shared/style/placeholders'
import { channelPresentation } from 'shared/constants/channelPresentation'

import Nav from './Nav'

const ChannelsNavbar = ({ channels, currentChannel, setCurrentChannel }) => {
  return (
    <Container>
      {channels.map(({ type, title, enabled }) => (
        <Nav
          enabled={ enabled }
          key={ type }
          label={ channelPresentation[type] || title }
          active={ type === currentChannel }
          onClick={ () => setCurrentChannel(type) } />
      ))}
    </Container>
  )
}

const Container = styled.div`
  border-bottom: ${cardBorderValue};

  background-color: ${greyishWhiteColor};
  display: flex;
  margin-left: -${cardPaddingXSize};
  margin-right: -${cardPaddingXSize};
  margin-top: -1rem;
  margin-bottom: 1rem;

  ${Nav} {
    flex-basis: 20%;
    flex-grow: 0;
    flex-shrink: 0
  }
`

ChannelsNavbar.propTypes = {
  channels: arrayOf(shape({
    type: string.isRequired,
    title: string.isRequired
  })).isRequired,
  currentChannel: string.isRequired,
  setCurrentChannel: func.isRequired
}

export default ChannelsNavbar

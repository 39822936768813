import campaignLandingPagesQuery from 'graphql/queries/campaignLandingPages'
import campaignCreativeSetsQuery from 'graphql/queries/campaignCreativeSets'

import buildLandingPagesValidationPayload from './buildLandingPagesValidationPayload'

const fetchCreativeSetsFromCache = ({ client, campaignUuid }) => {
  const { campaignCreativeSets } = client.readQuery({
    query: campaignCreativeSetsQuery,
    variables: { campaignUuid }
  })
  return campaignCreativeSets
}

export default ({ client, campaignUuid, creativeSets, validate }) => {
  const { campaign: { landingPages } } = client.readQuery({
    query: campaignLandingPagesQuery,
    variables: { uuid: campaignUuid }
  })

  validate({
    payload: buildLandingPagesValidationPayload({
      landingPages,
      creativeSets: creativeSets || fetchCreativeSetsFromCache({ client, campaignUuid })
    })
  })
}

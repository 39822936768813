import React from 'react'
import { array, func, object, shape, number } from 'prop-types'
import styled from 'styled-components'

import ItemsList from 'shared/components/ItemsList'

const List = ({ data, params, onTableChange, perPage, columns, ...props }) => {
  return (
    <Container { ...props }>
      <ItemsList
        hasSearchEnabled={ false }
        params={ params }
        setUrlParams={ onTableChange }
        totalCount={ data.total }
        perPage={ perPage }
        items={ data.items }
        columns={ columns }
      />
    </Container>
  )
}

List.propTypes = {
  columns: array.isRequired,
  data: shape({
    total: number.isRequired,
    items: array.isRequired
  }).isRequired,
  onTableChange: func.isRequired,
  params: object.isRequired,
  perPage: number.isRequired,
}

const Container = styled.div`
  margin-top: -1.1rem;
  margin-left: -1.45rem;
  margin-right: -1.45rem;
  margin-bottom: -3.2rem
`

export default List

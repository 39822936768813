import React                   from 'react'
import { Redirect }            from 'react-router-dom'
import { shape, node, string } from 'prop-types'

import { getUser, isUserAdmin } from 'shared/helpers/auth'

import loginRoutes from './loginRoutes'
import adminRoutes from './adminRoutes'
import userRoutes  from './userRoutes'

const isLoginPath = (path) => (
  loginRoutes.map((route) => route.path).includes(path)
)

const isUserPath = (path) => (
  userRoutes.map((route) => route.path).includes(path)
)

const isAdminPath = (path) => {
  const pathSegment = new URL(path, window.location).pathname
  return adminRoutes.map((route) => route.path).includes(pathSegment)
}

const userRolePath = (path) => isUserAdmin() ? `/admin${path}` : path

const userDefaultPath = () => {
  if (isUserAdmin()) {
    return '/admin'
  } else {
    return '/'
  }
}

const loginPathFrom = (path) => {
  return path.startsWith('/admin') ? '/admin/login' : '/login'
}

const getRedirectPath = (user, path) => {
  if (!isLoginPath(path) && !user) {
    return loginPathFrom(path)
  }

  if (isLoginPath(path) && user) {
    return userDefaultPath()
  }

  if (isUserPath(path) && isUserAdmin()) {
    return userDefaultPath()
  }

  if (isAdminPath(path) && !isUserAdmin()) {
    return userDefaultPath()
  }

  return null
}

const AppRedirect = ({ children, ...props }) => {
  const path = props.match.path
  const user = getUser()
  const redirectPath = getRedirectPath(user, path)

  return redirectPath
    ? (
      <Redirect to={ redirectPath } />
    ) : (
      children
    )
}

AppRedirect.propTypes = {
  children: node.isRequired,
  match: shape({
    path: string
  }).isRequired
}

export { loginPathFrom, userRolePath }

export default AppRedirect

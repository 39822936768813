import React    from 'react'
import { t }    from 'ttag'
import styled   from 'styled-components'

import SidebarBase from 'shared/components/Layout/Sidebar'
import SidebarLink from 'shared/components/Layout/Sidebar/SidebarLink'
import LinksGroup  from 'shared/components/Layout/Sidebar/LinksGroup'
import {
  IconDashboard,
  // IconMailLightning,
  IconTwoTables,
  IconHomeHeart,
  IconPosition,
  IconStack,
  // IconTools,
  // IconGroup
} from 'shared/components/ui/icons'
import { canRead }    from 'shared/helpers/auth'
import getImageUrl    from 'shared/helpers/getImageUrl'
import usersImage     from 'shared/assets/images/users.png'
import analyseImage   from 'shared/assets/images/analyse.png'

const Sidebar = (props) => {
  return (
    <SidebarBase { ...props }>
      <LinksGroup>
        {canRead('analytics') && (
          <SidebarLink exact to="/">
            <IconDashboard /> { t`Analytics` }
          </SidebarLink>
        )}
        {/* Hidden for MVP */}
        {/*<SidebarLink>*/}
        {/*  <IconMailLightning /> { t`Reports` }*/}
        {/*</SidebarLink>*/}

        {canRead('projects') && (
          <SidebarLink to="/projects">
            <IconTwoTables /> { t`Projects` }
          </SidebarLink>
        )}
        {canRead('sales_reports') && (
          <SidebarLink to="/sales_reports">
            <span className="custom-icon">
              <img
                src={ getImageUrl(analyseImage) }
                alt={ t`Analysis` }
              />
            </span> { t`Analysis` }
          </SidebarLink>
        )}
        {canRead('campaigns') && (
          <SidebarLink to="/campaigns">
            <IconHomeHeart /> { t`Campaigns` }
          </SidebarLink>
        )}
        {canRead('ads') && (
          <SidebarLink to="/ads">
            <IconStack /> { t`Ads` }
          </SidebarLink>
        )}
        {canRead('leads') && (
          <SidebarLink to="/leads">
            <IconPosition /> { t`Leads` }
          </SidebarLink>
        )}
        {canRead('users') && (
          <SidebarLink to="/users">
            <span className="custom-icon">
              <img
                src={ getImageUrl(usersImage) }
                alt={ t`Users` }
              />
            </span> { t`Users` }
          </SidebarLink>
        )}
        {/* Hidden for MVP */}
        {/*<SidebarLink>*/}
        {/*  <IconGroup /> { t`Organization` }*/}
        {/*</SidebarLink>*/}
      </LinksGroup>

      {/* Hidden for MVP */}
      {/*<LinksGroup>*/}
      {/*  <SidebarLink>*/}
      {/*    <IconTools /> { t`Settings` }*/}
      {/*  </SidebarLink>*/}
      {/*</LinksGroup>*/}
    </SidebarBase>
  )
}

export default styled(Sidebar)`
  .custom-icon {
    margin-right: .5rem;

    img {
      width: 1rem;
      height: 1rem;
    }
  }
`

import React from 'react'
import { t } from 'ttag'
import styled from 'styled-components'
import { oneOf, bool } from 'prop-types'

import { typesTranslations } from 'shared/constants/mimeTypes'

const EmptyAsset = ({ mediaType, clickable }) => {
  const mediaTypeTitle = typesTranslations[mediaType]
  const actionTitle = clickable ? t`Click or drag` : t`Drag`

  return (
    <EmptyAssetContainer clickable={ clickable }>
      <span className="icon-empty-image" />
      <span>{ actionTitle } { mediaTypeTitle } { t`here` }</span>
    </EmptyAssetContainer>
  )
}

EmptyAsset.propTypes = {
  mediaType: oneOf(Object.keys(typesTranslations)).isRequired,
  clickable: bool
}

EmptyAsset.defaultProps = {
  clickable: false
}

export const EmptyAssetContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: .65rem;
  height: 7rem;
  padding: 1.5rem 0;
  flex-direction: column;
  width: 10rem;

  cursor: ${({ clickable }) => clickable ? 'pointer' : 'default'};

  [class^=icon] {
    font-size: 2rem;
    margin-bottom: .5rem
  }
`

export default EmptyAsset

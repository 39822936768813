import React, { useState }   from 'react'
import { t }                 from 'ttag'
import { bool, func, array } from 'prop-types'

import toastError     from 'shared/toastError'
import BaseEmptyAsset from 'shared/components/ui/EmptyAsset'
import mimeTypes      from 'shared/constants/mimeTypes'

const areFilesAllowed = (files, mediaTypes) => {
  return [...files].every((file) => {
    const allowedMimeTypes = mediaTypes.map((mediaType) => {
      return mimeTypes[mediaType]
    }).flat()
    if(!allowedMimeTypes) return true

    return allowedMimeTypes.includes(file.type)
  })
}

const EmptyAssets = ({ mediaTypes, isUploading, onDrop, ...props }) => {
  const [isOver, setIsOver] = useState(false)

  const handleDragOver = (e) => {
    e.preventDefault()

    setIsOver(true)
  }

  const handleDrop  = (e) => {
    e.preventDefault()

    if (areFilesAllowed(e.dataTransfer.files, mediaTypes)) {
      onDrop({ target: e.dataTransfer })
    } else {
      toastError(t`Wrong file type`)
    }

    setIsOver(false)
  }

  const handleDragLeave = () => {
    setIsOver(false)
  }

  const title = (
    <span>
      { isUploading ? (
        t`Uploading...`
      ) : (
        `${ t`Click or drag files here` }`
      )}
    </span>
  )

  return (
    <div
      onDragOver={ handleDragOver }
      onDragLeave={ handleDragLeave }
      onDrop={ handleDrop }
    >
      <BaseEmptyAsset
        title={ title }
        isOver={ isOver }
        { ...props }
      />
    </div>
  )
}

EmptyAssets.propTypes = {
  mediaTypes: array.isRequired,
  isUploading: bool,
  onDrop: func
}

EmptyAssets.defaultProps = {
  isUploading: false,
  onDrop: () => {}
}

export default EmptyAssets

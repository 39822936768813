import { gql } from 'apollo-boost'

export default gql`
  query ProjectLeads ($projectUuid: ID!) {
    projectLeads(projectUuid: $projectUuid) {
      uuid
      fullName
      phoneNumber
      email
      createdAt
      stageId
    }
  }
`

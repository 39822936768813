import { useContext }  from 'react'
import { useMutation } from 'react-apollo'

import removeMutation            from 'graphql/mutations/showings/remove'
import projectFragment           from 'graphql/fragments/project'
import { buildFragmentParams }   from 'shared/helpers/graphql'
import { ProjectSummaryContext } from 'shared/components/ProjectSummary/index'

export default function useDeleteButton(uuid) {
  const { projectUuid } = useContext(ProjectSummaryContext)

  const [removeShowing, { loading }] = useMutation(removeMutation, {
    variables: { uuid },
    update: (cache, { data }) => {
      if (!data.removeShowing) {
        return
      }

      const object = { __typename: 'Project', uuid: projectUuid }
      const fragmentParams = buildFragmentParams(object, projectFragment)
      const fragmentData = cache.readFragment(fragmentParams)

      cache.writeFragment({
        ...fragmentParams,
        data: {
          ...fragmentData,
          showings: fragmentData.showings.filter((showing) => showing.uuid !== uuid)
        }
      })
    }
  })

  return {
    removeShowing,
    loading
  }
}

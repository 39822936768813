import prepareLeadsQuery from './prepareLeadsQuery'

const leadFieldsWithProject = `
  ... Lead,
  project {
    name
    uuid
  }
`

export default prepareLeadsQuery(leadFieldsWithProject)

import styled from 'styled-components'

import { buttonTextColor } from 'shared/style/colors'

export default styled.label`
  color: ${buttonTextColor};
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  margin: 0.875rem 0 0.625rem
`

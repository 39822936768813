import React from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'
import Select from 'react-select'
import { object, func, bool, array } from 'prop-types'

import { buttonTextColor } from 'shared/style/colors'
import CompanySelect       from 'shared/components/CompanySelect'

import Logo         from './Logo'
import DeleteButton from './DeleteButton'

const categories = [
  { label: t`Developer`, value: 0 },
  { label: t`Agent`, value: 1 },
  { label: t`Marketing`, value: 2 },
  { label: t`Broker`, value: 3 }
]

const Company = ({
  className,
  showLogo,
  isAdmin,
  company,
  selectedCompaniesUuids,
  updateCompanyUuid,
  updateCompanyCategory,
  deleteCompany
}) => {

  const renderTitle = (company) => {
    if (company.main) return t`Main organization`
    return t`Organization`
  }

  const selectedCategory = categories.find((element) => {
    return element.value === company.category
  })

  const onCompanyChange = (value) => {
    updateCompanyUuid(company, value)
  }

  const groupClassName = showLogo ? 'group-3' : 'group-2'
  const companyLogoClassName = company.main ? 'company-logo-main' : 'company-logo-secondary'

  const renderCompanyForAdmin = () => {
    return (
      <Group>
        <label>{ renderTitle(company) }</label>
        <div className={ groupClassName }>
          <CompanySelect
            name="companyUuid"
            value={ company.uuid }
            onChange={ onCompanyChange }
            selectedCompaniesUuids={ selectedCompaniesUuids }
          />

          <Select
            placeholder={ t`Category` }
            value={ selectedCategory }
            onChange={ (value) => updateCompanyCategory(company, value) }
            options={ categories }
          />

          { showLogo && (
            <div className={ companyLogoClassName }>
              <Logo src={ company.logo } label="test" />
            </div>
          )}

          { !company.main && (
            <DeleteButton
              onDelete={ () => deleteCompany(company) }
            />
          )}
        </div>
      </Group>
    )
  }

  const renderCompanyForUser = () => {
    return (
      <Group>
        <label>{ renderTitle(company) }</label>
        <div className={ groupClassName }>
          { company.name } ({ selectedCategory.label })
        </div>
      </Group>
    )
  }

  const renderCompany = () => {
    if (isAdmin) return renderCompanyForAdmin()
    return renderCompanyForUser()
  }

  return (
    <div className={ className }>
      { renderCompany() }
    </div>
  )
}

const Group = styled.div`
  input {
    box-shadow: none;
  }

  label {
    color: ${buttonTextColor};
    font-size: 0.875rem;
  }

  &.map-container {
    width: 400px;
  }

  div[class*="group-"] {
    display: flex;
  }

  .group-2 {
    > div {
      &:nth-of-type(1) {
        width: 60%;
        margin-right: .5rem;
      }

      &:nth-of-type(2) {
        width: 40%;
        margin-left: .5rem;
      }
    }
  }

  .group-3 {
    align-items: flex-start;

    div[class^="company-logo-"] {
      display: flex;
      justify-content: center;
    }

    .company-logo-main {
      margin-right: calc(2rem + 14px)
    }

    > div {
      :not(&:nth-of-type(1)) {
        margin-left: 1.5rem;
      }

      &:nth-of-type(1) {
        width: 50%;
        height: 2rem;
      }

      &:nth-of-type(2) {
        width: 35%;
        height: 2rem;
      }

      &:nth-of-type(3) {
        width: 15%;
      }
    }
  }
`

Company.propTypes = {
  company: object.isRequired,
  deleteCompany: func.isRequired,
  selectedCompaniesUuids: array.isRequired,
  updateCompanyCategory: func.isRequired,
  updateCompanyUuid: func.isRequired,
  isAdmin: bool,
  showLogo: bool
}

Company.defaultProps = {
  isAdmin: false,
  showLogo: false
}

export default Company

import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { t }  from 'ttag'

import PrimaryButton from 'shared/components/ui/PrimaryButton'
import PlusIcon      from 'shared/components/ui/icons/PlusIcon'

const AddNewButton = forwardRef((props, ref) => {
  return (
    <PrimaryButton { ...props } ref={ ref } data-test-id='add-new-creative-set-button' >
      <PlusIcon />
      { t`Add new creative set` }
    </PrimaryButton>
  )
})

AddNewButton.displayName = 'AddNewButton'

export default styled(AddNewButton)`
  align-items: center
  display: flex
  margin-left: auto
  padding: .25rem .5rem

  &:after {
    display: none
  }
  
  svg {
    margin-right: .5rem
  }
  
  svg path.circle-and-plus {
    fill: #FFF
  }
`

import React            from 'react'
import { t }            from 'ttag'
import { get }          from 'lodash'
import { useLazyQuery } from '@apollo/react-hooks'
import { Modal }        from 'react-bootstrap'
import { bool, func }   from 'prop-types'

import channelType from 'shared/types/channelType'
import SearchField from 'shared/components/ui/Search'

import audiencesQuery from './audiencesQuery'
import List           from './List'

const Search = ({ channel, show, onHide }) => {
  const [fetchAudiences, { data, loading }] = useLazyQuery(audiencesQuery)
  const audiences = get(data, 'audiences', [])

  const handleOnSearch = (search) => {
    fetchAudiences({ variables: { channelUuid: channel.uuid, search: search } })
  }

  const handleOnShow = () => {
    handleOnSearch('')
  }

  return (
    <Modal show={ show } onShow={ handleOnShow } onHide={ onHide }>
      <Modal.Header closeButton>
        <Modal.Title>
          { t`Search audience to reuse it here` }
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <SearchField onSearch={ search => { handleOnSearch(search) } } />

        <List
          channel={ channel }
          audiences={ audiences }
          loading={ loading }
        />
      </Modal.Body>
    </Modal>
  )
}

Search.propTypes = {
  channel: channelType.isRequired,
  onHide: func.isRequired,
  show: bool.isRequired
}

export default Search

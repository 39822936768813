import React from 'react'
import { get } from 'lodash'
import { string } from 'prop-types'
import styled from 'styled-components'
import { ngettext, msgid } from 'ttag'

import stageType from 'shared/types/stageType'
import Card from 'shared/components/ui/CardDeck/Item'
import CampaignCount from 'shared/components/CampaignCount'
import { projectMissingDataPresentation }
  from 'shared/components/Indicators/MissingData/Presentations'

const StageCard = ({ stage, className }) => {
  const { unitsCount, activeCampaignsCount } = stage
  const missingData = {
    data: stage.missingData,
    presentation: projectMissingDataPresentation
  }

  return (
    <Card
      className={ className }
      thumbnail={ get(stage, 'image.thumbnail') }
      header={ stage.name }
      subheader={ stage.projectName }
      missingData={ missingData }
    >
      <div className='stages-info'>
        <span className='units-count'>
          {
            ngettext(
              msgid`${unitsCount} Unit`,
              `${unitsCount} Units`,
              unitsCount
            )
          }
        </span>
      </div>

      <CampaignCount count={ activeCampaignsCount } />
    </Card>
  )
}

StageCard.propTypes = {
  className: string.isRequired,
  stage: stageType.isRequired
}

export default styled(StageCard)`
  .stages-info {
    padding-top: 0.75rem
    font-size: 0.875rem
  }
`

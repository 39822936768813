import { gql } from 'apollo-boost'

export default gql`
  mutation updateFacilityDetail(
    $detailUuid: ID!
    $description: String!
  ) {
    updateFacilityDetail(
      detailUuid: $detailUuid
      description: $description
    ) {
      detail {
        uuid
        title
        detailUuid
        description
      }
    }
  }
`

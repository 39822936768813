import updateAssetAssignmentMutation from 'graphql/mutations/assetable/updateAssetAssignment'

const useUpdateAssetAssignment = () => {
  return (client, asset) => {
    const uuid = asset.assetAssignmentUuid
    const portalEnabledDraft = !asset.portalEnabledDraft

    client.mutate({
      mutation: updateAssetAssignmentMutation,
      variables: { uuid, input: { portalEnabledDraft } }
    })
  }
}

export default useUpdateAssetAssignment

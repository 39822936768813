import React, { useEffect }  from 'react'
import { t }  from 'ttag'
import styled from 'styled-components'
import { object, string, bool, number } from 'prop-types'
import Chart from 'chart.js'

import { formatNumberWithLocale } from 'shared/helpers/number'

const StagesDetailsInfoBlock = ({ className, border, rooms, title, data, index }) => {
  const elementsCount = data.total.amount

  useEffect(() => {
    if (elementsCount === 0) return undefined

    const graphData = {
      datasets: [{
        data: Object.values(data.elements).map(element => (100 * element / elementsCount)),
        backgroundColor: ['#1bb481', '#105559', '#a6dfd2', '#138487', '#126b70'],
        borderColor: ['#1bb481', '#105559', '#a6dfd2', '#138487', '#126b70']
      }]
    }

    new Chart(document.getElementById(`doughnut-pie-${index}`), {
      type: 'doughnut',
      data: graphData,
      options: {
        cutoutPercentage: 80,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        }
      }
    })
  }, [data])

  const renderGraph = () => {
    if (elementsCount === 0) return undefined

    return <canvas id={ `doughnut-pie-${index}` } width={ '131' } height={ '131' } />
  }

  const translateKey = (key) => {
    switch (key) {
    case 'sold':
      return t`Sold`
    case 'reserved':
      return t`Reserved`
    case 'available':
      return t`Available`
    default:
      return key
    }
  }

  const findTopPositionbyMiddleAngle = (middleAngle) => {
    // geometry calculations for getting top position for legend block, based on angle
    // finding a value is a solution to a quadratic equation
    // y = a * x2 - 20
    if (middleAngle < 90) return 0.008642 * Math.pow(middleAngle, 2) - 20
    // y = a * x2 - b * x + 300
    if (middleAngle > 270) return 0.00247 * Math.pow(middleAngle, 2) - 1.7784 * middleAngle + 300
    // y = -a * x2 + b * x - 160
    return - 0.008642 * Math.pow(middleAngle, 2) + 3.11112 * middleAngle - 160
  }

  const renderLegend = () => {
    if (elementsCount > 0) {
      let summaryAngle = 0
      let middleAngle
      let top
      let lastTop = null
      let firstLeftValuePassed = false

      return Object.entries(data.elements).map(([key, value], index) => {
        const elementPercent = 100 * value / elementsCount
        const elementAngle = value / elementsCount * 360
        middleAngle = summaryAngle + elementAngle / 2
        summaryAngle += elementAngle

        const horizontalClass = middleAngle <= 180 ? 'right' : 'left'

        top = findTopPositionbyMiddleAngle(middleAngle)

        if (middleAngle <= 180) {
          if (lastTop && lastTop > top) top = lastTop
          else lastTop = top
          lastTop = lastTop + 30
        } else {
          if (firstLeftValuePassed === false) {
            lastTop = top
            firstLeftValuePassed = true
          }
          if (lastTop && lastTop < top) top = lastTop
          else lastTop = top
          lastTop = lastTop - 30
        }

        return (
          <div
            style={{ top: `${top}px` }}
            className={ `legend-block ${horizontalClass}` }
            key={ index /* eslint-disable-line react/no-array-index-key */ }
          >
            <div className='legend-title'>
              { translateKey(key) }{ rooms ? t` Rooms` : null }
            </div>
            <div className='legend-value'>
              <span>{ value }</span> <span>({ Math.round(elementPercent * 100) / 100 }%)</span>
            </div>
          </div>
        )
      })
    } else {
      return undefined
    }
  }

  return (
    <div
      className={ `${className} ${border ? 'border-right' : ''}` }
      data-test-id={ `sales-details-${index}` }
    >
      <div className={ `title ${ title.length > 27 ? 'long' : '' }` }>{ title }</div>
      <div className='graph'>
        { renderGraph() }
        { renderLegend() }
        <div className='graph-total-data'>
          <p className='graph-total-data-amount'>{ data.total.amount }</p>
          <p className='graph-total-data-value'>
            { data.total.percent ? `${data.total.percent}%` : t`Units` }
          </p>
        </div>
      </div>
      <div className='bottom'>
        <p>{ t`Sales value` }</p>
        <p>
          <span>NOK</span>
          { formatNumberWithLocale(data.sales_value) }
        </p>
      </div>
      <div className='bottom'>
        <p>{ t`Avg price/m2` }</p>
        <p>
          <span>NOK</span>
          { formatNumberWithLocale(data.average_m2_price) }
        </p>
      </div>
    </div>
  )
}

StagesDetailsInfoBlock.propTypes = {
  data: object.isRequired,
  index: number.isRequired,
  title: string.isRequired,
  border: bool,
  rooms: bool
}

StagesDetailsInfoBlock.defaultProps = {
  border: undefined,
  rooms: undefined
}

export default styled(StagesDetailsInfoBlock)`
  width: 25%;
  padding: 0 1.125rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .title {
    text-align: center;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.2;
    color: #4a4a4a;
    margin-bottom: 2.1rem;
    width: 100%;
    white-space: normal;
    text-overflow: ellipsis;
    min-height: 30px;
    
      &.long {
         min-height: 60px;
         margin-bottom: 2.9rem;
      }
  }

  .graph {
    position: relative;
    width: 8.1875rem;
    height: 8.1875rem;
    margin: 0 auto 1rem;

    .legend-block {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 3rem;

      &.right {
        right: -3.25rem;
      }

      &.left {
        left: -3.25rem;
      }

      .legend-title {
        font-size: .5rem;
        color: #828282;
        margin: 0;
        text-align: center;
      }

      .legend-value {
        display: flex;
        margin: 0;
        text-align: center;

        span {
          color: #363636;

          &:nth-of-type(1) {
            font-size: .75rem;
            line-height: .75rem;
            font-weight: 600;
            margin-right: .25rem;
          }

          &:nth-of-type(2) {
            font-size: .5rem;
            line-height: .75rem;
          }
        }
      }
    }

    .graph-total-data {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .graph-total-data-amount {
        font-size: 2.25rem;
        line-height: 2.25rem;
        color: #4a4a4a;
        font-weight: 600;
        margin: 0;
      }

      .graph-total-data-value {
        font-size: .875rem;
        font-weight: 600;
        line-height: 1.75rem;
        color: #4a4a4a;
        margin: 0;
      }
    }
  }

  .bottom {
    p {
      margin: 0 0 .3125rem;
      text-align: center;

      &:nth-of-type(1) {
        font-size: .625rem;
        font-weight: 400;
        color: rgba(74, 74, 74, 0.65);
      }

      &:nth-of-type(2) {
        font-size: 1.1875rem;
        line-height: 1.875rem;
        font-weight: 500;
        color: #4a4a4a;

        span {
          font-size: 1rem;
          margin-right: .25rem;
          color: rgba(74, 74, 74, 0.65);
        }
      }
    }
  }
`

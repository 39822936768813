import { useMutation }      from 'react-apollo'
import { useFormikContext } from 'formik'

import updateBuildingMutation
  from 'shared/components/NewBuilding/BuildingDetails/Details/updateBuildingMutation'

export default function useDetailsForm(buildingUuid) {
  const formikContext = useFormikContext()
  const [mutate] = useMutation(updateBuildingMutation)

  const updateBuilding = (input, location) => (
    mutate({
      variables: {
        buildingUuid,
        input: input,
        location: location
      }
    })
  )

  const createFieldChangeHandler = (fieldName, onValidCallback) => (value) => {
    formikContext.setFieldValue(fieldName, value)
    formikContext.setFieldTouched(fieldName, true)
    formikContext.validateForm({ ...formikContext.values, [fieldName]: value }).then((errors) => {
      if (!errors[fieldName]) {
        onValidCallback(fieldName, value)
      }
    })
  }

  const onNameChange = createFieldChangeHandler('name', (fieldName, value) => {
    updateBuilding({ [fieldName]: value })
  })

  const onAddressChange = createFieldChangeHandler('address', (fieldName, value) => {
    updateBuilding({}, { [fieldName]: value })
  })

  const onCountryCodeChange = createFieldChangeHandler('countryCode', (fieldName, value) => {
    formikContext.setFieldTouched('zipCode', true)
    formikContext.setFieldTouched('location', true)
    updateBuilding({}, { [fieldName]: value })
  })

  const onZipCodeChange = createFieldChangeHandler('zipCode', (fieldName, value) => {
    formikContext.setFieldTouched('location', true)
    updateBuilding({}, { [fieldName]: value })
  })

  const onLocationChange = createFieldChangeHandler('location', (fieldName, value) => {
    updateBuilding({}, value)
  })

  const onBuildingTypesChange = createFieldChangeHandler('buildingTypes', (fieldName, value) => {
    updateBuilding({ [fieldName]: value })
  })

  return {
    ...formikContext,
    onNameChange,
    onAddressChange,
    onCountryCodeChange,
    onZipCodeChange,
    onLocationChange,
    onBuildingTypesChange
  }
}

import React  from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import Gmp from 'shared/assets/icons/svg/gmp.svg'

const GmpIcon = ({ className, content }) => (
  <>
    <Gmp className={ className } />
    { content }
  </>
)

GmpIcon.propTypes = {
  content: string
}

GmpIcon.defaultProps = {
  content: undefined
}

export default styled(GmpIcon)`
  height: 0.8rem
  margin-right: 0.4rem
`

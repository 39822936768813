import { useDrop } from 'react-dnd'

import { mediaTypeDropEvents } from 'shared/components/Media/mediaTypes'

export default function useAssetDrop({ assetAssignments, mediaType, onAssetDrop }) {
  const acceptedMediaTypes = [mediaTypeDropEvents[mediaType]]
  if (mediaType === 'banner')
    acceptedMediaTypes.push(mediaTypeDropEvents['image'])

  return useDrop({
    accept: acceptedMediaTypes,
    drop: (e) => {
      onAssetDrop(e.asset, mediaType)
    },
    canDrop: ({ asset }) => {
      const existingAsset = assetAssignments.find(({ asset: { uuid } }) => asset.uuid === uuid)

      return !existingAsset
    },
    collect: (monitor) => ({
      isOver: monitor.canDrop() && monitor.isOver(),
    }),
  })
}

import React from 'react'
import { string, object } from 'prop-types'
import styled from 'styled-components'

const LinkButton = ({ href, children, className, dataTestId, target  }) => {
  return (
    <a className={ `btn ${className}` } href={ href } data-test-id={ dataTestId } target={ target }>
      { children }
    </a>
  )
}

LinkButton.propTypes = {
  href: string.isRequired,
  children: object,
  dataTestId: string,
  target: string
}

LinkButton.defaultProps = {
  children: {},
  dataTestId: '',
  target: null
}

export default styled(LinkButton)`
  border: 1px solid red
  margin-left: .625rem
  color: #697386
  box-shadow: 0rem 0.125rem 0.438rem -0.188rem #9c9c9c
  border-radius: 3px
  border: 1px solid #ced4da
  background-color: #ffffff
`

import React from 'react'
import axios from 'axios'
import { string } from 'prop-types'
import { t } from 'ttag'
import { saveAs } from 'file-saver'

import { getToken } from '../../helpers/auth'

const ExportLeadsButton = ({ projectUuid, projectName }) => {
  const handleExportLeads = (e) => {
    e.preventDefault()

    axios.get(`/projects/${projectUuid}/leads.xlsx`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      responseType: 'blob'
    }).then((response) => {
      const file = new Blob(
        [response.data],
        { type: response.headers['content-type'] }
      )
      
      if (file) saveAs(file , `Leads_${projectName}_${new Date().toISOString()}.xlsx`)
    })
  }

  return (
    <a href={ '' } onClick={ (e) => handleExportLeads(e) }>
      { t`Export leads` }
    </a>
  )
}

ExportLeadsButton.propTypes = {
  projectName: string.isRequired,
  projectUuid: string.isRequired
}

export default ExportLeadsButton

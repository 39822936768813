import React from 'react'
import { arrayOf, shape, node, string } from 'prop-types'

import Item from 'shared/components/ui/LabeledList/Item'

const LabeledList = ({ items }) => {
  return (
    <div>
      { items.map((item, i) => (
        <Item
          key={ i /* eslint-disable-line react/no-array-index-key */ }
          labelVariant={ item.labelVariant }
          labelText={ item.labelText }
          content={ item.content }
        />
      )) }
    </div>
  )
}

LabeledList.propTypes = {
  items: arrayOf(shape({
    labelVariant: string,
    labelText: string,
    content: node
  })).isRequired
}

export default LabeledList

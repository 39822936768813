import React, { useState } from 'react'
import { t } from 'ttag'
import { useApolloClient } from '@apollo/react-hooks'

import IconTakeNote from 'shared/assets/icons/svg/new/leadTakeNote.svg'
import IconAddActivity from 'shared/assets/icons/svg/new/leadAddActivity.svg'
import leadType from 'shared/types/leadType'

import addNote from './addNote'
import addActivity from './addActivity'
import NewActivityForm from './NewActivityForm'
import {
  Container,
  TabBar,
  Tab,
  SaveButton
} from './elements'

const modes = [
  { value: 'notes', label: t`Take notes`, Icon: IconTakeNote },
  { value: 'activity', label: t`Add activity`, Icon: IconAddActivity }
]

const Notes = ({ lead }) => {
  const client = useApolloClient()

  const [mode, setMode] = useState(modes[0].value)
  const [note, setNote] = useState({ title: '', text: '' })

  const handleSaveNote = async ({ target }) => {
    await addNote(client, lead, note)

    target.blur()

    setNote({ title: '', text: '' })
  }

  const handleSaveActivity = async (input, { resetForm }) => {
    await addActivity(client, lead, input)
    resetForm()
  }

  return (
    <Container>
      <TabBar>
        {modes.map(item => (
          <Tab
            key={ item.value }
            active={ item.value === mode }
            onClick={ () => setMode(item.value) }
            data-test-id={ `lead-tab-${item.value}` }
          >
            <span><item.Icon /></span>
            <span>{item.label}</span>
          </Tab>
        ))}
      </TabBar>

      { mode === 'notes' ? (
        <>
          <textarea
            value={ note.text }
            onChange={ e => setNote({ ...note, text: e.target.value }) }
            rows={ 5 }
            data-test-id='lead-note-textarea'
          />

          <SaveButton
            onClick={ handleSaveNote }
            data-test-id='lead-note-save-button'>
            { t`Save` }
          </SaveButton>
        </>
      ) : (
        <NewActivityForm lead={ lead } onSubmit={ handleSaveActivity } />
      )}
    </Container>
  )
}

Notes.propTypes = {
  lead: leadType.isRequired
}

export default Notes

import { gql } from 'apollo-boost'

import user from 'graphql/fragments/user'

export default gql`
  query Users($query: PageInput!, $companyUuid: ID) {
    users(query: $query, companyUuid: $companyUuid) {
      total
      items {
        ... User
      }
    }
  }
  ${user}
`

import { useQuery } from '@apollo/react-hooks'

import dashboardAnalyticsLeadsStatsQuery from 'graphql/queries/dashboardAnalytics/leadsStats'

export default function useLeadsStatsPerPeriod(durationValue, durationType, projectUuid) {
  const {
    data: {
      dashboardAnalyticsLeadsStats: {
        leadsStats = {}
      } = {}
    } = {},
    loading
  } = useQuery(dashboardAnalyticsLeadsStatsQuery, {
    variables: {
      durationType:  durationType,
      durationValue: durationValue,
      projectUuid:   projectUuid
    }
  })

  return {
    leadsStats,
    loading
  }
}

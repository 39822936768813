import React, { useState, useCallback } from 'react'
import { shape, arrayOf, array, func } from 'prop-types'
import { withApollo } from 'react-apollo'
import styled from 'styled-components'
import { t } from 'ttag'

import { combineErrors } from 'shared/helpers/inputValidation'
import InputWrapper from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import SubHeader from 'shared/components/ui/Card/SubHeader'
import apolloClientType from 'shared/types/apolloClientType'
import audienceLocationType from 'shared/types/audienceLocationType'
import createGmpAudienceLocationMutation
  from 'graphql/mutations/campaign/targeting/audienceLocations/createGmp'
import updateAudienceLocationMutation
  from 'graphql/mutations/campaign/targeting/audienceLocations/updateAudienceLocationMutation'
import removeAudienceLocationMutation
  from 'graphql/mutations/campaign/targeting/audienceLocations/removeAudienceLocationMutation'
import buildAudiencesValidationPayload
  from 'shared/components/CampaignInfo/Targeting/buildAudiencesValidationPayload'

import Map from './Map'
import List from './List'
import AddLocationForm from './AddLocationForm'

const DEFAULT_OPERATOR = 'include'

const Location = ({ client, audience, className, errors, validate }) => {
  const [currentOperator, setCurrentOperator] = useState(DEFAULT_OPERATOR)
  const [locations, setLocations] = useState(audience.locations)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [highlightedLocation, setHighlightedLocation] = useState(null)

  const errorsOnLocation = combineErrors({
    errors,
    uuid: audience.uuid,
    field: 'includedLocations'
  })

  const validateCampaign = (campaign) => {
    if(validate) {
      const payload = buildAudiencesValidationPayload(campaign).map((subPayload) => ({
        ...subPayload,
        campaign
      }))

      validate({ payload: payload })
    }
  }

  const onLocationCreate = (createdLocation) => {
    setLocations([
      ...locations,
      createdLocation
    ])
    setHighlightedLocation(createdLocation)
  }

  const handleGmpLocationCreate = useCallback(
    (audienceLocation) => {
      return client.mutate({
        mutation: createGmpAudienceLocationMutation,
        variables: {
          audienceUuid: audience.uuid,
          gmpId: audienceLocation.key,
          operator: audienceLocation.operator
        }
      }).then(({ data }) => {
        validateCampaign(data.createGmpAudienceLocation.campaign)
        return data
      }).then((data) => {
        onLocationCreate(data.createGmpAudienceLocation.audienceLocation)
      })
    },
    [locations]
  )

  const handleLocationRemove = useCallback(
    (audienceLocation) => {
      return client.mutate({
        mutation: removeAudienceLocationMutation,
        variables: {
          uuid: audienceLocation.uuid
        }
      }).then(({ data }) => {
        validateCampaign(data.removeAudienceLocation.campaign)
      }).then(() => {
        setLocations(
          locations.filter((location) => audienceLocation.uuid !== location.uuid)
        )
      })
    },
    [locations]
  )

  const handleLocationChange = useCallback(
    (audienceLocation) => (
      client.mutate({
        mutation: updateAudienceLocationMutation,
        variables: {
          uuid: audienceLocation.uuid,
          latitude: audienceLocation.latitude,
          longitude: audienceLocation.longitude,
          operator: audienceLocation.operator,
          radius: audienceLocation.radius
        }
      }).then(({ data }) => {
        validateCampaign(data.updateAudienceLocation.campaign)
      }).then(() => {
        setLocations(
          locations.map(location => (
            location.uuid === audienceLocation.uuid ? audienceLocation : location
          ))
        )
      })
    ),
    [locations]
  )

  const handleLocationSelect = useCallback(
    (selectedLocation) => setSelectedLocation(selectedLocation)
  )

  const handleLocationHighlight = useCallback(
    (location) => setHighlightedLocation(location)
  )

  return (
    <div className={ `${className} mb-4` }>
      <SubHeader>
        { t`Location` }
      </SubHeader>

      <List
        locations={ locations }
        onRemove={ handleLocationRemove }
        onLocationChange={ handleLocationChange }
        onItemClick={ handleLocationSelect }
        onHighlight={ handleLocationHighlight }
        highlightedLocation={ highlightedLocation }
      />

      <AddLocationForm
        currentLocations={ locations }
        currentOperator={ currentOperator }
        setCurrentOperator={ setCurrentOperator }
        onSubmit={ handleGmpLocationCreate }
      />

      <InputWrapper error={ errorsOnLocation }>
        <Map
          audience={ audience }
          locations={ locations }
          currentOperator={ currentOperator }
          selectedLocation={ selectedLocation }
          highlightedLocation={ highlightedLocation }
          onMarkerClick={ handleLocationHighlight }
        />
      </InputWrapper>
    </div>
  )
}

Location.propTypes = {
  audience: shape({
    locations: arrayOf(audienceLocationType).isRequired
  }).isRequired,
  client: apolloClientType.isRequired,
  errors: array,
  validate: func
}

Location.defaultProps = {
  errors: [],
  validate: undefined
}

export default styled(withApollo(Location))`
  .search-input-wrapper {
    width: 100%
    margin-left: 0.25rem
  }

  .mapboxgl-ctrl-geocoder {
    width: 100%
    max-width: 100%
    border-color: hsl(0,0%,80%);
    border-radius: 0.25rem
    border-style: solid
    border-width: 0.063rem
  }
`

import { useMutation } from 'react-apollo'

import toggleLockUserMutation from 'graphql/mutations/users/toggleLockUser'

export default function useToggleLockUser() {
  const [toggleLockUser, variables] = useMutation(toggleLockUserMutation)

  return {
    toggleLockUser,
    ...variables
  }
}

import { gql } from 'apollo-boost'

import gmpPlatformAudience from 'graphql/fragments/gmpPlatformAudience'
import campaign from 'graphql/fragments/campaign'

export default gql`
  mutation UpdateGmpAudience(
    $uuid: ID!
    $input: GmpAudienceInput!
  ) {
    updateGmpAudience(
      uuid: $uuid
      input: $input
    ) {
      campaign {
        ... Campaign
      }
      audience {
        platformAudience {
          ... GmpPlatformAudience
        }
      }
    }
  }
  ${gmpPlatformAudience}
  ${campaign}
`

import React      from 'react'
import { t }      from 'ttag'
import { string } from 'prop-types'
import styled     from 'styled-components'

import like        from 'shared/assets/icons/like.png'
import globe       from 'shared/assets/icons/globe.png'
import getImageUrl from 'shared/helpers/getImageUrl'
import { greyishBrownColor, textColorLight } from 'shared/style/colors'

const AdHeader = ({ content, logo, facebookPageName, className }) => {
  return (
    <div className={ className }>
      <div className="header">
        <img
          className="logo"
          src={ getImageUrl(logo) }
        />
        <div className="name-data">
          <div className="company-name">
            { facebookPageName }
          </div>
          <div className="sponsored">
            Sponsored
            <img
              alt={ t`facebook 'sponsored' icon` }
              src={ getImageUrl(globe) }
              className="globe"
            />
          </div>
        </div>
        <img
          alt={ t`facebook 'like' icon` }
          className="like"
          src={ getImageUrl(like) }
        />
      </div>
      <p className="body">
        { content }
      </p>
    </div>
  )
}

AdHeader.propTypes = {
  content: string,
  facebookPageName: string,
  logo: string
}

AdHeader.defaultProps = {
  content: '',
  facebookPageName: '',
  logo: ''
}

export default styled(AdHeader)`
  padding: 0 .5rem;
  border-radius: 5px

  .header {
    display: flex;
    align-items: flex-start;
  }

  .name-data {
    flex: 1;
  }

  .logo {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.625rem
  }

  .company-name {
    color: ${greyishBrownColor}
    font-size: 0.8125rem;
    font-family: Helvetica;
    font-weight: bold;
    padding-top: .25rem
  }

  .sponsored {
    align-items: center;
    color: ${textColorLight};
    display: flex;
    font-size: 0.6875rem;
    font-family: Helvetica;
    margin-top: -0.25rem
  }

  .globe {
    margin-left: .25rem
  }

  .body {
    clear: both;
    color: #000000;
    font-size: 0.6875rem;
    margin: .75rem 0 .9rem
  }
`

import React             from 'react'
import { shape, string } from 'prop-types'

import Card from 'shared/components/ui/Card'

import Header      from './Header'
import ImagesTable from './ImagesTable'

const UserImages = ({ user }) => {
  return (
    <Card className="mt-3" header={ <Header /> }>
      <ImagesTable avatars={ user.avatars }/>
    </Card>
  )
}

UserImages.propTypes = {
  user: shape({
    uuid: string.isRequired
  }).isRequired
}

export default UserImages

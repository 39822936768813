import { gql } from 'apollo-boost'

export default gql`
  query ProjectAnalyticsTotal($projectUuid: ID!) {
    projectAnalyticsTotal(projectUuid: $projectUuid) {
      totalImpressions
      totalVisitors
      totalLeads
      averageCpc
      averageCtr
      averageCpl
      averageArt
      averageLqs
      goalLeads
      totalSpent
    }
  }
`

import { gql } from 'apollo-boost'

import stageUnit from 'graphql/fragments/stageUnit'

export default gql`
  mutation deleteStageUnit(
    $uuid: ID!
  ) {
    deleteStageUnit(
      uuid: $uuid
    ) {
      unit {
        ... StageUnit
      }
    }
  }
  ${stageUnit}
`

import * as Yup from 'yup'
import { t }    from 'ttag'

import locationValidationSchema from 'shared/components/Location/locationValidationSchema'

import stakeholdersValidationSchema from './stakeholdersValidationSchema'

export default Yup.object().shape({
  name: Yup.string().required(t`Please fill a name.`),
  location: locationValidationSchema,
  stakeholders: stakeholdersValidationSchema
})

import React, { createRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useApolloClient } from 'react-apollo'
import { toast } from 'react-toastify'
import { t } from 'ttag'
import { FormLabel } from 'react-bootstrap'

import EmptyAsset from 'shared/components/MediaList/EmptyAsset'
import mimeTypes from 'shared/constants/mimeTypes'
import readBlobAsDataUrl from 'shared/helpers/readBlobAsDataUrl'
import ImageEditor from 'shared/components/ui/ImageEditor'
// eslint-disable-next-line max-len
import uploadImage from 'shared/components/ProjectPortalEdit/EditForm/ProjectImage/Uploader/uploadImage'
import { NonMemoAsset } from 'shared/components/ui/Asset'
import useMediaData from 'shared/components/Project/Regular/Media/useMediaData'
import projectAssetAssignments from 'graphql/queries/projectAssetAssignments'

const ProjectLogo = ({ project, className, mediaType, label }) => {
  const [assetAssignments, setAssetAssignments] = useState(project.assetAssignments)
  const findAssetAssignment = (assetAssignments) => {
    return assetAssignments
      .sort((x,y) => x.portalPositionDraft - y.portalPositionDraft)
      .find(({ mediaType: type }) => { return type === mediaType })
  }

  const refetchQueries = [
    {
      query: projectAssetAssignments,
      variables: { uuid: project.uuid }
    }
  ]

  const [assetAssignment, setAssetAssignment] = useState(() => {
    return findAssetAssignment(assetAssignments)
  })
  const { removeAssetAssignment } = useMediaData({
    projectUuid: project.uuid,
    assetAssignments: assetAssignments,
    refetchQueries: { removeAssetAssignment: refetchQueries }
  })

  const handleRemovingAssetAssignment = (removedAsset, confirmRemoval = false) => {
    removeAssetAssignment(removedAsset, confirmRemoval).then(() => {
      setAssetAssignment(null)
      setAssetAssignments([])
    })
  }

  const [isUploading, setIsUploading] = useState(false)
  const [fileDataURI, setFileDataURI] = useState(null)
  const [fileMeta, setFileMeta] = useState(null)
  const fileInput = createRef()
  const client = useApolloClient()

  const uploadCroppedImage = useCallback(() => {
    setIsUploading(true)
    uploadImage({ client, project, fileDataURI, fileMeta, mediaType, refetchQueries })
      .then(({ data: { attachFileToAssetable: { assetAssignment } } }) => {
        setAssetAssignment(assetAssignment)
        setAssetAssignments([assetAssignment])
      })
      .then(() => setFileDataURI(null))
      .then(() => toast.success(t`${ label } has been uploaded`))
      .then(() => setIsUploading(false))
  }, [fileDataURI])

  const setUploadingFiles  = useCallback((e) => {
    const file = e.target.files[0]

    setFileMeta({ name: file.name, type: file.type })

    if (!file) return

    readBlobAsDataUrl(file).then((fileDataURI) => {
      setFileDataURI(fileDataURI)
    })
  }, [])

  const openUploadWindow = () => {
    fileInput.current.click()
  }

  const guaranteeRef = (node) => {
    if (node) fileInput.current = node
    fileInput.current.value = ''
  }

  return (
    <div className={ className }>
      <div className="wrapper">
        { fileDataURI && (
          <ImageEditor
            fileDataURI={ fileDataURI }
            setFileDataURI={ setFileDataURI }
            onUpload={ uploadCroppedImage }
          />
        ) }
        <input
          type="file"
          accept={ mimeTypes[mediaType].join(',') }
          className="d-none"
          ref={ guaranteeRef }
          onChange={ setUploadingFiles }
          data-test-id='file-upload-input'
        />
        <div className="form-group">
          <FormLabel>{ label }</FormLabel>
        </div>
        {
          assetAssignment
            ? (
              <NonMemoAsset
                handleAssetRemoval={ handleRemovingAssetAssignment }
                onClick={ openUploadWindow }
                key={ assetAssignment.asset.media }
                asset={ assetAssignment.asset }
              />
            )
            : (
              <EmptyAsset
                isUploading={ isUploading }
                mediaType={ mediaType }
                onClick={ openUploadWindow }
                onDrop={ setUploadingFiles }
                uploadable={ t`a new project logo` }
              />
            )
        }
      </div>
    </div>
  )
}

ProjectLogo.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  project:  PropTypes.object.isRequired
}

export default ProjectLogo

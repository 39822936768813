import React from 'react'
import classNames from 'classnames'
import { bool, string } from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { t } from 'ttag'

import Header from 'shared/components/ui/Card/StaticHeader'
import projectOffersQuery from 'graphql/queries/projects/offers'

import Card from '../../ui/Card'
import Table from '../../ItemsList/Table'

import columns from './columns'

const Offers = ({ collapsed, className, projectUuid }) => {
  const { data } = useQuery(projectOffersQuery, {
    variables: { projectUuid }
  })

  const projectOffers = data ? data.projectOffers : []

  return (
    <Card
      collapsible
      collapsed={ collapsed }
      dataTestId="offers-list-panel"
      className={ classNames(className, 'mb-2') }
      header={ (
        <div className="d-flex justify-content-between">
          <Header className="align-self-center">
            { t`Offers` }
          </Header>
        </div>
      ) }
    >
      <Container>
        <Table
          tableOptions={{
            columns: columns,
            data: projectOffers,
            keyField: 'uuid',
            bordered: false
          }}
        />
      </Container>
    </Card>
  )
}

Offers.propTypes = {
  collapsed: bool.isRequired,
  projectUuid: string.isRequired
}

const Container = styled.div`
  margin-top: -1.1rem;
  margin-left: -1.45rem;
  margin-right: -1.45rem;
  margin-bottom: -3.2rem
`

export default Offers

import React                from 'react'
import styled               from 'styled-components'
import { t }                from 'ttag'
import { object, func }     from 'prop-types'

import Datepicker           from 'shared/components/ui/Datepickers/Datepicker'
import ProjectSelect        from 'shared/components/ProjectSelect'

const Filters = ({ filter, setFilter }) => {
  const handleFilterChange = (filterAttributes) => {
    setFilter((values) => ({
      ...values, ...filterAttributes,
    }))
  }

  return(
    <div data-test-id="dashboard-filters-wrapper" style={{ display: 'inherit' }}>
      <ProjectSelect
        isClearable
        defaultOptions
        placeholder={ t`Search Project` }
        selected={ filter.project_uuid }
        onChange={ value => handleFilterChange({ project_uuid: value }) } />
      <Datepicker
        showReset
        shouldCloseOnSelect
        placeholder={ t`From Date` }
        maxDate={ filter.end_date }
        placement="bottom"
        selected={ filter.start_date }
        showTimeInput={ false }
        onChange={ value => handleFilterChange({ start_date: value }) } />
      <Datepicker
        showReset
        shouldCloseOnSelect
        placeholder={ t`To Date` }
        minDate={ filter.start_date }
        placement="bottom"
        selected={ filter.end_date }
        showTimeInput={ false }
        onChange={ value => handleFilterChange({ end_date: value }) } />
    </div>
  )
}

Filters.propTypes = {
  filter: object,
  setFilter: func
}

Filters.defaultProps = {
  filter: {},
  setFilter: null
}

export default styled(Filters)`
  .form-control {
    box-shadow: none;
  }

  [data-test-id=project-select-wrapper] > div > div {
    min-height: 36px;
  }

  [data-test-id=project-select-wrapper] {
    font-size: .9rem;
    min-width: 200px;
  }

  h2 + div {
    div#dashboard-filters-wrapper {
      display: inherit !important;
    }
    display: inherit;
    font-size: 0.9rem
    .shared-datepicker {
      max-width: 120px;
      margin-left: 0.5rem;
    }
  }
`

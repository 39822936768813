import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { bool, shape, array, string } from 'prop-types'

import { mapLightRedColor, inheritColor } from 'shared/style/colors'
import CardHeader from 'shared/components/ui/Card/StaticHeader'

import AudienceName from './AudienceName'
import RemoveAudienceButton from './RemoveAudienceButton'

const Header = props => {
  const {
    className,
    audience,
    isCollapsible,
    opened,
    errors,
    audience: {
      platformAudience: { __typename: platformAudienceType }
    }
  } = props

  const getHeaderColor = () => {
    const audienceErrors = (errors || []).filter((error) => {
      return error.uuid === audience.uuid
    })

    return audienceErrors.length ? mapLightRedColor : inheritColor
  }

  const readOnly = !opened || !isCollapsible
  const isPortal = platformAudienceType === 'Portal'
  const additionalText
    = !isCollapsible && isPortal ? t`This audience has been generated automatically` : null

  return (
    <CardHeader
      className={ className }
      style={{ backgroundColor: getHeaderColor() }}
      data-test-id='audience-card-header'
    >
      <AudienceName
        audience={ audience }
        additionalText={ additionalText }
        readOnly={ readOnly }
        { ...props }
      />
      { opened && !readOnly && (
        <RemoveAudienceButton audience={ audience } />
      )}
    </CardHeader>
  )
}

Header.propTypes = {
  audience: shape({
    platformAudience: shape({
      __typename: string.isRequired
    }).isRequired
  }).isRequired,
  errors: array.isRequired,
  opened: bool.isRequired,
  isCollapsible: bool
}

Header.defaultProps = {
  isCollapsible: undefined
}

export default styled(Header)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 3rem 1rem 1.45rem;

  .audience-name {
    padding: 0
  }

  button {
    font-size: .875rem
  }

  .delete-button {
    height: 2rem
  }
`

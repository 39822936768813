import React  from 'react'
import styled from 'styled-components'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { string } from 'prop-types'

function popover(header) {
  return (
    <Popover>
      <Popover.Content>
        { header }
      </Popover.Content>
    </Popover>
  )
}

const HeaderMessage = ({ className, children }) => {
  return (
    <div className={ className }>
      <OverlayTrigger placement="right" overlay={ popover(children) }>
        <span>{ children }</span>
      </OverlayTrigger>
    </div>
  )
}

HeaderMessage.propTypes = {
  children: string.isRequired,
  className: string.isRequired
}

export default styled(HeaderMessage)`
  display: inline-block
`

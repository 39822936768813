import React from 'react'
import AsyncSelect from 'react-select/async'
import { useApolloClient } from 'react-apollo'
import { t } from 'ttag'
import { arrayOf, shape, string } from 'prop-types'

import MultiSelect from 'shared/components/ui/MultiSelect/MultiSelect'
import useCurrentCampaignUuid from 'shared/hooks/useCurrentCampaignUuid'
import query from 'graphql/queries/facebookDetailedTargeting'

import Option from './Option'

const styles = {
  container: provided => ({
    ...provided,
    width: '25rem'
  }),
  control: provided => ({
    ...provided,
    fontSize: '0.8rem'
  }),
  option: provided => ({
    ...provided,
    fontSize: '0.8rem'
  })
}

const typePresentation = {
  behaviors: t`Behaviors`,
  connections: t`Connections`,
  education_majors: t`Education majors`,
  education_schools: t`Education schools`,
  education_statuses: t`Education statuses`,
  ethnic_affinity: t`Ethnic affinity`,
  family_statuses: t`Family statuses`,
  friends_of_connections: t`Friends of connections`,
  generation: t`Generation`,
  home_ownership: t`Home ownership`,
  home_type: t`Home type`,
  home_value: t`Home value`,
  household_composition: t`Household composition`,
  income: t`Income`,
  industries: t`Industries`,
  interested_in: t`Interested in`,
  interests: t`Interests`,
  life_events: t`Life events`,
  moms: t`Moms`,
  net_worth: t`Net worth`,
  office_type: t`Office type`,
  politics: t`Politics`,
  relationship_statuses: t`Relationship statuses`,
  user_adclusters: t`User adclusters`,
  work_employers: t`Work employers`,
  work_positions: t`Work positions`
}

const valueToOption = el => ({
  ...el,
  label: el.name,
  value: el.id
})

const buildOptions = data => {
  const options = data.map(valueToOption)

  const groups = Array.from(new Set(options.map(el => el.type)))

  return groups.map(type => ({
    label: typePresentation[type],
    options: options.filter(el => el.type === type)
  }))
}

const DetailedTargetingSelect = ({ value: selectedValues, audience, ...props }) => {
  const campaignUuid = useCurrentCampaignUuid()
  const { uuid: audienceUuid } = audience
  const client = useApolloClient()

  const value = (selectedValues || []).map(valueToOption)

  const loadOptions = async term => {
    const {
      data: { facebookDetailedTargeting }
    } = await client.query({
      query: query,
      variables: { query: term, campaignUuid, audienceUuid }
    })

    return buildOptions(facebookDetailedTargeting)
  }

  return (
    <MultiSelect
      component={ AsyncSelect }
      loadOptions={ loadOptions }
      value={ value }
      styles={ styles }
      components={{ Option }}
      placeholder={ t`Add demographics, interest or behaviours` }
      noOptionsMessage={ () => t`No options` }
      { ...props }
    />
  )
}

DetailedTargetingSelect.propTypes = {
  audience: shape({
    uuid: string.isRequired,
  }).isRequired,
  value: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      type: string.isRequired
    })
  ),
}

DetailedTargetingSelect.defaultProps = {
  value: []
}

export default DetailedTargetingSelect

import React from 'react'
import { t } from 'ttag'
import { shape, bool, string } from 'prop-types'

import Checkbox from 'shared/components/ui/Checkbox'

import { ContactInfoContainer } from './elements'

const ContactTime = ({ contactInfo }) => {
  let otherTimeLabel = t`Other`
  if (contactInfo.contactTime) {
    otherTimeLabel += ` (${contactInfo.contactTime})`
  }

  return (
    <ContactInfoContainer style={{ maxWidth: '80%' }}>
      <label>{ t`Allowed contact time` }</label>

      <Checkbox
        disabled
        checked={ contactInfo.contactDuringTheDay }
        label={ t`Weekdays (09:00-16:00)` }
      />

      <Checkbox
        disabled
        checked={ contactInfo.contactDuringTheEvening }
        label={ t`Weekdays (after 16:00)` }
      />

      <Checkbox
        disabled
        checked={ contactInfo.contactOtherTime }
        label={ otherTimeLabel }
      />
    </ContactInfoContainer>
  )
}

ContactTime.propTypes = {
  contactInfo: shape({
    contactDuringTheDay: bool,
    contactDuringTheEvening: bool,
    contactOtherTime: bool,
    contactTime: string
  })
}

ContactTime.defaultProps = {
  contactInfo: {}
}

export default ContactTime

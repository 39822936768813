import { gql } from 'apollo-boost'

import project from 'graphql/fragments/project'
import asset from 'graphql/fragments/asset'

export default gql`
  mutation UpdateProject(
    $uuid: ID!
    $districtUuid: ID
    $input: ProjectInput!
    $location: LocationInput
    $companies: [CompanyAttributes!]
  ) {
    updateProject(
      uuid: $uuid
      districtUuid: $districtUuid
      location: $location
      input: $input
      companies: $companies
    ) {
      project {
        ... Project
        assets {
          ... Asset
        }
        companies
      }
    }
  }
  ${project}
  ${asset}
`

import { gql } from 'apollo-boost'

export default gql`
  mutation UpdatProjecteExtraUnits(
    $uuid: ID!,
    $input: ProjectExtraUnitsInput!
  ) {
    updateProjectExtraUnits(
      uuid: $uuid,
      input: $input
    ) {
      project {
        uuid
        enabledExtraUnits
      }
    }
  }
`

import React, { useRef, useEffect } from 'react'
import { any, func, bool } from 'prop-types'
import { Form } from 'react-bootstrap'
import { Beforeunload } from 'react-beforeunload'

const handleFocus = (event) => event.target.select()

const ThriftyInput = (props) => {
  const { value, onUpdate, setTouched, isInvalid, autoSelect, onBlur: _, ...restProps } = props
  const inputRef = useRef()

  let safeValue = value

  if (inputRef?.current?.type === 'number' && isNaN(parseFloat(safeValue))) {
    safeValue = undefined
  }

  const handleUpdate = () => {
    setTouched && setTouched(true)
    const currentValue = inputRef?.current?.value
    if (String(safeValue) === currentValue) { return }
    onUpdate(currentValue)
  }

  useEffect(() => {
    inputRef.current.value = safeValue
  }, [safeValue])

  return (
    <Beforeunload onBeforeunload={ handleUpdate }>
      <Form.Control
        defaultValue={ safeValue }
        ref={ inputRef }
        onBlur={ handleUpdate }
        onFocus={ autoSelect ? handleFocus : undefined }
        isInvalid={ isInvalid }
        { ...restProps }
      />
    </Beforeunload>
  )
}

ThriftyInput.propTypes = {
  autoSelect: bool,
  isInvalid: bool,
  onBlur: func,
  onUpdate: (props, propName, componentName) => {
    if (props.disabled) {
      return
    }

    if (typeof props[propName] !== 'function') {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Function required.`
      )
    }
  },
  setTouched: func,
  value: any
}

ThriftyInput.defaultProps = {
  autoSelect: false,
  isInvalid: false,
  onBlur: null,
  onUpdate: null,
  setTouched: undefined,
  value: null,
}

export default ThriftyInput

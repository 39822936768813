import React, { useMemo } from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import { get } from 'lodash'
import { array } from 'prop-types'

import Button from 'shared/components/ui/Button'
import { altoGreyColor } from 'shared/style/colors'
import landingPageType from 'shared/types/landingPageType'
import useCurrentCampaignUuid from 'shared/hooks/useCurrentCampaignUuid'
import ValidatableWrapper
  from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import HighlightableDiv
  from 'shared/components/ui/ThriftyInput/ValidatableWrapper/HighlightableDiv'
import { combineErrors } from 'shared/helpers/inputValidation'
import landingPagesTabName from 'shared/constants/campaigns/landingPagesTabName'
import redirectTo from 'shared/helpers/redirectTo'

import Placeholder from './Placeholder'
import Header from './Header'

const Preview = ({ className, landingPage, errors }) => {
  const campaignUuid = useCurrentCampaignUuid()

  const handlePreviewTemplateTypeClick = () => {
    let currentLocation = window.location
    if (currentLocation.search === '') {
      currentLocation = `${currentLocation}?infoTab=${landingPagesTabName}`
    }

    history.pushState(null, null, currentLocation)
    redirectTo(`/campaigns/${campaignUuid}/landing_pages/${landingPage.uuid}/edit`)
  }

  const templateUrl = get(landingPage, 'template.url')
  const previewUrl = useMemo(
    () => templateUrl && `${templateUrl}/preview.png?${Date.now()}`,
    [templateUrl]
  )

  const errorsOnContent = combineErrors({ errors, field: 'intro', uuid: landingPage.uuid })

  if (landingPage.custom) {
    return (
      <div className={ className }>
        <Header />
        <Placeholder />
      </div>
    )
  }

  return (
    <div className={ className }>
      <Header />
      <PreviewImage style={{ backgroundImage: `url(${previewUrl})` }}>
        { !previewUrl && t`Select a template to see preview` }
      </PreviewImage>

      <div id='disclaimer' className='alert alert-warning small' role='alert'>
        { t`The screenshot above is for demonstration purpose only.
            Actual landing page content and visual might differ.` }
      </div>

      <ValidatableWrapper error={ errorsOnContent }>
        <HighlightableDiv>
          <Button
            onClick={ handlePreviewTemplateTypeClick }
            data-test-id='edit-landing-page-content-button' >
            { t`Edit landing page content` }
          </Button>
        </HighlightableDiv>
      </ValidatableWrapper>
    </div>
  )
}

const PreviewImage = styled.div`
  background-size: cover;
  border-bottom: solid 1px ${altoGreyColor};
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  height: 13.75rem;
  justify-content: center;
`

Preview.propTypes = {
  landingPage: landingPageType.isRequired,
  errors: array
}

Preview.defaultProps = {
  errors: []
}

export default styled(Preview)`
  border: solid 1px ${altoGreyColor};
  border-radius: 4px;
  width: 29.5rem;
  text-align: center;

  #disclaimer {
    margin: 0;
    padding: 0.3rem;
  }

  ${Button} {
    margin: 0.5rem;
    width: 27rem
    font-size: 0.875rem;
  }
`

import React, { useState } from 'react'
import styled              from 'styled-components'
import { t }               from 'ttag'
import { string, func, array, object } from 'prop-types'

import { combineErrors } from 'shared/helpers/inputValidation'
import ThriftyInput      from 'shared/components/ui/ThriftyInput'
import InputWrapper      from 'shared/components/ui/ThriftyInput/ValidatableWrapper'
import Map               from 'shared/components/NewStage/StageDetails/Details/Map'
import CountriesSelect   from 'shared/components/NewStage/StageDetails/Details/CountriesSelect'
import ZipCodeSelect     from 'shared/components/NewStage/StageDetails/Details/ZipCodeSelect'

const validatableFields = [
  'name',
  'location.address',
  'location.countryCode',
  'location.zipCode',
  'location.latitude',
  'location.longitude'
]

const LeftColumn = ({ className, building, onUpdate, errors }) => {
  const [pointMapToCoordinates, setPointMapToCoordinates] = useState(undefined)

  const location = building.location || {}

  const handleNameChange = name => onUpdate({ name })

  const handleAddressChange = address => onUpdate({}, { address })

  const handleCountryChange = ({ value: countryCode }) => onUpdate({}, { countryCode })

  const handleZipChange = ({ value: zipCode, center }) => {
    setPointMapToCoordinates(center)
    onUpdate({}, { zipCode })
  }

  const handleLocationChange = coordinates => onUpdate({}, { ...coordinates })

  const errorsOnField = validatableFields.reduce((result, field) => {
    result[field] = combineErrors({ errors, field, uuid: building.uuid })
    return result
  }, {})

  return (
    <div className={ className }>
      <div className="group" data-test-id='building-name-group'>
        <label>{ t`Building name` }</label>
        <InputWrapper error={ errorsOnField['name'] }>
          <ThriftyInput
            type="text"
            value={ building.name }
            onUpdate={ handleNameChange }
          />
        </InputWrapper>
      </div>

      <div className="group" data-test-id='address-group'>
        <label>{ t`Building address` }</label>
        <InputWrapper error={ errorsOnField['location.address'] }>
          <ThriftyInput
            type="text"
            value={ location.address }
            onUpdate={ handleAddressChange }
          />
        </InputWrapper>
      </div>

      <div className="group" data-test-id='country-group'>
        <label>{ t`Country` }</label>
        <InputWrapper error={ errorsOnField['location.countryCode'] }>
          <CountriesSelect
            value={ location.countryCode }
            onChange={ handleCountryChange }
          />
        </InputWrapper>
      </div>

      <div className="group" data-test-id='zip-group'>
        <label>{ t`Zip code` }</label>
        <InputWrapper error={ errorsOnField['location.zipCode'] }>
          <ZipCodeSelect
            value={ location.zipCode }
            countryCode={ location.countryCode }
            onChange={ handleZipChange }
          />
        </InputWrapper>
      </div>

      <div className="group" data-test-id='location-group'>
        <label>
          <div>{ t`Location` }</div>
          <i>{ t`Drag the pin to adjust your location` }</i>
        </label>
        <InputWrapper error={ errorsOnField['location.latitude'] }>
          <Map
            pointMapToCoordinates={ pointMapToCoordinates }
            onLocationChange={ handleLocationChange }
            marker={{
              latitude: location.latitude, longitude: location.longitude
            }}
          />
        </InputWrapper>
      </div>
    </div>
  )
}

LeftColumn.propTypes = {
  building: object.isRequired,
  className: string.isRequired,
  onUpdate: func.isRequired,
  errors: array
}

LeftColumn.defaultProps = {
  errors: []
}

export default styled(LeftColumn)`
  width: 50%;
  padding: 1.25rem 1.25rem 1.625rem 2.8125rem;

  .input-wrapper {
    width: 100%
  }
`

import { gql } from 'apollo-boost'

import DashboardDataset from 'graphql/fragments/dashboardDataset'

export default gql`
  query DashboardAnalyticsImpressions($filter: JSON) {
    dashboardAnalyticsImpressions(filter: $filter) {
      impressions {
        ... DashboardDataset
      }
    }
  }
  ${DashboardDataset}
`

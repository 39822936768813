import { gql } from 'apollo-boost'

import audience from 'graphql/fragments/audience'

export default gql`
  mutation AddTargetAudience(
    $channelUuid: ID!
  ) {
    addTargetAudience(
      channelUuid: $channelUuid
    ) {
      audience {
        ... Audience
      }
      errors
    }
  }
  ${audience}
`

import { gql } from 'apollo-boost'

export default gql`
  query ProjectSalesAnalytics($uuid: ID!, $scopedTo: String!, $periodName: String!) {
    projectSalesAnalytics(uuid: $uuid, scopedTo: $scopedTo, periodName: $periodName) {
      data {
        date
        value
      }
    }
  }
`

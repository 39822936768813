import React from 'react'
import { Query } from 'react-apollo'

import { ensureNumber } from 'shared/helpers/number'
import ItemsList        from 'shared/components/ItemsList'
import UrlParams        from 'shared/components/UrlParams'

import columns        from './columns'
import productsQuery  from './productsQuery'
import sortByMappings from './sortByMappings'

function ProductsList(props) {
  return (
    <UrlParams>
      {({ setUrlParams, params }) => {
        const { search, sortBy, sortOrder } = params
        const page = ensureNumber(params.page, 1)

        return (
          <Query
            query={ productsQuery }
            variables={{ query: { page, search, sortOrder, sortBy: sortByMappings[sortBy] } }}
          >
            {({ data: { products } }) => {
              if (!products) {
                return null
              }

              return (
                <ItemsList
                  { ...props }
                  params={ params }
                  setUrlParams={ setUrlParams }
                  totalCount={ products.total }
                  items={ products.items }
                  columns={ columns }
                />
              )
            }}
          </Query>
        )
      }}
    </UrlParams>
  )
}

export default ProductsList
